import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { orderCountSelector, gettingOrdersSelector } from 'redux/modules/orders';
import { userInfoSelector } from 'redux/modules/auth';
import LabelSelect from 'components/_InputTypes/LabelSelect/LabelSelect';
import { ORDER_STATUS_OPTIONS } from 'helpers/constants/orderStatusContants';
import { BILLING_METHOD_FILTERING_OPTIONS } from 'helpers/constants/billingInfoConstants';
import { TEST_TYPE_FILTERING_OPTIONS } from 'helpers/constants/testTypeFilterConstant';
import ORDER_FILTERING_OPTIONS from 'helpers/constants/orderFilteringConstants';

import * as orderRole from '../../utils/orderRoles';

import styles from './OrdersList.scss';

const FilterSection = ({ resetPage }) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  const orderCount = useSelector(orderCountSelector);
  const gettingOrders = useSelector(gettingOrdersSelector);
  const userInfo = useSelector(userInfoSelector);

  const isHospitalAdminUser = orderRole.isHospitalAdminUser(userInfo);
  const isSupportUser = orderRole.isSupportUser(userInfo);
  const isClinicalConsultant = orderRole.isClinicalConsultant(userInfo);
  const isGeneticist = orderRole.isGeneticist(userInfo);
  const isInternalUser = isSupportUser;

  const [searchString, setSearchString] = useState(query.search || '');
  const [searchFilter, setSearchFilter] = useState(query.filter || (isInternalUser ? 'orders.id,patients.name,patients.dob' : ''));

  useEffect(() => {
    setSearchString(query.search);
  }, [query.search]);

  const debouncedResetPage = debounce(resetPage, 1000);

  const submitSearch = () => {
    if (searchFilter) {
      query.filter = searchFilter;
    } else {
      delete query.filter;
    }
    query.search = searchString;
    delete (query.page);
    debouncedResetPage(query);
  };

  // Toggle 'show' query parameter. If 'show' is already set to the same value
  // as 'showType' then reset it.
  const toggleShowQueryParam = (showType) => {
    if (query.show === showType) {
      resetPage();
      return;
    }

    resetPage({
      show: showType,
    });
  };

  const addOrRemoveQueryParam = (queryParam, value) => {
    if (query[queryParam] === value) {
      delete query[queryParam];
    } else {
      query[queryParam] = value;
    }
    if (!searchString) {
      delete query.search;
    } else {
      query.search = searchString;
    }
    delete (query.page);
    resetPage(query);
  };

  const changeOrderType = () => {
    query.shared = query.shared
      ? undefined
      : true;
    delete (query.page);
    resetPage(query);
  };

  const toggleArchive = () => toggleShowQueryParam('archive');

  const toggleUncheckedOrders = () => addOrRemoveQueryParam('show', 'unchecked');

  const togglePHIFreeOrders = () => addOrRemoveQueryParam('has_no_phifree_summary', 'true');

  const toggleSampleAtBpgOrders = () => addOrRemoveQueryParam('sample_at_bpg', 'true');
  const toggleExpandOrders = () => addOrRemoveQueryParam('expand_orders', 'true');

  const onFilterChange = (filter, value) => {
    if (!value || value === 'All') {
      delete query[filter];
    } else {
      query[filter] = value;
    }
    if (!searchString) {
      delete query.search;
    } else {
      query.search = searchString;
    }
    delete (query.page);
    resetPage(query);
  };

  const isFilterApplied = () => {
    const queryParams = Object.keys(query);

    // do not include empty params e.g. search=&show=unchecked
    const appliedQueryParams = queryParams.reduce((acc, q) => {
      if (query[q]) {
        acc.push(q);
      }
      return acc;
    }, []);

    const filters = ['has_no_phifree_summary', 'status', 'billingMethod', 'testType', 'search', 'show', 'sample_at_bpg', 'expand_orders'];
    return filters.some((filter1) => appliedQueryParams.includes(filter1));
  };

  const onKeyDownSearch = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      submitSearch();
    }
  };

  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          {userInfo
            && !isHospitalAdminUser
            && !isSupportUser
            && !isClinicalConsultant
            && (
              <div className={styles.toggleWrapper}>
                <span className={!query.shared ? 'bold' : ''}>
                  Ordered tests
                </span>
                <div>
                  <input
                    id="orderTypeToggle"
                    name="orderType"
                    className={styles.toggle}
                    type="checkbox"
                    checked={!!query.shared}
                    onChange={changeOrderType}
                  />
                  <label htmlFor="orderTypeToggle" />
                </div>
                <span className={query?.shared ? 'bold' : ''}>
                  Tests shared with me
                </span>
              </div>
            )}
          <div
            className={classNames('form-check checkbox order-archive-checkbox', {
              'no-margin-top': (userInfo && (isSupportUser || isHospitalAdminUser)),
            })}
            style={{ marginLeft: -20 }}
          >
            {!isSupportUser && !isClinicalConsultant && (
              <span style={{ paddingLeft: 20 }}>
                <input
                  id="archive_checkbox"
                  className="form-check-input"
                  type="checkbox"
                  checked={query?.show === 'archive'}
                  onChange={toggleArchive}
                />
                <label htmlFor="archive_checkbox" className="form-check-label">
                  View archived orders
                </label>
              </span>
            )}
            <div className={styles.filtering_select_container}>
              {isInternalUser && (
                <LabelSelect
                  name="test_status"
                  open
                  disabled={false}
                  readOnly={false}
                  label="Status"
                  labelSize="30"
                  inputSize="30"
                  value={query.status ? query.status : ''}
                  onChange={(e) => onFilterChange('status', e.target.value)}
                  options={ORDER_STATUS_OPTIONS}
                  required={false}
                  stackedLabel
                />
              )}
              {!isGeneticist && isInternalUser && (
                <LabelSelect
                  name="billing_method"
                  open
                  disabled={false}
                  readOnly={false}
                  label="Billing method"
                  labelSize="30"
                  inputSize="40"
                  value={query.billingMethod ? query.billingMethod : ''}
                  onChange={(e) => onFilterChange('billingMethod', e.target.value)}
                  options={BILLING_METHOD_FILTERING_OPTIONS}
                  required={false}
                  stackedLabel
                />
              )}
              {isSupportUser && (
                <LabelSelect
                  name="test_type_filter"
                  open
                  disabled={false}
                  readOnly={false}
                  label="Test type"
                  labelSize="30"
                  inputSize="40"
                  value={query.testType ? query.testType : ''}
                  onChange={(e) => onFilterChange('testType', e.target.value)}
                  options={TEST_TYPE_FILTERING_OPTIONS}
                  required={false}
                  stackedLabel
                />
              )}
              {isGeneticist && (
                <div className="form-check checkbox" style={{ paddingLeft: 20 }}>
                  <input
                    id="phifree_checkbox"
                    className="form-check-input"
                    type="checkbox"
                    checked={query.has_no_phifree_summary === 'true'}
                    onChange={togglePHIFreeOrders}
                  />
                  <label htmlFor="phifree_checkbox" className="form-check-label">
                    Without PHI-free summary
                  </label>
                </div>
              )}
              <div className={`form-check checkbox ${styles.orderlistFilterCheckboxContainer}`}>
                {!isGeneticist && isInternalUser && (
                  <>
                    <input
                      id="unchecked_orders_checkbox"
                      type="checkbox"
                      checked={query.show === 'unchecked'}
                      onChange={toggleUncheckedOrders}
                    />
                    <label htmlFor="unchecked_orders_checkbox">
                      Only unchecked
                    </label>
                  </>
                )}
                {!isGeneticist && isInternalUser && (
                  <div>
                    <input
                      id="sample_already_at_bpg_checkbox"
                      type="checkbox"
                      checked={query.sample_at_bpg === 'true'}
                      onChange={toggleSampleAtBpgOrders}
                    />
                    <label htmlFor="sample_already_at_bpg_checkbox">
                      Sample already at BpG
                    </label>
                  </div>
                )}
                {isSupportUser && (
                  <div>
                    <input
                      id="expand_orders_checkbox"
                      type="checkbox"
                      checked={query.expand_orders === 'true'}
                      onChange={toggleExpandOrders}
                    />
                    <label htmlFor="expand_orders_checkbox">
                      Expand orders
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.filters} float-right form-inline`}>
          <div className="input-group search-input-group">
            <input
              type="text"
              name="search"
              className="search form-control"
              placeholder="Search"
              onChange={(event) => setSearchString(event.target.value)}
              onKeyDown={onKeyDownSearch}
              value={searchString || ''}
            />
            <span className={styles.searchField}>
              <button type="button" className={`${styles.searchButton} search-button`} onClick={() => submitSearch()}>
                <i className="glyphicon glyphicon-search" aria-hidden="true" />
              </button>
            </span>
          </div>
          {isInternalUser && (
            <LabelSelect
              name="search fields"
              open
              disabled={false}
              readOnly={false}
              labelSize="30"
              inputSize="40"
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              options={ORDER_FILTERING_OPTIONS}
              required={false}
              stackedLabel
            />
          )}
        </div>
      </div>
      {isFilterApplied() && !gettingOrders && (
        <div className={styles.orderCount} id="order-count">
          <span id="order-count-span">{orderCount}</span>
          {' '}
          {orderCount === 1 ? 'order found' : 'orders found'}
        </div>
      )}
    </>
  );
};

FilterSection.propTypes = {
  resetPage: PropTypes.func.isRequired,
};

export default FilterSection;
