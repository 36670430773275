import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  consentPopupModalSelector,
  consentPopupModal,
  changeStep,
} from 'redux/modules/orders';
import INFORMED_CONSENT_POPUP from 'helpers/constants/popupModalConstant';

import PrimaryButton from 'components/StyleComponents/Button/PrimaryButton';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';
import styles from './ConsentPopupModal.scss';

const ConsentPopUpModal = ({ isImmediateDisposalProvince, isConsentSigned, isBiomarin }) => {
  const { showPopupModal, nextStep } = useSelector(consentPopupModalSelector);
  const dispatch = useDispatch();

  const popUpMessage = (isBiomarin && INFORMED_CONSENT_POPUP.isBiomarin)
    || (isImmediateDisposalProvince && INFORMED_CONSENT_POPUP.isImmediateDisposalProvince)
    || INFORMED_CONSENT_POPUP.default;

  const handleClose = () => {
    dispatch(consentPopupModal({ showPopupModal: false, dontShowTilNextRefresh: true }));
    dispatch(changeStep(nextStep));
  };

  const handleCancel = () => {
    dispatch(consentPopupModal({ showPopupModal: false }));
  };

  // consent popup modal should always show if the order belongs to immediate disposal states
  useEffect(() => {
    if (isConsentSigned) {
      dispatch(consentPopupModal({ modalToHandleStep: false }));
    }
    if (!isConsentSigned || isImmediateDisposalProvince) {
      dispatch(consentPopupModal({ modalToHandleStep: true }));
    }
  }, [isConsentSigned, isImmediateDisposalProvince, dispatch]);

  return (
    <Modal
      dialogClassName={`${styles.modal_wrapper} consent-popup-modal`}
      show={showPopupModal}
      onHide={handleCancel}
    >
      <Modal.Header id={styles.modal_header}>
        Information
      </Modal.Header>
      <Modal.Body className={styles.modal_body} id="no-consent-msg">
        {popUpMessage}
      </Modal.Body>
      <Modal.Footer>
        <SecondaryButton labelText="Cancel" onClick={handleCancel} />
        <PrimaryButton labelText="OK" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
};

ConsentPopUpModal.propTypes = {
  isImmediateDisposalProvince: PropTypes.bool.isRequired,
  isConsentSigned: PropTypes.bool.isRequired,
  isBiomarin: PropTypes.bool.isRequired,
};

export default ConsentPopUpModal;
