import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportAppendixReferral extends Component {
  static propTypes = {
    sortedPanelQaArr: PropTypes.array,
    appendixReferralShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string
  }

  render() {
    const {appendixHeading, sortedPanelQaArr, appendixReferralShown, onTogglePanel} = this.props;
    const styles = require('./_OrderReportAppendixReferral.scss');

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixReferralShown')}>
          {appendixHeading}
          {appendixReferralShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixReferralShown ? '' : 'invisible'}>
        {sortedPanelQaArr && sortedPanelQaArr.map(question =>
          <div key={question.order_no}>
            <label className="view_order_modal_label">{question.question}</label>
            <span>{question.answer}</span>
          </div>
        )}
        </div>
      </div>
    );
  }
}
