import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isExome, isMSD, isFMT } from 'utils/forms';

export default class OrderReportAppendixSummaryOfMethods extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
    orderInfo: PropTypes.object,
    appendixSummaryMethodsShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string,
    handleScrollTo: PropTypes.func
  }

  render() {
    const {
      appendixHeading,
      orderReport,
      appendixSummaryMethodsShown,
      onTogglePanel,
      orderInfo,
      handleScrollTo
    } = this.props;
    const isExpectedSpecialTypes = isExome(orderInfo.special_type) || isMSD(orderInfo.special_type) || isFMT(orderInfo.special_type);
    const styles = require('./_OrderReportAppendixSummaryOfMethods.scss');

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixSummaryMethodsShown')}>
          {appendixHeading}
          {appendixSummaryMethodsShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixSummaryMethodsShown ? '' : 'invisible'}>
          {orderReport.version < 6 &&
            <div>
              {orderReport.summary_of_methods &&
                <div>
                  <h3 className="h4">{orderReport.summary_of_methods_title}</h3>
                  <div>
                    {!isExpectedSpecialTypes && orderReport.summary_of_methods.split('\n').map((item, index) =>
                      <p key={index}>
                        {item}
                      </p>
                    )}
                    {isExpectedSpecialTypes && orderReport.summary_of_methods.split('\n').map((item, index) =>
                      <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                      </p>
                    )}
                  </div>
                </div>
              }

              {orderReport.summary_of_methods_cnv &&
                <div>
                  <h3 className="h4">{orderReport.summary_of_methods_cnv_title}</h3>
                  <div>
                    {orderReport.summary_of_methods_cnv.split('\n').map((item, index) =>
                      <p key={index}>
                        {item}
                      </p>
                    )}
                  </div>
                </div>
              }
            </div>
          }

          {orderReport.version >= 6 &&
            <div>
              {orderReport.summary_of_methods &&
                <div>
                  <h3 className="h4">{orderReport.summary_of_methods_title}</h3>
                  <div>
                    {orderReport.summary_of_methods.split('\n').map((item, index) =>
                      <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                      </p>
                    )}
                  </div>
                  {orderReport.version > 9 && (
                    <div dangerouslySetInnerHTML={{__html: orderReport.performing_site}}>
                    </div>
                  )}
                </div>
              }

              {orderReport.summary_of_methods_cnv &&
                <div>
                  <h3 className="h4">{orderReport.summary_of_methods_cnv_title}</h3>
                  <div>
                    {orderReport.summary_of_methods_cnv.split('\n').map((item, index) =>
                      <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                      </p>
                    )}
                  </div>
                </div>
              }

              {orderReport.version > 6 && orderReport.non_coding_variants && !isExpectedSpecialTypes &&
                <div>
                  <h3 className="h4">{orderReport.non_coding_variants_title}</h3>
                  {orderReport.non_coding_variants.split('\n').map((item, index) =>
                    <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                    </p>
                  )}
                </div>
              }

              {orderReport.version > 6 && orderReport.non_coding_variants && orderReport.non_coding_variants_notification && isExpectedSpecialTypes &&
                <div className="anchor-to-findings">
                  <a className="link-pink" onClick={() => handleScrollTo('noneCodingVariantsAppendix', 'appendixNoneCodingVariantsShown')}>{orderReport.non_coding_variants_notification}</a>
                </div>
              }

              {orderReport.glossary &&
                <div>
                  <h3 className="h4">{orderReport.glossary_title}</h3>
                  {orderReport.glossary.split('\n').map((item, index) =>
                    <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                    </p>
                  )}
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}
