// Blank order edit form
// Details: https://blueprintgenetics.atlassian.net/browse/SD-2652
import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field,
} from 'formik';

import { Yup } from 'components/Formik/validation';
import LabelInput from 'components/Formik/LabelInput';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';
import { LabelAttachments } from 'components';
import { keepOnlyFields } from 'utils/forms';
import SpinnerOverlay from 'components/Spinner/SpinnerOverlay';
import PromptIfDirty from 'components/Formik/PromptIfDirty';

import { OTHER_ATTACHMENT_TYPE, REQUISITION_ATTACHMENT_TYPE } from 'helpers/constants/fileTypeConstants';

const validationSchema = Yup.object().shape({
  transferring_to_email: Yup.string().nullable(), // Read-only field
  user_email: Yup.string(),
  patient_first_name: Yup.string().nullable(),
  patient_last_name: Yup.string().nullable(),
  patient_dob: Yup.string().nullable(),
  patient_ssn: Yup.string().nullable(),
  questions: Yup.array().of(Yup.object().shape({})),
});

const EditBlankOrderForm = ({
  order,
  questions,
  userInfo,
  onSave,
  onConvert,
  onDelete,
  onClose,
}) => {
  const submit = async (values, actions, isAndCloseButton) => {
    try {
      await onSave(values);

      if (isAndCloseButton) {
        onClose();
      }
    } catch (e) {
      actions.setSubmitting(false);
      actions.setFieldError('general', e.message);
    }
  };

  return (
    <Formik
      initialValues={keepOnlyFields(
        Object.keys(validationSchema.fields),
        { ...order, questions },
      )}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        submit(values, actions);
      }}
    >
      {(formProps) => (
        <Form>
          {formProps.errors.general && (
          <p className="text-danger">{formProps.errors.general}</p>
          )}
          <PromptIfDirty {...formProps} />

          <div>
            <Field
              name="user_email"
              component={LabelInput}
              label="Customer email"
              hint="Specify an email you want this order to be transferred to."
              autocomplete="off"
            />

            {formProps.values.transferring_to_email && (
            <Field
              name="transferring_to_email"
              component={LabelInput}
              label="Transferring order to"
              hint="Order is being transferred to this email. It takes a few minutes for the transfer to be completed."
              disabled
            />
            )}

            <Field
              name="patient_first_name"
              component={LabelInput}
              label="Patient first name"
            />

            <Field
              name="patient_last_name"
              component={LabelInput}
              label="Patient last name"
            />

            <Field
              name="patient_dob"
              component={SimpleDatePicker}
              label="Patient date of birth"
            />

            <Field
              name="patient_ssn"
              component={LabelInput}
              label="Patient Identifier / Medical Record Number"
            />

            <hr />

            <LabelAttachments
              orderId={order.order_id}
              userInfo={userInfo}
              type={REQUISITION_ATTACHMENT_TYPE}
              label="Attached requisition form files"
              chooseFileButtonCustomLabel="Attach req"
            />

            <LabelAttachments
              orderId={order.order_id}
              userInfo={userInfo}
              type={OTHER_ATTACHMENT_TYPE}
              label="Other attachments"
              chooseFileButtonCustomLabel="Other files"
            />

            <hr />

            <PatientHistoryQuestions questions={formProps.values.questions} />
          </div>

          <div>
            <span className="float-left button-group">
              <button
                className="btn btn-primary"
                type="button"
                disabled={formProps.isSubmitting}
                onClick={onDelete}
              >
                Delete
              </button>
            </span>

            <span className="float-right button-group">
              <button
                type="button"
                className="btn btn-default"
                disabled={formProps.isSubmitting || formProps.dirty}
                onClick={onConvert}
              >
                Convert
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={formProps.isSubmitting || !formProps.dirty}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={formProps.isSubmitting}
                onClick={async () => {
                  formProps.setSubmitting(true);
                  submit(formProps.values, formProps, 'saveAndCloseButton');
                }}
              >
                Save and Close
              </button>
            </span>
          </div>

          {formProps.isSubmitting && <SpinnerOverlay />}
        </Form>
      )}
    </Formik>
  );
};

EditBlankOrderForm.propTypes = {
  // Fields
  order: PropTypes.shape({
    order_id: PropTypes.number,
  }).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userInfo: PropTypes.shape({}).isRequired,

  // Methods
  onSave: PropTypes.func.isRequired,
  onConvert: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditBlankOrderForm;
