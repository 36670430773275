import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import SelectInput from 'components/Formik/SelectInput';

const BatchSelectField = ({ name, ...props }) => (
  <Field
    name={name}
    component={SelectInput}
    label={`Select ${name}`}
    placeholder={`Select ${name}`}
    required
    {...props}
  />
);

BatchSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default BatchSelectField;
