import PropTypes from 'prop-types';

export const ExomeFamilyPatientsPropTypes = {
  orderStatus: PropTypes.shape({
    order_id: PropTypes.number,
    patient_name_fm1: PropTypes.string,
    patient_ssn_fm1: PropTypes.string,
    patient_dob_fm1: PropTypes.string,
    lab_id_fm1: PropTypes.string,
    patient_name_fm2: PropTypes.string,
    patient_ssn_fm2: PropTypes.string,
    patient_dob_fm2: PropTypes.string,
    lab_id_fm2: PropTypes.string,
    special_type: PropTypes.string,
    printer_name: PropTypes.string,
  }),
  sampleTypeOptions: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleInputValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  sampleDisposalCategoryOptions: PropTypes.arrayOf(PropTypes.shape({})),
  wesSecondaryFindingsConsentOptions: PropTypes.arrayOf(PropTypes.shape({})),
  workflows: PropTypes.arrayOf(PropTypes.shape({})),
  showWorkflows: PropTypes.bool,
};

export const ExomeFamilyPatientsDefaultProps = {
  orderStatus: null,
  sampleTypeOptions: null,
  disabled: null,
  sampleDisposalCategoryOptions: [],
  wesSecondaryFindingsConsentOptions: [],
  workflows: [],
  showWorkflows: null,
};

export const ExomeFamilyPatientPropTypes = {
  disabled: PropTypes.bool,
  dob: PropTypes.string,
  handleInputValue: PropTypes.func.isRequired,
  lab_id: PropTypes.string,
  name: PropTypes.string,
  isImmediateDisposalProvince: PropTypes.bool.isRequired,
  isSparkSpecialType: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  order_id: PropTypes.number,
  orderStatus: PropTypes.shape({}),
  patientKey: PropTypes.string.isRequired,
  sampleDisposalCategoryOptions: PropTypes.arrayOf(PropTypes.shape({})),
  sampleDisposalRequired: PropTypes.bool,
  sampleTypeOptions: PropTypes.shape({}),
  showWorkflows: PropTypes.bool,
  special_type: PropTypes.string,
  ssn: PropTypes.string,
  wesSecondaryFindingsConsentOptions: PropTypes.arrayOf(PropTypes.shape({})),
  wesSecondaryFindingsConsentRequired: PropTypes.bool,
  workflows: PropTypes.arrayOf(PropTypes.shape({})),
};

export const ExomeFamilyPatientDefaultProps = {
  disabled: false,
  dob: null,
  lab_id: null,
  name: null,
  order_id: null,
  orderStatus: null,
  sampleDisposalCategoryOptions: [],
  sampleDisposalRequired: false,
  sampleTypeOptions: null,
  showWorkflows: null,
  special_type: null,
  ssn: null,
  wesSecondaryFindingsConsentOptions: [],
  wesSecondaryFindingsConsentRequired: false,
  workflows: null,
};
