import { sortCountries } from 'utils/forms';

const LOAD_COUNTRIES = 'nucleus/LOAD_COUNTRIES';
const LOAD_COUNTRIES_SUCCESS = 'nucleus/LOAD_COUNTRIES_SUCCESS';
const LOAD_COUNTRIES_FAIL = 'nucleus/LOAD_COUNTRIES_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  countries: {},
};

export default function orders(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_COUNTRIES: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_COUNTRIES_SUCCESS: {
      const countriesById = action.result.countries.reduce(
        (byId, country) => ({ ...byId, [country.id]: country }), {},
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        countries: countriesById,
      };
    }
    case LOAD_COUNTRIES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}

export function loadCountries() {
  return {
    types: [LOAD_COUNTRIES, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_FAIL],
    request: (client) => client.get('get_allowed_countries'),
  };
}

export function loadCountriesList() {
  return {
    request: (client) => client.get('get_allowed_countries')
      .then((result) => sortCountries(result.countries)),
  };
}

export const countriesSelector = (state) => state.countries.countries;

export const countriesErrorSelector = (state) => state.countries.error;

export const countriesLoadingSelector = (state) => state.countries.loading;
