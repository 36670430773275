import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// TODO: This LabelInput will be removed once redux-form is not in use
import { LabelInput } from 'components';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { fetchPanelGenes as fetchPanelGenesImported } from 'redux/modules/orders';
import Spinner from 'components/Spinner/Spinner';
import { isMitoGene } from 'utils/forms';
import GeneListNote from './GeneListNote';

import styles from './GeneSelection.scss';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPanelGenes: fetchPanelGenesImported,
}, dispatch);

class GeneSelection extends Component {
  filterGenes = debounce(async (gene) => {
    const {
      onFetchTranscripts,
      fetchPanelGenes,
      panelId,
      initialGenes,
    } = this.props;

    if (!gene) {
      // Do not fetch genes for empty input
      this.setState({
        gene: '',
        availableGenes: [],
      });

      return;
    }

    const res = await fetchPanelGenes({
      panelId,
      gene,
    });

    if (res && res.result && res.result.genes) {
      const genes = res.result.genes;

      // If we are in VST edit we need to set transcripts for the initial gene if search returns it
      if (onFetchTranscripts
          && initialGenes.length === 1
          && genes.length
          && initialGenes[0].hgnc_symbol === genes[0].hgnc_symbol) {
        if (genes[0].transcripts) {
          onFetchTranscripts(genes[0].transcripts);
        }
      } else {
        genes.sort((a, b) => a.hgnc_symbol.localeCompare(b.hgnc_symbol));

        this.setState({
          availableGenes: genes,
          loading: false,
          noGenesFound: !genes.length,
        });
      }
      return;
    }

    this.setState({
      error: 'Failed to fetch available genes',
      availableGenes: [],
      loading: false,
      noGenesFound: false,
    });
  }, 300);

  static propTypes = {
    initialGenes: PropTypes.arrayOf(
      PropTypes.shape({
        has_suboptimal_coverage: PropTypes.bool,
        hgnc_aliases: PropTypes.arrayOf(PropTypes.string),
        hgnc_symbol: PropTypes.string.isRequired,
        id: PropTypes.number,
        overlaps_segmental_duplication: PropTypes.bool,
      }),
    ), // initialGenes for example on the edit order case
    error: PropTypes.string,
    panelId: PropTypes.number.isRequired,
    readonly: PropTypes.bool,
    limitNbrGenes: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onFetchTranscripts: PropTypes.func,
    fetchPanelGenes: PropTypes.func.isRequired,
    hint: PropTypes.node,
    // ignoredGenes for when coming from a panel that has a preselection
    // of genes to not pick a gene twice
    ignoredGenes: PropTypes.arrayOf(
      PropTypes.shape({
        has_suboptimal_coverage: PropTypes.bool.isRequired,
        hgnc_aliases: PropTypes.arrayOf(PropTypes.string),
        hgnc_symbol: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        overlaps_segmental_duplication: PropTypes.bool,
      }),
    ),
    isClickedRemoveAll: PropTypes.bool,
  };

  static defaultProps = {
    readonly: false,
    hint: '',
    ignoredGenes: [],
    initialGenes: [],
    error: null,
    onFetchTranscripts: null,
    isClickedRemoveAll: false,
  };

  constructor(props) {
    super(props);

    const isVST = props.limitNbrGenes === 1;
    // We need to copy initialGenes so as not mutate props
    const selectedGenes = props.initialGenes.length !== 0 ? [...props.initialGenes] : [];
    const searchGene = isVST && selectedGenes.length ? selectedGenes[0].hgnc_symbol : '';

    this.state = {
      gene: '',
      availableGenes: [],
      selectedGenes,
      error: null,
      noGenesFound: false,
    };

    if (searchGene) {
      this.filterGenes(searchGene);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isClickedRemoveAll
      && prevProps.initialGenes.length > this.props.initialGenes.length
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedGenes: this.props.initialGenes.length !== 0 ? [...this.props.initialGenes] : [],
      });
    }
  }

  // ignore genes included in the panel in case of custom panels
  isIgnored = (availableGene, ignoredGenes) => ignoredGenes.some((gene) => (
    gene.hgnc_symbol === availableGene.hgnc_symbol
  ));

  isDisabled = (genes, ignoredGenes) => {
    const commonGenes = ignoredGenes.filter((ignoredGene) => genes.some((gene) => (
      gene.hgnc_symbol === ignoredGene.hgnc_symbol
    )));
    return genes.length === commonGenes.length;
  }

  selectFirst = () => {
    const { availableGenes, gene, selectedGenes } = this.state;
    const { limitNbrGenes } = this.props;

    if (availableGenes.length) {
      const availableGene = availableGenes[0];
      if (gene !== availableGene && selectedGenes.length < limitNbrGenes) {
        this.selectGene(availableGene);
      }
    }
  };

  selectGene = (availableGene) => {
    if (this.geneInputRef) {
      this.geneInputRef.focus();
    }

    this.setState(
      (prevState) => ({
        selectedGenes: [...prevState.selectedGenes, availableGene],
        availableGenes: [],
        gene: '',
      }),
      () => this.props.onClick(this.state.selectedGenes),
    );
  };

  selectGenesStartingWithPrefix = (availableGenes, prefix) => {
    this.setState((state) => {
      const genesToAdd = availableGenes.filter(
        (availableGene) => availableGene.hgnc_symbol.startsWith(prefix.toUpperCase()),
      );
      const selectedGenes = state.selectedGenes.concat(genesToAdd);
      this.props.onClick(selectedGenes);

      if (this.geneInputRef) {
        this.geneInputRef.focus();
      }

      return {
        selectedGenes,
        availableGenes: [],
        gene: '',
      };
    });
  }

  render() {
    const {
      readonly,
      hint,
      limitNbrGenes,
      ignoredGenes,
    } = this.props;

    const {
      availableGenes,
      gene,
      selectedGenes,
      loading,
      noGenesFound,
    } = this.state;

    const error = this.props.error || this.state.error;

    // variable to be more explicit in the conditions
    const isVST = limitNbrGenes === 1;

    const showButtonForAddingMitoGenes = limitNbrGenes - selectedGenes.length >= 37
      && availableGenes.length > 0
      && !isVST
      && gene.toLowerCase() === 'mt-';

    return (
      <div>
        <LabelInput
          label={(
            isVST
              ? 'Gene'
              : 'Add genes to the panel'
          )}
          inputRef={(input) => {
            this.geneInputRef = input;
          }}
          labelSize="12"
          inputSize={isVST ? '6' : '7'}
          name="gene"
          type="text"
          placeholder={isVST ? 'Please type a gene name' : 'Start typing to search for genes, start with "MT-" for mtDNA'}
          readOnly={readonly}
          value={gene}
          touched
          disabled={selectedGenes.length === limitNbrGenes}
          error={error}
          onChange={(e) => {
            const value = e.target.value.trim();
            this.filterGenes(value);
            this.setState({
              gene: value,
              error: null,
              availableGenes: [],
              loading: true,
            });
          }}
          onKeyUp={(e) => {
            // On Enter select first gene
            if (e.keyCode === 13) {
              this.selectFirst();
            }
          }}
          style={{ marginLeft: '15px' }}
          required
          hint={hint}
        />

        {selectedGenes.length === limitNbrGenes && !isVST && (
          <p className="text-danger">{`You can only add ${limitNbrGenes} genes.`}</p>
        )}

        <ul className="list-unstyled list-inline">
          {loading && !!gene.length && (
            <div className="spinner-inline">
              <Spinner />
            </div>
          )}

          {!loading && noGenesFound && (
            <p className="text-danger">No genes can be found.</p>
          )}

          {availableGenes.map((availableGene) => (
            <li key={availableGene.hgnc_ext_id} className="list-inline-item">
              <button
                type="button"
                className={classNames('btn', styles.gene, {
                  [styles.selected]: selectedGenes.includes(availableGene),
                })}
                disabled={
                  selectedGenes.length >= limitNbrGenes
                  || this.isIgnored(
                    availableGene,
                    ignoredGenes.concat(selectedGenes),
                  )
                }
                onClick={() => this.selectGene(availableGene)}
              >
                {availableGene.hgnc_symbol}
                <span className="text-pink">
                  {
                    availableGene.overlaps_segmental_duplication && !isVST
                      ? ' *'
                      : ''
                  }
                  {availableGene.has_suboptimal_coverage && !isVST ? ' #' : ''}
                </span>
              </button>
            </li>
          ))}
          { showButtonForAddingMitoGenes && (
            <li key="add-mito-genes">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.selectGenesStartingWithPrefix(availableGenes, gene)}
                disabled={
                  this.isDisabled(
                    availableGenes.filter((availableGene) => isMitoGene(availableGene.hgnc_symbol)),
                    ignoredGenes.concat(selectedGenes),
                  )
                }
              >
                Add the Mitochondrial Genome
              </button>
            </li>
          )}
        </ul>
        <div>
          <h3 className={styles.selected_genes_list_heading}>
            {isVST ? 'Selected gene' : 'Added genes'}
          </h3>
          <small className="text-gray">Click to remove</small>

          {selectedGenes.length === 0 && (
            <p className="small">
              {`No gene${isVST ? '' : 's'} selected. Please select a gene in the list above`}
            </p>
          )}

          <ul className="list-unstyled list-inline">
            {selectedGenes.map((selectedGene) => (
              <li key={selectedGene.hgnc_symbol || selectedGene.gene} className="list-inline-item">
                <button
                  type="button"
                  className={classNames('btn', styles.gene, {
                    [styles.selected]: selectedGenes.includes(selectedGene),
                  })}
                  onClick={() => {
                    this.setState(
                      (prevState) => ({
                        selectedGenes: prevState.selectedGenes.filter(
                          (value) => value !== selectedGene,
                        ),
                      }),
                      () => this.props.onClick(this.state.selectedGenes),
                    );
                  }}
                >
                  {selectedGene.hgnc_symbol || selectedGene.gene}
                  <span className="text-pink">
                    {
                      selectedGene.overlaps_segmental_duplication && !isVST
                        ? ' *'
                        : ''
                    }
                    {selectedGene.has_suboptimal_coverage && !isVST ? ' #' : ''}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        {!isVST ? <GeneListNote /> : null}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneSelection);
