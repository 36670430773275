/**
 * Helper methods to simplify form validation with Yup.
 */

import * as Yup from 'yup';

import {
  loopQuestions,
  questionAnswerField,
} from 'utils/patientQuestions';

export const requiredText = '* This field is required';

export { Yup };

/**
 * Require string to be non empty.
 */
export function requiredString(errorMessage = requiredText) {
  return Yup.string(errorMessage).ensure().required(errorMessage);
}

/**
 * Require integer to be more than zero.
 */
export function requiredPositiveInteger(errorMessage = requiredText) {
  return Yup.number().transform((value) => (
    // Default Yup casting returns an error when input value is empty string
    value || 0
  ))
    .integer(errorMessage)
    .positive(errorMessage)
    .required(errorMessage);
}

/**
 * A shorthand to Yup.mixed().when() method.
 */
// eslint-disable-next-line max-params
export function when(keys, is, then, otherwise = Yup.mixed()) {
  return Yup.mixed().when(keys, {
    is,
    then,
    otherwise,
  });
}

/**
 * A shorthand for partial value comparison
 */

// eslint-disable-next-line max-params
export function whenValue(keys, string, then, otherwise = Yup.mixed()) {
  return Yup.mixed().when(keys, {
    is: (value) => value.includes(string),
    then,
    otherwise,
  });
}

/**
 * Schema for date of birth validation
 */
export function dobSchema() {
  return Yup.string()
    .ensure()
    .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, requiredText);
}

const defaultPatientQuestionsValidationOptions = {
  // If set to true validation will require all question options
  // to be selected for checkbox
  requireAllCheckboxes: false,
};

/**
 * Helper method for validating patient questions.
 */
export function patientQuestions(
  questions,
  { requireAllCheckboxes } = defaultPatientQuestionsValidationOptions,
) {
  const questionErrors = [];

  loopQuestions(questions, ({ isQuestionVisible }) => {
    questions?.forEach((question, index) => {
      // Validate only visible and required questions
      if (isQuestionVisible(question) && question.is_compulsory) {
        let valid = true;
        const fieldName = questionAnswerField(question);
        const value = question[fieldName];

        if (!value) {
          valid = false;
        } else {
          switch (question.type) {
            case 'checkbox': {
              valid = Array.isArray(value) && !!value.length;
              if (
                valid
                && requireAllCheckboxes
                && value.length !== question.question_options.length
              ) {
                valid = false;
              }
              break;
            }

            case 'number':
              if (question.type === 'number' && (Number.isNaN(parseInt(value, 10)))) {
                valid = false;
              }
              break;

            default:
          }
        }

        if (!valid) {
          questionErrors[index] = {
            [fieldName]: requiredText,
          };
        }
      }
    });
  });

  return questionErrors;
}

/**
 * Validate groups of patient questions.
 * Each group might contain multiple questions.
 */
export function patientQuestionGroups(groups) {
  const errors = [];

  groups.forEach((group, index) => {
    const groupErrors = patientQuestions(group.questions);
    if (groupErrors.length) {
      errors[index] = {
        questions: groupErrors,
      };
    }
  });

  return errors;
}
