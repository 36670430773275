import React from 'react';
import PropTypes from 'prop-types';

import styles from './ViewOrderInfo.scss';

const VstSection = ({
  indexOrderId,
  indexOrderCompletionDate,
  variants,
}) => (
  <div>
    {indexOrderId && (
      <div className="modal_row">
        <label className="view_order_modal_label">
          Index order Id
        </label>
        <span>{indexOrderId}</span>
      </div>
    )}

    {indexOrderCompletionDate && (
      <div className="modal_row">
        <label className="view_order_modal_label">
          Index order reported on
        </label>
        <span>{indexOrderCompletionDate}</span>
      </div>
    )}

    <h4 className={styles.extra_info_heading}>Targeted variants</h4>
    {variants && variants.map((item) => (
      <div className={styles.subscription_item} key={item.id}>
        <div className={styles.subscription_item_row}>
          <label className="view_order_modal_label">Gene</label>
          <span>{item.gene}</span>
        </div>
        {!item.gene.startsWith('MT-') && (
          <div className={styles.subscription_item_row}>
            <label className="view_order_modal_label">
              Transcript
            </label>
            <span>{item.transcript}</span>
          </div>
        )}
        <div className={styles.subscription_item_row}>
          <label className="view_order_modal_label">
            Coding DNA/mtDNA change
          </label>
          <span>{item.c_dna_change}</span>
        </div>
        <div className={styles.subscription_item_row}>
          <label className="view_order_modal_label">
            Protein change
          </label>
          <span>{item.protein_change}</span>
        </div>
        <div className={styles.subscription_item_row}>
          <label className="view_order_modal_label">Event</label>
          <span>{item.event}</span>
        </div>
      </div>
    ))}
  </div>
);

VstSection.propTypes = {
  indexOrderId: PropTypes.string,
  indexOrderCompletionDate: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({
    gene: PropTypes.string,
    transcript: PropTypes.string,
    c_dna_change: PropTypes.string,
    protein_change: PropTypes.string,
    event: PropTypes.string,
  })),
};

VstSection.defaultProps = {
  indexOrderId: null,
  indexOrderCompletionDate: null,
  variants: null,
};

export default VstSection;
