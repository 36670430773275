import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik, Form, Field,
} from 'formik';

import SelectInput from 'components/Formik/SelectInput';
import LabelInput from 'components/Formik/LabelInput';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import SpinnerOverlay from 'components/Spinner/SpinnerOverlay';
import {
  isCountryUS,
  isLatamCountryCode,
  isUsTerritoryCountryCode,
  getProvinceOptions,
  validateWithContext,
} from 'utils/forms';
import { loadProvinces, provincesSelector } from 'redux/modules/provinces';
import { loadCountries, countriesSelector } from 'redux/modules/countries';
import useCountryArray from 'hooks/useCountryArray';
import { isUsRegion } from 'utils/envUtils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Hospital name is required'),
  country_id: Yup.string().required('Country is required'),
  production_location: Yup.string().when('id', {
    is: (value) => value && value !== 0,
    then: Yup.string().required('Production Location is required'),
  }),
  pricing_model: Yup.string().when('id', {
    is: (value) => value && value !== 0,
    then: Yup.string().required('Pricing Model is required'),
  }),
  province_id: Yup.string().nullable().when(['country_id', '$countries'], {
    is: (countryId, countries) => isCountryUS(countryId, countries),
    then: Yup.string().required('State is required for US hospitals'),
  }),
});

const validateForm = (countries) => validateWithContext(
  validationSchema,
  { countries },
);

const EditHospitalForm = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [showAllCountries, setShowAllCountries] = useState(false);

  const provinces = useSelector(provincesSelector);
  const countries = useSelector(countriesSelector);

  const countriesArray = useCountryArray(countries);

  const {
    hospital,
    isModify,
    onSave,
    onClose,
  } = props;

  const handleCountryChange = () => ({ target: { value } }) => {
    setSelectedCountryId(value);
  };

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  useEffect(() => {
    const countryId = selectedCountryId || hospital.country_id;
    if (isCountryUS(countryId, countries)) {
      dispatch(loadProvinces(countryId));
    }
  }, [dispatch, selectedCountryId, countries, hospital.country_id]);

  const handleDelete = async () => {
    const { onDelete } = props;

    setIsDeleting(true);
    setError(false);

    try {
      await onDelete();
      onClose();
    } catch (e) {
      setError(e.message);
      setIsDeleting(false);
    }
  };
  let provinceOptions = getProvinceOptions(provinces);
  const productionLocationOptions = [
    { key: 'Finland', value: 'Finland' },
    { key: 'United States', value: 'United States' },
  ];
  const pricingModelOptions = [
    { key: 'fixed', value: 'Fixed' },
    { key: 'gene_count', value: 'Gene Count' },
    { key: 'phenotype', value: 'Phenotype' },
  ];
  const usingUsRegion = isUsRegion();
  const countryOptions = useMemo(() => {
    let regionCountryOptions;
    if (showAllCountries) {
      regionCountryOptions = countriesArray;
    } else {
      // Filter list of countries based on selection and region
      regionCountryOptions = countriesArray.filter((country) => {
        if (country.id === Number(selectedCountryId) || country.id === hospital.country_id) {
          return true;
        }
        if (usingUsRegion) {
          return isUsTerritoryCountryCode(country.code) || isLatamCountryCode(country.code);
        }
        return !isLatamCountryCode(country.code);
      });
    }
    return regionCountryOptions.map((country) => ({
      key: country.id,
      value: country.name,
    }));
  }, [countriesArray, showAllCountries, selectedCountryId, hospital, usingUsRegion]);
  if (!showAllCountries) {
    provinceOptions = usingUsRegion ? provinceOptions.filter((province) => province.value !== 'New York') : provinceOptions.filter((province) => province.value === 'New York');
  }

  const currentCountryId = selectedCountryId || hospital.country_id;

  return (
    <Formik
      initialValues={hospital}
      validate={validateForm(countries)}
      onSubmit={(fields, actions) => {
        onSave(fields)
          .then(() => {
            actions.resetForm({ values: hospital });
            onClose();
          })
          .catch((e) => {
            actions.setFieldError('general', e.message);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(formProps) => (
        <Form>
          {formProps.errors.general && (
          <p className="text-danger">{formProps.errors.general}</p>
          )}

          {error && (
          <p className="text-danger">{error}</p>
          )}

          <PromptIfDirty {...formProps} />

          <div>
            <Field
              name="name"
              component={LabelInput}
              label="Hospital"
              required
            />

            <Field
              name="business_id"
              component={LabelInput}
              label="Business ID"
            />

            <Field
              name="address_line_1"
              component={LabelInput}
              label="Address Line 1"
            />

            <Field
              name="address_line_2"
              component={LabelInput}
              label="Address Line 2"
            />

            <Field
              name="city"
              component={LabelInput}
              label="City"
            />

            <Field
              name="zip"
              component={LabelInput}
              label="Zip"
            />

            <Field
              label="Country"
              name="country_id"
              component={SelectInput}
              options={countryOptions}
              placeholder="Select country:"
              onChange={handleCountryChange()}
              required
            />

            {isCountryUS(currentCountryId, countries) && (
            <Field
              label="State"
              name="province_id"
              component={SelectInput}
              options={provinceOptions}
              placeholder="Select state:"
              required
            />
            )}

            <div className="row">
              <div className="col-xs-12 col-md-6 col-form-label" />
              <div className="col-xs-12 col-md-6 pl-md-0 pb-2">
                <div className="from-group form-check checkbox">
                  <input
                    id="show-all-countries"
                    className="form-check-input"
                    type="checkbox"
                    name="show_all_countries"
                    checked={showAllCountries}
                    onChange={() => setShowAllCountries(!showAllCountries)}
                  />
                  <label htmlFor="show-all-countries" className="form-check-label">Also show the countries that cannot be selected by default in this Nucleus instance</label>
                </div>
              </div>
              {showAllCountries && (
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    <p>
                      { /* eslint-disable-next-line max-len */ }
                      The country list now displays countries from both Nucleus instances (EU and US). Make sure you are adding the hospital to the correct Nucleus instance.
                    </p>
                  </div>
                </div>
              )}
            </div>

            {isModify && (
              <Field
                label="Production Location"
                name="production_location"
                component={SelectInput}
                options={productionLocationOptions}
                placeholder="Select production location:"
                required
              />
            )}

            {isModify && (
              <>
                {selectedCountryId && Number(selectedCountryId) !== hospital.country_id && (
                  <div className="row">
                    <div className="col-12">
                      <div className="alert alert-info" role="alert">
                        { /* eslint-disable-next-line max-len */ }
                        <p>You just changed country of the hospital. Please check if you need to change the pricing model too.</p>
                      </div>
                    </div>
                  </div>
                )}
                <Field
                  label="Pricing Model"
                  name="pricing_model"
                  component={SelectInput}
                  options={pricingModelOptions}
                  placeholder="Select pricing model:"
                  required
                />
              </>
            )}
          </div>

          <div className="float-right button-group">
            {isModify && (
            <button
              type="button"
              className="btn btn-default"
              disabled={isDeleting}
              onClick={handleDelete}
            >
              Delete
            </button>
            )}

            <button
              type="button"
              className="btn btn-default"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={formProps.isSubmitting || !formProps.dirty}
            >
              Save
            </button>
          </div>

          {(formProps.isSubmitting || isDeleting) && <SpinnerOverlay />}
        </Form>
      )}
    </Formik>
  );
};

EditHospitalForm.propTypes = {
  // Fields
  hospital: PropTypes.shape({
    country_id: PropTypes.number,
  }).isRequired,
  isModify: PropTypes.bool.isRequired,

  // Methods
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditHospitalForm;
