const CREATE_AUTHORIZED_PROVIDER = 'nucleus/CREATE_AUTHORIZED_PROVIDER';
const CREATE_AUTHORIZED_PROVIDER_SUCCESS = 'nucleus/CREATE_AUTHORIZED_PROVIDER_SUCCESS';
const CREATE_AUTHORIZED_PROVIDER_FAIL = 'nucleus/CREATE_AUTHORIZED_PROVIDER_FAIL';
const CREATE_AUTHORIZED_PROVIDER_RESET = 'nucleus/CREATE_AUTHORIZED_PROVIDER_RESET';

const getInitialState = () => ({
  creating: false,
  created: false,
  creationError: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case CREATE_AUTHORIZED_PROVIDER: {
      return {
        ...state,
        creating: true,
        created: false,
      };
    }
    case CREATE_AUTHORIZED_PROVIDER_SUCCESS: {
      return {
        ...state,
        creating: false,
        created: true,
        creationError: null,
      };
    }
    case CREATE_AUTHORIZED_PROVIDER_FAIL: {
      return {
        ...state,
        creating: false,
        created: false,
        creationError: action.error,
      };
    }
    case CREATE_AUTHORIZED_PROVIDER_RESET: {
      return getInitialState();
    }
    default: {
      return state;
    }
  }
};

export const createAuthorizedProvider = (authorizedProviderData) => ({
  types: [
    CREATE_AUTHORIZED_PROVIDER,
    CREATE_AUTHORIZED_PROVIDER_SUCCESS,
    CREATE_AUTHORIZED_PROVIDER_FAIL,
  ],
  request: (client) => client.post('create_authorized_provider', authorizedProviderData),
});

export const resetAddAuthorizedProvider = () => ({ type: CREATE_AUTHORIZED_PROVIDER_RESET });

export const addAuthorizedProviderSelector = (state) => state.addAuthorizedProvider;
