import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/_OrderReport/_OrderReportPerformanceMetricsMito/_OrderReportPerformanceMetricsMito.scss';

const OrderReportPerformanceMetricsMito = ({ title, performanceMetrics }) => (
  <div>
    <h3 className="h4">{title}</h3>
    <table className="table">
      <thead>
        <tr className={styles.report_heading_row}>
          <th>Panel</th>
          <th>Genes</th>
          <th>Exons</th>
          <th>Bases</th>
          <th>Bases &gt; 1000X</th>
          <th>Median coverage</th>
          <th>Percent &gt; 1000X</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(performanceMetrics).map((key) => {
          const performanceMetric = performanceMetrics[key];
          return (
            <tr key={key}>
              <td>{performanceMetric.panel_name}</td>
              <td>{performanceMetric.number_genes}</td>
              <td>{performanceMetric.number_exons}</td>
              <td>{performanceMetric.number_bases}</td>
              <td>{performanceMetric.bases_covered}</td>
              <td>{performanceMetric.median_coverage}</td>
              <td>{performanceMetric.percent_1000}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

OrderReportPerformanceMetricsMito.propTypes = {
  title: PropTypes.string.isRequired,
  performanceMetrics: PropTypes.shape({}).isRequired,
};

export default OrderReportPerformanceMetricsMito;
