import React from 'react';
import PropTypes from 'prop-types';

import styles from './Input.scss';

const ToggleInput = ({
  isChecked,
  name,
  leftValue,
  rightValue,
  ...props
}) => (
  <div className={styles.toggleWrapper}>
    <span className={!isChecked ? 'bold' : ''}>
      {leftValue}
    </span>
    <div>
      <input
        id={`${name}Toggle`}
        name={name}
        className={styles.toggle}
        type="checkbox"
        checked={isChecked}
        {...props}
      />
      <label htmlFor={`${name}Toggle`} />
    </div>
    <span className={isChecked ? 'bold' : ''}>
      {rightValue}
    </span>
  </div>
);

ToggleInput.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
};

export default ToggleInput;
