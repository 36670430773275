import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import * as support from 'redux/modules/support';
import { Yup } from 'components/Formik/validation';
import LabelInput from 'components/Formik/LabelInput';
import { SecondaryButton, TertiaryButton } from 'components';
import QuestionPropTypes from 'types/QuestionPropTypes';

import QuestionOption from './QuestionOption';
import styles from './QuestionOptions.scss';

const validationSchema = Yup.object().shape({
  question_option: Yup.string().required('Question option is required'),
});

const QuestionOptions = ({
  question, onQuestionOptionsChange,
}) => {
  const dispatch = useDispatch();
  const error = useSelector(support.questionOptionErrorSelector);

  const [formValues, setFormValues] = useState({ patient_history_id: question.question_id });

  const options = question.question_options;
  const isEdit = Boolean(formValues.question_option);

  const handleCleanUpForm = () => {
    setFormValues({ patient_history_id: question.question_id });
  };

  const handleMoveOption = async (option, moveUp = false) => {
    await dispatch(support.moveQuestionOption(option, moveUp));

    onQuestionOptionsChange();
  };

  const handleQuestionOptionFormSubmit = async (values, actions) => {
    if (isEdit) {
      await dispatch(support.updateQuestionOptions(values));
    } else {
      await dispatch(support.createQuestionOptions(values));
    }

    actions.resetForm();
    handleCleanUpForm();
    actions.setSubmitting(false);

    onQuestionOptionsChange();
  };

  const renderOptions = () => options.map((option) => (
    <QuestionOption
      key={option.question_option_id}
      option={option}
      handleEdit={(editValue) => setFormValues({ ...formValues, ...editValue })}
      handleMove={handleMoveOption}
    />
  ));

  return (
    <>
      <h2 className={styles.heading}>Option text</h2>
      {options && options.length > 0 && renderOptions()}
      <Formik
        enableReinitialize
        resetForm
        validationSchema={validationSchema}
        initialValues={formValues}
        onSubmit={handleQuestionOptionFormSubmit}
      >
        {({
          handleSubmit, isSubmitting,
        }) => (
          <Form name="optionForm" className={styles.option_form}>
            <Field
              name="question_option"
              component={LabelInput}
              label="Option text"
            />
            {error && <div className={`${styles.error} clearfix`}>{error}</div>}
            {isEdit && (
              <>
                <TertiaryButton
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  labelText="Save modifications"
                  data-id="option_modification_button"
                  additionalClass={['btn_right', 'btn_separate']}
                />
                <SecondaryButton
                  onClick={handleCleanUpForm}
                  labelText="New option"
                  additionalClass="btn_right"
                />
              </>
            )}
            {!isEdit && (
              <TertiaryButton
                onClick={handleSubmit}
                disabled={isSubmitting}
                labelText="Create new option"
                data-id="option_create_button"
                additionalClass="btn_right"
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

QuestionOptions.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
  onQuestionOptionsChange: PropTypes.func.isRequired,
};

export default QuestionOptions;
