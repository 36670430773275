import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportAppendixDownloads extends Component {
  static propTypes = {
    reportOrderFiles: PropTypes.object,
    appendixDownloadsShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    downloadReportAttachedFile: PropTypes.func,
    appendixHeading: PropTypes.string
  }

  render() {
    const {appendixHeading, reportOrderFiles, appendixDownloadsShown, onTogglePanel, downloadReportAttachedFile} = this.props;
    const styles = require('./_OrderReportAppendixDownloads.scss');

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixDownloadsShown')}>
          {appendixHeading}
          {appendixDownloadsShown ?
            <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
            <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixDownloadsShown ? '' : 'invisible'}>
          <ul className="list-unstyled">
            {reportOrderFiles && Object.keys(reportOrderFiles).map(key =>
              <li className="attached-file-text" key={key} onClick={() => downloadReportAttachedFile(key)}>{reportOrderFiles[key].info_str}</li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
