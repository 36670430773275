import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getProfileInfo, saveUserInfo } from 'redux/modules/auth';
import Page, { initPageState, handlePagePromise } from 'components/Page';
import UserNotificationsList from 'admin/components/UserNotificationsList';
import { OPT_IN_OPTIONS } from 'utils/forms';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  gettingProfileInfo: state.auth.gettingProfileInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
  saveUserInfo,
}, dispatch);

class AccountSettingsEditOptouts extends Component {
  static propTypes = {
    userInfo: PropTypes.PropTypes.shape({
      notification_opt_out_new_order: PropTypes.bool,
      notification_opt_out_test_shared: PropTypes.bool,
      notification_opt_out_order_on_hold: PropTypes.bool,
      notification_opt_out_sample_in_analysis: PropTypes.bool,
      notification_opt_out_cc: PropTypes.bool,
      notification_opt_out_results_ready: PropTypes.bool,
      notification_opt_out_follow_up_reports: PropTypes.bool,
      user_email: PropTypes.string,
    }),
    getProfileInfo: PropTypes.func.isRequired,
    saveUserInfo: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    userInfo: {},
  };

  state = initPageState();

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  fetchData = async () => {
    if (Object.keys(this.props.userInfo).length !== 0) {
      return this.formatInitalValues(this.props.userInfo);
    }
    const { result } = await this.props.getProfileInfo();
    return this.formatInitalValues(result);
  };

  formatInitalValues = (userInfo) => {
    const initialValues = { notifications: [] };
    // filter out the notification fields from profile info
    const userNotif = {
      new_order: userInfo.notification_opt_out_new_order,
      test_shared: userInfo.notification_opt_out_test_shared,
      order_on_hold: userInfo.notification_opt_out_order_on_hold,
      sample_in_analysis: userInfo.notification_opt_out_sample_in_analysis,
      cc: userInfo.notification_opt_out_cc,
      results_ready: userInfo.notification_opt_out_results_ready,
      follow_up_reports: userInfo.notification_opt_out_follow_up_reports,
      sample_received: userInfo.notification_opt_out_sample_received,
    };

    Object.keys(userNotif).forEach((index) => {
      const trimmedIndex = index.replace('notification_opt_out_', '');
      // we dont want all in the checkboxes list
      if (trimmedIndex !== 'all') {
        // reverse boolean
        if (!userNotif[index]) {
          initialValues.notifications.push(trimmedIndex);
        }
      }
    });

    return initialValues;
  };

  renderForm = () => {
    const { userInfo } = this.props;

    const { data: initialValues } = this.state;

    return (
      <div>
        {userInfo.user_email && (
          <div className="form-group">
            <UserNotificationsList
              initialValues={initialValues}
              options={OPT_IN_OPTIONS}
              adminMode={false}
              onUpdate={(checkboxes) => {
                const notifications = {};

                OPT_IN_OPTIONS.forEach((optin) => {
                  // reverse boolean because it is fun ....
                  notifications[
                    optin.value
                  ] = !checkboxes.notifications.includes(optin.value);
                });

                this.props
                  .saveUserInfo({
                    notification_opt_outs: notifications,
                  })
                  .finally(() => {
                    this.props.history.push('/account-settings');
                  });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    const { page } = this.state;

    return (
      <Page
        cols={8}
        offsetCols={1}
        previousPageLink="/account-settings"
        previousPageLabel="Account settings"
        title="Edit email notification settings"
        initialData={this.initialData}
        render={this.renderForm}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettingsEditOptouts);
