import PropTypes from 'prop-types';

const LogPropTypes = {
  date_str: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  type_str: PropTypes.string.isRequired,
  field: PropTypes.string,
  old_content: PropTypes.string,
  new_content: PropTypes.string,
  is_safe_html: PropTypes.bool.isRequired,
};

export default LogPropTypes;
