import pickBy from 'lodash/pickBy';

const GET_ORDER_STATUS = 'nucleus/GET_ORDER_STATUS';
const GET_ORDER_STATUS_SUCCESS = 'nucleus/GET_ORDER_STATUS_SUCCESS';
const GET_ORDER_STATUS_FAILURE = 'nucleus/GET_ORDER_STATUS_FAILURE';
const CHANGE_ORDER_STATUS = 'nucleus/CHANGE_ORDER_STATUS_DATA';
const CHANGE_ORDER_STATUS_SUCCESS = 'nucleus/CHANGE_ORDER_STATUS_SUCCESS';
const CHANGE_ORDER_STATUS_FAILURE = 'nucleus/CHANGE_ORDER_STATUS_FAILURE';
const GET_USERS = 'nucleus/GET_USERS';
const GET_USERS_SUCCESS = 'nucleus/GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'nucleus/GET_USERS_FAILURE';
const CREATE_USER = 'nucleus/CREATE_USER';
const CREATE_USER_SUCCESS = 'nucleus/CREATE_USER_SUCCESS';
const CREATE_USER_FAILURE = 'nucleus/CREATE_USER_FAILURE';
const UPDATE_USER = 'nucleus/UPDATE_USER';
const UPDATE_USER_SUCCESS = 'nucleus/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAILURE = 'nucleus/UPDATE_USER_FAILURE';
const ACTIVATE_USER = 'nucleus/ACTIVATE_USER';
const ACTIVATE_USER_SUCCESS = 'nucleus/ACTIVATE_USER_SUCCESS';
const ACTIVATE_USER_FAILURE = 'nucleus/ACTIVATE_USER_FAILURE';
const RESET_2FA = 'nucleus/RESET_2FA';
const RESET_2FA_SUCCESS = 'nucleus/RESET_2FA_SUCCESS';
const RESET_2FA_FAILURE = 'nucleus/RESET_2FA_FAILURE';
const DEACTIVATE_USER = 'nucleus/DEACTIVATE_USER';
const DEACTIVATE_USER_SUCCESS = 'nucleus/DEACTIVATE_USER_SUCCESS';
const DEACTIVATE_USER_FAILURE = 'nucleus/DEACTIVATE_USER_FAILURE';
const SEND_PASSWORD = 'nucleus/SEND_PASSWORD';
const SEND_PASSWORD_SUCCESS = 'nucleus/SEND_PASSWORD_SUCCESS';
const SEND_PASSWORD_FAILURE = 'nucleus/SEND_PASSWORD_FAILURE';
const RESET_CREATE_USER = 'nucleus/RESET_CREATE_USER';
const GET_HOSPITALS = 'nucleus/GET_HOSPITALS';
const GET_HOSPITALS_SUCCESS = 'nucleus/GET_HOSPITALS_SUCCESS';
const GET_HOSPITALS_FAILURE = 'nucleus/GET_HOSPITALS_FAILURE';
const GET_GROUPS = 'nucleus/GET_GROUPS';
const GET_GROUPS_SUCCESS = 'nucleus/GET_GROUPS_SUCCESS';
const GET_GROUPS_FAILURE = 'nucleus/GET_GROUPS_FAILURE';
const GET_QUESTIONS = 'nucleus/GET_QUESTIONS';
const GET_QUESTIONS_SUCCESS = 'nucleus/GET_QUESTIONS_SUCCESS';
const GET_QUESTIONS_FAILURE = 'nucleus/GET_QUESTIONS_FAILURE';
const CREATE_QUESTION = 'nucleus/CREATE_QUESTION';
const CREATE_QUESTION_SUCCESS = 'nucleus/CREATE_QUESTION_SUCCESS';
const CREATE_QUESTION_FAILURE = 'nucleus/CREATE_QUESTION_FAILURE';
const UPDATE_QUESTION = 'nucleus/UPDATE_QUESTION';
const UPDATE_QUESTION_SUCCESS = 'nucleus/UPDATE_QUESTION_SUCCESS';
const UPDATE_QUESTION_FAILURE = 'nucleus/UPDATE_QUESTION_FAILURE';
const CREATE_QUESTION_OPTION = 'nucleus/CREATE_QUESTION_OPTION';
const CREATE_QUESTION_OPTION_SUCCESS = 'nucleus/CREATE_QUESTION_OPTION_SUCCESS';
const CREATE_QUESTION_OPTION_FAILURE = 'nucleus/CREATE_QUESTION_OPTION_FAILURE';
const UPDATE_QUESTION_OPTION = 'nucleus/UPDATE_QUESTION_OPTION';
const UPDATE_QUESTION_OPTION_SUCCESS = 'nucleus/UPDATE_QUESTION_OPTION_SUCCESS';
const UPDATE_QUESTION_OPTION_FAILURE = 'nucleus/UPDATE_QUESTION_OPTION_FAILURE';
const CREATE_GROUP = 'nucleus/CREATE_GROUP';
const CREATE_GROUP_SUCCESS = 'nucleus/CREATE_GROUP_SUCCESS';
const CREATE_GROUP_FAILURE = 'nucleus/CREATE_GROUP_FAILURE';
const DELETE_GROUP = 'nucleus/DELETE_GROUP';
const DELETE_GROUP_SUCCESS = 'nucleus/DELETE_GROUP_SUCCESS';
const DELETE_GROUP_FAILURE = 'nucleus/DELETE_GROUP_FAILURE';
const RESET_CREATE_GROUP = 'nucleus/RESET_CREATE_GROUP';
const GET_USERS_OF_GROUP = 'nucleus/GET_USERS_OF_GROUP';
const GET_USERS_OF_GROUP_SUCCESS = 'nucleus/GET_USERS_OF_GROUP_SUCCESS';
const GET_USERS_OF_GROUP_FAILURE = 'nucleus/GET_USERS_OF_GROUP_FAILURE';
const ADD_USER_OF_GROUP = 'nucleus/ADD_USER_OF_GROUP';
const ADD_USER_OF_GROUP_SUCCESS = 'nucleus/ADD_USER_OF_GROUP_SUCCESS';
const ADD_USER_OF_GROUP_FAILURE = 'nucleus/ADD_USER_OF_GROUP_FAILURE';
const DELETE_USER_OF_GROUP = 'nucleus/DELETE_USER_OF_GROUP';
const DELETE_USER_OF_GROUP_SUCCESS = 'nucleus/DELETE_USER_OF_GROUP_SUCCESS';
const DELETE_USER_OF_GROUP_FAILURE = 'nucleus/DELETE_USER_OF_GROUP_FAILURE';
const GET_LAST_ORDER_SHARES = 'nucleus/GET_LAST_ORDER_SHARES';
const GET_LAST_ORDER_SHARES_SUCCESS = 'nucleus/GET_LAST_ORDER_SHARES_SUCCESS';
const GET_LAST_ORDER_SHARES_FAILURE = 'nucleus/GET_LAST_ORDER_SHARES_FAILURE';
const SHARE_ORDER_ADMIN = 'nucleus/SHARE_ORDER_ADMIN';
const SHARE_ORDER_ADMIN_SUCCESS = 'nucleus/SHARE_ORDER_ADMIN_SUCCESS';
const SHARE_ORDER_ADMIN_FAILURE = 'nucleus/SHARE_ORDER_ADMIN_FAILURE';
const GET_LAST_ORDER_TRANSFERS = 'nucleus/GET_LAST_ORDER_TRANSFERS';
const GET_LAST_ORDER_TRANSFERS_SUCCESS = 'nucleus/GET_LAST_ORDER_TRANSFERS_SUCCESS';
const GET_LAST_ORDER_TRANSFERS_FAILURE = 'nucleus/GET_LAST_ORDER_TRANSFERS_FAILURE';
const RESET_TRANSFER_ORDER = 'nucleus/RESET_TRANSFER_ORDER';
const TRANSFER_ORDERS_BY_ID = 'nucleus/TRANSFER_ORDERS_BY_ID';
const TRANSFER_ALL_ORDERS = 'nucleus/TRANSFER_ALL_ORDERS';
const TRANSFER_ORDER_SUCCESS = 'nucleus/TRANSFER_ORDER_SUCCESS';
const TRANSFER_ORDER_FAILURE = 'nucleus/TRANSFER_ORDER_FAILURE';
const REMOVE_LAB_ID = 'nucleus/REMOVE_LAB_ID';
const REMOVE_LAB_ID_SUCCESS = 'nucleus/REMOVE_LAB_ID_SUCCESS';
const REMOVE_LAB_ID_FAIL = 'nucleus/REMOVE_LAB_ID_FAIL';
const GET_RESTRICTED_PANELS = 'nucleus/GET_RESTRICTED_PANELS';
const GET_RESTRICTED_PANELS_SUCCESS = 'nucleus/GET_RESTRICTED_PANELS_SUCCESS';
const GET_RESTRICTED_PANELS_FAILURE = 'nucleus/GET_RESTRICTED_PANELS_FAILURE';
const GET_USER = 'nucleus/GET_USER';
const GET_USER_SUCCESS = 'nucleus/GET_USER_SUCCESS';
const GET_USER_FAILURE = 'nucleus/GET_USER_FAILURE';
const SET_USER = 'nucleus/SET_USER';

const initialState = {
  error: null,
  // GET_ORDER_STATUS
  orderStatus: {},
  isOrderStatusSet: false,
  settingOrderStatus: false,
  printerNames: null,
  sampleTypeOptions: null,
  // CHANGE_ORDER_STATUS
  changingOrderStatus: false,
  isOrderStatusChanged: false,
  // GET_LAST_ORDER_SHARES
  waitingOrderShares: false,
  orderShares: undefined,
  // USERS
  waitingUsers: false,
  users: undefined,
  waitingCreateUser: false,
  doneCreateUser: false,
  waitingUpdateUser: false,
  doneUpdateUser: false,
  selectedUser: null,
  // SEND_PASSWORD
  sendingPassword: false,
  isPasswordSent: false,
  // HOSPITALS
  hospitals: [],
  waitingCreateHospital: false,
  doneCreateHospital: false,
  // GROUPS
  groups: [],
  waitingCreateGroup: false,
  doneCreateGroup: false,
  usersOfGroup: [],
  userOfGroupError: null,
  waitingUsersOfGroup: false,
  // SHARE_ORDER_ADMIN
  waitingShareOrderAdmin: false,
  doneShareOrderAdmin: false,
  // GET_LAST_ORDER_TRANSFERS
  waitingOrderTransfers: false,
  orderTransfers: undefined,
  // TRANSFER_ORDER
  waitingTransferOrder: false,
  doneTransferOrder: false,
  confirmationQuestion: null,
  // GET_RESTRICTED_PANELS
  restrictedPanels: [],
  // QUESTIONS
  waitingCreateQuestion: false,
  doneCreateQuestion: false,
};

function objectToArray(object) {
  return Object.keys(object).map((key) => object[key]);
}

function objectToSortedArray(object) {
  return Object
    .keys(object)
    .map((key) => object[key])
    .sort((element1, element2) => (element1.order_no - element2.order_no));
}

function pickOrderStatus(result) {
  return pickBy(result, (value, key) => key !== 'printer_names' && key !== 'sample_type_translations');
}

export default function support(state = initialState, action = {}) {
  switch (action.type) {
    //
    // Order status
    //

    case GET_ORDER_STATUS:
      return {
        ...state,
        isOrderStatusSet: false,
        settingOrderStatus: true,
        error: null,
      };
    case GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        settingOrderStatus: false,
        orderStatus: pickOrderStatus(action.result),
        printerNames: action.result && action.result.printer_names,
        sampleTypeOptions: action.result && action.result.sample_type_translations,
        isOrderStatusSet: true,
        error: null,
      };
    case GET_ORDER_STATUS_FAILURE:
      return {
        ...state,
        settingOrderStatus: false,
        isOrderStatusSet: false,
        orderStatus: null,
        error: action.error,
      };

    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        changingOrderStatus: true,
        isOrderStatusChanged: false,
        error: null,
      };
    case CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        changingOrderStatus: false,
        isOrderStatusChanged: true,
        error: null,
      };
    case CHANGE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        changingOrderStatus: false,
        isOrderStatusChanged: false,
        error: action.error,
      };

    //
    // Users
    //
    case GET_USERS:
      return {
        ...state,
        error: null,
        waitingUsers: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: objectToArray(action.result),
        waitingUsers: false,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingUsers: false,
      };
    case CREATE_USER:
      return {
        ...state,
        error: null,
        waitingCreateUser: true,
        doneCreateUser: false,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        waitingCreateUser: false,
        doneCreateUser: true,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingCreateUser: false,
        doneCreateUser: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        error: null,
        waitingUpdateUser: true,
        doneUpdateUser: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        waitingUpdateUser: false,
        doneUpdateUser: true,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingUpdateUser: false,
        doneUpdateUser: false,
      };
    case ACTIVATE_USER:
      return {
        ...state,
        error: null,
        waitingActivateUser: true,
        doneActivateUser: false,
      };
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        waitingActivateUser: false,
        doneActivateUser: true,
      };
    case ACTIVATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingActivateUser: false,
        doneActivateUser: false,
      };

    case DEACTIVATE_USER:
      return {
        ...state,
        error: null,
        waitingDeactivateUser: true,
        doneDeactivateUser: false,
      };
    case DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        waitingDeactivateUser: false,
        doneDeactivateUser: true,
      };
    case DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingDeactivateUser: false,
        doneDeactivateUser: false,
      };

    case RESET_2FA:
      return {
        ...state,
        error: null,
        waitingReset2FA: true,
        doneReset2FA: false,
      };
    case RESET_2FA_SUCCESS:
      return {
        ...state,
        waitingReset2FA: false,
        doneReset2FA: true,
      };
    case RESET_2FA_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingReset2FA: false,
        doneReset2FA: false,
      };

    case RESET_CREATE_USER:
      return {
        ...state,
        selectedUser: null,
        error: null,
        waitingCreateUser: false,
        doneCreateUser: false,
        sendingPassword: false,
        isPasswordSent: false,
        waitingActivateUser: false,
        doneActivateUser: false,
        waitingDeactivateUser: false,
        doneDeactivateUser: false,
        waitingReset2FA: false,
        doneReset2FA: false,
      };

    case GET_USER:
      return {
        ...state,
        error: null,
        selectedUser: null,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.result,
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case SET_USER:
      return {
        ...state,
        selectedUser: action.data,
      };

    //
    // Passwords
    //
    case SEND_PASSWORD:
      return {
        ...state,
        error: null,
        sendingPassword: true,
        isPasswordSent: false,
      };
    case SEND_PASSWORD_SUCCESS:
      return {
        ...state,
        sendingPassword: false,
        isPasswordSent: true,
      };
    case SEND_PASSWORD_FAILURE:
      return {
        ...state,
        sendingPassword: false,
        isPasswordSent: false,
        error: action.error,
      };
    //
    // Hospitals
    //
    case GET_HOSPITALS:
      return {
        ...state,
        error: null,
        waitingHospitals: true,
      };
    case GET_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospitals: objectToArray(action.result),
        waitingHospitals: false,
      };
    case GET_HOSPITALS_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingHospitals: false,
      };

    //
    // Groups
    //
    case GET_GROUPS:
      return {
        ...state,
        error: null,
        waitingGroups: true,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: objectToArray(action.result),
        waitingGroups: false,
      };
    case GET_GROUPS_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingGroups: false,
      };
    case CREATE_GROUP:
      return {
        ...state,
        error: null,
        waitingCreateGroup: true,
        doneCreateGroup: false,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        waitingCreateGroup: false,
        doneCreateGroup: true,
      };
    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingCreateGroup: false,
        doneCreateGroup: false,
      };
    case DELETE_GROUP:
      return {
        ...state,
        error: null,
        waitingDeleteGroup: true,
        doneDeleteGroup: false,
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        waitingDeleteGroup: false,
        doneDeleteGroup: true,
      };
    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingDeleteGroup: false,
        doneDeleteGroup: false,
      };
    case RESET_CREATE_GROUP:
      return {
        ...state,
        selectedGroup: null,
        usersOfGroup: [],
        error: null,
        userOfGroupError: null,
        waitingCreateGroup: false,
        doneCreateGroup: false,
        waitingDeleteGroup: false,
        doneDeleteGroup: false,
      };
    case GET_USERS_OF_GROUP:
      return {
        ...state,
        error: null,
        waitingUsersOfGroup: true,
      };
    case GET_USERS_OF_GROUP_SUCCESS:
      return {
        ...state,
        usersOfGroup: objectToArray(action.result),
        waitingUsersOfGroup: false,
      };
    case GET_USERS_OF_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingUsersOfGroup: false,
      };
    case ADD_USER_OF_GROUP:
      return {
        ...state,
        userOfGroupError: null,
        waitingAddUserOfGroup: true,
        doneAddUserOfGroup: false,
      };
    case ADD_USER_OF_GROUP_SUCCESS:
      return {
        ...state,
        waitingAddUserOfGroup: false,
        doneAddUserOfGroup: true,
      };
    case ADD_USER_OF_GROUP_FAILURE:
      return {
        ...state,
        userOfGroupError: action.error,
        waitingAddUserOfGroup: false,
        doneAddUserOfGroup: false,
      };
    case DELETE_USER_OF_GROUP:
      return {
        ...state,
        error: null,
        waitingDeleteUserOfGroup: true,
        doneDeleteUserOfGroup: false,
      };
    case DELETE_USER_OF_GROUP_SUCCESS:
      return {
        ...state,
        waitingDeleteUserOfGroup: false,
        doneDeleteUserOfGroup: true,
      };
    case DELETE_USER_OF_GROUP_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingDeleteUserOfGroup: false,
        doneDeleteUserOfGroup: false,
      };
      //
      // Order shares
      //

    case GET_LAST_ORDER_SHARES:
      return {
        ...state,
        error: null,
        waitingOrderShares: true,
      };
    case GET_LAST_ORDER_SHARES_SUCCESS:
      return {
        ...state,
        orderShares: objectToSortedArray(action.result),
        waitingOrderShares: false,
      };
    case GET_LAST_ORDER_SHARES_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingOrderShares: false,
      };
    case SHARE_ORDER_ADMIN:
      return {
        ...state,
        error: null,
        waitingShareOrderAdmin: true,
        doneShareOrderAdmin: false,
      };
    case SHARE_ORDER_ADMIN_SUCCESS:
      return {
        ...state,
        error: null,
        waitingShareOrderAdmin: false,
        doneShareOrderAdmin: true,
      };
    case SHARE_ORDER_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingShareOrderAdmin: false,
      };

      //
      // Order transfers
      //

    case GET_LAST_ORDER_TRANSFERS:
      return {
        ...state,
        error: null,
        waitingOrderTransfers: true,
      };
    case GET_LAST_ORDER_TRANSFERS_SUCCESS:
      return {
        ...state,
        error: null,
        orderTransfers: objectToSortedArray(action.result),
        waitingOrderTransfers: false,
      };
    case GET_LAST_ORDER_TRANSFERS_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingOrderTransfers: false,
      };
    case RESET_TRANSFER_ORDER:
      return {
        ...state,
        error: null,
        confirmationQuestion: null,
      };
    case TRANSFER_ORDERS_BY_ID:
      return {
        ...state,
        error: null,
        waitingTransferOrder: true,
        doneTransferOrder: false,
        confirmationQuestion: null,
      };
    case TRANSFER_ALL_ORDERS:
      return {
        ...state,
        error: null,
        waitingTransferOrder: true,
        doneTransferOrder: false,
        confirmationQuestion: null,
      };
    case TRANSFER_ORDER_SUCCESS:
      return {
        ...state,
        error: null,
        waitingTransferOrder: false,
        doneTransferOrder: true,
        confirmationQuestion: action.result && action.result.question,
      };
    case TRANSFER_ORDER_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingTransferOrder: false,
      };
    case REMOVE_LAB_ID:
      return {
        ...state,
        deleting: true,
        deleted: false,
        error: null,
      };
    case REMOVE_LAB_ID_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: true,
      };
    case REMOVE_LAB_ID_FAIL:
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.error,
      };
    case GET_RESTRICTED_PANELS:
      return {
        ...state,
        error: null,
      };
    case GET_RESTRICTED_PANELS_SUCCESS:
      return {
        ...state,
        restrictedPanels: action.result.restricted_products,
      };
    case GET_RESTRICTED_PANELS_FAILURE:
      return {
        ...state,
        restrictedPanels: [],
        error: action.error,
      };

      //
      // Questions
      //

    case GET_QUESTIONS:
      return {
        ...state,
        error: null,
      };
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.result.questions,
      };
    case GET_QUESTIONS_FAILURE:
      return {
        ...state,
        questions: [],
        error: action.error,
      };
    case CREATE_QUESTION:
      return {
        ...state,
        error: null,
        waitingCreateQuestion: true,
        doneCreateQuestion: false,
      };
    case CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        waitingCreateQuestion: false,
        doneCreateQuestion: true,
      };
    case CREATE_QUESTION_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingCreateQuestion: false,
        doneCreateQuestion: false,
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        error: null,
        waitingCreateQuestion: true,
        doneCreateQuestion: false,
      };
    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        waitingCreateQuestion: false,
        doneCreateQuestion: true,
      };
    case UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        error: action.error,
        waitingCreateQuestion: false,
        doneCreateQuestion: false,
      };
    case CREATE_QUESTION_OPTION:
      return {
        ...state,
        optionError: null,
        waitingCreateQuestionOption: true,
        doneCreateQuestionOption: false,
      };
    case CREATE_QUESTION_OPTION_SUCCESS:
      return {
        ...state,
        waitingCreateQuestionOption: false,
        doneCreateQuestionOption: true,
      };
    case CREATE_QUESTION_OPTION_FAILURE:
      return {
        ...state,
        optionError: action.error,
        waitingUpdateQuestionOption: false,
        doneCreateQuestionOption: false,
      };
    case UPDATE_QUESTION_OPTION:
      return {
        ...state,
        optionError: null,
        waitingCreateQuestionOption: true,
        doneCreateQuestionOption: false,
      };
    case UPDATE_QUESTION_OPTION_SUCCESS:
      return {
        ...state,
        waitingCreateQuestionOption: false,
        doneCreateQuestionOption: true,
      };
    case UPDATE_QUESTION_OPTION_FAILURE:
      return {
        ...state,
        optionError: action.error,
        waitingCreateQuestionOption: false,
        doneCreateQuestionOption: false,
      };
    default:
      return state;
  }
}

export function getOrderStatus(data) {
  return {
    types: [GET_ORDER_STATUS, GET_ORDER_STATUS_SUCCESS, GET_ORDER_STATUS_FAILURE],
    request: (client) => client.get(`get_order_status/${data.order_id}`),
  };
}

export function changeOrderStatus(data) {
  return {
    types: [CHANGE_ORDER_STATUS, CHANGE_ORDER_STATUS_SUCCESS, CHANGE_ORDER_STATUS_FAILURE],
    request: (client) => client.post('change_order_status', data),
  };
}

export function getUsers(showDeactivated) {
  return {
    types: [GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    request: (client) => client.get('get_users', {
      show_deactivated: showDeactivated,
    }),
  };
}

export function getUser(userId) {
  return {
    types: [GET_USER, GET_USER_SUCCESS, GET_USER_FAILURE],
    request: (client) => client.get('get_user', {
      user_id: userId,
    }),
  };
}

export function createUser(data) {
  return {
    types: [CREATE_USER, CREATE_USER_SUCCESS, CREATE_USER_FAILURE],
    request: (client) => client.post('create_user', data),
  };
}

export function updateUser(data) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    request: (client) => client.post('update_user', data),
  };
}

export function activateUser(data) {
  return {
    types: [ACTIVATE_USER, ACTIVATE_USER_SUCCESS, ACTIVATE_USER_FAILURE],
    request: (client) => client.post('activate_user', data),
  };
}

export function deactivateUser(data) {
  return {
    types: [DEACTIVATE_USER, DEACTIVATE_USER_SUCCESS, DEACTIVATE_USER_FAILURE],
    request: (client) => client.post('deactivate_user', data),
  };
}

export function reset2FA(data) {
  return {
    types: [RESET_2FA, RESET_2FA_SUCCESS, RESET_2FA_FAILURE],
    request: (client) => client.post('reset_two_factor_token', data),
  };
}

export function resetCreateUser() {
  return { type: RESET_CREATE_USER };
}

export function sendPassword(data) {
  return {
    types: [SEND_PASSWORD, SEND_PASSWORD_SUCCESS, SEND_PASSWORD_FAILURE],
    request: (client) => client.post('send_new_password', data),
  };
}

export function getHospitals() {
  return {
    types: [GET_HOSPITALS, GET_HOSPITALS_SUCCESS, GET_HOSPITALS_FAILURE],
    request: (client) => client.get('get_hospitals'),
  };
}

export function getHospital(hospitalId) {
  return {
    request: (client) => client.get('get_hospital', {
      hospital_id: hospitalId,
    }),
  };
}

export function saveHospital(data) {
  return {
    request: (client) => client.post('save_hospital', data),
  };
}

export function deleteHospital(hospitalId) {
  return {
    request: (client) => client.post('delete_hospital', {
      id: hospitalId,
    }),
  };
}

export function getHospitalProduct(hospitalId) {
  return {
    request: (client) => client.get('get_all_products', {
      hospital_id: hospitalId,
    }),
  };
}

export function setHospitalProductVisibility({ hospitalId, products }) {
  return {
    request: (client) => client.post('set_product_visibility', {
      hospital_id: hospitalId,
      products,
    }),
  };
}

export function getGroups() {
  return {
    types: [GET_GROUPS, GET_GROUPS_SUCCESS, GET_GROUPS_FAILURE],
    request: (client) => client.get('get_groups'),
  };
}

export function createGroup(data) {
  return {
    types: [CREATE_GROUP, CREATE_GROUP_SUCCESS, CREATE_GROUP_FAILURE],
    request: (client) => client.post('save_group', data),
  };
}

export function deleteGroup(data) {
  return {
    types: [DELETE_GROUP, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILURE],
    request: (client) => client.post('delete_group', data),
  };
}

export function resetCreateGroup() {
  return { type: RESET_CREATE_GROUP };
}

export function getUsersOfGroup(data) {
  return {
    types: [GET_USERS_OF_GROUP, GET_USERS_OF_GROUP_SUCCESS, GET_USERS_OF_GROUP_FAILURE],
    request: (client) => client.post('get_users_of_group', data),
  };
}

export function addUserOfGroup(data) {
  return {
    types: [ADD_USER_OF_GROUP, ADD_USER_OF_GROUP_SUCCESS, ADD_USER_OF_GROUP_FAILURE],
    request: (client) => client.post('save_user_of_group', data),
  };
}

export function deleteUserOfGroup(data) {
  return {
    types: [DELETE_USER_OF_GROUP, DELETE_USER_OF_GROUP_SUCCESS, DELETE_USER_OF_GROUP_FAILURE],
    request: (client) => client.post('delete_user_of_group', data),
  };
}

export function getLastOrderShares() {
  return {
    types: [GET_LAST_ORDER_SHARES, GET_LAST_ORDER_SHARES_SUCCESS, GET_LAST_ORDER_SHARES_FAILURE],
    request: (client) => client.get('get_last_order_shares'),
  };
}

export function shareOrderAdmin(data) {
  return {
    types: [SHARE_ORDER_ADMIN, SHARE_ORDER_ADMIN_SUCCESS, SHARE_ORDER_ADMIN_FAILURE],
    request: (client) => client.post('share_order_admin', {
      order_id: data.orderId,
      email_target_list: data.emailTargetList,
    }),
  };
}

export function shareAllOrdersAdmin(data) {
  return {
    types: [SHARE_ORDER_ADMIN, SHARE_ORDER_ADMIN_SUCCESS, SHARE_ORDER_ADMIN_FAILURE],
    request: (client) => client.post('share_all_orders_admin', {
      email_source: data.emailSource,
      email_target_list: data.emailTargetList,
      is_share_orders: data.isShareOrders,
      is_share_orders_superuser: data.isShareOrdersSuperuser,
    }),
  };
}

export function getLastOrderTransfers() {
  return {
    types: [
      GET_LAST_ORDER_TRANSFERS,
      GET_LAST_ORDER_TRANSFERS_SUCCESS,
      GET_LAST_ORDER_TRANSFERS_FAILURE,
    ],
    request: (client) => client.get('get_last_order_transfers'),
  };
}

export function resetTransferOrder() {
  return { type: RESET_TRANSFER_ORDER };
}

export function transferOrdersById(data) {
  return {
    types: [TRANSFER_ORDERS_BY_ID, TRANSFER_ORDER_SUCCESS, TRANSFER_ORDER_FAILURE],
    request: (client) => client.post('transfer_orders_by_id', {
      alias_email: data.alias_email,
      email: data.email_target,
      force: data.force,
      only_test: data.only_test,
      order_ids_string: data.order_ids_string,
    }),
  };
}

export function transferAllOrders(data) {
  return {
    types: [TRANSFER_ALL_ORDERS, TRANSFER_ORDER_SUCCESS, TRANSFER_ORDER_FAILURE],
    request: (client) => client.post('transfer_all_orders', {
      alias_email: data.alias_email,
      email_source: data.email_source,
      email_target: data.email_target,
      force: data.force,
      only_test: data.only_test,
    }),
  };
}

export function removeLabId(data) {
  return {
    types: [REMOVE_LAB_ID, REMOVE_LAB_ID_SUCCESS, REMOVE_LAB_ID_FAIL],
    request: (client) => client.post('remove_lab_id', data),
  };
}

export function reprintLabId(orderId, labId, printerName) {
  return {
    request: (client) => client.post('reprint_lab_id', {
      order_id: orderId,
      lab_id: labId,
      printer_name: printerName,
    }),
  };
}

export function getRestrictedPanels() {
  return {
    types: [GET_RESTRICTED_PANELS, GET_RESTRICTED_PANELS_SUCCESS, GET_RESTRICTED_PANELS_FAILURE],
    request: (client) => client.get('get_restricted_panels'),
  };
}

export function createBlankOrder() {
  return {
    request: (client) => client.post('create_blank_order', {
      type: 'blank',
    }),
  };
}

export function convertBlankOrder(data) {
  return {
    request: (client) => client.post('convert_blank_order', {
      order_id: data.orderId,
      product_id: data.productId,
      gene_collection_id: data.geneCollectionID,
      panel_tier_id: data.panelTierID,
    }),
  };
}

export function getQuestions() {
  return {
    types: [GET_QUESTIONS, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    request: (client) => client.get('get_questions'),
  };
}

export function getQuestion(questionId) {
  return {
    request: (client) => client.get('get_question', {
      question_id: questionId,
    }),
  };
}

export function createQuestion(data) {
  return {
    types: [CREATE_QUESTION, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_FAILURE],
    request: (client) => client.post('crud_patient_history', {
      op: 'create',
      patient_history_id: data.patient_history_id,
      name: data.name,
      question: data.question,
      type: data.type,
      unit: data.unit,
      allow_user_defined_unit: data.allow_user_defined_unit,
      free_text_option_question: data.free_text_option_question,
      hint: data.hint,
      is_common_question: data.is_common_question,
      default_order_no: data.default_order_no || 0,
      default_page_no: data.default_page_no || 0,
      default_is_compulsory: data.default_is_compulsory,
      deprecated: true,
    }),
  };
}

export function updateQuestion(data) {
  return {
    types: [UPDATE_QUESTION, UPDATE_QUESTION_SUCCESS, UPDATE_QUESTION_FAILURE],
    request: (client) => client.post('crud_patient_history', {
      op: 'update',
      patient_history_id: data.id || data.question_id,
      name: data.name,
      question: data.question,
      type: data.type,
      unit: data.unit,
      allow_user_defined_unit: data.allow_user_defined_unit,
      free_text_option_question: data.free_text_option_question,
      hint: data.hint,
      is_common_question: data.is_common_question,
      default_order_no: data.default_order_no,
      default_page_no: data.default_page_no,
      default_is_compulsory: data.default_is_compulsory,
      deprecated: true,
    }),
  };
}

export function getQuestionOptions(questionId) {
  return {
    request: (client) => client.get('get_question_options', {
      question_id: questionId,
    }),
  };
}

export function createQuestionOptions(data) {
  return {
    types: [CREATE_QUESTION_OPTION, CREATE_QUESTION_OPTION_SUCCESS, CREATE_QUESTION_OPTION_FAILURE],
    request: (client) => client.post('crud_patient_history_option', {
      op: 'create',
      history_option: data.question_option,
      patient_history_id: data.patient_history_id,
    }),
  };
}

export function updateQuestionOptions(data) {
  return {
    types: [UPDATE_QUESTION_OPTION, UPDATE_QUESTION_OPTION_SUCCESS, UPDATE_QUESTION_OPTION_FAILURE],
    request: (client) => client.post('crud_patient_history_option', {
      op: 'update',
      patient_history_option_id: data.question_option_id,
      patient_history_id: data.patient_history_id,
      history_option: data.question_option,
    }),
  };
}

export function moveQuestionOption(optionId, moveUp) {
  return {
    request: (client) => client.post('move_patient_history_option', {
      patient_history_option_id: optionId,
      move_up: moveUp,
    }),
  };
}

export const supportErrorSelector = (state) => state.support.error;

export const questionOptionErrorSelector = (state) => state.support.optionError;

export const selectedUserSelector = (state) => state.support.selectedUser;

export const restrictedPanelsSelector = (state) => state.support.restrictedPanels;

export const hospitalsSelector = (state) => state.support.hospitals;

export const waitingCreateUserSelector = (state) => state.support.waitingCreateUser;

export const doneCreateUserSelector = (state) => state.support.doneCreateUser;

export const waitingUpdateUserSelector = (state) => state.support.waitingUpdateUser;

export const doneUpdateUserSelector = (state) => state.support.doneUpdateUser;

export const isPasswordSentSelector = (state) => state.support.isPasswordSent;

export const doneActivateUserSelector = (state) => state.support.doneActivateUser;

export const doneDeactivateUserSelector = (state) => state.support.doneDeactivateUser;

export const doneReset2FAUserSelector = (state) => state.support.doneReset2FA;

export const waitingShareOrderAdminSelector = (state) => state.support.waitingShareOrderAdmin;

export const doneShareOrderAdminSelector = (state) => state.support.doneShareOrderAdmin;

export const waitingOrderSharesSelector = (state) => state.support.waitingOrderShares;

export const orderSharesSelector = (state) => state.support.orderShares;
