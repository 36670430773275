import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CountrySelect = ({
  options,
  disabled,
  readOnly,
  placeholder,
  value,
  noFormGroupWrap,
  touched,
  error,
  ...props
}) => (
  <div className={classNames({
    'form-group': !noFormGroupWrap,
    'has-error': (props.error && props.touched),
  })}
  >
    <div className="select">
      <span className="arrow" />
      <select
        className="form-control"
        value={value || ''}
        readOnly={readOnly}
        disabled={disabled}
        {...props}
      >
        {placeholder && (
          <option value="">{placeholder}</option>
        )}

        {options.map((option) => (
          <option key={option.id} value={option.id}>{option.name}</option>
        ))}

      </select>

      {touched && error && (
        <div><p className="text-danger">{error}</p></div>
      )}
    </div>
  </div>
);

CountrySelect.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  noFormGroupWrap: PropTypes.bool,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

CountrySelect.defaultProps = {
  disabled: false,
  readOnly: false,
  value: '',
  options: [],
  placeholder: null,
  noFormGroupWrap: false,
  error: null,
  touched: false,
};

export default CountrySelect;
