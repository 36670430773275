import React from 'react';
import PropTypes from 'prop-types';

export default function CompletedOrderCustomerInformation(props) {
  const orderInfo = props.orderInfo;
  return (
    <div>
      <div>
        <label className="view_order_modal_label">Institution</label>
        <span>{orderInfo && orderInfo.hospital_name ? orderInfo.hospital_name : 'N/A'}</span>
      </div>

      <div>
        <label className="view_order_modal_label">Name of ordering health care professional</label>
        <span>{orderInfo && orderInfo.user_name ? orderInfo.user_name : 'N/A'}</span>
      </div>

      <div>
        <label className="view_order_modal_label">Email of ordering health care professional</label>
        <span>{orderInfo && orderInfo.user_email ? orderInfo.user_email : 'N/A'}</span>
      </div>
    </div>
  );
}

/* eslint-disable react/require-default-props, react/forbid-prop-types */
CompletedOrderCustomerInformation.propTypes = {
  orderInfo: PropTypes.object,
};
/* eslint-enable react/require-default-props, react/forbid-prop-types */
