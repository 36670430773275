import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from 'components/StyleComponents/Button/PrimaryButton';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';

import styles from './ConfirmModal.scss';

const ConfirmModal = ({
  onConfirm,
  onCancel,
  show,
  messageHeader,
  confirmCaption,
  cancelCaption,
  children,
  ...props
}) => (
  <Modal {...props} show={show} onHide={onCancel} dialogClassName="confirm-dialog" backdropClassName={styles.backdrop} size="lg" aria-labelledby="contained-modal-title-sm" keyboard>
    <Modal.Header>
      {messageHeader}
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    <Modal.Footer>
      <SecondaryButton labelText={cancelCaption} onClick={onCancel} />
      <PrimaryButton labelText={confirmCaption} onClick={onConfirm} />
    </Modal.Footer>
  </Modal>
);

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  messageHeader: PropTypes.string,
  confirmCaption: PropTypes.string,
  cancelCaption: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ConfirmModal.defaultProps = {
  messageHeader: null,
  confirmCaption: 'Continue',
  cancelCaption: 'Cancel',
};

export default ConfirmModal;
