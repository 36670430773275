import React from 'react';
import { LabelRadioDisplay } from 'components';

export const yesOrNo = (value) => {
  const yesOrNoObj = {
    true: 'Yes',
    false: 'No',
  };
  return yesOrNoObj[value];
};

export const capitalise = (value) => {
  if (value === undefined || value === '') {
    return '';
  }

  if (value.length === 1) {
    return value.toUpperCase();
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isThisFetalSample = (orderInfo) => ['yes', 'index', 'family_member_one', 'family_member_two'].includes(orderInfo.is_this_a_fetal_sample);

export const fetalSampleAnswer = (value) => {
  const fetalSampleAnswerObj = {
    yes: 'Yes',
    index: 'Yes - Index',
    family_member_one: 'Yes - Family member 1',
    family_member_two: 'Yes - Family member 2',
  };
  return capitalise(fetalSampleAnswerObj[value] ?? value);
};

export const renderQuestion = (question) => {
  switch (question.type) {
    case 'text':
    case 'number':
    case 'textarea':
    case 'dob':
      return (
        <div>
          <label className="view_order_modal_label">{question.question}</label>
          <span>
            {`${question.answer ? question.answer : 'N/A'} ${question.unit ? question.unit : ''}`}
          </span>
        </div>
      );

    case 'select': {
      const selectedOption = () => question.question_options
        .find((questionOption) => questionOption.selected);
      return (
        <div>
          <label className="view_order_modal_label">{question.question}</label>
          <span>
            {selectedOption ? selectedOption.question_option : ''}
            <br />
            {question.free_text_option_answer ? question.free_text_option_answer : ''}
          </span>
        </div>
      );
    }

    case 'radio': {
      return (
        <div key={question.question_id}>
          <LabelRadioDisplay
            label={question.question}
            labelSize="6"
            inputSize="6"
            hint={question.hint}
            options={question.question_options}
            disabled
          />
        </div>
      );
    }

    default:
      return null;
  }
};
