import React from 'react';
import PropTypes from 'prop-types';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import { isExome, isScreening, isSpark } from 'utils/forms';
import { isUsRegion } from 'utils/envUtils';

const confirmationText = (
  <span>
    {' I have read and understood the '}
    <a
      className="link-pink"
      href="//blueprintgenetics.com/general-terms/"
      rel="noopener noreferrer"
      target="_blank"
    >
      General Terms of Service
    </a>
    .
  </span>
);

const confirmationTextComplianceLatamAndUS = (
  <span>
    To ensure compliance with state law, verification of patient informed consent
    is required for genetic testing. Testing laboratories located in Massachusetts
    require a signed acknowledgment from the ordering medical practitioner.
    This signed acknowledgment is required to complete the genetic testing you’ve ordered:
    I acknowledge that prior to ordering genetic testing on the patient listed above,
    <b>
      {' '}
      I have obtained a signed, written consent form from the patient
      (or their authorized representative)
      as required by applicable state law and/or regulations
    </b>
    . Additionally, I will maintain all
    written consent forms as part of the patient file and will make them available to
    Blueprint Genetics upon reasonable request.
    <br />
    <br />
  </span>
);

const confirmationTextSparkOld = (
  <span>
    I agree I will not bill the patient or their insurance for the genetic
    counseling or genetic testing services offered as part of this program.
    I have read and understood the
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      General Terms of Service
    </a>
    {' '}
    . I authorize Blueprint Genetics and Foundation Fighting Blindness to
    contact me by mail, email or phone to inform me about ongoing clinical
    trials, clinical studies, IRD related programs, services and products
    that relate to the patients who have received a genetic test. I
    understand that I may revoke this authorization by contacting
    {' '}
    <a href="//blueprintgenetics.com/contact/" className="link-pink" target="_blank" rel="noopener noreferrer">
      Blueprint Genetics Client Services
    </a>
    {' '}
    and that in revoking authorization I will not be able to use the no cost
    My Retina Tracker Genetic Testing Panel.
  </span>
);

const confirmationTextSparkOldLatamUS = (
  <span>
    I agree I will not bill the patient or their insurance for the genetic
    counseling or genetic testing services offered as part of this program.
    I have read and understood and accept the
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      General Terms of Service
    </a>
    {' '}
    and the
    {' '}
    <a href="//blueprintgenetics.com/wp-content/uploads/2020/09/BpG-Genetic-Testing-Program-Terms-2020.pdf" className="link-pink" target="_blank" rel="noopener noreferrer">
      Genetic Testing Program Terms
    </a>
    , as currently posted. I authorize Blueprint Genetics, Foundation Fighting Blindness
    and Scientific Collaborators to contact me by mail,
    email or phone to inform me about ongoing clinical trials,
    clinical studies, IRD related programs, services and products
    that relate to the patients who have received a genetic test. I
    understand that I may revoke this authorization by contacting
    {' '}
    <a href="//blueprintgenetics.com/contact/" className="link-pink" target="_blank" rel="noopener noreferrer">
      Blueprint Genetics Client Services
    </a>
    {' '}
    and that in revoking authorization I will not be able to participate
    in the Genetic Testing Program.
  </span>
);

const confirmationTextSparkNew = (
  <span>
    I agree I will not bill the patient or their insurance for the genetic
    counseling or genetic testing services offered as part of this program.
    I have read and understood the
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      General Terms of Service
    </a>
    {' '}
    and the
    {' '}
    <a href="//blueprintgenetics.com/wp-content/uploads/2020/09/BpG-Genetic-Testing-Program-Terms-2020.pdf" className="link-pink" target="_blank" rel="noopener noreferrer">
      Genetic Testing Program Terms
    </a>
    {' '}
    . I authorize Blueprint Genetics, Foundation Fighting Blindness and
    Scientific Collaborators to contact me by mail, email or phone to inform me
    about ongoing clinical trials, clinical studies, IRD related programs, services
    and products that relate to the patients who have received a genetic test.
    I understand that I may revoke this authorization by contacting Blueprint Genetics
    Client Services and that in revoking authorization I will not be able to
    participate in the Genetic Testing Program.
  </span>
);

const confirmationTextSparkAfterQuestIntegration = (
  <span>
    I agree I will not bill the patient or their insurance for the genetic counseling
    or genetic testing services offered as part of this program. I have read and
    understood and accept the
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      General Terms of Service
    </a>
    {' '}
    and the
    {' '}
    <a href="//blueprintgenetics.com/wp-content/uploads/2020/09/BpG-Genetic-Testing-Program-Terms-2020.pdf" className="link-pink" target="_blank" rel="noopener noreferrer">
      Genetic Testing Program Terms
    </a>
    {' '}
    , as currently posted. I authorize Blueprint Genetics, Foundation Fighting
    Blindness and Scientific Collaborators to contact me by mail, email or phone
    to inform me about ongoing clinical trials, clinical studies, IRD related programs,
    services and products that relate to the patients who have received a genetic test.
    I understand that I may revoke this authorization by contacting
    {' '}
    <a href="//blueprintgenetics.com/contact/" className="link-pink" target="_blank" rel="noopener noreferrer">
      Blueprint Genetics Client Services
    </a>
    {' '}
    and that in revoking authorization I will not be able to participate in the
    Genetic Testing Program.
  </span>
);

const confirmationTextExome = (
  <span>
    I have discussed the Informed Consent for Whole Exome Sequencing with the
    patient or their legal guardian and possible family members included in
    the test (Whole Exome Family products). I have obtained any other consent
    from the patient and family members that is required under the laws of my
    country/state and/or federal laws. I certify that the test ordered is medically
    necessary for the diagnosis or detection of a disease, illness, impairment,
    symptom, syndrome or disorder. The results of this test will be used in the
    medical management of the patient and/or genetic counseling of the patient
    and family member(s). I have read and understood Blueprint Genetics’
    General Terms of Service, as currently posted at
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      https://blueprintgenetics.com/general-terms/
    </a>
    {' '}
    . Unless there is a written agreement between the Institution and Blueprint Genetics,
    I accept, and have the authority to accept, these General Terms of Service
    on behalf of the Institution.
  </span>
);

const confirmationTextBasePanels = (
  <>
    <span>
      I have discussed the Informed Consent with the patient or their
      legal guardian and obtained any other consent from the patient that
      is required under the laws of my country/state and/or federal laws.
      I certify that the test ordered is medically necessary for the
      diagnosis or detection of a disease, illness, impairment, symptom,
      syndrome or disorder. The results of this test will be used in the
      medical management of the patient and/or genetic counseling of the
      patient and family member(s).
    </span>
    {confirmationText}
  </>
);

const confirmationTextBasePanelsAfterQuestIntegration = (
  <span>
    I have discussed the Informed Consent with the patient or their legal
    guardian and obtained any other consent from the patient that is
    required under the laws of my country/state and/or federal laws. I
    certify that the test ordered is medically necessary for the diagnosis
    or detection of a disease, illness, impairment, symptom, syndrome or
    disorder. The results of this test will be used in the medical management
    of the patient and/or genetic counseling of the patient and family member(s).
    I have read and understood Blueprint Genetics’ General Terms of Service,
    as currently posted at
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      https://blueprintgenetics.com/general-terms/
    </a>
    {' '}
    .  Unless there is a written agreement between the Institution and
    Blueprint Genetics, I accept, and have the authority to accept,
    these General Terms of Service on behalf of the Institution.
  </span>

);

const confirmationTextBasePanelsLatamAndUS = (
  <span>
    {confirmationTextComplianceLatamAndUS}
    I certify that the test ordered is medically necessary for the diagnosis or
    detection of a disease, illness, impairment, symptom, syndrome or disorder.
    The results of this test will be used in the medical management of the patient
    and/or genetic counseling of the patient and family member(s).
    I have read and understood Blueprint Genetics’ General Terms of Service, as currently posted at
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      https://blueprintgenetics.com/general-terms/
    </a>
    {' '}
    .  Unless there is a written agreement between the Institution and Blueprint Genetics,
    I accept, and have the authority to accept, these General Terms of Service on
    behalf of the Institution.
  </span>

);

const confirmationTextExomeLatamAndUS = (
  <span>
    To ensure compliance with state law, verification of patient informed
    consent is required for genetic testing.Testing laboratories located
    in Massachusetts require a signed acknowledgment from the ordering medical practitioner.
    This signed acknowledgment is required to complete the genetic testing you’ve ordered:
    I acknowledge that prior to ordering genetic testing on the patient listed above,
    <b>
      {' '}
      I have obtained a signed, written consent form from the patient
      (or their authorized representative) and possible family members
      included in the test (Whole Exome Family products), as required
      by applicable state law and/or regulations
    </b>
    . Additionally, I will maintain all
    written consent forms as part of the patient file and will make them available to
    Blueprint Genetics upon reasonable request.
    <br />
    <br />
    I certify that the test ordered is medically necessary for the diagnosis or
    detection of a disease, illness, impairment, symptom, syndrome or disorder.
    The results of this test will be used in the medical management of the patient
    and/or genetic counseling of the patient and family member(s).
    I have read and understood Blueprint Genetics’ General Terms of Service, as currently posted at
    {' '}
    <a href="//blueprintgenetics.com/general-terms/" className="link-pink" target="_blank" rel="noopener noreferrer">
      https://blueprintgenetics.com/general-terms/
    </a>
    {' '}
    .  Unless there is a written agreement between the Institution and Blueprint Genetics,
    I accept, and have the authority to accept, these General Terms of Service on
    behalf of the Institution.
  </span>

);

const confirmationTextSparkOldLatamAndUS = (
  <span>
    {confirmationTextComplianceLatamAndUS}
    {confirmationTextSparkOldLatamUS}
  </span>
);

const confirmationTextSparkLatamAndUS = (
  <span>
    {confirmationTextComplianceLatamAndUS}
    {confirmationTextSparkOldLatamUS}
  </span>
);

const confirmationTextResonate = (
  <span>
    I agree I will not bill the patient or their insurance for the
    genetic counseling or genetic testing services offered as part of this program.
    I have read and understood the
    {' '}
    <a
      href="https://blueprintgenetics.com/general-terms/"
      className="link-pink"
      target="_blank"
      rel="noopener noreferrer"
    >
      General Terms of Service
    </a>
    . I authorize Blueprint Genetics and Program sponsor and their affiliates
    to contact me by mail, email or phone to inform me about ongoing clinical trials,
    clinical studies, hearing loss related programs, services and
    products that relate to the patients who have received a genetic test.
    I understand that I may revoke this authorization by contacting
    {' '}
    <a
      href="https://blueprintgenetics.com/contact/"
      className="link-pink"
      target="_blank"
      rel="noopener noreferrer"
    >
      Blueprint Genetics Client Services
    </a>
    {' '}
    and that in revoking authorization I will not be able to
    participate in the Genetic Testing Program.
  </span>
);

const confirmationTextResonateLatamAndUS = (
  <span>
    {confirmationTextComplianceLatamAndUS}
    {confirmationTextResonate}
  </span>
);

const confirmationTextScreening = (
  <span>
    The result of this test will be used in the medical management of the patient and/or
    genetic counseling of the patient and family member(s). I have read and understood
    Blueprint Genetics’ General Terms of Service, as currently posted at
    {' '}
    <a
      href="//blueprintgenetics.com/general-terms/"
      className="link-pink"
      target="_blank"
      rel="noopener noreferrer"
    >
      https://blueprintgenetics.com/general-terms/
    </a>
    {' '}
    . Unless there is a written agreement between the Institution and Blueprint Genetics,
    I accept and have the authority to accept, these General Terms of Service on behalf
    of the Institution.
  </span>
);

const ConfirmationLabel = ({
  orderInfo,
}) => {
  const oldMRTPanel = (orderInfo.product.code === 'OP3701' && [2, 3].includes(orderInfo.product.version)) || (orderInfo.product.code === 'OP3801' && [2, 3, 4].includes(orderInfo.product.version));
  const deprecatedMRTPanel = orderInfo.product.version === '1';
  const isAfterUSOpsPhase1OfferingChanges = orderInfo.is_after_US_ops_phase1_offering_changes;
  const isAfterUSExpansionChanges = isUsRegion() && orderInfo.is_after_US_expansion_release;
  // eslint-disable-next-line max-len
  const confirmationTextBasePanelsChange = isAfterUSExpansionChanges ? confirmationTextBasePanelsLatamAndUS : confirmationTextBasePanelsAfterQuestIntegration;
  // eslint-disable-next-line max-len
  const confirmationTextExomeChange = isAfterUSExpansionChanges ? confirmationTextExomeLatamAndUS : confirmationTextExome;
  // eslint-disable-next-line max-len
  const confirmationTextSparkOldChange = isAfterUSExpansionChanges ? confirmationTextSparkOldLatamAndUS : confirmationTextSparkOld;
  // eslint-disable-next-line max-len
  const confirmationTextSparkChange = isAfterUSExpansionChanges ? confirmationTextSparkLatamAndUS : confirmationTextSparkAfterQuestIntegration;
  // eslint-disable-next-line max-len
  const confirmationTextResonateChange = isAfterUSExpansionChanges ? confirmationTextResonateLatamAndUS : confirmationTextResonate;

  switch (true) {
    case orderInfo.product.code === 'EA0601': {
      return <span>{confirmationTextResonateChange}</span>;
    }
    case isSpark(orderInfo.special_type): {
      if (deprecatedMRTPanel) {
        // Support for deprecated MRT product
        return (
          <span>
            <span>
              I agree I will not bill the patient or their insurance for the
              genetic counseling or genetic testing services offered as part of
              this study.
            </span>
            {confirmationText}
          </span>
        );
      }

      if (oldMRTPanel) {
        return <span>{confirmationTextSparkOldChange}</span>;
      }

      return (
        <span>
          {isAfterUSOpsPhase1OfferingChanges
            ? confirmationTextSparkChange
            : confirmationTextSparkNew}
        </span>
      );
    }
    case isExome(orderInfo.special_type): {
      return (
        <span>
          {isAfterUSOpsPhase1OfferingChanges
            ? confirmationTextExomeChange
            : confirmationTextBasePanels}
        </span>
      );
    }
    case isScreening(orderInfo.special_type): {
      return <span>{confirmationTextScreening}</span>;
    }
    default:
      return (
        <span>
          {isAfterUSOpsPhase1OfferingChanges
            ? confirmationTextBasePanelsChange
            : confirmationTextBasePanels}
        </span>
      );
  }
};

ConfirmationLabel.propTypes = {
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
};

export default ConfirmationLabel;
