import React, {Component} from 'react';
import PropTypes from 'prop-types';

// TODO: remove after redux-form is no longer used

export default class LabelInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    short: PropTypes.bool,
    suffix: PropTypes.string,
    hint: PropTypes.node,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    hideAsterix: PropTypes.bool,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    inputRef: () => {},
  };

  render() {
    const {
      disabled,
      required,
      suffix,
      hint,
      short,
      label,
      labelSize,
      inputSize,
      name,
      placeholder,
      readOnly,
      type,
      value,
      inputRef,
      ...props
    } = this.props;

    return (
      <div className="form-group row">
        <label className={'col-md-' + labelSize + ' col-form-label'}>
          {required ? label + ' *' : label}
          {hint && (<p className="small">{hint}</p>)}
        </label>
        <div className={'col-md-' + inputSize + ' no-padding-left'}>
          <input
            className={short ? 'short form-control' : 'form-control'}
            ref={(input) => inputRef(input)}
            autoComplete="off"
            disabled={disabled}
            placeholder={placeholder}
            name={name}
            readOnly={readOnly}
            type={type}
            {...props}
            value={value || ''}
            touched={props.touched ? 'true' : null} />
          <span className="small">{suffix}</span>
          {props && props.touched && props.error && <p className="temporary-message text-danger">{props.hideAsterix ? '' : '* '}{props.error}</p>}
        </div>
      </div>
    );
  }
}
