import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as orderActions from 'redux/modules/orders';
import { orderInfoSelector } from 'redux/modules/orders';

import { BILLING_METHODS } from 'helpers/constants/billingInfoConstants';

import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import ViewBillingInfoRow from './BillingInfoPartials/ViewBillingInfoRow';
import ViewInstitutionalBillingInfo from './BillingInfoPartials/ViewInstitutionalBillingInfo';
import ViewPatientInsuranceBillingInfo from './BillingInfoPartials/ViewPatientInsuranceBillingInfo';
import ViewPatientPaymentBillingInfo from './BillingInfoPartials/ViewPatientPaymentBillingInfo';
import ViewBillingInfoICDHospitalStatus from './BillingInfoPartials/ViewBillingInfoICDHospitalStatus';

import styles from './BillingInfo.scss';

const getBillingMethodLabel = (billingMethod) => {
  switch (billingMethod) {
    case BILLING_METHODS.INSTITUTIONAL:
      return 'Institutional';
    case BILLING_METHODS.PATIENT_INSURANCE:
      return 'Patient insurance';
    case BILLING_METHODS.PATIENT_PAYMENT:
      return 'Patient payment';
    case BILLING_METHODS.PROMOTIONAL_CODE:
      return 'Promotional code';
    case BILLING_METHODS.SPONSORED:
      return 'Sponsored testing';
    default:
      return null;
  }
};

const BillingInfo = () => {
  const orderInfo = useSelector(orderInfoSelector);
  const orderId = orderInfo.order_id;

  const billingInfoDetails = useSelector((state) => state.orders.billingInfoDetails);
  const billingInfoOrderFiles = useSelector((state) => state.orders.billingInfoOrderFiles);

  const dispatch = useDispatch();

  useEffect(() => {
    const getBillingInfoDetails = async () => {
      await dispatch(orderActions.billingInfo({ order_id: orderId }));
    };

    if (!billingInfoDetails) {
      getBillingInfoDetails();
    }
  }, [orderId, billingInfoDetails, dispatch]);

  useEffect(() => {
    if (!billingInfoOrderFiles) {
      dispatch(orderActions.listBillingInfoOrderFiles({ order_id: orderId, type: 'billing_attachment' }));
    }
  }, [orderId, billingInfoOrderFiles, dispatch]);

  const getBillingInfoView = () => {
    if (!billingInfoDetails) {
      return null;
    }

    const billingMethod = billingInfoDetails.billing_method;

    switch (billingMethod) {
      case BILLING_METHODS.INSTITUTIONAL:
        return (
          <ViewInstitutionalBillingInfo
            orderInfo={orderInfo}
            billingInfoDetails={billingInfoDetails}
          />
        );
      case BILLING_METHODS.PATIENT_INSURANCE:
        return (
          <ViewPatientInsuranceBillingInfo
            orderInfo={orderInfo}
            billingInfoDetails={billingInfoDetails}
            billingInfoOrderFiles={billingInfoOrderFiles}
          />
        );
      case BILLING_METHODS.PATIENT_PAYMENT:
        return (
          <ViewPatientPaymentBillingInfo
            orderInfo={orderInfo}
            billingInfoDetails={billingInfoDetails}
          />
        );
      default:
        return null;
    }
  };
  const isLoading = !billingInfoDetails || !billingInfoOrderFiles;
  const billingInfoView = getBillingInfoView();

  return (
    <ViewOrderStep loading={isLoading}>
      {!isLoading
        && (
          <div>
            <div>
              <ViewBillingInfoRow value={billingInfoDetails.billing_method ? getBillingMethodLabel(billingInfoDetails.billing_method) : 'No Billing information'} label="Billing Method" />
            </div>

            {billingInfoDetails.promotion_code && (
              <div>
                <h4 className={styles.billing_address_heading}>Promotional information</h4>
                <ViewBillingInfoRow value={billingInfoDetails.promotion_code} label="Promotional code" />
              </div>
            )}

            {billingInfoDetails.billing_method !== BILLING_METHODS.SPONSORED
              && (
                <ViewBillingInfoICDHospitalStatus
                  orderInfo={orderInfo}
                  withHospitalStatus={
                    billingInfoDetails.billing_method === BILLING_METHODS.PATIENT_INSURANCE
                    || billingInfoDetails.billing_method === BILLING_METHODS.PATIENT_PAYMENT
                  }
                />
              )}

            {billingInfoView}
          </div>
        )}
    </ViewOrderStep>
  );
};

export default BillingInfo;
