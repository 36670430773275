import PropTypes from 'prop-types';

const GenePropTypes = {
  id: PropTypes.number,
  hgnc_symbol: PropTypes.string,
  overlaps_segmental_duplication: PropTypes.bool,
  has_suboptimal_coverage: PropTypes.bool,
};

const OrderKitPropTypes = {
  has_order_kit: PropTypes.bool,
  name: PropTypes.string,
  institution: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country_name: PropTypes.string,
  blood_kit_number: PropTypes.number,
  saliva_kit_number: PropTypes.number,
  exome_kit_number: PropTypes.number,
  other: PropTypes.string,
};

const SpecialTypePropType = PropTypes.oneOf([
  'panel',
  'panel_deldup',
  'panel_plus',
  'extension',
  'fmt',
  'wgs',
  'exome',
  'exome_plus',
  'exome_family',
  'exome_family_plus',
  'single_gene',
  'biomarin',
  'nei',
  'spark',
  'fvt',
  'tvt',
  'blank',
  'msd',
  'screening',
]);

const OrderInfoPropTypes = {
  order_id: PropTypes.number.isRequired,
  hospital_name: PropTypes.string.isRequired,
  user_name: PropTypes.string.isRequired,
  ordered_by_name: PropTypes.string,
  reflex_order_id: PropTypes.string,
  product: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  skip_billing: PropTypes.bool,
  is_order_completed: PropTypes.bool.isRequired,
  is_customized: PropTypes.bool.isRequired,
  analysis_type: PropTypes.oneOf([
    'sequence_analysis',
    'deletion_duplication_analysis',
    'sequence_and_deletion_duplication_analysis',
  ]),
  special_type: SpecialTypePropType,
  original_panel_genes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  masked_genes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  added_genes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  custom_clinical_info: PropTypes.string,
  index_order_id: PropTypes.string,
  index_order_completion_date: PropTypes.string,
  order_kit: PropTypes.shape(OrderKitPropTypes),
};

const orderTestFormBillingInfoStaticProps = {
  // redux
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
    country_code: PropTypes.string,
  }).isRequired,
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
  aliasUserId: PropTypes.string,

  getBillingInfo: PropTypes.func.isRequired,
  getBillingMethods: PropTypes.func.isRequired,
  saveBillingInfo: PropTypes.func.isRequired,
  createInsuranceStatus: PropTypes.func.isRequired,

  // props
  hideModal: PropTypes.func.isRequired,
  savePanelAnswers: PropTypes.func.isRequired,
  getPanelQuestions: PropTypes.func.isRequired,
};

export default OrderInfoPropTypes;

export {
  GenePropTypes,
  SpecialTypePropType,
  OrderKitPropTypes,
  orderTestFormBillingInfoStaticProps,
};
