import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import ProductListItem, { ProductListItemEditVisibility } from './ProductListItem';
import styles from './PanelList.scss';
import styleListItem from './ProductListItem.scss';
import PanelTierInfo from './PanelTierInfo';

export default class PanelList extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(
      PropTypes.shape({
        panel_id: PropTypes.number.isRequired,
        category_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    searchString: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['editVisibility', 'ordering']),
    formProps: PropTypes.shape({
      setFieldValue: PropTypes.func,
    }),
    onSelect: PropTypes.func.isRequired,
    onUpgrade: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onCustomize: PropTypes.func.isRequired,
    savedFlexPanels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        product: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          category_id: PropTypes.number.isRequired,
          is_deprecated: PropTypes.number.isRequired,
        }),
      }),
    ),
    pricingModel: PropTypes.oneOf(['fixed', 'phenotype', 'gene_count', false]).isRequired,
  };

  static defaultProps = {
    savedFlexPanels: [],
    formProps: {
      setFieldValue: () => {},
    },
    mode: 'ordering',
  };

  state = {
    expandedCategories: {},
    categoriesWithPanels: [],
    showUpgradeModal: false,
    upgradeCollectionId: null,
    showFlexingMessageModal: false,
  };

  componentDidMount() {
    this.computePanelsInCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString) {
      // Recalculate when search updated
      this.computePanelsInCategories();
    }
  }

  toggleCategory = (categoryName) => {
    this.setState((state) => {
      const expandedCategories = { ...state.expandedCategories };

      if (expandedCategories[categoryName]) {
        delete expandedCategories[categoryName];
      } else {
        expandedCategories[categoryName] = true;
      }

      return {
        expandedCategories,
      };
    });
  };

  confirmUpgrade = (geneCollectionId) => {
    this.setState({
      showUpgradeModal: true,
      upgradeCollectionId: geneCollectionId,
    });
  }

  onUpgrade = () => {
    const { upgradeCollectionId } = this.state;
    this.props.onUpgrade(upgradeCollectionId);
    this.setState({ showUpgradeModal: false, upgradeCollectionId: null });
  };

  onDelete = (geneCollectionId) => {
    if (!window.confirm('Are you sure you want to delete this saved flex panel?')) {
      return;
    }
    // send the call to backend
    this.props.onDelete(geneCollectionId);
  };

  selectAllProductsInCategory = (panelsInCategory) => {
    panelsInCategory.map((panel) => this.props.formProps.setFieldValue(panel.panel_id, true));
  }

  deselectAllProductsInCategory = (panelsInCategory) => {
    panelsInCategory.map((panel) => this.props.formProps.setFieldValue(panel.panel_id, false));
  }

  renderSavedFlexPanel = () => {
    const { onSelect, savedFlexPanels, pricingModel } = this.props;

    const {
      expandedCategories,
    } = this.state;

    // if we have saved flex panels
    if (savedFlexPanels.length) {
      return (
        <div>
          {/*
            eslint-disable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions,
            camelcase
          */}
          <h3
            className={styles.category_heading}
            onClick={() => this.toggleCategory('saved-flex-panels')}
          >
            My Saved FLEX Panels
            <i
              className={classNames('glyphicon glyphicon-sm float-right', {
                'glyphicon-menu-up': expandedCategories['saved-flex-panels'],
                'glyphicon-menu-down': !expandedCategories['saved-flex-panels'],
              })}
              aria-hidden="true"
            />
          </h3>
          {expandedCategories['saved-flex-panels'] && (
          <ul className={`${styles.panel_list} list-unstyled`}>
            {savedFlexPanels.map((geneCollection) => (
              <li key={geneCollection.id}>
                <span
                  className={styleListItem.panel_name}
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    if (geneCollection.product.is_deprecated === 1) {
                      this.confirmUpgrade(geneCollection.id);
                    } else {
                      onSelect({
                        gene_collection_id: geneCollection.id,
                        name: geneCollection.product.name,
                        panel_id: geneCollection.product.id,
                        panel_tier_id: geneCollection.panel_tier.panel_tier_id,
                      });
                    }
                  }}
                >
                  {`${geneCollection.name} (${geneCollection.product.name})`}
                </span>
                <span className={styleListItem.panel_action_group}>
                  <PanelTierInfo product={geneCollection} pricingModel={pricingModel} />

                  <button
                    type="button"
                    className="btn btn-default btn-sm"
                    onClick={(event) => {
                      event.preventDefault();
                      if (geneCollection.product.is_deprecated === 1) {
                        this.confirmUpgrade(geneCollection.id);
                      } else {
                        if (pricingModel === 'phenotype') {
                          this.state.showFlexingMessageModal = true;
                        }
                        onSelect({
                          gene_collection_id: geneCollection.id,
                          name: geneCollection.product.name,
                          panel_id: geneCollection.product.id,
                          panel_tier_id: geneCollection.panel_tier?.panel_tier_id,
                          show_flexing_message: this.state.showFlexingMessageModal,
                        });
                      }
                    }}
                  >
                    {geneCollection.product.is_deprecated === 1 ? 'Update' : 'Order'}
                  </button>
                  {geneCollection.user_deletable === '1' && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      style={{ marginLeft: '5px' }}
                      onClick={(event) => {
                        event.preventDefault();
                        this.onDelete(geneCollection.id);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
          )}
          {/*
            eslint-enable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */}
          <ConfirmModal
            show={this.state.showUpgradeModal}
            onCancel={() => this.setState({ showUpgradeModal: false })}
            onConfirm={this.onUpgrade}
            confirmCaption="Ok"
          >
            <p>
              There is an update available to your Saved Flex Panel that requires your review.
              The panel you have customized has been updated to include our latest gene
              additions to enhance clinical utility.
              If you wish to keep the added genes, no action is required. If you wish to
              remove any genes, please find further instructions and information
              {' '}
              <a className={styles.link} target="_blank" rel="noopener noreferrer" href="https://blueprintgenetics.com/panel-update-2021/">here</a>
              .
              Please note that as the number of genes included has increased,
              the panel tier may be affected – you can check the current tier
              on the top right-hand of your screen when creating a new order.
            </p>
          </ConfirmModal>
        </div>
      );
    }

    return null;
  }

  computePanelsInCategories() {
    const { panels, searchString } = this.props;

    const grouped = panels.reduce((acc, panel) => {
      if (!Object.prototype.hasOwnProperty.call(acc, panel.category_name)) {
        acc[panel.category_name] = [];
      }

      acc[panel.category_name].push(panel);
      return acc;
    }, {});

    const list = Object.entries(grouped)
      .filter(([, panelsInCategory]) => panelsInCategory.length) // Keep only categories with panels
      .sort(([a], [b]) => a.localeCompare(b)); // Sort by category name
    if (searchString) {
      // If searching expand all categories
      const categories = list
        .map(([categoryName]) => categoryName)
        .reduce(
          (acc, name) => Object.assign(acc, {
            [name]: true,
          }),
          {},
        );
      // gene selection is not included in the normal panel
      categories['saved-flex-panels'] = true;
      this.setState({
        expandedCategories: categories,
      });
    }

    this.setState({
      categoriesWithPanels: list,
    });
  }

  render() {
    const {
      onSelect, onCustomize, mode, pricingModel,
    } = this.props;

    const {
      expandedCategories,
      categoriesWithPanels,
    } = this.state;

    return (
      <div>
        {this.renderSavedFlexPanel()}
        {categoriesWithPanels.map((
          [
            categoryName,
            panelsInCategory,
          ],
        ) => (
          /*
            eslint-disable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */
          <div key={categoryName}>
            <h3
              className={styles.category_heading}
              onClick={() => this.toggleCategory(categoryName)}
            >
              {categoryName}

              <i
                className={classNames('glyphicon glyphicon-sm float-right', {
                  'glyphicon-menu-up': expandedCategories[categoryName],
                  'glyphicon-menu-down': !expandedCategories[categoryName],
                })}
                aria-hidden="true"
              />
            </h3>

            {expandedCategories[categoryName] && mode === 'editVisibility' && (
              <ul className={`${styles.panel_list} list-unstyled`}>
                <button
                  className="btn btn-default"
                  style={{ marginRight: '5px' }}
                  type="button"
                  onClick={() => this.selectAllProductsInCategory(panelsInCategory)}
                >
                  Select all
                </button>
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={() => this.deselectAllProductsInCategory(panelsInCategory)}
                >
                  Deselect all
                </button>

                {panelsInCategory.map((panel) => (
                  <ProductListItemEditVisibility
                    product={panel}
                    key={panel.panel_id}
                  />
                ))}
              </ul>
            )}
            {expandedCategories[categoryName] && mode === 'ordering' && (
              <ul className={`${styles.panel_list} list-unstyled`}>
                {panelsInCategory.map((panel) => (
                  <ProductListItem
                    key={panel.panel_id}
                    product={panel}
                    pricingModel={pricingModel}
                    onSelect={onSelect}
                    onCustomize={onCustomize}
                  />
                ))}
              </ul>
            )}
            {/*
              eslint-enable
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions
            */}
          </div>
        ))}
      </div>
    );
  }
}
