import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Formik, Field, Form,
} from 'formik';
import LabelInput from 'components/Formik/LabelInput';

const twoFAVerificationValidationSchema = Yup.object().shape({
  code: Yup.string().required('Verification code is required'),
});

const TwoFAVerification = ({
  onSubmit,
}) => (
  <div>
    <div>
      <h2>Verification</h2>
      <p>Enter code:</p>
    </div>

    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={twoFAVerificationValidationSchema}
      onSubmit={(values, actions) => {
        onSubmit(values)
          .catch((e) => {
            actions.setFieldError('general', e.message);
          });
      }}
    >
      {(formProps) => (
        <Form>
          <Field
            type="password"
            placeholder="Verification code"
            name="code"
            component={LabelInput}
          />

          {formProps.errors.general && (
            <p className="text-danger">{formProps.errors.general}</p>
          )}
          <button type="submit" className="btn btn-primary btn-block">
            Verify
          </button>
        </Form>
      )}
    </Formik>
  </div>
);

TwoFAVerification.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default TwoFAVerification;
