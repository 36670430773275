/**
 * Nucleus HTTP client
 */

/**
 * Wrap promise with cancel functionality
 * @param {Promise} promise Original promise
 * @param {Function} cancel Cancel handler
 * @returns {Promise} Cancelable promise
 */
function createPromiseWithCancel(promise, cancel) {
  const originalThen = promise.then;

  // eslint-disable-next-line no-param-reassign
  promise.cancel = cancel;
  // eslint-disable-next-line no-param-reassign
  promise.then = (onFullfilled, onRejected) => createPromiseWithCancel(
    originalThen.call(promise, onFullfilled, onRejected),
    cancel,
  );

  return promise;
}

export default class NucleusClient {
  constructor() {
    this.baseUrl = '/api/v2/';
    this.store = null;
  }

  /**
   * Set redux store
   * @param {Object} store Redux store to set
   */
  setStore = (store) => {
    this.store = store;
  };

  /**
   * Send GET request
   * @param {string} path Endpoint path
   * @param {Object} query Query params
   * @returns {Promise}
   */
  get = (path, query = {}) => this.request(path, {
    method: 'GET',
    query,
  });

  /**
   * Send POST request
   * @param {string} path Endpoint path
   * @param {Object} body Request body
   * @returns {Promise}
   */
  post = (path, body) => this.request(path, {
    method: 'POST',
    body,
  });

  /**
   * Get JSON file given an absolute url
   * @param {string} url Absolute URL to call
   * @throws {Error}
   * @returns {Promise}
   */
  getRemoteJSON = (url) => fetch(url).then((res) => {
    if (!res.ok) {
      throw new Error(
        `Remote JSON responded with: ${res.status} (${res.statusText})`,
      );
    }

    return res.json();
  });

  /**
   * Get abort controller if present
   * @returns {Object} Object containing abort controller and its signal
   */
  getAbortController = () => {
    let controller;
    let signal;
    if (typeof window !== 'undefined' && window.AbortController) {
      controller = new window.AbortController();
      signal = controller.signal;
    }
    return { controller, signal };
  }

  /**
   * Get user authentication token from Redux store.
   * @returns {string}
   */
  getAuthToken = () => {
    if (!this.store) {
      return null;
    }

    const { auth } = this.store.getState();
    if (auth) {
      if (auth.user) {
        return auth.user.auth_token;
      }

      // Before user is completely logged in we store token outside of user.
      return auth.auth_token;
    }

    return null;
  };

  /**
   * Build a list of param=value strings for adding to url as query params
   * @param {Object} query Query params object
   * @returns {string[]}
   */
  buildQueryParams = (query) => Object.entries(query || {}).map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  );

  /**
   * Form a URL out of path and query params
   * @param {string} path Endpoint path to append to base URL
   * @param {Object} query Query Params object
   * @returns {string}
   */
  buildUrl = (path, query) => {
    let url = this.baseUrl + path;

    const queryParams = this.buildQueryParams(query);
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    return url;
  };

  /**
   * Build fetch compatible request params
   * @param {Object} params Partial request params
   * @returns {Object}
   */
  buildRequestParams = ({ method, body, signal }) => {
    const params = {
      headers: {},
      method,
      signal,
    };

    // Set authorization
    const authToken = this.getAuthToken();
    if (authToken) {
      params.headers.Authorization = authToken;
    }

    // Prepare request payload
    if (method === 'POST') {
      params.headers['Content-Type'] = 'application/json';
      if (body) {
        params.body = JSON.stringify(body);
      }
    }

    return params;
  }

  /**
   * Form and send request
   * @param {string} path Endpoint path
   * @param {Object} params Request params
   * @returns {Promise} Cancelable promise
   */
  request = (path, { method, query, body }) => {
    const url = this.buildUrl(path, query);

    const { controller, signal } = this.getAbortController();
    const requestParams = this.buildRequestParams({
      method, body, signal,
    });

    const requestPromise = fetch(url, requestParams)
      .then((res) => {
        if (!res.ok) {
          const err = new Error(
            `Nucleus responded with: ${res.status} (${res.statusText})`,
          );

          err.status = res.status;
          err.statusText = res.statusText;
          err.bodyText = res.text();

          throw err;
        }

        return res.json();
      })
      .then((resBody) => {
        if (!resBody.success) {
          throw new Error(resBody.message);
        }

        // Some requests return message instead of payload
        // Some requests return message and an empty payload
        if (resBody.message) {
          // If there is message AND (there is no payload OR payload is an empty object)
          // then return message
          const hasValidPayload = resBody.payload
            && typeof resBody.payload === 'object'
            && Object.keys(resBody.payload).length;
          if (!hasValidPayload) {
            return resBody.message;
          }
        }

        return resBody.payload;
      });

    return createPromiseWithCancel(requestPromise, () => {
      if (controller) {
        controller.abort();
      }
    });
  };
}
