import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';

const ProductionLocationInput = ({ value }) => (
  <LabelInput
    inputSize="6"
    label="Production Location"
    labelSize="6"
    name="production_location"
    readOnly
    value={value}
  />
);

ProductionLocationInput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default ProductionLocationInput;
