import React from 'react';
import PropTypes from 'prop-types';

import LabelInput from './LabelInput';

/**
 * Allows to reuse LabelInput component without Formik context.
 */
const LabelInputControlled = ({
  name,
  label,
  value,
  readOnly,
}) => (
  <LabelInput
    field={{
      name,
      value,
    }}
    label={label}
    readOnly={readOnly}
  />
);

LabelInputControlled.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

LabelInputControlled.defaultProps = {
  name: null,
  label: null,
  readOnly: false,
};

export default LabelInputControlled;
