import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import envUtils from 'utils/envUtils';
import styles from './OrderTestModal.scss';
import { OrderTestModalHeaderProps } from './OrderTestModalConstant';
import salesforceLogo from '../OrderList/salesforce_logo.svg';

const OrderTestModalHeader = ({
  dob,
  geneCountText,
  getPanelHeadingContent,
  getPanelVersionInfo,
  isInternalUser,
  isOrderCompleted,
  isPanelNew,
  isPanelUpdated,
  isSupportUser,
  orderId,
  orderIsCompleted,
  panelTierText,
  patientFirstName,
  patientInfo,
  patientLastName,
  salesforceOrderId,
  status,
  userRole,
  currentStep,
}) => (
  <Modal.Header closeButton>
    <div className="w-100">
      <div className={styles.panelHeadingRow}>
        <h2>
          {orderIsCompleted ? 'Edit' : 'Create'}
          {` Order ID ${orderId}`}
          {isSupportUser && salesforceOrderId && (
            <Link
              to={{
                pathname: envUtils.getSalesforceOrderLink({
                  salesforce_order_id: salesforceOrderId,
                }),
              }}
              target="_blank"
              id="salesforce-order-link"
            >
              <Isvg height={25} width={40} src={salesforceLogo}>
                Can not handle svg!
              </Isvg>
            </Link>
          )}
        </h2>
        {patientInfo && <h5>{`Patient: ${patientLastName}, ${patientFirstName}, ${dob}`}</h5>}
      </div>
      <div className={styles.panelHeadingRow}>
        <div className={styles.panelHeading} id="panel-heading-with-ribbon">
          <h4 className={styles.panelHeadingContent}>
            {getPanelHeadingContent()}
          </h4>
          {isPanelNew && (
          <span className={styles.panelTagNew}>New!</span>
          )}

          {isPanelUpdated && (
          <span className={styles.panelTagUpdate}>Updated</span>
          )}
        </div>
        {panelTierText && (
        <a
          className={styles.panelTierHeading}
          href="https://blueprintgenetics.com/pricing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {panelTierText}
        </a>
        )}
      </div>
      <div className={styles.panelHeadingRow}>
        <div className={styles.panelHeadingColumn}>
          {isInternalUser && <h5>{getPanelVersionInfo()}</h5>}
        </div>
        <div className={styles.panelHeadingColumn}>
          {geneCountText && <h5 className="h5">{geneCountText}</h5>}
        </div>
      </div>
      {status !== 'draft'
          && !isOrderCompleted
          && currentStep !== 8
          && userRole === 'support' && (
            <h4 className="text-danger">Not submitted!</h4>
      )}
    </div>
  </Modal.Header>
);

OrderTestModalHeader.propTypes = OrderTestModalHeaderProps;

export default OrderTestModalHeader;
