import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';

import { userInfoSelector } from 'redux/modules/auth';
import LabelInput from 'components/Formik/LabelInput';
import LabelInputControlled from 'components/Formik/LabelInputControlled';
import CheckboxControlled from 'components/Formik/CheckboxControlled';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';
import { isSupportUser } from 'utils/orderRoles';
import { areOnlyPrimaryFindingsSelected, hasIndexOrderFindings } from 'utils/fvtOrder';

import IndexVariants from './IndexVariants';

import styles from './IndexOrderSection.scss';

const IndexOrderSection = ({ indexOrder, isEligibleForFreeFVT, showPatientInfoInput }) => {
  const formikContext = useFormikContext();

  const userInfo = useSelector(userInfoSelector);

  const isFreeFVTNotificationVisible = () => {
    if (!isSupportUser(userInfo) || !indexOrder || !isEligibleForFreeFVT) {
      return false;
    }

    return areOnlyPrimaryFindingsSelected(formikContext.values.index_variants, indexOrder);
  };

  return (
    <div className={styles.indexOrderSection}>
      {indexOrder && (
        <div>
          <LabelInputControlled
            name="index_patient_name"
            label="Index patient's name"
            value={indexOrder.patient_name}
            readOnly
          />

          <div className={styles.orderCompletionDate}>
            {`Reported on ${indexOrder.order_completion_date}`}
          </div>

          <div className={`${styles.orderPatientTestName} form-group row`}>
            <label className="col-md-6 col-form-label">
              Index patient&apos;s test name
            </label>
            <span className="col-md-6 no-padding-left">{indexOrder.index_patient_test_name}</span>
          </div>

          {hasIndexOrderFindings(indexOrder) && !indexOrder.is_screening_duo && (
            <label className={styles.indexFindingsLabel}>
              Please select the variant(s) to be tested
            </label>
          )}

          {indexOrder.missing_variants_disclaimer && (
            <p className="text-danger">
              Note that some variants could not be shown in the table below.
              Please add them manually or contact customer support.
            </p>
          )}

          {!indexOrder.is_screening_duo && (
            <div>
              <IndexVariants
                variants={indexOrder.primary_findings}
                caption="Primary findings"
                findingType="primary"
                id="primary-findings"
                indexVariantValues={formikContext.values.index_variants}
              />

              <IndexVariants
                variants={indexOrder.secondary_findings}
                caption="Secondary findings"
                findingType="secondary"
                id="secondary-findings"
                indexVariantValues={formikContext.values.index_variants}
              />

              <IndexVariants
                variants={indexOrder.additional_findings}
                caption="Additional findings"
                findingType="additional"
                id="additional-findings"
                indexVariantValues={formikContext.values.index_variants}
              />

              <IndexVariants
                variants={indexOrder.candidate_findings}
                caption="Candidate findings"
                findingType="candidate"
                id="candidate-findings"
                indexVariantValues={formikContext.values.index_variants}
              />
            </div>
          )}

          {(typeof formikContext.errors.index_variants === 'string') && (
            <div>
              <p className="text-right text-danger">{formikContext.errors.index_variants}</p>
            </div>
          )}

          {!hasIndexOrderFindings(indexOrder) && !indexOrder.is_screening_duo && (
            <div className="form-group row">
              <label className="col-md-6 col-form-label" />
              <div>Order has no findings. Please add variants manually</div>
            </div>
          )}

          {indexOrder.is_screening_duo && (
            <div className="form-group row">
              <label className="col-md-6 col-form-label" />
              <div>
                Index Order ID is Screening DUO order,
                please add variant information manually
              </div>
            </div>
          )}

          {isSupportUser(userInfo) && indexOrder.has_family_service && (
            <div className={styles.supportOnlySection}>
              <CheckboxControlled
                name="index_order_has_fes"
                label="Index order includes Family Extension Service"
                labelClassName="view_order_modal_label"
                className="modal_checkbox"
                hint="Visible only for support"
                value={indexOrder.has_family_service}
                readOnly
              />
            </div>
          )}
        </div>
      )}

      {isFreeFVTNotificationVisible() && (
        <div
          id="free-fvt-notification"
          className={styles.supportFreeFvtNotification}
        >
          <span>
            Eligible Free FVT
          </span>
          <p className="small">Visible only for support</p>
        </div>
      )}

      {showPatientInfoInput && (
        <div className={styles.patientInfoInput}>
          <Field
            name="index_patient_name"
            component={LabelInput}
            label="Index patient's name"
            required
          />

          <Field
            name="index_patient_dob"
            component={SimpleDatePicker}
            label="Index patient's date of birth"
            required
          />
        </div>
      )}
    </div>
  );
};

const VariantClassificationPropTypes = {
  value: PropTypes.string,
  class: PropTypes.string,
};

const VariantPropTypes = {
  c_dna_change: PropTypes.string,
  classification: PropTypes.shape(VariantClassificationPropTypes),
  gene: PropTypes.string,
  protein_change: PropTypes.string,
  transcript: PropTypes.string,
};

IndexOrderSection.propTypes = {
  indexOrder: PropTypes.shape({
    patient_name: PropTypes.string,
    index_patient_test_name: PropTypes.string,
    order_completion_date: PropTypes.string,
    has_family_service: PropTypes.bool,
    missing_variants_disclaimer: PropTypes.bool,
    primary_findings: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)),
    secondary_findings: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)),
    additional_findings: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)),
    candidate_findings: PropTypes.arrayOf(PropTypes.shape(VariantPropTypes)),
    is_screening_duo: PropTypes.bool,
  }),
  isEligibleForFreeFVT: PropTypes.bool.isRequired,
  showPatientInfoInput: PropTypes.bool.isRequired,
};

IndexOrderSection.defaultProps = {
  indexOrder: null,
};

export default IndexOrderSection;
