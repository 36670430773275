import React from 'react';

export default function NotFound() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-8">
          <h1>Sorry! The page you were looking for was not found.</h1>
          <p>
            The page you are trying to access cannot be found or is unavailable.
            Please click the back button in your browser to return to
            the previous page and try clicking the link or button again.
          </p>
        </div>
      </div>
    </div>
  );
}
