import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Yup,
  requiredString,
  when,
  requiredPositiveInteger,
} from 'components/Formik/validation';
import { getFieldError } from 'components/Formik/ErrorMessage';
import CountrySelect from 'components/_Login/CountrySelect/CountrySelect';
import LabelInput from 'components/Formik/LabelInput';
import Checkbox from 'components/Formik/Checkbox';
import Textarea from 'components/Formik/Textarea';
import { isExome, isScreening } from 'utils/forms';

const kitNumberError = "You need to order at least 1 kit. Uncheck Order a specimen kit if you don't want to order any kit.";

/**
 * OrderKit validation schema.
 * 'has_order_kit' and 'is_exome' must be set for this validation to run.
 */
export const orderKitSchema = Yup.object().shape({
  name: when('has_order_kit', true, requiredString()),
  email: when(
    ['has_order_kit', 'is_screening'],
    true,
    requiredString(),
  ),
  phone: when('has_order_kit', true, requiredString()),
  address_1: when('has_order_kit', true, requiredString()),
  address_2: Yup.string().ensure(),
  city: when('has_order_kit', true, requiredString()),
  state: Yup.string().ensure(),
  postcode: when(
    ['has_order_kit', 'is_screening'],
    true,
    requiredString(),
  ),
  country_id: when('has_order_kit', true, requiredPositiveInteger()),
  // When has_order_kit, not exome and saliva kit number is not valid
  blood_kit_number: when(
    ['has_order_kit', 'is_exome'],
    (hasOrderKit, isExomeValue) => hasOrderKit && !isExomeValue,
    when(
      'saliva_kit_number',
      (salivaKitNumber) => !requiredPositiveInteger().isValidSync(salivaKitNumber),
      requiredPositiveInteger(kitNumberError),
    ),
  ),
  // When has_order_kit, not exome and blood kit number is not valid
  // OR when patient address is selected
  saliva_kit_number: when(
    ['has_order_kit', 'is_patient_address'],
    (hasOrderKit, isPatientAddress) => hasOrderKit && isPatientAddress,
    when(
      ['blood_kit_number', 'is_patient_address'],
      (bloodKitNumber, isPatientAddress) => (
        !requiredPositiveInteger().isValidSync(bloodKitNumber)
        || isPatientAddress
      ),
      requiredPositiveInteger(kitNumberError),
    ),
  ),
  exome_kit_number: when(
    ['has_order_kit', 'is_exome', 'is_patient_address'],
    (hasOrderKit, isExomeValue, isPatientAddress) => (
      hasOrderKit
      && isExomeValue
      && !isPatientAddress
    ),
    requiredPositiveInteger(),
  ),
  other: Yup.string().ensure(),
});

/**
 * Set default values when order kit is has_order_kit.
 * Formik requires object keys to be present for the validation.
 */
export function defaultOrderKitValues(orderInfo = {}) {
  const defaultValues = Object.keys(orderKitSchema.fields).reduce(
    (values, field) => {
      let value;
      switch (field) {
        case 'blood_kit_number':
        case 'saliva_kit_number':
        case 'exome_kit_number':
        case 'country_id':
          value = 0;
          break;
        default:
          value = '';
      }

      return Object.assign(values, {
        [field]: value,
      });
    },
    {
      has_order_kit: false,
      is_exome: isExome(orderInfo.special_type),
      is_screening: isScreening(orderInfo.special_type),
    },
  );

  if (orderInfo.order_kit) {
    return Object.assign(defaultValues, orderInfo.order_kit);
  }

  return defaultValues;
}

/**
 * Reset other kit since we have fields that can appear and dissapear
 * but their values stays.
 * Formik requires object keys to be present for the validation.
 */
export const resetOtherKits = (orderKit) => {
  const orderKitClone = { ...orderKit };
  const { is_exome: isExomeOrder, is_patient_address: isPatientAddress } = orderKitClone;
  let {
    blood_kit_number: bloodKit,
    saliva_kit_number: salivaKit,
    exome_kit_number: exomeKit,
  } = orderKitClone;

  // exome can have saliva and exome kits
  if (isExomeOrder) {
    bloodKit = 0;
    if (!isPatientAddress) {
      salivaKit = 0;
    } else {
      exomeKit = 0;
    }
  }
  if (!isExomeOrder) {
    exomeKit = 0;
  }
  if (isPatientAddress) {
    bloodKit = 0;
  }

  return {
    ...orderKitClone,
    is_exome: isExomeOrder,
    is_patient_address: isPatientAddress,
    blood_kit_number: bloodKit,
    saliva_kit_number: salivaKit,
    exome_kit_number: exomeKit,
  };
};

const OrderKit = ({
  countries, values, isScreeningSolo, isScreeningDuo,
}) => {
  const displayExomeKit = !!values.is_exome && !values.is_patient_address;
  const displayBothSalivaAndBlood = !values.is_exome && !values.is_patient_address;
  const displayOnlySaliva = !!values.is_patient_address;

  return (
    !isScreeningSolo && !isScreeningDuo ? (
      <div>
        <Field
          name="order_kit.has_order_kit"
          component={Checkbox}
          label="Order specimen kit"
          hint="Order a specimen kit with sample tube, requisition form and prepaid return."
          labelClassName="view_order_modal_label"
          className="modal_checkbox"
        />

        {values.has_order_kit && (
          <div>
            <Field
              name="order_kit.is_patient_address"
              component={Checkbox}
              label="Deliver kit to patient"
              hint="Deliver kit to patient, with sample tube, requisition form and prepaid return."
              labelClassName="view_order_modal_label"
              className="modal_checkbox"
            />

            <Field
              name="order_kit.name"
              component={LabelInput}
              label="Recipient name"
              required
            />

            <Field
              name="order_kit.phone"
              component={LabelInput}
              label="Phone number for the delivery"
              required
            />

            <Field
              name="order_kit.address_1"
              component={LabelInput}
              label="Delivery address line 1"
              required
            />

            <Field
              name="order_kit.address_2"
              component={LabelInput}
              label="Delivery address line 2"
            />

            <Field
              name="order_kit.city"
              component={LabelInput}
              label="City/Town"
              required
            />

            <Field
              name="order_kit.state"
              component={LabelInput}
              label="State"
            />

            <Field
              name="order_kit.postcode"
              component={LabelInput}
              label="Postcode"
            />

            {countries && (
              <div className="form-group row">
                <label className="col-md-6 col-form-label">Country *</label>
                <div className="col-md-6 no-padding-left">
                  <Field name="order_kit.country_id">
                    {({ form, field }) => (
                      <CountrySelect
                        name={field.name}
                        id={field.name}
                        value={field.value || ''}
                        options={countries}
                        noFormGroupWrap
                        placeholder="Select country"
                        onChange={(e) => field.onChange(e)}
                        touched
                        error={getFieldError(form, field.name)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            )}

            {displayBothSalivaAndBlood && (
              <div>
                <Field
                  name="order_kit.blood_kit_number"
                  component={LabelInput}
                  type="number"
                  label="Number of blood kits"
                  hint="Requires at least 1 kit (buccal or blood)"
                />

                <Field
                  name="order_kit.saliva_kit_number"
                  component={LabelInput}
                  type="number"
                  label="Number of buccal swab kits"
                  hint="Requires at least 1 kit (buccal or blood)"
                />
              </div>
            )}

            {displayOnlySaliva && (
              <Field
                name="order_kit.saliva_kit_number"
                component={LabelInput}
                type="number"
                label="Number of buccal swab kits"
                hint="Requires at least 1 kit"
                required
              />
            )}

            {displayExomeKit && (
              <Field
                name="order_kit.exome_kit_number"
                component={LabelInput}
                type="number"
                label="Number of exome kits"
                required
              />
            )}

            <Field
              name="order_kit.other"
              component={Textarea}
              label="Other information"
            />
          </div>
        )}
      </div>
    ) : (
      <div>
        <Field
          name="order_kit.has_order_kit"
          component={Checkbox}
          label="Order specimen kit"
          hint="Order a specimen kit with sample tube and prepaid return."
          labelClassName="view_order_modal_label"
          className="modal_checkbox"
        />

        {values.has_order_kit && (
          <div>
            <Field
              name="order_kit.name"
              component={LabelInput}
              label="Recipient name"
              required
            />

            <Field
              name="order_kit.email"
              component={LabelInput}
              label="Recipient email"
              required
            />

            <Field
              name="order_kit.phone"
              component={LabelInput}
              label="Phone number for the delivery"
              required
            />

            <Field
              name="order_kit.address_1"
              component={LabelInput}
              label="Delivery address line 1"
              required
            />

            <Field
              name="order_kit.address_2"
              component={LabelInput}
              label="Delivery address line 2"
            />

            <Field
              name="order_kit.city"
              component={LabelInput}
              label="City/Town"
              required
            />

            <Field
              name="order_kit.state"
              component={LabelInput}
              label="State"
            />

            <Field
              name="order_kit.postcode"
              component={LabelInput}
              label="Postcode"
              required
            />

            {countries && (
              <div className="form-group row">
                <label className="col-md-6 col-form-label">Country *</label>
                <div className="col-md-6 no-padding-left">
                  <Field name="order_kit.country_id">
                    {({ form, field }) => (
                      <CountrySelect
                        name={field.name}
                        id={field.name}
                        value={field.value || ''}
                        options={countries}
                        noFormGroupWrap
                        placeholder="Select country"
                        onChange={(e) => field.onChange(e)}
                        touched
                        error={getFieldError(form, field.name)}
                      />
                    )}
                  </Field>
                </div>
              </div>
            )}

            {displayBothSalivaAndBlood && isScreeningSolo && (
              <div>
                <Field
                  name="order_kit.saliva_kit_number"
                  component={LabelInput}
                  type="number"
                  label="Number of buccal swab kits"
                  hint="Requires at least 1 kit"
                />
              </div>
            )}
            {displayBothSalivaAndBlood && isScreeningDuo && (
              <div>
                <Field
                  name="order_kit.saliva_kit_number"
                  component={LabelInput}
                  type="number"
                  label="Number of buccal swab kits"
                  hint="For DUO testing, please order 2 kits: one for each individual"
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

OrderKit.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.shape({
    has_order_kit: PropTypes.bool,
    is_exome: PropTypes.bool,
    is_patient_address: PropTypes.bool,
  }).isRequired,
  isScreeningSolo: PropTypes.bool.isRequired,
  isScreeningDuo: PropTypes.bool.isRequired,
};

export default OrderKit;
