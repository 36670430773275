import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

const PromptIfDirty = ({ dirty, isSubmitting }) => (
  <Prompt
    when={(dirty && !isSubmitting)}
    message="Are you sure you want to close without saving? All changes you have made will be lost."
  />
);

PromptIfDirty.propTypes = {
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default PromptIfDirty;
