import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import Page, { initPageState, handlePagePromise, navigateBack } from 'components/Page';
import EditBlankOrderForm from 'admin/components/EditBlankOrderForm';
import OrderTestModal from 'components/OrderTestModal/OrderTestModal';

import * as orderActions from 'redux/modules/orders';

import { excludeFields } from 'utils/forms';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
} from 'utils/patientQuestions';
import MessageModal from '../../components/MessageModal/MessageModal';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrder: orderActions.getOrder,
  saveOrder: orderActions.saveOrder,
  deleteOrderById: orderActions.deleteOrderById,
  getPatientInfoQuestions: orderActions.getPatientInfoQuestions,
  savePanelAnswers: orderActions.savePanelAnswers,
  resetOrderInfo: orderActions.resetOrderInfo,
}, dispatch);

class EditOrderPage extends Component {
  static propTypes = {
    // Fields
    match: PropTypes.shape({
      params: PropTypes.shape({
        orderId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        show_flexing_message: PropTypes.bool,
      }),
    }).isRequired,
    userInfo: PropTypes.shape({
      user_role: PropTypes.string.isRequired,
    }).isRequired,

    // Methods
    getOrder: PropTypes.func.isRequired,
    saveOrder: PropTypes.func.isRequired,
    deleteOrderById: PropTypes.func.isRequired,
    getPatientInfoQuestions: PropTypes.func.isRequired,
    savePanelAnswers: PropTypes.func.isRequired,
    resetOrderInfo: PropTypes.func.isRequired,
  };

  state = initPageState();

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  componentWillUnmount() {
    // Clean up attachments
    this.props.resetOrderInfo();
  }

  getTitle = () => {
    const {
      data: {
        order,
      },
    } = this.state;

    return (
      (order && order.order_id)
        ? `Edit Order ID ${order.order_id}`
        : 'Edit Order'
    );
  }

  openConvertModal = async () => {
    const {
      history,
    } = this.props;

    const {
      data: {
        order,
      },
    } = this.state;

    history.push(`/orders/${order.order_id}/convert`);
  }

  save = (values) => {
    const {
      data: { order },
    } = this.state;

    const {
      saveOrder,
      savePanelAnswers,
    } = this.props;

    const payload = excludeFields(['questions'], values);
    const answers = constructQuestionAnswers(values.questions);

    return savePanelAnswers(order.order_id, answers, 1)
      .then(() => saveOrder(order.order_id, payload));
  }

  deleteOrder = async () => {
    const {
      history,
      deleteOrderById,
    } = this.props;

    const orderId = parseInt(this.state.data.order.order_id, 10);
    await deleteOrderById(orderId);

    history.push('/orders');
  }

  fetchData() {
    const {
      match,
      getOrder,
      getPatientInfoQuestions,
    } = this.props;

    const {
      orderId,
    } = match.params;

    return getOrder(orderId, { show_transfer: true }).then((order) => {
      if (order.special_type !== 'blank') {
        return {
          order,
        };
      }
      // For blank orders fetch patient info questions
      return getPatientInfoQuestions({
        order_id: orderId,
      }).then((questions) => ({
        order,
        questions: prepareQuestionsForForm(
          Object.values(questions.result)
            .sort((a, b) => a.order_no - b.order_no),
        ),
      }));
    });
  }

  renderPage = () => {
    const {
      match,
      location,
      history,
      userInfo,
    } = this.props;

    const { orderId } = match.params;
    const showFlexingMessage = location.state?.show_flexing_message || false;

    const {
      data: {
        order,
        questions,
      },
      page,
    } = this.state;

    if (!order || page.error) {
      return null;
    }

    // Check that order can be edited by user
    if (!order.can_edit_order) {
      return (
        <Redirect to="/orders" />
      );
    }

    const closeOrder = (cancel) => {
      if (userInfo.user_role === 'support' && !cancel) {
        return navigateBack(location, history, `/orders?filter=orders.id&search=${orderId}`);
      }
      return history.push('/orders');
    };

    const flexMessage = 'Please note that due to flexing, the panel tier may be affected. When adding genes to a panel,'
    + ' only genes in the same phenotypic category as the original panel may be added.'
    + ' If genes are added outside of the phenotype category Blueprint Genetics may contact you to discuss your ordering options.';

    switch (order.special_type) {
      case 'blank':
        return (
          <EditBlankOrderForm
            order={order}
            questions={questions}
            userInfo={userInfo}
            onSave={(
              (values) => this.save(values)
                .then(() => handlePagePromise(this.fetchData(), this))
            )}
            onConvert={() => this.openConvertModal()}
            onDelete={() => handlePagePromise(this.deleteOrder(), this, true)}
            onClose={() => {
              history.push(`/orders?filter=orders.id&search=${orderId}`);
            }}
          />
        );

      default:
        return (
          <>
            <OrderTestModal
              closeOrder={closeOrder}
            />
            <MessageModal
              show={showFlexingMessage}
              message={flexMessage}
              hide={() => history.replace({
                state: { ...location.state, show_flexing_message: false },
              })}
            />
          </>

        );
    }
  }

  render() {
    const {
      page,
    } = this.state;

    return (
      <Page
        cols={10}
        offsetCols={1}
        previousPageLink="/orders"
        previousPageLabel="orders"
        title={this.getTitle()}
        render={this.renderPage}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EditOrderPage);
