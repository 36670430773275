import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './OrderProgressBar.scss';

const OrderProgressBar = ({
  steps,
  currentStep,
  reachedStep,
  isOrderCompleted,
  waiting,
  onStepChange,
}) => {
  const handleStepClick = (step) => {
    const isCurrentStep = step === currentStep;
    const isEnabledStep = isOrderCompleted || step <= reachedStep;

    if (!waiting && !isCurrentStep && isEnabledStep) {
      onStepChange(step);
    }
  };

  const renderStep = (step) => {
    const key = step.key;
    const isActive = key === currentStep;
    const isReached = !isActive && key <= reachedStep;
    const isDisabled = waiting || key > reachedStep;

    const classes = classNames(
      'text-center',
      isOrderCompleted
        ? { [styles.active]: isActive, 'order-progress-bar-active-step': isActive }
        : {
          [styles.progressing_active]: isActive,
          'order-progress-bar-active-step': isActive,
          [styles.progressing_reached]: isReached,
          [styles.progressing_disabled]: isDisabled,
        },
    );

    return (
      /*
        eslint-disable
        jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-noninteractive-element-interactions
      */
      <li
        key={key}
        className={classes}
        onClick={() => handleStepClick(key)}
        data-cy={`order-progress-bar-step-${key}`}
      >
        <span className={styles.step_key}>{key}</span>
        {step.description}
      </li>
      /*
        eslint-enable
        jsx-a11y/click-events-have-key-events,
        jsx-a11y/no-noninteractive-element-interactions
      */
    );
  };

  return (
    <ul className={classNames(styles.view_test_progress_bar, 'list-unstyled')}>
      {steps.map((step) => renderStep(step))}
    </ul>
  );
};

OrderProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  currentStep: PropTypes.number,
  reachedStep: PropTypes.number,
  isOrderCompleted: PropTypes.bool,
  waiting: PropTypes.bool,
  onStepChange: PropTypes.func.isRequired,
};

OrderProgressBar.defaultProps = {
  steps: null,
  currentStep: null,
  reachedStep: null,
  isOrderCompleted: true,
  waiting: null,
};

export default OrderProgressBar;
