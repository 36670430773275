import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { NewOrderTransferModal } from 'admin/components';
import { Spinner } from 'components';
import { getLastOrderTransfers } from 'redux/modules/support';

import styles from './OrderTransfers.scss';

const mapStateToProps = (state) => ({
  waitingOrderTransfers: state.support.waitingOrderTransfers,
  orderTransfers: state.support.orderTransfers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getLastOrderTransfers,
}, dispatch);

class OrderTransfers extends Component {
  static propTypes = {
    waitingOrderTransfers: PropTypes.bool,
    orderTransfers: PropTypes.arrayOf(PropTypes.shape({})),
    getLastOrderTransfers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    waitingOrderTransfers: null,
    orderTransfers: [],
  };

  state = {
    showDialog: false,
  };

  componentDidMount() {
    this.updateList();
  }

  openDialog = () => {
    this.setState({
      showDialog: true,
    });
  }

  closeDialog = () => {
    this.setState({
      showDialog: false,
    });
    this.updateList();
  }

  updateList() {
    this.props.getLastOrderTransfers();
  }

  static renderOrder(order) {
    return (
      <tr key={`${order.order_id},${order.email}`}>
        <td>{order.order_id}</td>
        <td>{order.email}</td>
        <td>{order.full_name}</td>
        <td>{order.alias_user_email}</td>
        <td>{order.is_transferred ? 'yes' : 'no'}</td>
        <td>{order.created}</td>
      </tr>
    );
  }

  render() {
    return (
      <div className={`${styles.order_transfers} container`}>
        <Helmet title="Order Transfers" />
        <div className="clearfix">
          <button
            type="button"
            className={`${styles.btn_new_transfer} btn float-right`}
            disabled={false}
            onClick={this.openDialog}
          >
            + Transfer Order(s)
          </button>

          <h1>Order Transfers</h1>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Email</th>
              <th>Full name</th>
              <th>Ordered by</th>
              <th>Finished</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            {this.props.orderTransfers && this.props.orderTransfers.map(OrderTransfers.renderOrder)}
          </tbody>
        </table>

        {this.props.waitingOrderTransfers && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}

        <NewOrderTransferModal
          onClose={this.closeDialog}
          show={this.state.showDialog}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrderTransfers);
