import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import merge from 'lodash/merge';
import {
  getOrderStatus, changeOrderStatus, removeLabId,
} from 'redux/modules/support';
import { listRequisitionOrderFiles } from 'redux/modules/orders';
import Modal from 'react-bootstrap/Modal';

import {
  isFVT, isPanel, isTVT, isSingleGene,
  isSingleExome, isExomeFamily, isNEI,
  isSpark,
} from 'utils/forms';
import { OrderStatusModalPropTypes, OrderStatusModalDefaultProps } from './OrderStatusModalConstants';

import {
  testTypesForExomeFamilyOrders,
  testTypesForSingleExomeOrders,
  testTypesForFVTOrders,
  testTypesForPanelOrders,
  testTypesForTVTOrders,
  testTypesForSingleGeneOrders,
  testTypesForInternalOrders,
  defaultOrderTestType,
} from '../ChangeOrderStatusForm/ChangeOrderStatusConstants';
import ChangeOrderStatusForm, { shouldDisplayLimsWorkflow } from '../ChangeOrderStatusForm/ChangeOrderStatusForm';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const mapStateToProps = (state) => {
  const hasReqAttachment = (
    state.orders.requisitionOrderFiles
    && Object.keys(state.orders.requisitionOrderFiles).length > 0
  );

  return ({
    userInfo: state.auth.userInfo,
    orderStatus: state.support.orderStatus,
    isOrderStatusSet: state.support.isOrderStatusSet,
    settingOrderStatus: state.support.settingOrderStatus,
    changingOrderStatus: state.support.changingOrderStatus,
    isOrderStatusChanged: state.support.isOrderStatusChanged,
    error: state.support.error,
    printerNames: state.support.printerNames,
    sampleTypeOptions: state.support.sampleTypeOptions,
    hasReqAttachment,
  });
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrderStatus,
  changeOrderStatus,
  removeLabId,
  listRequisitionOrderFiles,
}, dispatch);

class OrderStatusModal extends Component {
  static propTypes = OrderStatusModalPropTypes;

  static defaultProps = OrderStatusModalDefaultProps;

  constructor() {
    super();
    this.state = {
      orderStatus: undefined,
      testTypeOptions: defaultOrderTestType,
    };
  }

  componentDidMount() {
    this.props.getOrderStatus({ order_id: this.props.orderId });
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isOrderStatusSet && this.props.isOrderStatusSet) {
      const orderStatus = merge({}, this.props.orderStatus);
      let testTypeOptions = defaultOrderTestType;

      if (orderStatus.production_location === 'United States') {
        testTypeOptions = this.getUSTestTypeOptions(this.props.orderStatus.special_type);
        const testTypeSelected = testTypeOptions.find((option) => (
          option.value === orderStatus.test_type
        ));

        // Auto select first test_type option if no selected option match
        if (!testTypeSelected) {
          orderStatus.test_type = testTypeOptions[0].value;
        }
      }

      if (!orderStatus.printer_name && this.props.printerNames) {
        orderStatus.printer_name = Object.keys(this.props.printerNames)[0];
      }
      orderStatus.number_of_samples = orderStatus.number_of_samples ? orderStatus.number_of_samples.toString() : '1';
      orderStatus.print_nucleus_id_label = orderStatus.print_nucleus_id_label || false;
      orderStatus.reason = orderStatus.on_hold_reason || null;
      orderStatus.has_lab_id = orderStatus.has_lab_id || false;

      this.setState({
        orderStatus,
        currentStatus: orderStatus.status,
        testTypeOptions,
      });
    }
    if (prevState.orderStatus && prevState.orderStatus.status !== 'sample_in_analysis' && this.state.orderStatus.status === 'sample_in_analysis') {
      this.props.listRequisitionOrderFiles({
        order_id: this.props.orderId,
        type: 'requisition_attachment',
      });
    }

    if (!prevProps.isOrderStatusChanged && this.props.isOrderStatusChanged) {
      this.props.hide();
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  componentWillUnmount() {
    this.props.resetOrderInfo();
  }

  /**
   * This can be upgraded to use as global feature later if necessary.
   * Current name is to clarify usage for target US production location
   * @param {string} specialType
   * @returns {Object}
   */
  getUSTestTypeOptions = (specialType) => {
    if (isSingleExome(specialType)) return testTypesForSingleExomeOrders;
    if (isExomeFamily(specialType)) return testTypesForExomeFamilyOrders;
    if (isFVT(specialType)) return testTypesForFVTOrders;
    if (
      isPanel(specialType)
      || isNEI(specialType)
      || isSpark(specialType)
    ) return testTypesForPanelOrders;
    if (isTVT(specialType)) return testTypesForTVTOrders;
    if (isSingleGene(specialType)) return testTypesForSingleGeneOrders;

    return testTypesForInternalOrders;
  }

  onChangeValue = (key, value) => {
    this.setState((state) => ({
      orderStatus: {
        ...state.orderStatus,
        [key]: value,
      },
    }));
  }

  changeStatus = () => {
    const { hasReqAttachment } = this.props;
    const { orderStatus } = this.state;

    const statusInAnalysis = orderStatus.status === 'sample_in_analysis';
    const haveOrderStatusChanged = this.props.orderStatus
      && this.props.orderStatus.status !== orderStatus.status;

    const shouldShowReqAttachmentWarning = haveOrderStatusChanged
      && orderStatus.is_land_mail_purchase
      && statusInAnalysis && !hasReqAttachment;

    const immediateSampleDisposalCategory = (
      orderStatus.sample_disposal_category === 'immediate_disposal'
      || orderStatus.sample_disposal_category_fm1 === 'immediate_disposal'
      || orderStatus.sample_disposal_category_fm2 === 'immediate_disposal'
    );

    if (shouldShowReqAttachmentWarning) {
      // eslint-disable-next-line no-restricted-globals
      const proceedAnyway = confirm('Req attachment is missing, are you sure you want to proceed?');

      if (!proceedAnyway) return;
    }

    const statusOnHoldOrInAnalysis = orderStatus.status === 'sample_on_hold' || statusInAnalysis;

    if (!orderStatus.is_checked && statusOnHoldOrInAnalysis && orderStatus.special_type !== 'blank') {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const proceedAnyway = confirm('This order has not been checked - are you sure you want to proceed?');
      if (!proceedAnyway) {
        return;
      }
    }

    if (statusInAnalysis && immediateSampleDisposalCategory) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const proceedAnyway = confirm('Are you sure you want to select immediate disposal?');
      if (!proceedAnyway) {
        return;
      }
    }

    if (orderStatus.status === 'sample_in_analysis' && orderStatus.belongs_to_internal_user) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      const proceedAnyway = confirm('This order is under an internal user - are you sure you want to proceed?');
      if (!proceedAnyway) {
        return;
      }
    }

    const data = {
      order_id: this.props.orderId,
      status: orderStatus.status,
      primary_sample_type: orderStatus.primary_sample_type,
      sample_collection_date: orderStatus.sample_collection_date,
      customer_sample_id: orderStatus.customer_sample_id,
      sample_disposal_category: orderStatus.sample_disposal_category,
      wes_secondary_findings_consent: orderStatus.wes_secondary_findings_consent,
      primary_sample_type_fm1: orderStatus.primary_sample_type_fm1,
      sample_collection_date_fm1: orderStatus.sample_collection_date_fm1,
      customer_sample_id_fm1: orderStatus.customer_sample_id_fm1,
      sample_disposal_category_fm1: orderStatus.sample_disposal_category_fm1,
      wes_secondary_findings_consent_fm1: orderStatus.wes_secondary_findings_consent_fm1,
      primary_sample_type_fm2: orderStatus.primary_sample_type_fm2,
      sample_collection_date_fm2: orderStatus.sample_collection_date_fm2,
      customer_sample_id_fm2: orderStatus.customer_sample_id_fm2,
      sample_disposal_category_fm2: orderStatus.sample_disposal_category_fm2,
      wes_secondary_findings_consent_fm2: orderStatus.wes_secondary_findings_consent_fm2,
      printer_name: orderStatus.printer_name,
      number_of_samples: (
        Number.parseInt(orderStatus.number_of_samples.parseInt, 10)
          ? Number.parseInt(orderStatus.number_of_samples.parseInt, 10)
          : orderStatus.number_of_samples
      ),

      primary_sample_type_individual_2: orderStatus?.primary_sample_type_individual_2,
      sample_collection_date_individual_2: orderStatus?.sample_collection_date_individual_2,
      customer_sample_id_individual_2: orderStatus?.customer_sample_id_individual_2,
      sample_disposal_category_individual_2: orderStatus.sample_disposal_category_individual_2,

      print_nucleus_id_label: orderStatus.print_nucleus_id_label,
      additional_lims_info: orderStatus.additional_lims_info,
      reason: orderStatus.reason,
      test_type: orderStatus.test_type,
    };
    if (shouldDisplayLimsWorkflow(orderStatus, orderStatus.status)) {
      // Set this only when workflow is shown
      data.lims_workflow = orderStatus.lims_workflow;
      data.lims_workflow_individual_2 = orderStatus.lims_workflow_individual_2;
      data.lims_workflow_fm1 = orderStatus.lims_workflow_fm1;
      data.lims_workflow_fm2 = orderStatus.lims_workflow_fm2;
    }
    this.props.changeOrderStatus(data);
  }

  removeLabId = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to remove the lab ID for this order?')) {
      this.props.removeLabId({
        order_id: this.props.orderId,
      });

      this.props.hide();
    }
  }

  render() {
    const {
      hide,
      show,
      error,
      changingOrderStatus: saving,
      settingOrderStatus: loading,
    } = this.props;
    const printerTypeOptions = (
      this.props.printerNames
        ? Object.entries(this.props.printerNames).map(([id, label]) => ({ value: id, label }))
        : []
    );
    const sampleTypeOptions = (
      this.props.sampleTypeOptions
        ? (
          Object.entries(this.props.sampleTypeOptions)
            .reduce((acc, [id, label]) => Object.assign(acc, {
              [id]: {
                question_option_id: id,
                question_option: label,
              },
            }), {})
        )
        : {}
    );

    const resultsReported = this.state.orderStatus && this.state.orderStatus.internal_status === 'results_reported';
    return (
      <Modal show={show} onHide={hide} size="xl" aria-labelledby="contained-modal-title-sm">
        <ModalHeader error={error} />
        <div>
          <ModalBody
            isOrderStatus={!!this.state.orderStatus}
            loading={loading}
            saving={saving}
          >
            <ChangeOrderStatusForm
              currentStatus={this.state.currentStatus}
              onChangeValue={this.onChangeValue}
              orderStatus={this.state.orderStatus}
              printerTypeOptions={printerTypeOptions}
              sampleTypeOptions={sampleTypeOptions}
              testTypeOptions={this.state.testTypeOptions}
            />
          </ModalBody>
          <ModalFooter
            changeStatus={this.changeStatus}
            error={error}
            hasLabId={this.state?.orderStatus?.has_lab_id}
            hide={hide}
            isOrderStatus={!!this.state.orderStatus}
            loading={loading}
            removeLabId={this.removeLabId}
            resultsReported={resultsReported}
            saving={saving}
          />
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusModal);
