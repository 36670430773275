import React from 'react';
import PropTypes from 'prop-types';

import styles from './ButtonContainer.scss';

const ButtonContainer = ({ onClick, children }) => (
  <button
    type="button"
    onClick={onClick}
    className={styles['button-container']}
  >
    {children}
  </button>
);

ButtonContainer.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

ButtonContainer.defaultProps = {
  onClick: null,
  children: null,
};

export default ButtonContainer;
