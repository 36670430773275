import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { InsuranceProgressBar, Spinner } from 'components';
import { downloadFileFunc, formatFileSize } from 'utils/forms';

import styles from './InsuranceCaseEditModal.scss';
import NewCommentInput from './NewCommentInput';
import NewCommentAttachment from './NewCommentAttachment';

export const steps = [
  {
    key: 1,
    status: 'benefit_investigation',
    description: 'Benefit Investigation',
  },
  {
    key: 2,
    status: 'patient_communication',
    description: 'Patient Communication',
  },
  {
    key: 3,
    status: 'prior_authorization',
    description: 'Prior Authorization',
    optional: true,
  },
  {
    key: 4,
    status: 'claim',
    description: 'Claim',
    optional: true,
  },
  {
    key: 5,
    status: 'payment_settlement',
    description: 'Payment Settlement',
  },
  {
    key: 6,
    hideLine: true,
    status: 'start_analysis',
    description: 'Start analysis',
    hideComments: true,
  },
  {
    key: 7,
    hideLine: true,
    status: 'cancel_test',
    description: 'Cancel Test',
    hideComments: true,
  },
];

function getStep(status) {
  return steps.find((step) => step.status === status) || { };
}

export default class InsuranceCaseEditModal extends Component {
  static propTypes = {
    hide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
    }).isRequired,
    resetInsuranceInfo: PropTypes.func.isRequired,
    changeInsuranceStatus: PropTypes.func.isRequired,
    changeInsuranceSubStatus: PropTypes.func.isRequired,
    toggleOwner: PropTypes.func.isRequired,
    addInsuranceItem: PropTypes.func.isRequired,
    getInsuranceItems: PropTypes.func.isRequired,
    getInsuranceStatus: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    order: PropTypes.shape({
      insurance_status: PropTypes.string,
      final_insurance_status: PropTypes.string,
      owner: PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})),
    getOrderFile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: [],
  };

  state = {
    status: 'benefit_investigation',
    currentStep: 'benefit_investigation',
    newItemVisible: false,
    downloadCount: 0,
  };

  componentDidMount() {
    const status = this.props.order.insurance_status;

    this.setState({
      status,
      finalStatus: this.props.order.final_insurance_status,
      currentStep: status,
      owner: this.props.order.owner,
      newItemVisible: false,
    });
    this.props.resetInsuranceInfo();
    this.props.getInsuranceStatus({ order_id: this.props.orderId }).then((action) => {
      this.setState({
        paStatus: action.result.pa_status,
        claimStatus: action.result.claim_status,
        hasSubStatus: true,
      });
    });
    if (status) {
      this.props.getInsuranceItems({ order_id: this.props.orderId, status_step: status });
    }
  }

  componentWillUnmount() {
    this.props.resetInsuranceInfo();
  }

  /* eslint-disable react/sort-comp */
  nextStep = () => {
    this.changeStep(getStep(this.state.currentStep).key + 1);
  }

  changeStep = (key) => {
    this.selectStep(steps[key - 1].status);
  }

  handleChangeOwner = (event) => {
    const owner = event.target.value;

    this.props.toggleOwner({
      order_id: this.props.orderId,
      owner,
    });
    this.setState({
      owner,
    });
  }

  toggleEnabled = () => {
    const step = this.state.currentStep;

    if (this.isEnabled(step)) {
      this.disable(step);
    } else {
      this.enable(step);
    }
  }

  handleFileChange = (fileChangeEvent) => {
    const filesInput = fileChangeEvent.target;
    const file = filesInput.files[0];
    const maxFileSize = 10000000; // 10 MB

    if (file.size > maxFileSize) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      alert('The file you are trying to upload exceeds the maximum file size.');
      return;
    }
    const reader = new FileReader();

    this.setState({
      fileName: file.name,
      fileSize: file.size,
      fileMimeType: undefined,
      base64Data: undefined,
    });

    reader.onload = (event) => {
      const dataUrl = event.target.result;
      const mimeType = dataUrl.split(';')[0].split(':')[1];
      const content = dataUrl.split(',')[1];

      this.setState({
        fileMimeType: mimeType || 'application/octet-stream',
        base64Data: content,
      });
    };

    reader.onerror = (event) => {
      // eslint-disable-next-line no-console
      console.log('Error: ', event.target.error);
    };

    reader.readAsDataURL(file);
  }

  saveNewItem = () => {
    const statusStep = this.state.currentStep;

    this.setState({
      savingItem: true,
    });

    this.props.addInsuranceItem({
      order_id: +this.props.orderId,
      status_step: statusStep,
      comment: this.state.comment,
      follow_up: this.state.followUp,
      file_name: this.state.fileName,
      file_size: this.state.fileSize,
      file_mime_type: this.state.fileMimeType,
      base64_data: this.state.base64Data,
    }).then(() => {
      this.props.getInsuranceItems({
        order_id: this.props.orderId,
        status_step: statusStep,
      }).then(() => {
        this.setState({
          comment: '',
          followUp: '',
          fileName: undefined,
          fileSize: undefined,
          fileMimeType: undefined,
          base64Data: undefined,
          newItemVisible: false,
          savingItem: false,
        });
      });
    }).catch((error) => {
      this.setState({
        savingItem: false,
        errorMessage: `Add insurance item failed. ${error}`,
      });
      throw error;
    });
  }

  showNewItem = () => {
    this.setState({
      newItemVisible: true,
    });
  }

  handleChangeFinalStatus = (finalStatus) => {
    if (finalStatus !== this.state.finalStatus) {
      if (finalStatus === 'start_analysis') {
        // eslint-disable-next-line no-alert, no-restricted-globals
        if (!window.confirm('Are you sure you want to start analysis?')) {
          return;
        }
      } else if (finalStatus === 'cancel_test') {
        // eslint-disable-next-line no-alert, no-restricted-globals
        if (!window.confirm('Are you sure you want to cancel test?')) {
          return;
        }
      }
      this.props.changeInsuranceStatus({
        order_id: +this.props.orderId,
        status: finalStatus,
      }).catch((error) => {
        this.setState({
          savingItem: false,
          errorMessage: `Change insurance status failed. ${error}`,
        });
      });

      this.setState({
        finalStatus,
      });
    }
  }

  handleClickDeleteFile = (event) => {
    event.preventDefault();
    this.setState({
      fileName: undefined,
      fileSize: undefined,
      fileMimeType: undefined,
      base64Data: undefined,
    });
  }

  handleClose = () => {
    if (
      this.state.newItemVisible
      && (
        this.state.comment
        || this.state.followUp
        || this.state.fileName
      )
    ) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!window.confirm('You have not saved your entry, are you sure you want to close the dialog?')) {
        return;
      }
    }
    this.props.hide();
  }

  handleChangeComment = (value) => {
    this.setState({
      comment: value,
    });
  }

  handleChangeFollowUp = (value) => {
    this.setState({
      followUp: value,
    });
  }

  handleChangePaStatus(value) {
    this.props.changeInsuranceSubStatus({
      order_id: +this.props.orderId,
      status_step: this.state.currentStep,
      pa_status: value,
    });
    this.setState({
      paStatus: value,
    });
  }

  handleChangeClaimStatus(value) {
    this.props.changeInsuranceSubStatus({
      order_id: +this.props.orderId,
      status_step: this.state.currentStep,
      claim_status: value,
    });
    this.setState({
      claimStatus: value,
    });
  }

  handleClickFile(event, file) {
    event.preventDefault();
    this.setState((state) => ({
      downloadCount: state.downloadCount + 1,
    }));
    this.props.getOrderFile({
      order_id: this.props.orderId,
      file_id: file.file_id,
    }).then((action) => {
      this.setState((state) => ({ downloadCount: state.downloadCount - 1 }));
      downloadFileFunc(action.result);
    }).catch((error) => {
      this.setState((state) => ({ downloadCount: state.downloadCount - 1 }));
      throw error;
    });
  }

  selectStep(step) {
    let status = this.state.status;
    const stepInfo = getStep(step);

    if (step !== status) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (window.confirm(`Do you want to change status to '${stepInfo.description.toLowerCase()}'?`)) {
        this.props.changeInsuranceStatus({
          order_id: +this.props.orderId,
          status: step,
        }).catch((error) => {
          this.setState({
            savingItem: false,
            errorMessage: `Change insurance status failed. ${error}`,
          });
        });
        status = step;
      }
    }

    this.props.getInsuranceItems({ order_id: +this.props.orderId, status_step: step }).then(() => {
      this.setState({
        currentStep: step,
        status,
      });
    });
  }

  isEnabled(step) {
    switch (step) {
      case 'prior_authorization': return this.state.paStatus !== null;
      case 'claim': return this.state.claimStatus !== null;
      case 'start_analysis':
      case 'cancel_test':
      default: return true;
    }
  }

  enable(step) {
    switch (step) {
      case 'prior_authorization': this.handleChangePaStatus('in_process'); break;
      case 'claim': this.handleChangeClaimStatus('in_process'); break;
      default:
    }
  }

  disable(step) {
    switch (step) {
      case 'prior_authorization': this.handleChangePaStatus(null); break;
      case 'claim': this.handleChangeClaimStatus(null); break;
      default:
    }
  }

  renderCommentSection() {
    const {
      comment,
      followUp,
      fileName,
      fileMimeType,
    } = this.state;
    const items = this.props.items || [];
    const newItemVisible = this.state.newItemVisible || items.length === 0;
    const canSaveNewItem = (comment || followUp || fileName) && !(fileName && !fileMimeType);

    return (
      <div>
        {this.renderCommentList(items)}
        {newItemVisible && (this.renderNewComment())}
        <p>
          {newItemVisible && <button type="button" className="btn btn-default" disabled={!canSaveNewItem || this.state.savingItem} onClick={this.saveNewItem}>Save</button>}
          {!newItemVisible && <button type="button" className="btn btn-default" onClick={this.showNewItem}>+</button>}
          {this.state.savingItem
            && (
            <span className={styles.spinner_inline}>
              <Spinner />
            </span>
            )}
        </p>
      </div>
    );
  }

  renderCommentList(items) {
    return (
      <div>
        {items.map((item) => (
          <div key={item.insurance_item_id} className={styles.communicationRow}>
            <div className={styles.fieldRow}>
              <div className={styles.field} style={{ flex: 2 }}>
                <label className="col-form-label">Comment</label>
                <div>{item.comment}</div>
              </div>
              <div className={styles.field} style={{ flex: 2 }}>
                <label className="col-form-label">Follow-up</label>
                <div>{item.follow_up}</div>
              </div>
              <div className={styles.field} style={{ flex: 1 }}>
                <label className="col-form-label">Attachment</label>
                {item.file_name && (
                <div style={{ fontSize: 14 }}>
                  <button
                    type="button"
                    onClick={(event) => this.handleClickFile(event, item)}
                    className="btn btn-link"
                  >
                    {item.file_name}
                    {' '}
                    (
                    {formatFileSize(item.file_size)}
                    )
                  </button>
                </div>
                )}
              </div>
            </div>
            <p>
              {item.user_name}
              ,
              {' '}
              {item.timestamp}
            </p>
          </div>
        ))}
      </div>
    );
  }

  renderNewComment() {
    const {
      comment,
      followUp,
      fileName,
      fileSize,
      fileMimeType,
    } = this.state;

    return (
      <div className={styles.communicationRow}>
        <div className={styles.fieldRow}>
          <NewCommentInput
            label="Comment"
            value={comment}
            onChange={this.handleChangeComment}
          />
          <NewCommentInput
            label="Follow-up"
            value={followUp}
            onChange={this.handleChangeFollowUp}
          />
          <NewCommentAttachment
            fileName={fileName}
            fileMimeType={fileMimeType}
            fileSize={fileSize}
            onFileChange={this.handleFileChange}
            onFileDelete={this.handleClickDeleteFile}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      show,
    } = this.props;
    const {
      status,
      finalStatus,
      currentStep,
      hasSubStatus,
      owner,
    } = this.state;
    const stepInfo = getStep(currentStep);
    const enabled = this.isEnabled(currentStep);
    const progressBarSteps = steps.map((step) => ({
      ...step,
      disabled: !this.isEnabled(step.status),
    }));

    return (
      <Modal show={show} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-sm" className={styles.insurance_edit_modal}>
        <Modal.Header closeButton>
          <div className="w-100">
            <div style={{ marginTop: 10 }}>
              <span className="form-check radio" style={{ display: 'inline', paddingRight: 20 }}>
                <input id="owner_sun" className="form-check-input" type="radio" value="sun" checked={owner === 'sun'} onChange={this.handleChangeOwner} />
                <label htmlFor="owner_sun" className="form-check-label">Sun Knowledge</label>
              </span>
              <span className="form-check radio" style={{ display: 'inline' }}>
                <input id="owner_bpg" className="form-check-input" type="radio" value="bpg" checked={owner === 'bpg'} onChange={this.handleChangeOwner} />
                <label htmlFor="owner_bpg" className="form-check-label">Blueprint Genetics</label>
              </span>
            </div>
            <h2 style={{ marginTop: 30 }}>Edit insurance case</h2>
            <InsuranceProgressBar
              status={getStep(status).key}
              finalStatus={getStep(finalStatus).key}
              currentStep={getStep(currentStep).key}
              changeStep={this.changeStep}
              changeFinalStatus={this.handleChangeFinalStatus}
              steps={progressBarSteps}
            />
            <h3>{stepInfo.description}</h3>

            <div>
              {hasSubStatus && stepInfo.optional && (
              <div className={styles.toggleWrapper}>
                <span className={!enabled ? 'bold' : ''}>
                  No
                </span>
                <div>
                  <input
                    id="stepEnabledToggle"
                    name="orderType"
                    className={styles.toggle}
                    type="checkbox"
                    checked={enabled}
                    onChange={this.toggleEnabled}
                  />
                  <label htmlFor="stepEnabledToggle" />
                </div>
                <span className={enabled ? 'bold' : ''}>
                  Yes
                </span>
              </div>
              )}
              {enabled && currentStep === 'prior_authorization' && (
              <div>
                <label className="col-form-label">Prior Authorization Status</label>
                <div className="select" style={{ width: 250 }}>
                  <span className="arrow" />
                  <select className="form-control" value={this.state.paStatus} onChange={(event) => this.handleChangePaStatus(event.target.value)}>
                    <option value="in_process">PA in process</option>
                    <option value="pending">PA pending</option>
                    <option value="denied">PA denied</option>
                    <option value="approved">PA approved</option>
                  </select>
                </div>
              </div>
              )}
              {enabled && currentStep === 'claim' && (
              <div>
                <label className="col-form-label">Claim Status</label>
                <div className="select" style={{ width: 250 }}>
                  <span className="arrow" />
                  <select className="form-control" value={this.state.claimStatus} onChange={(event) => this.handleChangeClaimStatus(event.target.value)}>
                    <option value="in_process">Claim in process</option>
                    <option value="pending">Claim pending</option>
                    <option value="denied">Claim denied</option>
                    <option value="paid">Claim paid</option>
                  </select>
                </div>
              </div>
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {currentStep && enabled && !stepInfo.hideComments && this.renderCommentSection()}
          {this.state.errorMessage && (
            <div className="text-danger">{this.state.errorMessage}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.state.downloadCount > 0
            && (
            <span className="float-left">
              <div className={styles.spinner_inline}>
                <Spinner />
              </div>
              <span className={styles.sansSerif}>Downloading file...</span>
            </span>
            )}
          <button type="button" className="btn btn-default" onClick={this.handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}
