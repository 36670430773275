import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as support from 'redux/modules/support';
import { isUsTerritoryCountryCode } from 'utils/forms';

const useUserFormOptions = () => {
  const restrictedPanels = useSelector(support.restrictedPanelsSelector);
  const hospitals = useSelector(support.hospitalsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(support.getHospitals());
  }, [dispatch]);

  useEffect(() => {
    dispatch(support.getRestrictedPanels());
  }, [dispatch]);

  const restrictedPanelOptions = useMemo(() => (restrictedPanels
    ? restrictedPanels.map(({ id: key, name: value }) => ({ key, value }))
    : []), [restrictedPanels]);

  const hospitalOptions = useMemo(() => (hospitals
    ? hospitals.map(({ hospital_id: key, hospital_name: value }) => ({ key, value }))
    : []), [hospitals]);

  const usHospitalIds = useMemo(() => hospitals
    .filter((hospital) => isUsTerritoryCountryCode(hospital.country_code))
    .map((hospital) => parseInt(hospital.hospital_id, 10)), [hospitals]);

  return {
    hospitalOptions,
    restrictedPanelOptions,
    usHospitalIds,
  };
};

export default useUserFormOptions;
