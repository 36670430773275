import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';

const SampleIdInput = ({
  customerSampleId, handleInputValue, onChangeValue, label,
}) => (
  <LabelInput
    inputSize="6"
    label={label}
    labelSize="6"
    name="customer_sample_id"
    onChange={(event) => onChangeValue('customer_sample_id', handleInputValue(event))}
    type="text"
    value={customerSampleId}
  />
);

SampleIdInput.propTypes = {
  customerSampleId: PropTypes.string,
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

SampleIdInput.defaultProps = {
  customerSampleId: null,
};

export default SampleIdInput;
