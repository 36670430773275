import React from 'react';
import PropTypes from 'prop-types';

import styles from './ViewOrderInfo.scss';

const GeneListHeading = ({ children }) => (
  <h5 className={styles.selected_genes_list_heading}>
    {children}
  </h5>
);

GeneListHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GeneListHeading;
