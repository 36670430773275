import PropTypes from 'prop-types';

import {
  ORDER_ATTACHMENT_TYPE,
  REQUISITION_ATTACHMENT_TYPE,
  BILLING_ATTACHMENT_TYPE,
  OTHER_ATTACHMENT_TYPE,
} from 'helpers/constants/fileTypeConstants';

export default PropTypes.oneOf([
  ORDER_ATTACHMENT_TYPE,
  BILLING_ATTACHMENT_TYPE,
  REQUISITION_ATTACHMENT_TYPE,
  OTHER_ATTACHMENT_TYPE,
]);
