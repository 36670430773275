import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Page, { initPageState, navigateBack } from 'components/Page';
import ViewBlankOrderForm from 'admin/components/ViewBlankOrderForm';
import OrderDetailModal from 'components/OrderDetailModal/OrderDetailModal';
import * as orderActions from 'redux/modules/orders';
import PhiFreeSummaryFloatingTextArea from 'admin/components/ClinicalSummaryFloatingTextArea/PhiFreeSummaryFloatingTextArea';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrder: orderActions.getOrder,
  resetOrderInfo: orderActions.resetOrderInfo,
  savePHIFreeSummary: orderActions.savePHIFreeSummary,
}, dispatch);

class ViewOrderPage extends Component {
  static propTypes = {
    // Fields
    match: PropTypes.shape({
      params: PropTypes.shape({
        orderId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({}).isRequired,

    // Methods
    getOrder: PropTypes.func.isRequired,
    resetOrderInfo: PropTypes.func.isRequired,
    savePHIFreeSummary: PropTypes.func.isRequired,
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
    }).isRequired,
  };

  state = { ...initPageState(), phiFreeSummary: '' };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.resetOrderInfo();
  }

  onChangePhiFreeSummary(phiFreeSummary) {
    this.setState({ phiFreeSummary });
  }

  async onSavePhiFreeSummary() {
    const {
      match,
      savePHIFreeSummary,
    } = this.props;

    const {
      orderId,
    } = match.params;

    try {
      await savePHIFreeSummary(orderId, this.state.phiFreeSummary);
      this.fetchData();
    } catch (err) {
      // TODO: error handling
    }
  }

  getTitle = () => {
    const {
      data: order,
    } = this.state;

    return (
      order && order.order_id
        ? `Order Summary ID ${order.order_id}`
        : 'Order Summary'
    );
  }

  async fetchData() {
    try {
      const data = await this.fetchOrder();
      this.setState({
        data,
        phiFreeSummary: data.phi_free_summary || '',
        page: {
          loading: false,
          error: null,
        },
      });
    } catch (err) {
      this.setState({
        page: {
          loading: false,
          error: err.message,
        },
      });
    }
  }

  fetchOrder() {
    const {
      match,
      getOrder,
    } = this.props;

    const {
      orderId,
    } = match.params;

    return getOrder(orderId, {
      get_order_kit_info: true,
      get_custom_panel_info: true,
      show_transfer: true,
    });
  }

  renderPage = () => {
    const {
      location,
      history,
    } = this.props;

    const {
      data: order,
      page,
    } = this.state;

    if (!order) {
      return null;
    }

    const isPhiFreeSummaryTextAreaVisible = () => order && this.props.userInfo.user_role === 'geneticist';
    const isPhiFreeSummaryReadOnly = () => (!order || order.status !== 'sample_in_analysis');

    const exitConfirmationDueToChangesInPhiFreeSummaryNeeded = () => {
      if (this.state.phiFreeSummary === '' && order.phi_free_summary === null) {
        return false;
      }
      if (this.state.phiFreeSummary !== order.phi_free_summary) {
        return true;
      }
      return false;
    };

    const onModalClose = () => {
      if (exitConfirmationDueToChangesInPhiFreeSummaryNeeded()) {
        const confirmUnsavedPhiFreeSummary = window.confirm('Closing this page you will lose all the unsaved changes in the PHI-free summary. Do you want to leave this page?');
        if (!confirmUnsavedPhiFreeSummary) {
          return;
        }
      }
      navigateBack(location, history, '/orders');
    };

    switch (order.special_type) {
      case 'blank':
        return (
          <ViewBlankOrderForm order={order} />
        );

      default:
        return (
          <>
            {isPhiFreeSummaryTextAreaVisible()
            && (
            <PhiFreeSummaryFloatingTextArea
              readOnly={isPhiFreeSummaryReadOnly()}
              originalPhiFreeSummary={order.phi_free_summary}
              phiFreeSummary={this.state.phiFreeSummary}
              onChangePhiFreeSummary={
                (phiFreeSummary) => this.onChangePhiFreeSummary(phiFreeSummary)
              }
              onSavePhiFreeSummary={(phiFreeSummary) => this.onSavePhiFreeSummary(phiFreeSummary)}
            />
            )}
            <OrderDetailModal
              phiFreeSummaryTextAreaVisible={isPhiFreeSummaryTextAreaVisible()}
              hide={onModalClose}
              error={page.error}
            />
          </>
        );
    }
  }

  render() {
    const {
      page,
    } = this.state;

    return (
      <Page
        cols={10}
        offsetCols={1}
        previousPageLink="/orders"
        previousPageLabel="orders"
        title={this.getTitle()}
        render={this.renderPage}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ViewOrderPage);
