import React from 'react';
import PropTypes from 'prop-types';

const CommonCell = ({ cellData }) => (
  <div>
    {cellData}
  </div>
);

CommonCell.propTypes = {
  cellData: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]).isRequired,
};

export default CommonCell;
