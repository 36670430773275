import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportStatement extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
    isExomeSpecialTypes: PropTypes.bool,
  }


  render() {
    const {orderReport, isExomeSpecialTypes} = this.props;
    const styles = require('./_OrderReportStatement.scss');

    return (
      <div className="report-section">
        <h2>Statement</h2>

        {orderReport.clinical_history &&
          <div>
            <h3 className="h4">Clinical history</h3>
            <div dangerouslySetInnerHTML={{__html: orderReport.clinical_history}}></div>
          </div>
        }

        {orderReport.clinical_report &&
          <div className={styles.clinical_report_content}>
            <h3 className="h4">Clinical report</h3>
            <div dangerouslySetInnerHTML={{__html: orderReport.clinical_report}}></div>
          </div>
        }

        {orderReport.version >= 7 && isExomeSpecialTypes && orderReport.candidate_comments &&
          <div dangerouslySetInnerHTML={{__html: orderReport.candidate_comments}}></div>
        }

        {orderReport.conclusion &&
          <div>
            <h3 className="h4">Conclusion</h3>
            <div dangerouslySetInnerHTML={{__html: orderReport.conclusion}}></div>
          </div>
        }

        {orderReport.conclusion_fi &&
          <div>
            <h3 className="h4">Yhteenveto</h3>
            <div dangerouslySetInnerHTML={{__html: orderReport.conclusion_fi}}></div>
          </div>
        }

        {orderReport.confirmation &&
          <div>
            <h3 className="h4">Confirmation</h3>
            <div dangerouslySetInnerHTML={{__html: orderReport.confirmation}}></div>
          </div>
        }

        {orderReport.processing &&
          <div>
            <h3 className="h4">Process</h3>
            <table className="table">
              <thead>
                <tr className={styles.report_heading_row}>
                  <th>Step</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(orderReport.processing).map(key =>
                    <tr key={key}>
                      <td>{orderReport.processing[key].step}</td>
                      <td>{orderReport.processing[key].date_str}</td>
                    </tr>
                )}
              </tbody>
            </table>
          </div>
        }
      </div>
    );
  }
}
