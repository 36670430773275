import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';
import QuestionPropTypes from 'types/QuestionPropTypes';

const SelectPreview = ({ question }) => (
  <LabelSelect
    label={question.question}
    labelSize="6"
    inputSize="6"
    name={question.name}
    options={question.question_options.map((option) => ({
      key: option.question_option_id,
      value: option.question_option,
      label: option.question_option,
    }))}
    onChange={() => {}}
    placeholder="Select ..."
  />
);

SelectPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default SelectPreview;
