import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { NewOrderShareModal } from 'admin/components';
import { Spinner, PrimaryButton } from 'components';
import {
  getLastOrderShares,
  waitingOrderSharesSelector,
  orderSharesSelector,
  shareOrderAdmin,
  shareAllOrdersAdmin,
} from 'redux/modules/support';
import styles from './OrderShares.scss';

const OrderShares = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [showShareAllOrders, setShowShareAllOrders] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const waitingOrderShares = useSelector(waitingOrderSharesSelector);
  const orderShares = useSelector(orderSharesSelector);
  const labelText = showShareAllOrders ? 'Share All Orders' : 'Share Order';

  useEffect(() => {
    dispatch(getLastOrderShares());
  }, [dispatch]);

  const openDialog = () => {
    setShowDialog(true);
    setSuccessMessage('');
  };

  const openDialogForAllOrders = () => {
    setShowDialog(true);
    setShowShareAllOrders(true);
    setSuccessMessage('');
  };

  const closeDialog = () => {
    setShowDialog(false);
    setShowShareAllOrders(false);
    dispatch(getLastOrderShares());
  };

  const showSuccessMessage = async (shareOption, data) => {
    const response = await dispatch(shareOption(data));
    if (response) {
      setSuccessMessage(response.result);
    }
  };

  const handleCreate = (data) => {
    if (!showShareAllOrders) {
      showSuccessMessage(shareOrderAdmin, data);
    } else {
      showSuccessMessage(shareAllOrdersAdmin, data);
    }
  };

  const renderOrder = (order) => (
    <tr key={`${order.order_id},${order.email}`}>
      <td>{order.order_id}</td>
      <td>{order.email}</td>
      <td>{order.full_name}</td>
      <td>{order.created}</td>
    </tr>
  );

  return (
    <div className={`${styles.order_transfers} container`}>
      <Helmet title="Order Sharing" />
      <h1>Order Sharing</h1>
      <div className="clearfix mb-sm">
        <PrimaryButton
          labelText="+ Share All Orders"
          onClick={openDialogForAllOrders}
          additionalClass="btn_left"
        />
        <PrimaryButton
          labelText="+ Share Order"
          onClick={openDialog}
          additionalClass="btn_right"
        />
        {successMessage && (
          <p className={styles.center}>
              {successMessage}
          </p>
        )}
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Order</th>
            <th>Email</th>
            <th>Full name</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {orderShares && orderShares.map(renderOrder)}
        </tbody>
      </table>

      {waitingOrderShares && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}

      <NewOrderShareModal
        onClose={closeDialog}
        show={showDialog}
        showShareAllOrders={showShareAllOrders}
        labelText={labelText}
        handleCreate={handleCreate}
      />
    </div>
  );
};

export default OrderShares;
