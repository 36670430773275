import React from 'react';
import PropTypes from 'prop-types';

import styles from './PatientInfo.scss';

const PatientInfo = ({
  name,
  ssn,
  dob,
  order_id: orderId,
  lab_id: labId,
}) => (
  <div>
    <div className={`row ${styles.order_status_header_row}`}>
      <div className="col-md-2">PATIENT</div>
      <div className="col-md-2">Patient ID/MRN</div>
      <div className="col-md-2">DOB</div>
      <div className="col-md-2">ORDER ID</div>
      <div className="col-md-2">LAB ID</div>
    </div>
    <div className={`row ${styles.order_status_detail_row}`}>
      <div className="col-md-2">{name}</div>
      <div className={`col-md-2 ${styles.break_text}`}>{ssn}</div>
      <div className="col-md-2">{dob}</div>
      <div className="col-md-2">{orderId}</div>
      <div className="col-md-2">{labId}</div>
    </div>
  </div>
);

PatientInfo.propTypes = {
  name: PropTypes.string.isRequired,
  ssn: PropTypes.string.isRequired,
  dob: PropTypes.string.isRequired,
  order_id: PropTypes.number.isRequired,
  lab_id: PropTypes.string,
};

PatientInfo.defaultProps = {
  lab_id: null,
};

export default PatientInfo;
