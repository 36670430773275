/**
 *  Point of contact for component modules
 *
 *  ie: import { CounterButton, InfoBar } from 'components';
 *
 */

// Login view
export SignupForm from './_Login/Signup/SignupForm';
export RequestPasswordForm from './_Login/RequestPassword/RequestPasswordForm';
export ForgotPasswordForm from './_Login/ForgotPassword/ForgotPasswordForm';
export LoginForm from './_Login/LoginForm/LoginForm';
export CountrySelect from './_Login/CountrySelect/CountrySelect';
export TwoFAVerification from './_Login/TwoFAVerification/TwoFAVerification';

// Order list
export OrderList from './OrderList/OrderList';
export OrderLogs from './OrderLogs/OrderLogs';

// Create order process
export OrderTestModal from './OrderTestModal/OrderTestModal';
export ConfirmationQuestions from './_CreateOrderProcess/_OrderTestFormOrderInfo/ConfirmationQuestions';

// View order process
export OrderDetailModal from './OrderDetailModal/OrderDetailModal';
export ReflexModal from './ReflexModal/ReflexModal';

// Input types
export LabelAttachments from './_InputTypes/LabelAttachments/LabelAttachments';
export LabelInput from './_InputTypes/LabelInput/LabelInput';
export LabelDateInput from './_InputTypes/LabelDateInput/LabelDateInput';
export ArrayCheckbox from './_InputTypes/ArrayCheckbox/ArrayCheckbox';
export LabelSelect from './_InputTypes/LabelSelect/LabelSelect';
export LabelRadioDisplay from './_InputTypes/LabelRadioDisplay/LabelRadioDisplay';
export LabelCheckboxDisplay from './_InputTypes/LabelCheckboxDisplay/LabelCheckboxDisplay';

// Order report
export OrderReportHeader from './_OrderReport/_OrderReportHeader/_OrderReportHeader';
export OrderReportPatientInfo from './_OrderReport/_OrderReportPatientInfo/_OrderReportPatientInfo';
export OrderReportTestResults from './_OrderReport/_OrderReportTestResults/_OrderReportTestResults';
export OrderReportVariants from './_OrderReport/_OrderReportVariants/_OrderReportVariants';
export OrderReportVariantsExome from './_OrderReport/_OrderReportVariantsExome/_OrderReportVariantsExome';
export OrderReportCandidateFindings from './_OrderReport/_OrderReportCandidateFindings/_OrderReportCandidateFindings';
export OrderReportVariantsWgs from './_OrderReport/_OrderReportVariantsWgs/_OrderReportVariantsWgs';
export OrderReportVariantsDeldup from './_OrderReport/_OrderReportVariantsDeldup/_OrderReportVariantsDeldup';
export OrderReportVariantsExomeDeldup from './_OrderReport/_OrderReportVariantsExomeDeldup/_OrderReportVariantsExomeDeldup';
export OrderReportPerformanceMetrics from './_OrderReport/_OrderReportPerformanceMetrics/_OrderReportPerformanceMetrics';
export OrderReportPerformanceMetricsExome from './_OrderReport/_OrderReportPerformanceMetricsExome/_OrderReportPerformanceMetricsExome';
export OrderReportTargetRegion from './_OrderReport/_OrderReportTargetRegion/_OrderReportTargetRegion';
export OrderReportStatement from './_OrderReport/_OrderReportStatement/_OrderReportStatement';
export OrderReportAppendixReferral from './_OrderReport/_OrderReportAppendixReferral/_OrderReportAppendixReferral';
export OrderReportAppendixGeneTable from './_OrderReport/_OrderReportAppendixGeneTable/_OrderReportAppendixGeneTable';
export OrderReportAppendixSequencingCoverage from './_OrderReport/_OrderReportAppendixSequencingCoverage/_OrderReportAppendixSequencingCoverage';
export OrderReportAppendixSummaryOfMethods from './_OrderReport/_OrderReportAppendixSummaryOfMethods/_OrderReportAppendixSummaryOfMethods';
export OrderReportAppendixArchivesHistory from './_OrderReport/_OrderReportAppendixArchivesHistory/_OrderReportAppendixArchivesHistory';
export OrderReportAppendixDownloads from './_OrderReport/_OrderReportAppendixDownloads/_OrderReportAppendixDownloads';
export OrderReportAppendixAddFindings from './_OrderReport/_OrderReportAppendixAddFindings/_OrderReportAppendixAddFindings';
export OrderReportAppendixSecondaryFindings from './_OrderReport/_OrderReportAppendixSecondaryFindings/_OrderReportAppendixSecondaryFindings';
export OrderReportAppendixNoneCodingVariants from './_OrderReport/_OrderReportAppendixNoneCodingVariants/_OrderReportAppendixNoneCodingVariants';

// Main navigation
export ProfileDropdown from './ProfileDropdown/ProfileDropdown';
export AdminDropdown from './AdminDropdown/AdminDropdown';
// Notification
export NotificationDropdown from './_Notification/NotificationDropdown/NotificationDropdown';

// misc
export Spinner from './Spinner/Spinner';

export AliasUserSelect from './AliasUserSelect/AliasUserSelect';
export CheckLoginModal from './CheckLoginModal/CheckLoginModal';
export FeatureMessageModal from './FeatureMessageModal/FeatureMessageModal';
export ConfirmModal from './ConfirmModal/ConfirmModal';

export ProgressBarComponent from '../admin/components/ProgressBarComponent/ProgressBarComponent';
export InsuranceCaseEditModal from './InsuranceCaseEditModal/InsuranceCaseEditModal';
export InsuranceCaseDetailModal from './InsuranceCaseDetailModal/InsuranceCaseDetailModal';
export InsuranceProgressBar from './InsuranceProgressBar/InsuranceProgressBar';
export OrderStatusModal from '../admin/components/OrderStatusModal/OrderStatusModal';

// Deploy countdown banner
export DeployCountdown from './DeployCountdown/DeployCountdown';

// StyleComponents
export PrimaryButton from './StyleComponents/Button/PrimaryButton';
export SecondaryButton from './StyleComponents/Button/SecondaryButton';
export TertiaryButton from './StyleComponents/Button/TertiaryButton';
export SearchInput from './StyleComponents/Input/SearchInput';
export ToggleCheckbox from './StyleComponents/Input/ToggleCheckbox';
