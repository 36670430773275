/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  Formik, Field, Form,
} from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { TwoFAVerification } from 'components';
import LabelInput from 'components/Formik/LabelInput';
import * as authActions from 'redux/modules/auth';
import * as notificationActions from 'redux/modules/notifications';
import queryString from 'query-string';
import { resolveRedirectTo } from 'router/RouteOrRedirect';
import { getNucleusLoginLink } from 'utils/envUtils';

import styles from './LoginForm.scss';

const userSchema = Yup.object().shape({
  email: Yup.string()
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  confirmSignup: authActions.confirmSignup,
  login: authActions.login,
  login2fa: authActions.login2fa,
  setUser: authActions.setUser,
  getNotifications: notificationActions.getNotifications,
}, dispatch);

class LoginForm extends Component {
  static propTypes = {
    confirmSignup: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    login2fa: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape({
        state: PropTypes.shape({
          message: PropTypes.string,
        }),
        search: PropTypes.string,
      }),
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
  };

  state = {
    step: 'login',
    message: '',
    user: null,
  };

  componentDidMount() {
    const {
      history,
      confirmSignup,
    } = this.props;

    const authQuery = queryString.parse(history.location.search).auth;

    if (history.location && history.location.state && history.location.state.message) {
      const messageFromSignUp = history.location.state.message;
      this.setState({ message: messageFromSignUp });
    }

    if (authQuery) {
      confirmSignup({ auth_token: authQuery })
        .then((res) => {
          if (res) {
            this.setState({ message: res });
          }
        })
        .catch((e) => {
          this.setState({ message: e.message });
        });
    }
  }

  login = (values) => this.props.login(values)
    .then((user) => {
      if (user.auth_token_type === 'login') {
        // Logged in
        this.completeLogin(user);
        return;
      }

      this.setState({
        step: '2fa',
        user,
      });
    })

  login2fa = (values) => this.props.login2fa(values.code).then((user) => {
    this.completeLogin({ ...this.state.user, ...user, auth_token_type: 'login' });
  })

  completeLogin = (user) => {
    const {
      history,
      location,
      setUser,
      getNotifications,
    } = this.props;

    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
    history.push(resolveRedirectTo(location, '/orders'));
    getNotifications();
  }

  render() {
    // MFA and login needs to be on the same url
    // to allow previous link redirection like
    // /orders/new/FVT001
    const { message, step } = this.state;

    // display TwoFAForm when loggin in so we can stay in the same url
    return (
      <div>
        {step === 'login' && (
          <div>
            <h1 className="no-margin-top">Nucleus</h1>
            <h2>Login</h2>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={userSchema}
              onSubmit={(values, actions) => {
                this.login(values)
                  .catch((e) => {
                    actions.setFieldError('general', e.message);
                  });
              }}
            >
              {(formProps) => (
                <Form className="form-group">
                  <Field
                    type="email"
                    placeholder="Your username"
                    name="email"
                    component={LabelInput}
                  />

                  <Field
                    type="password"
                    name="password"
                    placeholder="Your password"
                    component={LabelInput}
                  />

                  <button className="btn btn-default btn-block" type="submit">
                    Log In
                  </button>

                  {formProps.errors.general && (
                    <p id="login-error" className="text-danger">
                      {formProps.errors.general}
                      <span className={styles.login_error_select_country_text}>
                        or reselect location of your organization
                      </span>
                      <a
                        href={getNucleusLoginLink(process.env.REACT_APP_ENV)}
                        className={styles.login_error_select_country_link}
                      >
                        here
                      </a>
                    </p>
                  )}

                  {message && (
                    <p className="text-danger">{message}</p>
                  )}
                </Form>
              )}
            </Formik>

            <div className={styles.login_btn_wrapper}>
              <div className="float-left">
                <Link
                  to="/request_password"
                  replace
                  className={styles.btn_link_recover_pw}
                >
                  Recover password
                </Link>
              </div>

              <div className="float-right">
                <Link to="/signup" replace className={styles.btn_link_new_acc}>
                  Request an account
                </Link>
              </div>
            </div>
          </div>
        )}

        {step === '2fa' && (
          <TwoFAVerification onSubmit={this.login2fa} />
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LoginForm);
