import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './OrdersList.scss';

const PagesSection = ({ setPage, numberOfPages, children }) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const currentPageNumber = parseInt(query.page, 10) || 1;

  const [pageInputValue, setPageInputValue] = useState(currentPageNumber);

  useEffect(() => {
    setPageInputValue(currentPageNumber);
  }, [currentPageNumber]);

  const handleBlurPageNumber = () => {
    const pageNumber = parseInt(pageInputValue, 10);

    if (!pageNumber) {
      setPageInputValue(currentPageNumber);
    } else {
      setPage(pageNumber);
    }
  };

  const handleClickPage = (event, pageNumber) => {
    event.preventDefault();
    setPage(pageNumber);
  };

  const renderPageSelector = (pageNumber, currentPage, numPages) => (
    pageNumber !== currentPage ? (
      <button
        key={pageNumber}
        type="button"
        onClick={(event) => handleClickPage(event, pageNumber)}
      >
        {pageNumber}
      </button>
    ) : (
      <input
        key={pageNumber}
        className={styles.selectedPage}
        type="number"
        value={pageInputValue}
        onChange={(event) => setPageInputValue(event.target.value)}
        readOnly={numPages === 1}
        onBlur={handleBlurPageNumber}
        onKeyDown={(event) => { if (event.key === 'Enter') handleBlurPageNumber(); }}
      />
    ));

  const renderPagingHeader = (numPages, currentPage) => {
    const pages = [];
    let hasEllipsis = true;

    let rangeMin = currentPage - 5;
    let rangeMax = currentPage + 5;

    if (rangeMin < 1) {
      rangeMax += 1 - rangeMin;
    } else if (rangeMax > numPages - 2) {
      rangeMin -= rangeMax - (numPages - 2);
    }
    if (rangeMin > 1) {
      rangeMin += 1;
    }
    if (rangeMax < numPages - 2) {
      rangeMax -= 1;
    }

    for (let page = 1; page <= numPages; page += 1) {
      if (page === 1 || page === numPages || (page >= rangeMin && page <= rangeMax)) {
        pages.push(renderPageSelector(page, currentPage, numPages));
        hasEllipsis = false;
      } else if (!hasEllipsis) {
        pages.push(<span key={page}>...</span>);
        hasEllipsis = true;
      }
    }

    return numPages > 0 && (
      <div className={styles.pageSelector}>
        <button
          type="button"
          className={classNames({
            [styles.disabled]: currentPage <= 1,
          })}
          onClick={(event) => handleClickPage(event, currentPage - 1)}
        >
          &lt;
        </button>
        {pages}
        <button
          type="button"
          className={classNames({
            [styles.disabled]: currentPage >= numPages,
          })}
          onClick={(event) => handleClickPage(event, currentPage + 1)}
        >
          &gt;
        </button>
      </div>
    );
  };

  return (
    <>
      <div id="paging-header" className="mx-auto">{renderPagingHeader(numberOfPages, currentPageNumber)}</div>
      {children}
      <div className="mx-auto">{renderPagingHeader(numberOfPages, currentPageNumber)}</div>
    </>
  );
};

PagesSection.propTypes = {
  setPage: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default PagesSection;
