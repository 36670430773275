import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Search from '../Icons/Search';
import styles from './Input.scss';

const SearchInput = ({
  additionalClass, ...props
}) => {
  const cx = classNames.bind(styles);

  const formControl = cx('search_form_control', additionalClass);
  const inputControl = cx('search_input_control');
  const buttonControl = cx('search_button_control');

  return (
    <div className={formControl}>
      <input
        type="text"
        name="search"
        className={inputControl}
        {...props}
      />
      <button type="button" className={buttonControl}>
        <Search />
      </button>
    </div>
  );
};

SearchInput.propTypes = {
  additionalClass: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

SearchInput.defaultProps = {
  additionalClass: '',
};

export default SearchInput;
