import React from 'react';
import PropTypes from 'prop-types';

import QuestionPropTypes from 'types/QuestionPropTypes';

import { LabelCheckboxDisplay } from 'components';

const CheckboxPreview = ({ question }) => (
  <LabelCheckboxDisplay
    label={question.question}
    labelSize="6"
    inputSize="6"
    hint={question.hint}
    options={question.question_options}
    freeTextAnswer={question.free_text_option_question}
  />
);

CheckboxPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default CheckboxPreview;
