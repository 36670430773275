import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { downloadOrderFile } from 'redux/modules/orders';
import AttachmentTypeEnum from 'types/AttachmentTypeEnum';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';
import { filesSelector, listFiles } from 'redux/modules/orderAttachments';

const AttachmentList = ({ orderId, type, label }) => {
  const dispatch = useDispatch();

  const files = useSelector(filesSelector(type));

  useEffect(() => {
    dispatch(listFiles(orderId, type));
  }, [dispatch, orderId, type]);

  const handleFileClick = (fileId) => () => dispatch(downloadOrderFile(orderId, fileId));

  const fileIds = files ? Object.keys(files) : [];

  return (
    <div>
      <label className="view_order_modal_label">
        {label}
      </label>
      <span>
        <ul className="list-unstyled">
          {!fileIds.length && (
            <div>
              No attached file.
            </div>
          )}
          {fileIds.map((fileId) => {
            const file = files[fileId];
            if (file.virus_scan_status === 'clean' || file.uploaded_by_the_user) {
              return (
                <SecondaryButton
                  key={fileId}
                  onClick={handleFileClick(fileId)}
                  labelText={file.file_name}
                />
              );
            }
            return (
              <div>
                <span>{file.file_name}</span>
                {' '}
                <span>
                  Virus scan status:
                  {' '}
                  {file.virus_scan_status || 'unknown'}
                </span>
              </div>
            );
          })}
        </ul>
      </span>
    </div>
  );
};

AttachmentList.propTypes = {
  label: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  type: AttachmentTypeEnum.isRequired,
};

export default AttachmentList;
