const LOAD_PROVINCES = 'nucleus/LOAD_PROVINCES';
const LOAD_PROVINCES_SUCCESS = 'nucleus/LOAD_PROVINCES_SUCCESS';
const LOAD_PROVINCES_FAIL = 'nucleus/LOAD_PROVINCES_FAIL';

const initialState = {
  loading: false,
  loaded: false,
  provinces: [],
};

export default function orders(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PROVINCES: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_PROVINCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        provinces: action.result.provinces,
      };
    }
    case LOAD_PROVINCES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}

export function loadProvinces(countryId) {
  return {
    types: [LOAD_PROVINCES, LOAD_PROVINCES_SUCCESS, LOAD_PROVINCES_FAIL],
    request: (client) => client.get('get_provinces', { country_id: countryId }),
  };
}

export const provincesSelector = (state) => state.provinces.provinces;
