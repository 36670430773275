import React from 'react';
import PropTypes from 'prop-types';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';

import styles from './GroupedPatientHistoryQuestions.scss';

/**
 * Group is visible when at least one of the questions is visible.
 */
function isGroupVisible(group) {
  return group.questions.some((question) => !question.is_hidden_by_default);
}

const GroupedPatientHistoryQuestions = ({
  formProps,
  groups,
  namePrefix,
  addButtonLabel,
  removeButtonLabel,
}) => {
  let lastVisibleGroupIndex = 0;
  const lastGroup = groups.length ? groups[groups.length - 1] : null;

  const toggleQuestionsVisibility = (group, groupIndex, isHidden) => {
    group.questions.forEach((question, index) => {
      formProps.setFieldValue(`${namePrefix}[${groupIndex}].questions[${index}].is_hidden_by_default`, isHidden, false);
    });
  };

  const showNextGroup = (groupIndex) => {
    // Mark questions as visible for the next hidden group
    const group = groups[groupIndex];
    if (group) {
      toggleQuestionsVisibility(group, groupIndex, false);
    }
  };

  const hideGroup = (groupIndex) => {
    // Mark questions as hidden for the group
    const group = groups[groupIndex];
    if (group) {
      toggleQuestionsVisibility(group, groupIndex, true);
    }
  };

  return (
    <div className={styles.container}>
      {groups.map((group, groupIndex) => {
        const isVisible = isGroupVisible(group);
        if (isVisible) {
          lastVisibleGroupIndex = groupIndex;
        }

        if (!isVisible) {
          return null;
        }

        return (
          <div key={group.id}>
            <hr />

            <PatientHistoryQuestions
              questions={group.questions}
              namePrefix={`${namePrefix}[${groupIndex}]`}
            />
          </div>
        );
      })}

      {/* Show remove button when we have more than one visible group */}
      {lastVisibleGroupIndex > 0 && (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => hideGroup(lastVisibleGroupIndex)}
          >
            {removeButtonLabel}
          </button>
        </div>
      )}

      {/* Show add button when last group is hidden */}
      {lastGroup && !isGroupVisible(lastGroup) && (
        <div className="text-right">
          <button
            type="button"
            className="btn btn-default"
            onClick={() => showNextGroup(lastVisibleGroupIndex + 1)}
          >
            {addButtonLabel}
          </button>
        </div>
      )}

    </div>
  );
};

GroupedPatientHistoryQuestions.propTypes = {
  formProps: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    questions: PropTypes.arrayOf(PropTypes.shape({
      question_id: PropTypes.number.isRequired,
      is_hidden_by_default: PropTypes.bool,
      selected_option_id: PropTypes.string,
      question_options: PropTypes.arrayOf(PropTypes.shape({
        question_option_id: PropTypes.string.isRequired,
        conditional_question_id: PropTypes.number,
      })),
    })).isRequired,
  })).isRequired,
  namePrefix: PropTypes.string,
  addButtonLabel: PropTypes.string.isRequired,
  removeButtonLabel: PropTypes.string,
};

GroupedPatientHistoryQuestions.defaultProps = {
  namePrefix: '',
  removeButtonLabel: 'Remove',
};

export default GroupedPatientHistoryQuestions;
