import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './ActionButton.scss';

function getStyle(withMargin) {
  if (withMargin) {
    return {
      margin: '0 10px',
    };
  }

  return {};
}

const ActionButton = ({
  disabled,
  to,
  text,
  withMargin,
  id,
  hide,
}) => {
  if (hide) return null;

  return (
    <div className="float-right" style={getStyle(withMargin)}>
      <div className={styles.new_test_btn} id={id}>
        <Link
          to={to}
          className={classNames(styles.btn_new_test, 'btn', { disabled })}
          disabled={disabled}
        >
          {`+ ${text}`}
        </Link>
      </div>
    </div>
  );
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  withMargin: PropTypes.bool,
  id: PropTypes.string,
  hide: PropTypes.bool,
};

ActionButton.defaultProps = {
  disabled: false,
  withMargin: false,
  id: '',
  hide: false,
};

export default ActionButton;
