import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';

const SampleDisposalDateInput = ({ value }) => (
  <LabelInput
    hint="Automatically set by Nucleus backend when report has been submitted and data is sent to LIMS. Cannot be edited."
    inputSize="6"
    label="Sample disposal date"
    labelSize="6"
    name="sample_disposal_date"
    readOnly
    value={value}
  />
);

SampleDisposalDateInput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SampleDisposalDateInput;
