import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportAppendixSecondaryFindings extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
    appendixSecondaryFindingsShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string
  }

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  }

  render() {
    const {orderReport, appendixSecondaryFindingsShown, appendixHeading, onTogglePanel} = this.props;
    const styles = require('./_OrderReportAppendixSecondaryFindings.scss');
    let sortedVariantsArr;
    if (orderReport.secondary_findings) {
      const variantsArr = Object.keys(orderReport.secondary_findings).map(key => { return orderReport.secondary_findings[key]; });
      sortedVariantsArr = variantsArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }
    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixSecondaryFindingsShown')}>
          {appendixHeading}
          {appendixSecondaryFindingsShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixSecondaryFindingsShown ? '' : 'invisible'}>
          {orderReport.secondary_findings_appendix_visible &&
            <div>
              {orderReport.secondary_introduction && orderReport.secondary_introduction.split('\n').map((item, index) =>
                <p key={index} dangerouslySetInnerHTML={{__html: item}}>
                </p>
              )}
              {sortedVariantsArr &&
                <h3 className="h4">{orderReport.secondary_findings_title}</h3>
              }
              <table className="table v6-variants-table">
                  {sortedVariantsArr && sortedVariantsArr.map((variant, index) =>
                    <tbody key={index}>
                      <tr>
                        <td>
                          <span className="report-td-heading">Gene</span><br/>
                           <a className="link-gray" href={variant.gene_url} target="_blank"><span>{variant.gene}</span></a>
                        </td>
                        <td>
                          <span className="report-td-heading">Pos</span><br/>
                           <span>{variant.pos}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Transcript</span><br/>
                           <span>{variant.transcript}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Nomenclature</span><br/>
                           <span>{variant.nomenclature}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Consequence</span><br/>
                           <span>{variant.consequence}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Genotype</span><br/>
                           <span>{variant.genotype}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Classification</span><br/>
                          <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <span className="report-td-heading">ID</span><br/>
                          {variant.dbsnp_id_url &&
                            <a className="link-gray" href={variant.dbsnp_id_url} target="_blank"><span>{variant.dbsnp_id}</span></a>
                          }
                          {!variant.dbsnp_id_url &&
                            <span>{variant.dbsnp_id}</span>
                          }
                        </td>
                        {variant.gnomad &&
                          <td>
                            <span className="report-td-heading">gnomAD</span><br/>
                             <span>{variant.gnomad}</span>
                          </td>
                        }
                        {variant.exac &&
                          <td>
                            <span className="report-td-heading">EXAC AC/AN</span><br/>
                             <span>{variant.exac}</span>
                          </td>
                        }
                        <td>
                          <span className="report-td-heading">Polyphen</span><br/>
                           <span className={this.textColorClass(variant.polyphen.class)}>{variant.polyphen.value}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Sift</span><br/>
                           <span className={this.textColorClass(variant.sift.class)}>{variant.sift.value}</span>
                        </td>
                        <td colSpan="2">
                            <span className="report-td-heading">Muttaster</span><br/>
                            <span className={this.textColorClass(variant.muttaster.class)}>{variant.muttaster.value}</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <a className="link-gray" href={variant.omim_url} target="_blank"><span>{variant.omim}</span></a>
                        </td>
                        <td colSpan="2">
                          <span className="report-td-heading">Phenotype</span><br/>
                           <span>{variant.phenotype}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Inheritance</span><br/>
                           <span>{variant.inheritance}</span>
                        </td>
                        <td colSpan="2">
                          <span className="report-td-heading">Comment</span><br/>
                           <span>{variant.comment}</span>
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
              {orderReport.secondary_comments &&
                <div>
                  <h3 className="h4">Notes regarding secondary findings</h3>
                  <div dangerouslySetInnerHTML={{__html: orderReport.secondary_comments}}></div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}
