import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, yupToFormErrors,
} from 'formik';

import * as orders from 'redux/modules/orders';
import {
  Yup, requiredPositiveInteger, when, requiredString, patientQuestions,
} from 'components/Formik/validation';
import {
  keepOnlyFields, isExome, isSpark, isScreening,
} from 'utils/forms';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
} from 'utils/patientQuestions';
import { userInfoSelector } from 'redux/modules/auth';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import Checkbox from 'components/Formik/Checkbox';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';
import OrderUserInfo from 'components/_CreateOrderProcess/OrderUserInfo';
import useData from 'hooks/useData';
import { EXOME_PLUS, EXOME_FAMILY_PLUS, MSD } from 'helpers/constants/specialTypeConstants';
import CompletedOrderCustomerInformation from './CompletedOrderCustomerInformation';

import { resetOtherKits } from './OrderKit';
import useOrderInfoCommonData from './useOrderInfoCommonData';
import { AkouosSection, validateAkouosSection } from './AkouosSection';
import CommonSection, { commonSectionValidationSchema, getCommonSectionDefaultValues, getCommonSectionInitialValues } from './CommonSection';
import ReflexOrderSection from './ReflexOrderSection';
import FlexSection from './FlexSection';

// This validates only some of the fields. More complex logic is in validate method.
const validationSchema = Yup.object().shape({
  ...commonSectionValidationSchema,
  case_phone: when(
    'special_type',
    (specialType) => isSpark(specialType),
    requiredString(),
    Yup.string().ensure(),
  ),
  reflex_order_id: when(
    'expand_to_exome',
    true,
    requiredPositiveInteger('* Order ID must be a number (eg. 12345)'),
  ),
  custom_clinical_info: when('is_customized', true, requiredString()),
});

// TODO rename initialValues to something like formValues since this is super confusing
function getInitialValues({
  userInfo,
  orderInfo,
  deliveryInfo,
  sparkPanelQuestions,
  sampleTypePanelQuestions,
}) {
  return {
    ...getCommonSectionInitialValues({ userInfo, orderInfo, deliveryInfo }),
    special_type: orderInfo.special_type, // For form validation
    questions: prepareQuestionsForForm(
      Object.values(sparkPanelQuestions || {}).sort((a, b) => a.order_no - b.order_no),
    ),
    sample_type_questions: prepareQuestionsForForm(
      Object.values(sampleTypePanelQuestions).sort((a, b) => a.order_no - b.order_no),
    ),
    is_customized: orderInfo.is_customized,
    added_genes: orderInfo.added_genes || [],
    masked_genes: orderInfo.masked_genes || [],
    custom_clinical_info: orderInfo.custom_clinical_info || '',
    gene_request: orderInfo.gene_request,
    gene_collection_name: orderInfo.gene_collection_name,

    is_express_service: orderInfo.is_express_service,
    has_family_service: orderInfo.has_family_service,

    reflex_order_id: orderInfo.reflex_order_id,
    expand_to_exome: !!orderInfo.reflex_order_id,

    akouos_2: orderInfo.akouos_2,
    akouos_3: orderInfo.akouos_3,
    akouos_4: orderInfo.akouos_4,
    akouos_5: orderInfo.akouos_5,

    // eslint-disable-next-line no-nested-ternary
    akouos_6: typeof orderInfo.akouos_6 === 'boolean' ? (orderInfo.akouos_6 ? 'yes' : 'no') : undefined,
    // eslint-disable-next-line no-nested-ternary
    akouos_7: typeof orderInfo.akouos_7 === 'boolean' ? (orderInfo.akouos_7 ? 'yes' : 'no') : undefined,
    // eslint-disable-next-line no-nested-ternary
    akouos_8: typeof orderInfo.akouos_8 === 'boolean' ? (orderInfo.akouos_8 ? 'yes' : 'no') : undefined,
  };
}

const OrderTestFormOrderInfo = ({ hideModal }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector);
  const orderId = useSelector(orders.orderIdSelector);
  const failedToLoadPanelGeneLists = useSelector(orders.failedToLoadPanelGeneListsSelector);
  const panelGeneLists = useSelector(orders.panelGeneListsSelector);

  const [initialValues, setInitialValues] = useState({
    ...getCommonSectionDefaultValues(),
    questions: [],
    sample_type_questions: [],
    added_genes: [],
    masked_genes: [],
  });

  const {
    orderInfoDataLoading,
    orderInfoDataError,
    orderInfo,
    deliveryInfo,
    sampleTypePanelQuestions,
  } = useOrderInfoCommonData({ orderId, getCustomPanelInfo: true });

  const {
    data: sparkPanelQuestions,
    isLoading: sparkPanelQuestionsLoading,
    error: sparkPanelQuestionsError,
  } = useData(() => orders.getPanelQuestions(orderId, 2));

  useEffect(() => {
    if (!orderInfo || !deliveryInfo) {
      return;
    }

    setInitialValues(getInitialValues({
      userInfo,
      orderInfo,
      deliveryInfo,
      sparkPanelQuestions,
      sampleTypePanelQuestions,
    }));
  }, [deliveryInfo, orderInfo, sampleTypePanelQuestions, sparkPanelQuestions, userInfo]);

  useEffect(() => {
    if (!orderInfo) { return; }

    /* eslint-disable camelcase */
    const { panel_genes, product } = orderInfo;

    if (product.panel_tier_id) {
      dispatch(orders.setCurrentPanelTierId(product.panel_tier_id));
    }
    if (panel_genes) {
      dispatch(orders.setCurrentGeneCount(panel_genes.length));
    }
  }, [dispatch, orderInfo]);

  const error = orderInfoDataError || sparkPanelQuestionsError;
  const loading = orderInfoDataLoading || sparkPanelQuestionsLoading;

  const handleSubmit = async (values, actions) => {
    const specialType = orderInfo.special_type;

    actions.setFieldError('general', null);

    try {
      const payload = keepOnlyFields([
        'is_customized',
        'case_phone',
        'is_express_service',
        'has_family_service',
        'order_kit',
      ], values);

      if (values.is_customized) {
        const prepareGeneData = (gene) => ({
          prince_id: gene.id,
          hgnc_ext_id: gene.hgnc_ext_id,
          hgnc_symbol: gene.hgnc_symbol,
        });

        payload.added_genes = values.added_genes.map(prepareGeneData);
        payload.masked_genes = values.masked_genes.map(prepareGeneData);
        payload.custom_clinical_info = values.custom_clinical_info;
        payload.gene_request = values.gene_request;
        payload.gene_collection_name = values.gene_collection_name;
        payload.panel_tier_id = values.panel_tier_id;
      }

      payload.order_kit = resetOtherKits(payload.order_kit);

      await dispatch(orders.saveOrder(orderInfo.order_id, payload));
      await dispatch(orders.saveResultsDeliveryInfo(orderInfo.order_id, values.delivery_info));

      // Save answers
      if (values.questions.length) {
        const answers = constructQuestionAnswers(values.questions);
        await dispatch(orders.savePanelAnswers(orderInfo.order_id, answers, 2));
      }
      if (values.sample_type_questions.length) {
        const answers = constructQuestionAnswers(values.sample_type_questions);
        await dispatch(orders.savePanelAnswers(orderInfo.order_id, answers, 3));
      }

      if (orderInfo.product.code === 'EA0601') {
        const answers = {};

        for (let i = 2; i < 9; i += 1) {
          const key = `akouos_${i}`;
          if (values[key]) {
            answers[key] = typeof values[key] === 'boolean'
              ? values[key]
              : values[key] === 'yes';
          }
        }

        await dispatch(orders.saveHardCodedAkouosAnswers(orderInfo.order_id, answers));

        await dispatch(orders.saveBillingInfo({
          order_id: orderInfo.order_id,
          billing_method: 'sponsored',
        }));
      }

      const expandTypes = [EXOME_PLUS, EXOME_FAMILY_PLUS, MSD];
      if (expandTypes.includes(specialType)) {
        await dispatch(orders.saveReflex(
          orderInfo.order_id,
          values.expand_to_exome ? values.reflex_order_id : null,
        ));
      }
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  const validate = async (values) => {
    const errors = {};

    try {
      await validationSchema.validate(values, {
        abortEarly: false,
      });
    } catch (e) {
      const yupErrors = yupToFormErrors(e);
      Object.assign(errors, yupErrors);
    }

    if (values.is_customized
      && !values.masked_genes.length
      && !values.added_genes.length) {
      errors.is_customized = 'No customization has been made to the panel original gene list. Please uncheck Customize the panel checkbox to proceed.';
    }

    if (
      values.is_customized
      && panelGeneLists
      // eslint-disable-next-line max-len
      && (panelGeneLists.panel.genes.length - values.masked_genes.length + values.added_genes.length) < 2
    ) {
      errors.general = 'Minimum two genes are required.';
    }

    if (orderInfo.product.code === 'EA0601') {
      const akouosError = validateAkouosSection(values);
      if (akouosError) {
        errors.akouos = akouosError;
      }
    }

    const questionErrors = patientQuestions(values.questions, {
      requireAllCheckboxes: true,
    });
    if (questionErrors.length) {
      errors.questions = questionErrors;
    }

    if (failedToLoadPanelGeneLists) {
      errors.required_details = 'Failed to load required details';
    }

    return errors;
  };

  const isSupport = userInfo.user_role === 'support';
  const specialType = orderInfo && orderInfo.special_type;
  const isExomeTest = isExome(specialType);

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, actions) => (handleSubmit(values, actions))}
    >
      {(formProps) => (
        <Form autoComplete="off">
          <PromptIfDirty {...formProps} />
          <EditOrderStep
            {...formProps}
            hideModal={hideModal}
            isFirstStep
            error={error || formProps.errors.general}
            loading={loading}
            submit={() => formProps.submitForm()}
          >
            {orderInfo && (
              <div>
                {orderInfo.product.code === 'EA0601' && <AkouosSection orderInfo={orderInfo} isSupport={isSupport} />}

                {orderInfo.product.code !== 'EA0601' && <OrderUserInfo orderInfo={orderInfo} />}

                {isSupport && orderInfo.is_order_completed && orderInfo.product.code !== 'EA0601' && (
                  <CompletedOrderCustomerInformation orderInfo={orderInfo} />
                )}

                {orderInfo.product.code !== 'EA0601' && (
                  <>
                    <ReflexOrderSection orderInfo={orderInfo} />
                    <FlexSection orderInfo={orderInfo} />
                  </>
                )}

                {orderInfo.product.code !== 'EA0601'
                  && (
                    orderInfo.has_family_service
                    && !orderInfo.is_us_account
                  )
                  && (
                    <Field
                      name="has_family_service"
                      component={Checkbox}
                      label={(
                        <span>
                          Family Extension Service
                          <p className="small">
                            Available for up to 5 first degree family members
                            whose testing is ordered and samples are received
                            within 12 months from the original index
                            patient&apos;s report date.
                            {' '}
                            <strong>
                              For &apos;likely pathogenic&apos;
                              and &apos;pathogenic&apos; variants only.
                              Note that this service has a fee.
                            </strong>
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://blueprintgenetics.com/family-extension-service/">
                              Read more
                            </a>
                          </p>
                        </span>
                      )}
                      labelClassName="view_order_modal_label"
                      className="modal_checkbox"
                    />
                  )}

                {orderInfo.express_service_available && (
                  <Field
                    name="is_express_service"
                    component={Checkbox}
                    label="Express Service"
                    labelClassName="view_order_modal_label"
                    className="modal_checkbox"
                  />
                )}

                {!isSpark(specialType) && !isScreening(specialType) && (
                  <div className="mail-fax-description small">
                    We will send you a notification when results
                    are available here in Nucleus. In addition,
                    you may choose to have the results by regular mail or by fax.
                  </div>
                )}

                {isScreening(specialType) && (
                  <div className="mail-fax-description small">
                    We will send you a notification when results
                    are available here in Nucleus.
                  </div>
                )}

                <CommonSection
                  orderInfo={orderInfo}
                  phoneNumberRequired={isSpark(specialType)}
                />

                <PatientHistoryQuestions
                  enableHTMLHint={orderInfo.product.is_novodetect_product ?? false}
                  oneColumn
                  questions={formProps.values.questions}
                />

                <div className="small text-pink">(*) required</div>

                {isExomeTest && (
                  <div className="small">
                    The results of our Whole Exome tests are provided in a
                    detailed clinical statement. In addition, upon separate
                    request, filtered variant result files and raw data files
                    can be provided. Please contact our Support for more information.
                  </div>
                )}

                {(specialType === MSD) && (
                  <div className="small">
                    The results of our Development Disorder Panels are
                    provided in a detailed clinical statement. In addition, upon
                    separate request, filtered variant result files and raw data
                    files can be provided. Please contact our Support for more information.
                  </div>
                )}
              </div>
            )}
          </EditOrderStep>
        </Form>
      )}
    </Formik>
  );
};

OrderTestFormOrderInfo.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default OrderTestFormOrderInfo;
