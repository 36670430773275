import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Button.scss';

const DefaultButton = ({
  variant, additionalClass, labelText, ...props
}) => {
  const cx = classNames.bind(styles);

  const className = cx('shared', variant, additionalClass);

  return (
    <button
      type="button"
      className={className}
      {...props}
    >
      {labelText}
    </button>
  );
};

DefaultButton.propTypes = {
  labelText: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quartenary']).isRequired,
  additionalClass: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

DefaultButton.defaultProps = {
  additionalClass: '',
};

export default DefaultButton;
