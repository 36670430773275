import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPatientInfoQuestions,
  getPanelQuestions,
} from 'redux/modules/orders';

import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import {
  fmtRenderPositiveControlTextLabel,
  getKeyValuePairsForQuestions,
  getPatientInfoLabels,
  isFMT,
  isSpark,
  sortQuestions,
} from 'utils/forms';
import ConfirmationQuestions from 'components/_CreateOrderProcess/_OrderTestFormOrderInfo/ConfirmationQuestions';

import { mrtMinorPatientQuestions } from 'utils/patientQuestions';
import { SCREENING } from 'helpers/constants/specialTypeConstants';
import { patientsInfoPropTypes, defaultProps } from './OrderInfoPropTypes';
import {
  yesOrNo, capitalise, isThisFetalSample, fetalSampleAnswer, renderQuestion,
} from './PatientsInfoHelpers';

const PatientsInfo = () => {
  const dispatch = useDispatch();
  const orderInfo = useSelector((state) => state.orders.orderInfo);
  const patientInfoQuestions = useSelector((state) => state.orders.patientInfoQuestions);

  const [sampleTypeQuestions, setSampleTypeQuestions] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchSampleTypeQuestion = async () => {
      const sampleTypeQuestion = await dispatch(getPanelQuestions(orderInfo.order_id, 3));
      if (mounted) {
        setSampleTypeQuestions(sortQuestions(sampleTypeQuestion || {}));
      }
    };

    if (!patientInfoQuestions) {
      // Load only once
      dispatch(getPatientInfoQuestions({
        panel_id: orderInfo.product.id,
        order_id: orderInfo.order_id,
        page_no: 1,
      }));
    }
    fetchSampleTypeQuestion();
    return () => {
      mounted = false;
    };
  }, [dispatch, orderInfo, patientInfoQuestions]);

  const specialType = orderInfo.special_type;

  let patientInfoQuestionArr = [];
  let patientMinorInfoQuestionsArr = [];
  if (patientInfoQuestions) {
    const rawPatientInfoQuestionArr = Object.values(patientInfoQuestions)
      .sort((a, b) => a.order_no - b.order_no);
    patientInfoQuestionArr = mrtMinorPatientQuestions(rawPatientInfoQuestionArr);
    patientMinorInfoQuestionsArr = mrtMinorPatientQuestions(rawPatientInfoQuestionArr, true);
  }

  const indexPatientTestedAtBpG = orderInfo.index_patient_tested_at_bpg;
  const providePositiveControl = orderInfo.provide_positive_control;

  const patientInfoLabels = getPatientInfoLabels(specialType);

  const screeningSpecialType = specialType === SCREENING;

  return (
    <ViewOrderStep>
      <div>
        <label className="view_order_modal_label">
          {patientInfoLabels.firstName}
        </label>
        <span>{orderInfo.patient_first_name}</span>
      </div>
      <div>
        <label className="view_order_modal_label">
          {patientInfoLabels.lastName}
        </label>
        <span>{orderInfo.patient_last_name}</span>
      </div>
      <div>
        <label className="view_order_modal_label">
          {patientInfoLabels.dob}
        </label>
        <span>{orderInfo.patient_dob}</span>
      </div>
      <div>
        <label className="view_order_modal_label">
          {patientInfoLabels.ssn}
        </label>
        <span>{orderInfo.patient_ssn}</span>
      </div>

      {orderInfo.show_fetus_question && (
        <div>
          <div>
            <label className="view_order_modal_label">
              Is this a fetal sample?
            </label>
            <span>
              {fetalSampleAnswer(orderInfo.is_this_a_fetal_sample)}
            </span>
          </div>

          {isThisFetalSample(orderInfo) && (
            <div>
              <div>
                <label className="view_order_modal_label">
                  Is this sample from an ongoing pregnancy?
                </label>
                <span>
                  {capitalise(orderInfo.sample_from_ongoing_pregnancy)}
                </span>
              </div>
              {orderInfo.sample_from_ongoing_pregnancy === 'yes'
                && orderInfo.gestational_age && (
                  <div>
                    <label className="view_order_modal_label">
                      Gestational age
                    </label>
                    <span>{orderInfo.gestational_age}</span>
                  </div>
              )}
            </div>
          )}
        </div>
      )}

      {isFMT(specialType) && (
        <div>
          <div>
            <label className="view_order_modal_label">
              Index patient tested at Blueprint Genetics
            </label>
            <span>{yesOrNo(indexPatientTestedAtBpG)}</span>
          </div>

          {indexPatientTestedAtBpG && (
            <div>
              <div>
                <label className="view_order_modal_label">
                  Name of index patient
                </label>
                <span>{orderInfo.index_patient_name}</span>
              </div>
              <div>
                <label className="view_order_modal_label">
                  Index order ID
                </label>
                <span>{orderInfo.index_order_id}</span>
              </div>
              <div>
                <label className="view_order_modal_label">
                  Accept testing without positive control
                </label>
                <span>
                  {yesOrNo(orderInfo.accept_without_positive_control)}
                </span>
              </div>
            </div>
          )}

          {!indexPatientTestedAtBpG && (
            <div>
              <div>
                <label className="view_order_modal_label">
                  {fmtRenderPositiveControlTextLabel(orderInfo.created_date)}
                </label>
                <span>{yesOrNo(providePositiveControl)}</span>
              </div>
            </div>
          )}
          <div>
            <label className="view_order_modal_label">
              Indication for testing
            </label>
            <span>
              {orderInfo.testing_need ? orderInfo.testing_need : 'N/A'}
            </span>
          </div>
        </div>
      )}

      {patientInfoQuestionArr?.length > 0
        && patientInfoQuestionArr.map((question) => (
          <div key={question.question_id}>
            {renderQuestion(question)}
          </div>
        ))}
      {/* Ideally this field should come from patient histories. */}
      {isSpark(specialType) && (
        <div>
          <label className="view_order_modal_label">
            {patientInfoLabels.zipCode}
          </label>
          <span>{orderInfo.patient_zip}</span>
        </div>
      )}

      {patientMinorInfoQuestionsArr?.length > 0
        && patientMinorInfoQuestionsArr.map((question) => (
          <div key={question.question_id}>
            {renderQuestion(question)}
          </div>
        ))}

      <ConfirmationQuestions
        questions={sampleTypeQuestions}
        values={getKeyValuePairsForQuestions(sampleTypeQuestions)}
        screeningSpecialType={screeningSpecialType}
        enableHTMLQuestion={orderInfo.product.is_novodetect_product ?? false}
      />

      {orderInfo.akouos_4 && (
        <>
          <div>
            <label className="view_order_modal_label">
              {patientInfoLabels.phone}
            </label>
            <span>{orderInfo.patient_phone}</span>
          </div>
          <div>
            <label className="view_order_modal_label">
              {patientInfoLabels.address}
            </label>
            <span>{orderInfo.patient_address}</span>
          </div>
          <div>
            <label className="view_order_modal_label">
              {patientInfoLabels.email}
            </label>
            <span>{orderInfo.patient_email}</span>
          </div>
        </>
      )}
    </ViewOrderStep>
  );
};

PatientsInfo.propTypes = patientsInfoPropTypes;
PatientsInfo.defaultProps = defaultProps;

export default PatientsInfo;
