const RESET_INSURANCE_INFO = 'nucleus/RESET_INSURANCE_INFO';
const CHANGE_INSURANCE_SUB_STATUS = 'nucleus/CHANGE_INSURANCE_SUB_STATUS';
const CHANGE_INSURANCE_SUB_STATUS_SUCCESS = 'nucleus/CHANGE_INSURANCE_SUB_STATUS_SUCCESS';
const CHANGE_INSURANCE_SUB_STATUS_FAIL = 'nucleus/CHANGE_INSURANCE_SUB_STATUS_FAIL';
const TOGGLE_OWNER = 'nucleus/TOGGLE_OWNER';
const TOGGLE_OWNER_SUCCESS = 'nucleus/TOGGLE_OWNER_SUCCESS';
const TOGGLE_OWNER_FAIL = 'nucleus/TOGGLE_OWNER_FAIL';
const GET_INSURANCE_ITEMS = 'nucleus/GET_INSURANCE_ITEMS';
const GET_INSURANCE_ITEMS_SUCCESS = 'nucleus/GET_INSURANCE_ITEMS_SUCCESS';
const GET_INSURANCE_ITEMS_FAIL = 'nucleus/GET_INSURANCE_ITEMS_FAIL';
const GET_INSURANCE_STATUS = 'nucleus/GET_INSURANCE_STATUS';
const GET_INSURANCE_STATUS_SUCCESS = 'nucleus/GET_INSURANCE_STATUS_SUCCESS';
const GET_INSURANCE_STATUS_FAIL = 'nucleus/GET_INSURANCE_STATUS_FAIL';

const initialState = {
  loaded: false,
  currentStep: 0,
};

export default function orders(state = initialState, action = {}) {
  function makeItemsIntoArray(items) {
    return Object.values(items).sort((v1, v2) => v1.order_no - v2.order_no);
  }

  switch (action.type) {
    case RESET_INSURANCE_INFO:
      return {
        ...state,
        items: [],
      };
    case GET_INSURANCE_ITEMS:
      return {
        ...state,
        gettingItems: true,
        haveItems: false,
        error: null,
      };
    case GET_INSURANCE_ITEMS_SUCCESS:
      return {
        ...state,
        gettingItems: false,
        haveItems: true,
        items: makeItemsIntoArray(action.result),
      };
    case GET_INSURANCE_ITEMS_FAIL:
      return {
        ...state,
        gettingItems: false,
        haveItems: false,
        error: action.error,
      };
    case GET_INSURANCE_STATUS:
      return {
        ...state,
        gettingItems: true,
        haveItems: false,
        error: null,
      };
    case GET_INSURANCE_STATUS_SUCCESS:
      return {
        ...state,
        gettingItems: false,
        haveItems: true,
      };
    case GET_INSURANCE_STATUS_FAIL:
      return {
        ...state,
        gettingItems: false,
        haveItems: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function resetInsuranceInfo() {
  return { type: RESET_INSURANCE_INFO };
}

export function changeInsuranceStatus(data) {
  return {
    request: (client) => client.post('change_insurance_status', data),
  };
}

export function changeInsuranceSubStatus(data) {
  return {
    types: [
      CHANGE_INSURANCE_SUB_STATUS,
      CHANGE_INSURANCE_SUB_STATUS_SUCCESS,
      CHANGE_INSURANCE_SUB_STATUS_FAIL,
    ],
    request: (client) => client.post('change_insurance_sub_status', data),
  };
}

export function toggleOwner(data) {
  return {
    types: [TOGGLE_OWNER, TOGGLE_OWNER_SUCCESS, TOGGLE_OWNER_FAIL],
    request: (client) => client.post('toggle_owner', data),
  };
}

export function addInsuranceItem(data) {
  return {
    request: (client) => client.post('add_insurance_item', data),
  };
}

export function getInsuranceItems(data) {
  const path = [
    'get_insurance_items',
    data.order_id,
    data.status_step,
  ].join('/');
  return {
    types: [GET_INSURANCE_ITEMS, GET_INSURANCE_ITEMS_SUCCESS, GET_INSURANCE_ITEMS_FAIL],
    request: (client) => client.get(path),
  };
}

export function getInsuranceStatus(data) {
  return {
    types: [GET_INSURANCE_STATUS, GET_INSURANCE_STATUS_SUCCESS, GET_INSURANCE_STATUS_FAIL],
    request: (client) => client.get(`get_insurance_status/${data.order_id}`),
  };
}

export function createInsuranceStatus(orderId) {
  return {
    request: (client) => client.post('create_insurance_status', {
      order_id: orderId,
    }),
  };
}
