import { combineReducers } from 'redux';

import auth from './auth';
import orders from './orders';
import countries from './countries';
import notifications from './notifications';
import insurance from './insurance';
import support from './support';
import addAuthorizedProvider from './addAuthorizedProvider';
import provinces from './provinces';
import orderAttachments from './orderAttachments';

export default combineReducers({
  auth,
  orders,
  countries,
  notifications,
  insurance,
  support,
  addAuthorizedProvider,
  provinces,
  orderAttachments,
});
