import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GeneSelection from 'components/GeneSelection/GeneSelection';
import classNames from 'classnames';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import { isMitoGene } from 'utils/forms';
import { isUsRegion } from 'utils/envUtils';

import styles from './CustomGenesPanel.scss';

export default class CustomGenesPanel extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    // A list of genes that panel includes
    originalGenes: PropTypes.arrayOf(
      PropTypes.shape({
        has_suboptimal_coverage: PropTypes.bool.isRequired,
        overlaps_segmental_duplication: PropTypes.bool.isRequired,
        hgnc_symbol: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    // A list of genes that were added by user
    addedGenes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    // A list of genes that were removed from originalGenes by user
    maskedGenes: PropTypes.arrayOf(
      PropTypes.shape({
        has_suboptimal_coverage: PropTypes.bool.isRequired,
        overlaps_segmental_duplication: PropTypes.bool.isRequired,
        hgnc_symbol: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    updateAddedGenes: PropTypes.func.isRequired,
    updateMaskedGenes: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      maskGeneError: '',
      isMasked: {},
      isClickedRemoveAll: false,
    };
  }

  componentDidMount() {
    const isMaskedObj = {};

    this.props.originalGenes.forEach((gene) => {
      isMaskedObj[gene.id] = false;
    });
    this.props.maskedGenes.forEach((gene) => {
      isMaskedObj[gene.id] = true;
    });

    this.setState({
      isMasked: isMaskedObj,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isClickedRemoveAll) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isClickedRemoveAll: false,
      });
    }
  }

  /**
   * Toggle genes' mask. Fails if trying to mask all original genes.
   *
   * By default toggles mask value for each gene.
   * Set `maskValue` to true/false to disable default behaviour.
   */
  handleMask = (genes, maskValue = null) => {
    const { originalGenes } = this.props;

    this.setState((state) => {
      // Construct a new state with all `genes` masked
      const isMasked = genes.reduce(
        (isMaskedAcc, gene) => ({
          ...isMaskedAcc,
          [gene.id]: maskValue !== null ? maskValue : !isMaskedAcc[gene.id],
        }),
        state.isMasked,
      );

      // Check that there are some original genes left unmasked
      const maskedGenes = originalGenes.filter((gene) => isMasked[gene.id]);
      if (maskedGenes.length === originalGenes.length) {
        return {
          maskGeneError: 'You can only remove until 1 gene remains.',
        };
      }

      return {
        isMasked,
      };
    }, () => {
      const maskedGenes = originalGenes.filter((gene) => this.state.isMasked[gene.id]);
      this.props.updateMaskedGenes(maskedGenes);
    });
  };

  handleClickGenes = (genes) => {
    this.props.updateAddedGenes(genes);
  };

  /**
   * Show add mito genome button when
   * - panel has mito genes
   * - all mito genes are masked
   */
  canAddMitoGenome = () => {
    const { originalGenes } = this.props;
    const mitoGenes = originalGenes.filter((gene) => isMitoGene(gene.hgnc_symbol));
    if (!mitoGenes.length) {
      return false;
    }

    return mitoGenes.every((gene) => this.state.isMasked[gene.id]);
  }

  /**
   * Show remove mito genome button when
   * - some of panel genes are mito but not all
   * - some of mito genes have been added by user
   */
  canRemoveMitoGenome = () => {
    const { originalGenes, addedGenes } = this.props;
    const mitoGenes = originalGenes.filter((gene) => isMitoGene(gene.hgnc_symbol));
    if (mitoGenes.length === originalGenes.length) {
      return false;
    }

    const isMitoGenesIncluded = mitoGenes.some((gene) => !this.state.isMasked[gene.id]);
    const isMitoGenesAdded = addedGenes.some((gene) => isMitoGene(gene.hgnc_symbol));
    return isMitoGenesIncluded || isMitoGenesAdded;
  }

  removeMitoGenes = () => {
    const { addedGenes, originalGenes } = this.props;

    // Remove mito genes from original genes
    const mitoGenes = originalGenes.filter((gene) => isMitoGene(gene.hgnc_symbol));
    this.handleMask(mitoGenes, true);

    // Remove mito genes from added genes
    const updatedGenes = addedGenes.filter((gene) => !isMitoGene(gene.hgnc_symbol));
    this.props.updateAddedGenes(updatedGenes);

    this.setState({
      isClickedRemoveAll: true,
    });
  }

  addMitoGenes = () => {
    const { originalGenes } = this.props;
    const mitoGenes = originalGenes.filter((gene) => isMitoGene(gene.hgnc_symbol));
    this.handleMask(mitoGenes, false);
  }

  render() {
    const {
      addedGenes,
      originalGenes,
      orderInfo,
    } = this.props;
    const { isMasked, maskGeneError } = this.state;
    const panelId = orderInfo.product.id;
    const usingUsRegion = isUsRegion();

    return (
      <section>
        <p className="text-gray">
          <i className="text-pink glyphicon glyphicon-exclamation-sign" />
          {' '}
          The
          customization of panels is intended to bring value to the patient’s
          current diagnostics and phenotype(s).
          {' '}
          {
            usingUsRegion ? (
              <>
                When adding genes to a panel, only genes in the same phenotypic
                category as the original panel may be added.
                {' '}
                <b>
                  If genes are added outside of the phenotype category Blueprint Genetics
                  may contact you to discuss your ordering options.
                </b>
              </>
            ) : (
              <>
                Only add genes relevant to the current diagnostics and phenotype(s).
              </>
            )
          }
        </p>
        <p className="text-gray">
          Please note that customization of the panel may lead to reduced
          diagnostic utility and the intended use may change in a case where
          gene(s) relevant for the patient’s phenotype are removed.
        </p>
        <p className="text-gray">
          In addition, if the panel is customized to only include genes
          with suboptimal coverage or segmental duplication (marked with
          number sign or an asterisk, respectively), the sensitivity to detect
          variants may be reduced.
        </p>
        <p className="text-gray">
          The ordering clinician is responsible for
          any potential limitations when customizing a panel.
        </p>
        <p className="text-gray">
          The non-coding region of the mitochondrial genome is only
          included when the 37 mitochondrial genes are added to the panel.
        </p>
        <h3 className={styles.selected_genes_list_heading}>Included genes</h3>
        <small className="text-gray">
          Click to remove/undo
        </small>

        {this.canRemoveMitoGenome() && (
          <button
            type="button"
            className={classNames('btn btn-default float-right')}
            onClick={() => this.removeMitoGenes()}
          >
            Remove the Mitochondrial Genome
          </button>
        )}

        {this.canAddMitoGenome() && (
          <button
            type="button"
            className={classNames('btn btn-default float-right')}
            onClick={() => this.addMitoGenes()}
          >
            Add the Mitochondrial Genome
          </button>
        )}

        <ul className={`list-unstyled list-inline ${styles.included_gene}`}>
          {/*
            eslint-disable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */}
          {originalGenes.map((gene) => (
            <li
              key={gene.id}
              className={classNames('list-inline-item', styles.gene_pill_item, {
                [styles.masked]: isMasked[gene.id],
                'gene-is-masked': isMasked[gene.id],
              })}
              onClick={() => this.handleMask([gene])}
            >
              {gene.hgnc_symbol}
              {gene.overlaps_segmental_duplication && (
                <span className="text-pink">*</span>
              )}
              {gene.has_suboptimal_coverage && (
                <span className="text-pink">#</span>
              )}
            </li>
          ))}
          {/*
            eslint-enable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */}
        </ul>

        {maskGeneError && (
          <div>
            <span className="text-danger" style={{ marginRight: '5px' }}>
              {maskGeneError}
            </span>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => this.setState({ maskGeneError: '' })}
            >
              OK
            </button>
          </div>
        )}

        <GeneSelection
          ignoredGenes={originalGenes}
          initialGenes={addedGenes}
          panelId={panelId}
          limitNbrGenes={200}
          onClick={(genes) => this.handleClickGenes(genes)}
          isClickedRemoveAll={this.state.isClickedRemoveAll}
        />
      </section>
    );
  }
}
