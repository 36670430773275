import React from 'react';
import PropTypes from 'prop-types';

import { OrderKitPropTypes } from 'types/OrderInfoPropTypes';

import styles from './ViewOrderInfo.scss';

const OrderKitSection = ({ orderKit }) => {
  const hasOrderKit = orderKit && orderKit.has_order_kit;

  return (
    <>
      <div className="form-check checkbox modal_checkbox">
        <input type="checkbox" className="form-check-input" checked={hasOrderKit} disabled />
        <label className="view_order_modal_label form-check-label">Order specimen kit</label>
      </div>

      {hasOrderKit && (
        <div>
          <h4 className={styles.extra_info_heading}>Specimen kit</h4>
          <label className="view_order_modal_label">Recipient Name</label>
          <span>
            {orderKit.name || 'N/A'}
          </span>
          <label className="view_order_modal_label">Institution</label>
          <span>
            {orderKit.institution || 'N/A'}
          </span>
          <label className="view_order_modal_label">Email</label>
          <span>
            {orderKit.email || 'N/A'}
          </span>
          <label className="view_order_modal_label">Phone</label>
          <span>
            {orderKit.phone || 'N/A'}
          </span>
          <label className="view_order_modal_label">Address 1st line</label>
          <span>
            {orderKit.address_1 || 'N/A'}
          </span>
          <label className="view_order_modal_label">Address 2nd line</label>
          <span>
            {orderKit.address_2 || 'N/A'}
          </span>
          <label className="view_order_modal_label">City/Town</label>
          <span>
            {orderKit.city || 'N/A'}
          </span>
          <label className="view_order_modal_label">State</label>
          <span>
            {orderKit.state || 'N/A'}
          </span>
          <label className="view_order_modal_label">Postcode</label>
          <span>
            {orderKit.postcode || 'N/A'}
          </span>
          <label className="view_order_modal_label">Country</label>
          <span>
            {orderKit.country_name || 'N/A'}
          </span>
          {orderKit.blood_kit_number !== 0 && (
            <div>
              <label className="view_order_modal_label">
                Number of blood kits
              </label>
              <span>
                {orderKit.blood_kit_number}
              </span>
            </div>
          )}

          {orderKit.saliva_kit_number !== 0 && (
            <div>
              <label className="view_order_modal_label">
                Number of buccal swab kits
              </label>
              <span>
                {orderKit.saliva_kit_number}
              </span>
            </div>
          )}

          {orderKit.exome_kit_number !== 0 && (
            <div>
              <label className="view_order_modal_label">
                Number of exome kits
              </label>
              <span>
                {orderKit.exome_kit_number}
              </span>
            </div>
          )}
          <label className="view_order_modal_label">
            Other information
          </label>
          <span>
            {orderKit.other || 'N/A'}
          </span>
        </div>
      )}
    </>
  );
};

OrderKitSection.propTypes = {
  orderKit: PropTypes.shape(OrderKitPropTypes),
};

OrderKitSection.defaultProps = {
  orderKit: null,
};

export default OrderKitSection;
