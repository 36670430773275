export const FVT = 'fvt';
export const TVT = 'tvt';
export const MSD = 'msd';
export const EXOME = 'exome';
export const EXOME_FAMILY = 'exome_family';
export const EXOME_PLUS = 'exome_plus';
export const EXOME_FAMILY_PLUS = 'exome_family_plus';
export const FMT = 'fmt';
export const WGS = 'wgs';
export const SINGLE_GENE = 'single_gene';
export const BIOMARIN = 'biomarin';
export const NEI = 'nei';
export const SPARK = 'spark';
export const MITO_CHROMOSOME = 'MT';
export const PANEL = 'panel';
export const PANEL_DELDUP = 'panel_deldup';
export const PANEL_PLUS = 'panel_plus';
export const SCREENING = 'screening';
