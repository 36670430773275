import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Yup } from 'components/Formik/validation';

import useData from 'hooks/useData';
import { aliasUserIdSelector, userInfoSelector } from 'redux/modules/auth';
import { loadCountriesList } from 'redux/modules/countries';
import { LabelAttachments } from 'components';
import LabelInput from 'components/Formik/LabelInput';
import { OTHER_ATTACHMENT_TYPE, REQUISITION_ATTACHMENT_TYPE } from 'helpers/constants/fileTypeConstants';
import { isScreeningDuo, isScreeningSolo } from 'utils/forms';
import { isInternalRole } from 'utils/orderRoles';
import { SCREENING } from 'helpers/constants/specialTypeConstants';

import DeliveryInfo, { deliveryInfoSchema } from './DeliveryInfo';
import OrderKit, { defaultOrderKitValues, orderKitSchema } from './OrderKit';

const getSkipFieldsMap = (skipFields) => skipFields
  .reduce((acc, field) => ({ ...acc, [field]: true }), {});

export const commonSectionValidationSchema = {
  case_phone: Yup.string().ensure(),
  delivery_info: deliveryInfoSchema,
  order_kit: orderKitSchema,
};

export const getCommonSectionDefaultValues = () => ({
  delivery_info: {
    mail_list: [],
    mail_subscription: false,
    fax_list: [],
    fax_subscription: false,
  },
  order_kit: {},
});

export const getCommonSectionInitialValues = ({ orderInfo, deliveryInfo, userInfo }) => ({
  case_phone: orderInfo.case_phone,
  order_kit: defaultOrderKitValues(orderInfo),
  delivery_info: { ...deliveryInfo, user_role: userInfo.user_role },
});

const CommonSection = ({
  orderInfo,
  phoneNumberRequired,
}) => {
  const userInfo = useSelector(userInfoSelector);
  const aliasUserId = useSelector(aliasUserIdSelector);

  const { data: countries } = useData(loadCountriesList);

  const formikContext = useFormikContext();

  const skipFields = getSkipFieldsMap(orderInfo.skip_misc_fields || []);
  const showAttachments = userInfo && isInternalRole(userInfo.user_role);

  const isScreeningType = orderInfo?.special_type === SCREENING;

  return (
    <>
      {!isScreeningType && (
        <>
          <DeliveryInfo
            skipFields={skipFields}
            values={formikContext.values.delivery_info}
            formProps={formikContext}
          />
          <Field
            name="case_phone"
            component={LabelInput}
            label="Phone number for possible case discussion"
            required={phoneNumberRequired}
          />
        </>
      )}

      {showAttachments && (
        <>
          <LabelAttachments
            orderId={orderInfo.order_id}
            userInfo={userInfo}
            aliasUserId={aliasUserId}
            type={REQUISITION_ATTACHMENT_TYPE}
            label="Attached requisition form files"
            chooseFileButtonCustomLabel="Attach req"
          />

          <LabelAttachments
            orderId={orderInfo.order_id}
            userInfo={userInfo}
            aliasUserId={aliasUserId}
            type={OTHER_ATTACHMENT_TYPE}
            label="Other attachments"
            chooseFileButtonCustomLabel="Other files"
          />
        </>
      )}

      {isScreeningType && (
        <p>
          If you wish to order a larger number of kits, place an order via our website:
          <a href="https://blueprintgenetics.com/order-kit/"> https://blueprintgenetics.com/order-kit/</a>
        </p>
      )}

      {orderInfo.order_kit_available && (
        <OrderKit
          countries={countries || []}
          values={formikContext.values.order_kit}
          isScreeningSolo={isScreeningType && isScreeningSolo(orderInfo.product.code)}
          isScreeningDuo={isScreeningType && isScreeningDuo(orderInfo.product.code)}
        />
      )}
    </>
  );
};

CommonSection.propTypes = {
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number.isRequired,
    order_kit_available: PropTypes.bool.isRequired,
    skip_misc_fields: PropTypes.arrayOf(PropTypes.oneOf(['mail_delivery', 'emea_address'])),
    /* eslint-disable react/forbid-prop-types  */
    product: PropTypes.object.isRequired,
    special_type: PropTypes.string,
  }).isRequired,
  phoneNumberRequired: PropTypes.bool,
};

CommonSection.defaultProps = {
  phoneNumberRequired: false,
};

export default CommonSection;
