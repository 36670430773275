import React from 'react';
import PropTypes from 'prop-types';
import { SearchInput, PrimaryButton, ToggleCheckbox } from 'components';

const AdminHeading = (props) => {
  const {
    title, labelText, buttonFunc, searchFunc, placeholder, ...other
  } = props;

  return (
    <>
      <PrimaryButton labelText={labelText} onClick={buttonFunc} additionalClass="btn_right" />
      <h1>{title}</h1>
      <div className="clearfix">
        {Object.getOwnPropertyNames(other).length !== 0
        && (
          <ToggleCheckbox
            {...other}
          />
        )}
        <SearchInput onChange={searchFunc} placeholder={placeholder} additionalClass="input_right" />
      </div>
    </>
  );
};

AdminHeading.propTypes = {
  labelText: PropTypes.string.isRequired,
  buttonFunc: PropTypes.func.isRequired,
  searchFunc: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default AdminHeading;
