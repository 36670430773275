import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { isUserFormValid } from './userFormValidation';

const SaveUserButton = ({ usHospitalIds, onClick }) => {
  const formikContext = useFormikContext();

  const { values, validateForm } = formikContext;

  const handleClick = async () => {
    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }

    onClick(values);
  };

  return (
    <button
      type="button"
      className="btn btn-primary"
      disabled={!isUserFormValid(values, usHospitalIds)}
      onClick={handleClick}
    >
      Save
    </button>
  );
};

SaveUserButton.propTypes = {
  usHospitalIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SaveUserButton;
