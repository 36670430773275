import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import ViewOrderNavigation from 'components/_ViewOrderProcess/ViewOrderNavigation';
import OrderProgressBar from 'components/OrderProgressBar/OrderProgressBar';
import ViewOrderInfo from 'components/_ViewOrderProcess/ViewOrderInfo/ViewOrderInfo';
import BillingInfo from 'components/_ViewOrderProcess/BillingInfo/BillingInfo';
import PatientsInfo from 'components/_ViewOrderProcess/PatientsInfo/PatientsInfo';
import PatientsHistoryInfo from 'components/_ViewOrderProcess/PatientsHistoryInfo/PatientsHistoryInfo';
import OrderTestFormShareResults from 'components/_CreateOrderProcess/_OrderTestFormShareResults/_OrderTestFormShareResults';
import InformedConsentInfo from 'components/_ViewOrderProcess/InformedConsentInfo/InformedConsentInfo';
import ViewTestComment from 'components/_ViewOrderProcess/ViewTestComment/ViewTestComment';
import Spinner from 'components/Spinner/Spinner';

import { isScreening, isVST } from 'utils/forms';

export function getSteps(orderInfo = {}) {
  const steps = [];

  steps.push({
    description: 'Order info',
    component: ViewOrderInfo,
  });

  if (orderInfo.product.code !== 'EA0601') {
    steps.push({
      description: 'Billing info',
      component: BillingInfo,
    });
  }

  steps.push({
    description: (isVST(orderInfo.special_type) || isScreening(orderInfo.special_type)) ? 'Identifying information' : 'Patient information',
    component: PatientsInfo,
  });

  steps.push({
    description: 'Clinical history',
    component: PatientsHistoryInfo,
  });

  steps.push({
    description: 'Informed consent',
    component: InformedConsentInfo,
  });

  steps.push({
    description: 'Share results',
    component: OrderTestFormShareResults,
  });

  steps.push({
    description: 'Comments',
    component: ViewTestComment,
  });

  return steps;
}

const ViewOrderStep = ({
  children,
  currentStep,
  changeStep,
  orderInfo,
  dirty,
  loading,
  error,
  submit,
  isValid,
  setFieldError,
  hideModal,
}) => {
  const steps = getSteps(orderInfo);

  const onChangeStep = async (step) => {
    if (submit) {
      if (dirty) {
        // Submit form only when it was modified
        await submit('step', currentStep, step);
        if (!isValid) {
          setFieldError('general', 'Please recheck your information');
          return;
        }
      }

      if ((step > 7) && hideModal) {
        hideModal();
      }
    }
    if (step <= steps.length) {
      changeStep(step);
    }
  };

  const navigation = (
    <ViewOrderNavigation
      currentStep={currentStep}
      onChangeStep={onChangeStep}
      stepCount={steps.length}
    />
  );

  return (
    <div>
      {navigation}

      <OrderProgressBar
        currentStep={currentStep}
        onStepChange={onChangeStep}
        steps={steps.map((step, index) => ({
          key: index + 1,
          description: step.description,
        }))}
      />

      {error && (
        <div className="mb-sm">
          <span className="text-danger">{error}</span>
        </div>
      )}

      {loading && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}

      {children}

      {navigation}
    </div>
  );
};

ViewOrderStep.propTypes = {
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number.isRequired,
  changeStep: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({}).isRequired,
  dirty: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  isValid: PropTypes.bool,
  submit: PropTypes.func,
  hideModal: PropTypes.func,
  setFieldError: PropTypes.func,
};

ViewOrderStep.defaultProps = {
  dirty: false,
  loading: false,
  error: null,
  submit: null,
  isValid: true,
  hideModal: null,
  setFieldError: () => {},
};

export default connect(
  (state) => ({
    currentStep: state.orders.currentStep,
    orderInfo: state.orders.orderInfo,
  }),
  {
    changeStep: orderActions.changeStep,
  },
)(ViewOrderStep);
