import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Helmet from "react-helmet";
import { Spinner } from "components";
import { withRouter } from "react-router-dom";
import {
  OrderReportHeader,
  OrderReportPatientInfo,
  OrderReportTestResults,
  OrderReportVariants,
  OrderReportVariantsExome,
  OrderReportCandidateFindings,
  OrderReportVariantsWgs,
  OrderReportVariantsDeldup,
  OrderReportVariantsExomeDeldup,
  OrderReportPerformanceMetrics,
  OrderReportPerformanceMetricsExome,
  OrderReportTargetRegion,
  OrderReportStatement,
  OrderReportAppendixReferral,
  OrderReportAppendixGeneTable,
  OrderReportAppendixSequencingCoverage,
  OrderReportAppendixSummaryOfMethods,
  OrderReportAppendixArchivesHistory,
  OrderReportAppendixDownloads,
  OrderReportAppendixAddFindings,
  OrderReportAppendixSecondaryFindings,
  OrderReportAppendixNoneCodingVariants,
} from "components";

import OrderReportVariantsMito from "components/_OrderReport/_OrderReportVariantsMito/_OrderReportVariantsMito";
import OrderReportPerformanceMetricsMito from "components/_OrderReport/_OrderReportPerformanceMetricsMito/_OrderReportPerformanceMetricsMito";
import OrderReportVariantsMitoDeldup from "components/_OrderReport/_OrderReportVariantsMitoDeldup/_OrderReportVariantsMitoDeldup";

import { Link } from "react-router-dom";
import {
  viewOrderInfo,
  getReport,
  getReportFile,
  listReportArchiveFiles,
  getReportArchiveFile,
  getPanelQa,
  listReportOrderFiles,
  getOrderFile,
  getCoverageFile,
  getMitoCoverageFile,
  resetOrderReportValues,
  performOperation,
} from "redux/modules/orders";
import {
  isVST,
  isExome,
  isFMT,
  isSupportedSpecialType,
  isWGS,
  downloadFileFunc,
} from "utils/forms";
import isVusClarificationServiceOrder from "utils/vusClarificationService";

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userInfo: state.auth.userInfo,
  orderInfo: state.orders.orderInfo,
  gettingOrder: state.orders.gettingOrder,
  orderReport: state.orders.orderReport,
  error: state.orders.error,
  reportFileObject: state.orders.reportFileObject,
  archiveReportFiles: state.orders.archiveReportFiles,
  reportArchiveFile: state.orders.reportArchiveFile,
  panelQa: state.orders.panelQa,
  reportOrderFiles: state.orders.reportOrderFiles,
  orderFileObject: state.orders.orderFileObject,
  coverageFile: state.orders.coverageFile,
  mitoCoverageFile: state.orders.mitoCoverageFile,
  gettingReport: state.orders.gettingReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      viewOrderInfo,
      getReport,
      getReportFile,
      listReportArchiveFiles,
      getReportArchiveFile,
      getPanelQa,
      listReportOrderFiles,
      getOrderFile,
      getCoverageFile,
      getMitoCoverageFile,
      resetOrderReportValues,
      performOperation,
    },
    dispatch
  );

class OrderReport extends Component {
  static propTypes = {
    user: PropTypes.object,
    orderInfo: PropTypes.object,
    viewOrderInfo: PropTypes.func,
    gettingOrder: PropTypes.bool,
    error: PropTypes.string,
    getReport: PropTypes.func,
    getReportFile: PropTypes.func,
    orderReport: PropTypes.object,
    params: PropTypes.object,
    reportFileObject: PropTypes.object,
    archiveReportFiles: PropTypes.object,
    listReportArchiveFiles: PropTypes.func,
    getReportArchiveFile: PropTypes.func,
    reportArchiveFile: PropTypes.object,
    panelQa: PropTypes.object,
    getPanelQa: PropTypes.func,
    reportOrderFiles: PropTypes.object,
    listReportOrderFiles: PropTypes.func,
    getOrderFile: PropTypes.func,
    getCoverageFile: PropTypes.func,
    getMitoCoverageFile: PropTypes.func,
    orderFileObject: PropTypes.object,
    coverageFile: PropTypes.object,
    mitoCoverageFile: PropTypes.object,
    gettingReport: PropTypes.bool,
    resetOrderReportValues: PropTypes.func,
    performOperation: PropTypes.func.isRequired,
  };

  state = {
    appendixReferralShown: false,
    appendixAddFindingsShown: false,
    appendixSecondaryFindingsShown: false,
    appendixGeneTableShown: false,
    appendixSequencingCoverageShown: false,
    appendixSummaryMethodsShown: false,
    appendixDownloadsShown: false,
    appendixArchivesHistoryShown: false,
    appendixNoneCodingVariantsShown: false,
  };

  componentDidMount() {
    const orderId = this.props.match.params.orderId;
    this.props.viewOrderInfo(orderId);
    this.props.listReportArchiveFiles({ order_id: orderId });
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    if (this.props.orderInfo !== prevProps.orderInfo && this.props.orderInfo) {
      if (this.props.orderInfo.report_version >= 4) {
        const orderId = this.props.match.params.orderId;
        this.props.getReport({ order_id: orderId });
        this.props.getPanelQa({ order_id: orderId });
        this.props.listReportOrderFiles({
          order_id: orderId,
          type: "report_attachment",
        });
      }
    }
    if (
      (this.props.reportFileObject && !prevProps.reportFileObject) ||
      (this.props.reportFileObject !== prevProps.reportFileObject &&
        this.props.reportFileObject)
    ) {
      downloadFileFunc(this.props.reportFileObject);
    }
    if (
      (this.props.reportArchiveFile && !prevProps.reportArchiveFile) ||
      (this.props.reportArchiveFile !== prevProps.reportArchiveFile &&
        this.props.reportArchiveFile)
    ) {
      downloadFileFunc(this.props.reportArchiveFile);
    }
    if (
      (this.props.orderFileObject && !prevProps.orderFileObject) ||
      (this.props.orderFileObject !== prevProps.orderFileObject &&
        this.props.orderFileObject)
    ) {
      downloadFileFunc(this.props.orderFileObject);
    }

    if (
      this.props.orderReport !== prevProps.orderReport &&
      this.props.orderReport &&
      this.props.orderInfo
    ) {
      const isSupportedSpecialTypes = isSupportedSpecialType(
        this.props.orderInfo.special_type
      );
      if (
        (!this.props.orderInfo.special_type || isSupportedSpecialTypes) &&
        this.props.orderReport.coverage_plot_file_id
      ) {
        this.props.getCoverageFile({
          order_id: this.props.match.params.orderId,
          file_id: this.props.orderReport.coverage_plot_file_id,
        });
      }
      if (this.props.orderReport.coverage_plot_mito_file_id) {
        this.props.getMitoCoverageFile({
          order_id: this.props.match.params.orderId,
          file_id: this.props.orderReport.coverage_plot_mito_file_id,
        });
      }
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  componentWillUnmount() {
    this.props.resetOrderReportValues();
  }

  onTogglePanel = (stateToToggle) => {
    this.setState({
      [stateToToggle]: this.state[stateToToggle] ? false : true,
    });
  };

  getArchiveFile = (archiveId) => {
    this.props.getReportArchiveFile({
      order_id: this.props.match.params.orderId,
      archive_id: archiveId,
      format: "pdf",
    });
  };

  downloadReportAttachedFile = (fileId) => {
    this.props.getOrderFile({
      order_id: this.props.match.params.orderId,
      file_id: fileId,
    });
  };

  isEmpty = (obj) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };

  textColorClass = (colorClass) => {
    if (colorClass === "good") {
      return "text-gray";
    }
    if (colorClass === "severe") {
      return "text-pink";
    }
    return "";
  };

  handleScrollTo = (ref, state) => {
    ReactDOM.findDOMNode(this[ref]).scrollIntoView();
    this.setState({
      [state]: true,
    });
  };

  handleOrderFVTButtonClick = async (
    sourceOrderId,
    orderReport,
    aliasUserId
  ) => {
    const mappings = orderReport.product_operations.find(
      (pm) => pm.operation === "copy_index_order"
    );
    const details = mappings && mappings.details && mappings.details[0];
    const productOperationId = details && details.operation_id;

    if (productOperationId) {
      const operationResult = await this.props.performOperation(
        sourceOrderId,
        productOperationId,
        aliasUserId
      );
      this.props.history.push(`/orders/${operationResult.order_id}/edit`);
    } else {
      throw new Error("No Product Operation ID");
    }
  };

  render() {
    const {
      orderInfo,
      gettingReport,
      orderReport,
      archiveReportFiles,
      reportOrderFiles,
      gettingOrder,
      userInfo,
    } = this.props;
    const {
      appendixReferralShown,
      appendixAddFindingsShown,
      appendixSecondaryFindingsShown,
      appendixGeneTableShown,
      appendixSequencingCoverageShown,
      appendixSummaryMethodsShown,
      appendixDownloadsShown,
      appendixArchivesHistoryShown,
      appendixNoneCodingVariantsShown,
    } = this.state;
    const styles = require("./OrderReport.scss");

    // to check against whole exome special type
    let isExomeSpecialTypes;
    if (orderInfo) {
      isExomeSpecialTypes =
        orderInfo.special_type === "msd" || isExome(orderInfo.special_type);
    }

    // to check against whole genome sequencing special type
    let isWgsSpecialType;
    if (orderInfo) {
      isWgsSpecialType = isWGS(orderInfo.special_type);
    }

    let sortedPanelQaArr;
    if (this.props.panelQa) {
      const panelQaArr = Object.keys(this.props.panelQa).map((key) => {
        return this.props.panelQa[key];
      });
      sortedPanelQaArr = panelQaArr.sort((element1, element2) => {
        return element1.order_no - element2.order_no;
      });
    }

    let appendixIndex = 1;
    let reportBody = null;

    if (orderInfo && !gettingOrder && !gettingReport && orderReport) {
      // Has loaded order and report
      if (orderInfo.report_version >= 4) {
        if (isFMT(orderInfo.special_type)) {
          reportBody = (
            <div>
              <OrderReportHeader
                userInfo={userInfo}
                orderInfo={orderInfo}
                orderReport={orderReport}
                getReportFile={this.props.getReportFile}
                isEmpty={this.isEmpty}
                onOrderFVTButtonClick={this.handleOrderFVTButtonClick}
              />

              <OrderReportPatientInfo orderInfo={orderInfo} />

              <div>
                <h2>{orderReport.test_info_title}</h2>
                <table className="table">
                  <thead>
                    <tr className={styles.report_heading_row}>
                      <th>{orderReport.test_type_title}</th>
                      <th>Index order ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {orderReport.fmt_test_type
                          ? orderReport.fmt_test_type
                          : "N/A"}
                      </td>
                      <td>
                        {orderInfo.index_order_id
                          ? orderInfo.index_order_id
                          : "N/A"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="report-section">
                {orderReport.test_result && (
                  <div>
                    <h2>Test result</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: orderReport.test_result,
                      }}
                    ></div>
                  </div>
                )}
              </div>

              <div className="report-section">
                <h2>Statement</h2>

                {orderReport.description && (
                  <div>
                    <h3 className="h4">Description</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: orderReport.description,
                      }}
                    ></div>
                  </div>
                )}

                {orderReport.test_result && (
                  <div>
                    <h3 className="h4">Test result</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: orderReport.test_result,
                      }}
                    ></div>
                  </div>
                )}

                {orderReport.processing && (
                  <div>
                    <h3 className="h4">Process</h3>
                    <table className="table">
                      <thead>
                        <tr className={styles.report_heading_row}>
                          <th>Step</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(orderReport.processing).map((key) => (
                          <tr key={key}>
                            <td>{orderReport.processing[key].step}</td>
                            <td>{orderReport.processing[key].date_str}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              <OrderReportAppendixReferral
                sortedPanelQaArr={sortedPanelQaArr}
                appendixReferralShown={appendixReferralShown}
                onTogglePanel={this.onTogglePanel}
                appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
              />

              <OrderReportAppendixDownloads
                reportOrderFiles={reportOrderFiles}
                appendixDownloadsShown={appendixDownloadsShown}
                onTogglePanel={this.onTogglePanel}
                downloadReportAttachedFile={this.downloadReportAttachedFile}
                appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
              />

              <OrderReportAppendixArchivesHistory
                archiveReportFiles={archiveReportFiles}
                logs={orderReport.logs}
                appendixArchivesHistoryShown={appendixArchivesHistoryShown}
                onTogglePanel={this.onTogglePanel}
                isEmpty={this.isEmpty}
                appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                getArchiveFile={this.getArchiveFile}
              />
            </div>
          );
        } else if (isVusClarificationServiceOrder(orderInfo)) {
          reportBody = (
            <div>
              <OrderReportHeader
                userInfo={userInfo}
                orderInfo={orderInfo}
                orderReport={orderReport}
                getReportFile={this.props.getReportFile}
                isEmpty={this.isEmpty}
                onOrderFVTButtonClick={this.handleOrderFVTButtonClick}
              />
              <p className={styles.vus_clarification_service_notification}>
                This individual’s sample was sequenced as a part of the VUS
                Clarification Service to help clarify{" "}
                <a href={`/orders/report/${orderInfo.index_order_id}`}>
                  the index patient’s results
                </a>
                . Separate reports for family members are not available with
                this service.
              </p>
              <OrderReportPatientInfo orderInfo={orderInfo} />
            </div>
          );
        } else {
          reportBody = (
            <div>
              <OrderReportHeader
                userInfo={userInfo}
                orderInfo={orderInfo}
                orderReport={orderReport}
                getReportFile={this.props.getReportFile}
                isEmpty={this.isEmpty}
                onOrderFVTButtonClick={this.handleOrderFVTButtonClick}
              />

              <OrderReportPatientInfo orderInfo={orderInfo} />

              {isVST(orderInfo.special_type) && (
                <div>
                  <h2>{orderReport.test_info_title}</h2>
                  <table className="table">
                    <thead>
                      <tr className={styles.report_heading_row}>
                        <th>{orderReport.test_type_title}</th>
                        <th>Index order ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {orderReport.variant_count
                            ? `${orderReport.variant_count} mutations`
                            : null}
                        </td>
                        <td>
                          {orderInfo.index_order_id
                            ? orderInfo.index_order_id
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <div className="report-section">
                <h2>Summary of results</h2>

                {orderReport.test_results && (
                  <OrderReportTestResults
                    testResults={orderReport.test_results}
                  />
                )}

                {!this.isEmpty(orderReport.variants) && (
                  <OrderReportVariants orderReport={orderReport} />
                )}

                {!this.isEmpty(orderReport.variants_mito) && (
                  <OrderReportVariantsMito orderReport={orderReport} />
                )}

                {isExomeSpecialTypes && orderReport.variants_exome && (
                  <OrderReportVariantsExome orderReport={orderReport} />
                )}

                {isExomeSpecialTypes && orderReport.candidate_findings && (
                  <OrderReportCandidateFindings orderReport={orderReport} />
                )}

                {orderReport.variants_wgs_cnv && (
                  <OrderReportVariantsWgs
                    variantsWgs={orderReport.variants_wgs_cnv}
                    reportVersion={orderInfo.report_version}
                  />
                )}

                {orderReport.variants_del_dup && (
                  <OrderReportVariantsDeldup
                    variantsDeldup={orderReport.variants_del_dup}
                    reportVersion={orderInfo.report_version}
                  />
                )}

                {isExomeSpecialTypes && orderReport.variants_exome_deldup && (
                  <OrderReportVariantsExomeDeldup orderReport={orderReport} />
                )}

                {!this.isEmpty(orderReport.variants_mito_deldup) && (
                  <OrderReportVariantsMitoDeldup
                    orderReport={orderReport}
                    textColorClass={this.textColorClass}
                  />
                )}

                {orderReport.additional_findings_notification && (
                  <div className="anchor-to-findings">
                    <a
                      className="link-pink"
                      onClick={() =>
                        this.handleScrollTo(
                          "additionalFindingsAppendix",
                          "appendixAddFindingsShown"
                        )
                      }
                    >
                      {orderReport.additional_findings_notification}
                    </a>
                  </div>
                )}

                {orderReport.secondary_findings_notification &&
                  orderReport.secondary_findings_appendix_visible && (
                    <div className="anchor-to-findings">
                      <a
                        className="link-pink"
                        onClick={() =>
                          this.handleScrollTo(
                            "secondaryFindingsAppendix",
                            "appendixSecondaryFindingsShown"
                          )
                        }
                      >
                        {orderReport.secondary_findings_notification}
                      </a>
                    </div>
                  )}

                {!this.isEmpty(orderReport.performance_metrics) &&
                  !isVST(orderInfo.special_type) && (
                    <div>
                      {!isExomeSpecialTypes && (
                        <OrderReportPerformanceMetrics
                          title={orderReport.performance_metrics_title}
                          performanceMetrics={orderReport.performance_metrics}
                          reportVersion={orderReport.version}
                        />
                      )}
                      {isExomeSpecialTypes && (
                        <OrderReportPerformanceMetricsExome
                          title={orderReport.performance_metrics_title}
                          performanceMetricsExome={
                            orderReport.performance_metrics
                          }
                          reportVersion={orderReport.version}
                          isMito={false}
                        />
                      )}
                    </div>
                  )}

                {!this.isEmpty(orderReport.performance_metrics_mito) &&
                  !isVST(orderInfo.special_type) &&
                  !isExomeSpecialTypes && (
                    <OrderReportPerformanceMetricsMito
                      title={orderReport.performance_metrics_mito_title}
                      performanceMetrics={orderReport.performance_metrics_mito}
                    />
                  )}

                {!this.isEmpty(orderReport.performance_metrics_mito) &&
                  isExomeSpecialTypes && (
                    <OrderReportPerformanceMetricsExome
                      title={orderReport.performance_metrics_mito_title}
                      performanceMetricsExome={
                        orderReport.performance_metrics_mito
                      }
                      reportVersion={orderReport.version}
                      isMito={true}
                    />
                  )}

                {orderInfo && orderReport.target_region && (
                  <OrderReportTargetRegion
                    title={orderReport.target_region_title}
                    targetRegion={orderReport.target_region}
                    orderInfo={orderInfo}
                  />
                )}
              </div>

              <OrderReportStatement
                orderReport={orderReport}
                isExomeSpecialTypes={isExomeSpecialTypes}
              />

              <div className="appendices">
                {orderReport.version < 6 && (
                  <div>
                    {!isExomeSpecialTypes &&
                      !isWgsSpecialType &&
                      orderInfo.analysis_type !==
                        "deletion_duplication_analysis" && (
                        <div>
                          <OrderReportAppendixReferral
                            sortedPanelQaArr={sortedPanelQaArr}
                            appendixReferralShown={appendixReferralShown}
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                          />

                          <OrderReportAppendixGeneTable
                            orderReport={orderReport}
                            appendixGeneTableShown={appendixGeneTableShown}
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Gene table`}
                            isEmpty={this.isEmpty}
                            reportVersion={orderReport.version}
                          />

                          <OrderReportAppendixSequencingCoverage
                            coverageFile={this.props.coverageFile}
                            mitoCoverageFile={this.props.mitoCoverageFile}
                            orderReport={orderReport}
                            appendixSequencingCoverageShown={
                              appendixSequencingCoverageShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            isEmpty={this.isEmpty}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Sequencing coverage`}
                            reportVersion={orderReport.version}
                          />

                          <OrderReportAppendixSummaryOfMethods
                            orderReport={orderReport}
                            appendixSummaryMethodsShown={
                              appendixSummaryMethodsShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                            orderInfo={orderInfo}
                          />

                          <OrderReportAppendixDownloads
                            reportOrderFiles={reportOrderFiles}
                            appendixDownloadsShown={appendixDownloadsShown}
                            onTogglePanel={this.onTogglePanel}
                            downloadReportAttachedFile={
                              this.downloadReportAttachedFile
                            }
                            appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                          />

                          <OrderReportAppendixArchivesHistory
                            archiveReportFiles={archiveReportFiles}
                            logs={orderReport.logs}
                            appendixArchivesHistoryShown={
                              appendixArchivesHistoryShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            isEmpty={this.isEmpty}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                            getArchiveFile={this.getArchiveFile}
                          />
                        </div>
                      )}
                    {isExomeSpecialTypes && orderInfo && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />

                        <OrderReportAppendixSequencingCoverage
                          coverageFile={this.props.coverageFile}
                          mitoCoverageFile={this.props.mitoCoverageFile}
                          orderReport={orderReport}
                          appendixSequencingCoverageShown={
                            appendixSequencingCoverageShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Sequencing coverage`}
                          reportVersion={orderReport.version}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                      </div>
                    )}
                    {isWgsSpecialType && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                      </div>
                    )}
                    {orderInfo.analysis_type ===
                      "deletion_duplication_analysis" && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                      </div>
                    )}
                  </div>
                )}
                {orderReport.version >= 6 && (
                  <div>
                    {!isExomeSpecialTypes &&
                      !isWgsSpecialType &&
                      orderInfo.analysis_type !==
                        "deletion_duplication_analysis" && (
                        <div>
                          <OrderReportAppendixReferral
                            sortedPanelQaArr={sortedPanelQaArr}
                            appendixReferralShown={appendixReferralShown}
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                          />

                          <OrderReportAppendixAddFindings
                            orderReport={orderReport}
                            appendixAddFindingsShown={appendixAddFindingsShown}
                            onTogglePanel={this.onTogglePanel}
                            ref={(thisDiv) => {
                              this.additionalFindingsAppendix = thisDiv;
                            }}
                            isEmpty={this.isEmpty}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Additional findings`}
                          />

                          {orderReport.secondary_findings_appendix_visible && (
                            <OrderReportAppendixSecondaryFindings
                              orderReport={orderReport}
                              appendixSecondaryFindingsShown={
                                appendixSecondaryFindingsShown
                              }
                              onTogglePanel={this.onTogglePanel}
                              ref={(thisDiv) => {
                                this.secondaryFindingsAppendix = thisDiv;
                              }}
                              appendixHeading={`APPENDIX ${appendixIndex++}: Secondary findings`}
                            />
                          )}

                          <OrderReportAppendixGeneTable
                            orderReport={orderReport}
                            appendixGeneTableShown={appendixGeneTableShown}
                            className={"appendixGeneTableShown"}
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Gene table`}
                            reportVersion={orderReport.version}
                            isEmpty={this.isEmpty}
                          />

                          <OrderReportAppendixSequencingCoverage
                            coverageFile={this.props.coverageFile}
                            mitoCoverageFile={this.props.mitoCoverageFile}
                            orderReport={orderReport}
                            appendixSequencingCoverageShown={
                              appendixSequencingCoverageShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            isEmpty={this.isEmpty}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Sequencing coverage`}
                            reportVersion={orderReport.version}
                          />

                          <OrderReportAppendixSummaryOfMethods
                            orderReport={orderReport}
                            appendixSummaryMethodsShown={
                              appendixSummaryMethodsShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={
                              orderReport.version > 6
                                ? `APPENDIX ${appendixIndex++}: Summary of the test`
                                : `APPENDIX ${appendixIndex++}: Summary of methods`
                            }
                            orderInfo={orderInfo}
                          />

                          <OrderReportAppendixDownloads
                            reportOrderFiles={reportOrderFiles}
                            appendixDownloadsShown={appendixDownloadsShown}
                            onTogglePanel={this.onTogglePanel}
                            downloadReportAttachedFile={
                              this.downloadReportAttachedFile
                            }
                            appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                          />

                          <OrderReportAppendixArchivesHistory
                            archiveReportFiles={archiveReportFiles}
                            logs={orderReport.logs}
                            appendixArchivesHistoryShown={
                              appendixArchivesHistoryShown
                            }
                            onTogglePanel={this.onTogglePanel}
                            isEmpty={this.isEmpty}
                            appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                            getArchiveFile={this.getArchiveFile}
                          />
                        </div>
                      )}
                    {isExomeSpecialTypes && orderInfo && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />
                        {orderReport.version >= 7 && (
                          <div>
                            <OrderReportAppendixAddFindings
                              orderReport={orderReport}
                              appendixAddFindingsShown={
                                appendixAddFindingsShown
                              }
                              onTogglePanel={this.onTogglePanel}
                              ref={(thisDiv) => {
                                this.additionalFindingsAppendix = thisDiv;
                              }}
                              appendixHeading={`APPENDIX ${appendixIndex++}: Additional findings`}
                              isEmpty={this.isEmpty}
                            />
                            {orderReport.secondary_findings_appendix_visible && (
                              <OrderReportAppendixSecondaryFindings
                                orderReport={orderReport}
                                appendixSecondaryFindingsShown={
                                  appendixSecondaryFindingsShown
                                }
                                onTogglePanel={this.onTogglePanel}
                                ref={(thisDiv) => {
                                  this.secondaryFindingsAppendix = thisDiv;
                                }}
                                appendixHeading={`APPENDIX ${appendixIndex++}: Secondary findings`}
                              />
                            )}
                          </div>
                        )}

                        <OrderReportAppendixSequencingCoverage
                          coverageFile={this.props.coverageFile}
                          mitoCoverageFile={this.props.mitoCoverageFile}
                          orderReport={orderReport}
                          appendixSequencingCoverageShown={
                            appendixSequencingCoverageShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Sequencing coverage`}
                          reportVersion={orderReport.version}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                          handleScrollTo={this.handleScrollTo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                        {orderReport.version >= 7 && (
                          <OrderReportAppendixNoneCodingVariants
                            orderReport={orderReport}
                            appendixNoneCodingVariantsShown={
                              appendixNoneCodingVariantsShown
                            }
                            ref={(thisDiv) => {
                              this.noneCodingVariantsAppendix = thisDiv;
                            }}
                            onTogglePanel={this.onTogglePanel}
                            appendixHeading={`APPENDIX ${appendixIndex++}: ${
                              orderReport.non_coding_variants_title
                            }`}
                          />
                        )}
                      </div>
                    )}
                    {isWgsSpecialType && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                      </div>
                    )}
                    {orderInfo.analysis_type ===
                      "deletion_duplication_analysis" && (
                      <div>
                        <OrderReportAppendixReferral
                          sortedPanelQaArr={sortedPanelQaArr}
                          appendixReferralShown={appendixReferralShown}
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Referral`}
                        />

                        <OrderReportAppendixAddFindings
                          orderReport={orderReport}
                          appendixAddFindingsShown={appendixAddFindingsShown}
                          onTogglePanel={this.onTogglePanel}
                          ref={(thisDiv) => {
                            this.additionalFindingsAppendix = thisDiv;
                          }}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Additional findings`}
                          isEmpty={this.isEmpty}
                        />

                        <OrderReportAppendixSummaryOfMethods
                          orderReport={orderReport}
                          appendixSummaryMethodsShown={
                            appendixSummaryMethodsShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Summary of methods`}
                          orderInfo={orderInfo}
                        />

                        <OrderReportAppendixDownloads
                          reportOrderFiles={reportOrderFiles}
                          appendixDownloadsShown={appendixDownloadsShown}
                          onTogglePanel={this.onTogglePanel}
                          downloadReportAttachedFile={
                            this.downloadReportAttachedFile
                          }
                          appendixHeading={`APPENDIX ${appendixIndex++}: Downloads`}
                        />

                        <OrderReportAppendixArchivesHistory
                          archiveReportFiles={archiveReportFiles}
                          logs={orderReport.logs}
                          appendixArchivesHistoryShown={
                            appendixArchivesHistoryShown
                          }
                          onTogglePanel={this.onTogglePanel}
                          isEmpty={this.isEmpty}
                          appendixHeading={`APPENDIX ${appendixIndex++}: Report archive and change history`}
                          getArchiveFile={this.getArchiveFile}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
    } else if (orderInfo && !gettingOrder && !reportBody) {
      reportBody = (
        <div>
          <h1>{orderInfo.product.name}</h1>
          <h5>Order ID {orderInfo.order_id}</h5>
          This report version does not support reading online. Please download
          the report file. <br />
          <br />
          <button
            className="btn btn-primary"
            onClick={() => this.props.getReportFile(orderInfo.order_id)}
          >
            Print report
          </button>
          {archiveReportFiles && !this.isEmpty(archiveReportFiles) && (
            <div className={styles.report_archive_list}>
              <h2>Report archive</h2>
              <ul className="list-unstyled">
                {Object.keys(archiveReportFiles).map((key) => (
                  <li
                    className="attached-file-text"
                    key={key}
                    onClick={() => this.getArchiveFile(key)}
                  >
                    Archived: {archiveReportFiles[key].created_str}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={classNames([styles.order_report_page, "container"])}>
        <Helmet title="Order report" />
        <Link className={styles.link_back} to="/orders">
          Back to orders
        </Link>
        {(gettingOrder || gettingReport) && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
        {!gettingOrder && !gettingReport && !reportBody && (
          <div style={{ marginTop: "20px" }}>
            Report not found or not accessible. Please contact support.
          </div>
        )}
        {reportBody}
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderReport);
