import React from 'react';

import Spinner from './Spinner';

const SpinnerOverlay = () => (
  <div className="spinner-fixed">
    <Spinner />
  </div>
);

export default SpinnerOverlay;
