import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';

import { isIndexOrderIdValid } from 'utils/forms';
import { TertiaryButton } from 'components';

import styles from './IndexOrderIdInput.scss';

const IndexOrderIdInput = ({ onSubmitIndexOrderId }) => {
  const { values } = useFormikContext();

  const handleInputKeyUp = (e) => {
    if (e.key === 'Enter') {
      onSubmitIndexOrderId();
    }
  };

  return (
    <div className="form-group row">
      <label className="col-md-6 col-form-label">
        Index patient&apos;s Blueprint Genetics order ID
      </label>

      <Field
        type="number"
        name="index_order_id"
        className="form-control short"
        onKeyUp={handleInputKeyUp}
      />

      <TertiaryButton
        id="confirm-index-order-id"
        labelText="Confirm Order ID"
        onClick={onSubmitIndexOrderId}
        disabled={!isIndexOrderIdValid(values.index_order_id)}
        additionalClass={styles.button}
      />
    </div>
  );
};

IndexOrderIdInput.propTypes = {
  onSubmitIndexOrderId: PropTypes.func.isRequired,
};

export default IndexOrderIdInput;
