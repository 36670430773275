import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import isEqual from 'lodash/isEqual';
import { LabelInput, Spinner } from 'components';
import {
  createGroup,
  deleteGroup,
  resetCreateGroup,
  getUsersOfGroup,
  addUserOfGroup,
  deleteUserOfGroup,
} from 'redux/modules/support';
import { convertCamelToSnake } from 'utils/convertSnakeCamel';

import styles from './NewGroupModal.scss';

const mapStateToProps = (state) => ({
  waitingCreateGroup: state.support.waitingCreateGroup,
  doneCreateGroup: state.support.doneCreateGroup,
  doneDeleteGroup: state.support.doneDeleteGroup,
  usersOfGroup: state.support.usersOfGroup,
  error: state.support.error,
  userOfGroupError: state.support.userOfGroupError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createGroup,
  deleteGroup,
  resetCreateGroup,
  getUsersOfGroup,
  addUserOfGroup,
  deleteUserOfGroup,
}, dispatch);

class NewGroupModal extends Component {
  static propTypes = {
    waitingCreateGroup: PropTypes.bool,
    doneCreateGroup: PropTypes.bool,
    doneDeleteGroup: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    createGroup: PropTypes.func.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    resetCreateGroup: PropTypes.func.isRequired,
    getUsersOfGroup: PropTypes.func.isRequired,
    addUserOfGroup: PropTypes.func.isRequired,
    deleteUserOfGroup: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedGroup: PropTypes.shape({
      groupId: PropTypes.string,
      groupName: PropTypes.string,
      groupAdminName: PropTypes.string,
      groupAdminEmail: PropTypes.string,
    }),
    usersOfGroup: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    userOfGroupError: PropTypes.string,
  };

  static defaultProps = {
    waitingCreateGroup: null,
    doneCreateGroup: null,
    doneDeleteGroup: null,
    selectedGroup: undefined,
    usersOfGroup: [],
    userOfGroupError: null,
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };

    this.initialData = {};
  }

  componentDidMount() {
    this.updateList();
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      if (this.props.selectedGroup) {
        const group = this.props.selectedGroup;

        this.setState({
          data: this.initialData = {
            id: group.groupId,
            groupName: group.groupName,
            groupAdminName: group.groupAdminName,
            groupAdminEmail: group.groupAdminEmail,
          },
          error: this.props.error,
          userOfGroupError: this.props.userOfGroupError,
        }, () => this.updateList(group.groupId));
      } else {
        this.setState({
          data: this.initialData = {
            id: 0,
          },
        });
      }
    }

    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    if (prevProps.userOfGroupError !== this.props.userOfGroupError) {
      this.setState({ userOfGroupError: this.props.userOfGroupError });
    }
    if (!prevProps.doneCreateGroup && this.props.doneCreateGroup) {
      this.close();
    }
    if (!prevProps.doneDeleteGroup && this.props.doneDeleteGroup) {
      this.close();
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  isPristine = () => isEqual(this.state.data, this.initialData)

  close = () => {
    this.props.onClose();
    this.props.resetCreateGroup();
  }

  handleChange = (event) => {
    const input = event.target;
    this.setState((state) => ({
      data: {
        ...state.data,
        [input.name]: input.type === 'checkbox' ? input.checked : input.value,
      },
    }));
  }

  handleCreate = () => {
    this.props.createGroup(convertCamelToSnake(this.state.data));
  }

  handleClose = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (this.isPristine() || confirm('Are you sure you want to close without saving? All changes you have made will be lost.') === true) {
      this.close();
    }
  }

  deleteGroup = () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to delete this group?') === true) {
      this.props.deleteGroup({ id: this.state.data.id });
    }
  }

  deleteUserOfGroup = async (userOfGroup) => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to remove member from this group?') === true) {
      await this.props.deleteUserOfGroup({ user_of_group_id: userOfGroup.user_of_group_id });
      this.props.getUsersOfGroup({ group_id: this.props.selectedGroup.groupId });
    }
  }

  addUserOfGroup = async (event) => {
    const email = this.state.data.group_member_email;
    const id = this.props.selectedGroup.groupId;

    event.preventDefault();
    if (email && id) {
      await this.props.addUserOfGroup({ group_member_email: email, group_id: id });
      this.props.getUsersOfGroup({ group_id: id });

      if (!this.props.userOfGroupError) {
        this.setState((state) => ({
          data: {
            ...state.data,
            group_member_email: null,
          },
        }));
      }
    }
  }

  checkValidity() {
    const data = this.state.data;
    return data.groupName && data.groupAdminEmail;
  }

  updateList(id) {
    if ((!this.props.usersOfGroup || !this.props.usersOfGroup.length) && id) {
      this.props.getUsersOfGroup({ group_id: id });
    }
  }

  renderUserOfGroup = (userOfGroup) => (
    <tr key={userOfGroup.user_of_group_id}>
      <td><span>{userOfGroup.name}</span></td>
      <td><span>{userOfGroup.email}</span></td>
      <td>
        <button
          type="button"
          className="btn btn-link"
          data-userofgroupid={userOfGroup.user_of_group_id}
          onClick={() => this.deleteUserOfGroup(userOfGroup)}
        >
          Remove
        </button>
      </td>
    </tr>
  );

  render() {
    const {
      show,
      waitingCreateGroup,
    } = this.props;
    const {
      error,
      userOfGroupError,
    } = this.state;

    const data = this.state.data;
    const selectedGroup = this.props.selectedGroup;
    const usersOfGroup = this.props.usersOfGroup;
    const isModify = selectedGroup !== undefined;
    const isValidInput = this.checkValidity();
    const pristine = this.isPristine();
    const isValidMemberEmail = this.state.data.group_member_email;

    return (
      <Modal show={show} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-sm">
        <Modal.Header closeButton>
          {!isModify && <h2 style={{ marginTop: 30 }}>Create Group</h2>}
          {isModify && <h2 style={{ marginTop: 30 }}>Modify Group</h2>}
        </Modal.Header>
        <Modal.Body>
          <form name="groupForm">
            <LabelInput
              label="Group name"
              name="groupName"
              required
              labelSize="6"
              inputSize="6"
              value={data.groupName}
              onChange={this.handleChange}
            />
            <LabelInput
              label="Group admin email"
              name="groupAdminEmail"
              required
              labelSize="6"
              inputSize="6"
              value={data.groupAdminEmail}
              onChange={this.handleChange}
            />
          </form>

        </Modal.Body>
        <Modal.Footer>
          {error && (<p className={`${styles.error} clearfix`}>{error}</p>)}
          {isModify && (
            <button type="button" className="btn btn-default" onClick={this.deleteGroup}>Delete</button>
          )}
          <button type="button" className="btn btn-default" onClick={this.handleClose}>Cancel</button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || !isValidInput}
            onClick={this.handleCreate}
          >
            Save
          </button>

        </Modal.Footer>
        <Modal.Body>
          {isModify && (
          <div>
            <h4>Group members</h4>
            <table className="table">
              <tbody>
                {
                  usersOfGroup && usersOfGroup.map(this.renderUserOfGroup)
                }
              </tbody>
            </table>
            <form name="userOfGroupForm" onSubmit={this.addUserOfGroup}>
              <LabelInput
                label="Group member email"
                name="group_member_email"
                required
                labelSize="6"
                inputSize="6"
                value={data.group_member_email}
                onChange={this.handleChange}
              />
            </form>
          </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {userOfGroupError && (<p className={`${styles.error} clearfix`}>{userOfGroupError}</p>)}
          {isModify && (
            <button
              type="button"
              className="btn btn-default"
              disabled={pristine || !isValidMemberEmail}
              onClick={this.addUserOfGroup}
            >
              Add group member
            </button>
          )}
        </Modal.Footer>

        {waitingCreateGroup && (
          <div className={styles.spinner_block}>
            <Spinner />
          </div>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewGroupModal);
