import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getProfileInfo, reset2fa, disable2fa, confirmDisable2fa,
} from 'redux/modules/auth';
import { Spinner } from 'components';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import styles from './AccountSettingsDisable2fa.scss';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  gettingProfileInfo: state.auth.gettingProfileInfo,
  authenticationKey: state.auth.authenticationKey,
  qrCodeData: state.auth.qrCodeData,
  disable2faPending: state.auth.disable2faPending,
  disable2faSuccess: state.auth.disable2faSuccess,
  disable2faError: state.auth.disable2faError,
  disable2faEmail: state.auth.disable2faEmail,
  confirmDisable2faPending: state.auth.confirmDisable2faPending,
  confirmDisable2faSuccess: state.auth.confirmDisable2faSuccess,
  confirmDisable2faError: state.auth.confirmDisable2faError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
  reset2fa,
  disable2fa,
  confirmDisable2fa,
}, dispatch);

class AccountSettingsDisable2fa extends Component {
  static propTypes = {
    userInfo: PropTypes.shape({}),
    gettingProfileInfo: PropTypes.bool,
    reset2fa: PropTypes.func.isRequired,
    disable2faPending: PropTypes.bool,
    disable2faSuccess: PropTypes.bool,
    disable2faError: PropTypes.string,
    disable2faEmail: PropTypes.string,
    disable2fa: PropTypes.func.isRequired,
    confirmDisable2faPending: PropTypes.bool,
    confirmDisable2faSuccess: PropTypes.bool,
    confirmDisable2faError: PropTypes.string,
    getProfileInfo: PropTypes.func.isRequired,
    confirmDisable2fa: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    userInfo: null,
    gettingProfileInfo: null,
    disable2faPending: null,
    disable2faSuccess: null,
    disable2faError: null,
    disable2faEmail: null,
    confirmDisable2faPending: null,
    confirmDisable2faSuccess: null,
    confirmDisable2faError: null,
    location: {},
  };

  state = {
    code: '',
  };

  componentDidMount() {
    const token = queryString.parse(this.props.location.search).auth;

    this.props.getProfileInfo();
    this.props.reset2fa();
    if (token) {
      this.props.confirmDisable2fa({
        token,
      });
    }
  }

  handleClickDisable2fa = () => {
    this.props.disable2fa({
      code: this.state.code,
    });
  }

  handleCodeChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  }

  render() {
    const { gettingProfileInfo } = this.props;
    const waiting = (
      gettingProfileInfo
      || this.props.disable2faPending
      || this.props.confirmDisable2faPending
    );
    const token = queryString.parse(this.props.location.search).auth;

    return (
      <div className={`${styles.accountSettings} container`}>
        <div className="row">
          <div className={`${styles.header} col-md-12`}>
            <Helmet title="Confirm disable two-factor authentication" />
            <h1>Disable two-factor authentication</h1>
          </div>
          {!gettingProfileInfo && (
            <div style={{ marginLeft: 15, marginRight: 15 }}>
              {this.props.confirmDisable2faSuccess && (
                <div>
                  <p>
                    <strong>Two-factor authentication is now disabled for your account.</strong>
                  </p>
                </div>
              )}
              <p className="text-danger">{this.props.confirmDisable2faError}</p>

              {!token && !this.props.disable2faSuccess && (
                <div>
                  <label>Authentication code</label>
                  <p>
                    <input
                      className="form-control"
                      type="password"
                      value={this.state.code}
                      style={{ width: 200 }}
                      onChange={this.handleCodeChange}
                    />
                  </p>
                  <p className="text-danger">{this.props.disable2faError}</p>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={this.props.disable2faPending}
                    onClick={this.handleClickDisable2fa}
                  >
                    Send confirmation email
                  </button>
                </div>
              )}

              {this.props.disable2faSuccess && (
                <div>
                  <p>
                    A confirmation email with further instructions has been sent to you email
                    address. Please follow the link in that email to continue the process.
                  </p>
                  {this.props.disable2faEmail && <pre>{this.props.disable2faEmail}</pre>}
                </div>
              )}
            </div>
          )}

          {waiting && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettingsDisable2fa);
