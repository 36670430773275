import React from 'react';
import PropTypes from 'prop-types';

import QuestionPropTypes from 'types/QuestionPropTypes';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';

const DOBPreview = ({ question }) => (
  <SimpleDatePicker
    field={{
      name: question.name,
      value: '1985-06-18',
    }}
    form={{
      touched: {},
      errors: {},
      setFieldValue: () => {},
    }}
    label={question.question}
    preview
  />
);

DOBPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default DOBPreview;
