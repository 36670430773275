import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import ConsentDefault from './ConsentDefault';
import ComponentLayout from '../ComponentLayout';

const OrderTestFormInformedConsent = (props) => {
  const { orderInfo: { order_id: orderId } } = props;
  return (
    <ComponentLayout
      type="informed_consent"
      orderId={orderId}
      defaultToRender={ConsentDefault}
      {...props}
    />
  );
};

OrderTestFormInformedConsent.propTypes = {
  downloadConsentFile: PropTypes.func.isRequired,
  saveOrder: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getComponentLayout: PropTypes.func.isRequired,
  resetComponentLayout: PropTypes.func.isRequired,
  orderInfo: OrderInfoPropTypes.isRequired,
  hideModal: PropTypes.func.isRequired,
  getConsentLanguages: PropTypes.func.isRequired,
  panelGeneLists: PropTypes.shape({
    panel: PropTypes.shape({
      secondary_findings: PropTypes.bool,
    }),
  }),
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
  }).isRequired,
};

OrderTestFormInformedConsent.defaultProps = {
  panelGeneLists: {
    panel: {
      secondary_findings: false,
    },
  },
};

const mapStateToProps = (state) => ({
  orderInfo: state.orders.orderInfo,
  panelGeneLists: state.orders.panelGeneLists,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadConsentFile: orderActions.downloadConsentFile,
  saveOrder: orderActions.saveOrder,
  getOrder: orderActions.getOrder,
  getConsentLanguages: orderActions.getConsentLanguages,
  getComponentLayout: orderActions.getComponentLayout,
  resetComponentLayout: orderActions.resetComponentLayout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestFormInformedConsent);
