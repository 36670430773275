import React from 'react';
import PropTypes from 'prop-types';

import styles from '../BatchOrder.scss';

const mapResultData = (resultData, text, className) => resultData.map((data) => (
  <div className={className} key={data}>
    {text}
    {data}
  </div>
));

const CreatedOrdersResult = ({
  ordersCreated, dataErrors, dataWarning, error,
}) => {
  const showCreatedOrders = ordersCreated && ordersCreated.length > 0;
  const showDataErrors = dataErrors && dataErrors.length > 0;
  const showDataWarning = dataWarning && dataWarning.length > 0;

  const heading = showCreatedOrders ? `Orders created: ${ordersCreated.length}` : 'Errors encountered while parsing data:';

  return (
    <div className={styles.batch_import_body}>
      {error && <p className="text-danger">{error}</p>}

      {showDataWarning && (
        <>
          <h3>Warning:</h3>
          <div>{dataWarning}</div>
        </>
      )}
      {(showCreatedOrders || showDataErrors) && (
        <>
          <h3>
            {heading}
          </h3>
          {showCreatedOrders ? mapResultData(ordersCreated, 'Order: ') : mapResultData(dataErrors, '- ', styles.errors)}
        </>
      )}
    </div>
  );
};

CreatedOrdersResult.propTypes = {
  ordersCreated: PropTypes.arrayOf(PropTypes.number),
  dataErrors: PropTypes.arrayOf(PropTypes.string),
  dataWarning: PropTypes.string,
  error: PropTypes.string,
};

CreatedOrdersResult.defaultProps = {
  ordersCreated: null,
  dataErrors: null,
  dataWarning: null,
  error: null,
};

export default CreatedOrdersResult;
