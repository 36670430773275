import { EXOME } from 'helpers/constants/specialTypeConstants';

export const calculatePanelTiers = (panels, panelTiers, pricingModel) => panels.map((panel) => {
  const isSingleGeneTest = panel.category_name === 'Single gene tests';
  const isWESPanel = panel.special_type && panel.special_type.includes(EXOME);
  const isVSTPanel = panel.category_name === 'Variant Specific Testing';
  if (panel.gene_count && !(isSingleGeneTest || isWESPanel || isVSTPanel)) {
    const phenotypeTier = pricingModel === 'phenotype' ? `phenotype_tier_${panel.phenotype_tier}` : '';
    const panelTier = panelTiers.find((tier) => {
      if (pricingModel === 'phenotype') {
        return phenotypeTier === tier.size;
      }
      return tier.minimum <= panel.gene_count
        && (tier.maximum ? tier.maximum >= panel.gene_count : true);
    });
    return { ...panel, panel_tier: panelTier };
  }
  return panel;
});

export const calculatePanelTier = (geneCount, panelTiers) => panelTiers?.find((tier) => (
  tier.minimum <= geneCount
  && (tier.maximum ? tier.maximum >= geneCount : true)
));

export const getPanelTierNumber = (phenotypeName) => {
  switch (phenotypeName) {
    case 'phenotype_tier_1':
      return 1;
    case 'phenotype_tier_2':
      return 2;
    case 'phenotype_tier_3':
      return 3;
    case 'phenotype_tier_4':
      return 4;
    default:
      return phenotypeName;
  }
};
