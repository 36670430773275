export function getDefaultColumnOrder(column) {
  switch (column) {
    case 'status':
    case 'insurance_status':
    case 'owner':
      return 'asc';
    default:
      return 'desc';
  }
}

export function getColumnOrder(query, column) {
  switch (query.order) {
    case 'desc':
    case 'asc':
      return query.order;

    default:
      return getDefaultColumnOrder(column);
  }
}

export const getOrderBy = (query) => query.order_by || 'date';
