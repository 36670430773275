import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from './ErrorMessage';

const Textarea = ({
  // Field
  field,
  // Appearance
  label,
  labelSize,
  inputSize,
  hint,
  textareaClassName,
  placeholder,
  required,
}) => (
  <div className="form-group row">
    {label && (
      <label htmlFor={field.name} className={`col-md-${labelSize} col-form-label`}>
        {required ? `${label} *` : label}
        {hint && (<p className="small">{hint}</p>)}
      </label>
    )}

    <div className={classNames(`col-md-${inputSize}`, {
      [`offset-md-${labelSize}`]: !label,
      'no-padding-left': !!label,
    })}
    >
      <textarea
        id={field.name}
        className={`form-control ${textareaClassName}`}
        placeholder={placeholder}
        required={required}
        {...field}
        value={field.value || ''}
      />

      <ErrorMessage name={field.name} />
    </div>
  </div>
);

Textarea.propTypes = {
  // Field
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,

  // Appearance
  label: PropTypes.node,
  labelSize: PropTypes.number,
  inputSize: PropTypes.number,
  hint: PropTypes.string,
  textareaClassName: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

Textarea.defaultProps = {
  label: null,
  labelSize: 6,
  inputSize: 6,
  hint: null,
  textareaClassName: '',
  placeholder: null,
  required: false,
};

export default Textarea;
