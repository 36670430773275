import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import * as orderActions from 'redux/modules/orders';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import {
  isBiomarin,
  isScreening,
  isSpark,
} from 'utils/forms';
import {
  NEI,
  BIOMARIN,
  SPARK,
} from 'helpers/constants/specialTypeConstants';
import ConsentDownloadForm from './ConsentDownloadForm';
import ConsentSecondaryFindingsForm from './ConsentSecondaryFindingsForm';
import ConsentSparkForm from './ConsentSparkForm';
import ConsentSparkFormV2 from './ConsentSparkFormV2';
import ConsentSparkFormV3 from './ConsentSparkFormV3';
import ConsentSparkFormV4 from './ConsentSparkFormV4';
import ConsentPopUpModal from './ConsentPopUpModal';
import ConsentScreeningForm from './ConsentScreeningForm';

const mapStateToProps = (state) => ({
  orderInfo: state.orders.orderInfo,
  panelGeneLists: state.orders.panelGeneLists,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadConsentFile: orderActions.downloadConsentFile,
  saveOrder: orderActions.saveOrder,
  getOrder: orderActions.getOrder,
  getConsentLanguages: orderActions.getConsentLanguages,
}, dispatch);

class ConsentDefault extends Component {
  static propTypes = {
    downloadConsentFile: PropTypes.func.isRequired,
    saveOrder: PropTypes.func.isRequired,
    getOrder: PropTypes.func.isRequired,
    orderInfo: OrderInfoPropTypes.isRequired,
    hideModal: PropTypes.func.isRequired,
    getConsentLanguages: PropTypes.func.isRequired,
    panelGeneLists: PropTypes.shape({
      panel: PropTypes.shape({
        secondary_findings: PropTypes.bool,
      }),
    }),
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    panelGeneLists: {
      panel: {
        secondary_findings: false,
      },
    },
  };

  state = {
    loading: true,
    error: null,
    consentInfo: {
      consentLanguage: 'en',
    },
    hasSecondaryFindings: false,
    panelVersion: null,
    productCode: null,
    specialType: null,
    consentLanguages: {},
    userSawConsentModal: false,
    isConsentSigned: false,
    secondaryFindingsConsent: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const {
      orderInfo,
      getOrder,
      getConsentLanguages,
      panelGeneLists,
    } = this.props;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      // always fetch order details
      // to always have the latest value
      const orderDetails = await getOrder(orderInfo.order_id);
      this.setState({
        secondaryFindingsConsent: orderDetails.patient_secondary_findings_consent,
      });
      const consentLanguages = await getConsentLanguages(orderInfo.product.id);

      // default values for normal consent and spark
      const consentInfo = {
        consentLanguage: 'en',
        consentSigned: orderDetails.patient_consent,
      };

      // if secondary findings depends on get panel gene lists
      // (panels with suffix 'ACMG59 genes' in name)
      let hasSecondaryFindings = false;
      if (
        panelGeneLists
        && panelGeneLists.panel
        && panelGeneLists.panel.secondary_findings
      ) {
        hasSecondaryFindings = panelGeneLists.panel.secondary_findings;
      }

      this.setState({
        consentLanguages,
        consentInfo, // formik form initial values
        specialType: orderDetails.special_type, // for spark and biomarin
        productCode: orderDetails.product.code, // for different version of MRT
        panelVersion: orderDetails.product.version, // for ConsentSparkForm
        hasSecondaryFindings,
        loading: false,
        userSawConsentModal: false,
        isConsentSigned: orderDetails.patient_consent,
      });
    } catch (e) {
      this.setState({
        error: 'Failed to load consent info',
        loading: false,
      });
    }
  };

  isValidScreeningConsent = (screeningConsent) => {
    if (!screeningConsent) {
      // eslint-disable-next-line
      alert(
        'Acknowledgement is required in order to proceed - please tick the box.',
      );
      return false;
    }

    return true;
  };

  isValidSecondaryFindingsConsent = (secondaryFindingsConsent) => {
    if (secondaryFindingsConsent !== 'yes') {
      // eslint-disable-next-line
      alert(
        'Patient consent is required in order to report ACMG59 secondary findings. If patient has not given permission for the analysis of ACMG59 genes, please order the equivalent panel without ACMG59 genes.',
      );
      return false;
    }

    return true;
  };

  isValidSparkConsent = (consentSigned) => {
    if (!consentSigned) {
      // eslint-disable-next-line
      alert('Patient consent is required in order to proceed with the order.');
      return false;
    }

    return true;
  };

  validate = (values) => {
    const { consentSigned } = values;
    const {
      hasSecondaryFindings,
      specialType,
      userSawConsentModal,
      secondaryFindingsConsent,
    } = this.state;

    // update isConsentSigned state whenever the checkbox is ticked/unticked
    this.setState({ isConsentSigned: consentSigned });

    if (userSawConsentModal) {
      return {};
    }

    if (isSpark(specialType)) { // My Retina Tracker Panel
      if (!this.isValidSparkConsent(consentSigned)) {
        this.setState({
          userSawConsentModal: true,
        });
        return { hiddenError: 'Consent needed!' };
      }
    }

    if (hasSecondaryFindings) {
      if (!this.isValidSecondaryFindingsConsent(secondaryFindingsConsent)) {
        this.setState({
          userSawConsentModal: true,
        });
        return { hiddenError: 'Consent needed!' };
      }
    }

    if (isScreening(specialType)) {
      if (!this.isValidScreeningConsent(consentSigned)) {
        return { hiddenError: 'Consent needed!' };
      }
    }

    return {};
  };

  submit = async (values, actions) => {
    const { orderInfo } = this.props;
    const { secondaryFindingsConsent } = this.state;

    try {
      await this.props.saveOrder(orderInfo.order_id, {
        patient_consent: values.consentSigned,
        patient_secondary_findings_consent: secondaryFindingsConsent,
      });
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  renderConsentForm(formProps) {
    const {
      specialType,
      panelVersion,
      productCode,
      hasSecondaryFindings,
      consentLanguages,
    } = this.state;

    const { consentLanguage } = formProps.values;

    if (isSpark(specialType)) {
      if (productCode === 'OP3701' && panelVersion === 1) {
        return <ConsentSparkForm panelVersion={panelVersion} />;
      }

      if (productCode === 'OP3701' && ['2', '3'].includes(panelVersion)) {
        return <ConsentSparkFormV2 />;
      }

      // 7 is newest version of OP3801
      if (productCode === 'OP3801' && parseInt(panelVersion, 10) === 7) {
        return (
          <ConsentSparkFormV4
            consentLanguages={consentLanguages}
            consentLanguage={consentLanguage}
          />
        );
      }

      return <ConsentSparkFormV3 />;
    }

    if (isScreening(specialType)) {
      return <ConsentScreeningForm />;
    }

    if (hasSecondaryFindings) {
      return <ConsentSecondaryFindingsForm />;
    }

    return (
      <ConsentDownloadForm
        consentLanguages={consentLanguages}
        consentLanguage={consentLanguage}
      />
    );
  }

  renderDisclaimer() {
    let disclaimer = '';

    const { specialType } = this.state;

    switch (specialType) {
      case NEI:
        disclaimer = 'Please send a copy of the signed consent form together with the patient sample to Blueprint Genetics.';
        break;
      case BIOMARIN:
        disclaimer = (
          <span>
            Please note that this panel requires a specific consent form to be
            sent with the sample to Blueprint Genetics.
            <br />
            Without this specific consent form, we cannot proceed with the
            order.
          </span>
        );
        break;
      case SPARK:
        disclaimer = '';
        break;
      default:
    }

    return (
      <div
        className="form-group text-center"
        style={{ marginLeft: 0, marginRight: 0 }}
      >
        {disclaimer}
      </div>
    );
  }

  render() {
    const { hideModal, userInfo } = this.props;
    const {
      loading, error, consentInfo, specialType, isConsentSigned,
    } = this.state;
    const {
      is_immediate_disposal_province: isImmediateDisposalProvince,
    } = this.props.orderInfo;

    const isSupportRole = userInfo.user_role === 'support';
    const isScreeningSpecialType = isScreening(specialType);
    const showConsentPopUpModal = !isSupportRole && !isScreeningSpecialType;

    return (
      <Formik
        onSubmit={this.submit}
        validate={this.validate}
        enableReinitialize
        initialValues={consentInfo}
      >
        {(formProps) => (
          <Form>
            <PromptIfDirty {...formProps} />
            <EditOrderStep
              {...formProps}
              hideModal={hideModal}
              error={error}
              loading={loading}
              submit={(action) => (
                action === 'save'
                  ? this.submit(formProps.values, formProps)
                  : formProps.submitForm()
              )}
            >
              {consentInfo && (
                <div className="row">{this.renderConsentForm(formProps)}</div>
              )}
              {showConsentPopUpModal ? (
                <ConsentPopUpModal
                  isImmediateDisposalProvince={isImmediateDisposalProvince}
                  isConsentSigned={isConsentSigned}
                  isBiomarin={isBiomarin(specialType)}
                />
              ) : null}
              {this.renderDisclaimer()}
            </EditOrderStep>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentDefault);
