import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import * as auth from 'redux/modules/auth';

const AuthorizedProviderOrderInfo = ({
  label, name, hospitalName, isAuthorizedProvider, authorizationLastApproved,
}) => {
  const userRole = useSelector(auth.userRoleSelector);

  return (
    <div className="form-group row mb-0">
      <label className="col-md-6 col-form-label">{label}</label>
      <ul className="col-md-6 list-unstyled pl-md-0">
        <li>
          <label>Name:</label>
          {` ${name}`}
        </li>
        <li>
          <label>Institution:</label>
          {` ${hospitalName}`}
        </li>

        {userRole === 'support' && (
          <>
            <li>
              <label>Is authorized provider:</label>
              {isAuthorizedProvider ? ' Yes' : ' No'}
            </li>
            {isAuthorizedProvider && authorizationLastApproved && (
            <li>
              <label>Last approved on:</label>
              {` ${moment(authorizationLastApproved).format('YYYY-MM-DD')}`}
            </li>
            )}
          </>
        )}

      </ul>
    </div>
  );
};

AuthorizedProviderOrderInfo.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  hospitalName: PropTypes.string,
  isAuthorizedProvider: PropTypes.bool,
  authorizationLastApproved: PropTypes.string,
};

AuthorizedProviderOrderInfo.defaultProps = {
  name: null,
  hospitalName: null,
  isAuthorizedProvider: false,
  authorizationLastApproved: null,
};

export default AuthorizedProviderOrderInfo;
