import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../Users.scss';

const userPropTypes = {
  userDisabled: PropTypes.bool.isRequired,
};

const CommonCell = ({ cellData, rowData }) => {
  const { userInactive } = rowData;

  const cx = classNames.bind(styles);

  const className = cx({ inactive_user: userInactive }, 'common_cell');

  return (
    <div className={className}>
      {cellData}
    </div>
  );
};

CommonCell.propTypes = {
  cellData: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]).isRequired,
  rowData: PropTypes.shape(userPropTypes).isRequired,
};

export default CommonCell;
