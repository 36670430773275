import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getPanelQuestions } from 'redux/modules/orders';

import { LabelCheckboxDisplay } from 'components';
import ViewBillingInfoRow from './ViewBillingInfoRow';

const ViewBillingInfoICDHospitalStatus = ({ orderInfo, withHospitalStatus }) => {
  const [ICDAndHospitalStatusElement, setICDAndHospitalStatusElement] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getQuestions = () => dispatch(getPanelQuestions(orderInfo?.order_id));
        const panelQuestions = await getQuestions();
        const hospitalStatus = Object.values(panelQuestions)
          .filter((question) => ((question.name === 'icd10cod_1' || question.name === 'hospitals_1')
            && orderInfo.is_after_hospital_status_release_us_order));
        setICDAndHospitalStatusElement(hospitalStatus);
      } catch (e) {
        setICDAndHospitalStatusElement(null);
      }
    };
    fetchData();
  }, [dispatch, orderInfo?.is_after_hospital_status_release_us_order, orderInfo?.order_id]);
  return (
    <>
      {ICDAndHospitalStatusElement?.length > 0 && (
        <>
          <ViewBillingInfoRow
            value={ICDAndHospitalStatusElement[0].answer ?? 'N/A'}
            label="ICD-10 codes"
          />
          {withHospitalStatus && (
            <div key={ICDAndHospitalStatusElement[1].question_id}>
              <LabelCheckboxDisplay
                label={ICDAndHospitalStatusElement[1].question}
                labelSize="6"
                inputSize="6"
                hint={ICDAndHospitalStatusElement[1].hint}
                options={ICDAndHospitalStatusElement[1].question_options}
                freeTextAnswer={ICDAndHospitalStatusElement[1].free_text_option_answer}
                disabled
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

ViewBillingInfoICDHospitalStatus.propTypes = {
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number,
    is_after_hospital_status_release_us_order: PropTypes.bool,
  }).isRequired,
  withHospitalStatus: PropTypes.bool,
};

ViewBillingInfoICDHospitalStatus.defaultProps = {
  withHospitalStatus: false,
};

export default ViewBillingInfoICDHospitalStatus;
