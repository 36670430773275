import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';
import {
  formatAssayType,
} from '../ChangeOrderStatusConstants';

const DefaultAssayTypeInput = ({ value }) => (
  <LabelInput
    inputSize="6"
    label="Default assay type"
    labelSize="6"
    name="default_assay_type"
    readOnly
    value={formatAssayType(value)}
  />
);

DefaultAssayTypeInput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default DefaultAssayTypeInput;
