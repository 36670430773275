/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { downloadFileFunc } from 'utils/forms';

const GET_ORDERS = 'nucleus/GET_ORDERS';
const GET_ORDERS_SUCCESS = 'nucleus/GET_ORDERS_SUCCESS';
const GET_ORDERS_FAIL = 'nucleus/GET_ORDERS_FAIL';
const VIEW_ORDER = 'nucleus/VIEW_ORDER';
const VIEW_ORDER_SUCCESS = 'nucleus/VIEW_ORDER_SUCCESS';
const VIEW_ORDER_FAIL = 'nucleus/VIEW_ORDER_FAIL';
const VIEW_ORDER_DELIVERY_RESULT = 'nucleus/VIEW_ORDER_DELIVERY_RESULT';
const VIEW_ORDER_DELIVERY_RESULT_SUCCESS = 'nucleus/VIEW_ORDER_DELIVERY_RESULT_SUCCESS';
const VIEW_ORDER_DELIVERY_RESULT_FAIL = 'nucleus/VIEW_ORDER_DELIVERY_RESULT_FAIL';
const VIEW_VARIANTS = 'nucleus/VIEW_VARIANTS';
const VIEW_VARIANTS_SUCCESS = 'nucleus/VIEW_VARIANTS_SUCCESS';
const VIEW_VARIANTS_FAIL = 'nucleus/VIEW_VARIANTS_FAIL';
const VIEW_BILLING_INFO = 'nucleus/VIEW_BILLING_INFO';
const VIEW_BILLING_INFO_SUCCESS = 'nucleus/VIEW_BILLING_INFO_SUCCESS';
const VIEW_BILLING_INFO_FAIL = 'nucleus/VIEW_BILLING_INFO_FAIL';
const RESET_CURRENT_STEP = 'nucleus/RESET_CURRENT_STEP';
const CHANGE_CURRENT_STEP = 'nucleus/CHANGE_CURRENT_STEP';
const SET_REACHED_STEP = 'nucleus/SET_REACHED_STEP';
const CONSENT_POPUP_MODAL = 'nucleus/CONSENT_POPUP_MODAL';
const VIEW_COLLABORATOR_LIST = 'nucleus/VIEW_COLLABORATOR_LIST';
const VIEW_COLLABORATOR_LIST_FAIL = 'nucleus/VIEW_COLLABORATOR_LIST_FAIL';
const VIEW_COLLABORATOR_LIST_SUCCESS = 'nucleus/VIEW_COLLABORATOR_LIST_SUCCESS';
const RESET_ORDER_INFO = 'nucleus/RESET_ORDER_INFO';
const GET_PATIENT_INFO_QUESTIONS = 'nucleus/GET_PATIENT_INFO_QUESTIONS';
const GET_PATIENT_INFO_QUESTIONS_SUCCESS = 'nucleus/GET_PATIENT_INFO_QUESTIONS_SUCCESS';
const GET_PATIENT_INFO_QUESTIONS_FAIL = 'nucleus/GET_PATIENT_INFO_QUESTIONS_FAIL';
const GET_CONFIRMATION_QUESTIONS = 'nucleus/GET_CONFIRMATION_QUESTIONS';
const GET_CONFIRMATION_QUESTIONS_SUCCESS = 'nucleus/GET_CONFIRMATION_QUESTIONS_SUCCESS';
const GET_CONFIRMATION_QUESTIONS_FAIL = 'nucleus/GET_CONFIRMATION_QUESTIONS_FAIL';
const CHECK_ORDER = 'nucleus/CHECK_ORDER';
const CHECK_ORDER_SUCCESS = 'nucleus/CHECK_ORDER_SUCCESS';
const CHECK_ORDER_FAIL = 'nucleus/CHECK_ORDER_FAIL';
const LIST_BILLING_INFO_ORDER_FILES = 'nucleus/LIST_BILLING_INFO_ORDER_FILES';
const LIST_BILLING_INFO_ORDER_FILES_SUCCESS = 'nucleus/LIST_BILLING_INFO_ORDER_FILES_SUCCESS';
const LIST_BILLING_INFO_ORDER_FILES_FAIL = 'nucleus/LIST_BILLING_INFO_ORDER_FILES_FAIL';
const LIST_REPORT_ORDER_FILES = 'nucleus/LIST_REPORT_ORDER_FILES';
const LIST_REPORT_ORDER_FILES_SUCCESS = 'nucleus/LIST_REPORT_ORDER_FILES_SUCCESS';
const LIST_REPORT_ORDER_FILES_FAIL = 'nucleus/LIST_REPORT_ORDER_FILES_FAIL';
const LIST_REQUISITION_ORDER_FILES = 'nucleus/LIST_REQUISITION_ORDER_FILES';
const LIST_REQUISITION_ORDER_FILES_SUCCESS = 'nucleus/LIST_REQUISITION_ORDER_FILES_SUCCESS';
const LIST_REQUISITION_ORDER_FILES_FAIL = 'nucleus/LIST_REQUISITION_ORDER_FILES_FAIL';
const GET_REPORT = 'nucleus/GET_REPORT';
const GET_REPORT_SUCCESS = 'nucleus/GET_REPORT_SUCCESS';
const GET_REPORT_FAIL = 'nucleus/GET_REPORT_FAIL';
const LIST_REPORT_ARCHIVE_FILES = 'nucleus/LIST_REPORT_ARCHIVE_FILES';
const LIST_REPORT_ARCHIVE_FILES_SUCCESS = 'nucleus/LIST_REPORT_ARCHIVE_FILES_SUCCESS';
const LIST_REPORT_ARCHIVE_FILES_FAIL = 'nucleus/LIST_REPORT_ARCHIVE_FILES_FAIL';
const SAVE_ORDER_COMMENTS = 'nucleus/SAVE_ORDER_COMMENTS';
const SAVE_ORDER_COMMENTS_SUCCESS = 'nucleus/SAVE_ORDER_COMMENTS_SUCCESS';
const SAVE_ORDER_COMMENTS_FAIL = 'nucleus/SAVE_ORDER_COMMENTS_FAIL';
const RESET_ORDER_REPORT_VALUES = 'nucleus/RESET_ORDER_REPORT_VALUES';
const GET_ORDER_FILE = 'nucleus/GET_ORDER_FILE';
const GET_ORDER_FILE_SUCCESS = 'nucleus/GET_ORDER_FILE_SUCCESS';
const GET_ORDER_FILE_FAIL = 'nucleus/GET_ORDER_FILE_FAIL';
const GET_REPORT_FILE = 'nucleus/GET_REPORT_FILE';
const GET_REPORT_FILE_SUCCESS = 'nucleus/GET_REPORT_FILE_SUCCESS';
const GET_REPORT_FILE_FAIL = 'nucleus/GET_REPORT_FILE_FAIL';
const GET_REPORT_ARCHIVE_FILE = 'nucleus/GET_REPORT_ARCHIVE_FILE';
const GET_REPORT_ARCHIVE_FILE_SUCCESS = 'nucleus/GET_REPORT_ARCHIVE_FILE_SUCCESS';
const GET_REPORT_ARCHIVE_FILE_FAIL = 'nucleus/GET_REPORT_ARCHIVE_FILE_FAIL';
const GET_ORDER_SUMMARY_FILE = 'nucleus/GET_ORDER_SUMMARY_FILE';
const GET_ORDER_SUMMARY_FILE_SUCCESS = 'nucleus/GET_ORDER_SUMMARY_FILE_SUCCESS';
const GET_ORDER_SUMMARY_FILE_FAIL = 'nucleus/GET_ORDER_SUMMARY_FILE_FAIL';
const GET_PANEL_QA = 'nucleus/GET_PANEL_QA';
const GET_PANEL_QA_SUCCESS = 'nucleus/GET_PANEL_QA_SUCCESS';
const GET_PANEL_QA_FAIL = 'nucleus/GET_PANEL_QA_FAIL';
const GET_ORDER_COMMENTS = 'nucleus/GET_ORDER_COMMENTS';
const GET_ORDER_COMMENTS_SUCCESS = 'nucleus/GET_ORDER_COMMENTS_SUCCESS';
const GET_ORDER_COMMENTS_FAIL = 'nucleus/GET_ORDER_COMMENTS_FAIL';
const GET_COVERAGE_FILE = 'nucleus/GET_COVERAGE_FILE';
const GET_COVERAGE_FILE_SUCCESS = 'nucleus/GET_COVERAGE_FILE_SUCCESS';
const GET_COVERAGE_FILE_FAIL = 'nucleus/GET_COVERAGE_FILE_FAIL';
const GET_MITO_COVERAGE_FILE = 'nucleus/GET_MITO_COVERAGE_FILE';
const GET_MITO_COVERAGE_FILE_SUCCESS = 'nucleus/GET_MITO_COVERAGE_FILE_SUCCESS';
const GET_MITO_COVERAGE_FILE_FAIL = 'nucleus/GET_MITO_COVERAGE_FILE_FAIL';
const ARCHIVE_ORDER = 'nucleus/ARCHIVE_ORDER';
const ARCHIVE_ORDER_SUCCESS = 'nucleus/ARCHIVE_ORDER_SUCCESS';
const ARCHIVE_ORDER_FAIL = 'nucleus/ARCHIVE_ORDER_FAIL';
const GET_PANEL_GENE_LISTS = 'nucleus/GET_PANEL_GENE_LISTS';
const GET_PANEL_GENE_LISTS_SUCCESS = 'nucleus/GET_PANEL_GENE_LISTS_SUCCESS';
const GET_PANEL_GENE_LISTS_FAIL = 'nucleus/GET_PANEL_GENE_LISTS_FAIL';
const GET_PANEL_GENES = 'nucleus/GET_PANEL_GENES';
const GET_PANEL_GENES_SUCCESS = 'nucleus/GET_PANEL_GENES_SUCCESS';
const GET_PANEL_GENES_FAIL = 'nucleus/GET_PANEL_GENES_FAIL';
const GET_PANEL_VERSION_INFORMATION = 'nucleus/GET_PANEL_VERSION_INFORMATION';
const GET_PANEL_VERSION_INFORMATION_SUCCESS = 'nucleus/GET_PANEL_VERSION_INFORMATION_SUCCESS';
const GET_PANEL_VERSION_INFORMATION_FAIL = 'nucleus/GET_PANEL_VERSION_INFORMATION_FAIL';
const RESET_PANEL_VERSION_INFORMATION = 'nucleus/RESET_PANEL_VERSION_INFORMATION';
const GET_PANEL_TIERS = 'nucleus/GET_PANEL_TIERS';
const GET_PANEL_TIERS_SUCCESS = 'nucleus/GET_PANEL_TIERS_SUCCESS';
const GET_PANEL_TIERS_FAIL = 'nucleus/GET_PANEL_TIERS_FAIL';
const CHANGE_NOTIFICATION_ORDER_ID = 'nucleus/CHANGE_NOTIFICATION_ORDER_ID';
const GET_ORDER_OFFSET = 'nucleus/GET_ORDER_OFFSET';
const GET_ORDER_OFFSET_SUCCESS = 'nucleus/GET_ORDER_OFFSET_SUCCESS';
const GET_ORDER_OFFSET_FAIL = 'nucleus/GET_ORDER_OFFSET_FAIL';
const GET_DEPLOY_SCHEDULE = 'nucleus/GET_DEPLOY_SCHEDULE';
const GET_DEPLOY_SCHEDULE_SUCCESS = 'nucleus/GET_DEPLOY_SCHEDULE_SUCCESS';
const GET_DEPLOY_SCHEDULE_FAIL = 'nucleus/GET_DEPLOY_SCHEDULE_FAIL';
const SUBMIT_REFLEX = 'nucleus/SUBMIT_REFLEX';
const SUBMIT_REFLEX_SUCCESS = 'nucleus/SUBMIT_REFLEX_SUCCESS';
const SUBMIT_REFLEX_FAIL = 'nucleus/SUBMIT_REFLEX_FAIL';
const SET_ORDER_INFO = 'nucleus/SET_ORDER_INFO';
const SET_ORDER_ERROR = 'nucleus/SET_ORDER_ERROR';
const SET_CURRENT_PANEL_TIER_ID = 'nucleus/SET_CURRENT_PANEL_TIER_ID';
const SET_CURRENT_GENE_COUNT = 'nucleus/SET_CURRENT_GENE_COUNT';
const SET_COMPONENT_LAYOUT = 'nucleus/SET_COMPONENT_LAYOUT';
const RESET_COMPONENT_LAYOUT = 'nucleus/RESET_COMPONENT_LAYOUT';

const initialState = {
  loaded: false,
  currentStep: 1,
  reachedStep: 1,
  visibleColumns: {},
  orderCount: 0,
  consentPopupModal: {
    showPopupModal: false,
    nextStep: 0,
    modalToHandleStep: false,
    dontShowTilNextRefresh: false,
  },
  componentLoaded: {
    componentName: null,
    componentType: null,
  },
};

export default function orders(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        gettingOrders: true,
        haveOrders: false,
        error: null,
      };
    case GET_ORDERS_SUCCESS: {
      const visibleColumns = action.result.visible_columns.reduce(
        (acc, column) => {
          acc[column] = true;
          return acc;
        },
        {},
      );

      return {
        ...state,
        gettingOrders: false,
        haveOrders: true,
        list: action.result.orders,
        orderCount: action.result.total_count,
        visibleColumns,
        error: null,
      };
    }
    case GET_ORDERS_FAIL:
      return {
        ...state,
        gettingOrders: false,
        haveOrders: false,
        list: null,
        orderCount: 0,
        error: action.error,
      };
    case VIEW_ORDER:
      return {
        ...state,
        haveDeliveryResultInfo: false,
        gettingOrder: true,
        haveOrder: false,
        error: null,
      };
    case VIEW_ORDER_SUCCESS:
      return {
        ...state,
        gettingOrder: false,
        haveOrder: true,
        orderInfo: action.result,
        orderId: action.result.orderId,
        message: null,
        error: null,
      };
    case VIEW_ORDER_FAIL:
      return {
        ...state,
        gettingOrder: false,
        haveOrder: false,
        orderInfo: null,
        orderId: null,
        error: action.error,
        message: '',
      };
    case VIEW_ORDER_DELIVERY_RESULT:
      return {
        ...state,
        gettingDeliveryResultInfo: true,
        haveDeliveryResultInfo: true,
        error: null,
      };
    case VIEW_ORDER_DELIVERY_RESULT_SUCCESS:
      return {
        ...state,
        gettingDeliveryResultInfo: false,
        haveDeliveryResultInfo: true,
        viewOrderDeliveryResult: action.result,
        message: null,
      };
    case VIEW_ORDER_DELIVERY_RESULT_FAIL:
      return {
        ...state,
        gettingDeliveryResultInfo: false,
        haveDeliveryResultInfo: false,
        error: action.error,
        message: 'There was some problem getting the delivery result info',
      };
    case VIEW_VARIANTS:
      return {
        ...state,
        gettingVariants: true,
        haveVariants: true,
        error: null,
      };
    case VIEW_VARIANTS_SUCCESS:
      return {
        ...state,
        gettingVariants: false,
        haveVariants: true,
        variants: action.result,
        message: null,
      };
    case VIEW_VARIANTS_FAIL:
      return {
        ...state,
        gettingVariants: false,
        haveVariants: false,
        error: action.error,
        message: '',
      };

    case VIEW_BILLING_INFO:
      return {
        ...state,
        gettingBillingInfo: true,
        haveBillingInfo: false,
        error: null,
      };
    case VIEW_BILLING_INFO_FAIL:
      return {
        ...state,
        gettingBillingInfo: false,
        haveBillingInfo: false,
        error: action.error,
        message: 'There was a problem with getting billing info',
      };
    case VIEW_BILLING_INFO_SUCCESS:
      return {
        ...state,
        gettingBillingInfo: false,
        haveBillingInfo: true,
        billingInfoDetails: action.result,
        message: null,
        error: null,
      };
    case RESET_CURRENT_STEP:
      return {
        ...state,
        currentStep: 1,
      };
    case CONSENT_POPUP_MODAL:
      return {
        ...state,
        consentPopupModal: { ...state.consentPopupModal, ...action.popupPayload },
      };
    case CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
      };
    case SET_REACHED_STEP:
      return {
        ...state,
        reachedStep: action.step,
      };
    case VIEW_COLLABORATOR_LIST:
      return {
        ...state,
        gettingCollaborators: true,
        haveCollaborators: false,
        error: null,
      };
    case VIEW_COLLABORATOR_LIST_FAIL:
      return {
        ...state,
        gettingCollaborators: false,
        haveCollaborators: false,
        error: action.error,
        message: 'There was a problem getting collaborators',
      };
    case VIEW_COLLABORATOR_LIST_SUCCESS:
      return {
        ...state,
        gettingCollaborators: false,
        haveCollaborators: true,
        collaboratorList: action.result,
        message: null,
      };
    case RESET_ORDER_INFO:
      return {
        ...state,
        currentStep: 1,
        reachedStep: 1,
        order: null,
        orderId: null,
        orderInfo: null,
        viewOrderDeliveryResult: null,
        billingInfoDetails: null,
        collaboratorList: null,
        panelQuestions: null,
        patientInfoQuestions: null,
        confirmationQuestions: null,
        requisitionOrderFiles: null,
        failedToLoadPanelGeneLists: false,
        billingInfoOrderFiles: null,
        orderLogs: {},
        variants: null,
        saveReflexError: null,
        checkReflexError: null,
        panelGeneLists: null,
        havePanelGeneLists: false,
      };

    case RESET_ORDER_REPORT_VALUES:
      return {
        ...state,
        orderInfo: null,
        orderReport: null,
        reportFileObject: null,
        archiveReportFiles: null,
        reportArchiveFile: null,
        panelQa: null,
        reportOrderFiles: null,
        orderFileObject: null,
        coverageFile: null,
        mitoCoverageFile: null,
      };

    case GET_PATIENT_INFO_QUESTIONS:
      return {
        ...state,
        gettingPatientInfoQuestions: true,
        error: null,
      };
    case GET_PATIENT_INFO_QUESTIONS_SUCCESS:
      return {
        ...state,
        gettingPatientInfoQuestions: false,
        patientInfoQuestions: action.result,
      };
    case GET_PATIENT_INFO_QUESTIONS_FAIL:
      return {
        ...state,
        gettingPatientInfoQuestions: false,
        error: action.error,
      };
    case GET_CONFIRMATION_QUESTIONS:
      return {
        ...state,
        gettingConfirmationQuestions: true,
        error: null,
      };
    case GET_CONFIRMATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        gettingConfirmationQuestions: false,
        confirmationQuestions: action.result,
      };
    case GET_CONFIRMATION_QUESTIONS_FAIL:
      return {
        ...state,
        gettingConfirmationQuestions: false,
        error: action.error,
      };
    case CHECK_ORDER:
      return {
        ...state,
        checking: true,
        checked: false,
        error: null,
      };
    case CHECK_ORDER_SUCCESS:
      return {
        ...state,
        checking: false,
        checked: true,
        orderInfo: {
          ...state.orderInfo,
          is_checked: true,
        },
      };
    case CHECK_ORDER_FAIL:
      return {
        ...state,
        checking: false,
        checked: false,
        error: action.error,
      };
    case LIST_BILLING_INFO_ORDER_FILES:
      return {
        ...state,
        gettingBillingInfoOrderFilesList: true,
        gotBillingInfoOrderFile: false,
        error: null,
      };
    case LIST_BILLING_INFO_ORDER_FILES_SUCCESS:
      return {
        ...state,
        gettingBillingInfoOrderFilesList: false,
        gotBillingInfoOrderFile: true,
        billingInfoOrderFiles: action.result,
      };
    case LIST_BILLING_INFO_ORDER_FILES_FAIL:
      return {
        ...state,
        gettingBillingInfoOrderFilesList: false,
        gotBillingInfoOrderFile: false,
        error: action.error,
      };
    case LIST_REPORT_ORDER_FILES:
      return {
        ...state,
        gettingReportOrderFilesList: true,
        gotReportOrderFile: false,
        error: null,
      };
    case LIST_REPORT_ORDER_FILES_SUCCESS:
      return {
        ...state,
        gettingReportOrderFilesList: false,
        gotReportOrderFile: true,
        reportOrderFiles: action.result,
      };
    case LIST_REPORT_ORDER_FILES_FAIL:
      return {
        ...state,
        gettingReportOrderFilesList: false,
        gotReportOrderFile: false,
        error: action.error,
      };
    case LIST_REQUISITION_ORDER_FILES:
      return {
        ...state,
        gettingRequisitionOrderFilesList: true,
        gotRequisitionOrderFile: false,
        error: null,
      };
    case LIST_REQUISITION_ORDER_FILES_SUCCESS:
      return {
        ...state,
        gettingRequisitionOrderFilesList: false,
        gotRequisitionOrderFile: true,
        requisitionOrderFiles: action.result,
      };
    case LIST_REQUISITION_ORDER_FILES_FAIL:
      return {
        ...state,
        gettingRequisitionOrderFilesList: false,
        gotRequisitionOrderFile: false,
        error: action.error,
      };
    case GET_REPORT:
      return {
        ...state,
        gettingReport: true,
        error: null,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        gettingReport: false,
        orderReport: action.result,
      };
    case GET_REPORT_FAIL:
      return {
        ...state,
        gettingReport: false,
        error: action.error,
      };
    case LIST_REPORT_ARCHIVE_FILES:
      return {
        ...state,
        gettingReportArchiveFiles: true,
        error: null,
      };
    case LIST_REPORT_ARCHIVE_FILES_SUCCESS:
      return {
        ...state,
        gettingReportArchiveFiles: false,
        archiveReportFiles: action.result,
      };
    case LIST_REPORT_ARCHIVE_FILES_FAIL:
      return {
        ...state,
        gettingReportArchiveFiles: false,
        error: action.error,
      };
    case SAVE_ORDER_COMMENTS:
      return {
        ...state,
        savingComment: true,
        error: null,
      };
    case SAVE_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        savingComment: false,
        savedComment: true,
      };
    case SAVE_ORDER_COMMENTS_FAIL:
      return {
        ...state,
        savingComment: false,
        savedComment: false,
        error: action.error,
      };
    case GET_ORDER_FILE:
      return {
        ...state,
        gettingOrderFile: true,
        error: null,
      };
    case GET_ORDER_FILE_SUCCESS:
      return {
        ...state,
        gettingOrderFile: false,
        orderFileObject: action.result,
      };
    case GET_ORDER_FILE_FAIL:
      return {
        ...state,
        gettingOrderFile: false,
        error: action.error,
      };
    case GET_REPORT_FILE:
      return {
        ...state,
        gettingReportFile: true,
        error: null,
      };
    case GET_REPORT_FILE_SUCCESS:
      return {
        ...state,
        gettingReportFile: false,
        reportFileObject: action.result,
      };
    case GET_REPORT_FILE_FAIL:
      return {
        ...state,
        gettingReportFile: false,
        error: action.error,
      };
    case GET_REPORT_ARCHIVE_FILE:
      return {
        ...state,
        gettingReportArchiveFile: true,
        error: null,
      };
    case GET_REPORT_ARCHIVE_FILE_SUCCESS:
      return {
        ...state,
        gettingReportArchiveFile: false,
        reportArchiveFile: action.result,
      };
    case GET_REPORT_ARCHIVE_FILE_FAIL:
      return {
        ...state,
        gettingReportArchiveFile: false,
        error: action.error,
      };
    case GET_ORDER_SUMMARY_FILE:
      return {
        ...state,
        gettingOrderSummaryFile: true,
        haveOrderSummaryFile: false,
        error: null,
      };
    case GET_ORDER_SUMMARY_FILE_SUCCESS:
      return {
        ...state,
        gettingOrderSummaryFile: false,
        haveOrderSummaryFile: true,
        orderSummaryFileObject: action.result,
      };
    case GET_ORDER_SUMMARY_FILE_FAIL:
      return {
        ...state,
        gettingOrderSummaryFile: false,
        haveOrderSummaryFile: false,
        error: action.error,
      };
    case GET_PANEL_QA:
      return {
        ...state,
        gettingPanelQa: true,
        receivedPanelQa: false,
        error: null,
      };
    case GET_PANEL_QA_SUCCESS:
      return {
        ...state,
        gettingPanelQa: false,
        receivedPanelQa: true,
        panelQa: action.result,
      };
    case GET_PANEL_QA_FAIL:
      return {
        ...state,
        gettingPanelQa: false,
        receivedPanelQa: false,
        error: action.error,
      };
    case GET_ORDER_COMMENTS:
      return {
        ...state,
        gettingComments: true,
        receivedComments: false,
        error: null,
      };
    case GET_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        gettingComments: false,
        receivedComments: true,
        comments: action.result,
      };
    case GET_ORDER_COMMENTS_FAIL:
      return {
        ...state,
        gettingComments: false,
        receivedComments: false,
        error: action.error,
      };
    case GET_COVERAGE_FILE:
      return {
        ...state,
        gettingCoverageFile: true,
        receiveCoverageFile: false,
        error: null,
      };
    case GET_COVERAGE_FILE_SUCCESS:
      return {
        ...state,
        gettingCoverageFile: true,
        receiveCoverageFile: false,
        coverageFile: action.result,
      };
    case GET_COVERAGE_FILE_FAIL:
      return {
        ...state,
        gettingCoverageFile: true,
        receiveCoverageFile: false,
        error: action.error,
      };
    case GET_MITO_COVERAGE_FILE:
      return {
        ...state,
        gettingMitoCoverageFile: true,
        receiveMitoCoverageFile: false,
        error: null,
      };
    case GET_MITO_COVERAGE_FILE_SUCCESS:
      return {
        ...state,
        gettingMitoCoverageFile: true,
        receiveMitoCoverageFile: false,
        mitoCoverageFile: action.result,
      };
    case GET_MITO_COVERAGE_FILE_FAIL:
      return {
        ...state,
        gettingMitoCoverageFile: true,
        receiveMitoCoverageFile: false,
        error: action.error,
      };
    case ARCHIVE_ORDER:
      return {
        ...state,
        archiving: true,
        archived: false,
        error: null,
      };
    case ARCHIVE_ORDER_SUCCESS:
      return {
        ...state,
        archiving: false,
        archived: true,
      };
    case ARCHIVE_ORDER_FAIL:
      return {
        ...state,
        archiving: false,
        archived: false,
        error: action.error,
      };
    case GET_PANEL_GENE_LISTS:
      return {
        ...state,
        gettingPanelGeneLists: true,
        havePanelGeneLists: false,
        panelGeneLists: null,
        failedToLoadPanelGeneLists: false,
        error: null,
      };
    case GET_PANEL_GENE_LISTS_SUCCESS:
      return {
        ...state,
        gettingPanelGeneLists: false,
        havePanelGeneLists: true,
        panelGeneLists: action.result,
      };
    case GET_PANEL_GENE_LISTS_FAIL:
      return {
        ...state,
        gettingPanelGeneLists: false,
        havePanelGeneLists: false,
        failedToLoadPanelGeneLists: true,
        error: action.error,
      };
    case GET_PANEL_VERSION_INFORMATION_SUCCESS:
      return {
        ...state,
        panelVersionInformation: action.result,
      };
    case GET_PANEL_VERSION_INFORMATION_FAIL:
      return {
        ...state,
        panelVersionInformation: null,
      };
    case RESET_PANEL_VERSION_INFORMATION:
      return {
        ...state,
        panelVersionInformation: null,
      };
    case GET_PANEL_TIERS_SUCCESS:
      return {
        ...state,
        panelTiers: action.result.panelTiers,
      };
    case CHANGE_NOTIFICATION_ORDER_ID:
      return {
        ...state,
        notificationOrderId: action.notificationOrderId,
      };
    case GET_ORDER_OFFSET:
      return {
        ...state,
        gettingOrderOffset: true,
        haveOrderOffset: false,
        orderOffset: null,
        error: null,
      };
    case GET_ORDER_OFFSET_SUCCESS:
      return {
        ...state,
        gettingOrderOffset: false,
        haveOrderOffset: true,
        orderOffset: action.result,
      };
    case GET_ORDER_OFFSET_FAIL:
      return {
        ...state,
        gettingOrderOffset: false,
        haveOrderOffset: false,
        error: action.error,
      };
    case GET_DEPLOY_SCHEDULE:
      return {
        ...state,
        gettingDeploySchedule: true,
        haveDeploySchedule: false,
        getDeployScheduleError: null,
      };
    case GET_DEPLOY_SCHEDULE_SUCCESS:
      return {
        ...state,
        gettingDeploySchedule: false,
        haveDeploySchedule: true,
        deploySchedule: action.result,
      };
    case GET_DEPLOY_SCHEDULE_FAIL:
      return {
        ...state,
        gettingDeploySchedule: false,
        haveDeploySchedule: false,
        getDeployScheduleError: action.error,
        deploySchedule: null,
      };
    case SUBMIT_REFLEX:
      return {
        ...state,
        reflexError: null,
        reflexResult: null,
        submittingReflex: true,
      };
    case SUBMIT_REFLEX_SUCCESS:
      return {
        ...state,
        reflexResult: action.result,
        submittingReflex: false,
      };
    case SUBMIT_REFLEX_FAIL:
      return {
        ...state,
        reflexError: action.error,
        submittingReflex: false,
      };
    case SET_ORDER_INFO:
      return {
        ...state,
        orderInfo: action.result,
      };
    case SET_ORDER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case SET_CURRENT_PANEL_TIER_ID:
      return {
        ...state,
        currentPanelTierId: action.panel_tier_id,
      };
    case SET_CURRENT_GENE_COUNT:
      return {
        ...state,
        currentGeneCount: action.gene_count,
      };
    case SET_COMPONENT_LAYOUT:
      return {
        ...state,
        componentLoaded: action.result,
      };
    case RESET_COMPONENT_LAYOUT:
      return {
        ...state,
        componentLoaded: {
          componentName: null,
          componentType: null,
        },
      };
    default:
      return state;
  }
}

function setOrderError(error) {
  return {
    type: SET_ORDER_ERROR,
    error,
  };
}

export function setCurrentPanelTierId(panelTierId) {
  return {
    type: SET_CURRENT_PANEL_TIER_ID,
    panel_tier_id: panelTierId,
  };
}

export function setCurrentGeneCount(geneCount) {
  return {
    type: SET_CURRENT_GENE_COUNT,
    gene_count: geneCount,
  };
}

// the relevant parameters for new order are
// panel_id (number) - ID of the test panel (product)
// alias_user_id (number or null) - Alias user ID
// is_customized (boolean, optional) - Defines whether the panel is base or to be customized
// gene_collection_id (number, optional) - If the panel is saved flex panel
// panel_tier_id (number, optional) - Defines the pricing of the panel
export function newOrder(data) {
  return {
    request: (client) => client.post('create_order', data),
  };
}

export function resetOrderReportValues() {
  return { type: RESET_ORDER_REPORT_VALUES };
}

export function saveOrder(orderId, data) {
  return {
    request: (client) => client.post('save_order', {
      order_id: orderId,
      ...data,
    }),
  };
}

export function savePHIFreeSummary(orderId, phiFreeSummary) {
  return {
    request: (client) => client.post('save_order_phifree_summary', {
      order_id: orderId,
      phi_free_summary: phiFreeSummary,
    }),
  };
}

export function saveInternalComments(orderId, comments) {
  return {
    request: (client) => client.post('save_internal_comments', {
      order_id: orderId,
      internal_comments: comments,
    }),
  };
}

// params for getting orders
// type (string,optional) - Order type, specify “shared” to only retrieve shared orders
// sort (string,optional) - Sort order (DESC/ASC?)
// search_str (string,optional) - Search string
export function getOrders(data) {
  return {
    types: [GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_FAIL],
    request: (client) => client.post('orders', data),
  };
}

export function viewOrderInfo(orderId, orderKitInfo = true) {
  return {
    types: [VIEW_ORDER, VIEW_ORDER_SUCCESS, VIEW_ORDER_FAIL],
    request: (client) => client.get('get_order', {
      order_id: orderId,
      get_order_kit_info: orderKitInfo,
      get_custom_panel_info: true,
    }),
  };
}

export function resetOrderInfo() {
  return { type: RESET_ORDER_INFO };
}

export function getOrder(
  orderId,
  query = {
    get_order_kit_info: false,
    get_custom_panel_info: false,
    show_transfer: false,
  },
) {
  return {
    request: (client, dispatch) => client
      .get('get_order', {
        order_id: orderId,
        ...query,
      })
      .then((result) => {
        dispatch({
          type: SET_ORDER_INFO,
          result,
        });
        return result;
      }),
  };
}

export function getIndexOrder(orderId) {
  return {
    request: (client) => client.get(`get_index_order/${orderId}`),
  };
}

export function viewOrderDeliveryResult(data) {
  return {
    types: [
      VIEW_ORDER_DELIVERY_RESULT,
      VIEW_ORDER_DELIVERY_RESULT_SUCCESS,
      VIEW_ORDER_DELIVERY_RESULT_FAIL,
    ],
    request: (client) => client.get(`get_results_delivery_info/${data.order_id}`),
  };
}

export function getResultsDeliveryInfo(orderId) {
  return {
    request: (client) => client.get(`get_results_delivery_info/${orderId}`),
  };
}

export function viewVariants(data) {
  return {
    types: [VIEW_VARIANTS, VIEW_VARIANTS_SUCCESS, VIEW_VARIANTS_FAIL],
    request: (client) => client.get(`get_targeted_variants/${data.order_id}`),
  };
}

export function getTargetedVariants(orderId) {
  return {
    request: (client) => client.get(`get_targeted_variants/${orderId}`),
  };
}

export function billingInfo(data) {
  return {
    types: [
      VIEW_BILLING_INFO,
      VIEW_BILLING_INFO_SUCCESS,
      VIEW_BILLING_INFO_FAIL,
    ],
    request: (client) => client.get(`get_billing_info/${data.order_id}`),
  };
}

export function getBillingInfo(orderId) {
  return {
    request: (client) => client.get(`get_billing_info/${orderId}`),
  };
}

export function getBillingMethods(productId) {
  return {
    request: (client) => client.get(`get_billing_methods/${productId}`),
  };
}

export function resetCurrentStep() {
  return { type: RESET_CURRENT_STEP };
}

export function changeStep(step) {
  return {
    type: CHANGE_CURRENT_STEP,
    step,
  };
}

export function consentPopupModal(popupPayload) {
  return {
    type: CONSENT_POPUP_MODAL,
    popupPayload,
  };
}

export function getComponentLayout(
  orderId,
  componentType,
) {
  return {
    request: (client, dispatch) => client
      .get('get_component_layout', {
        order_id: orderId,
        component_type: componentType,
      })
      .then((result) => {
        dispatch({
          type: SET_COMPONENT_LAYOUT,
          result,
        });
        return result;
      }),
  };
}

export function resetComponentLayout() {
  return { type: RESET_COMPONENT_LAYOUT };
}

export function setReachedStep(step) {
  return {
    type: SET_REACHED_STEP,
    step,
  };
}

export function viewCollaboratorList(data) {
  return {
    types: [
      VIEW_COLLABORATOR_LIST,
      VIEW_COLLABORATOR_LIST_SUCCESS,
      VIEW_COLLABORATOR_LIST_FAIL,
    ],
    request: (client) => client.get(`get_order_shares/${data.order_id}`),
  };
}

function makeCollaboratorList(res) {
  return Object.entries(res)
    .map(([userId, value]) => ({
      user_id: parseInt(userId, 10),
      ...value,
    }));
}
export function getOrderCollaborators(orderId) {
  return {
    request: (client) => client.get(`get_order_shares/${orderId}`).then(makeCollaboratorList),
  };
}

export function saveReflex(orderId, reflexOrderId) {
  return {
    request: (client) => client.post('save_reflex', {
      order_id: orderId,
      reflex_order_id: reflexOrderId,
    }),
  };
}

export function saveFmtInfo(orderId, data) {
  return {
    request: (client) => client.post('save_fmt_info', {
      order_id: orderId,
      ...data,
    }),
  };
}

export function saveBillingInfo(data) {
  return {
    request: (client) => client.post('save_billing_info', data),
  };
}

export function saveResultsDeliveryInfo(orderId, deliveryInfo) {
  return {
    request: (client) => client.post('save_results_delivery_info', {
      order_id: orderId,
      ...deliveryInfo,
    }),
  };
}

export function saveHardCodedAkouosAnswers(orderID, answers) {
  return {
    request: (client) => client.post('save_akouos_answers', {
      order_id: orderID,
      ...answers,
    }),
  };
}

export function saveTargetedVariants(orderId, variants) {
  return {
    request: (client) => client.post('save_targeted_variants', {
      order_id: orderId,
      variants,
    }),
  };
}

export function getPanelQuestions(orderId, pageNumber = 0) {
  return {
    request: (client) => client.post('get_panel_questions', {
      order_id: orderId,
      page_no: pageNumber,
    }),
  };
}

export function getPdfForProduct(panelId) {
  const path = ['get_pdf_for_product', panelId].join('/');
  return {
    request: (client) => client.get(path).then((file) => {
      downloadFileFunc(file);
    }),
  };
}

/**
 * params for getting patient info questions
 * panel_id (number) - ID of the panel
 * order_id (number) - ID of an order. Will return panel questions and answers and
 *                     overrides panel_id if both are specified.
 * page_no(number) - 1 for patient info
 */
export function getPatientInfoQuestions(data) {
  return {
    types: [
      GET_PATIENT_INFO_QUESTIONS,
      GET_PATIENT_INFO_QUESTIONS_SUCCESS,
      GET_PATIENT_INFO_QUESTIONS_FAIL,
    ],
    request: (client) => client.post('get_panel_questions', {
      ...data,
      page_no: 1,
    }),
  };
}

/**
 * panel_id (number) - ID of the panel
 * params for getting patient info questions
 * order_id (number) - ID of an order. Will return panel questions
 *                     and answers and overrides panel_id if both are specified.
 * page_no(number)   - 1 for patient info
 */
export function getConfirmationQuestions(data) {
  return {
    types: [
      GET_CONFIRMATION_QUESTIONS,
      GET_CONFIRMATION_QUESTIONS_SUCCESS,
      GET_CONFIRMATION_QUESTIONS_FAIL,
    ],
    request: (client) => client.post('get_panel_questions', {
      ...data,
      page_no: 2,
    }),
  };
}

export function deleteOrder(orderId) {
  return {
    request: (client, dispatch) => {
      dispatch(setOrderError(null));
      dispatch(setCurrentPanelTierId(null));
      dispatch(setCurrentGeneCount(null));

      return client
        .post('delete_order', {
          order_id: orderId,
        })
        .catch((e) => {
          dispatch(setOrderError(e.message));
          throw e;
        });
    },
  };
}

export function deleteOrderById(orderId) {
  return {
    request: (client) => client.post('delete_order', {
      order_id: orderId,
    }),
  };
}

export function finishOrder(orderId) {
  return {
    request: (client) => client.post('finish_order', {
      order_id: orderId,
    }),
  };
}

export function checkOrder(orderId) {
  return {
    request: (client) => client.post('check_order', {
      order_id: orderId,
    }),
  };
}

export function shareOrder(orderId, userIds) {
  return {
    request: (client) => client.post('share_order', {
      order_id: orderId,
      user_ids: userIds,
    }).then(makeCollaboratorList),
  };
}

function listOrderFilesRequest(data) {
  const path = ['list_order_files', data.order_id, data.type].join('/');
  return (client) => client.get(path);
}

// params for listing files attached to order
// order_id (number) - ID of order to share
// type (string, optional) - File type (billing_attachment)
export function listBillingInfoOrderFiles(data) {
  return {
    types: [
      LIST_BILLING_INFO_ORDER_FILES,
      LIST_BILLING_INFO_ORDER_FILES_SUCCESS,
      LIST_BILLING_INFO_ORDER_FILES_FAIL,
    ],
    request: listOrderFilesRequest(data),
  };
}

// params for listing files attached to order
// order_id (number) - ID of order to share
// type (string, optional) - File type (report_attachment)
export function listReportOrderFiles(data) {
  return {
    types: [
      LIST_REPORT_ORDER_FILES,
      LIST_REPORT_ORDER_FILES_SUCCESS,
      LIST_REPORT_ORDER_FILES_FAIL,
    ],
    request: listOrderFilesRequest(data),
  };
}

// params for listing files attached to order
// order_id (number) - ID of order to share
// type (string, optional) - File type (requisition_attachment)
export function listRequisitionOrderFiles(data) {
  return {
    types: [
      LIST_REQUISITION_ORDER_FILES,
      LIST_REQUISITION_ORDER_FILES_SUCCESS,
      LIST_REQUISITION_ORDER_FILES_FAIL,
    ],
    request: listOrderFilesRequest(data),
  };
}

export function savePanelAnswers(orderId, answers, pageNumber = 0) {
  return {
    request: (client) => client.post('save_panel_answers', {
      order_id: orderId,
      answers,
      page_no: pageNumber,
    }),
  };
}

// params for getting report
// order_id (number) - ID of order to share
export function getReport(data) {
  return {
    types: [GET_REPORT, GET_REPORT_SUCCESS, GET_REPORT_FAIL],
    request: (client) => client.get(`get_report/${data.order_id}`),
  };
}

// params for listing report archive files
// order_id (number) - ID of order to share
export function listReportArchiveFiles(data) {
  return {
    types: [
      LIST_REPORT_ARCHIVE_FILES,
      LIST_REPORT_ARCHIVE_FILES_SUCCESS,
      LIST_REPORT_ARCHIVE_FILES_FAIL,
    ],
    request: (client) => client.get(`list_report_archive_files/${data.order_id}`),
  };
}

// params for saving order comments
// order_id (number) - ID of order to share
// comments_txt (string) - Text body of comment
export function saveOrderComments(data) {
  return {
    types: [
      SAVE_ORDER_COMMENTS,
      SAVE_ORDER_COMMENTS_SUCCESS,
      SAVE_ORDER_COMMENTS_FAIL,
    ],
    request: (client) => client.post('save_order_comments', data),
  };
}

// example response for getting consent languages
// {
//   "en": "English",
//   "es": "Spanish",
//   "fi": "Finnish",
//   "fr": "French",
//   "no": "Norwegian",
//   "sv": "Swedish"
// }
export function getConsentLanguages(productId) {
  return {
    request: (client) => client.get(`get_consent_languages/${productId}`).then((result) => result),
  };
}

// params for getting order file.
// order_id (number) - ID of order to share
// file_id (number) - ID of file
// Result of the call:
// {
//   "file_name": "test",
//   "mime_type": "ASCII",
//   "data": "sdafasc="
// }
export function getOrderFile(data) {
  const path = ['get_order_file', data.order_id, data.file_id].join('/');
  return {
    types: [GET_ORDER_FILE, GET_ORDER_FILE_SUCCESS, GET_ORDER_FILE_FAIL],
    request: (client) => client.get(path),
  };
}

export function downloadOrderFile(orderId, fileId) {
  const path = ['get_order_file', orderId, fileId].join('/');
  return {
    request: (client) => client.get(path).then((file) => {
      downloadFileFunc(file);
    }),
  };
}

export function getOrderLogs(orderId) {
  return {
    request: (client) => client.post('get_order_logs', {
      order_id: orderId,
    }),
  };
}
export function getCoverageFile(data) {
  const path = ['get_order_file', data.order_id, data.file_id].join('/');
  return {
    types: [
      GET_COVERAGE_FILE,
      GET_COVERAGE_FILE_SUCCESS,
      GET_COVERAGE_FILE_FAIL,
    ],
    request: (client) => client.get(path),
  };
}

export function getMitoCoverageFile(data) {
  const path = ['get_order_file', data.order_id, data.file_id].join('/');
  return {
    types: [
      GET_MITO_COVERAGE_FILE,
      GET_MITO_COVERAGE_FILE_SUCCESS,
      GET_MITO_COVERAGE_FILE_FAIL,
    ],
    request: (client) => client.get(path),
  };
}

export function downloadConsentFile(orderId, lang, format = 'pdf') {
  const path = ['get_consent_file', orderId, lang, format].join('/');
  return {
    request: (client) => client.get(path).then((file) => {
      downloadFileFunc(file);
    }),
  };
}

// params for getting report file
// order_id (number) - ID of order to share
// format (string) - File format (e.g. pdf)
export function getReportFile(
  orderId,
  label = 'index_patient',
  format = 'pdf',
) {
  return {
    types: [GET_REPORT_FILE, GET_REPORT_FILE_SUCCESS, GET_REPORT_FILE_FAIL],
    request: (client) => client.get(`get_report_file/${orderId}/${label}/${format}`),
  };
}

// params for getting order summary file
// order_id (number) - ID of order to share
// format (string) - File format (e.g. pdf)
export function getOrderSummaryFile(data) {
  const path = ['get_order_summary_file', data.order_id, data.format].join('/');
  return {
    types: [
      GET_ORDER_SUMMARY_FILE,
      GET_ORDER_SUMMARY_FILE_SUCCESS,
      GET_ORDER_SUMMARY_FILE_FAIL,
    ],
    request: (client) => client.get(path),
  };
}

// same as above but without types
// needs both not to break anything using the old way
export function downloadOrderSummaryFile(data) {
  const path = ['get_order_summary_file', data.order_id, data.format].join('/');
  return {
    request: (client) => client.get(path).then((file) => downloadFileFunc(file)),
  };
}

// params for getting archive report file
// order_id (number) - ID of order to share
// archive_id (number) - ID of archive
// format (string) - File format (e.g. pdf)
export function getReportArchiveFile(data) {
  const path = [
    'get_report_archive_file',
    data.order_id,
    data.archive_id,
    data.format,
  ].join('/');
  return {
    types: [
      GET_REPORT_ARCHIVE_FILE,
      GET_REPORT_ARCHIVE_FILE_SUCCESS,
      GET_REPORT_ARCHIVE_FILE_FAIL,
    ],
    request: (client) => client.get(path),
  };
}

// params for getting panel qa
// order_id (number) - ID of the order to share
export function getPanelQa(data) {
  return {
    types: [GET_PANEL_QA, GET_PANEL_QA_SUCCESS, GET_PANEL_QA_FAIL],
    request: (client) => client.post('get_panel_qa', data),
  };
}

export function getOrderComments(data) {
  return {
    types: [
      GET_ORDER_COMMENTS,
      GET_ORDER_COMMENTS_SUCCESS,
      GET_ORDER_COMMENTS_FAIL,
    ],
    request: (client) => client.get(`get_order_comments/${data.order_id}`),
  };
}

// params for getting panel qa
// order_id (number) - ID of the order to archive
export function archiveOrder(data) {
  return {
    types: [ARCHIVE_ORDER, ARCHIVE_ORDER_SUCCESS, ARCHIVE_ORDER_FAIL],
    request: (client) => client.post('archive_order', data),
  };
}

export function getPanelGeneLists(panelId) {
  return {
    types: [
      GET_PANEL_GENE_LISTS,
      GET_PANEL_GENE_LISTS_SUCCESS,
      GET_PANEL_GENE_LISTS_FAIL,
    ],
    request: (client) => client.post('get_panel_gene_lists', {
      panel_id: panelId,
    }),
  };
}

export function fetchPanelGenes({ panelId, gene }) {
  return {
    types: [GET_PANEL_GENES, GET_PANEL_GENES_SUCCESS, GET_PANEL_GENES_FAIL],
    request: (client) => client.get('panel_genes', {
      panel_id: panelId,
      gene,
    }),
  };
}

export function changeNotificationOrderId(orderId) {
  return {
    type: CHANGE_NOTIFICATION_ORDER_ID,
    notificationOrderId: orderId,
  };
}

export function getDeploySchedule() {
  return {
    types: [
      GET_DEPLOY_SCHEDULE,
      GET_DEPLOY_SCHEDULE_SUCCESS,
      GET_DEPLOY_SCHEDULE_FAIL,
    ],
    /* eslint-disable-next-line no-undef */
    request: (client) => client.getRemoteJSON(process.env.REACT_APP_MAINTENANCE_URL || '/maintenance.json'),
  };
}

export function submitReflex(data) {
  return {
    types: [SUBMIT_REFLEX, SUBMIT_REFLEX_SUCCESS, SUBMIT_REFLEX_FAIL],
    request: (client) => client.post('create_reflex_order', data),
  };
}

export function performOperation(sourceOrderId, productOperationId, aliasUserId) {
  const isAliasUserIdNumber = !Number.isNaN(parseInt(aliasUserId, 10));

  return {
    request: (client) => client.post('perform_operation', {
      source_order_id: sourceOrderId,
      product_operation_id: productOperationId,
      ...(isAliasUserIdNumber && { alias_user_id: aliasUserId }),
    }),
  };
}

export function getPanels() {
  return {
    request: (client) => client.get('get_panels'),
  };
}

export function getPanelTiers() {
  return {
    types: [
      GET_PANEL_TIERS,
      GET_PANEL_TIERS_SUCCESS,
      GET_PANEL_TIERS_FAIL,
    ],
    request: (client) => client.post('get_panel_tiers'),
  };
}

export function getPanelVersionInformation(panelId) {
  let parsedPanelId = panelId;

  if (typeof panelId !== 'number') {
    parsedPanelId = parseInt(panelId, 10);
  }

  return {
    types: [
      GET_PANEL_VERSION_INFORMATION,
      GET_PANEL_VERSION_INFORMATION_SUCCESS,
      GET_PANEL_VERSION_INFORMATION_FAIL,
    ],
    request: (client) => client.post('get_panel_version_information', {
      panel_id: parsedPanelId,
    }),
  };
}

export function resetPanelVersionInformation() {
  return { type: RESET_PANEL_VERSION_INFORMATION };
}

// params for upgrading a gene collection (needed when the underlying product gets deprecated)
// gene_collection_id (number) - ID of the gene collection
export function upgradeGeneCollection(geneCollectionId) {
  return {
    request: (client) => client.post('upgrade_gene_collection', {
      gene_collection_id: geneCollectionId,
    }),
  };
}

// params for deleting a gene collection
// gene_collection_id (number) - ID of the gene collection
export function deleteGeneCollection(geneCollectionId) {
  return {
    request: (client) => client.post('delete_gene_collection', {
      gene_collection_id: geneCollectionId,
    }),
  };
}

/**
 * Sends an email request to add genes
 * @param {string} requestedGenes
 */
export function sendGeneRequest(requestedGenes) {
  return {
    request: (client) => client.post('send_gene_request', {
      requested_genes: requestedGenes,
    }),
  };
}

const ordersSelector = (state) => state.orders;
export const orderInfoSelector = (state) => ordersSelector(state).orderInfo;
export const orderIdSelector = (state) => orderInfoSelector(state).order_id;
export const variantsSelector = (state) => state.orders.variants;
export const panelTiersSelector = (state) => state.orders.panelTiers;
export const viewOrderDeliveryResultSelector = (state) => state.orders.viewOrderDeliveryResult;
export const confirmationQuestionsSelector = (state) => state.orders.confirmationQuestions;
export const currentGeneCountSelector = (state) => state.orders.currentGeneCount;
export const currentStepSelector = (state) => ordersSelector(state).currentStep;
export const reachedStepSelector = (state) => ordersSelector(state).reachedStep;
export const failedToLoadPanelGeneListsSelector = (state) => (
  ordersSelector(state).failedToLoadPanelGeneLists
);
export const havePanelGeneListsSelector = (state) => ordersSelector(state).havePanelGeneLists;
export const panelGeneListsSelector = (state) => ordersSelector(state).panelGeneLists;
export const orderCountSelector = (state) => ordersSelector(state).orderCount;
export const gettingOrdersSelector = (state) => ordersSelector(state).gettingOrders;
export const visibleColumnsSelector = (state) => ordersSelector(state).visibleColumns;
export const gettingReportFileSelector = (state) => ordersSelector(state).gettingReportFile;
export const archivingSelector = (state) => ordersSelector(state).archiving;
export const ordersListSelector = (state) => ordersSelector(state).list;
export const consentPopupModalSelector = (state) => ordersSelector(state).consentPopupModal;
