import React, { useState } from 'react';
import BaseTable, { Column } from 'react-base-table';
import { useHistory } from 'react-router';
import debounce from 'lodash/debounce';
import Helmet from 'react-helmet';

import { getQuestions } from 'redux/modules/support';
import { AdminHeading } from 'admin/components';
import Spinner from 'components/Spinner/Spinner';
import useData from 'hooks/useData';

import styles from './Questions.scss';
import EditLinkCell from './components/EditLinkCell';

const fieldContains = (question, fieldName, searchString) => {
  if (question[fieldName]) {
    return question[fieldName].toLowerCase().includes(searchString);
  }
  return false;
};

const getFilteredQuestions = (data, error, searchString) => {
  if (!error) {
    const questions = data.questions;
    const toSearch = searchString.toLowerCase();

    if (!questions || !toSearch) {
      return questions;
    }

    return questions.filter((question) => (
      fieldContains(question, 'question', toSearch)
          || fieldContains(question, 'free_text_option_question', toSearch)
          || fieldContains(question, 'hint', toSearch)
    ));
  }
  return [];
};

const Questions = () => {
  const [searchString, setSearchString] = useState('');

  const changeSearchString = debounce((string) => {
    setSearchString(string.toLowerCase());
  }, 200);

  const { data, isLoading, error } = useData(getQuestions);

  const title = 'Questions';

  const renderBody = () => {
    const questionData = getFilteredQuestions(data, error, searchString);

    if (questionData.length === 0) {
      return (
        <div className={styles.search_error}>
          <p>No questions matching the search criteria were found.</p>
        </div>
      );
    }

    return (
      <BaseTable
        fixed
        data={questionData}
        width={1260}
        estimatedRowHeight={100}
        height={window.innerHeight - 50}
        className={styles.table}
      >
        <Column
          title="Question"
          key="question"
          dataKey="question"
          width={280}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          title="Type"
          key="type"
          dataKey="type"
          width={120}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          title="Unit"
          key="unit"
          dataKey="unit"
          width={120}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          title="Free text option question"
          key="free_text_option_question"
          dataKey="free_text_option_question"
          width={220}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          title="Hint"
          key="hint"
          dataKey="hint"
          width={320}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          title="Option count"
          key="patient_history_option_count"
          dataKey="patient_history_option_count"
          width={130}
          align="center"
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={EditLinkCell}
          key="question_id"
          dataKey="question_id"
          width={55}
          align="left"
        />
      </BaseTable>
    );
  };

  const { push } = useHistory();

  const props = {
    labelText: '+\xa0 Add question',
    title: 'Questions',
    buttonFunc: () => push('/questions/new'),
    searchFunc: (event) => changeSearchString(event.target.value),
    placeholder: 'Find a question',
  };

  return (
    <div className={`${styles.questions} container`}>
      <Helmet title={title} />
      <div className={styles.heading}>
        <AdminHeading {...props} />
      </div>
      <div className="col-lg-12">
        {isLoading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <div>{renderBody()}</div>
        )}
      </div>
    </div>
  );
};

export default Questions;
