import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import { isInternalUserSelector, isSupportUserSelector } from 'redux/modules/auth';
import * as orderActions from 'redux/modules/orders';
import Spinner from 'components/Spinner/Spinner';
import { getSteps } from 'components/_CreateOrderProcess/EditOrderStep';
import { fullPanelName } from 'utils/forms';
import { getPanelTierNumber } from 'utils/panelTiers';
import { NEI } from 'helpers/constants/specialTypeConstants';
import OrderTestModalHeader from './OrderTestModalHeader';
import OrderTestModalBody from './OrderTestModalBody';
import { OrderTestModalStaticProps, OrderTestModalDefaultProps } from './OrderTestModalConstant';
import { getPricingModelForOrder } from '../../utils/orderInfo';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderInfo: state.orders.orderInfo,
  currentStep: state.orders.currentStep,
  error: state.orders.error,
  panelTiers: state.orders.panelTiers,
  currentPanelTierId: state.orders.currentPanelTierId,
  currentGeneCount: state.orders.currentGeneCount,
  isInternalUser: isInternalUserSelector(state),
  isSupportUser: isSupportUserSelector(state),
  panelVersionInformation: state.orders.panelVersionInformation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getPanelGeneLists: orderActions.getPanelGeneLists,
  getPanelTiers: orderActions.getPanelTiers,
  resetOrderInfo: orderActions.resetOrderInfo,
  setCurrentPanelTierId: orderActions.setCurrentPanelTierId,
  setCurrentGeneCount: orderActions.setCurrentGeneCount,
  getPanelVersionInformation: orderActions.getPanelVersionInformation,
  resetPanelVersionInformation: orderActions.resetPanelVersionInformation,
  consentPopupModal: orderActions.consentPopupModal,
}, dispatch);

class OrderTestModal extends Component {
  static propTypes = OrderTestModalStaticProps;

  static defaultProps = OrderTestModalDefaultProps;

  componentDidMount() {
    // Load required data
    const {
      getPanelTiers, getPanelGeneLists, getPanelVersionInformation, orderInfo,
    } = this.props;

    // Loads panel information from prince.
    // In addition to genes we fetch secondary findings flag from CLINT.
    if (orderInfo.is_customized || orderInfo.special_type === NEI) {
      getPanelGeneLists(orderInfo.product.id);
    }

    // Load panel version information
    if ((orderInfo.product && orderInfo.product.prince_id) || orderInfo.ext_panel_id) {
      // If order's product is a flex product, use that custom panel instead of original panel
      getPanelVersionInformation(orderInfo.ext_panel_id || orderInfo.product.prince_id);
    }

    getPanelTiers();
  }

  componentWillUnmount() {
    this.props.resetOrderInfo();
    this.props.resetPanelVersionInformation();
  }

  close = (cancel) => {
    this.props.consentPopupModal({ dontShowTilNextRefresh: false });
    this.props.setCurrentGeneCount(null);
    this.props.setCurrentPanelTierId(null);
    this.props.closeOrder(cancel);
  }

  getPanelHeadingContent = () => {
    const {
      currentStep,
      orderInfo,
    } = this.props;

    const productCode = orderInfo.product.code;
    const productName = fullPanelName(orderInfo);
    const productCodeSuffix = productCode ? `, ${productCode}` : '';
    const wasOrderedSuffix = currentStep > getSteps(orderInfo).length ? ' ordered' : '';

    return `${productName}${productCodeSuffix}${wasOrderedSuffix}`;
  }

  getPanelVersionInfo = () => {
    const { panelVersionInformation } = this.props;
    if (!panelVersionInformation) { return ''; }

    return `Version: ${panelVersionInformation.version}, ${panelVersionInformation.last_updated}`;
  };

  renderBody() {
    const {
      currentStep,
      orderInfo,
      error,
      userInfo,
      panelTiers,
      currentPanelTierId,
      currentGeneCount,
      isInternalUser,
      isSupportUser,
    } = this.props;

    const isPanelNew = orderInfo.product.is_new;
    const isPanelUpdated = orderInfo.product.is_updated;

    const orderPricingModel = getPricingModelForOrder(orderInfo, userInfo);

    let panelTierText = null;

    if (orderInfo.product.panel_tier_id && panelTiers) {
      let panelTier = null;
      if (orderPricingModel === 'phenotype') {
        panelTier = panelTiers.find((pt) => pt.panel_tier_id === orderInfo.product.panel_tier_id);
      } else {
        panelTier = panelTiers.find((pt) => pt.panel_tier_id === currentPanelTierId);
      }

      panelTierText = panelTier && `Panel tier: ${getPanelTierNumber(panelTier.size)}`;
    }

    const geneCountText = currentGeneCount && `Number of genes: ${currentGeneCount}`;

    const dob = orderInfo.patient_dob ? orderInfo.patient_dob : null;
    const patientLastName = orderInfo.patient_last_name ? orderInfo.patient_last_name : null;
    const patientFirstName = orderInfo.patient_first_name ? orderInfo.patient_first_name : null;
    const patientInfo = dob || patientFirstName || patientLastName;

    const steps = getSteps(orderInfo);

    return (
      <div>
        <OrderTestModalHeader
          dob={dob}
          geneCountText={geneCountText}
          getPanelHeadingContent={this.getPanelHeadingContent}
          getPanelVersionInfo={this.getPanelVersionInfo}
          isInternalUser={isInternalUser}
          isSupportUser={isSupportUser}
          isOrderCompleted={orderInfo.is_order_completed}
          isPanelNew={isPanelNew}
          isPanelUpdated={isPanelUpdated}
          orderId={orderInfo.order_id}
          orderIsCompleted={orderInfo.is_order_completed}
          panelTierText={panelTierText}
          patientFirstName={patientFirstName}
          patientInfo={patientInfo}
          patientLastName={patientLastName}
          salesforceOrderId={orderInfo.salesforce_order_id}
          status={orderInfo.status}
          userRole={userInfo.user_role}
          currentStep={currentStep}
        />
        <OrderTestModalBody
          currentStep={currentStep}
          error={error}
          close={this.close}
          steps={steps}
        />
      </div>
    );
  }

  static renderLoading() {
    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  render() {
    const { orderInfo } = this.props;
    const orderInfoLoaded = !!orderInfo;

    return (
      <Modal
        show
        onHide={this.close}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-sm"
      >
        {orderInfoLoaded && this.renderBody()}

        {!orderInfoLoaded && OrderTestModal.renderLoading()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestModal);
