import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getProfileInfo, isAuthenticated, shouldUseAlbAuth } from 'redux/modules/auth';
import RouteOrRedirect, { resolveRedirectTo } from './RouteOrRedirect';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  authError: state.auth.error,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
}, dispatch);

class PrivateRoute extends React.Component {
  static propTypes = {
    path: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    component: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func,
    ]).isRequired,
    SupportRole: PropTypes.bool,
    user: PropTypes.shape({
      auth_token_type: PropTypes.string,
    }),
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
    }),
    authError: PropTypes.string,
    location: PropTypes.shape({}).isRequired,
    getProfileInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    SupportRole: false,
    userInfo: {},
    authError: null,
    user: null,
  };

  componentDidMount() {
    if (!this.isUserInfoLoaded() && (shouldUseAlbAuth() || isAuthenticated(this.props.user))) {
      this.props.getProfileInfo();
    }
  }

  isSupportUser = () => {
    const { userInfo } = this.props;
    return userInfo && userInfo.user_role === 'support';
  }

  isUserInfoLoaded = () => {
    const { userInfo } = this.props;
    return !!userInfo && !!Object.keys(userInfo).length;
  }

  falsePredicate = () => false

  truePredicate = () => true

  /**
   * Render `component` or redirect the user to other routes based on guard
   * conditions like incomplete auth state or insufficient role.
   */
  renderOrRedirect() {
    const {
      path, component, location, SupportRole, user, ...rest
    } = this.props;

    // (legacy auth) Always require being logged in first
    if (!shouldUseAlbAuth() && !isAuthenticated(user)) {
      return (
        <RouteOrRedirect
          {...rest}
          path={path}
          predicate={this.falsePredicate}
          redirectTo={resolveRedirectTo(location, '/login')}
          component={component}
        />
      );
    }

    // NOTE Wed Mar 15 19:32:05 EET 2023, Trung:
    //
    // With legacy authentication, the authState.user object is set as the
    // result of the /api/v2/login endpoint. With ALB auth, however, the
    // login endpoint is no longer called, so authState.user is now
    // derived from authState.userInfo and cached in localStorage. If it
    // is null (first request after ALB login), we delay rendering until
    // after the first successful /api/v2/get_profile_info.
    //
    // This delaying also benefits legacy auth because certain pages need to
    // access authState.userInfo to render correctly, e.g. OrdersList
    if (!this.isUserInfoLoaded()) {
      return (
        <div className="mt-1 text-center">
          Checking authentication...
        </div>
      );
    }

    if (SupportRole) {
      return (
        <RouteOrRedirect
          {...rest}
          path={path}
          predicate={this.isSupportUser}
          redirectTo={resolveRedirectTo(location, '/orders')}
          component={component}
        />
      );
    }

    // We know that user is logged in, thus predicate can be always true
    return (
      <RouteOrRedirect
        {...rest}
        path={path}
        predicate={this.truePredicate}
        redirectTo={resolveRedirectTo(location, '/login')}
        component={component}
      />
    );
  }

  render() {
    const { authError } = this.props;

    if (authError) {
      return (
        <div className="text-danger mt-1 text-center">
          Authentication error:
          {' '}
          {authError}
        </div>
      );
    }

    return this.renderOrRedirect();
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PrivateRoute);
