import React from 'react';
import PropTypes from 'prop-types';

import { LabelDateInput } from 'components';

const DateInput = ({ sampleCollectionDate, onChangeValue, label }) => (
  <LabelDateInput
    inputSize="6"
    label={label}
    labelSize="6"
    name="sample_collection_date"
    onChange={(date) => onChangeValue('sample_collection_date', date)}
    selected={sampleCollectionDate}
  />
);

DateInput.propTypes = {
  sampleCollectionDate: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

DateInput.defaultProps = {
  sampleCollectionDate: null,
};

export default DateInput;
