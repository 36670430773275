import React, { useState, useEffect } from 'react';
import BaseTable, { Column } from 'react-base-table';
import { useHistory } from 'react-router';
import debounce from 'lodash/debounce';
import Helmet from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import { getHospitals } from 'redux/modules/support';
import { countriesSelector, countriesErrorSelector, loadCountries } from 'redux/modules/countries';
import { AdminHeading } from 'admin/components';
import Spinner from 'components/Spinner/Spinner';
import mapHospital from 'utils/hospitals';
import useData from 'hooks/useData';
import CommonCell from './components/CommonCell';
import AddressCell from './components/AddressCell';
import LinkCell from './components/LinkCell';

import styles from './Hospitals.scss';

const Hospitals = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState('');

  const hospitalData = useData(getHospitals);
  const countries = useSelector(countriesSelector);
  const countriesError = useSelector(countriesErrorSelector);

  const errorMsg = hospitalData.error || countriesError;

  const title = 'Hospitals';

  const changeSearchString = debounce((string) => {
    setSearchString(string.toLowerCase());
  }, 200);

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  const getFilteredHospitals = () => {
    if (hospitalData && hospitalData.data && countries) {
      const hospitals = Object
        .values(hospitalData.data)
        .map((item) => mapHospital(item, countries));
      const toSearch = searchString.toLowerCase();

      if (!hospitals || !searchString) {
        return hospitals;
      }

      const fieldContains = (hospital, fieldName) => {
        if (hospital[fieldName]) {
          return hospital[fieldName].toLowerCase().includes(toSearch);
        }
        return false;
      };

      return hospitals.filter((hospital) => (
        fieldContains(hospital, 'hospitalName')
          || fieldContains(hospital, 'hospitalBusinessId')
          || fieldContains(hospital.hospitalAddress, 'line1')
          || fieldContains(hospital.hospitalAddress, 'line2')
          || fieldContains(hospital.hospitalAddress, 'city')
          || fieldContains(hospital, 'hospitalCountry')
      ));
    }
    return [];
  };

  const renderBody = () => {
    const tableData = getFilteredHospitals();

    if (tableData.length === 0) {
      return (
        <div className={styles.search_error}>
          <p>No hospitals matching the search criteria were found.</p>
        </div>
      );
    }

    if (typeof errorMsg === 'string') {
      return (
        <div className={styles.search_error}>
          <p>No hospitals were found.</p>
          <p>{errorMsg}</p>
        </div>
      );
    }

    return (
      <BaseTable
        data={tableData}
        width={1200}
        height={window.innerHeight - 100}
        className={styles.table}
        rowHeight={60}
      >
        <Column
          cellRenderer={CommonCell}
          title="Name"
          key="hospitalName"
          dataKey="hospitalName"
          width={240}
          flexGrow={2}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Business ID"
          key="hospitalBusinessId"
          dataKey="hospitalBusinessId"
          width={100}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={AddressCell}
          title="Address"
          key="hospitalAddress"
          dataKey="hospitalAddress"
          width={200}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="ZIP"
          key="hospitalZip"
          dataKey="hospitalZip"
          width={100}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Country"
          key="hospitalCountry"
          dataKey="hospitalCountry"
          width={100}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Users"
          key="hospitalUserCount"
          dataKey="hospitalUserCount"
          width={70}
          flexGrow={1}
          align="center"
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Orders"
          key="hospitalOrderCount"
          dataKey="hospitalOrderCount"
          width={70}
          flexGrow={1}
          align="center"
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={LinkCell}
          key="editKey"
          dataKey="id"
          width={50}
          flexGrow={1}
          linkLabel="Edit"
          align="center"
        />
        <Column
          cellRenderer={LinkCell}
          key="productKey"
          dataKey="id"
          width={70}
          flexGrow={1}
          linkLabel="Products"
        />
      </BaseTable>
    );
  };

  const { push } = useHistory();

  const props = {
    labelText: '+\xa0 Add Hospital',
    title: 'Hospitals',
    buttonFunc: () => push('/hospitals/new'),
    searchFunc: (event) => changeSearchString(event.target.value),
    placeholder: 'Find a hospital',
  };

  return (
    <div className={`${styles.hospitals} container`}>
      <Helmet title={title} />
      <div className={styles.heading}>
        <AdminHeading {...props} />
      </div>
      {hospitalData.isLoading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <div>{renderBody()}</div>
      )}
    </div>
  );
};

export default Hospitals;
