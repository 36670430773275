import React from 'react';
import PropTypes from 'prop-types';
import styles from 'theme/variables.scss';
import ErrorMessage from './ErrorMessage';

const SelectInput = ({
  // Field
  field,
  options,
  form,
  // Appearance
  label,
  labelSize,
  inputSize,
  placeholder,
  hint,
  required,
  disabled,
  extraInput,
  onChange,
  isHighlighted,
}) => (
  <div className="form-group row" style={isHighlighted ? { backgroundColor: styles.highLightedQuestion } : {}}>
    {label && (
      <label
        htmlFor={field.name}
        className={`col-md-${labelSize} col-form-label`}
      >
        {required ? `${label} *` : label}
        <p className="small">{hint}</p>
      </label>
    )}

    <div className={(inputSize === 'fit') ? 'col-12' : `col-md-${inputSize} no-padding-left`}>
      <div className="select">
        <div className="arrow" />

        <select
          className="form-control"
          id={field.name}
          disabled={disabled}
          required={required}
          {...field}
          value={field.value || ''}
          onChange={(event) => {
            if (onChange) {
              onChange(event, form);
            }

            if (field.onChange) {
              field.onChange(event);
            }
          }}
        >
          {!!placeholder && (
            <option value="" hidden={required}>
              {placeholder}
            </option>
          )}

          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
        </select>

        {extraInput || null}

        <ErrorMessage name={field.name} />
      </div>
    </div>
  </div>
);

SelectInput.propTypes = {
  // Field
  field: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  // Appearance
  label: PropTypes.string,
  labelSize: PropTypes.number,
  inputSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'fit']),
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  extraInput: PropTypes.node,
  onChange: PropTypes.func,
  isHighlighted: PropTypes.bool,
};

SelectInput.defaultProps = {
  placeholder: '',
  label: null,
  labelSize: 6,
  inputSize: 6,
  hint: null,
  required: false,
  disabled: false,
  extraInput: null,
  onChange: null,
  isHighlighted: false,
};

export default SelectInput;
