import React from 'react';
import PropTypes from 'prop-types';

import { getQuestionOptions } from 'components/PatientHistoryQuestion';
import QuestionPropTypes from 'types/QuestionPropTypes';
import { LabelRadioDisplay } from 'components';

const BoolSliderPreview = ({ question }) => (
  <LabelRadioDisplay
    label={question.question}
    labelSize="6"
    inputSize="6"
    hint={question.hint}
    freeTextAnswer={question.free_text_option_question}
    options={getQuestionOptions(question)}
  />
);

BoolSliderPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default BoolSliderPreview;
