import { useEffect, useState } from 'react';

import { sortCountries } from 'utils/forms';

const useCountryArray = (countriesById) => {
  const [countryArray, setCountryArray] = useState([]);

  useEffect(() => {
    if (countriesById && Object.keys(countriesById).length !== countryArray.length) {
      const sortedCountryArray = sortCountries(Object.values(countriesById));

      setCountryArray(sortedCountryArray);
    }
  }, [countriesById, countryArray.length]);

  return countryArray;
};

export default useCountryArray;
