import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';

const UsProdLocationSelect = ({
  handleInputValue,
  isRequired,
  onChangeValue,
  resultsReported,
  testTypeOptions,
  value,
}) => (
  <LabelSelect
    inputSize="6"
    label="Test type"
    labelSize="6"
    name="test_type"
    onChange={(event) => onChangeValue('test_type', handleInputValue(event))}
    options={testTypeOptions}
    readOnly={resultsReported}
    required={isRequired}
    value={value}
  />

);

UsProdLocationSelect.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  resultsReported: PropTypes.bool.isRequired,
  testTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
};

export default UsProdLocationSelect;
