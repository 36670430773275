import React from 'react';
import PropTypes from 'prop-types';

const EditOrderNavigation = ({
  isFirstStep,
  isLastStep,
  currentStep,
  onChangeStep,
  isSubmitting,
  dirty,
  onCancel,
  cancelLabel,
  onSaveAsDraft,
  saveLabel,
  extraButtons,
}) => (
  <div className="form-group row">
    <div className="col-md-12">
      <span className="float-left button-group">
        {!isFirstStep && (
          <button
            className="btn btn-default"
            type="button"
            disabled={isSubmitting}
            onClick={() => onChangeStep(currentStep - 1)}
          >
            Previous
          </button>
        )}

        <button
          className="btn btn-primary"
          type="button"
          disabled={isSubmitting}
          onClick={() => onCancel()}
        >
          {cancelLabel}
        </button>
      </span>

      <span className="float-right button-group">
        <button
          className="btn btn-default"
          type="button"
          disabled={isSubmitting || !dirty}
          onClick={() => onSaveAsDraft()}
        >
          {saveLabel}
        </button>

        {!isLastStep && (
          <button
            className="btn btn-primary"
            type="button"
            disabled={isSubmitting}
            onClick={() => onChangeStep(currentStep + 1)}
          >
            Next
          </button>
        )}

        {extraButtons({
          nextStep: () => onChangeStep(currentStep + 1),
        })}
      </span>
    </div>
  </div>
);

EditOrderNavigation.propTypes = {
  isFirstStep: PropTypes.bool.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  onSaveAsDraft: PropTypes.func.isRequired,
  saveLabel: PropTypes.string.isRequired,
  extraButtons: PropTypes.func.isRequired,
};

export default EditOrderNavigation;
