import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as order from 'redux/modules/orders';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
} from 'utils/patientQuestions';
import { userInfoSelector, aliasUserIdSelector } from 'redux/modules/auth';

import { patientQuestions } from 'components/Formik/validation';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';
import { LabelAttachments } from 'components';

const validate = (loading) => (values) => {
  if (loading) {
    return { formIsLoading: true };
  }
  const errors = {};
  const questionErrors = patientQuestions(values.questions);
  if (questionErrors.length) {
    errors.questions = questionErrors;
  }
  return errors;
};

const prepareQuestions = (data, orderInfo, billingMethod) => prepareQuestionsForForm(
  Object.values(data)
    .sort((a, b) => a.order_no - b.order_no),
).map((question) => {
  if (
    (orderInfo.is_after_hospital_status_release_us_order
      && question.name === 'icd10cod_1'
      && billingMethod !== 'sponsored_testing'
    )
    || ((billingMethod === 'patient_insurance'
      || billingMethod === 'patient_payment')
      && question.name === 'hospitals_1'
      && orderInfo.is_after_hospital_status_release_us_order)) {
    return { ...question, is_compulsory: true };
  }
  return question;
});

const OrderTestForm = ({ hideModal }) => {
  const [questions, setQuestions] = useState([]);
  const [skipFields, setSkipFields] = useState({});
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const userInfo = useSelector(userInfoSelector);
  const orderInfo = useSelector(order.orderInfoSelector);
  const aliasUserId = useSelector(aliasUserIdSelector);

  const { order_id: orderId } = orderInfo;

  const dispatch = useDispatch();
  const savePanelAnswers = (answers) => dispatch(
    order.savePanelAnswers(orderId, answers),
  );

  const submit = async (values, actions) => {
    actions.setFieldError('general', null);
    try {
      const answers = constructQuestionAnswers(values.questions);
      await savePanelAnswers(answers);
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!orderInfo) {
          return;
        }

        const getPanelQuestions = () => dispatch(order.getPanelQuestions(orderId));
        const panelQuestions = await getPanelQuestions();
        const miscFields = orderInfo.skip_misc_fields || [];
        const getBillingInfo = () => dispatch(order.getBillingInfo(orderId));
        const { billing_method: actualBillingMethod } = await getBillingInfo();
        setQuestions(prepareQuestions(panelQuestions, orderInfo, actualBillingMethod));
        setSkipFields(miscFields.reduce((acc, field) => Object.assign(acc, {
          [field]: true,
        }), {}));
        setIsLoading(false);
      } catch (err) {
        setIsError(err);
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    setIsError(null);
    fetchData();
  }, [dispatch, orderId, orderInfo]);

  return (
    <Formik
      validate={validate(isLoading)}
      initialValues={{ questions }}
      enableReinitialize
      onSubmit={(values, actions) => submit(values, actions)}
    >
      {(formProps) => (
        <Form autoComplete="off">
          <PromptIfDirty {...formProps} />
          <EditOrderStep
            {...formProps}
            hideModal={hideModal}
            error={isError || formProps.errors.general}
            loading={isLoading}
            submit={() => formProps.submitForm()}
          >
            {!isLoading && !skipFields.patient_history_attachments && (
              <>
                <LabelAttachments
                  orderId={orderId}
                  userInfo={userInfo}
                  aliasUserId={aliasUserId}
                  type="order_attachment"
                  label="Attached files"
                  hint="Attach additional patient documentation, such as a copy of patient’s insurance card, family tree, ECG or other clinical documentation."
                />
                <p className="small">Allowed file types are TXT, PDF, JPG, PNG, ZIP, Word and Excel.</p>
              </>
            )}
            <PatientHistoryQuestions questions={formProps.values.questions} isSupportUser={userInfo.user_role === 'support'} />
          </EditOrderStep>
        </Form>
      )}
    </Formik>
  );
};

OrderTestForm.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default OrderTestForm;
