// TODO: remove after redux-form is no longer used
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrayCheckbox, LabelInput } from 'components';

function getHiddenQuestionOverrides(questions, values) {
  const hiddenQuestionOverrides = {};

  if (questions && values) {
    questions.forEach((question) => {
      if (question.question_options) {
        const value = values.find((vv) => vv.key === question.question_id.toString());
        const selected = (
          value !== undefined
          && question.question_options.find((op) => op.question_id === value.value)
        );

        if (selected && selected.conditional_question_id) {
          hiddenQuestionOverrides[selected.conditional_question_id] = true;
        }
      }
    });
  }

  return hiddenQuestionOverrides;
}

export default class ConfirmationQuestions extends Component {
  static propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.shape({
        onChange: PropTypes.func,
      }),
    })),
    values: PropTypes.arrayOf(PropTypes.shape({})),
    questions: PropTypes.arrayOf(PropTypes.shape({})),
    screeningSpecialType: PropTypes.bool,
    enableHTMLQuestion: PropTypes.bool,
  };

  static defaultProps = {
    fields: null,
    values: null,
    questions: null,
    screeningSpecialType: false,
    enableHTMLQuestion: false,
  };

  componentDidUpdate() {
    this.clearHiddenQuestions(this.props);
  }

  getField(questionId) {
    const idString = String(questionId);

    // If not fields present, create read only field.
    if (!this.props.fields) {
      const questionValue = this.props.values.find((item) => item.key === idString);

      if (!questionValue) {
        return null;
      }
      return {
        value: questionValue.value,
        readOnly: true,
      };
    }
    const questionField = this.props.fields.find((item) => item.key.value === idString);

    return questionField && questionField.value;
  }

  clearHiddenQuestions(props) {
    if (!props.fields || !props.values) {
      return;
    }
    const { values, questions } = props;
    const hiddenQuestionOverrides = getHiddenQuestionOverrides(questions, values);

    values.forEach((item, index) => {
      const question = questions.find((qq) => String(qq.question_id) === item.key);
      const hidden = question.is_hidden_by_default && !hiddenQuestionOverrides[item.key];

      if (hidden && item.value) {
        this.props.fields[index].value.onChange('');
      }
    });
  }

  renderSource = (freeTextOptionAnswer) => (
    <span>
      , Source:
      {
        ` ${freeTextOptionAnswer}`
      }
    </span>
  )

  renderQuestion = (question) => {
    const field = this.getField(question.question_id);

    if (!field) {
      return null;
    }

    switch (question.type) {
      case 'checkbox':
        return (
          <div style={{ marginTop: 15 }} key={question.question_id}>
            {this.props.enableHTMLQuestion
              ? <div dangerouslySetInnerHTML={{ __html: `<p><strong>${question.question}</strong></p>` }} />
              : <p><strong>{question.question}</strong></p>}

            {
              question.question_options?.map((option) => {
                const givenValue = String(option.question_option_id);
                const selected = field.value.indexOf(givenValue) !== -1;

                return (
                  <div key={option.question_option_id}>
                    <ArrayCheckbox
                      {...field}
                      className="long-question-option"
                      key={option.question_option_id}
                      id={option.question_option_id}
                      givenValue={givenValue}
                      label={option.question_option}
                    />
                    {field.touched && field.error && !selected && <div className="text-danger">{field.error}</div>}
                  </div>
                );
              })
            }
          </div>
        );
      case 'radio':
        return (
          <div style={{ marginTop: 15 }} key={question.question_id}>
            <p><strong>{question.question}</strong></p>
            {field.touched && field.error && <div className="text-danger">{field.error}</div>}
            {
              question.question_options.map((option) => {
                const optionId = option.question_option_id;
                const value = String(optionId);

                return (
                  <div className={`form-check radio long-question-option${field.readOnly ? ' readonly' : ''}`} key={option.question_option_id}>
                    <input
                      {...field}
                      id={optionId}
                      className="form-check-input"
                      type="radio"
                      checked={field.value === value}
                      value={value}
                    />
                    <label htmlFor={optionId} className="form-check-label">
                      {option.question_option}
                    </label>
                  </div>
                );
              })
            }
            {question.free_text_option_answer && (
              <div style={{ marginTop: 10 }}>
                Additional information:&nbsp;
                {question.free_text_option_answer}
              </div>
            )}
          </div>
        );
      case 'text': {
        const parts = question.question.split('\r\n\r\n');
        const title = parts.length === 2 ? parts[0] : '';
        const label = parts.length === 2 ? parts[1] : question.question;

        return (
          <div style={{ marginTop: 15 }} key={question.question_id}>
            <p><strong>{title}</strong></p>
            {this.props.fields
              ? (
                <LabelInput
                  label={label}
                  labelSize="6"
                  inputSize="6"
                  hint={question.hint}
                  type="text"
                  {...field}
                  required={question.is_compulsory}
                />
              )
              : (
                <div className="modal_row">
                  <label className="view_order_modal_label">
                    {label}
                  </label>
                  <span>
                    {field.value}
                  </span>
                </div>
              )}
          </div>
        );
      }
      default:
        return null;
    }
  }

  render() {
    const { questions, values, screeningSpecialType } = this.props;

    const updatedQuestions = questions?.map((question) => {
      if (screeningSpecialType && question?.name === 'sampletyp_1') {
        return {
          ...question,
          question: 'Sample type individual 1',
        };
      }
      return question;
    });

    const hiddenQuestionOverrides = getHiddenQuestionOverrides(updatedQuestions, values);
    const isQuestionVisible = (question) => !(
      question.is_hidden_by_default
      && !hiddenQuestionOverrides[question.question_id]
    );

    return (
      <div>
        {updatedQuestions?.length > 0
          && updatedQuestions.filter(isQuestionVisible).map(this.renderQuestion)}
      </div>
    );
  }
}
