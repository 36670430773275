import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';

const PrintOrderSummaryFile = ({
  downloadOrderSummaryFile,
  orderInfo,
}) => (
  <div className="float-left">
    <button
      className="btn btn-default"
      style={{ marginRight: '15px' }}
      type="button"
      onClick={() => {
        downloadOrderSummaryFile({
          order_id: orderInfo.order_id,
          format: 'pdf',
        });
      }}
    >
      Print
    </button>
  </div>
);

PrintOrderSummaryFile.propTypes = {
  downloadOrderSummaryFile: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(
  (state) => ({
    orderInfo: state.orders.orderInfo,
  }),
  {
    downloadOrderSummaryFile: orderActions.downloadOrderSummaryFile,
  },
)(PrintOrderSummaryFile);
