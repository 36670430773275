import React from 'react';

import { LabelDateInput, LabelInput } from 'components';
import PatientInfo from '../PatientInfo/PatientInfo';
import RadioButtonComponent from '../RadioButtonComponent/RadioButtonComponent';

import { WorkFlowsSelect } from '../ChangeOrderStatusForm/FormInputs';

import { ScreeningDuoPatientPropTypes, ScreeningDuoPatientDefaultProps } from './ScreeningDuoPatientConstants';

const ScreeningDuoPatient = ({
  orderStatus,
  sampleTypeOptions,
  handleInputValue,
  onChange,
  disabled,
  reprintLabId,
  showWorkflows,
  resultsReported,
}) => {
  const {
    order_id: orderId,
    lab_id_individual_2: labId,
    name_individual_2: name,
    dob_individual_2: dob,
    ssn_individual_2: ssn,
  } = orderStatus;

  const handleReprintLabId = () => reprintLabId(labId, orderStatus.printer_name);

  return (
    <div>
      <PatientInfo
        name={name}
        ssn={ssn}
        dob={dob}
        order_id={orderId}
        lab_id={labId}
      />
      <RadioButtonComponent
        disabled={disabled}
        inputSize="6"
        label="Primary sample type individual 2"
        labelSize="6"
        name="primary_sample_type_individual_2"
        onChange={(event) => {
          onChange('primary_sample_type_individual_2', handleInputValue(event));
        }}
        options={sampleTypeOptions}
        type="radio"
        value={orderStatus.primary_sample_type_individual_2}
      />
      <LabelDateInput
        label="Sample collection date individual 2"
        labelSize="6"
        inputSize="6"
        name="sample_collection_date_individual_2"
        selected={orderStatus.sample_collection_date_individual_2}
        onChange={(date) => onChange('sample_collection_date_individual_2', date)}
        disabled={disabled}
      />
      <LabelInput
        label="Customer sample ID individual 2"
        labelSize="6"
        inputSize="6"
        name="customer_sample_id_individual_2"
        type="text"
        value={orderStatus.customer_sample_id_individual_2}
        onChange={(event) => onChange('customer_sample_id_individual_2', handleInputValue(event))}
        disabled={disabled}
      />
      <LabelInput
        inputSize="6"
        label="Sample disposal category individual 2"
        labelSize="6"
        name="sample_disposal_category_individual_2"
        readOnly
        value="No consent - 1-year storage"
      />
      {showWorkflows && (
        <WorkFlowsSelect
          handleInputValue={handleInputValue}
          onChangeValue={onChange}
          options={orderStatus.lims_workflows_available}
          resultsReported={resultsReported}
          value={orderStatus.lims_workflow_individual_2}
          individualLabel={2}
        />
      )}
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleReprintLabId}
        >
          Reprint Lab ID Individual 2
        </button>
      </div>
    </div>
  );
};

ScreeningDuoPatient.propTypes = ScreeningDuoPatientPropTypes;
ScreeningDuoPatient.defaultProps = ScreeningDuoPatientDefaultProps;

export default ScreeningDuoPatient;
