import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { userRoleSelector } from 'redux/modules/auth';
import AddAuthorizedProviderForm from 'components/AddAuthorizedProvider/AddAuthorizedProviderForm';
import { authorizedProviderDescription } from 'utils/forms';

const AddAuthorizedProvider = () => {
  const title = 'Add Authorized Provider';

  const currentUserRole = useSelector(userRoleSelector);

  if (currentUserRole !== 'order_agent') {
    return <Redirect to="/" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Helmet title={title} />
          <h1>
            {title}
          </h1>
          <p>
            {authorizedProviderDescription}
          </p>
          <br />
          <div className="col-lg-6 offset-lg-3">
            <AddAuthorizedProviderForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAuthorizedProvider;
