import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrderReportVariantsWgsHeader from './_OrderReportVariantsWgsHeader';

export default class OrderReportVariantsWgs extends Component {
  static propTypes = {
    variantsWgs: PropTypes.object,
    reportVersion: PropTypes.number,
  }

  render() {
    const { variantsWgs } = this.props;
    const styles = require('./_OrderReportVariantsWgs.scss');

    return (
      <div>
        <OrderReportVariantsWgsHeader />
        <table className="table">
          <thead>
            <tr className={styles.report_heading_row}>
              <th>Pos</th>
              <th>Length</th>
              <th>Event</th>
              <th>Copy number</th>
              <th>Genotype</th>
              <th>Overlapping genes</th>
              <th>Links</th>
              <th>Classification</th>
            </tr>
          </thead>
          <tbody>
            {variantsWgs && Object.keys(variantsWgs).map((key) => (
              <tr key={key}>
                <td>{variantsWgs[key].position}</td>
                <td>{variantsWgs[key].length}</td>
                <td>{variantsWgs[key].event}</td>
                <td>{variantsWgs[key].copy_number}</td>
                <td>{variantsWgs[key].gt}</td>
                <td>{variantsWgs[key].overlap}</td>
                <td><a className="link-pink" target="_blank" href={variantsWgs[key].links}>{variantsWgs[key].links_txt}</a></td>
                <td>{variantsWgs[key].classification}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
