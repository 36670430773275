import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EXOME, SCREENING } from 'helpers/constants/specialTypeConstants';
import PanelList from './PanelList';
import SingleGeneList from './SingleGeneList';
import ProductListItem, { ProductListItemEditVisibility } from './ProductListItem';

import styles from './ProductSelection.scss';

let categories = [{
  type: 'panels',
  label: 'Panels',
}, {
  type: 'wes',
  label: 'Whole Exome Sequencing',
}, {
  type: 'vst',
  label: 'Variant Specific Testing',
}, {
  type: 'single-gene',
  label: 'Single Gene Tests',
}, {
  type: 'screening',
  label: 'Screening Tests',
}];

function isProductInCategory(categoryType, product) {
  switch (categoryType) {
    case 'wes':
      return product.special_type && product.special_type.includes(EXOME);

    case 'vst':
      return product.category_name === 'Variant Specific Testing';

    case 'single-gene':
      return product.category_name === 'Single gene tests';

    case 'screening':
      return product.special_type && product.special_type.includes(SCREENING);

    default:
    // panel
      return categories
        // Keep non panels categories
        .filter(({ type }) => type !== 'panels')
        // Test that product does not belong to any of the categories
        .every(({ type }) => !isProductInCategory(type, product));
  }
}

export default class ProductSelection extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
    })).isRequired,
    onSelect: PropTypes.func,
    onUpgrade: PropTypes.func,
    onDelete: PropTypes.func,
    onCustomize: PropTypes.func,
    mode: PropTypes.oneOf(['editVisibility', 'ordering']),
    pricingModel: PropTypes.oneOf(['fixed', 'phenotype', 'gene_count']),
    savedFlexPanels: PropTypes.arrayOf(PropTypes.shape({})),
    formProps: PropTypes.shape({
      resetForm: PropTypes.func,
      initialValues: PropTypes.shape({}),
      isSubmitting: PropTypes.bool,
    }),
  };

  static defaultProps = {
    savedFlexPanels: [],
    onUpgrade: () => {},
    onDelete: () => {},
    onSelect: () => {},
    onCustomize: () => {},
    formProps: {},
    mode: 'ordering',
    pricingModel: false,
  }

  state = {
    selectedCategory: 'panels',
    searchString: '',
  };

  selectCategory = (category) => {
    this.setState({
      selectedCategory: category,
    });
  }

  setSearch = (e) => {
    this.setState({
      searchString: e.target.value,
    });
  }

  getCategoryProducts = (categoryType) => {
    const {
      products,
    } = this.props;

    const {
      searchString,
    } = this.state;

    const searchStringLowerCase = searchString.toLowerCase();

    const shouldIncludeProduct = (product) => (
      !product.category_is_hidden
        && !product.is_hidden
        && isProductInCategory(categoryType, product)
    );

    const filterProductByName = (product) => {
      const { name } = product;
      let productName = name;

      const singleGeneTestStr = ' single gene test';
      // Almost all single gene test product have the string above in its name
      // which can affect search result and should be trim away
      // https://blueprintgenetics.atlassian.net/browse/SD-7790
      if (
        product.category_name === 'Single gene tests'
        && productName.includes(singleGeneTestStr)
      ) {
        productName = productName.split(singleGeneTestStr, 1)[0];
      }

      return productName.toLowerCase().includes(searchStringLowerCase);
    };

    return products
      .filter(shouldIncludeProduct) // Keep products from selected category
      .filter(filterProductByName)
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
  }

  // filtered saved flex panels with the search
  getFilteredSavedFlexPanels = () => {
    const { savedFlexPanels } = this.props;
    const { searchString } = this.state;
    return savedFlexPanels
      .filter(({ name }) => ( // Keep products that match search
        name.toLowerCase().includes(searchString.toLowerCase())
      ))
      .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name
  }

  renderEditVisibilityActions = () => {
    const {
      formProps,
    } = this.props;

    const resetDefaultVisibility = () => {
      // using resetForm to both reset values from DB and also current form
      formProps.resetForm({ values: formProps.initialValues });
    };

    return (
      <div className="col-lg-8 offset-lg-2">
        <div className="row button-group">
          <div className="col-lg-6">
            <div className="col-lg-1">
              <i
                className="glyphicon glyphicon-lg glyphicon-info-sign "
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-11">
              <p>
                Reset will be applied only after saving.
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <button
              className="btn btn-default btn-block"
              type="button"
              onClick={resetDefaultVisibility}
            >
              Reset product set
            </button>
          </div>
          <div className="col-lg-3">
            <button className="btn btn-primary btn-block" type="submit">
              {formProps.isSubmitting ? 'Updating...' : 'Save product set'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderScreeningProducts = (products, onSelect) => {
    const reproductiveProducts = products.filter((product) => product.product_code.startsWith('CS'));
    const proactiveProducts = products.filter((product) => product.product_code.startsWith('PS'));
    return (
      <div className="col-lg-8 offset-lg-2">
        <h3>Reproductive tests</h3>
        <ul
          className={`${styles.panel_list} list-unstyled`}
          id="reproductive-tests"
        >
          {reproductiveProducts.map((product) => (
            <ProductListItem
              key={product.panel_id}
              product={product}
              onSelect={() => onSelect(product)}
            />
          ))}
        </ul>
        <h3>Proactive tests</h3>
        <ul
          className={`${styles.panel_list} list-unstyled`}
          id="proactive-tests"
        >
          {proactiveProducts.map((product) => (
            <ProductListItem
              key={product.panel_id}
              product={product}
              onSelect={() => onSelect(product)}
            />
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const {
      onSelect,
      onUpgrade,
      onDelete,
      onCustomize,
      formProps,
      mode,
    } = this.props;

    const {
      selectedCategory,
      searchString,
    } = this.state;

    const visibleCategories = categories.filter(({ type }) => type === selectedCategory);
    const screeningProducts = this.getCategoryProducts('screening');
    if (screeningProducts.length === 0 && searchString.length === 0) {
      categories = [
        {
          type: 'panels',
          label: 'Panels',
        },
        {
          type: 'wes',
          label: 'Whole Exome Sequencing',
        },
        {
          type: 'vst',
          label: 'Variant Specific Testing',
        },
        {
          type: 'single-gene',
          label: 'Single Gene Tests',
        },
      ];
    }
    return (
      <div>
        <CategorySelection
          selectedCategory={selectedCategory}
          selectCategory={this.selectCategory}
        />
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="form-inline tests-search-form">
              <div className="input-group search-input-group">
                <input
                  type="text"
                  name="search"
                  className="search form-control"
                  placeholder="Find a test"
                  value={searchString}
                  onChange={this.setSearch}
                />

                <span className="input-group-button">
                  <button type="button">
                    <i className="glyphicon glyphicon-search" aria-hidden="true" />
                  </button>
                </span>
              </div>
            </div>
          </div>

          {mode === 'editVisibility' && (
            this.renderEditVisibilityActions(visibleCategories)
          )}
        </div>

        <section className={styles.panel_list_wrapper}>
          {visibleCategories.map(({ type }) => (
            <div key={type} className="row">
              {(() => {
                const products = this.getCategoryProducts(type);
                switch (type) {
                  case 'panels':
                    return (
                      <div className="col-lg-8 offset-lg-2">
                        <PanelList
                          panels={products}
                          savedFlexPanels={this.getFilteredSavedFlexPanels()}
                          searchString={searchString}
                          onSelect={onSelect}
                          onUpgrade={onUpgrade}
                          onDelete={onDelete}
                          onCustomize={onCustomize}
                          formProps={formProps}
                          mode={mode}
                          pricingModel={this.props.pricingModel}
                        />
                      </div>
                    );

                  case 'single-gene':
                    return (
                      <div className="col-12">
                        <SingleGeneList
                          products={products}
                          searchString={searchString}
                          onSelect={onSelect}
                          mode={mode}
                        />
                      </div>
                    );

                  case 'screening':
                    return this.renderScreeningProducts(products, onSelect);

                  default:
                    return (
                      <div className="col-lg-8 offset-lg-2">
                        {mode === 'editVisibility' && (
                          <ul className={`${styles.panel_list} list-unstyled`}>
                            {products.map((product) => (
                              <ProductListItemEditVisibility
                                key={product.panel_id}
                                product={product}
                              />
                            ))}
                          </ul>
                        )}

                        {mode === 'ordering' && (
                          <ul className={`${styles.panel_list} list-unstyled`} id="variant-specific-tests">
                            {products.map((product) => (
                              <ProductListItem
                                key={product.panel_id}
                                product={product}
                                onSelect={() => onSelect(product)}
                              />
                            ))}
                          </ul>
                        )}
                      </div>
                    );
                }
              })()}
            </div>
          ))}
        </section>
      </div>
    );
  }
}

/*
  eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-noninteractive-element-interactions
*/
const CategorySelection = ({
  selectCategory,
  selectedCategory,
}) => (
  <ul className={`${styles.panel_toggle_btn_group} list-unstyled`}>
    {categories.map(({ type, label }) => (
      <li
        key={type}
        id={type}
        className={classNames(styles.panel_group_toggle_btn, {
          [styles.active]: selectedCategory === type,
        })}
        onClick={() => selectCategory(type)}
      >
        <span>{label}</span>

        {(selectedCategory === type) && (
          <i
            className={`${styles.panel_group_toggle_icon} glyphicon glyphicon-sm glyphicon-menu-down`}
            aria-hidden="true"
          />
        )}
      </li>
    ))}
  </ul>
);
/*
  eslint-enable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-noninteractive-element-interactions
*/

CategorySelection.propTypes = {
  selectCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
};
