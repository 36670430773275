import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Checkbox from 'components/Formik/Checkbox';
import SelectInput from 'components/Formik/SelectInput';
import PrintConsent from './PrintConsent';

const ConsentSparkFormV4 = ({
  consentLanguages,
  consentLanguage,
}) => (
  <div className="col-md-4 offset-md-4">
    <div className="form-group">
      <Field
        name="consentLanguage"
        id="consent_language"
        component={SelectInput}
        inputSize={12}
        options={Object.entries(consentLanguages).map((keyConsentLanguage) => ({
          key: keyConsentLanguage[0],
          value: keyConsentLanguage[1],
        }))}
      />
    </div>
    <PrintConsent lang={consentLanguage} />
    <Field
      name="consentSigned"
      id="consentSigned"
      component={Checkbox}
      label="Informed consent from patient (or their authorized representative) has been obtained as required by applicable state law and/or regulations."
      labelClassName="consentSigned"
      className="text-left"
    />
  </div>
);

ConsentSparkFormV4.propTypes = {
  consentLanguages: PropTypes.shape({}).isRequired,
  consentLanguage: PropTypes.string.isRequired,
};

export default ConsentSparkFormV4;
