import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { doneUpdateUserSelector } from 'redux/modules/support';
import usePrevious from 'hooks/usePrevious';

const useOnUpdateUser = (onUpdateUser) => {
  const doneUpdateUser = useSelector(doneUpdateUserSelector);

  const previousDoneUpdateUser = usePrevious(doneUpdateUser);

  useEffect(() => {
    if (previousDoneUpdateUser === false && doneUpdateUser === true) {
      onUpdateUser();
    }
  }, [onUpdateUser, previousDoneUpdateUser, doneUpdateUser]);
};

export default useOnUpdateUser;
