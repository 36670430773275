import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';

import OrderInfoPropTypes from 'types/OrderInfoPropTypes';

import Checkbox from 'components/Formik/Checkbox';
import RadioButton from 'components/Formik/RadioButton';
import RadioButtonGroup from 'components/Formik/RadioButtonGroup';
import OrderUserInfo from 'components/_CreateOrderProcess/OrderUserInfo';
import CompletedOrderCustomerInformation from './CompletedOrderCustomerInformation';

const validateAkouosSection = (values) => {
  /* eslint-disable camelcase */
  const {
    akouos_2,
    akouos_3,
    akouos_4,
    akouos_5,
  } = values;

  if (!akouos_2 || !akouos_3) {
    return 'Compliance to following terms and conditions must be selected';
  }

  if (!akouos_4 && !akouos_5) {
    return 'one of the two options must be selected!';
  }

  const {
    akouos_6,
    akouos_7,
    akouos_8,
  } = values;

  if (akouos_8 !== 'yes') {
    return 'Patient mustn&apos;t have evidence of syndromic medical history';
  }

  if (akouos_6 !== 'yes' && akouos_7 !== 'yes') {
    return 'Please select one!';
  }

  return '';
};

// Only one option for genetic counseling is allowed
const handleGeneticCounselingInfo = (form, field, { target: { checked } }) => {
  if (field.name === 'akouos_4') {
    /**
     * Here order matters, If you set the value of akouos_4 first and then akouos_5 then
     * it will call the onchange handler linked to akouos_5 which is this function itself
     * in that case "checked" would be undefined as we have not clicked on the checkbox instead
     * set the value via "setFieldValue".
     * Due to which both fields becomes false ("akouos_4" = undefined and "akouos_5" = false)
     * and this state would lead to validation error.
     *
    */
    form.setFieldValue('akouos_4', checked);
    form.setFieldValue('akouos_5', false);
  } else {
    form.setFieldValue('akouos_4', false);
    form.setFieldValue('akouos_5', checked);
  }
};

const AkouosSection = ({ orderInfo, isSupport }) => (
  <>
    {orderInfo && <OrderUserInfo orderInfo={orderInfo} />}
    {isSupport && orderInfo.is_order_completed && (
      <CompletedOrderCustomerInformation orderInfo={orderInfo} />
    )}
    <div>
      Successful submission of this requisition form requires
      compliance to the following terms and conditions.
      Please select the option to demonstrate compliance*:
    </div>
    <FieldArray
      name="akouos"
      render={() => (
        <>
          <Field
            name="akouos_2"
            component={Checkbox}
            label={(
              <p>
                I have reviewed the content of the Resonate Program:
                Sponsored Genetic Testing Informed Consent with the patient or
                their authorized representative, including the notification that
                Blueprint Genetics may provide de-identified information to the
                sponsor of this testing program.
                <br />
                I confirm that any additional informed consent has been obtained
                as required by applicable state law and/or regulations.
              </p>
            )}
            required
          />
          <Field
            name="akouos_3"
            component={Checkbox}
            label={(
              <div>
                <span>
                  I confirm that I understand that my
                  patient&apos;s sample analysis will only begin when:
                </span>
                <ol>
                  <li>
                    informed consent has been obtained
                    in accordance with applicable state,
                    federal and/or country laws and regulations; and
                  </li>
                  <li>
                    I have submitted this requisition successfully.
                  </li>
                </ol>
              </div>
            )}
            required
          />
        </>
      )}
    />
    <div>
      Genetic counseling is required for all patients
      undergoing genetic testing through the Program.
      Please indicate how you plan to provide
      genetic counseling to your patient*:
    </div>
    <Field
      name="akouos_5"
      component={Checkbox}
      onChange={handleGeneticCounselingInfo}
      label={(
        <span>
          I will provide genetic counseling to the patient or
          refer the patient to a local genetic counselor.
          With this option I understand the cost of
          genetic counseling is not covered by the Resonate Program.
          I affirm my patient understands the requirement for counseling,
          understands the options available,
          and understands and accepts the additional costs
          that may be incurred in selecting this option.
        </span>
      )}
    />
    <Field
      name="akouos_4"
      component={Checkbox}
      onChange={handleGeneticCounselingInfo}
      label={(
        <span>
          I request genetic counseling for this patient through
          InformedDNA (an independent, phone-based,
          Genetic Counseling service, contracted for this study).
        </span>
      )}
    />
    <div>
      Eligibility Criteria *
    </div>
    <div>
      Eligibility for this program is a current or
      prior clinical diagnosis of auditory neuropathy,
      or a medical history consistent with auditory neuropathy.
      Auditory neuropathy (AN) is a hearing disorder
      characterized by an absent or
      abnormal auditory brainstem response (ABR)
      with preservation of otoacoustic emissions (OAEs)
      or cochlear microphonics (CMs).
    </div>
    <FieldArray
      name="akouos_6"
      render={() => (
        <>
          <RadioButtonGroup
            name="akouos_6"
            label="OAE or CM present with absent or abnormal ABR:"
            required
          >
            <Field
              key="akouos_6_yes"
              component={RadioButton}
              name="akouos_6"
              value="yes"
              label="Yes"
            />
            <Field
              key="akouos_6_no"
              component={RadioButton}
              name="akouos_6"
              value="no"
              label="No"
            />
          </RadioButtonGroup>
          <RadioButtonGroup
            name="akouos_7"
            label="OAE or CM previously present with absent or abnormal ABR:"
            required
          >
            <Field
              key="akouos_7_yes"
              component={RadioButton}
              name="akouos_7"
              value="yes"
              label="Yes"
            />
            <Field
              key="akouos_7_no"
              component={RadioButton}
              name="akouos_7"
              value="no"
              label="No"
            />
          </RadioButtonGroup>
          <RadioButtonGroup
            name="akouos_8"
            label="Patient does not have evidence of syndromic medical history:"
            required
          >
            <Field
              key="akouos_8_yes"
              component={RadioButton}
              name="akouos_8"
              value="yes"
              label="Yes"
            />
            <Field
              key="akouos_8_no"
              component={RadioButton}
              name="akouos_8"
              value="no"
              label="No"
            />
          </RadioButtonGroup>
        </>
      )}
    />
  </>
);

AkouosSection.propTypes = {
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
  isSupport: PropTypes.bool.isRequired,
};

export { AkouosSection, validateAkouosSection };
