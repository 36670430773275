import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import {
  Formik, Form, Field, yupToFormErrors,
} from 'formik';
import {
  Yup,
  requiredString,
  when,
  requiredText,
  dobSchema,
  patientQuestions,
  patientQuestionGroups,
} from 'components/Formik/validation';

import LabelInput from 'components/Formik/LabelInput';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';
import GroupedPatientHistoryQuestions from 'components/GroupedPatientHistoryQuestions';
import FetalQuestion from 'components/FetalQuestion/FetalQuestion';

import {
  getPatientInfoLabels,
  excludeFields,
  keepOnlyFields,
  isSpark,
  showFetalSampleQuestion,
  isScreeningDuo,
  isResonateProgramPanelV3AndAbove,
} from 'utils/forms';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
  groupQuestions,
  constructGroupedQuestionAnswers,
  mrtMinorPatientQuestions,
  isThePatientMinorQuestion,
} from 'utils/patientQuestions';
import { SCREENING, SPARK } from 'helpers/constants/specialTypeConstants';
import { PATIENT_ADDRESSES_QUESTIONS, PATIENT_MINOR_QUESTIONS } from 'helpers/constants/patientInformationFieldConstants';
import MrtIsPatientMinorInfoQuestions from './MrtIsPatientMinorInfoQuestions';
import SampleTypeQuestion from './SampleTypeQuestion';

const validationSchema = Yup.object().shape({
  gestational_age: Yup.string().nullable(),
  is_this_a_fetal_sample: Yup.string().oneOf([
    null,
    'no',
    'yes',
    'none',
    'index',
    'family_member_one',
    'family_member_two',
  ]).nullable(),
  patient_first_name: requiredString(),
  patient_last_name: requiredString(),
  patient_dob: dobSchema().required(requiredText),
  patient_ssn: Yup.string().nullable(),

  patient_zip: Yup.string()
    .when('special_type', {
      is: SPARK,
      then: requiredString(),
      otherwise: Yup.string().nullable(),
    })
    .when(['informed_dna', 'resonate_panel_v3_and_above'], {
      is: (informedDna, latestResonate) => informedDna
        && latestResonate,
      then: requiredString(),
      otherwise: Yup.string().nullable(),
    }),
  patient_address: Yup.string()
    .when(['informed_dna', 'resonate_panel_v3_and_above'], {
      is: (informedDna, latestResonate) => informedDna
        && !latestResonate,
      then: requiredString(),
      otherwise: Yup.string().nullable(),
    }),
  patient_phone: when('informed_dna', true, requiredString()),
  patient_email: when('informed_dna', true, Yup.string().nullable()),
  sample_from_ongoing_pregnancy: Yup.string().oneOf([
    null,
    'no',
    'yes',
  ]).nullable(),
  special_type: Yup.mixed(),
  informed_dna: Yup.mixed(),
});

/**
 * Validate the form. Use Yup schema for validating fields.
 */

async function validate(values) {
  const errors = {};
  let updatedQuestions = values?.questions;
  const isPatientMinorQuestion = isThePatientMinorQuestion(values?.questions);
  const showPatientMinorQuestions = isPatientMinorQuestion?.selected_option_id
    === isPatientMinorQuestion?.question_options[0]?.question_option_id;
  const isLatestResonatePanel = values.resonate_panel_v3_and_above;
  const isInformedDna = values.informed_dna;
  updatedQuestions = values?.questions.map((question) => {
    if ((showPatientMinorQuestions && PATIENT_MINOR_QUESTIONS.includes(question.name))
      || (
        isLatestResonatePanel
        && isInformedDna
        && PATIENT_ADDRESSES_QUESTIONS.includes(question.name)
      )
    ) {
      return {
        ...question,
        is_compulsory: true,
      };
    }
    return question;
  });
  const questionErrors = patientQuestions(updatedQuestions);
  if (questionErrors.length) {
    errors.questions = questionErrors;
  }

  // Validate family member grouped questions
  const fmGroupErrors = patientQuestionGroups(values.fmGroups);
  if (fmGroupErrors.length) {
    errors.fmGroups = fmGroupErrors;
  }

  try {
    await validationSchema.validate(excludeFields(['questions'], values), {
      abortEarly: false,
    });
  } catch (e) {
    const yupErrors = yupToFormErrors(e);
    Object.assign(errors, yupErrors);
  }
  return errors;
}

const mapStateToProps = (state) => ({
  orderId: state.orders.orderInfo.order_id,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrder: orderActions.getOrder,
  saveOrder: orderActions.saveOrder,
  getPanelQuestions: orderActions.getPanelQuestions,
  savePanelAnswers: orderActions.savePanelAnswers,
}, dispatch);

class OrderTestFormPatientInfo extends Component {
  static propTypes = {
    orderId: PropTypes.number.isRequired,
    getOrder: PropTypes.func.isRequired,
    saveOrder: PropTypes.func.isRequired,
    getPanelQuestions: PropTypes.func.isRequired,
    savePanelAnswers: PropTypes.func.isRequired,
    userInfo: PropTypes.shape().isRequired,

    // props
    hideModal: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    orderInfo: null,
    initialValues: {
      questions: [],
      fmGroups: [],
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { orderId } = this.props;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      const [orderInfo, questionsResponse, sampleTypeQuestion] = await Promise.all([
        this.props.getOrder(orderId),
        this.props.getPanelQuestions(orderId, 1),
        this.props.getPanelQuestions(orderId, 3),
      ]);

      const questions = prepareQuestionsForForm(
        Object.values(questionsResponse)
          .sort((a, b) => a.order_no - b.order_no),
      );
      const otherQuestions = questions.filter((question) => !question.name.startsWith('familymem_'));
      const fmGroups = groupQuestions(questions, 'familymem', 'dob');
      const fieldNames = Object.keys(validationSchema.fields);

      this.setState({
        loading: false,
        orderInfo,
        initialValues: {
          ...keepOnlyFields(fieldNames, orderInfo),
          questions: otherQuestions,
          fmGroups,
          informed_dna: orderInfo.akouos_4,
          resonate_panel_v3_and_above: isResonateProgramPanelV3AndAbove(
            orderInfo.product.code,
            orderInfo.product.version,
          ),
          sample_type_questions: prepareQuestionsForForm(
            Object.values(sampleTypeQuestion).sort((a, b) => a.order_no - b.order_no),
          ),
        },
      });
    } catch (e) {
      this.setState({
        error: 'Failed to load patient info',
        loading: false,
      });
    }
  }

  submit = async (values, actions) => {
    try {
      const { questions } = values;
      const { orderId } = this.props;
      actions.setFieldError('general', null);

      // Update order
      const payload = excludeFields(['questions', 'fmGroups', 'special_type', 'informed_dna'], values);

      if (payload?.sample_from_ongoing_pregnancy
        !== this.state.initialValues?.sample_from_ongoing_pregnancy) {
        payload.allow_fetal_remove = true;
      }

      // We hide addresses question if latest resonate panel and informed dna is not selected.
      // We use 2 conditions so that it wont affect other panels (like MRT)
      const hideAddressesQuestions = values.resonate_panel_v3_and_above && !values.informed_dna;
      if (hideAddressesQuestions) payload.patient_zip = '';
      await this.props.saveOrder(orderId, payload);
      // Save patient answers
      if (
        questions.length
        || values.fmGroups.length
        || values.sample_type_questions.length
      ) {
        const answers = constructQuestionAnswers(
          questions,
          hideAddressesQuestions,
        );

        const fmAnswers = constructGroupedQuestionAnswers(values.fmGroups);
        const sampleTypeAnswers = constructQuestionAnswers(values.sample_type_questions);
        const allAnswers = Object.assign(answers, { ...fmAnswers, ...sampleTypeAnswers });

        await this.props.savePanelAnswers(orderId, allAnswers, 1);
      }
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  }

  renderForm(formProps) {
    const { orderInfo } = this.state;
    const { userInfo } = this.props;
    const isSparkProduct = isSpark(orderInfo.special_type);
    const isScreeningProduct = orderInfo?.special_type === SCREENING;

    const patientInfoQuestions = mrtMinorPatientQuestions(formProps?.values?.questions);

    const patientMinorInfoQuestions = mrtMinorPatientQuestions(formProps?.values?.questions, true);
    // Different orders require different labels for patient info fields

    const isLatestResonatePanel = isResonateProgramPanelV3AndAbove(
      orderInfo?.product?.code,
      orderInfo?.product?.version,
    );
    const isInformedDna = orderInfo.akouos_4;

    const patientInfoLabels = getPatientInfoLabels(orderInfo.special_type);

    return (
      <div>
        <Field
          name="patient_first_name"
          component={LabelInput}
          label={patientInfoLabels.firstName}
          required
        />

        <Field
          name="patient_last_name"
          component={LabelInput}
          label={patientInfoLabels.lastName}
          required
        />

        <Field
          name="patient_dob"
          component={SimpleDatePicker}
          label={patientInfoLabels.dob}
          required
        />

        {orderInfo.product.code !== 'EA0601' && (
          <Field
            name="patient_ssn"
            component={LabelInput}
            label={patientInfoLabels.ssn}
          />
        )}

        {isInformedDna && (
          <>
            <Field
              name="patient_phone"
              component={LabelInput}
              label={patientInfoLabels.phone}
              required
            />
            {!isLatestResonatePanel && (
              <Field
                name="patient_address"
                component={LabelInput}
                label={patientInfoLabels.address}
                required
              />
            )}
            <Field
              name="patient_email"
              component={LabelInput}
              label={patientInfoLabels.email}
            />
          </>
        )}

        {isLatestResonatePanel && isInformedDna && (
          <Field
            name="patient_zip"
            component={LabelInput}
            label="Patient Postal Code"
            required
          />
        )}

        {(!isSparkProduct && !isLatestResonatePanel)
          && (
            <SampleTypeQuestion
              sampleTypePanelQuestions={formProps.values.sample_type_questions}
              isScreeningProduct={isScreeningProduct}
              screeningLabel={isScreeningProduct && 'Individual 1'}
            />
          )}

        {showFetalSampleQuestion(orderInfo) && (
          <FetalQuestion orderInfo={orderInfo} values={formProps.values} userInfo={userInfo} />
        )}

        <PatientHistoryQuestions
          questions={patientInfoQuestions}
          isLatestResonatePanel={isLatestResonatePanel}
          isInformedDna={orderInfo.akouos_4}
        />
        <GroupedPatientHistoryQuestions
          namePrefix="fmGroups"
          addButtonLabel="Add family member"
          removeButtonLabel="Remove family member"
          formProps={formProps}
          groups={formProps.values.fmGroups}
        />

        {/* Ideally this field should come from patient histories. */}
        {isSparkProduct && (
          <Field
            name="patient_zip"
            component={LabelInput}
            label={patientInfoLabels.zipCode}
            required
          />
        )}

        {patientMinorInfoQuestions?.length > 0
          && <MrtIsPatientMinorInfoQuestions questions={patientMinorInfoQuestions} />}

        {(isSparkProduct || isScreeningDuo(orderInfo.product.code) || isLatestResonatePanel)
          && (
            <SampleTypeQuestion
              sampleTypePanelQuestions={formProps.values.sample_type_questions}
              isScreeningProduct={isScreeningProduct}
              isScreeningDuo={isScreeningDuo(orderInfo.product.code)}
              screeningLabel={isScreeningProduct && 'Individual 2'}
            />
          )}
      </div>
    );
  }

  render() {
    const { hideModal } = this.props;

    const {
      orderInfo,
      loading,
      error,
      initialValues,
    } = this.state;

    return (
      <Formik
        validate={validate}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => this.submit(values, actions)}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <PromptIfDirty {...formProps} />

            <EditOrderStep
              {...formProps}
              hideModal={hideModal}
              error={error || formProps.errors.general}
              loading={loading}
              submit={() => formProps.submitForm()}
            >

              {orderInfo && this.renderForm(formProps)}

            </EditOrderStep>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestFormPatientInfo);
