import React from 'react';
import PropTypes from 'prop-types';

import QuestionPropTypes from 'types/QuestionPropTypes';
import RadioButtonPreview from './RadioButtonPreview';
import BoolSliderPreview from './BoolSliderPreview';
import CheckboxPreview from './CheckboxPreview';
import TextAreaPreview from './TextAreaPreview';
import TextPreview from './TextPreview';
import NumberTextPreview from './NumberTextPreview';
import SelectPreview from './SelectPreview';
import DOBPreview from './DOBPreview';

import styles from './QuestionPreview.scss';

const QuestionPreview = ({ question }) => {
  if (!question) {
    return (
      null
    );
  }

  if (Object.keys(question).length === 0) {
    return (
      null
    );
  }
  const components = {
    radio: RadioButtonPreview,
    bool_slider: BoolSliderPreview,
    checkbox: CheckboxPreview,
    textarea: TextAreaPreview,
    text: TextPreview,
    number: NumberTextPreview,
    select: SelectPreview,
    dob: DOBPreview,
  };

  const TagName = components[question.type];

  return (
    <>
      <h2 className={styles.heading}>Preview</h2>
      <TagName question={question} />
    </>
  );
};

QuestionPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes),
};

QuestionPreview.defaultProps = {
  question: {},
};

export default QuestionPreview;
