import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getOrderSummaryFile } from 'redux/modules/orders';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { downloadFileFunc } from 'utils/forms';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderInfo: state.orders.orderInfo,
  haveOrderSummaryFile: state.orders.haveOrderSummaryFile,
  orderSummaryFileObject: state.orders.orderSummaryFileObject,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrderSummaryFile,
}, dispatch);

class OrderTestFormSendSampleInfo extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    userInfo: PropTypes.shape({
      country: PropTypes.string,
    }).isRequired,
    orderSummaryFileObject: PropTypes.shape({}),
    getOrderSummaryFile: PropTypes.func.isRequired,
    haveOrderSummaryFile: PropTypes.bool,

    // props
    hideModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    orderSummaryFileObject: null,
    haveOrderSummaryFile: null,
  };

  state ={
    skipFields: {},
  };

  componentDidMount() {
    const { orderInfo } = this.props;

    this.props.getOrderSummaryFile({
      order_id: orderInfo.order_id,
      format: 'pdf',
    });

    const miscFields = orderInfo.skip_misc_fields || [];
    const skipFields = miscFields.reduce((acc, field) => Object.assign(acc, {
      [field]: true,
    }), {});

    this.setState({
      skipFields,
    });
  }

  printOrder = () => {
    downloadFileFunc(this.props.orderSummaryFileObject);
  };

  render() {
    const {
      userInfo,
      hideModal,
      haveOrderSummaryFile,
    } = this.props;

    const { skipFields } = this.state;

    const userCountry = userInfo.country;
    const cssStyle = { margin: 10 };

    return (
      <div className="text-center sample-info-wrapper">
        <p>Please send the sample to the address below. </p>
        <div className="address-wrapper">
          {!skipFields.emea_address && (
            <div
              className="address-item"
            >
              <h5>Europe, Canada, Middle East, Asia and Australia</h5>
              <p>
                Blueprint Genetics Oy
                <br />
                Samples
                <br />
                Keilaranta 16 A-B
                <br />
                02150 Espoo
                <br />
                FINLAND
              </p>
            </div>
          )}
          {userCountry === 'CA' && (
            <p className="address-item-connect text-pink">OR</p>
          )}
          <div
            className={`${userCountry === 'CA' ? 'canada' : ''} address-item`}
          >
            {userCountry !== 'CA' && <h5>US and South America</h5>}
            <p>
              <br />
              Blueprint Genetics
              <br />
              Samples
              <br />
              200 Forest Street, 2nd Floor
              <br />
              Marlborough, MA 01752
              <br />
              United States
            </p>
          </div>
        </div>
        <p>
          Your order will be processed and you will receive a notification by
          email when results are ready.
          {' '}
          <br />
          To see the status of the sample, please login later.
        </p>
        <a
          href="//blueprintgenetics.com/general-terms/"
          target="_blank"
          rel="noopener noreferrer"
          className="link-pink"
        >
          General terms
        </a>
        <div className="button-wrapper text-center">
          {haveOrderSummaryFile && (
            <button
              className="btn btn-default"
              type="button"
              onClick={this.printOrder}
              style={cssStyle}
            >
              Print
            </button>
          )}
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => hideModal()}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestFormSendSampleInfo);
