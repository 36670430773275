import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import EditOrderStep from '../EditOrderStep';
import PromptIfDirty from '../../Formik/PromptIfDirty';
import ConsentSparkForm from '../_OrderTestFormInformedConsent/ConsentSparkForm';
import ConsentSparkFormV3 from '../_OrderTestFormInformedConsent/ConsentSparkFormV3';
import ConsentSparkFormV4 from '../_OrderTestFormInformedConsent/ConsentSparkFormV4';
import ConsentAkouosForm from './ConsentAkouosForm';

const mapStateToProps = (state) => ({
  orderInfo: state.orders.orderInfo,
  panelGeneLists: state.orders.panelGeneLists,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadConsentFile: orderActions.downloadConsentFile,
  saveOrder: orderActions.saveOrder,
  getOrder: orderActions.getOrder,
  getConsentLanguages: orderActions.getConsentLanguages,
}, dispatch);

class OrderTestFormRequiredConsent extends Component {
  static propTypes = {
    downloadConsentFile: PropTypes.func.isRequired,
    saveOrder: PropTypes.func.isRequired,
    getOrder: PropTypes.func.isRequired,
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    hideModal: PropTypes.func.isRequired,
    getConsentLanguages: PropTypes.func.isRequired,
  };

  state = {
    loading: true,
    error: null,
    consentInfo: {
      consentLanguage: 'en',
    },
    panelVersion: null,
    productCode: null,
    consentLanguages: {},
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchData()
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  fetchData = async () => {
    const {
      orderInfo,
      getOrder,
      getConsentLanguages,
    } = this.props;

    this.setState({
      error: null,
    });

    try {
      const orderDetails = await getOrder(orderInfo.order_id);
      const consentLanguages = await getConsentLanguages(orderInfo.product.id);

      const consentInfo = {
        consentSigned: orderDetails.patient_consent,
        consentLanguage: 'en',
      };

      this.setState({
        consentLanguages,
        consentInfo,
        productCode: orderDetails.product.code,
        panelVersion: orderDetails.product.version,
      });
    } catch (e) {
      this.setState({
        error: 'Failed to load consent info',
      });
    }
  }

  submit = async (values, actions) => {
    const { orderInfo } = this.props;

    try {
      await this.props.saveOrder(orderInfo.order_id, {
        patient_consent: values.consentSigned,
      });
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  validate = (values) => {
    const errors = {};

    const { consentSigned } = values;
    if (!consentSigned) {
      errors.consentSigned = 'Consent is required in order to proceed.';
    }

    return errors;
  }

  renderConsentForm = (formProps) => {
    const {
      panelVersion, productCode, consentLanguages,
    } = this.state;
    const { consentLanguage } = formProps.values;

    if (productCode === 'OP3701') {
      return <ConsentSparkForm panelVersion={parseInt(panelVersion, 10)} />;
    }

    if (productCode === 'EA0601') {
      return <ConsentAkouosForm />;
    }

    // 7 is newest version of OP3801
    if (productCode === 'OP3801' && parseInt(panelVersion, 10) === 7) {
      return (
        <ConsentSparkFormV4
          consentLanguages={consentLanguages}
          consentLanguage={consentLanguage}
        />
      );
    }

    return <ConsentSparkFormV3 />;
  }

  render() {
    const { hideModal } = this.props;
    const { consentInfo, error, loading } = this.state;

    return (
      <Formik
        onSubmit={this.submit}
        validate={this.validate}
        enableReinitialize
        initialValues={consentInfo}
      >
        {(formProps) => (
          <Form>
            <PromptIfDirty {...formProps} />
            <EditOrderStep
              {...formProps}
              error={error}
              hideModal={hideModal}
              loading={loading}
              submit={(action) => (
                action === 'save'
                  ? this.submit(formProps.values, formProps)
                  : formProps.submitForm()
              )}
              isRequiredConsent
            >
              {consentInfo && (
                <div className="row">{this.renderConsentForm(formProps)}</div>
              )}
            </EditOrderStep>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestFormRequiredConsent);
