import React from 'react';
import PropTypes from 'prop-types';

import ViewBillingInfoRow from './ViewBillingInfoRow';
import ViewPatientBillingInfo from './ViewPatientBillingInfo';

import styles from '../BillingInfo.scss';

const PAYMENT_METHODS = {
  CHECK: 'check',
  CREDIT_CARD: 'credit_card',
  PAYPAL: 'paypal',
  BANK_TRANSFER: 'bank_transfer',
};

const getPaymentMethodLabel = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.CHECK:
      return 'Check';
    case PAYMENT_METHODS.CREDIT_CARD:
      return 'Credit card';
    case PAYMENT_METHODS.PAYPAL:
      return 'PayPal';
    case PAYMENT_METHODS.BANK_TRANSFER:
      return 'Bank transfer';
    default:
      return null;
  }
};

const ViewPatientPaymentBillingInfo = ({ billingInfoDetails }) => {
  const isLegacyBillingInfo = billingInfoDetails.is_legacy;
  const isCreditCardPayment = billingInfoDetails.payment_method === PAYMENT_METHODS.CREDIT_CARD;
  const isOtherTypeCreditCard = billingInfoDetails.credit_card_type === 'other';

  return (
    <div>
      <h4 className={styles.billing_address_heading}>Payment information</h4>
      <ViewPatientBillingInfo billingInfoDetails={billingInfoDetails} />
      <ViewBillingInfoRow value={billingInfoDetails.payment_method && getPaymentMethodLabel(billingInfoDetails.payment_method)} label="Payment method" />

      {
        isCreditCardPayment && (
          <div>
            <ViewBillingInfoRow value={billingInfoDetails.credit_card_type} label="Card type" />

            {isOtherTypeCreditCard && (
              <ViewBillingInfoRow value={billingInfoDetails.other_credit_card} label="Other" />
            )}

            <ViewBillingInfoRow value={billingInfoDetails.credit_card_number} label="Card number" />
            <ViewBillingInfoRow value={billingInfoDetails.credit_card_exp_date} label="Expiration date" />
            <ViewBillingInfoRow value={billingInfoDetails.credit_card_cvc} label="CVC" />

            {isLegacyBillingInfo
              ? <ViewBillingInfoRow value={billingInfoDetails.credit_card_holder_name} label="Card holder name" />
              : (
                <>
                  <ViewBillingInfoRow value={billingInfoDetails.credit_card_holder_first_name} label="Card holder first name" />
                  <ViewBillingInfoRow value={billingInfoDetails.credit_card_holder_last_name} label="Card holder last name" />
                </>
              )}

            <ViewBillingInfoRow value={billingInfoDetails.credit_card_holder_email} label="Card holder email" />
            <ViewBillingInfoRow value={billingInfoDetails.credit_card_holder_phone} label="Card holder phone" />
            <ViewBillingInfoRow value={billingInfoDetails.credit_card_amount} label="Amount" />
          </div>
        )
      }
    </div>
  );
};

ViewPatientPaymentBillingInfo.propTypes = {
  billingInfoDetails: PropTypes.shape({
    is_legacy: PropTypes.bool,
    payment_method: PropTypes.string,
    credit_card_type: PropTypes.string,
    other_credit_card: PropTypes.string,
    credit_card_number: PropTypes.string,
    credit_card_exp_date: PropTypes.string,
    credit_card_cvc: PropTypes.string,
    credit_card_holder_name: PropTypes.string,
    credit_card_holder_first_name: PropTypes.string,
    credit_card_holder_last_name: PropTypes.string,
    credit_card_holder_email: PropTypes.string,
    credit_card_holder_phone: PropTypes.string,
    credit_card_amount: PropTypes.string,
  }),
};

ViewPatientPaymentBillingInfo.defaultProps = {
  billingInfoDetails: null,
};

export default ViewPatientPaymentBillingInfo;
