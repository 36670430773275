import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import { Spinner } from 'components';

import styles from './OrderStatusModal.scss';

const ModalBody = ({
  children,
  isOrderStatus,
  loading,
  saving,
}) => (
  <Modal.Body>
    {(loading || saving)
      && (
        <div className={styles.spinner_inline}>
          <Spinner />
        </div>
      )}
    {!loading && !saving && isOrderStatus
      && (
        <div>
          {children}
        </div>
      )}
  </Modal.Body>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  isOrderStatus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default ModalBody;
