export function NEIBatchOrderUpload(data) {
  return {
    request: (client) => client.post('nei_batch_order', data),
  };
}

export function NEIBatchOrderListHospitals() {
  return {
    request: (client) => client.get('get_hospitals', {
      support_batch_ordering: true,
    }).then((users) => Object.values(users)),
  };
}

export function NEIBatchOrderListClinicians(hospitalId) {
  return {
    request: (client) => client.get('get_users', {
      hospital_id: hospitalId,
      show_deactivated: false,
    }).then((users) => Object.values(users)),
  };
}

export function batchOrderUpload(data) {
  return {
    request: (client) => client.post('batch_order', data),
  };
}
