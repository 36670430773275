import React from 'react';
import PropTypes from 'prop-types';

const VariantProp = ({
  title,
  value,
  isLink = false,
  url = '',
  className = '',
  colSpan = 1,
}) => {
  const content = isLink
    ? <div><a className="link-pink" href={url} target="_blank" rel="noopener noreferrer">{value}</a></div>
    : <div className={className}>{value}</div>;

  return (
    <td colSpan={colSpan}>
      <div className="report-td-heading">{title}</div>
      {content}
    </td>
  );
};

/* eslint-disable react/require-default-props */
VariantProp.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  url: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.number,
};
/* eslint-enable react/require-default-props */

export default VariantProp;
