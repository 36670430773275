import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const columnPropTypes = {
  linkLabel: PropTypes.string.isRequired,
};

const EditLinkCell = ({ cellData, column }) => {
  const { linkLabel } = column;
  const linkTarget = linkLabel === 'Edit' ? `/hospitals/${cellData}` : `/hospitals/${cellData}/edit_visibility`;

  return (
    <div>
      <Link
        to={linkTarget}
        className="link-pink"
      >
        {linkLabel}
      </Link>
    </div>
  );
};

EditLinkCell.propTypes = {
  cellData: PropTypes.number.isRequired,
  column: PropTypes.shape(columnPropTypes).isRequired,
};

export default EditLinkCell;
