import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { OrderLogs } from 'components';

export default class OrderReportAppendixArchivesHistory extends Component {
  static propTypes = {
    archiveReportFiles: PropTypes.object,
    logs: PropTypes.object,
    appendixArchivesHistoryShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string,
    isEmpty: PropTypes.func,
    getArchiveFile: PropTypes.func
  }

  render() {
    const {getArchiveFile, appendixHeading, archiveReportFiles, logs, appendixArchivesHistoryShown, onTogglePanel, isEmpty} = this.props;
    const styles = require('./_OrderReportAppendixArchivesHistory.scss');

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixArchivesHistoryShown')}>
          {appendixHeading}
          {appendixArchivesHistoryShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixArchivesHistoryShown ? '' : 'invisible'}>
          <h3 className="h4">Report archive</h3>
          <ul className="list-unstyled">
            {archiveReportFiles && Object.keys(archiveReportFiles).map(key =>
              <li className="attached-file-text" key={key} onClick={() => getArchiveFile(key)}>Archived: {archiveReportFiles[key].created_str}</li>
            )}
          </ul>
          {logs && !isEmpty(logs) &&
            <div>
              <h3 className="h4">Change history</h3>
              <OrderLogs logs={logs}/>
            </div>
          }
        </div>
      </div>
    );
  }
}
