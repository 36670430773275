import React from 'react';

import styles from './GeneSelection.scss';

const GeneListNote = () => (
  <div className={styles.gene_list_note}>
    <p>
      <b className="text-pink">* </b>
      Some, or all, of the gene is duplicated in the genome.
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink"
        href="https://blueprintgenetics.com/pseudogene/"
      >
        Read more
      </a>
      .
    </p>
    <p>
      <b className="text-pink"># </b>
      The gene has suboptimal coverage (means &lt; 90% of the gene&apos;s target nucleotides are
      covered at &gt; 20x with mapping quality score (MQ &gt; 20) reads), and/or the gene has
      exons listed under Test limitations section that are not included in the panel as they
      are not sufficiently covered with high-quality sequence reads. The sensitivity to
      detect variants may be limited in genes marked with an asterisk (
      <span className="text-pink">*</span>
      ) or number
      sign (
      <span className="text-pink">#</span>
      ). Gene refers to the HGNC
      approved gene symbol; Inheritance refers to inheritance patterns such as
      autosomal dominant (AD), autosomal recessive (AR), X-linked (XL), X-linked
      dominant (XLD) and X-linked recessive (XLR); ClinVar refers to the number
      of variants in the gene classified as pathogenic or likely pathogenic in
      this database (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink"
        href="http://www.ncbi.nlm.nih.gov/clinvar/"
      >
        ClinVar
      </a>
      ); HGMD refers to the number of variants with possible disease association
      in the gene listed in Human Gene Mutation Database (
      <a
        className="text-pink"
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.hgmd.cf.ac.uk/ac/"
      >
        HGMD
      </a>
      ). The list of associated, gene specific phenotypes are generated from
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink"
        href="http://research.nhgri.nih.gov/CGD/"
      >
        CGD &nbsp;
      </a>
      or &nbsp;
      <a target="_blank" rel="noopener noreferrer" href="http://www.orpha.net/">
        Orphanet &nbsp;
      </a>
      databases.
    </p>
  </div>
);

export default GeneListNote;
