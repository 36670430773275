import React from 'react';
import PropTypes from 'prop-types';

import styles from '../BatchOrder.scss';

const BatchPropTypes = {
  file: PropTypes.shape({}),
};

const BatchFileField = ({ values, setFieldValue, fileRef }) => {
  const handleFileChange = (event) => {
    const files = event.target.files;

    if (!files.length) {
      return;
    }

    const file = files[0];

    setFieldValue('file', file);
  };

  return (
    <>
      <label className={styles.batch_file_field}>Attach file (max 50MB)</label>
      <input
        type="file"
        id="file_input"
        className={`${styles.batch_file_field} form-control`}
        onChange={handleFileChange}
        ref={fileRef}
      />
      <label
        htmlFor="file_input"
        className="btn btn-default file-label-btn"
      >
        {(values.file && values.file.name) || 'Choose a file'}
      </label>
    </>
  );
};

BatchFileField.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape(BatchPropTypes).isRequired,
  fileRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default BatchFileField;
