import React from 'react';
import { Form, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import * as support from 'redux/modules/support';
import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import Checkbox from 'components/Formik/Checkbox';
import QuestionPropTypes from 'types/QuestionPropTypes';
import { Yup } from 'components/Formik/validation';
import { createInitialValues } from 'utils/forms';
import { TertiaryButton, SecondaryButton } from 'components';

import styles from './EditQuestion.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  question: Yup.string().ensure().required('Question is required'),
  type: Yup.string().ensure().required('Question type is required'),
  unit: Yup.string().nullable(),
  allow_user_defined_unit: Yup.boolean(),
  free_text_option_question: Yup.string().nullable(),
  hint: Yup.string().nullable(),
  is_common_question: Yup.boolean(),
  default_order_no: Yup.number()
    .typeError('Order no must be a number')
    .integer('Order no must be an integer')
    .max(99, 'Order no should not be bigger than 99')
    .min(0, 'Order no should not be smaller than 0')
    .nullable(),
  default_is_compulsory: Yup.boolean(),
  default_page_no: Yup.number()
    .typeError('Order no must be a number')
    .integer('Order no must be an integer')
    .min(0, 'Order no should not be smaller than 0')
    .max(99, 'Order no should not be bigger than 99')
    .nullable(),
});

const types = [
  { key: 'textarea', value: 'Text area' },
  { key: 'text', value: 'Text' },
  { key: 'number', value: 'Number (small text)' },
  { key: 'select', value: 'Select' },
  { key: 'radio', value: 'Radio button' },
  { key: 'checkbox', value: 'Checkbox' },
  { key: 'bool_slider', value: 'Boolean slider' },
  { key: 'dob', value: 'DOB' }];

const setInitialFormValues = (shouldReset, question) => {
  if (shouldReset || !question) {
    return createInitialValues(validationSchema.fields);
  }
  return question;
};

const EditQuestionForm = ({
  isEditMode, question, onQuestionUpdate, shouldReset, navigateBack,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const error = useSelector((state) => state.support.error);

  const labelTextSubmitButton = isEditMode ? 'Save modifications' : 'Create a question';
  const labelTextCancelButton = isEditMode ? 'Close' : 'Cancel';

  const handleFormSubmit = async (values, actions) => {
    if (isEditMode) {
      await dispatch(support.updateQuestion(values));
    } else {
      const createdQuestion = await dispatch(support.createQuestion(values));
      const newQuestionId = createdQuestion.result.question_id;
      history.push(`/questions/${newQuestionId}`);
    }

    actions.setSubmitting(false);

    onQuestionUpdate();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={setInitialFormValues(shouldReset, question)}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Form name="questionForm">
          {errors.general && (
            <div className={`${styles.error} clearfix`}>{errors.general}</div>
          )}
          <Field
            component={LabelInput}
            label="Alphanumeric ID"
            name="name"
            hint="Unique ID, leave empty to be constructed automatically"
          />
          <Field
            component={LabelInput}
            label="Question"
            name="question"
          />
          <Field
            label="Type"
            name="type"
            component={SelectInput}
            options={types}
            placeholder="Select question type:"
          />
          <Field
            component={LabelInput}
            label="Unit"
            name="unit"
          />
          <Field
            component={Checkbox}
            label="Allow user defined unit"
            name="allow_user_defined_unit"
            id="allow_user_defined_unit"
            labelClassName="view_order_modal_label"
            className="modal_checkbox"
          />
          <Field
            component={LabelInput}
            label="Free text option question"
            name="free_text_option_question"
          />
          <Field
            component={LabelInput}
            label="Hint"
            name="hint"
          />
          <Field
            component={Checkbox}
            label="Is common question"
            hint="Will be added to all panels"
            name="is_common_question"
            id="is_common_question"
            labelClassName="view_order_modal_label"
            className="modal_checkbox"
          />
          <Field
            component={LabelInput}
            label="Default order no"
            name="default_order_no"
            hint="Within a page"
          />
          <Field
            component={Checkbox}
            label="Default is compulsory"
            name="default_is_compulsory"
            id="default_is_compulsory"
            labelClassName="view_order_modal_label"
            className="modal_checkbox"
          />
          {error && <div className={`${styles.error} clearfix`}>{error}</div>}
          <TertiaryButton
            labelText={labelTextSubmitButton}
            data-id="add_or_edit_question_button"
            additionalClass={['btn_right', 'btn_margin_top']}
            disabled={isSubmitting}
            onClick={handleSubmit}
          />
          <SecondaryButton
            labelText={labelTextCancelButton}
            additionalClass={['btn_right', 'btn_separate', 'btn_margin_top']}
            onClick={navigateBack}
          />
        </Form>
      )}
    </Formik>
  );
};

EditQuestionForm.propTypes = {
  onQuestionUpdate: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  question: PropTypes.shape(QuestionPropTypes),
  shouldReset: PropTypes.bool.isRequired,
  navigateBack: PropTypes.func.isRequired,
};

EditQuestionForm.defaultProps = {
  question: null,
};

export default EditQuestionForm;
