import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from './ErrorMessage';

const id = (field, value) => `${field.name}-${value}`;

const CheckboxGroup = ({
  // Field
  field,
  form,
  options,
  // Appearance
  label,
  required,
  hint,
  extraInput,
  labelSize,
  inputSize,
  disabled,
  enableHTMLHint,
}) => (
  <div className="form-group row">
    <label className={`col-md-${labelSize} col-form-label`}>
      {
        enableHTMLHint ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: required ? `${label} *` : label }} />
            {hint && (<div className="small" dangerouslySetInnerHTML={{ __html: hint }} />)}
          </>
        ) : (
          <>
            {required ? `${label} *` : label}
            {hint && (<p className="small">{hint}</p>)}
          </>
        )
      }
    </label>
    <div className={classNames(`col-md-${inputSize}`, {
      // Bootstrap has max 12 columns
      'no-padding-left': inputSize !== 12,
    })}
    >
      {options.map((option) => (
        <div key={option.key} className="form-check checkbox">
          <input
            type="checkbox"
            name={field.name}
            id={id(field, option.key)}
            className="form-check-input"
            checked={field?.value?.includes(option.key)}
            disabled={disabled}
            onBlur={field.onBlur}
            onChange={(e) => {
              if (e.target.checked) {
                // Add to array
                form.setFieldValue(
                  field.name,
                  field.value.concat([option.key]),
                );
              } else {
                // Remove from array
                form.setFieldValue(
                  field.name,
                  field.value.filter((v) => v !== option.key),
                );
              }
            }}
          />

          <label htmlFor={id(field, option.key)} className="form-check-label" data-testid={option.value}>
            {option.value}
          </label>
        </div>
      ))}

      {extraInput || null}

      <ErrorMessage name={field.name} />
    </div>
  </div>
);

CheckboxGroup.propTypes = {
  // Field
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  // Appearance
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  extraInput: PropTypes.node,
  labelSize: PropTypes.number,
  inputSize: PropTypes.number,
  enableHTMLHint: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  required: false,
  hint: null,
  extraInput: null,
  labelSize: 6,
  inputSize: 6,
  disabled: false,
  enableHTMLHint: false,
};

export default CheckboxGroup;
