import React from 'react';
import PropTypes from 'prop-types';
import Isvg from 'react-inlinesvg';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import IntroVideo from './IntroVideo';
import IntroText from './IntroText';
import styles from './Login.scss';
import logo from './logo-bpg.svg';

const LoginLayout = (props) => {
  const { children } = props;
  return (
    <div className={styles.login_page}>
      <Helmet title="Login" />
      <div className="container">
        <div className={styles.logo}>
          <Link to="/login">
            <Isvg className={styles.logo} src={logo}>
              Cannot handle svg!
            </Isvg>
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-5 col-xl-4 order-lg-2">
            <div className={styles.login_wrapper}>{children}</div>
            <IntroText />
          </div>
          <IntroVideo />
        </div>
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
