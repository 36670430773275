import React from 'react';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';
import PrintConsent from './PrintConsent';

const ConsentSparkFormV3 = () => (
  <div className="col-md-8 offset-md-2">
    <PrintConsent />
    <Field
      name="consentSigned"
      id="consentSigned"
      component={Checkbox}
      label="Informed consent from patient (or their authorized representative) has been obtained as required by applicable state law and/or regulations."
      labelClassName="consentSigned"
      className="text-left"
    />
  </div>
);

export default ConsentSparkFormV3;
