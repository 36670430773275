import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import Page, { initPageState, handlePagePromise, navigateBack } from 'components/Page';
import ProductSelection from 'components/ProductSelection/ProductSelection';
import * as orderActions from 'redux/modules/orders';
import * as supportActions from 'redux/modules/support';
import { calculatePanelTiers } from 'utils/panelTiers';

const mapStateToProps = (state) => ({
  panelTiers: state.orders.panelTiers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrder: orderActions.getOrder,
  getPanels: orderActions.getPanels,
  getPanelTiers: orderActions.getPanelTiers,
  convertBlankOrder: supportActions.convertBlankOrder,
}, dispatch);

class ConvertBlankOrderPage extends Component {
  static propTypes = {
    // Fields
    match: PropTypes.shape({
      params: PropTypes.shape({
        orderId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({}).isRequired,
    panelTiers: PropTypes.arrayOf(PropTypes.shape({
      size: PropTypes.string.isRequired,
      minimum: PropTypes.number.isRequired,
      maximum: PropTypes.number.isRequired,
    })),

    // Methods
    getOrder: PropTypes.func.isRequired,
    getPanels: PropTypes.func.isRequired,
    getPanelTiers: PropTypes.func.isRequired,
    convertBlankOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    panelTiers: undefined,
  }

  state = initPageState({
    selectedProduct: null,
    modalError: null,
    data: {},
  });

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  getTitle = () => {
    const {
      data: {
        order,
      },
    } = this.state;

    return (
      (order && order.order_id)
        ? `Convert Order ID ${order.order_id}`
        : 'Convert Order'
    );
  }

  convert = async (product) => {
    const {
      history,
      convertBlankOrder,
    } = this.props;

    const orderId = parseInt(this.state.data.order.order_id, 10);
    const geneCollectionID = product.gene_collection_id || null;
    const panelTierID = product.panel_tier_id || null;

    try {
      await convertBlankOrder({
        orderId,
        productId: product.panel_id,
        geneCollectionID,
        panelTierID,
      });
    } catch (e) {
      this.setState({
        modalError: e.message,
      });
      return;
    }

    history.push(`/orders/${orderId}/edit`);
  }

  selectProduct = (product) => {
    this.setState({
      selectedProduct: product,
    });
  }

  onHideModal = () => {
    const {
      location,
      history,
    } = this.props;

    const {
      data: {
        order,
      },
    } = this.state;

    navigateBack(location, history, `/orders/${order.order_id}/edit`);
  }

  fetchData = async () => {
    const {
      match,
      getOrder,
      getPanels,
      getPanelTiers,
      panelTiers,
    } = this.props;

    const {
      orderId,
    } = match.params;

    if (!panelTiers) {
      await getPanelTiers();
    }

    return Promise.all([
      getOrder(orderId),
      getPanels(),
    ]).then(([order, panels]) => ({
      order,
      products: panels.products,
      savedFlexPanels: panels.gene_collections,
    }));
  }

  renderModal = () => {
    const {
      data: {
        order,
        products,
        savedFlexPanels,
      },
      selectedProduct,
      modalError,
    } = this.state;

    const pricingModel = order.user_hospital_pricing_model;

    const productsWithTiers = calculatePanelTiers(
      products,
      this.props.panelTiers,
      pricingModel,
    );
    const savedFlexPanelsWithTiers = calculatePanelTiers(
      savedFlexPanels,
      this.props.panelTiers,
      pricingModel,
    );

    return (
      <Modal
        show
        onHide={this.onHideModal}
        size="xl"
        className="modal-extra-large"
        aria-labelledby="contained-modal-title-sm"
      >
        <Modal.Header closeButton>
          <h2>Select Product</h2>
        </Modal.Header>

        <Modal.Body>
          {modalError && (
            <p className="text-danger">{modalError}</p>
          )}

          {products && !selectedProduct && (
            <ProductSelection
              products={productsWithTiers}
              onSelect={this.selectProduct}
              onCustomize={this.selectProduct}
              savedFlexPanels={savedFlexPanelsWithTiers}
              pricingModel={pricingModel}
            />
          )}

          {selectedProduct && (
            <div className="form-group row">
              <label className="col-md-2 col-form-label">Selected product</label>
              <div className="col-md-10">
                <span className="form-control">{[selectedProduct.name, selectedProduct.product_code].join(', ')}</span>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {products && (
            <button
              className="btn btn-primary"
              type="button"
              disabled={!selectedProduct}
              onClick={() => this.convert(selectedProduct)}
            >
              Convert to normal order
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  renderPage = () => {
    const {
      data: {
        order,
      },
      page,
    } = this.state;

    if (!order || page.error) {
      return null;
    }

    // Check that order can be edited by user
    if (!order.can_edit_order) {
      return (
        <Redirect to="/orders" />
      );
    }

    if (!order.special_type === 'blank') {
      return (
        <Redirect to={`/orders/${order.order_id}/edit`} />
      );
    }

    return this.renderModal();
  }

  render() {
    const {
      page,
    } = this.state;

    const {
      match,
    } = this.props;

    const {
      orderId,
    } = match.params;

    return (
      <Page
        cols={10}
        offsetCols={1}
        previousPageLink={`/orders/${orderId}/edit`}
        previousPageLabel="Edit order"
        title={this.getTitle()}
        render={this.renderPage}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ConvertBlankOrderPage);
