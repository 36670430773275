import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';

// Find page that we should redirect to. It is either the previous page user came from
// or fallback to defaultPath.
export function resolveRedirectTo(location, defaultPath) {
  const { state } = location;
  if (state && state.from && state.from.pathname && state.from.pathname !== '/login') {
    // Try to redirect to the previous page unless it is login page
    return [state.from.pathname, state.from.search];
  }

  return [defaultPath, location.search];
}

function RouteOrRedirect({
  component: Component,
  render,
  redirectTo,
  predicate,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props /* eslint-disable react/prop-types */) => {
        const [pathname, search] = redirectTo;
        if (predicate()) {
          if (typeof render === 'function') {
            return render(props);
          }

          return (
            <Component {...props} />
          );
        }

        if (props.location.pathname === pathname) {
          // Do not redirect to the same path
          return null;
        }

        return (
          <Redirect
            to={{
              state: { from: props.location },
              pathname,
              search,
            }}
          />
        );
      }}
    />
  );
}

RouteOrRedirect.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  redirectTo: PropTypes.arrayOf(PropTypes.string).isRequired,
  predicate: PropTypes.func.isRequired,
  render: PropTypes.func,
};

RouteOrRedirect.defaultProps = {
  render: null,
};

export default RouteOrRedirect;
