import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik, Form, Field, yupToFormErrors,
} from 'formik';
import {
  Yup, requiredText,
} from 'components/Formik/validation';

import { keepOnlyFields, isIndexOrderIdValid } from 'utils/forms';
import { userInfoSelector } from 'redux/modules/auth';
import * as orders from 'redux/modules/orders';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import Checkbox, { defaultOnChange as defaultCheckboxOnChange } from 'components/Formik/Checkbox';
import { defaultOnChange as defaultRadioOnChange } from 'components/Formik/RadioButton';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
} from 'utils/patientQuestions';
import { isSupportUser } from 'utils/orderRoles';
import OrderUserInfo from 'components/_CreateOrderProcess/OrderUserInfo';
import {
  isFVTOrderEligibleForFreeFVT,
  isIndexOrderEligibleForFreeFVT,
  hasIndexOrderFindings,
} from 'utils/fvtOrder';

import CompletedOrderCustomerInformation from './CompletedOrderCustomerInformation';
import AddVariants, { newEmptyVariant, variantsSchema } from './AddVariants';
import { resetOtherKits } from './OrderKit';
import useOrderInfoWithTargetVariantData from './useOrderInfoWithTargetVariantData';
import CommonSection, { getCommonSectionInitialValues, commonSectionValidationSchema, getCommonSectionDefaultValues } from './CommonSection';
import IndexOrderSection from './IndexOrderSection';
import IndexOrderOptions from './IndexOrderOptions';
import IndexOrderIdInput from './IndexOrderIdInput';

const indexPatientTestedAtBpgOptions = [{
  value: 'yes',
  label: 'Yes',
}, {
  value: 'no',
  label: 'No / Not known',
}];

// This validates only some of the fields. More complex logic is in validate method.
const validationSchema = Yup.object().shape({
  ...commonSectionValidationSchema,
  index_patient_tested_at_bpg: Yup.string()
    .oneOf(indexPatientTestedAtBpgOptions.map((option) => option.value), requiredText),
  variants: variantsSchema,
  // https://github.com/yannickcr/eslint-plugin-react/issues/2722
  /* eslint-disable react/forbid-prop-types  */
  index_variants: Yup.array(),
  /* eslint-enable react/forbid-prop-types */
});

function getInitialValues({
  userInfo,
  orderInfo,
  deliveryInfo,
  variants,
  sampleTypePanelQuestions,
}) {
  let testedAtBpg = '';
  switch (orderInfo.index_patient_tested_at_bpg) {
    case true:
      testedAtBpg = 'yes';
      break;
    case false:
      testedAtBpg = 'no';
      break;
    default:
  }

  const nonIndexVariants = variants.filter((v) => !v.index_variant);
  const indexVariants = variants.filter((v) => v.index_variant);

  return {
    ...getCommonSectionInitialValues({ orderInfo, userInfo, deliveryInfo }),

    index_patient_tested_at_bpg: testedAtBpg,
    index_order_id: orderInfo.index_order_id || '',
    index_order_id_not_known: orderInfo.index_order_id_not_known,
    index_patient_dob: orderInfo.index_patient_dob || '',
    index_patient_name: orderInfo.index_patient_name || '',

    variants: nonIndexVariants,
    index_variants: indexVariants,
    sample_type_questions: prepareQuestionsForForm(
      Object.values(sampleTypePanelQuestions).sort((a, b) => a.order_no - b.order_no),
    ),
  };
}

const FVTOrderTestFormOrderInfo = ({ hideModal }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector);
  const orderId = useSelector(orders.orderIdSelector);

  const [indexOrder, setIndexOrder] = useState(null);
  const [indexOrderError, setIndexOrderError] = useState(null);
  const [clickedConfirmOrderId, setClickedConfirmOrderId] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ...getCommonSectionDefaultValues(),
    variants: [],
    index_variants: [],
    sample_type_questions: [],
  });
  const [showAddVariantTable, setShowAddVariantTable] = useState(false);

  const {
    loading,
    error,
    orderInfo,
    deliveryInfo,
    variants,
    sampleTypePanelQuestions,
  } = useOrderInfoWithTargetVariantData(orderId);

  useEffect(() => {
    if (!orderInfo || !deliveryInfo) {
      return;
    }

    setInitialValues(getInitialValues({
      userInfo,
      orderInfo,
      deliveryInfo,
      variants,
      sampleTypePanelQuestions,
    }));
  }, [deliveryInfo, orderInfo, sampleTypePanelQuestions, userInfo, variants]);

  useEffect(() => {
    const fetchIndexOrder = async () => {
      try {
        const newIndexOrder = await dispatch(orders.getIndexOrder(orderInfo.index_order_id));

        setIndexOrder(newIndexOrder);
        setIndexOrderError(null);
        setShowAddVariantTable(!hasIndexOrderFindings(newIndexOrder));
      } catch (e) {
        setIndexOrder(null);
        setIndexOrderError(e.message);
      }
    };

    if (orderInfo && orderInfo.index_order_id) {
      fetchIndexOrder();
    } else {
      setShowAddVariantTable(true);
    }
  }, [dispatch, orderInfo]);

  const prepareFmtInfoPayload = (values) => {
    const fmtInfo = keepOnlyFields(['index_order_id_not_known'], values);

    switch (values.index_patient_tested_at_bpg) {
      case 'yes':
        fmtInfo.index_patient_tested_at_bpg = true;
        fmtInfo.index_patient_name = values.index_patient_name;
        fmtInfo.index_patient_dob = values.index_patient_dob;
        fmtInfo.index_order_id = values.index_order_id || null;

        if (indexOrder) {
          fmtInfo.index_patient_name = indexOrder.patient_name;
          fmtInfo.index_patient_dob = indexOrder.patient_dob;
          fmtInfo.index_order_id = indexOrder.order_id;
        }
        break;
      case 'no':
        fmtInfo.index_patient_tested_at_bpg = false;
        fmtInfo.index_order_id = null;
        fmtInfo.index_patient_name = '';
        fmtInfo.index_patient_dob = null;
        break;
      default:
    }

    return fmtInfo;
  };

  const handleSubmit = async (values, actions) => {
    actions.setFieldError('general', null);

    try {
      const payload = keepOnlyFields(['case_phone', 'order_kit'], values);
      payload.order_kit = resetOtherKits(payload.order_kit);
      await dispatch(orders.saveOrder(orderInfo.order_id, payload));
      await dispatch(orders.saveResultsDeliveryInfo(orderInfo.order_id, values.delivery_info));

      const fmtInfoPayload = prepareFmtInfoPayload(values);
      await dispatch(orders.saveFmtInfo(orderInfo.order_id, fmtInfoPayload));

      const variantPayload = values.variants.concat(values.index_variants);
      await dispatch(orders.saveTargetedVariants(orderInfo.order_id, variantPayload));

      if (values.sample_type_questions.length) {
        const answers = constructQuestionAnswers(values.sample_type_questions);
        await dispatch(orders.savePanelAnswers(orderInfo.order_id, answers, 3));
      }
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  const validate = async (values) => {
    const errors = {};

    const indexOrderDetailsMissing = values.index_patient_tested_at_bpg === 'yes'
      && !values.index_order_id_not_known
      && !values.index_order_id;

    const indexOrderWithoutVariants = values.index_patient_tested_at_bpg === 'yes'
      && !values.index_order_id_not_known
      && !!values.index_order_id
      && !values.index_variants.length;

    try {
      await validationSchema.validate(values, {
        abortEarly: false,
      });
    } catch (e) {
      const yupErrors = yupToFormErrors(e);
      Object.assign(errors, yupErrors);
    }

    if (values.index_patient_tested_at_bpg === 'yes' && values.index_order_id_not_known) {
      if (!values.index_patient_name) {
        errors.index_patient_name = requiredText;
      }

      if (!values.index_patient_dob) {
        errors.index_patient_dob = requiredText;
      }
    }

    if (indexOrderDetailsMissing || (indexOrderWithoutVariants && !values.variants.length)) {
      errors.index_patient_tested_at_bpg = '* Please confirm the index order ID below and select the correct variant(s) to be tested.';
    }

    if (!values.variants.length && !values.index_variants.length) {
      errors.index_variants = 'At least one variant must be selected';
    }

    return errors;
  };

  const resetIndexFields = (formProps) => {
    formProps.setFieldValue('index_variants', [], /* validate */ false);
    formProps.setFieldValue('index_patient_dob', '', /* validate */ false);
    formProps.setFieldValue('index_patient_name', '', /* validate */ false);

    formProps.validateForm();

    setIndexOrder(null);
  };

  const addFirstVariant = (formProps) => {
    if (!formProps.values.variants.length) {
      // If no variants exist then add variant
      formProps.setFieldValue('variants', formProps.values.variants.concat(newEmptyVariant()));
    }
  };

  const handleIndexOrderOptionChange = (formProps) => (form, field, e) => {
    defaultRadioOnChange(form, field, e);

    if (e.target.value === 'no') {
      // If 'No' selected then add variant
      addFirstVariant(formProps);
    }

    resetIndexFields(formProps);
  };

  const handleIndexOrderIdNotKnownCheckboxChange = (formProps) => (form, field, e) => {
    defaultCheckboxOnChange(form, field);
    formProps.setFieldValue('index_order_id', '');
    resetIndexFields(formProps);

    if (e.target.checked) {
      // If checked then add variant
      addFirstVariant(formProps);
    }
  };

  const handleSubmitIndexOrderId = (formProps) => async () => {
    const enteredOrderId = formProps.values.index_order_id;

    if (!isIndexOrderIdValid(enteredOrderId)) {
      return;
    }

    setClickedConfirmOrderId(true);

    formProps.setFieldValue('index_order_id_not_known', false);
    resetIndexFields(formProps);

    try {
      const newIndexOrder = await dispatch(orders.getIndexOrder(enteredOrderId));

      setIndexOrder(newIndexOrder);
      setIndexOrderError(null);

      if (!hasIndexOrderFindings(newIndexOrder)) {
        addFirstVariant(formProps);
        setShowAddVariantTable(true);
      } else {
        setShowAddVariantTable(false);
      }

      if (formProps.values.variants.length && hasIndexOrderFindings(newIndexOrder)) {
        // It could be that physician has checked "Index patient's order ID not known"
        // before confirming the index order ID. That would have inserted an empty
        // variant which we should remove.
        // Be aware that support can add variants all the time so we should remove
        // only empty variants.
        const nonEmptyVariants = formProps.values.variants.filter((v) => v.gene);
        formProps.setFieldValue('variants', nonEmptyVariants, false);
      }

      formProps.validateForm();
    } catch (e) {
      setIndexOrder(null);
      setIndexOrderError(e.message);

      formProps.setFieldValue('index_order_id_not_known', true);
      formProps.setFieldValue('index_order_id', '');
      addFirstVariant(formProps);
    }
  };

  const isEligibleForFreeFVT = () => !!(
    clickedConfirmOrderId
      ? isIndexOrderEligibleForFreeFVT(indexOrder)
      : isFVTOrderEligibleForFreeFVT(orderInfo)
  );

  const isSupport = isSupportUser(userInfo);
  const allowAddingManualVariants = isSupport || (orderInfo && !orderInfo.is_order_completed);

  const showPatientInfoInput = (formProps) => !!(
    formProps.values.index_order_id_not_known || indexOrderError
  );

  return (
    <Formik
      validate={validate}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form autoComplete="off">
          <PromptIfDirty {...formProps} />

          <EditOrderStep
            {...formProps}
            hideModal={hideModal}
            isFirstStep
            error={error || indexOrderError || formProps.errors.general}
            loading={loading}
            submit={() => formProps.submitForm()}
          >
            {orderInfo && (
              <div>
                <OrderUserInfo orderInfo={orderInfo} />

                {isSupport && orderInfo.is_order_completed && (
                  <CompletedOrderCustomerInformation orderInfo={orderInfo} />
                )}

                <IndexOrderOptions onChange={handleIndexOrderOptionChange(formProps)} />

                {formProps.values.index_patient_tested_at_bpg === 'yes' && (
                  <div>
                    <IndexOrderIdInput onSubmitIndexOrderId={handleSubmitIndexOrderId(formProps)} />

                    <Field
                      name="index_order_id_not_known"
                      component={Checkbox}
                      label="Index patient's order ID not known"
                      labelClassName="view_order_modal_label"
                      className="modal_checkbox"
                      onChange={handleIndexOrderIdNotKnownCheckboxChange(formProps)}
                    />

                    <IndexOrderSection
                      indexOrder={indexOrder}
                      isEligibleForFreeFVT={isEligibleForFreeFVT()}
                      showPatientInfoInput={showPatientInfoInput(formProps)}
                    />
                  </div>
                )}

                {allowAddingManualVariants && (
                  <AddVariants
                    orderInfo={orderInfo}
                    variants={formProps.values.variants}
                    isSubmitting={formProps.isSubmitting}
                    hint={
                      formProps.values.index_patient_tested_at_bpg === 'yes' && formProps.values.index_order_id_not_known
                        ? (
                          <span>
                            {'Start typing to search for genes. For mitochondrial genes, please contact '}
                            <a href="https://blueprintgenetics.com/contact/">Client Services.</a>
                          </span>
                        )
                        : null
                    }
                    allowEmpty={hasIndexOrderFindings(indexOrder)}
                    showAddVariantTable={showAddVariantTable}
                    setShowAddVariantTable={setShowAddVariantTable}
                  />
                )}

                <CommonSection orderInfo={orderInfo} />
              </div>
            )}
          </EditOrderStep>
        </Form>
      )}
    </Formik>
  );
};

FVTOrderTestFormOrderInfo.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default FVTOrderTestFormOrderInfo;
