import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { isAuthenticated } from 'redux/modules/auth';
import RouteOrRedirect, { resolveRedirectTo } from './RouteOrRedirect';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

function NotLoggedRoute(props) {
  const {
    path, location, user, ...rest
  } = props;
  const redirectTo = resolveRedirectTo(location, '/orders');

  return (
    <RouteOrRedirect
      {...rest}
      path={path}
      predicate={() => !isAuthenticated(user)}
      redirectTo={redirectTo}
    />
  );
}

NotLoggedRoute.propTypes = {
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  component: PropTypes.func,
  render: PropTypes.func,
  user: PropTypes.shape({
    auth_token_type: PropTypes.string,
  }),
  location: PropTypes.shape({}).isRequired,
};

NotLoggedRoute.defaultProps = {
  user: {},
  component: () => (<div>Unknown</div>),
  render: null,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotLoggedRoute);
