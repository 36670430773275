import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const EditLinkCell = ({ cellData }) => (
  <div>
    <Link
      to={`/questions/${cellData}`}
      className="link-pink"
    >
      Edit
    </Link>
  </div>
);

EditLinkCell.propTypes = {
  cellData: PropTypes.number.isRequired,
};

export default EditLinkCell;
