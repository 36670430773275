import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportPerformanceMetrics extends Component {
  static propTypes = {
    title: PropTypes.string,
    performanceMetrics: PropTypes.object,
    reportVersion: PropTypes.number,
  }


  render() {
    const {title, performanceMetrics, reportVersion} = this.props;
    const styles = require('./_OrderReportPerformanceMetrics.scss');

    return (
      <div>
        <h3 className="h4">{title}</h3>
        <table className="table">
          <thead>
            {reportVersion <= 6 &&
              <tr className={styles.report_heading_row}>
                <th>Panel</th>
                <th>Genes</th>
                <th>Exons</th>
                <th>Bases</th>
                <th>Bases > 15X</th>
                <th>Median coverage</th>
                <th>Percent > 15X</th>
              </tr>
            }
            {reportVersion > 6 &&
              <tr className={styles.report_heading_row}>
                <th>Panel</th>
                <th>Genes</th>
                <th>Exons</th>
                <th>Bases</th>
                <th>Bases > 20X</th>
                <th>Median coverage</th>
                <th>Percent > 20X</th>
              </tr>
            }
          </thead>
          <tbody>
            {reportVersion <= 7 &&
              <tr>
                <td>{performanceMetrics.panel_name}</td>
                <td>{performanceMetrics.number_genes}</td>
                <td>{performanceMetrics.number_exons}</td>
                <td>{performanceMetrics.number_bases}</td>
                <td>{performanceMetrics.bases_covered}</td>
                <td>{performanceMetrics.median_coverage}</td>
                <td>{performanceMetrics.percent_over_cutoff}</td>
              </tr>
            }
            {reportVersion >=8 && Object.keys(performanceMetrics).map(key => {
              const performanceMetric = performanceMetrics[key];
              return (
                <tr key={key}>
                  <td>{performanceMetric.panel_name}</td>
                  <td>{performanceMetric.number_genes}</td>
                  <td>{performanceMetric.number_exons}</td>
                  <td>{performanceMetric.number_bases}</td>
                  <td>{performanceMetric.bases_covered}</td>
                  <td>{performanceMetric.median_coverage}</td>
                  <td>{performanceMetric.percent_over_cutoff}</td>
                </tr>
              ); 
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
