import React from 'react';
import PropTypes from 'prop-types';

const LabelCheckbox = ({
  id, checked, label, disabled, onChange, hint, name, value,
}) => (
  <div className="form-check checkbox modal_checkbox">
    <input
      type="checkbox"
      id={id}
      className="form-check-input"
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      name={name}
      value={value}
    />
    <label htmlFor={id} className="view_order_modal_label form-check-label">
      {label}
      {hint && <p className="small">{hint}</p>}
    </label>
  </div>
);

LabelCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
};

LabelCheckbox.defaultProps = {
  disabled: false,
  hint: undefined,
  onChange: undefined,
  name: undefined,
  value: undefined,
};

export default LabelCheckbox;
