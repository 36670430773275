const mapHospital = (hospital, countries) => {
  const { name: countryName } = countries[hospital.country_id] || {};

  return {
    hospitalName: hospital.hospital_name,
    hospitalBusinessId: hospital.business_id,
    hospitalAddress: {
      line1: hospital.address_line_1 || null,
      line2: hospital.address_line_2 || null,
      city: hospital.city || null,
    },
    hospitalZip: hospital.zip,
    hospitalCountry: countryName,
    hospitalUserCount: hospital.user_count,
    hospitalOrderCount: hospital.order_count,
    editKey: { edit: hospital.hospital_id },
    productKey: { product: hospital.hospital_id },
    id: hospital.hospital_id,
  };
};

export default mapHospital;
