import PropTypes from 'prop-types';

export const OrderTestModalStaticProps = {
  closeOrder: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  orderInfo: PropTypes.shape({
    ext_panel_id: PropTypes.string,
    order_id: PropTypes.number.isRequired,
    special_type: PropTypes.string,
    patient_dob: PropTypes.string,
    patient_last_name: PropTypes.string,
    patient_first_name: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      code: PropTypes.string,
      is_new: PropTypes.bool,
      is_updated: PropTypes.bool,
      panel_tier_id: PropTypes.number,
      version: PropTypes.number.isRequired,
      last_updated: PropTypes.string.isRequired,
      prince_id: PropTypes.number,
    }).isRequired,
    is_customized: PropTypes.bool,
    is_order_completed: PropTypes.bool,
    status: PropTypes.string,
  }),
  error: PropTypes.string,
  isInternalUser: PropTypes.bool,
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
  }).isRequired,
  resetOrderInfo: PropTypes.func.isRequired,
  resetPanelVersionInformation: PropTypes.func.isRequired,
  consentPopupModal: PropTypes.func.isRequired,
  getPanelGeneLists: PropTypes.func.isRequired,
  getPanelTiers: PropTypes.func.isRequired,
  getPanelVersionInformation: PropTypes.func.isRequired,
  panelTiers: PropTypes.arrayOf(PropTypes.shape({
    panel_tier_id: PropTypes.number,
    size: PropTypes.string,
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    isActive: PropTypes.bool,
  })),
  panelVersionInformation: PropTypes.shape({
    version: PropTypes.number,
    last_updated: PropTypes.string,
  }),
  currentPanelTierId: PropTypes.number,
  currentGeneCount: PropTypes.number,
  setCurrentPanelTierId: PropTypes.func.isRequired,
  setCurrentGeneCount: PropTypes.func.isRequired,
};

export const OrderTestModalDefaultProps = {
  currentStep: null,
  orderInfo: null,
  error: null,
  panelTiers: null,
  currentPanelTierId: null,
  currentGeneCount: null,
  isInternalUser: false,
  panelVersionInformation: null,
};

export const OrderTestModalHeaderProps = {
  dob: PropTypes.string,
  geneCountText: PropTypes.number,
  getPanelHeadingContent: PropTypes.func,
  getPanelVersionInfo: PropTypes.func,
  isInternalUser: PropTypes.bool,
  isOrderCompleted: PropTypes.bool,
  isPanelNew: PropTypes.bool,
  isPanelUpdated: PropTypes.bool,
  orderId: PropTypes.number,
  orderIsCompleted: PropTypes.bool,
  panelTierText: PropTypes.string,
  patientFirstName: PropTypes.string,
  patientInfo: PropTypes.string,
  patientLastName: PropTypes.string,
  status: PropTypes.string,
  userRole: PropTypes.string,
};
