import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './_OrderReportAppendixSequencingCoverage.scss';

export default class OrderReportAppendixSequencingCoverage extends Component {
  static propTypes = {
    coverageFile: PropTypes.object,
    orderReport: PropTypes.object,
    appendixSequencingCoverageShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    isEmpty: PropTypes.func,
    appendixHeading: PropTypes.string,
    reportVersion: PropTypes.number,
  }

  renderCoverageRegions = (insufficientlyCoveredRegions, reportVersion, isMito = false) => (
    <div>
      {reportVersion <= 6 &&
        <h3 className="h4">{'Regions covered with < 15X'}</h3>
      }
      {(reportVersion > 6 && !isMito) &&
        <h3 className="h4">{'Regions covered with < 20X'}</h3>
      }
      {isMito &&
        <h3 className="h4">{'Regions covered with < 1000X'}</h3>
      }
      <table className="table">
        <thead>
          <tr className={styles.report_heading_row}>
            <th>Chromosome</th>
            <th>Start</th>
            <th>End</th>
            <th>Size</th>
            <th>Gene</th>
            <th>Exon</th>
            <th>Min coverage</th>
            <th>Median coverage</th>
            <th>Max coverage</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(insufficientlyCoveredRegions).map(key =>
              <tr key={key}>
                <td>{insufficientlyCoveredRegions[key].chromosome}</td>
                <td>{insufficientlyCoveredRegions[key].start}</td>
                <td>{insufficientlyCoveredRegions[key].end}</td>
                <td>{insufficientlyCoveredRegions[key].size}</td>
                <td>{insufficientlyCoveredRegions[key].gene}</td>
                <td>{insufficientlyCoveredRegions[key].exon}</td>
                <td>{insufficientlyCoveredRegions[key].minimum}</td>
                <td>{insufficientlyCoveredRegions[key].median}</td>
                <td>{insufficientlyCoveredRegions[key].maximum}</td>
              </tr>
          )}
        </tbody>
      </table>
    </div>
  )

  render() {
    const {appendixHeading,
          coverageFile,
          mitoCoverageFile,
          orderReport,
          appendixSequencingCoverageShown,
          onTogglePanel,
          isEmpty,
          reportVersion} = this.props;

    const {
      insufficiently_covered_regions: insufficientlyCoveredRegions,
      insufficiently_covered_regions_mito: insufficientlyCoveredRegionsMito
    } = orderReport;

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixSequencingCoverageShown')}>
          {appendixHeading}
          {appendixSequencingCoverageShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixSequencingCoverageShown ? '' : 'invisible'}>
          {coverageFile &&
            <div>
              <h3 className="h4">Coverage plot - Nuclear genes</h3>
              <img className={styles.coverImage} src={'data:' + coverageFile.mime_type + ';base64,' + coverageFile.data} />
            </div>
          }

          {mitoCoverageFile &&
            <div>
              <h3 className="h4">Coverage plot- Mitochondrial genes</h3>
              <img className={styles.coverImage} src={'data:' + mitoCoverageFile.mime_type + ';base64,' + mitoCoverageFile .data} />
            </div>
          }

          {insufficientlyCoveredRegions &&
           !isEmpty(insufficientlyCoveredRegions) &&
           this.renderCoverageRegions(insufficientlyCoveredRegions, reportVersion)
          }

          {insufficientlyCoveredRegionsMito &&
           !isEmpty(insufficientlyCoveredRegionsMito) &&
           this.renderCoverageRegions(insufficientlyCoveredRegionsMito, reportVersion, true)
          }
        </div>
      </div>
    );
  }
}
