import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';

const OnHoldReasonsSelect = ({
  handleInputValue, onChangeValue, onHoldReasonOptions, reason, resultsReported,
}) => (
  <LabelSelect
    inputSize="6"
    label="On hold reason"
    labelSize="6"
    name="printer_name_2"
    onChange={(event) => onChangeValue('reason', handleInputValue(event))}
    options={onHoldReasonOptions}
    placeholder="Select on hold reason..."
    readOnly={resultsReported}
    value={reason}
  />
);

OnHoldReasonsSelect.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onHoldReasonOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  reason: PropTypes.string.isRequired,
  resultsReported: PropTypes.bool.isRequired,
};

export default OnHoldReasonsSelect;
