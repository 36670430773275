/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { isInternalRole, isSupportUser } from 'utils/orderRoles';

const RESET_2FA = 'auth/RESET_2FA';
const INIT_2FA = 'auth/INIT_2FA';
const INIT_2FA_SUCCESS = 'auth/INIT_2FA_SUCCESS';
const INIT_2FA_FAIL = 'auth/INIT_2FA_FAIL';
const GET_2FA = 'auth/GET_2FA';
const GET_2FA_SUCCESS = 'auth/GET_2FA_SUCCESS';
const GET_2FA_FAIL = 'auth/GET_2FA_FAIL';
const CONFIRM_ENABLE_2FA = 'auth/CONFIRM_ENABLE_2FA';
const CONFIRM_ENABLE_2FA_SUCCESS = 'auth/CONFIRM_ENABLE_2FA_SUCCESS';
const CONFIRM_ENABLE_2FA_FAIL = 'auth/CONFIRM_ENABLE_2FA_FAIL';
const DISABLE_2FA = 'auth/DISABLE_2FA';
const DISABLE_2FA_SUCCESS = 'auth/DISABLE_2FA_SUCCESS';
const DISABLE_2FA_FAIL = 'auth/DISABLE_2FA_FAIL';
const CONFIRM_DISABLE_2FA = 'auth/CONFIRM_DISABLE_2FA';
const CONFIRM_DISABLE_2FA_SUCCESS = 'auth/CONFIRM_DISABLE_2FA_SUCCESS';
const CONFIRM_DISABLE_2FA_FAIL = 'auth/CONFIRM_DISABLE_2FA_FAIL';
const LOGOUT = 'auth/LOGOUT';
const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';
const REQUEST_SIGNUP_SUCCESS = 'auth/REQUEST_SIGNUP_SUCCESS';
const REQUEST_SIGNUP_FAIL = 'auth/REQUEST_SIGNUP_FAIL';
const GET_PROFILE_INFO = 'auth/GET_PROFILE_INFO';
const GET_PROFILE_INFO_SUCCESS = 'auth/GET_PROFILE_INFO_SUCCESS';
const GET_PROFILE_INFO_FAIL = 'auth/GET_PROFILE_INFO_FAIL';
const SAVE_USER_INFO = 'auth/SAVE_USER_INFO';
const SAVE_USER_INFO_SUCCESS = 'auth/SAVE_USER_INFO_SUCCESS';
const SAVE_USER_INFO_FAIL = 'auth/SAVE_USER_INFO_FAIL';
const CONFIRM_SIGNUP_SUCCESS = 'auth/CONFIRM_SIGNUP_SUCCESS';
const CONFIRM_SIGNUP_FAIL = 'auth/CONFIRM_SIGNUP_FAIL';
const GET_ALIAS_USERS = 'auth/GET_ALIAS_USERS';
const GET_ALIAS_USERS_SUCCESS = 'auth/GET_ALIAS_USERS_SUCCESS';
const GET_ALIAS_USERS_FAIL = 'auth/GET_ALIAS_USERS_FAIL';
const SET_ALIAS_USER_ID = 'auth/SET_ALIAS_USER_ID';
const CHANGE_PASSWORD_SIMPLE = 'auth/CHANGE_PASSWORD_SIMPLE';
const CHANGE_PASSWORD_SIMPLE_SUCCESS = 'auth/CHANGE_PASSWORD_SIMPLE_SUCCESS';
const CHANGE_PASSWORD_SIMPLE_FAIL = 'auth/CHANGE_PASSWORD_SIMPLE_FAIL';
const SET_OPT_IN_INITIAL_VALUES = 'auth/SET_OPT_IN_INITIAL_VALUES';
const SET_USER_OPT_IN_INITIAL_VALUES = 'auth/SET_USER_OPT_IN_INITIAL_VALUES';
const SET_USER = 'auth/SET_USER';
const SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN';
const GET_REGION_ACCESS = 'auth/GET_REGION_ACCESS';
const GET_REGION_ACCESS_SUCCESS = 'auth/GET_REGION_ACCESS_SUCCESS';
const GET_REGION_ACCESS_FAIL = 'auth/GET_REGION_ACCESS_FAIL';
export const SESSION_EXPIRED = 'auth/SESSION_EXPIRED';

const initialState = {
  loaded: false,
  aliasUserId: '',
  error: null,
  sessionExpired: false,
  has_region_access: false,
};

export function shouldUseAlbAuth() {
  return document.cookie.includes('BpgNucleusAlbAuthEnabled=true');
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        sessionExpired: false,
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        user: null,
        sessionExpired: false,
      };
    case RESET_2FA:
      return {
        ...state,
        get2faPending: false,
        get2faSuccess: false,
        get2faError: null,
        authenticationKey: null,
        qrCodeData: null,
        init2faPending: false,
        init2faError: null,
        init2faSuccess: false,
        confirmEnable2faPending: false,
        confirmEnable2faError: null,
        confirmEnable2faSuccess: false,
        disable2faPending: false,
        disable2faError: null,
        disable2faSuccess: false,
      };
    case GET_2FA:
      return {
        ...state,
        get2faPending: true,
        get2faSuccess: false,
        authenticationKey: null,
        qrCodeData: null,
      };
    case GET_2FA_SUCCESS:
      return {
        ...state,
        get2faPending: false,
        get2faSuccess: true,
        authenticationKey: action.result.authentication_key,
        qrCodeData: action.result.qr_code_data,
      };
    case GET_2FA_FAIL:
      return {
        ...state,
        get2faPending: false,
        get2faError: action.error,
      };
    case INIT_2FA:
      return {
        ...state,
        init2faPending: true,
        init2faSuccess: false,
      };
    case INIT_2FA_SUCCESS:
      return {
        ...state,
        init2faPending: false,
        init2faSuccess: true,
        init2faError: null,
        init2faEmail: action.result.email,
      };
    case INIT_2FA_FAIL:
      return {
        ...state,
        init2faPending: false,
        init2faError: action.error,
      };
    case CONFIRM_ENABLE_2FA:
      return {
        ...state,
        confirmEnable2faPending: true,
        confirmEnable2faSuccess: false,
      };
    case CONFIRM_ENABLE_2FA_SUCCESS:
      return {
        ...state,
        confirmEnable2faPending: false,
        confirmEnable2faSuccess: true,
        confirmEnable2faError: null,
      };
    case CONFIRM_ENABLE_2FA_FAIL:
      return {
        ...state,
        confirmEnable2faPending: false,
        confirmEnable2faError: action.error,
      };
    case DISABLE_2FA:
      return {
        ...state,
        disable2faPending: true,
        disable2faSuccess: false,
      };
    case DISABLE_2FA_SUCCESS:
      return {
        ...state,
        disable2faPending: false,
        disable2faSuccess: true,
        disable2faError: null,
        disable2faEmail: action.result.email,
      };
    case DISABLE_2FA_FAIL:
      return {
        ...state,
        disable2faPending: false,
        disable2faError: action.error,
      };
    case CONFIRM_DISABLE_2FA:
      return {
        ...state,
        comfirmDisable2faPending: true,
        confirmDisable2faSuccess: false,
      };
    case CONFIRM_DISABLE_2FA_SUCCESS:
      return {
        ...state,
        comfirmDisable2faPending: false,
        confirmDisable2faSuccess: true,
        confirmDisable2faError: null,
      };
    case CONFIRM_DISABLE_2FA_FAIL:
      return {
        ...state,
        comfirmDisable2faPending: false,
        confirmDisable2faError: action.error,
      };
    case REQUEST_SIGNUP_SUCCESS:
      return {
        ...state,
        message: action.result,
      };
    case REQUEST_SIGNUP_FAIL:
      return {
        ...state,
      };
    case SAVE_USER_INFO:
      return {
        ...state,
        savingUserInfo: true,
        savedUserInfo: false,
      };
    case SAVE_USER_INFO_SUCCESS:
      return {
        ...state,
        savingUserInfo: false,
        savedUserInfo: true,
        message: action.result,
      };
    case SAVE_USER_INFO_FAIL:
      return {
        ...state,
        savingUserInfo: false,
        savedUserInfo: false,
        error: action.error,
      };
    case GET_PROFILE_INFO:
      return {
        ...state,
        gettingProfileInfo: true,
        receivedProfileInfo: false,
      };
    case GET_PROFILE_INFO_SUCCESS: {
      const newState = {
        ...state,
        gettingProfileInfo: false,
        receivedProfileInfo: true,
        userInfo: action.result,
      };

      if (shouldUseAlbAuth()) {
        const user = {
          user_role: action.result.user_role,
          user_name: action.result.user_name,
          hospital_name: action.result.hospital_name,
          email: action.result.user_email,
        };

        localStorage.setItem('user', JSON.stringify(user));

        return {
          ...newState,
          user,
          new_feature_message: action.result.new_feature_message,
          long_term_message_for_us_and_latam: action.result.long_term_message_for_us_and_latam,
        };
      }

      return newState;
    }
    case GET_PROFILE_INFO_FAIL:
      return {
        ...state,
        gettingProfileInfo: false,
        receivedProfileInfo: false,
        error: action.error,
      };
    case CONFIRM_SIGNUP_SUCCESS:
      return {
        ...state,
        confirmingSignup: false,
        message: action.result,
      };
    case CONFIRM_SIGNUP_FAIL:
      return {
        ...state,
        confirmingSignup: false,
        error: action.error,
      };
    case GET_ALIAS_USERS:
      return {
        ...state,
        gettingAliasUsers: true,
      };
    case GET_ALIAS_USERS_SUCCESS:
      return {
        ...state,
        gettingAliasUsers: false,
        aliasUsers: action.result,
      };
    case GET_ALIAS_USERS_FAIL:
      return {
        ...state,
        gettingAliasUsers: false,
        error: action.error,
      };
    case SET_ALIAS_USER_ID:
      return {
        ...state,
        aliasUserId: action.aliasUserId,
      };
    case CHANGE_PASSWORD_SIMPLE:
      return {
        ...state,
        changingPassword: true,
        changedPassword: false,
      };
    case CHANGE_PASSWORD_SIMPLE_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changedPassword: true,
        message: action.message,
      };
    case CHANGE_PASSWORD_SIMPLE_FAIL:
      return {
        ...state,
        changingPassword: false,
        changedPassword: false,
        error: action.error,
      };
    case SET_OPT_IN_INITIAL_VALUES:
      return {
        ...state,
        optInInitialValues: action.optInInitialValues,
      };
    case SET_USER_OPT_IN_INITIAL_VALUES:
      return {
        ...state,
        userOptInInitialValues: action.userOptInInitialValues,
      };
    case SET_USER:
      return {
        ...state,
        user: action.user,
        auth_token: null,
        new_feature_message: action.user.new_feature_message,
        long_term_message_for_us_and_latam: action.user.long_term_message_for_us_and_latam,
      };
    case SET_AUTH_TOKEN:
      return {
        ...state,
        auth_token: action.authToken,
      };
    case SESSION_EXPIRED:
      return {
        ...state,
        sessionExpired: true,
      };
    case GET_REGION_ACCESS_SUCCESS:
      return {
        ...state,
        hasRegionAccess: action.result.has_region_access,
      };
    case GET_REGION_ACCESS_FAIL:
      return {
        ...state,
        hasRegionAccess: false,
      };
    default:
      return state;
  }
}

export function isAuthenticated(user) {
  if (!user) {
    return false;
  }

  return (shouldUseAlbAuth() && user.email)
    || user.auth_token_type === 'login';
}

export function requestPasswordChange(data) {
  return {
    request: (client) => client.post('change_password', data),
  };
}

// data = {email, password, password_confirmation, auth_token}
// the auth token is a parameter in the url
export function confirmPasswordChange(data) {
  return {
    request: (client) => client.post('confirm_change_password', data),
  };
}

export function signup(data) {
  return {
    request: (client, dispatch) => client
      .post('signup', data)
      .then((result) => {
        dispatch({
          type: REQUEST_SIGNUP_SUCCESS,
          result,
        });
        return result;
      })
      .catch((e) => {
        dispatch({
          type: REQUEST_SIGNUP_FAIL,
        });
        throw e;
      }),
  };
}

export function setAuthToken(authToken) {
  return {
    type: SET_AUTH_TOKEN,
    authToken,
  };
}

export function login(params) {
  return {
    request: (client, dispatch) => client
      .post('login', params)
      .then((user) => {
        dispatch(setAuthToken(user.auth_token));
        return user;
      }),
  };
}

export function login2fa(code) {
  return {
    request: (client) => client.post('login_2fa', {
      code,
    }),
  };
}

export function reset2fa() {
  return {
    type: RESET_2FA,
  };
}

export function init2fa() {
  return {
    types: [INIT_2FA, INIT_2FA_SUCCESS, INIT_2FA_FAIL],
    request: (client) => client.post('init_2fa'),
  };
}

export function get2fa(data) {
  return {
    types: [GET_2FA, GET_2FA_SUCCESS, GET_2FA_FAIL],
    request: (client) => client.post('get_2fa', data),
  };
}

export function confirmEnable2fa(data) {
  return {
    types: [
      CONFIRM_ENABLE_2FA,
      CONFIRM_ENABLE_2FA_SUCCESS,
      CONFIRM_ENABLE_2FA_FAIL,
    ],
    request: (client) => client.post('confirm_enable_2fa', data),
  };
}

export function disable2fa(data) {
  return {
    types: [DISABLE_2FA, DISABLE_2FA_SUCCESS, DISABLE_2FA_FAIL],
    request: (client) => client.post('disable_2fa', data),
  };
}

export function confirmDisable2fa(data) {
  return {
    types: [
      CONFIRM_DISABLE_2FA,
      CONFIRM_DISABLE_2FA_SUCCESS,
      CONFIRM_DISABLE_2FA_FAIL,
    ],
    request: (client) => client.post('confirm_disable_2fa', data),
  };
}

export function getProfileInfo() {
  return {
    types: [GET_PROFILE_INFO, GET_PROFILE_INFO_SUCCESS, GET_PROFILE_INFO_FAIL],
    request: (client) => client.get('get_profile_info'),
  };
}

export function saveUserInfo(data) {
  return {
    types: [SAVE_USER_INFO, SAVE_USER_INFO_SUCCESS, SAVE_USER_INFO_FAIL],
    request: (client) => client.post('save_profile_info', data),
  };
}

export function logout() {
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);

  return {
    types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
    request: (client) => client.post('logout', {
      email: user && user.email,
      token: user && user.auth_token,
    }),
  };
}

export function albLogout() {
  const logoutUrl = new URL(process.env.LOGOUT_URL);
  logoutUrl.searchParams.append('client_id', process.env.USER_POOL_CLIENT_ID);
  logoutUrl.searchParams.append('logout_uri', process.env.LOGOUT_REDIRECT_URL);

  document.location = logoutUrl;
}

// params for confirming signup
// auth_token (string) - Authentication token (see signup)
export function confirmSignup(data) {
  return {
    request: (client, dispatch) => client
      .post('confirm_signup', data)
      .then((result) => {
        dispatch({
          type: CONFIRM_SIGNUP_SUCCESS,
          result,
        });
        return result;
      })
      .catch((e) => {
        dispatch({
          type: CONFIRM_SIGNUP_FAIL,
        });
        throw e;
      }),
  };
}

export function getAliasUsers() {
  return {
    types: [GET_ALIAS_USERS, GET_ALIAS_USERS_SUCCESS, GET_ALIAS_USERS_FAIL],
    request: (client) => client.get('get_alias_users'),
  };
}

export function getHospitalUsers(productId) {
  return {
    request: (client) => client.get('get_hospital_users', {
      product_id: productId,
    }).then((res) => res.users),
  };
}

export function setAliasUserId(id) {
  return {
    type: SET_ALIAS_USER_ID,
    aliasUserId: id,
  };
}

// Params for simple password change api call
// {
//   "password": String,
//   "password_repeat": String,
//   "old_password": "String"
// }
export function changePasswordSimple(data) {
  return {
    request: (client) => client.post('change_password_simple', data),
  };
}

export function setOptInInitialValues(valuesObj) {
  return {
    type: SET_OPT_IN_INITIAL_VALUES,
    optInInitialValues: valuesObj,
  };
}

export function setUserOptInInitialValues(valuesObj) {
  return {
    type: SET_USER_OPT_IN_INITIAL_VALUES,
    userOptInInitialValues: valuesObj,
  };
}

export function disableUserNotifications(data) {
  return {
    request: (client) => client.post('disable_user_notifications', data),
  };
}

export function getUserNotifications(userEmail) {
  return {
    request: (client) => client.post('get_user_notifications', {
      user_email: userEmail,
    }),
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function getRegionAccess() {
  return {
    types: [GET_REGION_ACCESS, GET_REGION_ACCESS_SUCCESS, GET_REGION_ACCESS_FAIL],
    request: (client) => client.get('get_region_access'),
  };
}

export const userSelector = (state) => state.auth.user;
export const userInfoSelector = (state) => state.auth.userInfo;
export const userRoleSelector = (state) => userInfoSelector(state).user_role;
export const userHospitalNameSelector = (state) => userInfoSelector(state).hospital_name;
export const userProvinceSelector = (state) => userInfoSelector(state).province_id;
export const userCountryIdSelector = (state) => userInfoSelector(state).country_id;
export const isInternalUserSelector = (state) => isInternalRole(userRoleSelector(state));
export const isSupportUserSelector = (state) => isSupportUser(userInfoSelector(state));
export const aliasUsersSelector = (state) => state.auth.aliasUsers;
export const aliasUserIdSelector = (state) => state.auth.aliasUserId;
