import { validateWithContext } from 'utils/forms';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  hospital_id: Yup.number().required('Hospital is required'),
  department: Yup.string().nullable(),
  role: Yup.string().required('Role is required'),
  title: Yup.string().nullable(),
  full_name: Yup.string().nullable(),
  allow_order_sharing: Yup.bool(),
  has_eea_rights: Yup.bool(),
  is_super_user: Yup.bool(),
  // https://github.com/yannickcr/eslint-plugin-react/issues/2722
  /* eslint-disable react/forbid-prop-types  */
  allowed_panel_ids: Yup.array(),
  /* eslint-enable react/forbid-prop-types */
  is_authorized_provider: Yup.bool(),
  npi: Yup.string().nullable().when(['is_authorized_provider', 'hospital_id', '$usHospitalIds'], {
    is: (isAuthorizedProvider, hospitalId, usHospitalIds) => usHospitalIds.includes(hospitalId)
      && isAuthorizedProvider,
    then: Yup.string().required('NPI is required for authorized providers'),
  }),
});

export const validateUserForm = (usHospitalIds) => validateWithContext(
  validationSchema,
  { usHospitalIds },
);

export const isUserFormValid = (values, usHospitalIds) => validationSchema.isValidSync(
  values,
  { context: { usHospitalIds } },
);
