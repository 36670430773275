import React, {Component} from 'react';
import PropTypes from 'prop-types';

// TODO: remove after redux-form is no longer used

export default class ArrayCheckbox extends Component {
  static propTypes = {
    className: PropTypes.string,
    givenValue: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
  };

  handleChange = (event) => {
    const { givenValue, ...props } = this.props;
    if (props.value !== undefined && props.value.length > 0) {
      if (event.target.checked) {
        props.onChange(props.value.concat([givenValue]));
      } else {
        const valuesCopy = [...props.value];
        const valueIndex = props.value.indexOf(givenValue);
        valuesCopy.splice(valueIndex, 1);
        props.onChange(valuesCopy);
      }
    } else {
      if (event.target.checked) {
        const valuesArray = [];
        valuesArray.push(givenValue);
        props.onChange(valuesArray);
      }
    }
  }

  render() {
    const {className, label, givenValue, id, disabled, readOnly, ...props} = this.props;
    return (
      <div className={'form-check checkbox ' + className + (readOnly ? ' readonly' : '')}>
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          {...props}
          checked={props.value.indexOf(givenValue) >= 0}
          onChange={readOnly ? undefined : this.handleChange}
          disabled={disabled}
          readOnly={readOnly}
          />
        <label htmlFor={id} className="form-check-label">
          {label}
        </label>
      </div>
    );
  }
}
