import React from 'react';

const IntroVideo = () => (
  <div className="col-lg-7 col-xl-8 order-lg-1">
    <h1>Introduction</h1>
    <div className="video-container-wrap">
      <div className="video-container">
        <iframe
          src="https://player.vimeo.com/video/788928319?color=44697d&title=0&byline=0&portrait=0"
          width="640"
          height="360"
          frameBorder="0"
          allowFullScreen
          title="video"
        />
      </div>
    </div>
  </div>
);

export default IntroVideo;
