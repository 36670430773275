import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import * as orders from 'redux/modules/orders';
import { userInfoSelector } from 'redux/modules/auth';

import { Yup, patientQuestions } from 'components/Formik/validation';
import { getPanelTierNumber } from 'utils/panelTiers';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import {
  constructQuestionAnswers,
  prepareQuestionsForForm,
} from 'utils/patientQuestions';
import ConfirmOrderBody from './ConfirmOrderBody';

const validationSchema = Yup.object().shape({
  // Require confirmation checkbox only when order can be submitted
  confirm_order: Yup.boolean().when(
    ['is_order_completed', 'is_support_user', 'is_novodetect_product'],
    {
      is: (isOrderCompleted, isSupportUser, isNovodetect) => {
        if (isSupportUser) return false;
        if (isNovodetect) return false;
        return !isOrderCompleted;
      },
      then: Yup.boolean().oneOf(
        [true],
        'This needs to be checked to proceed.',
      ),
      otherwise: Yup.boolean(),
    },
  ),
  internal_comments: Yup.string().nullable(),
});

const validate = (values) => {
  const errors = {};
  const questionErrors = patientQuestions(values.questions, { requireAllCheckboxes: true });
  if (questionErrors.length) {
    errors.questions = questionErrors;
  }

  return errors;
};

const OrderTestFormConfirmOrder = ({ hideModal }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const orderId = useSelector(orders.orderIdSelector);
  const panelTiers = useSelector(orders.panelTiersSelector);
  const currentGeneCount = useSelector(orders.currentGeneCountSelector);

  const [loading, setLoading] = useState(true);
  const [isSavingOrderCheckedStatus, setSavingOrderCheckedStatus] = useState(false);
  const [error, setError] = useState(null);
  const [skipFields, setSkipFields] = useState({});
  const [orderInfo, setOrderInfo] = useState(null);
  const [logs, setLogs] = useState({});
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [questions, setQuestions] = useState([]);

  const isSupportUser = userInfo.user_role === 'support';
  const [initialValues, setInitialValues] = useState({
    confirm_order: false,
    is_order_completed: false,
    is_support_user: isSupportUser,
    is_novodetect_product: false,
    questions: [],
  });

  const areInternalCommentsShown = userInfo.user_role === 'support'
    || userInfo.user_role === 'geneticist';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [newOrderInfo, newLogs, newDeliveryInfo, panelQuestions] = await Promise.all([
          dispatch(orders.getOrder(orderId, { get_custom_panel_info: true })),
          dispatch(orders.getOrderLogs(orderId)),
          dispatch(orders.getResultsDeliveryInfo(orderId)),
          dispatch(orders.getPanelQuestions(orderId, 4)),
        ]);
        const panelQuestionsOrdered = prepareQuestionsForForm(
          Object.values(panelQuestions)
            .sort((a, b) => a.order_no - b.order_no),
        );
        const miscFields = newOrderInfo.skip_misc_fields || [];
        const newSkipFields = miscFields.reduce((acc, field) => ({ ...acc, [field]: true }), {});
        setLoading(false);
        setOrderInfo(newOrderInfo);
        setLogs(newLogs);
        setDeliveryInfo(newDeliveryInfo);
        setSkipFields(newSkipFields);
        setQuestions(panelQuestionsOrdered);
        setInitialValues((values) => ({
          ...values,
          is_novodetect_product: newOrderInfo.product.is_novodetect_product,
          questions: panelQuestionsOrdered,
        }));
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    };

    setLoading(true);
    setError(null);
    fetchData();
  }, [dispatch, orderId]);

  /**
   * Saves the form without finishing the order
   */
  const save = (values) => {
    if (areInternalCommentsShown) {
      return dispatch(orders.saveInternalComments(orderInfo.order_id, values.internal_comments || ''));
    }

    return Promise.resolve();
  };

  /**
   * Saves the form and finishes the order.
   */
  const saveAndFinishOrder = async (values) => {
    await save(values);

    if (!orderInfo.is_order_completed) {
      await dispatch(orders.finishOrder(orderInfo.order_id));
    }
  };

  const wrapSubmit = async (submit, values, actions) => {
    actions.setFieldError('general', null);

    try {
      await submit(values);
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  const handleCheckOrderButtonClick = async () => {
    setSavingOrderCheckedStatus(true);

    try {
      await dispatch(orders.checkOrder(orderId));
      hideModal();
    } catch (e) {
      setError('Failed to check order');
      setSavingOrderCheckedStatus(false);
    }
  };

  const handleEditOrderStepSubmit = (formProps) => async (action, currentStep, step) => {
    // If saving as draft don not complete the order
    let onlySave = action === 'save';
    // If going back do not complete the order
    if (step < currentStep) {
      onlySave = true;
    }

    if (onlySave) {
      // Only save the form
      return wrapSubmit(save, formProps.values, formProps);
    }

    const answers = constructQuestionAnswers(
      formProps.values.questions,
    );
    await dispatch(orders.savePanelAnswers(orderId, answers, 4));
    // Save and submit
    return formProps.submitForm();
  };

  const renderButtons = (formProps) => {
    if (!orderInfo) {
      return () => (null);
    }

    const formHasUnsavedChanges = formProps.dirty;
    const isOrderAlreadyChecked = orderInfo.is_checked;
    const isSavingAndFinishingOrder = formProps.isSubmitting;

    // eslint-disable-next-line react/prop-types
    return ({ nextStep }) => (
      orderInfo.is_order_completed
        ? (
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleCheckOrderButtonClick}
            disabled={isOrderAlreadyChecked || isSavingOrderCheckedStatus || formHasUnsavedChanges}
          >
            Check order
          </button>
        ) : (
          <button
            className="btn btn-primary"
            type="button"
            disabled={isSavingAndFinishingOrder}
            onClick={nextStep}
          >
            Submit
          </button>
        )
    );
  };

  const getPanelTier = (order, tiers) => (
    order.product.panel_tier_id && tiers ? tiers.find(
      (pt) => pt.panel_tier_id === order.product.panel_tier_id,
    ) : null
  );

  let panelTierText = null;

  if (orderInfo) {
    initialValues.internal_comments = orderInfo.internal_comments;
    initialValues.is_order_completed = orderInfo.is_order_completed;

    const panelTier = getPanelTier(orderInfo, panelTiers);
    panelTierText = panelTier && `Panel tier: ${getPanelTierNumber(panelTier.size)}`;
  }

  const geneCountText = currentGeneCount ? `Gene count: ${currentGeneCount}` : null;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validate={validate}
      enableReinitialize
      onSubmit={(values, actions) => wrapSubmit(saveAndFinishOrder, values, actions)}
    >
      {(formProps) => (
        <Form autoComplete="off">
          <PromptIfDirty {...formProps} />
          <EditOrderStep
            {...formProps}
            hideModal={hideModal}
            isLastStep
            error={error || formProps.errors.general}
            loading={loading || isSavingOrderCheckedStatus}
            submit={handleEditOrderStepSubmit(formProps)}
            extraButtons={renderButtons(formProps)}
          >
            {orderInfo && (
              <ConfirmOrderBody
                orderInfo={orderInfo}
                deliveryInfo={deliveryInfo}
                logs={logs}
                skipFields={skipFields}
                areInternalCommentsShown={areInternalCommentsShown}
                panelTierText={panelTierText}
                geneCountText={geneCountText}
                isSupportUser={isSupportUser}
                questions={questions}
              />
            )}
          </EditOrderStep>
        </Form>
      )}
    </Formik>
  );
};

OrderTestFormConfirmOrder.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default OrderTestFormConfirmOrder;
