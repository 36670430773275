import React from 'react';
import PropTypes from 'prop-types';
import AuthorizedProviderOrderInfo from 'components/_CreateOrderProcess/AuthorizedProviderOrderInfo';

const OrderUserInfo = ({ orderInfo }) => (
  <>
    <AuthorizedProviderOrderInfo
      label="Ordering health care professional"
      name={orderInfo.user_name}
      hospitalName={orderInfo.user_hospital}
      isAuthorizedProvider={orderInfo.user_is_authorized_provider}
      authorizationLastApproved={orderInfo.user_authorization_last_approved}
    />

    { orderInfo.ordered_by_name && (
      <AuthorizedProviderOrderInfo
        label="Ordered by"
        name={orderInfo.ordered_by_name}
        hospitalName={orderInfo.alias_user_hospital}
        isAuthorizedProvider={orderInfo.alias_user_is_authorized_provider}
        authorizationLastApproved={orderInfo.alias_user_authorization_last_approved}
      />
    )}
  </>
);

OrderUserInfo.propTypes = {
  orderInfo: PropTypes.shape({
    user_name: PropTypes.string,
    user_hospital: PropTypes.string,
    user_is_authorized_provider: PropTypes.bool,
    user_authorization_last_approved: PropTypes.string,
    ordered_by_name: PropTypes.string,
    alias_user_hospital: PropTypes.string,
    alias_user_is_authorized_provider: PropTypes.bool,
    alias_user_authorization_last_approved: PropTypes.string,
  }).isRequired,
};

export default OrderUserInfo;
