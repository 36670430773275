import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import Helmet from 'react-helmet';

import { Spinner } from 'components';
import * as support from 'redux/modules/support';
import { QuestionPreview, QuestionOptions, EditQuestionForm } from 'admin/components';
import { navigateBack } from 'components/Page';
import useData from 'hooks/useData';

import styles from './EditQuestion.scss';

const EditQuestionPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { questionId } = useParams();

  const shouldSkip = questionId === 'new';
  const title = shouldSkip ? 'Create question' : 'Edit question';

  const {
    data,
    isLoading,
    error,
    setShouldFetch,
  } = useData(() => support.getQuestion(questionId), !shouldSkip);

  const fetchQuestion = () => setShouldFetch(true);

  const canAddOptions = data && Boolean(data.type === 'radio' || data.type === 'checkbox' || data.type === 'select');
  const { waiting } = useSelector((state) => state.support.waitingCreateQuestion, shallowEqual);

  const renderChildren = () => (
    <>
      {error && <div>{error}</div>}
      <EditQuestionForm
        shouldReset={shouldSkip}
        isEditMode={questionId > 0}
        question={data}
        onQuestionUpdate={fetchQuestion}
        navigateBack={(e) => {
          e.preventDefault();
          navigateBack(location, history, '/questions');
        }}
      />
      {questionId > 0 && canAddOptions && (
        <QuestionOptions
          question={data}
          onQuestionOptionsChange={fetchQuestion}
        />
      )}
      {questionId > 0 && <QuestionPreview question={data} />}
    </>
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <Helmet title={title} />
          <a
            href="/questions"
            onClick={(e) => {
              e.preventDefault();
              navigateBack(location, history, '/questions');
            }}
          >
            ← Back to Questions
          </a>
          <h1>{title}</h1>
        </div>
        <div className="col-lg-10 offset-lg-1">
          {error && (<p className="text-danger">{error}</p>)}
          {isLoading || waiting ? (
            <div className={styles.spinner_block}>
              <Spinner />
            </div>
          ) : renderChildren()}
        </div>
      </div>
    </div>
  );
};

export default EditQuestionPage;
