import React from 'react';
import PropTypes from 'prop-types';

import VariantProp from 'components/_OrderReport/_OrderReportVariantsMito/_VariantProp';

const OrderReportVariantsMitoDeldup = ({
  orderReport,
  textColorClass,
}) => (
  <div>
    <h3 className="h4">{ orderReport.variants_mito_deldup_title ? orderReport.variants_mito_deldup_title : 'Primary mitochondrial variant table: Copy number aberrations' }</h3>
    <table className="table v6-variants-table">
      {Object.keys(orderReport.variants_mito_deldup).map((key) => {
        const variant = orderReport.variants_mito_deldup[key];

        return (
          <tbody key={key}>
            <tr>
              <VariantProp
                title="Pos"
                value={variant.position}
              />
              <VariantProp
                title="Size"
                value={variant.size}
              />
              <VariantProp
                title="Event"
                value={variant.event}
              />
              <VariantProp
                title="AF"
                value="Heteroplasmy Fraction"
              />
              <VariantProp
                title="Classification"
                value={variant.classification.value}
                className={textColorClass(variant.classification.class)}
              />
            </tr>
            <tr>
              <VariantProp
                title="Inheritance"
                value={variant.inheritance}
              />
              <VariantProp
                title="Phenotype"
                value={variant.phenotype}
                colSpan={2}
              />
              <VariantProp
                title="Genes"
                value={variant.gene}
                isLink
                url={variant.gene_url}
                colSpan={2}
              />
            </tr>
          </tbody>
        );
      })}
    </table>
  </div>
);

OrderReportVariantsMitoDeldup.propTypes = {
  orderReport: PropTypes.shape({
    variants_mito_deldup_title: PropTypes.string,
    variants_mito_deldup: PropTypes.objectOf(PropTypes.shape({
      position: PropTypes.number,
      size: PropTypes.number,
      event: PropTypes.string,
      classification: PropTypes.shape({
        value: PropTypes.string,
        class: PropTypes.string,
      }),
      inheritance: PropTypes.string,
      phenotype: PropTypes.string,
      gene: PropTypes.string,
      gene_url: PropTypes.string,
    })),
  }).isRequired,
  textColorClass: PropTypes.func.isRequired,
};

export default OrderReportVariantsMitoDeldup;
