import React from 'react';
import PropTypes from 'prop-types';
import QRCodeImg from 'qrcode.react';

const QRCode = ({ text }) => {
  if (!text) {
    return null;
  }
  return (
    <QRCodeImg value={text} />
  );
};

QRCode.propTypes = {
  text: PropTypes.string.isRequired,
};

export default QRCode;
