import React, { Component } from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { getProfileInfo, saveUserInfo } from 'redux/modules/auth';
import LabelInput from 'components/Formik/LabelInput';
import Page, { initPageState, handlePagePromise } from 'components/Page';

import styles from './AccountSettingsEdit.scss';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
  saveUserInfo,
}, dispatch);

class AccountSettingsEdit extends Component {
  static propTypes = {
    userInfo: PropTypes.PropTypes.shape({
      user_name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      department_name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      opt_out_cc: PropTypes.bool,
    }),
    getProfileInfo: PropTypes.func.isRequired,
    saveUserInfo: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    userInfo: {
      user_name: '',
      title: '',
      department_name: '',
      phone: '',
      opt_out_cc: false,
    },
  };

  state = initPageState();

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  fetchData = async () => {
    // if coming from account-settings
    if (this.props.userInfo.user_name !== '') {
      const { userInfo } = this.props;
      return this.formatProfile(userInfo);
    }
    // if we reload from edit-profile
    const { result } = await this.props.getProfileInfo();
    return this.formatProfile(result);
  };

  formatProfile = (userInfo) => ({
    profileInfo: {
      name: userInfo.user_name ? userInfo.user_name : '',
      title: userInfo.title ? userInfo.title : '',
      department_name: userInfo.department_name ? userInfo.department_name : '',
      phone: userInfo.phone ? userInfo.phone : '',
      opt_out_cc: !!userInfo.opt_out_cc,
    },
  });

  renderForm = () => {
    const {
      data: { profileInfo },
    } = this.state;

    return (
      <div>
        {Object.keys(profileInfo).length !== 0 && (
          <Formik
            initialValues={profileInfo}
            onSubmit={(fields, actions) => {
              this.props
                .saveUserInfo(fields)
                .catch((e) => {
                  actions.setFieldError('general', e.message);
                })
                .finally(() => {
                  this.props.history.push('/account-settings');
                });
            }}
          >
            {(formProps) => (
              <Form>
                {formProps.errors.general && (
                  <p className="text-danger">{formProps.errors.general}</p>
                )}

                <Field
                  name="name"
                  component={LabelInput}
                  label="Name"
                  required
                />

                <Field
                  name="title"
                  component={LabelInput}
                  label="Title"
                />

                <Field
                  name="department_name"
                  component={LabelInput}
                  label="Department name"
                />

                <Field
                  name="phone"
                  component={LabelInput}
                  label="Phone"
                />

                {profileInfo && (
                  <div className={styles.acc_info_wrapper}>
                    <span className="float-right button-group">
                      <Link className="btn btn-default" to="/account-settings">
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </span>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    );
  };

  render() {
    const { page } = this.state;

    return (
      <Page
        cols={8}
        offsetCols={1}
        previousPageLink="/account-settings"
        previousPageLabel="Account settings"
        title="Edit account settings"
        initialData={this.initialData}
        render={this.renderForm}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettingsEdit);
