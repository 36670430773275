import React from 'react';
import { LabelRadioDisplay, LabelCheckboxDisplay } from 'components';

export const renderSelectAnswer = (question) => {
  const selected = question.question_options.find((option) => option.selected);

  if (selected) {
    return selected.question_option;
  }

  return null;
};

export const renderQuestion = (question) => {
  if (question.type === 'radio') {
    return (
      <div key={question.question_id}>
        <LabelRadioDisplay
          label={question.question}
          labelSize="6"
          inputSize="6"
          hint={question.hint}
          options={question.question_options}
          freeTextAnswer={question.free_text_option_answer}
          disabled
        />
      </div>
    );
  }
  if (question.type === 'checkbox') {
    return (
      <div key={question.question_id}>
        <LabelCheckboxDisplay
          label={question.question}
          labelSize="6"
          inputSize="6"
          hint={question.hint}
          options={question.question_options}
          freeTextAnswer={question.free_text_option_answer}
          disabled
        />
      </div>
    );
  }
  if (question.type === 'bool_slider') {
    if (question.answer === '0') {
      return (
        <div key={question.question_id}>
          <label className="view_order_modal_label">
            {question.question}
          </label>
          <span>
            No
            <br />
            {question.free_text_option_answer}
          </span>
        </div>
      );
    }
    if (question.answer === '1') {
      return (
        <div key={question.question_id}>
          <label className="view_order_modal_label">
            {question.question}
          </label>
          <span>
            Yes
            {' '}
            <br />
            {question.free_text_option_answer}
          </span>
        </div>
      );
    }
    return (
      <div key={question.question_id}>
        <label className="view_order_modal_label">{question.question}</label>
        <span>
          N/A
          {question.free_text_option_answer && (
          <span>
            <br />
            {question.free_text_option_answer}
          </span>
          )}
        </span>
      </div>
    );
  }
  if (
    question.type === 'text'
      || question.type === 'number'
      || question.type === 'textarea'
      || question.type === 'dob'
  ) {
    return (
      <div key={question.question_id}>
        <label className="view_order_modal_label">{question.question}</label>
        <span>
          {question.answer ? question.answer : 'N/A'}
          {' '}
          {question.unit ? question.unit : ''}
        </span>
      </div>
    );
  }
  if (question.type === 'select') {
    return (
      <div key={question.question_id}>
        <label className="view_order_modal_label">{question.question}</label>
        <span>
          {renderSelectAnswer(question)}
          {' '}
          <br />
          {question.free_text_option_answer
            ? question.free_text_option_answer
            : ''}
        </span>
      </div>
    );
  }

  return null;
};
