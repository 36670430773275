import React from 'react';
import PropTypes from 'prop-types';

import QuestionPropTypes from 'types/QuestionPropTypes';

const TextPreview = ({ question }) => (
  <div className="form-group row">
    <label htmlFor={question.name} className="col-md-6 col-form-label">
      {question.question && question.question}
      {question.hint && <p className="small">{question.hint}</p>}
    </label>

    <div className="col-md-6">
      <input type="text" id={question.name} className="form-control" />
    </div>
  </div>
);

TextPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default TextPreview;
