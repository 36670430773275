import { createStore, applyMiddleware, compose } from 'redux';
import envUtils from 'utils/envUtils';
import clientMiddleware from './middleware/clientMiddleware';
import rootReducer from './modules/reducer';

export default function configureStore(preloadedState, client) {
  const isProd = envUtils.isProdEnvironment();

  // only enable Redux-devtools on non-production environment
  const composeEnhancers = (!isProd && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        clientMiddleware(client),
      ),
    ),
  );

  return store;
}
