import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';

const PrintConsent = ({ downloadConsentFile, orderInfo, lang }) => (
  <div className="form-group text-center">
    <span className="button-group">
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => {
          downloadConsentFile(orderInfo.order_id, lang);
        }}
      >
        Print consent
      </button>
    </span>
  </div>
);

PrintConsent.propTypes = {
  lang: PropTypes.string,
  downloadConsentFile: PropTypes.func.isRequired,
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number.isRequired,
  }).isRequired,
};

PrintConsent.defaultProps = {
  lang: 'en',
};

export default connect(
  (state) => ({
    orderInfo: state.orders.orderInfo,
  }),
  {
    downloadConsentFile: orderActions.downloadConsentFile,
  },
)(PrintConsent);
