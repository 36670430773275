import { useEffect, useRef } from 'react';

/**
 * Return previous value of any value passed in as argument
 * More information can be found: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @param {*} value;
 */
const usePrevious = (value) => {
  // Create new ref instance
  // ref is an object with mutable property: current
  // Here [value] is passed into useRef
  // So in first invoke of usePrevious, first return will be value instead of undefined
  const ref = useRef(value);

  // This will not run in first invoke, only run on each subsequent render
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // This always happen before useEffect, return previous value of value
  // Meanwhile the ref current still got updated with new value.
  return ref.current;
};

export default usePrevious;
