import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const FeatureMessageModal = ({
  hide,
  show,
  message,
  ...props
}) => (
  <Modal {...props} show={show} size="xl" aria-labelledby="contained-modal-title-sm" keyboard>
    <Modal.Header />
    <Modal.Body>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </Modal.Body>
    <Modal.Footer>
      <button type="button" className="btn btn-primary" onClick={hide}>Continue</button>
    </Modal.Footer>
  </Modal>
);

FeatureMessageModal.propTypes = {
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default FeatureMessageModal;
