import React, {Component} from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';

export function parseDate(inputString) {
  const d = new Date(inputString)

  // new Date('2018-11-24'); becomes
  // Sat Nov 24 2018 02:00:00 GMT+0200 (Eastern European Standard Time)
  // in Espoo but
  // Fri Nov 23 2018 16:00:00 GMT-0800 (Pacific Standard Time)
  // in seattle so we need to first add 12 hours to get the date part right and
  // then I set the time to 00:00:00 for the test
  if (d.getHours() >= 12) {
    d.setHours(d.getHours() + 12);
  }
  d.setHours(0);

  return d
}

export function formatDate(d) {
  // d is always yyyy-MM-dd 00:00:00 in the current local time
  // so hours >= 12 implies western hemisphere and thus we need to add 12 hours to get the correct date
  if (d && d.getHours() >= 12) {
    d.setHours(d.getHours() + 12);
  }

  if (!d) return null

  return [
    d.getFullYear(),
    `${(d.getMonth() + 1)}`.padStart(2, '0'),
    `${d.getDate()}`.padStart(2, '0'),
  ].join('-');
}

export default class LabelDateInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  };
  render() {
    const { disabled, label, labelSize, inputSize, name, placeholder, selected, ...props } = this.props;

    return (
      <div className="form-group row">
        <label className={'col-md-' + labelSize + ' col-form-label'}>{label}</label>
        <div className={'col-md-' + inputSize + ' no-padding-left'}>
          <DatePicker
            disabled={disabled}
            showYearDropdown
            dateFormat="yyyy-MM-dd"
            placeholder={placeholder}
            popoverAttachment="top center"
            popoverTargetAttachment="bottom center"
            tetherConstraints={[]}
            {...props}
            onChange={(d) => props.onChange(formatDate(d))}
            selected={typeof selected === 'string' ? parseDate(selected) : selected}
            className="form-control"
            isClearable={true} />
          {props && props.touched && props.error && <p className="text-danger">* {props.error}</p>}
        </div>
      </div>
    );
  }
}
