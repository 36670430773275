import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Yup } from 'components/Formik/validation';
import Helmet from 'react-helmet';

import Spinner from 'components/Spinner/Spinner';
import * as batch from 'redux/modules/batchOrder';
import ToggleInput from 'components/StyleComponents/Input/ToggleInput';
import BatchSelectField from './components/BatchSelectField';
import BatchFileField from './components/BatchFileField';
import CreatedOrdersResult from './components/CreatedOrdersResult';

import styles from './BatchOrder.scss';

const FILE_SIZE = 50000000;
const SUPPORTED_FORMATS = [
  'text/csv',
];

const getBase64 = (file) => new Promise(((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    // split to remove type at the beginning of the file
    // e.g data:text/csv;base64,
    resolve(reader.result.split(',')[1]);
  };
  reader.readAsDataURL(file);
}));

const BatchOrder = () => {
  const [ordersCreated, setOrdersCreated] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [dataWarning, setDataWarning] = useState();
  const [clinicians, setClinicians] = useState();
  const [hospitals, setHospitals] = useState();
  const [showNeiFields, setShowNeiFields] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const dispatch = useDispatch();
  const fileRef = useRef();

  const title = 'Batch Order';
  const shouldShowNeiFields = showNeiFields && hospitals && clinicians;

  const batchOrdersValidationSchema = Yup.object().shape({
    file: Yup.mixed()
      .test('fileSize', 'File Size is too large', (value) => value && (value.size <= FILE_SIZE))
      .test('fileType', 'Unsupported File Format - use csv file', (value) => value && (SUPPORTED_FORMATS.includes(value.type)))
      .required('Select a file'),
  });

  const initialValues = shouldShowNeiFields ? {
    hospital: hospitals[0].hospital_id,
    clinician: clinicians[0].user_id,
  } : {};

  const clearError = () => {
    setError({});
  };

  useEffect(() => {
    const fetchData = async () => {
      const hospitalData = await dispatch(batch.NEIBatchOrderListHospitals());
      const clinicianData = await dispatch(
        batch.NEIBatchOrderListClinicians(hospitalData[0].hospital_id),
      );
      setClinicians(clinicianData);
      setHospitals(hospitalData);
    };

    if (showNeiFields) {
      fetchData();
    }

    clearError();
    setOrdersCreated([]);
    setDataErrors([]);
    setDataWarning();
  }, [dispatch, showNeiFields]);

  // ** change the clinician when the hospital change **
  const handleHospitalChange = async (event) => {
    const hospitalId = event.target.value;
    const clinicianData = await dispatch(batch.NEIBatchOrderListClinicians(hospitalId));
    setClinicians(clinicianData);
  };

  const handleBatchOrderTypeChange = () => {
    setShowNeiFields(!showNeiFields);
  };

  const submit = async (values, actions) => {
    if (!values.file) {
      setError({ message: 'Please add a file' });
      return;
    }

    setIsLoading(true);
    const fileAsBase64 = await getBase64(values.file);
    const fileToSend = {
      file_mime_type: values.file.type,
      file_size: values.file.size,
      base64_data: fileAsBase64,
    };

    try {
      const result = showNeiFields
        ? await dispatch(batch.NEIBatchOrderUpload({
          ...fileToSend,
          hospital_id: values.hospital,
          clinician_id: values.clinician,
        }))
        : await dispatch(batch.batchOrderUpload(fileToSend));

      setOrdersCreated(result.order_ids);
      setDataErrors(result.data_errors);
      setDataWarning(result.warning);
      setIsLoading(false);

      actions.resetForm(initialValues);
      fileRef.current.value = null;
      clearError();
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  const renderForm = (formProps) => {
    const { isSubmitting, setFieldValue, values } = formProps;

    return (
      <div className={styles.batch_import_body}>
        <Form>
          {shouldShowNeiFields && (
          <>
            <BatchSelectField
              name="hospital"
              onChange={handleHospitalChange}
              options={hospitals.map((hospital) => ({
                key: hospital.hospital_id,
                value: hospital.hospital_name,
              }))}
            />
            <BatchSelectField
              name="clinician"
              options={clinicians.map((clinician) => ({
                key: clinician.user_id,
                value: clinician.full_name,
              }))}
            />
          </>
          )}
          <BatchFileField
            setFieldValue={setFieldValue}
            values={values}
            fileRef={fileRef}
          />
          <button
            className="btn btn-primary float-right"
            type="submit"
            disabled={isSubmitting || !values.file}
          >
            Upload File and Create Orders
          </button>
        </Form>
        <p className="text-danger">{formProps.errors.file}</p>
      </div>
    );
  };

  return (
    <div className={`${styles.batch_import} container`}>
      <Helmet title={title} />
      <h1>Batch Import</h1>
      <ToggleInput
        onChange={handleBatchOrderTypeChange}
        name="batchOrderType"
        isChecked={showNeiFields}
        leftValue="Batch orders"
        rightValue="NEI batch orders"
      />
      <Formik
        initialValues={initialValues}
        validationSchema={batchOrdersValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        onSubmit={(values, actions) => submit(values, actions)}
      >
        {(formProps) => renderForm(formProps)}
      </Formik>
      {isLoading ? (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <CreatedOrdersResult
          ordersCreated={ordersCreated}
          dataErrors={dataErrors}
          dataWarning={dataWarning}
          error={error.message}
        />
      )}
    </div>
  );
};

export default BatchOrder;
