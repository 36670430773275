import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import LabelInput from 'components/Formik/LabelInput';

import styles from './DeliveryEntry.scss';

const FaxItem = ({
  prefix,
  onRemove,
  isSupport,
}) => (
  <div className={styles.subscription_item}>
    <Field
      name={`${prefix}fax_number`}
      component={LabelInput}
      label="Fax number"
      required={!isSupport}
    />

    <Field
      name={`${prefix}recipient_name`}
      component={LabelInput}
      label="Recipient name"
      required={!isSupport}
    />

    <div>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={onRemove}
      >
        Remove item
      </button>
    </div>
  </div>
);

FaxItem.propTypes = {
  prefix: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  isSupport: PropTypes.bool.isRequired,
};

FaxItem.defaultProps = {
  prefix: '',
};

export default FaxItem;
