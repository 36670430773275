import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { changePasswordSimple } from 'redux/modules/auth';
import styles from './AccountSettingsChangePw.scss';

const changePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required('Password is required'),
  password: Yup.string().required('New password is required'),
  password_repeat: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const AccountSettingsEdit = (props) => (
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <Helmet title="Account settings" />
        <h1>Change password</h1>
      </div>
      <Formik
        initialValues={{
          old_password: '',
          password: '',
          password_repeat: '',
        }}
        validationSchema={changePasswordValidationSchema}
        onSubmit={(fields, actions) => {
          // changePasswordSimple coming from redux without a dispatch
          props.changePasswordSimple(fields)
            .then(() => {
              // eslint-disable-next-line no-alert
              alert('Your password has been changed successfully');
            })
            .catch((error) => {
              // to get rid of authError from redux
              actions.setFieldError('general', error.message);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {(formProps) => (
          <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            {formProps.errors.general
            && <p className="text-danger">{formProps.errors.general}</p>}
            <div className={styles.acc_info_wrapper}>
              <Form>
                <div className="form-group row">
                  <label htmlFor="old_password" className="col-md-4 col-form-label">Current password</label>
                  <div className="col-md-8 no-padding-left">
                    <Field
                      name="old_password"
                      type="password"
                      placeholder="Your current password"
                      className={classNames('form-control', {
                        'is-invalid': formProps.errors.old_password && formProps.touched.old_password,
                      })}
                    />
                    <ErrorMessage
                      name="old_password"
                      component="p"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password" className="col-md-4 col-form-label">New password</label>
                  <div className="col-md-8 no-padding-left">
                    <Field
                      name="password"
                      type="password"
                      placeholder="New password"
                      className={
                        `form-control${
                          formProps.errors.password && formProps.touched.password ? ' is-invalid' : ''}`
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="p"
                      className="text-danger"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password_repeat" className="col-md-4 col-form-label">Confirm new password</label>
                  <div className="col-md-8 no-padding-left">
                    <Field
                      name="password_repeat"
                      type="password"
                      placeholder="Confirm new password"
                      className={
                        `form-control${
                          formProps.errors.password_repeat && formProps.touched.password_repeat
                            ? ' is-invalid'
                            : ''}`
                      }
                    />
                    <ErrorMessage
                      name="password_repeat"
                      component="p"
                      className="text-danger"
                    />
                  </div>
                </div>
                <span className="float-right button-group">
                  <Link className="btn btn-default" to="/account-settings">Cancel</Link>
                  <button disabled={formProps.isSubmitting} className="btn btn-primary" type="submit">Change password</button>
                </span>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  </div>
);

AccountSettingsEdit.propTypes = {
  changePasswordSimple: PropTypes.func.isRequired,
};

export default withRouter(connect(null, { changePasswordSimple })(AccountSettingsEdit));
