import React from 'react';
import PropTypes from 'prop-types';

import RadioButtonComponent from '../../RadioButtonComponent/RadioButtonComponent';

const SampleTypeRadioButton = ({
  handleInputValue,
  onChangeValue,
  resultsReported,
  sampleTypeOptions,
  value,
  label,
}) => (
  <RadioButtonComponent
    disabled={resultsReported}
    inputSize="6"
    label={label}
    labelSize="6"
    name="primary_sample_type"
    onChange={(event) => onChangeValue('primary_sample_type', handleInputValue(event))}
    options={sampleTypeOptions}
    type="radio"
    value={value}
  />
);

SampleTypeRadioButton.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  resultsReported: PropTypes.bool.isRequired,
  sampleTypeOptions: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SampleTypeRadioButton;
