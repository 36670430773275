import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class OrderReportVariantsExomeDeldup extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    orderReport: PropTypes.object,
  }

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  }

  renderVariant(variant, index) {
    const rowOne = (
      <tr>
        <td>
          <span className="report-td-heading">Gene</span>
          <br />
          {variant.gene.split(',')
            .map((gene, geneIndex) => (
              <a
                // eslint-disable-next-line react/no-array-index-key
                key={geneIndex}
                className="link-gray clearfix"
                target="_blank"
                rel="noopener noreferrer"
                href={variant.gene_base_url + gene}
              >
                <span>{gene}</span>
              </a>
            ))}
        </td>
        <td>
          <span className="report-td-heading">Event</span>
          <br />
          <span>{variant.event}</span>
        </td>
        <td>
          <span className="report-td-heading">Copy number</span>
          <br />
          <span>{variant.copy_number}</span>
        </td>
        <td style={{ minWidth: '120px', maxWidth: '300px', wordBreak: 'break-word' }}>
          <span className="report-td-heading">Genotype</span>
          <br />
          <span>{variant.genotype}</span>
        </td>
        {variant.gnomad
        && (
          <td>
            <span className="report-td-heading">gnomAD</span>
            <br />
            <span>{variant.gnomad}</span>
          </td>
        )}
        <td>
          <span className="report-td-heading">Impact</span>
          <br />
          {variant.impact && variant.impact.split(',')
            // eslint-disable-next-line react/no-array-index-key
            .map((impact, impactIndex) => <span key={impactIndex} className="clearfix">{impact}</span>)}
        </td>
        <td>
          <span className="report-td-heading">Links</span>
          <br />
          <a className="link-gray" target="_blank" rel="noopener noreferrer" href={variant.links}><span>{variant.links_txt}</span></a>
        </td>
        <td>
          <span className="report-td-heading">Classification</span>
          <br />
          <span className={this.textColorClass(variant.classification.class)}>
            {variant.classification.value}
          </span>
        </td>
      </tr>
    );

    const rowTwo = (
      <tr>
        <td />
        <td>
          <a className="link-gray" target="_blank" rel="noopener noreferrer" href={variant.omim_url}><span>{variant.omim}</span></a>
        </td>
        <td colSpan="3">
          <span className="report-td-heading">Phenotype</span>
          <br />
          <span>{variant.phenotype}</span>
        </td>
        <td colSpan="2">
          <span className="report-td-heading">Comment</span>
          <br />
          <span>{variant.comment}</span>
        </td>
      </tr>
    );

    return (
      <tbody key={index}>
        {rowOne}
        {rowTwo}
      </tbody>
    );
  }

  render() {
    const { orderReport } = this.props;
    // const styles = require('./_OrderReportVariantsExomeDeldup.scss');
    let sortedVariantsDeldupArr;
    if (orderReport.variants_exome_deldup) {
      const variantsDeldupArr = Object.keys(orderReport.variants_exome_deldup)
        .map((key) => orderReport.variants_exome_deldup[key]);

      sortedVariantsDeldupArr = variantsDeldupArr
        .sort((element1, element2) => (element1.order_no - element2.order_no));
    }

    return (
      <div>
        {orderReport.version >= 7 && sortedVariantsDeldupArr
        && (
          <div>
            <h3 className="h4">{orderReport.variants_exome_deldup_title}</h3>
            <div>
              <table className="table v6-variants-table">
                {sortedVariantsDeldupArr
                  .map((variant, index) => this.renderVariant(variant, index))}
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}
