import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isSupportUser } from 'utils/orderRoles';
import { userInfoSelector } from 'redux/modules/auth';

import styles from './_OrderTestFormShareResults.scss';

const DeselectUsers = ({
  formProps,
  isCompleted,
  selectedUsers,
  deselectPerson,
}) => {
  const userInfo = useSelector(userInfoSelector);
  const isSupport = isSupportUser(userInfo);
  const handleDeselect = (userId, form) => {
    /**
     * Show warning alert when removing collaborator/s
     * For support users = always show
     * For other users = show only if order is not completed
     */
    if (
      (isCompleted || isSupport)
      && !window.confirm('Are you sure you want to remove sharing?')
    ) {
      return;
    }
    deselectPerson(userId, form);
  };

  return (
    <div className="col-md-6">
      <h4 className={styles.share_result_heading}>Share results with:</h4>
      <ul className={`${styles.share_result_list} list-unstyled`}>
        {selectedUsers(formProps).map((u) => (
          /*
            eslint-disable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */
          <li
            key={u.user_id}
            className={styles.share_result_item}
            onClick={() => handleDeselect(
              u.user_id,
              formProps,
            )}
          >
            {u.name}
            <br />
            <span className="small">{u.email}</span>
            <span className="float-right glyphicon glyphicon-minus" />
          </li>
        ))}
      </ul>
    </div>
  );
};

DeselectUsers.propTypes = {
  formProps: PropTypes.shape({}).isRequired,
  isCompleted: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.func.isRequired,
  deselectPerson: PropTypes.func.isRequired,
};

export default DeselectUsers;
