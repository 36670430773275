import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Helmet from 'react-helmet';

import * as orderActions from 'redux/modules/orders';
import * as auth from 'redux/modules/auth';
import * as orderRoles from 'utils/orderRoles';
import { calculatePanelTiers } from 'utils/panelTiers';
import {
  isTVT, isFVT, isExome, isMSD,
} from 'utils/forms';
import { Spinner } from 'components';
import ProductSelection from 'components/ProductSelection/ProductSelection';
import usePrevious from 'hooks/usePrevious';
import PropTypes from 'prop-types';

const NewOrder = (props) => {
  const [products, setProducts] = useState(null);
  const [savedFlexPanels, setSavedFlexPanels] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const userInfo = useSelector(auth.userInfoSelector);
  const aliasUserId = useSelector(auth.aliasUserIdSelector);
  const panelTiers = useSelector(orderActions.panelTiersSelector);
  const canPlaceOrder = orderRoles.canPlaceOrderForAliasOrForOneself(userInfo, aliasUserId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!panelTiers) {
          await dispatch(orderActions.getPanelTiers());
        } else {
          const panelData = await dispatch(orderActions.getPanels());
          const productsWithTiers = calculatePanelTiers(
            panelData.products,
            panelTiers,
            userInfo.pricing_model,
          );
          const savedFlexPanelsWithTiers = calculatePanelTiers(
            panelData.gene_collections,
            panelTiers,
            userInfo.pricing_model,
          );

          setProducts(productsWithTiers);
          setSavedFlexPanels(savedFlexPanelsWithTiers);
          setLoading(false);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      }
    };

    setLoading(true);
    setError(null);
    fetchData();
  }, [dispatch, panelTiers, userInfo]);

  const createOrder = useCallback(async (product, isCustomized) => {
    setLoading(true);
    const canPlaceOrderAsAliasUser = (
      orderRoles.isSuperUser(userInfo)
      || orderRoles.isOrderAgent(userInfo)
    );
    const isAliasUserIdSelected = aliasUserId && aliasUserId !== 'current-user';
    const parsedAliasUserId = (
      isAliasUserIdSelected
      && canPlaceOrderAsAliasUser ? Number(aliasUserId) : undefined
    );

    const panelTierExists = Boolean(product.panel_tier_id);
    const isPanelWithoutPanelTier = isFVT(product.special_type) || isTVT(product.special_type) || isExome(product.special_type) || isMSD(product.special_type) || product.special_type === 'single_gene' || product.special_type === 'screening' || userInfo.pricing_model === 'phenotype';

    // panelTier is not provided for Canadian customers
    if (userInfo.country !== 'Canada' && !panelTierExists && !isPanelWithoutPanelTier) {
      setError('Failed to create an order; pricing info is missing. Please try again later.');
      setLoading(false);
    } else {
      const orderPayload = {
        panel_id: product.panel_id,
        gene_collection_id: product.gene_collection_id || null,
        alias_user_id: parsedAliasUserId,
        is_customized: isCustomized,
        ...(panelTierExists && { panel_tier_id: product.panel_tier_id }),
      };

      try {
        const result = await dispatch(orderActions.newOrder(orderPayload));
        history.push(`/orders/${result.order_id}/edit`, { show_flexing_message: product.show_flexing_message || false });
      } catch (e) {
        setError('Failed to create an order');
        setLoading(false);
      }
    }
  }, [aliasUserId, dispatch, history, userInfo]);

  const upgradeSaveFlex = async (geneCollectionId) => {
    try {
      await dispatch(orderActions.upgradeGeneCollection(geneCollectionId));
      window.location.reload();
    } catch (e) {
      setError(`Failed to upgrade the saved gene collection. Backend: ${e}`);
      setLoading(false);
    }
  };

  const deleteSaveFlex = async (geneCollectionId) => {
    try {
      await dispatch(orderActions.deleteGeneCollection(geneCollectionId));
      setSavedFlexPanels((state) => state.filter(
        (geneCollection) => geneCollection.id !== geneCollectionId,
      ));
    } catch (e) {
      setError(`Failed to delete the saved gene collection. Backend: ${e}`);
      setLoading(false);
    }
  };

  const { productCode } = useParams();
  const prevProducts = usePrevious(products);

  useEffect(() => {
    const autoCreateOrder = () => {
      if (productCode && products && !prevProducts) {
        const foundProduct = products.find((product) => (
          product.product_code === productCode && !product.is_hidden && !product.category_is_hidden
        ));

        /* eslint-disable camelcase */
        if (foundProduct) {
          createOrder({
            ...foundProduct,
            panel_tier_id: foundProduct.panel_tier?.panel_tier_id,
          }, false);
        } else {
          // eslint-disable-next-line no-alert
          alert('Unfortunately the test you selected is not available in Nucleus. Please select a test from the panel list to create new order.');
          history.push('/orders/new');
        }
      }
    };

    autoCreateOrder();
  }, [createOrder, history, productCode, products, prevProducts]);

  return (
    <div className="container">
      <Helmet title={props.title} />
      <div>
        <div className="row col-lg-12">
          <h1>{props.title}</h1>
        </div>

        {error && (
          <div><p className="text-danger">{error}</p></div>
        )}

        {!canPlaceOrder && (
          <p>
            Please select an Ordering Provider to start ordering a new test
          </p>
        )}

        {canPlaceOrder && !loading && products && (
          <ProductSelection
            products={products}
            onSelect={(product) => createOrder(product)}
            onUpgrade={(geneCollectionId) => upgradeSaveFlex(geneCollectionId)}
            onDelete={(geneCollectionId) => deleteSaveFlex(geneCollectionId)}
            onCustomize={(product) => createOrder(product, true)}
            savedFlexPanels={savedFlexPanels}
            pricingModel={userInfo.pricing_model}
          />
        )}

        {loading && (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

NewOrder.propTypes = {
  title: PropTypes.string,
};

NewOrder.defaultProps = {
  title: 'New Test',
};
export default NewOrder;
