import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { formatFileSize } from 'utils/forms';

import styles from './InsuranceCaseEditModal.scss';

const NewCommentAttachment = ({
  fileName,
  fileMimeType,
  fileSize,
  onFileChange,
  onFileDelete,
}) => (
  <div className={classNames(styles.field, styles.newCommentAttachment)}>
    <label>Attachment</label>
    <div>
      <input
        type="file"
        id="insurance_file_input"
        className="form-control"
        onChange={onFileChange}
      />
      <label
        htmlFor="insurance_file_input"
        className={classNames('btn btn-default file-label-btn', styles.uploadFileButton)}
      >
        Upload file
      </label>
    </div>
    {fileName && fileMimeType && (
      <div className={styles.fileInfo}>
        {fileName}
        {' '}
        (
        {formatFileSize(fileSize)}
        )
        <button
          type="button"
          onClick={onFileDelete}
          className={`${styles.deleteFile} btn btn-link`}
        >
          &#x2715;
        </button>
      </div>
    )}
  </div>
);

NewCommentAttachment.propTypes = {
  fileName: PropTypes.string,
  fileMimeType: PropTypes.string,
  fileSize: PropTypes.number,
  onFileChange: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

NewCommentAttachment.defaultProps = {
  fileName: null,
  fileMimeType: null,
  fileSize: null,
};

export default NewCommentAttachment;
