import React from 'react';
import PropTypes from 'prop-types';

import { LabelRadioDisplay } from 'components';
import QuestionPropTypes from 'types/QuestionPropTypes';

const RadioButtonPreview = ({ question }) => (
  <LabelRadioDisplay
    label={question.question}
    labelSize="6"
    inputSize="6"
    hint={question.hint}
    freeTextAnswer={question.free_text_option_question}
    options={question.question_options}
  />
);

RadioButtonPreview.propTypes = {
  question: PropTypes.shape(QuestionPropTypes).isRequired,
};

export default RadioButtonPreview;
