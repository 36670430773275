import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';

const PrinterSelect = ({
  handleInputValue,
  onChangeValue,
  printerTypeOptions,
  resultsReported,
  value,
}) => (
  <LabelSelect
    inputSize="6"
    label="Printer"
    labelSize="6"
    name="printer_name"
    onChange={(event) => onChangeValue('printer_name', handleInputValue(event))}
    options={printerTypeOptions}
    readOnly={resultsReported}
    value={value}
  />
);

PrinterSelect.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  printerTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  resultsReported: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default PrinterSelect;
