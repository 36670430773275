import React from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';

/**
 * Default ErrorMessage from Formik doesn't work when field is inside array.
 */

export function getFieldError(form, name) {
  const error = getIn(form.errors, name);
  const touched = getIn(form.touched, name);

  return (touched && error) ? error : null;
}

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getFieldError(form, name);

      if (error) {
        return (
          <p className="text-danger">{error}</p>
        );
      }

      return null;
    }}
  </Field>
);

ErrorMessage.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ErrorMessage;
