import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { userInfoSelector, aliasUserIdSelector } from 'redux/modules/auth';
import {
  visibleColumnsSelector,
  gettingReportFileSelector,
  getReportFile,
  archiveOrder,
  archivingSelector,
  ordersListSelector,
} from 'redux/modules/orders';
import { OrderList } from 'components';
import * as orderRole from 'utils/orderRoles';
import { getOrderBy, getDefaultColumnOrder, getColumnOrder } from 'utils/ordersList';
import { isUsRegion } from 'utils/envUtils';

import styles from './OrdersList.scss';

const OrdersTable = (props) => {
  const [archivingOrderId, setArchivingOrderId] = useState(null);
  const [downloadingReportId, setDownloadingReportId] = useState(null);

  const handleArchivingOrderId = (id) => {
    setArchivingOrderId(id);
  };

  const handleDownloadingReportId = (id) => {
    setDownloadingReportId(id);
  };

  const {
    openInsuranceCaseDetailModal,
    openInsuranceCaseEditModal,
    openOrderStatusModal,
    openReflexModal,
    resetPage,
    handleOrderFVTLinkClick,
  } = props;

  const location = useLocation();
  const query = queryString.parse(location.search);

  const userInfo = useSelector(userInfoSelector);
  const aliasUserId = useSelector(aliasUserIdSelector);
  const visibleColumns = useSelector(visibleColumnsSelector);
  const gettingReportFile = useSelector(gettingReportFileSelector);
  const archiving = useSelector(archivingSelector);
  const orders = useSelector(ordersListSelector);

  // Move dispatching functions to OrderList when/if is is refactored into functional component
  const dispatch = useDispatch();

  const dispatchArchiveOrder = (orderId) => {
    dispatch(archiveOrder(orderId));
  };

  const dispatchGetReportFile = (orderId, reportLabel) => {
    dispatch(getReportFile(orderId, reportLabel));
  };

  const isColumnVisible = (column) => (
    Object.prototype.hasOwnProperty.call(visibleColumns, column)
  );

  const sortByProperty = (property) => {
    if (getOrderBy(query) === property) {
      // Reverse order
      const desc = getColumnOrder(query, property) === 'desc';
      query.order = desc ? 'asc' : 'desc';
    } else {
      const defaultOrder = getDefaultColumnOrder(property);
      query.order = defaultOrder;
    }

    query.order_by = property;
    delete (query.page);

    resetPage(query);
  };

  const renderSortIndicator = (column) => {
    const active = getOrderBy(query) === column;
    const order = active
      ? getColumnOrder(query, column)
      : getDefaultColumnOrder(column);
    const desc = order === 'desc';

    return (
      <i
        className={classNames('glyphicon', {
          'glyphicon-menu-down': desc,
          'glyphicon-menu-up': !desc,
          [styles.inactive]: !active,
          inactive: !active,
        })}
        aria-hidden="true"
      />
    );
  };

  const renderOrder = (order) => {
    const showFVTLink = orderRole.shouldShowFVTLink(
      order.product_operations,
      userInfo,
      aliasUserId,
    ) && !isUsRegion();

    return (
      <OrderList
        order={order}
        visibleColumns={visibleColumns}
        key={order.order_id}
        openInsuranceCaseDetailModal={openInsuranceCaseDetailModal}
        openInsuranceCaseEditModal={openInsuranceCaseEditModal}
        openOrderStatusModal={openOrderStatusModal}
        getReportFile={dispatchGetReportFile}
        gettingReportFile={gettingReportFile}
        setDownloadingReportId={handleDownloadingReportId}
        downloadingReportId={downloadingReportId}
        userInfo={userInfo}
        archiveOrder={dispatchArchiveOrder}
        archiving={archiving}
        archivingOrderId={archivingOrderId}
        setArchivingOrderId={handleArchivingOrderId}
        isSupportView={orderRole.isSupportUser(userInfo)}
        openReflexModal={openReflexModal}
        onOrderFVTLinkClick={handleOrderFVTLinkClick}
        showFVTLink={showFVTLink}
      />
    );
  };

  return (
    <table className="table">
      <thead>
        <tr className={styles.no_border_top}>
          <th className={styles.date_col} onClick={() => sortByProperty('date')}>
            {'Date '}
            {renderSortIndicator('date')}
          </th>

          <th className="text-nowrap">Order ID</th>

          {isColumnVisible('lab-id') && <th className="text-nowrap">Lab ID</th>}

          {isColumnVisible('order-user') && <th className={styles.physician_col}>Physician/ Hospital</th>}

          {isColumnVisible('analysis-started') && <th>Analysis started</th>}

          {isColumnVisible('patient') && <th>Patient</th>}

          {isColumnVisible('date-of-birth') && <th className={styles.dob_col}>Date of Birth</th>}

          {isColumnVisible('insurance-company') && <th>Insurance company</th>}

          <th>Test</th>

          {isColumnVisible('insurance-status') && (
          <th className={styles.insurance_status_col} onClick={() => sortByProperty('insurance_status')}>
            {'Insurance status '}
            {renderSortIndicator('insurance_status')}
          </th>
          )}

          {isColumnVisible('last-updated') && (
          <th className={styles.last_updated_col} onClick={() => sortByProperty('last_updated')}>
            {'Last update '}
            {renderSortIndicator('last_updated')}
          </th>
          )}

          {isColumnVisible('owner') && (
          <th className={styles.sortable_col} onClick={() => sortByProperty('owner')}>
            {'Owner '}
            {renderSortIndicator('owner')}
          </th>
          )}

          {isColumnVisible('status') && (
          <th className={styles.test_status_col} onClick={() => sortByProperty('status')}>
            {'Test Status '}
            {renderSortIndicator('status')}
          </th>
          )}

          {isColumnVisible('fax-status') && <th className={styles.fax_status_col}>Fax Status</th>}

          {isColumnVisible('is-checked') && (
          <th className={styles.sortable_col} onClick={() => sortByProperty('is_checked')}>
            {'Check '}
            {renderSortIndicator('is_checked')}
          </th>
          )}

          {isColumnVisible('has-phi-free-summary') && (
          <th className={styles.sortable_col} onClick={() => sortByProperty('has_phi_free_summary')}>
            {'PHI-free summary '}
            {renderSortIndicator('has_phi_free_summary')}
          </th>
          )}

          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {userInfo && orders && orders.map(renderOrder)}
      </tbody>
    </table>
  );
};

OrdersTable.propTypes = {
  resetPage: PropTypes.func.isRequired,
  openInsuranceCaseDetailModal: PropTypes.func.isRequired,
  openInsuranceCaseEditModal: PropTypes.func.isRequired,
  openOrderStatusModal: PropTypes.func.isRequired,
  openReflexModal: PropTypes.func.isRequired,
  handleOrderFVTLinkClick: PropTypes.func.isRequired,
};

export default OrdersTable;
