export const BILLING_METHODS = {
  INSTITUTIONAL: 'institutional',
  PATIENT_INSURANCE: 'patient_insurance',
  PATIENT_PAYMENT: 'patient_payment',
  PROMOTIONAL_CODE: 'promotional_code',
  SPONSORED: 'sponsored',
};

export const BILLING_METHOD_FILTERING_OPTIONS = [
  {
    value: '',
    label: '- All -',
  },
  {
    value: BILLING_METHODS.INSTITUTIONAL,
    label: 'Institutional',
  },
  {
    value: BILLING_METHODS.PATIENT_INSURANCE,
    label: 'Patient insurance',
  },
  {
    value: BILLING_METHODS.PATIENT_PAYMENT,
    label: 'Patient payment',
  },
  {
    value: BILLING_METHODS.SPONSORED,
    label: 'Sponsored',
  },
];

export { BILLING_METHODS as default };
