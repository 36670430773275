import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import SelectInput from 'components/Formik/SelectInput';

import styles from './ReflexModal.scss';

const validationSchema = Yup.object().shape({
  target: Yup.string().required('A product is required'),
});

export default class ReflexModal extends Component {
  static propTypes = {
    // Passed props
    submitError: PropTypes.string,
    orderInfo: PropTypes.shape({
      possible_reflex_target_products: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    orderId: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onSubmitOrder: PropTypes.func.isRequired,
  };

  static defaultProps = {
    submitError: null,
    orderInfo: null,
  };

  isOrderLoaded = () => !!this.props.orderInfo;

  render() {
    const {
      orderId,
      onHide,
      submitError,
      orderInfo,
      onSubmitOrder,
    } = this.props;

    return (
      <Modal
        show
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-sm"
      >
        <div>
          <Modal.Header closeButton>
            <h2>
              Reflex Order
              {orderId}
            </h2>
          </Modal.Header>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              target: '',
            }}
            onSubmit={(values, formProps) => {
              const data = {
                source_order_id: orderId,
                product_id: values.target,
              };
              // we need to keep using the redux actions behind onSubmit order
              // as it is used to go to the new order
              onSubmitOrder(data);
              formProps.setSubmitting(false);
            }}
          >
            {(formProps) => (
              <Form>
                {!this.isOrderLoaded() || formProps.isSubmitting ? (
                  <div className={styles.spinner_inline}>
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <Modal.Body>
                      <div className="form-group row">
                        <label className="col-md-6 col-form-label">
                          Order ID
                        </label>
                        <span>{orderId}</span>
                      </div>
                      <Field
                        defaultValue=""
                        name="target"
                        id="target"
                        placeholder="Select product"
                        options={
                          orderInfo.possible_reflex_target_products
                            ? orderInfo.possible_reflex_target_products.map(
                              (p) => ({ key: p.id, value: p.name }),
                            )
                            : []
                        }
                        keyForOptionId="id"
                        keyForOptionName="name"
                        label="Select product"
                        component={SelectInput}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      {/*
                          we need the form props submit count as the error doesn't reset
                          if you go to a new reflex modal
                     */}
                      {submitError && formProps.submitCount >= 1 && (
                        <div className="text-right col-md-12">
                          <span className="text-danger">{submitError}</span>
                        </div>
                      )}
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={formProps.isSubmitting || !formProps.isValid}
                      >
                        Submit Order
                      </button>
                    </Modal.Footer>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  }
}
