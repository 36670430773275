import React, {Component} from 'react';
import PropTypes from 'prop-types';

const styles = require('./_OrderReportAppendixGeneTable.scss');

export default class OrderReportAppendixGeneTable extends Component {
  static propTypes = {
    orderReport: PropTypes.shape({
      genes: PropTypes.object,
      genes_mito: PropTypes.object,
    }),
    appendixGeneTableShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string,
    reportVersion: PropTypes.number,
    isEmpty: PropTypes.func,
  }

  renderGenes = (genes, reportVersion) => (
    <table className="table">
      <thead>
        <tr className={styles.report_heading_row}>
          <th>Gene</th>
          {reportVersion <= 6 &&
            <th>Ensembl ID</th>
          }
          <th>Chromosome</th>
          <th>Start</th>
          <th>End</th>
          <th>Exons covered</th>
          <th>Bases covered</th>
        </tr>
      </thead>
      <tbody>
      {Object.keys(genes).map(key =>
        <tr key={key}>
          <td>{genes[key].gene}</td>
          {reportVersion <= 6 &&
            <td>{genes[key].ensembl_id}</td>
          }
          <td>{genes[key].chromosome}</td>
          <td>{genes[key].start}</td>
          <td>{genes[key].end}</td>
          <td>{genes[key].exons_covered}</td>
          <td>{genes[key].bases_covered}</td>
        </tr>
      )}
      </tbody>
    </table>
  )

  render() {
    const {
      appendixHeading,
      orderReport,
      appendixGeneTableShown,
      onTogglePanel,
      reportVersion,
      isEmpty,
    } = this.props;

    const { genes, genes_mito } = orderReport;

    const showMitoGenes = !isEmpty(genes_mito);

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixGeneTableShown')}>
          {appendixHeading}
          {appendixGeneTableShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixGeneTableShown ? '' : 'invisible'}>
          {!isEmpty(genes) && this.renderGenes(genes, reportVersion)}
          {showMitoGenes && <h3>Mitochondrial Genes</h3>}
          {showMitoGenes && this.renderGenes(genes_mito, reportVersion)}
        </div>
      </div>
    );
  }
}
