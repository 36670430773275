import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import { isScreening, isSpark } from 'utils/forms';

import styles from './InformedConsentInfo.scss';

const mapStateToProps = (state) => ({
  orderInfo: state.orders.orderInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  downloadConsentFile: orderActions.downloadConsentFile,
  getConsentLanguages: orderActions.getConsentLanguages,
}, dispatch);

class InformedConsentInfo extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    downloadConsentFile: PropTypes.func.isRequired,
    getConsentLanguages: PropTypes.func.isRequired,
  };

  state = {
    consentLanguage: 'en',
    consentLanguages: {},
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({
      loading: true,
    });

    const { orderInfo, getConsentLanguages } = this.props;
    const consentLanguages = await getConsentLanguages(orderInfo.product.id);
    this.setState({
      consentLanguages,
      loading: false,
    });
  }

  handleChange = (event) => {
    this.setState({
      consentLanguage: event.target.value,
      error: null,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { consentLanguage } = this.state;
    const { orderInfo } = this.props;
    if (consentLanguage) {
      this.props.downloadConsentFile(orderInfo.order_id, consentLanguage);
    } else {
      this.setState({ error: 'You need to pick a language.' });
    }
  };

  renderBody() {
    const { orderInfo } = this.props;
    const { consentLanguages } = this.state;

    const specialType = orderInfo.special_type;
    const informedConsentInfoDetails = orderInfo.patient_consent;

    const { consentLanguage, error } = this.state;

    return (
      <div>
        <div className="row">
          {!isScreening(specialType) && informedConsentInfoDetails && (
            <div className="col-md-4 offset-md-4">
              <p className={styles.consent_text}>
                * The patient has signed the consent form.
              </p>
            </div>
          )}

          {!isScreening(specialType) && !informedConsentInfoDetails && (
            <div className="col-md-4 offset-md-4">
              <p className={styles.consent_text}>
                * Patient did not sign the consent form yet.
              </p>
              {!isSpark(specialType) && (
                <form>
                  <div className="form-group">
                    <div className="select">
                      <span className="arrow" />
                      <select
                        className="form-control"
                        name="consentLanguage"
                        id="consentLanguage"
                        onChange={this.handleChange}
                        value={consentLanguage || ''}
                      >
                        {Object.keys(consentLanguages).map((key) => (
                          <option key={key} value={key}>
                            {consentLanguages[key]}
                          </option>
                        ))}
                      </select>
                      {error && (
                        <div>
                          <p className="text-danger">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <span className="button-group">
                      <button
                        className="btn btn-primary"
                        disabled={!consentLanguage}
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Print consent
                      </button>
                    </span>
                  </div>
                </form>
              )}
            </div>
          )}
          {isScreening(specialType) && (
            <div className="col-md-8 offset-md-2">
              <div className="form-group">
                <p>
                  For healthcare providers&apos; reference, Blueprint
                  Genetics&apos; template of informed consent is available on
                  our website:&nbsp;
                  <a
                    href="https://blueprintgenetics.com/screening/consent"
                    className="link-pink"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://blueprintgenetics.com/screening/consent
                  </a>
                </p>
                <p>
                  Blueprint Genetics does not need an informed consent template
                  to perform the ordered test and the consent template does not
                  need to be sent with the sample.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    return (
      <ViewOrderStep loading={loading}>
        {!loading && this.renderBody()}
      </ViewOrderStep>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InformedConsentInfo);
