import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  downloadOrderFile,
} from 'redux/modules/orders';
import {
  filesSelector,
  listFiles,
  addFile,
  addFileErrorSelector,
  addingFileSelector,
  deleteFile,
  deletingFileSelector,
  deleteFileErrorSelector,
  resetFiles,
} from 'redux/modules/orderAttachments';
import usePrevious from 'hooks/usePrevious';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';
import AttachmentTypeEnum from 'types/AttachmentTypeEnum';

import FileItem from './FileItem';

const LabelAttachments = ({
  orderId,
  type,
  label,
  hint,
  userInfo,
  aliasUserId,
  chooseFileButtonCustomLabel,
}) => {
  const dispatch = useDispatch();

  const files = useSelector(filesSelector(type));

  const addingFile = useSelector(addingFileSelector(type));
  const previousAddingFile = usePrevious(addingFile);
  const addFileError = useSelector(addFileErrorSelector(type));

  const deletingFile = useSelector(deletingFileSelector(type));
  const previousDeletingFile = usePrevious(deletingFile);
  const deleteFileError = useSelector(deleteFileErrorSelector(type));

  const addedNewFile = !addingFile && previousAddingFile && !addFileError;
  const deletedExistingFile = !deletingFile && previousDeletingFile && !deleteFileError;

  const listFilesRequestRef = useRef(null);
  const fileInputRef = useRef(null);

  // Fetch files on mount
  useEffect(() => {
    if (orderId) {
      listFilesRequestRef.current = dispatch(listFiles(orderId, type));
    }
  }, [dispatch, orderId, type]);

  // Re-fetch files if a file is added or deleted.
  useEffect(() => {
    if (orderId && (addedNewFile || deletedExistingFile)) {
      listFilesRequestRef.current = dispatch(listFiles(orderId, type));
    }
  }, [addedNewFile, deletedExistingFile, dispatch, orderId, type]);

  // Cancel any unfinished requests on unmount
  useEffect(() => () => {
    if (listFilesRequestRef.current) {
      listFilesRequestRef.current.cancel();
    }

    dispatch(resetFiles(type));
  }, [dispatch, type]);

  const handleFileChange = ({ target }) => {
    const file = target.files[0];

    if (!file) { return; }

    const maxFileSize = 10000000; // 10 MB

    if (file.size > maxFileSize) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      alert('The file you are trying to upload exceeds the maximum file size.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const encodedString = event.target.result.split(',')[1];

      const fileToSend = {
        order_id: orderId,
        type,
        file_name: file.name,
        file_mime_type: file.type,
        file_size: file.size,
        base64_data: encodedString,
      };

      if (userInfo.is_superuser && aliasUserId && aliasUserId !== 'current-user') {
        fileToSend.alias_user_id = Number(aliasUserId);
      }
      dispatch(addFile(fileToSend));
    };

    reader.onerror = (event) => {
      // eslint-disable-next-line no-console
      console.log('Error: ', event.target.error);
    };

    reader.readAsDataURL(file);
  };

  const handleChooseFileButtonClick = () => fileInputRef.current.click();

  const handleConfirmDeleteFile = (fileId) => () => dispatch(deleteFile(
    type,
    {
      order_id: orderId,
      file_id: fileId,
    },
  ));

  const handleFileClick = (fileId) => () => {
    const file = files[fileId];

    if (file.virus_scan_status === 'clean' || file.uploaded_by_the_user) {
      dispatch(downloadOrderFile(orderId, fileId));
    }
  };

  const chooseFileButtonLabel = useMemo(() => {
    if (addingFile) {
      return 'Uploading...';
    }

    return chooseFileButtonCustomLabel || 'Choose a file';
  }, [chooseFileButtonCustomLabel, addingFile]);

  return (
    <div>
      {files && (
        <div className="form-group row">
          <label className="col-md-6 col-form-label">
            {label}
          </label>
          <div className="col-md-6 no-padding-left">
            <ul className="list-unstyled">
              {Object.keys(files).map((fileId) => (
                <FileItem
                  key={fileId}
                  fileName={files[fileId].file_name}
                  onClick={handleFileClick(fileId)}
                  onConfirmDelete={handleConfirmDeleteFile(fileId)}
                  virusScanStatus={files[fileId].virus_scan_status}
                />
              ))}
            </ul>
          </div>
        </div>
      )}

      {addFileError && (
        <p className="text-danger">{addFileError}</p>
      )}

      {deleteFileError && (
        <p className="text-danger">{deleteFileError}</p>
      )}

      <div className="form-group row">
        <label className="col-md-6 col-form-label">
          Attach file (max 10MB)
          <p className="small">{hint}</p>
        </label>
        <div className="col-md-6 no-padding-left">
          <input
            className="form-control"
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <SecondaryButton
            onClick={handleChooseFileButtonClick}
            labelText={chooseFileButtonLabel}
          />
        </div>
      </div>
    </div>
  );
};

LabelAttachments.propTypes = {
  aliasUserId: PropTypes.string,
  chooseFileButtonCustomLabel: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  type: AttachmentTypeEnum.isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  userInfo: PropTypes.shape({
    is_superuser: PropTypes.bool,
  }).isRequired,
};

LabelAttachments.defaultProps = {
  hint: null,
  aliasUserId: null,
  chooseFileButtonCustomLabel: null,
};

export default LabelAttachments;
