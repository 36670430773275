import React, { useState } from 'react';
import BaseTable, { Column } from 'react-base-table';
import debounce from 'lodash/debounce';
import Helmet from 'react-helmet';

import { getGroups } from 'redux/modules/support';
import { AdminHeading, NewGroupModal } from 'admin/components';
import Spinner from 'components/Spinner/Spinner';
import useModal from 'hooks/useModal';
import useData from 'hooks/useData';
import mapGroup from 'utils/groups';
import CommonCell from './components/CommonCell';
import AdminCell from './components/AdminCell';
import EditCell from './components/EditCell';

import styles from './Groups.scss';

const Groups = () => {
  const [selectedGroup, setSelectedGroup] = useState({});
  const [searchString, setSearchString] = useState('');

  const changeSearchString = debounce((string) => {
    setSearchString(string.toLowerCase());
  }, 200);

  const { toggle, show } = useModal();
  const {
    data,
    isLoading,
    error,
    setShouldFetch,
  } = useData(getGroups);

  const title = 'Groups';

  const getFilteredGroups = () => {
    if (data) {
      const groups = Object.values(data).map((item) => mapGroup(item));
      const toSearch = searchString.toLowerCase();

      if (!groups || !toSearch) {
        return groups;
      }

      const fieldContains = (group, fieldName) => {
        if (group[fieldName]) {
          return group[fieldName].toLowerCase().includes(toSearch);
        }
        return false;
      };

      return groups.filter((group) => (
        fieldContains(group, 'groupName')
        || fieldContains(group.groupAdmin, 'groupAdminName')
        || fieldContains(group.groupAdmin, 'groupAdminEmail')
      ));
    }
    return [];
  };

  const openDialog = (event, group) => {
    const selection = group;
    toggle();
    setSelectedGroup(selection);
  };

  const closeDialog = () => {
    toggle();
    setShouldFetch(true);
  };

  const renderBody = () => {
    const groupData = getFilteredGroups();

    if (groupData.length === 0) {
      return (
        <div className={styles.search_error}>
          <p>No groups matching the search criteria were found.</p>
        </div>
      );
    }

    if (typeof error === 'string') {
      return (
        <div className={styles.search_error}>
          <p>No groups were found.</p>
          <p>{error}</p>
        </div>
      );
    }

    return (
      <BaseTable
        data={groupData}
        width={1200}
        height={window.innerHeight - 100}
        className={styles.table}
        rowHeight={70}
      >
        <Column
          cellRenderer={CommonCell}
          title="Group name"
          key="groupName"
          dataKey="groupName"
          width={280}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={AdminCell}
          title="Group admin"
          key="groupAdmin"
          dataKey="groupAdmin"
          width={360}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Hospital name"
          key="groupHospitalName"
          dataKey="groupHospitalName"
          width={310}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Member count"
          key="groupUserCount"
          dataKey="groupUserCount"
          width={150}
          align="center"
          headerClassName={styles.table_column}
        />
        <Column
          cellRenderer={EditCell}
          key="groupId"
          dataKey="groupId"
          width={100}
          align="left"
          onOpenDialog={openDialog}
          className={styles.table_column}
        />
      </BaseTable>
    );
  };

  const props = {
    labelText: '+\xa0 Add Group',
    title: 'Groups',
    buttonFunc: openDialog,
    searchFunc: (event) => changeSearchString(event.target.value),
    placeholder: 'Find a group',
  };

  return (
    <div className={`${styles.groups} container`}>
      <Helmet title={title} />
      <div className={styles.heading}>
        <AdminHeading {...props} />
      </div>
      <div className="col-lg-12">
        {isLoading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <div>{renderBody()}</div>
        )}
      </div>
      <NewGroupModal selectedGroup={selectedGroup} onClose={closeDialog} show={show} />
    </div>
  );
};

export default Groups;
