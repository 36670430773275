import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import LabelInput from 'components/Formik/LabelInput';
import Textarea from 'components/Formik/Textarea';

export const PatientPaymentDescription = ({
  isPatientPayment,
  isSupportUser,
}) => (isPatientPayment && !isSupportUser ? (
  <div className="form-group row">
    <label className="col-md-6 col-form-label" />
    <label className="col-md-6 no-padding-left">
      <p>
        Patient payment is by credit card.
        <span className="small">The payment process begins with the patient receiving a link with payment details to the email address filled out below. The sample goes to analysis once the payment has been collected by Blueprint Genetics.</span>
      </p>
      <p className="small">Please contact billing support (blueprintgenetics.com/contact) if you wish to discuss alternative payment options.</p>
    </label>
  </div>
) : null);

const PatientPaymentBillingInfo = ({ userInfo, billingInfo }) => (
  <div>
    {
      billingInfo.is_legacy ? (
        <Field
          name="patient_address"
          component={Textarea}
          label="Address"
        />
      ) : (
        <>
          <Field
            name="patient_address_line_1"
            component={LabelInput}
            label="Street address line 1"
          />
          <Field
            name="patient_address_line_2"
            component={LabelInput}
            label="Street address line 2"
          />
          <Field
            name="patient_city"
            component={LabelInput}
            label="City"
          />
          <Field
            name="patient_state"
            component={LabelInput}
            label="State"
          />
          <Field
            name="patient_zip"
            component={LabelInput}
            label="Zip code"
          />
        </>
      )
    }

    <Field
      name="credit_card_holder_email"
      component={LabelInput}
      label="Email"
      required
    />

    {
      billingInfo.is_legacy ? (
        <Field
          name="credit_card_holder_name"
          component={LabelInput}
          label="Name"
          required
        />
      ) : (
        <>
          <Field
            name="credit_card_holder_first_name"
            component={LabelInput}
            label="First name"
            required
          />
          <Field
            name="credit_card_holder_last_name"
            component={LabelInput}
            label="Last name"
            required
          />
        </>
      )
    }

    <Field
      name="credit_card_holder_phone"
      component={LabelInput}
      label="Phone"
    />

    {userInfo.user_role === 'support' && (
      <div>
        {billingInfo.patient_phone && (
          <div className="form-group row">
            <label className="col-md-6 col-form-label">Patient phone</label>
            <span className="col-md-6 no-padding-left">{billingInfo.patient_phone}</span>
          </div>
        )}

        {billingInfo.patient_email && (
          <div className="form-group row">
            <label className="col-md-6 col-form-label">Patient email</label>
            <span className="col-md-6 no-padding-left">{billingInfo.patient_email}</span>
          </div>
        )}
      </div>
    )}
  </div>
);

PatientPaymentDescription.propTypes = {
  isPatientPayment: PropTypes.bool.isRequired,
  isSupportUser: PropTypes.bool.isRequired,
};

PatientPaymentBillingInfo.propTypes = {
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
  }).isRequired,
  billingInfo: PropTypes.shape({
    patient_phone: PropTypes.string,
    patient_email: PropTypes.string,
    is_legacy: PropTypes.bool,
  }).isRequired,
};

export default PatientPaymentBillingInfo;
