import PropTypes from 'prop-types';

export const patientsInfoPropTypes = {
  orderInfo: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      version: PropTypes.number,
    }).isRequired,
    order_id: PropTypes.number,
    special_type: PropTypes.string,
    index_patient_tested_at_bpg: PropTypes.bool,
    provide_positive_control: PropTypes.bool,
    patient_first_name: PropTypes.string,
    patient_last_name: PropTypes.string,
    patient_dob: PropTypes.string,
    patient_ssn: PropTypes.string,
    index_patient_name: PropTypes.string,
    index_order_id: PropTypes.string,
    accept_without_positive_control: PropTypes.bool,
    created_date: PropTypes.string,
    testing_need: PropTypes.string,
    patient_zip: PropTypes.string,
    show_fetus_question: PropTypes.bool.isRequired,
    sample_from_ongoing_pregnancy: PropTypes.string,
    is_this_a_fetal_sample: PropTypes.string,
    gestational_age: PropTypes.string,
    akouos_4: PropTypes.bool,
    patient_phone: PropTypes.string,
    patient_address: PropTypes.string,
    patient_email: PropTypes.string,
  }).isRequired,
  getPatientInfoQuestions: PropTypes.func.isRequired,
  patientInfoQuestions: PropTypes.shape({}),
};

export const defaultProps = {
  patientInfoQuestions: null,
};
