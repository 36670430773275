import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import classNames from 'classnames';

import { isVariantMatch } from 'utils/fvtOrder';

import VariantItem from './VariantItem';

import styles from './IndexVariants.scss';

/**
 * Finds whether variant is already selected and returns the index
 * of an item in Formik values.
 */
function isVariantSelected(variant, values) {
  const found = values
    .map((value, index) => [value, index])
    .find(([value]) => isVariantMatch(value, variant));
  if (found) {
    return [true, found[1]];
  }

  return [false, 0];
}

const IndexVariants = ({
  variants,
  caption,
  findingType,
  indexVariantValues,
  id,
}) => {
  if (!variants.length) {
    return (<div />);
  }

  return (
    <table
      id={id}
      className={classNames(styles.indexVariants, 'table')}
    >
      <caption>{caption}</caption>
      <thead>
        <tr style={{ backgroundColor: '#f9f9f9' }}>
          <th>Gene</th>
          <th>Coding DNA/mtDNA change</th>
          <th>Protein change</th>
          <th>Event</th>
          <th>Classification</th>
          <th>Select</th>
        </tr>
      </thead>
      <FieldArray
        name="index_variants"
        render={(arrayHelpers) => (
          <tbody>
            {variants.map((variant, index) => {
              const [selected, valuesIndex] = isVariantSelected(variant, indexVariantValues);

              return (/* eslint-disable react/no-array-index-key */
                <VariantItem
                  key={index}
                  variant={variant}
                  onClick={() => {
                    if (selected) {
                      arrayHelpers.remove(valuesIndex);
                    } else {
                      arrayHelpers.push({
                        ...variant,
                        index_variant: true,
                        finding_type: findingType,
                      });
                    }
                  }}
                  selected={selected}
                />
              ); /* eslint-disable react/no-array-index-key */
            })}
          </tbody>
        )}
      />
    </table>
  );
};

IndexVariants.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.shape({})),
  caption: PropTypes.string.isRequired,
  indexVariantValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  findingType: PropTypes.string.isRequired,
  id: PropTypes.string,
};

IndexVariants.defaultProps = {
  variants: [],
  id: '',
};

export default IndexVariants;
