import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';

const ConsentSparkForm = ({ panelVersion }) => (
  <div className="col-md-8 offset-md-2">
    <div className="form-check checkbox long-question-option">
      <Field
        name="consentSigned"
        id="consentSigned"
        component={Checkbox}
        label={(
          <div>
            <p>By checking this box I affirm:</p>
            {/* Support deprecated product */}
            {panelVersion === 1 && (
              <ol>
                <li>
                  The patient has fully completed and signed the My Retina
                  Tracker genetic testing study informed consent form
                </li>
                <li>
                  I will retain a full copy of all pages of the signed informed
                  consent on file and make it available for audit if requested
                </li>
              </ol>
            )}
            {panelVersion !== 1 && (
              <ul>
                <li style={{ 'list-style-type': 'none' }}>
                  The patient has fully completed and signed the My Retina
                  Tracker genetic testing study informed consent form. I will
                  retain a full copy of all pages of the signed My Retina
                  Tracker Genetic Testing Study informed consent on file and
                  make it available for audit if requested.
                </li>
              </ul>
            )}
          </div>
        )}
        labelClassName="consentSigned"
        className="text-left"
      />
    </div>
  </div>
);

ConsentSparkForm.propTypes = {
  panelVersion: PropTypes.number.isRequired,
};

export default ConsentSparkForm;
