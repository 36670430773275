import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Input.scss';

const ToggleCheckbox = ({
  checkboxText, htmlFor, ...props
}) => {
  const cx = classNames.bind(styles);

  const checkboxControl = cx('checkbox_form_control');

  return (
    <span className={checkboxControl}>
      <input
        type="checkbox"
        {...props}
      />
      <label
        htmlFor={htmlFor}
      >
        {checkboxText}
      </label>
    </span>
  );
};

ToggleCheckbox.propTypes = {
  checkboxText: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default ToggleCheckbox;
