import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { aliasUserIdSelector } from 'redux/modules/auth';
import { shouldShowFVTLink } from 'utils/orderRoles';
import isVusClarificationServiceOrder from 'utils/vusClarificationService';
import { VUS_CLARIFICATION_SERVICE_PRODUCT_NAME } from 'helpers/constants/testTypeConstants';
import { isUsRegion } from 'utils/envUtils';
import styles from './_OrderReportHeader.scss';

const OrderReportHeader = (props) => {
  const aliasUserId = useSelector(aliasUserIdSelector);
  const {
    orderInfo, orderReport, getReportFile, isEmpty, userInfo, onOrderFVTButtonClick,
  } = props;
  const isVusClarificationService = isVusClarificationServiceOrder(orderInfo);

  const [fvtError, setFvtError] = useState(false);

  const familyMembers = orderReport.available_fm_reports || [];
  const patientName = [
    orderInfo.patient_first_name,
    orderInfo.patient_last_name,
  ].join(' ');

  const handleFVTOrderButtonClick = async () => {
    try {
      await onOrderFVTButtonClick(orderInfo.order_id, orderReport, aliasUserId);
    } catch (error) {
      setFvtError(true);
    }
  };

  const getProductName = () => {
    if (isVusClarificationService) {
      return VUS_CLARIFICATION_SERVICE_PRODUCT_NAME;
    }
    return orderInfo.product.name;
  };

  return (
    <div>
      <h1>{getProductName()}</h1>

      <div className="row">
        <div className="col-lg-6">
          <h5>
            Order ID
            {' '}
            {orderInfo.order_id}
          </h5>
          {orderReport.revised_text && <b>{orderReport.revised_text}</b>}

          <h4>{patientName}</h4>

          <div>
            {!isVusClarificationService && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => getReportFile(orderInfo.order_id)}
              >
                Print report
              </button>
            )}
            {shouldShowFVTLink(orderReport.product_operations, userInfo, aliasUserId) && !isUsRegion() && (
              <button
                className={`${styles.fvt_order_btn} btn btn-primary`}
                id="order-fvt-btn"
                href="#"
                type="button"
                onClick={handleFVTOrderButtonClick}
              >
                Order Familial Variant Testing
              </button>
            )}

            {fvtError && (
              <div>
                <span className="text-danger">Error in creating FVT order</span>
              </div>
            )}
          </div>
        </div>

        {!!familyMembers.length && (
          <div className="col-lg-6">
            <h5>Secondary findings</h5>
            <ul className="list-unstyled">
              {familyMembers.map((member) => {
                const name = [member.first_name, member.last_name].join(' ');

                return (
                  <li key={name}>
                    <div>
                      <h4 className={styles.family_member}>
                        {name}
                        {' '}
                        <small>
                          (
                          {member.relationship}
                          )
                        </small>
                      </h4>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => getReportFile(orderInfo.order_id, member.label)}
                      >
                        Print report
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>

      {orderReport.follow_up_reports
        && !isEmpty(orderReport.follow_up_reports) && (
          <div className="report-section">
            <h2>Follow-up report</h2>
            {Object.keys(orderReport.follow_up_reports).map((key) => (
              <div key={key}>
                <p>
                  {'Follow-up '}
                  {orderReport.follow_up_reports[key].date_str}
                </p>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{
                  __html: orderReport.follow_up_reports[key].follow_up_text,
                }}
                />
              </div>
            ))}
          </div>
      )}
    </div>
  );
};

OrderReportHeader.propTypes = {
  orderInfo: PropTypes.shape({
    order_id: PropTypes.string,
    patient_first_name: PropTypes.string,
    patient_last_name: PropTypes.string,
    product: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    test_type: PropTypes.string,
  }).isRequired,
  orderReport: PropTypes.shape({
    revised_text: PropTypes.string,
    available_fm_reports: PropTypes.arrayOf(PropTypes.shape({})),
    follow_up_reports: PropTypes.objectOf(PropTypes.shape({
      date_str: PropTypes.string,
      follow_up_text: PropTypes.string,
    })),
    product_operations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  getReportFile: PropTypes.func.isRequired,
  isEmpty: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
    is_superuser: PropTypes.bool,
    hospital_name: PropTypes.string,
  }).isRequired,
  onOrderFVTButtonClick: PropTypes.func.isRequired,
};

export default OrderReportHeader;
