import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';
import moment from 'moment';

const convertToDateMonth = (inputDate) => moment(inputDate).local().format('MMMM D');

const DeployCountdown = ({ deployStart, deployEnd, isLoginPage }) => {
  const start = moment(deployStart).local().format('MMMM Do YYYY [at] h:mm:ss a');
  const end = moment(deployEnd).local().format('MMMM Do YYYY [at] h:mm:ss a');

  return (
    <Countdown
      date={deployStart}
      renderer={({
        days,
        hours,
        minutes,
        seconds,
        completed,
      }) => {
        let countDownClasses = 'deploy-countdown-banner';
        if (isLoginPage) {
          countDownClasses += ' deploy-countdown-banner-login-page';
        }
        if (completed) {
          return (
            <div className={countDownClasses}>
              {`Service maintenance is ongoing. Please save all changes when placing the order. Nucleus will be fully available again on ${end}.`}
            </div>
          );
        }

        // Render a countdown
        if (String(process.env.REACT_APP_REGION) === 'US') {
          return (
            <div className={countDownClasses}>
              <span>
                {' '}
                Effective
                {` ${convertToDateMonth(deployStart)}`}
                , Blueprint Genetics will transition laboratory services to our global headquarters
                and laboratory in Helsinki, Finland and discontinue providing lab services from the US.
                For more information, please visit
                <a href="//blueprintgenetics.com/us-lab-operations/" className="link-pink" target="_blank" rel="noopener noreferrer">
                  {' https://blueprintgenetics.com/us-lab-operations/'}
                </a>
                .
              </span>
            </div>
          );
        }
        return (
          <div className={countDownClasses}>
            {`Service maintenance break will start on ${start} and end on ${end}. `}
            Nucleus will be temporarily unavailable during this time. Please save all changes before the break.
            <br />
            Service break starts in
            <span className="text-bold text-pink">
              {` ${days} day(s), ${hours} hour(s), ${minutes} minute(s) and ${seconds} second(s)`}
            </span>
          </div>
        );
      }}
    />
  );
};

DeployCountdown.propTypes = {
  deployStart: PropTypes.string.isRequired,
  deployEnd: PropTypes.string.isRequired,
  isLoginPage: PropTypes.bool.isRequired,
};

export default DeployCountdown;
