import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import classNames from 'classnames';
import { changeNotificationOrderId } from 'redux/modules/orders';
import {
  getNotifications,
  readNotification,
} from 'redux/modules/notifications';
import { withRouter } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Spinner } from 'components';

import styles from './NotificationDropdown.scss';

const mapStateToProps = (state) => ({
  loadingNotification: state.notifications.loading,
  notifications: state.notifications.notifications,
  newNotificationsCount: state.notifications.newNotificationsCount,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  changeNotificationOrderId,
  getNotifications,
  readNotification,
}, dispatch);

class NotificationDropdown extends Component {
  static propTypes = {
    newNotificationsCount: PropTypes.number,
    getNotifications: PropTypes.func.isRequired,
    loadingNotification: PropTypes.bool,
    notifications: PropTypes.arrayOf(PropTypes.shape({})),
    readNotification: PropTypes.func.isRequired,
    changeNotificationOrderId: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    newNotificationsCount: 0,
    loadingNotification: false,
    notifications: [],
  };

  navigateFromNotification = (notification) => {
    const { location } = this.props;

    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this.toggle);
    if (node && node.click) {
      node.click();
    }

    this.markNotification(notification.notification_id);

    if (notification.message === 'Results ready' || notification.message === 'Updated report') {
      if (location.pathname !== `/orders/report/${notification.order_id}`) {
        this.props.history.push(`/orders/report/${notification.order_id}`);
      }
    } else {
      this.props.changeNotificationOrderId(notification.order_id);
      this.props.history.push(`/orders?search=${notification.order_id}`);
    }
  }

  markNotification = (id) => {
    this.props.readNotification(id)
      .then(() => this.props.getNotifications());
  }

  render() {
    const {
      newNotificationsCount,
      loadingNotification,
      notifications,
    } = this.props;

    const tooltip = (
      <Tooltip bsPrefix={`${styles.notification_tooltip} tooltip`} id="tooltip">Mark as Read</Tooltip>
    );

    const title = (
      <div>
        <i className={`${styles.notification_icon} glyphicon glyphicon-lg glyphicon-bell`} aria-hidden="true" />

        {newNotificationsCount !== 0 && (
          <span className={`${styles.notification_number} notification-count`}>{newNotificationsCount}</span>
        )}
      </div>
    );

    /*
      eslint-disable
      jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions,
      jsx-a11y/anchor-is-valid,
      jsx-a11y/no-noninteractive-element-interactions
    */
    return (
      <NavDropdown
        alignRight
        id="notification-dropdown"
        title={title}
        className={styles.notification_dropdown}
        ref={(toggle) => {
          this.toggle = toggle;
        }}
      >

        <div className={styles.overflow}>
          <div className={styles.get_notifications_link}>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => this.props.getNotifications()}
            >
              Get notifications
            </button>
            <span className={styles.status_heading}>Status</span>
          </div>

          {loadingNotification && (
            <div className={styles.menu_spinner}>
              <Spinner />
            </div>
          )}

          {!loadingNotification && notifications.map((notification) => (
            <NavDropdown.Item
              key={notification.notification_id}
              className={classNames(styles[`notification_item_${notification.priority}`], styles.notification_list_item, {
                [styles.notification_list_item_new]: !!notification.is_new,
              })}
              onClick={() => this.navigateFromNotification(notification)}
            >
              <span className={`${styles.notification_message} notification-message`}>
                {
                  notification.order_id
                    ? `${notification.message} for ID: ${notification.order_id}`
                    : notification.message
                }
              </span>

              <span className={`${styles.notification_mark} mark-notification-as-read`}>
                {notification.is_new && (
                  <OverlayTrigger placement="left" overlay={tooltip}>
                    <i
                      className={`${styles.unread_icon} ${styles.item_icon}`}
                      aria-hidden="true"
                    />
                  </OverlayTrigger>
                )}

                {!notification.is_new && (
                  <i className={`${styles.read_icon} ${styles.item_icon}`} aria-hidden="true" />
                )}

                <br />
              </span>
            </NavDropdown.Item>
          ))}

          {!loadingNotification && notifications.length === 0 && (
            <div className={`${styles.notification_list_item} text-center ${styles.disabled}`}>
              <span className="text-light-gray">You have no notification.</span>
            </div>
          )}
        </div>
      </NavDropdown>
    );
    /*
      eslint-enable
      jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions,
      jsx-a11y/anchor-is-valid,
      jsx-a11y/no-noninteractive-element-interactions
    */
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationDropdown);
