import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import Textarea from 'components/Formik/Textarea';
import Checkbox from 'components/Formik/Checkbox';
import OrderLogs from 'components/OrderLogs/OrderLogs';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';

import { fullPanelName } from 'utils/forms';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import DeliveryInfoPropTypes from 'types/DeliveryInfoPropTypes';
import LogPropTypes from 'types/LogPropTypes';
import ConfirmationLabel from './ConfirmationLabel';
import GenesList from './GenesList';

const ConfirmOrderBody = ({
  orderInfo,
  deliveryInfo,
  logs,
  skipFields,
  areInternalCommentsShown,
  panelTierText,
  geneCountText,
  isSupportUser,
  questions,
}) => {
  const isOrderComplete = orderInfo.is_order_completed;
  const isNovodetectProduct = orderInfo.product.is_novodetect_product;
  const skipGeneralTerms = skipFields.general_terms;
  return (
    <div>
      {areInternalCommentsShown && (
        <div>
          <h4>Comments</h4>
          <p>Internal comments (for support and geneticist use only)</p>
          <Field
            name="internal_comments"
            component={Textarea}
            labelSize={0}
            inputSize={12}
          />
        </div>
      )}

      <h4>Order summary</h4>

      <p
        className="h5"
        data-cy="order-confirm-panel-name"
      >
        {fullPanelName(orderInfo)}
      </p>

      {panelTierText && (
        <p
          data-cy="order-confirm-panel-tier"
        >
          {panelTierText}
        </p>
      )}

      {geneCountText && (
        <p
          data-cy="order-confirm-panel-tier"
        >
          {geneCountText}
        </p>
      )}

      {orderInfo.is_customized && (
        <div>
          {orderInfo.original_panel_genes && (
            <GenesList
              label="Original genes"
              genes={orderInfo.original_panel_genes}
            />
          )}

          {orderInfo.masked_genes && (
            <GenesList
              label="Removed genes"
              genes={orderInfo.masked_genes}
            />
          )}

          {orderInfo.added_genes && (
            <GenesList
              label="Added genes"
              genes={orderInfo.added_genes}
            />
          )}
        </div>
      )}

      {deliveryInfo && deliveryInfo.mail_subscription && (
        <p>Delivery of results by regular mail</p>
      )}

      {deliveryInfo && deliveryInfo.fax_subscription && (
        <p>Delivery of results by fax</p>
      )}

      <br />

      <div>
        <p>
          The price for the purchased services is presented at the Blueprint
          Genetics
          {' '}
          <a
            className="link-pink"
            href="//blueprintgenetics.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            website
          </a>
          . Custom pricing applies if separately agreed between your
          institution and Blueprint Genetics.
        </p>
        <br />

        <PatientHistoryQuestions questions={questions} oneColumn />
      </div>

      {!isOrderComplete && !skipGeneralTerms && !isSupportUser && !isNovodetectProduct && (
        <Field
          name="confirm_order"
          component={Checkbox}
          label={<ConfirmationLabel orderInfo={orderInfo} />}
          className=""
          labelClassName=""
        />
      )}

      {logs && !!Object.keys(logs).length && (
        <div>
          <h3 className="h4">Change history</h3>
          <OrderLogs logs={logs} />
        </div>
      )}
    </div>
  );
};

ConfirmOrderBody.propTypes = {
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
  deliveryInfo: PropTypes.shape(DeliveryInfoPropTypes),
  logs: PropTypes.objectOf(PropTypes.shape(LogPropTypes)),
  skipFields: PropTypes.shape({
    general_terms: PropTypes.bool,
  }).isRequired,
  areInternalCommentsShown: PropTypes.bool.isRequired,
  panelTierText: PropTypes.string,
  geneCountText: PropTypes.string,
  isSupportUser: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    question_id: PropTypes.number.isRequired,
    is_hidden_by_default: PropTypes.bool,
    selected_option_id: PropTypes.string,
    question_options: PropTypes.arrayOf(PropTypes.shape({
      question_option_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      conditional_question_id: PropTypes.number,
    })),
  })).isRequired,
};

ConfirmOrderBody.defaultProps = {
  deliveryInfo: null,
  logs: null,
  panelTierText: null,
  geneCountText: null,
};

export default ConfirmOrderBody;
