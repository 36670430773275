import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from '../../theme/variables.scss';

import ErrorMessage from './ErrorMessage';

const RadioButtonGroup = ({
  name,
  label,
  required,
  hint,
  children,
  labelSize,
  inputSize,
  isHighlighted,
}) => (

  <div className="form-group row" style={isHighlighted ? { backgroundColor: styles.highLightedQuestion } : {}}>
    <label className={`col-md-${labelSize} col-form-label`}>
      {required ? `${label} *` : label}
      {hint && (<p className="small">{hint}</p>)}
    </label>
    <div className={classNames(`col-md-${inputSize}`, {
      // Bootstrap has max 12 columns
      'no-padding-left': inputSize !== 12,
    })}
    >
      {children}

      <ErrorMessage name={name} />
    </div>
  </div>
);

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hint: PropTypes.string,
  children: PropTypes.node.isRequired,
  labelSize: PropTypes.number,
  inputSize: PropTypes.number,
  isHighlighted: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  required: false,
  hint: null,
  labelSize: 6,
  inputSize: 6,
  isHighlighted: false,
};

export default RadioButtonGroup;
