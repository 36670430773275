import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Page, { handlePagePromise } from 'components/Page';
import Spinner from 'components/Spinner/Spinner';
import * as supportActions from 'redux/modules/support';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createBlankOrder: supportActions.createBlankOrder,
}, dispatch);

class NewBlankOrderPage extends Component {
  prompted = false;

  static propTypes = {
    // Fields
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,

    // Methods
    createBlankOrder: PropTypes.func.isRequired,
  };

  state = {
    page: {
      loading: false,
      error: null,
    },
  };

  createBlankOrder = async () => {
    const {
      history,
      createBlankOrder,
    } = this.props;

    const result = await createBlankOrder();
    history.replace(`/orders/${result.order_id}/edit`);
  }

  renderPage = () => {
    if (!this.prompted) {
      this.prompted = true;

      handlePagePromise(this.createBlankOrder(), this);
    }

    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  render() {
    const {
      page,
    } = this.state;

    return (
      <Page
        title="Blank order"
        render={this.renderPage}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NewBlankOrderPage);
