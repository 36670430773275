import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { requiredPositiveInteger } from 'components/Formik/validation';
import { useFormikContext, Field } from 'formik';

import { EXOME_PLUS, EXOME_FAMILY_PLUS, MSD } from 'helpers/constants/specialTypeConstants';
import { fullPanelName } from 'utils/forms';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import Checkbox, { defaultOnChange as defaultCheckboxOnChange } from 'components/Formik/Checkbox';
import LabelInput from 'components/Formik/LabelInput';

const canExpandToExome = (specialType, isOldWesProduct, isAfterIteration17) => {
  // Expand to exome no longer available for WE0301 and WE0401 after iteration 17
  if (isOldWesProduct && isAfterIteration17) {
    return false;
  }

  switch (specialType) {
    case EXOME_PLUS:
    case EXOME_FAMILY_PLUS:
    case MSD: {
      return true;
    }
    default: {
      return false;
    }
  }
};

const ReflexOrderSection = ({ orderInfo }) => {
  const { values, setFieldError } = useFormikContext();

  const [focusReflexOrderId, setFocusReflexOrderId] = useState(false);

  const specialType = orderInfo.special_type;
  const panelName = fullPanelName(orderInfo);

  const finalOrderDateText = orderInfo.hospital_is_us_or_latin_america ? ' submitted before April 12, 2021' : '';

  const isAfterIteration17 = orderInfo.is_after_iteration_17;

  const isOldWesProduct = orderInfo.is_old_wes;

  const checkReflexOrderId = async () => {
    const reflexOrderId = values.reflex_order_id;

    try {
      await requiredPositiveInteger('* Order ID must be a number (eg. 12345)').validate(reflexOrderId);
    } catch (e) {
      setFieldError('reflex_order_id', e.message);
    }
  };

  if (canExpandToExome(specialType, isOldWesProduct, isAfterIteration17)) {
    return (
      <div>
        <Field
          name="expand_to_exome"
          component={Checkbox}
          label={specialType === MSD ? 'Expand to Development Disorder Panel' : 'Expand to Exome'}
          labelClassName="view_order_modal_label"
          className="modal_checkbox"
          onChange={(form, field) => {
            defaultCheckboxOnChange(form, field);
            setFocusReflexOrderId(true);
          }}
        />

        <div className="small">
          Expand your patient&apos;s previous test done at Blueprint Genetics
          (available for panel and single gene orders
          {finalOrderDateText}
          ) to
          {` ${panelName}. `}
          {specialType !== MSD && (
            <a
              href="https://blueprintgenetics.com/expansionservice/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more
            </a>
          )}
        </div>

        {values.expand_to_exome && (
          <div className="stable-layout">
            <Field
              type="number"
              name="reflex_order_id"
              component={LabelInput}
              label="Order ID of previous test"
              required
              autoFocus={focusReflexOrderId}
              onBlur={checkReflexOrderId}
            />
          </div>
        )}
      </div>
    );
  }

  if (values.reflex_order_id) {
    return (
      <div>
        <label className="view_order_modal_label">
          Order ID of previous test
        </label>
        <span>
          {values.reflex_order_id}
        </span>
      </div>
    );
  }

  return null;
};

ReflexOrderSection.propTypes = {
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
};

export default ReflexOrderSection;
