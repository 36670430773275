import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { OrderLogs } from 'components';
import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import {
  saveOrderComments,
  saveInternalComments,
  getOrderComments,
  getOrderLogs,
} from 'redux/modules/orders';

const validationSchema = Yup.object().shape({
  testComment: Yup.string(),
  internalComments: Yup.string(),
});

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderComments: state.orders.comments,
  orderInfo: state.orders.orderInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveOrderComments,
  saveInternalComments,
  getOrderComments,
  getOrderLogs,
}, dispatch);

class ViewTestComment extends Component {
  static propTypes = {
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
    }).isRequired,
    orderInfo: PropTypes.shape({
      order_id: PropTypes.number.isRequired,
      internal_comments: PropTypes.string,
    }).isRequired,
    orderComments: PropTypes.shape({}),
    hideModal: PropTypes.func.isRequired,
    getOrderComments: PropTypes.func.isRequired,
    getOrderLogs: PropTypes.func.isRequired,
    saveOrderComments: PropTypes.func.isRequired,
    saveInternalComments: PropTypes.func.isRequired,
  };

  static defaultProps = {
    orderComments: null,
  };

  state = {
    logs: null,
  };

  componentDidMount() {
    const orderId = this.props.orderInfo.order_id;
    this.props.getOrderComments({
      order_id: orderId,
    });

    this.props.getOrderLogs(orderId).then((result) => {
      this.setState({
        logs: result,
      });
    });
  }

  render() {
    const {
      orderComments,
      userInfo,
      orderInfo,
      hideModal,
    } = this.props;

    const {
      logs,
    } = this.state;

    let questions = [];
    let comments = [];

    if (orderComments) {
      questions = Object.values(orderComments).map((comment) => comment.question);
      comments = Object.values(orderComments).map((comment) => comment.answer);
    }

    const initialValues = {
      testComment: comments[0] || '',
      internalComments: orderInfo.internal_comments || '',
    };

    return (
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          actions.setSubmitting(false);

          await this.props.saveOrderComments({
            order_id: orderInfo.order_id,
            comments_txt: values.testComment,
          });

          if (userInfo.user_role === 'support' || userInfo.user_role === 'geneticist') {
            this.props.saveInternalComments(orderInfo.order_id, values.internalComments || '');
          }

          actions.resetForm({ values: initialValues });
        }}
      >
        {(formProps) => (
          <Form>
            <PromptIfDirty {...formProps} />

            <ViewOrderStep
              {...formProps}
              hideModal={hideModal}
              error={formProps.errors.general}
              submit={() => formProps.submitForm()}
            >
              <h4>Comments</h4>
              <div className="form-group">
                <p>{questions[0]}</p>
                <Field
                  className="form-control"
                  component="textarea"
                  name="testComment"
                  id="testComment"
                  disabled={userInfo.user_role === 'geneticist'}
                />
                <ErrorMessage name="testComment" />
                {(userInfo.user_role === 'support'
                  || userInfo.user_role === 'geneticist') && (
                    <div>
                      <p>
                        Internal comments (for support and geneticist use only)
                      </p>
                      <div className="form-group">
                        <Field
                          className="form-control"
                          component="textarea"
                          name="internalComments"
                          id="internalComments"
                          disabled={userInfo.user_role === 'geneticist'}
                        />
                        <ErrorMessage name="internalComments" />
                      </div>
                    </div>
                )}
              </div>

              {logs && !!Object.keys(logs).length && (
                <div>
                  <h3 className="h4">Change history</h3>
                  <OrderLogs logs={logs} />
                </div>
              )}
            </ViewOrderStep>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTestComment);
