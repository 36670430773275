import range from 'lodash/range';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

import ErrorMessage from './ErrorMessage';

const YEARS = range(new Date().getFullYear(), 1900 - 1);
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DEFAULT_DATE_OBJ = { year: 0, month: 0, day: 0 };

export function parseDate(str) {
  if (!str) {
    return { ...DEFAULT_DATE_OBJ };
  }

  const [year, month, day] = str.split('-').map((d) => parseInt(d, 10) || 0);

  return {
    year,
    month,
    day,
  };
}

export function padZeros(value, length) {
  let paddedStr = value.toString();

  while (paddedStr.length < length) {
    paddedStr = `0${paddedStr}`;
  }

  return paddedStr;
}

export function isMatchingDate(a, b) {
  if (!a || !b) return false;
  return a.year === b.year && a.month === b.month && a.day === b.day;
}

export function isValidDateObj(dateObj) {
  if (!dateObj) return false;
  return dateObj.year > 0 && dateObj.month > 0 && dateObj.day > 0;
}

export const dateToString = (dateObj) => [
  padZeros(dateObj.year, 4),
  padZeros(dateObj.month, 2),
  padZeros(dateObj.day, 2),
].join('-');

export function getDaysAtDate(dateObj) {
  return dateObj.year && dateObj.month ? new Date(dateObj.year, dateObj.month, 0).getDate() : 0;
}

export function getDaysList(dateObj) {
  if (!dateObj || !dateObj.year || !dateObj.month) return [];
  return range(1, getDaysAtDate(dateObj) + 1);
}

function SimpleDatePicker(props) {
  const {
    form, field, onChange, label, labelSize, inputSize, required, preview,
  } = props;

  const [selectedDate, setSelectedDate] = useState(DEFAULT_DATE_OBJ);
  if (field.value) {
    const fieldDateObj = parseDate(field.value);
    if (isValidDateObj(fieldDateObj) && !isMatchingDate(fieldDateObj, selectedDate)) {
      setSelectedDate(fieldDateObj); // Set initial value or value changed outside the component
    }
  }

  const handleDateChange = (dateKey) => (event) => {
    const dateValue = parseInt(event.target.value, 10);
    const dateObj = { ...selectedDate, [dateKey]: dateValue };
    const daysInMonth = getDaysAtDate(dateObj);
    if (daysInMonth < dateObj.day) {
      dateObj.day = 0;
    }
    const value = isValidDateObj(dateObj) ? dateToString(dateObj) : null;
    form.setFieldValue(field.name, value);
    if (typeof onChange === 'function') {
      onChange(value);
    }
    setSelectedDate(dateObj);
  };

  const { year, month, day } = selectedDate;
  const days = useMemo(() => getDaysList(selectedDate), [selectedDate]);

  return (
    <div className="form-group row">
      <label htmlFor={`${field.name}-year`} className={`col-md-${labelSize} col-form-label`}>
        {required ? `${label} *` : label}
      </label>

      <div className={`col-md-${inputSize} no-padding-left`}>
        <div className="birthday-select select">
          <span className="arrow" />
          <select
            className="form-control"
            id={`${field.name}-year`}
            name={`${field.name}-year`}
            value={year}
            onChange={handleDateChange('year')}
          >
            <option value={0} hidden disabled>
              Year
            </option>
            {YEARS.map((yearName) => (
              <option key={yearName} value={yearName}>
                {yearName}
              </option>
            ))}
          </select>
        </div>

        <div className="birthday-select select">
          <span className="arrow" />
          <select
            className="form-control"
            name={`${field.name}-month`}
            value={month}
            onChange={handleDateChange('month')}
          >
            <option value={0}>Month</option>
            {MONTH_NAMES.map((name, index) => (
              <option value={index + 1} key={name}>
                {name}
              </option>
            ))}
          </select>
        </div>

        <div className="birthday-select select">
          <span className="arrow" />
          <select
            className="form-control"
            disabled={!year || !month}
            name={`${field.name}-day`}
            value={day}
            onChange={handleDateChange('day')}
          >
            <option value={0}>Day</option>
            {days.map((dayName) => (
              <option key={dayName} value={dayName}>
                {dayName}
              </option>
            ))}
          </select>
        </div>

        {!preview && <ErrorMessage name={field.name} />}
      </div>
    </div>
  );
}

SimpleDatePicker.propTypes = {
  // Field
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    /* eslint-disable react/forbid-prop-types  */
    value: PropTypes.any,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func,

  // Appearance
  label: PropTypes.string.isRequired,
  labelSize: PropTypes.number,
  inputSize: PropTypes.number,
  required: PropTypes.bool,
  preview: PropTypes.bool,
};

SimpleDatePicker.defaultProps = {
  labelSize: 6,
  inputSize: 6,
  required: false,
  preview: false,
  onChange: null,
};

export default SimpleDatePicker;
