import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LabelInput from 'components/Formik/LabelInput';
import RadioButton, { defaultOnChange as defaultRadioButtonOnChange } from 'components/Formik/RadioButton';
import Textarea from 'components/Formik/Textarea';
import SelectInput from 'components/Formik/SelectInput';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';
import RadioButtonGroup from 'components/Formik/RadioButtonGroup';
import CheckboxGroup from 'components/Formik/CheckboxGroup';
import Checkbox from 'components/Formik/Checkbox';
import { Field } from 'formik';
import { questionAnswerField } from 'utils/patientQuestions';
import HIGHLIGHTED_QUESTIONS from 'helpers/constants/highlightedQuestionsConstants';

export function getQuestionOptions(question) {
  if (question.type === 'bool_slider') {
    return [{
      question_option_id: '1',
      question_option: 'Yes',
    }, {
      question_option_id: '0',
      question_option: 'No',
    }];
  }

  return question.question_options;
}

const PatientHistoryQuestion = ({
  question,
  namePrefix,
  oneColumn,
  isSupportUser,
  enableHTMLHint,
}) => {
  const fieldProps = {
    name: `${namePrefix}.${questionAnswerField(question)}`,
  };

  const [highlighted, setHighlighted] = useState(false);

  const isHighlightedQuestion = HIGHLIGHTED_QUESTIONS.some((a) => question.question.includes(a));

  const initHighlightedQuestion = () => {
    if (isHighlightedQuestion) {
      const selectedAnswer = question.question_options ? question.question_options.map((option) => {
        // eslint-disable-next-line react/prop-types
        if (option.question_option_id === question.selected_option_id) {
          return option.question_option;
        }
        return '';
      }) : '';
      /* yes, somehow it seems possible that "option.question_option" returns actually an array.
      However that only occasionally happens */
      const answerResult = question.selected_option_id && Array.isArray(selectedAnswer) ? selectedAnswer.join().replaceAll(',', '') : question.answer;

      if (isSupportUser && (answerResult === 'Yes' || answerResult === '1')) {
        setHighlighted(true);
      }
    }
  };

  const handleHighlight = (option) => {
    if (isHighlightedQuestion && option === 'Yes' && isSupportUser) {
      return setHighlighted(true);
    }
    return setHighlighted(false);
  };

  useEffect(() => {
    initHighlightedQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (oneColumn) {
    fieldProps.labelSize = 12;
    fieldProps.inputSize = 12;
  }

  let freeTextInput = null;
  if (question.free_text_option_question) {
    freeTextInput = (
      <Field name={`${namePrefix}.free_text_option_answer`}>
        {({ field }) => (
          <textarea
            {...field}
            value={field.value || ''}
            className="form-control additional-textarea"
            placeholder={question.free_text_option_question}
          />
        )}
      </Field>
    );
  }

  switch (question.type) {
    case 'dob':
      fieldProps.component = SimpleDatePicker;
      break;

    case 'select':
      fieldProps.component = SelectInput;
      fieldProps.options = question.question_options.map((option) => ({
        key: option.question_option_id,
        value: option.question_option,
      }));
      fieldProps.placeholder = 'Select';
      fieldProps.extraInput = freeTextInput;
      break;

    case 'radio':
    case 'bool_slider': {
      return (
        <RadioButtonGroup
          isHighlighted={highlighted}
          name={fieldProps.name}
          label={question.question}
          required={question.is_compulsory}
          hint={question.hint}
          labelSize={fieldProps.labelSize}
          inputSize={fieldProps.inputSize}
        >
          {getQuestionOptions(question).map((option) => (

            <Field
              key={`${fieldProps.name}-${option.question_option_id}`}
              component={RadioButton}
              name={fieldProps.name}
              value={option.question_option_id}
              label={option.question_option}
              onChange={(form, field, e) => {
                defaultRadioButtonOnChange(form, field, e);
                handleHighlight(option.question_option);
              }}
            />
          ))}

          {isSupportUser
            && !question.is_compulsory
            && question.answer
            && question.answer !== 'false'
            && (
              <Field
                key={`${fieldProps.name}_empty`}
                component={RadioButton}
                name={fieldProps.name}
                label="Remove selection"
                value={null}
                onChange={(form, field, e) => {
                  defaultRadioButtonOnChange(form, field, e);
                  handleHighlight('');
                }}
              />
            )}

          {freeTextInput}
        </RadioButtonGroup>
      );
    }

    case 'number':
      fieldProps.type = 'number';
      fieldProps.component = LabelInput;
      fieldProps.short = true;
      break;

    case 'checkbox':
      fieldProps.component = CheckboxGroup;
      fieldProps.options = question.question_options.map((option) => ({
        key: option.question_option_id,
        value: option.question_option,
      }));
      fieldProps.extraInput = freeTextInput;
      fieldProps.enableHTMLHint = enableHTMLHint;
      break;

    case 'single-checkbox':
      fieldProps.component = Checkbox;
      fieldProps.label = question.hint;
      fieldProps.labelClassName = '';
      break;

    case 'textarea':
      fieldProps.component = Textarea;
      break;

    case 'title':
      return (
        <div className="form-group">
          <p>
            <strong>{question.question.toUpperCase()}</strong>
          </p>
          <p>{question.hint}</p>
        </div>
      );

    default:
      fieldProps.component = LabelInput;
  }

  return (
    <Field
      {...fieldProps}
      label={question.question}
      hint={question.hint}
      suffix={question.unit}
      required={question.is_compulsory}
      disabled={question.disabled}
    />
  );
};

PatientHistoryQuestion.propTypes = {
  question: PropTypes.shape({
    question_id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    question_options: PropTypes.arrayOf(PropTypes.shape({})),
    free_text_option_question: PropTypes.string,
    question: PropTypes.string,
    is_compulsory: PropTypes.bool,
    hint: PropTypes.string,
    unit: PropTypes.string,
    disabled: PropTypes.bool,
    answer: PropTypes.shape({}),
    selected_option_id: PropTypes.number,
  }).isRequired,
  namePrefix: PropTypes.string.isRequired,
  // Defines how question label and input should be displayed
  // Default: two column layout
  oneColumn: PropTypes.bool,
  isSupportUser: PropTypes.bool,
  enableHTMLHint: PropTypes.bool,
};

PatientHistoryQuestion.defaultProps = {
  oneColumn: false,
  isSupportUser: false,
  enableHTMLHint: false,
};

export default PatientHistoryQuestion;
