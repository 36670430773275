import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportAppendixAddFindings extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
    appendixAddFindingsShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string
  };

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  };

  render() {
    const {
      appendixHeading,
      orderReport,
      appendixAddFindingsShown,
      onTogglePanel,
      isEmpty,
    } = this.props;
    const styles = require('./_OrderReportAppendixAddFindings.scss');
    let sortedVariantsArr;
    let sortedVariantsDeldupArr;
    let sortedVariantsMitoArr;
    let sortedVariantsMitoDeldupArr;
    if (orderReport.additional_findings) {
      const variantsArr = Object.keys(orderReport.additional_findings).map(key => { return orderReport.additional_findings[key]; });
      sortedVariantsArr = variantsArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }
    if (orderReport.additional_findings_del_dup) {
      const variantsDeldupArr = Object.keys(orderReport.additional_findings_del_dup).map(key => { return orderReport.additional_findings_del_dup[key]; });
      sortedVariantsDeldupArr = variantsDeldupArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }
    if (orderReport.additional_findings_mito) {
      const variantsMitoArr = Object.keys(orderReport.additional_findings_mito).map(key => { return orderReport.additional_findings_mito[key]; });
      sortedVariantsMitoArr = variantsMitoArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }
    if (orderReport.additional_findings_mito_del_dup) {
      const variantsMitoDeldupArr = Object.keys(orderReport.additional_findings_mito_del_dup).map(key => { return orderReport.additional_findings_mito_del_dup[key]; });
      sortedVariantsMitoDeldupArr = variantsMitoDeldupArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixAddFindingsShown')}>
          {appendixHeading}
          {appendixAddFindingsShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixAddFindingsShown ? '' : 'invisible'}>
          <div dangerouslySetInnerHTML={{__html: orderReport.additional_findings_description}} />
          <div className="appendix-findings-variant-table">
            {sortedVariantsArr &&
              <h3 className="h4">{orderReport.additional_findings_title}</h3>
            }
            {sortedVariantsArr &&
              <table className="table v6-variants-table">
                {sortedVariantsArr.map((variant, index) =>
                  <tbody key={index}>
                    <tr>
                      <td>
                        <span className="report-td-heading">Gene</span><br/>
                         <a className="link-gray" href={variant.gene_url}><span>{variant.gene}</span></a>
                      </td>
                      <td>
                        <span className="report-td-heading">Pos</span><br/>
                         <span>{variant.pos}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Transcript</span><br/>
                         <span>{variant.transcript}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Nomenclature</span><br/>
                         <span>{variant.nomenclature}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Consequence</span><br/>
                         <span>{variant.consequence}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Genotype</span><br/>
                         <span>{variant.genotype}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Classification</span><br/>
                         <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <span className="report-td-heading">ID</span><br/>
                        {variant.dbsnp_id_url &&
                          <a className="link-gray" href={variant.dbsnp_id_url}><span>{variant.dbsnp_id}</span></a>
                        }
                        {!variant.dbsnp_id_url &&
                          <span>{variant.dbsnp_id}</span>
                        }
                      </td>
                      {variant.gnomad &&
                        <td>
                          <span className="report-td-heading">gnomAD</span><br/>
                           <span>{variant.gnomad}</span>
                        </td>
                      }
                      {variant.exac &&
                        <td>
                          <span className="report-td-heading">EXAC AC/AN</span><br/>
                           <span>{variant.exac}</span>
                        </td>
                      }
                      <td>
                        <span className="report-td-heading">Polyphen</span><br/>
                         <span className={this.textColorClass(variant.polyphen.class)}>{variant.polyphen.value}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Sift</span><br/>
                         <span className={this.textColorClass(variant.sift.class)}>{variant.sift.value}</span>
                      </td>
                      <td colSpan="2">
                          <span className="report-td-heading">Muttaster</span><br/>
                          <span className={this.textColorClass(variant.muttaster.class)}>{variant.muttaster.value}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <a className="link-gray" href={variant.omim_url}><span>{variant.omim}</span></a>
                      </td>
                      <td colSpan="2">
                        <span className="report-td-heading">Phenotype</span><br/>
                         <span>{variant.phenotype}</span>
                      </td>
                      <td>
                        <span className="report-td-heading">Inheritance</span><br/>
                         <span>{variant.inheritance}</span>
                      </td>
                      <td colSpan="2">
                        <span className="report-td-heading">Comment</span><br/>
                         <span>{variant.comment}</span>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            }
          </div>

          {orderReport.show_additional_comments &&
            <div>
              <h3 className="h4">{orderReport.additional_comments_title}</h3>
              <div dangerouslySetInnerHTML={{__html: orderReport.additional_comments}}></div>
            </div>
          }

          <div className="appendix-findings-variant-table">
            {sortedVariantsMitoArr &&
              <h3 className="h4">{orderReport.additional_findings_mito_title}</h3>
            }
            {sortedVariantsMitoArr &&
              <table className="table v6-variants-table">
                  {sortedVariantsMitoArr.map((variant, index) =>
                    <tbody key={index}>
                      <tr>
                        <td>
                          <span className="report-td-heading">Gene</span><br/>
                           <a className="link-gray" href={variant.gene_url}><span>{variant.gene}</span></a>
                        </td>
                        <td>
                          <span className="report-td-heading">Pos</span><br/>
                           <span>{variant.pos}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">AF</span><br/>
                           <span>{variant.af}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Level</span><br/>
                           <span>{variant.level}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">GT</span><br/>
                           <span>{variant.genotype}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Transcript</span><br/>
                          <span>{variant.transcript}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Nomenclature</span><br/>
                          <span>{variant.nomenclature}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Consequence</span><br/>
                          <span>{variant.consequence}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Classification</span><br/>
                          <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td colSpan="3">
                          <span className="report-td-heading">HMTVAR</span><br/>
                           <span>{variant.hmtvar}</span>
                        </td>
                        <td colSpan="3">
                          <span className="report-td-heading">Phenotype</span><br/>
                           <span>{variant.phenotype}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Inheritance</span><br/>
                           <span>{variant.inheritance}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Comment</span><br/>
                           <span>{variant.comment}</span>
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
            }
          </div>

          {orderReport.show_additional_mito_comments &&
            <div>
              <h3 className="h4">{orderReport.additional_mito_comments_title}</h3>
              <div dangerouslySetInnerHTML={{__html: orderReport.additional_mito_comments}}></div>
            </div>
          }

          <div className="appendix-findings-variant-table">
            {sortedVariantsDeldupArr &&
              <h3 className="h4">{orderReport.additional_findings_del_dup_title}</h3>
            }
            {sortedVariantsDeldupArr &&
              <table className="table v6-variants-table">
                  {sortedVariantsDeldupArr.map((variant, index) =>
                    <tbody key={index}>
                      <tr>
                        <td>
                          <span className="report-td-heading">Gene</span><br/>
                           <a className="link-gray" href={variant.gene_url}><span>{variant.gene}</span></a>
                        </td>
                        <td>
                          <span className="report-td-heading">Event</span><br/>
                           <span>{variant.event}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Copy number</span><br/>
                           <span>{variant.copy_number}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Genotype</span><br/>
                           <span>{variant.genotype}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Impact</span><br/>
                           <span>{variant.impact}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Links</span><br/>
                          <a className="link-gray" href={variant.links}><span>{variant.links_txt}</span></a>
                        </td>
                        <td>
                          <span className="report-td-heading">Classification</span><br/>
                          <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <a className="link-gray" href={variant.omim_url}><span>{variant.omim}</span></a>
                        </td>
                        <td colSpan="3">
                          <span className="report-td-heading">Phenotype</span><br/>
                           <span>{variant.phenotype}</span>
                        </td>
                        <td colSpan="2">
                          <span className="report-td-heading">Comment</span><br/>
                           <span>{variant.comment}</span>
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
            }
          </div>
          <div className="appendix-findings-variant-table">
            {!isEmpty(sortedVariantsMitoDeldupArr) &&
              <h3 className="h4">{orderReport.additional_findings_mito_del_dup_title}</h3>
            }
            {!isEmpty(sortedVariantsMitoDeldupArr) &&
              <table className="table v6-variants-table">
                  {sortedVariantsMitoDeldupArr.map((variant, index) =>
                    <tbody key={index}>
                      <tr>
                        <td>
                          <span className="report-td-heading">Pos</span><br/>
                          <span>{variant.pos}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Size</span><br/>
                           <span>{variant.size}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">Event</span><br/>
                           <span>{variant.event}</span>
                        </td>
                        <td>
                          <span className="report-td-heading">AF</span><br/>
                           <span>Heteroplasmy Fraction</span>
                        </td>
                        <td>
                          <span className="report-td-heading">classification</span><br/>
                          <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="report-td-heading">Inheritance</span><br/>
                           <span>{variant.inheritance}</span>
                        </td>
                        <td colSpan="2">
                          <span className="report-td-heading">Phenotype</span><br/>
                           <span>{variant.phenotype}</span>
                        </td>
                        <td colSpan="2">
                          <span className="report-td-heading">Genes</span><br/>
                           <span>{variant.gene}</span>
                        </td>
                      </tr>
                    </tbody>
                  )}
              </table>
            }
          </div>

          {orderReport.show_additional_mitocnv_comments &&
            <div>
              <h3 className="h4">{orderReport.additional_mitocnv_comments_title}</h3>
              <div dangerouslySetInnerHTML={{__html: orderReport.additional_mitocnv_comments}}></div>
            </div>
          }
        </div>
      </div>
    );
  }
}
