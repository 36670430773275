// eslint-disable-next-line import/prefer-default-export
export const getPanelTierForOrder = (orderInfo, panelTiers) => (
  orderInfo.product.panel_tier_id
  && panelTiers ? panelTiers.find((pt) => pt.panel_tier_id === orderInfo.product.panel_tier_id)
    : null
);

export const getPricingModelForOrder = (orderInfo, userInfo) => {
  // If a support user edits/converts/transfers an order, the pricing_model should be the
  // order user's pricing model and not that of support user
  if (orderInfo && orderInfo.user_hospital_pricing_model) {
    return orderInfo.user_hospital_pricing_model;
  }
  return userInfo.pricing_model;
};
