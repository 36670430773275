import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'components';
import { downloadFileFunc } from 'utils/forms';
import { steps as InsuranceCaseEditModalSteps } from '../InsuranceCaseEditModal/InsuranceCaseEditModal';

import styles from './InsuranceCaseDetailModal.scss';

export default class InsuranceCaseDetailModal extends Component {
  static propTypes = {
    hide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    resetInsuranceInfo: PropTypes.func.isRequired,
    resetOrderInfo: PropTypes.func.isRequired,
    getInsuranceItems: PropTypes.func.isRequired,
    getInsuranceStatus: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    getOrderFile: PropTypes.func.isRequired,
    billingInfoDetails: PropTypes.shape({
      insurance_company: PropTypes.string.isRequired,
      insurance_id_no: PropTypes.string.isRequired,
      group_no: PropTypes.string.isRequired,
      patient_relation: PropTypes.string.isRequired,
      policy_holder_name: PropTypes.string,
      policy_holder_dob: PropTypes.string,
      patient_address: PropTypes.string.isRequired,
      patient_email: PropTypes.string,
      patient_phone: PropTypes.string,
    }).isRequired,
    orderInfo: PropTypes.shape({
      order_id: PropTypes.number.isRequired,
      patient_last_name: PropTypes.string.isRequired,
      patient_first_name: PropTypes.string.isRequired,
      patient_dob: PropTypes.string.isRequired,
      product: PropTypes.shape({
        name: PropTypes.string,
      }).isRequired,
    }).isRequired,
    billingInfoOrderFiles: PropTypes.shape({}),
    requisitionOrderFiles: PropTypes.shape({}),
  };

  static defaultProps = {
    billingInfoOrderFiles: null,
    requisitionOrderFiles: null,
  };

  state = {
    downloadCount: 0,
    attachments: {
    },
  };

  /* eslint-disable react/no-did-update-set-state */
  componentDidMount() {
    const steps = InsuranceCaseEditModalSteps.filter((step) => !step.hideComments);

    this.props.resetInsuranceInfo();
    this.props.getInsuranceStatus({ order_id: this.props.orderId });

    function makeItemsIntoArray(items) {
      return Object.values(items).sort((v1, v2) => v1 - v2);
    }

    const requests = steps.map((step) => this.props.getInsuranceItems({
      order_id: this.props.orderId,
      status_step: step.status,
    }).then((action) => {
      const items = makeItemsIntoArray(action.result);

      this.setState((prevState) => ({
        attachments: {
          ...prevState.attachments,
          [step.status]: items.filter((item) => item.file_name),
        },
      }));
    }));

    Promise.all(requests).then(() => {
      this.setState({
        attachmentsLoaded: true,
      });
    }).catch((error) => {
      this.setState({
        attachmentsLoaded: true,
      });
      throw error;
    });
  }
  /* eslint-enable react/no-did-update-set-state */

  componentWillUnmount() {
    this.props.resetInsuranceInfo();
    this.props.resetOrderInfo();
  }

  handleClickFile(event, file) {
    event.preventDefault();

    this.setState((state) => ({
      downloadCount: state.downloadCount + 1,
    }));
    this.props.getOrderFile({
      order_id: this.props.orderId,
      file_id: file.file_id,
    }).then((action) => {
      this.setState((state) => ({ downloadCount: state.downloadCount - 1 }));
      downloadFileFunc(action.result);
    }).catch((error) => {
      this.setState((state) => ({ downloadCount: state.downloadCount - 1 }));
      throw error;
    });
  }

  renderFileSize = (fileSize) => {
    const megabyte = 1024 * 1024;
    if (fileSize > megabyte) {
      return `${Math.floor(fileSize / megabyte)}MB`;
    }
    return `${Math.floor(fileSize / 1024)}kB`;
  }

  render() {
    const {
      show,
      hide,
      billingInfoDetails,
      billingInfoOrderFiles,
      requisitionOrderFiles,
      orderInfo,
    } = this.props;

    const steps = InsuranceCaseEditModalSteps.filter((step) => !step.hideComments);
    const attachments = this.state.attachments;
    let sectionsWithAttachments = [];
    const showSpinner = this.state.downloadCount > 0;

    sectionsWithAttachments.push({
      showIfEmpty: true,
      key: 'insurance',
      name: 'Insurance',
      files: billingInfoOrderFiles ? Object.keys(billingInfoOrderFiles).map((fileId) => ({
        file_id: fileId,
        file_name: billingInfoOrderFiles[fileId].file_name,
        file_size: billingInfoOrderFiles[fileId].size,
      })) : [],
    });
    sectionsWithAttachments.push({
      showIfEmpty: true,
      key: 'requisition',
      name: 'Requisition',
      files: requisitionOrderFiles ? Object.keys(requisitionOrderFiles).map((fileId) => ({
        file_id: fileId,
        file_name: requisitionOrderFiles[fileId].file_name,
        file_size: requisitionOrderFiles[fileId].size,
      })) : [],
    });
    steps.forEach((step) => {
      sectionsWithAttachments.push({
        key: step.status,
        name: step.description,
        files: attachments[step.status] || [],
      });
    });

    sectionsWithAttachments = sectionsWithAttachments.filter((section) => (
      section.showIfEmpty || section.files.length > 0
    ));

    return (
      <Modal show={show} onHide={hide} size="xl" aria-labelledby="contained-modal-title-sm">
        <Modal.Header closeButton>
          <h2>View insurance case</h2>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Basic information</h4>
            <label className="view_order_modal_label">Order ID</label>
            <span>{orderInfo.order_id || 'N/A'}</span>
            <label className="view_order_modal_label">Test name</label>
            <span>{orderInfo.product.name ? orderInfo.product.name : 'N/A'}</span>
            <label className="view_order_modal_label">Patient name</label>
            <span>
              {orderInfo.patient_last_name ? orderInfo.patient_last_name : ''}
              ,
              {' '}
              {orderInfo.patient_first_name ? orderInfo.patient_first_name : ''}
            </span>
            <label className="view_order_modal_label">Patient Date of Birth</label>
            <span>{orderInfo.patient_dob || 'N/A'}</span>
          </div>
          <div>
            <h4 className={styles.billing_address_heading}>Insurance information</h4>

            <label className="view_order_modal_label">Insurance company</label>
            <span>{billingInfoDetails.insurance_company ? billingInfoDetails.insurance_company : 'N/A'}</span>
            <label className="view_order_modal_label">Insurance ID</label>
            <span>{billingInfoDetails.insurance_id_no ? billingInfoDetails.insurance_id_no : 'N/A'}</span>
            <label className="view_order_modal_label">Group #</label>
            <span>{billingInfoDetails.group_no ? billingInfoDetails.group_no : 'N/A'}</span>
            <label className="view_order_modal_label">Patient relation to policy holder</label>
            <span>{billingInfoDetails.patient_relation ? billingInfoDetails.patient_relation : 'N/A'}</span>
            <label className="view_order_modal_label">Policy holder</label>
            <span>{billingInfoDetails.policy_holder_name ? billingInfoDetails.policy_holder_name : 'N/A'}</span>
            <label className="view_order_modal_label">Policy holder Date of Birth</label>
            <span>{billingInfoDetails.policy_holder_dob ? billingInfoDetails.policy_holder_dob : 'N/A'}</span>
            <label className="view_order_modal_label">Patient address</label>
            <span>{billingInfoDetails.patient_address ? billingInfoDetails.patient_address : 'N/A'}</span>
            <label className="view_order_modal_label">Patient phone</label>
            <span>{billingInfoDetails.patient_phone ? billingInfoDetails.patient_phone : 'N/A'}</span>
            <label className="view_order_modal_label">Patient email</label>
            <span>{billingInfoDetails.patient_email ? billingInfoDetails.patient_email : 'N/A'}</span>
          </div>
          <div>
            <h4>Attached files</h4>
            {this.state.attachmentsLoaded
              && (
              <div>
                {sectionsWithAttachments.map((section) => (
                  <div key={section.key}>
                    <label className="view_order_modal_label">{section.name}</label>
                    {section.files.length > 0
                    && (
                    <ul className="list-unstyled" style={{ display: 'inline-block' }}>
                      {section.files.map((file) => (
                        <li key={file.file_id}>
                          <span>
                            <button
                              type="button"
                              onClick={(event) => this.handleClickFile(event, file)}
                              className="btn btn-link"
                            >
                              {file.file_name}
                              {' '}
                              {file.file_size && (
                              <span>
                                (
                                {this.renderFileSize(file.file_size)}
                                )
                              </span>
                              )}
                            </button>
                          </span>
                        </li>
                      ))}
                    </ul>
                    )}
                    {section.files.length === 0 && <span>No attached file.</span>}
                  </div>
                ))}
              </div>
              )}
            {!this.state.attachmentsLoaded && <div style={{ display: 'block', textAlign: 'center' }} className={styles.spinner_inline}><Spinner /></div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {showSpinner
            && (
            <span className="float-left">
              <div className={styles.spinner_inline}>
                <Spinner />
              </div>
              <span className={styles.sans_serif}>Downloading file...</span>
            </span>
            )}
          <button type="button" className="btn btn-default" onClick={hide}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }
}
