import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportAppendixNoneCodingVariants extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
    appendixNoneCodingVariantsShown: PropTypes.bool,
    onTogglePanel: PropTypes.func,
    appendixHeading: PropTypes.string
  }

  render() {
    const {orderReport, appendixNoneCodingVariantsShown, appendixHeading, onTogglePanel} = this.props;
    const styles = require('./_OrderReportAppendixNoneCodingVariants.scss');

    return (
      <div className="report-section report-collapse">
        <h2 className={styles.appendix_heading + ' h3'} onClick={() => onTogglePanel('appendixNoneCodingVariantsShown')}>
          {appendixHeading}
          {appendixNoneCodingVariantsShown ?
           <i className={'glyphicon glyphicon-sm glyphicon-menu-up float-right'} aria-hidden="true"></i> :
           <i className={'glyphicon glyphicon-sm glyphicon-menu-down float-right'} aria-hidden="true"></i>
          }
        </h2>
        <div className={appendixNoneCodingVariantsShown ? '' : 'invisible'}>
          <table className={styles.non_coding_variants_table + ' table'}>
            <tbody dangerouslySetInnerHTML={{__html: orderReport.non_coding_variants}}>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
