import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as orders from 'redux/modules/orders';
import * as auth from 'redux/modules/auth';
import { ConfirmationQuestions } from 'components';
import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import {
  isVST,
  sortQuestions,
  getKeyValuePairsForQuestions,
  isExome,
} from 'utils/forms';
import AuthorizedProviderOrderInfo from 'components/_CreateOrderProcess/AuthorizedProviderOrderInfo';
import { OTHER_ATTACHMENT_TYPE, REQUISITION_ATTACHMENT_TYPE } from 'helpers/constants/fileTypeConstants';
import { isInternalRole } from 'utils/orderRoles';
import { SCREENING } from 'helpers/constants/specialTypeConstants';

import GeneralSection from './GeneralSection';
import PanelCustomizationSection from './PanelCustomizationSection';
import VstSection from './VstSection';
import MailSubscriptionSection from './MailSubscriptionSection';
import FaxSubscriptionSection from './FaxSubscriptionSection';
import OrderKitSection from './OrderKitSection';
import AttachmentList from './AttachmentList';
import AkouosInfo from './AkouosInfo';

const ViewOrderInfo = () => {
  const dispatch = useDispatch();

  const userRole = useSelector(auth.userRoleSelector);
  const orderInfo = useSelector(orders.orderInfoSelector);
  const viewVariantInfo = useSelector(orders.variantsSelector);
  const viewOrderDeliveryResultInfo = useSelector(orders.viewOrderDeliveryResultSelector);
  const confirmationQuestions = useSelector(orders.confirmationQuestionsSelector);

  const orderId = orderInfo.order_id;

  useEffect(() => {
    if (!viewVariantInfo && isVST(orderInfo.special_type)) {
      dispatch(orders.viewVariants({ order_id: orderId }));
    }
  }, [dispatch, orderId, orderInfo.special_type, viewVariantInfo]);

  useEffect(() => {
    if (!viewOrderDeliveryResultInfo) {
      dispatch(orders.viewOrderDeliveryResult({ order_id: orderId }));
    }
  }, [dispatch, orderId, viewOrderDeliveryResultInfo]);

  useEffect(() => {
    if (!confirmationQuestions) {
      dispatch(orders.getConfirmationQuestions({ order_id: orderId }));
    }
  }, [dispatch, orderId, confirmationQuestions]);

  const renderBody = () => {
    const specialType = orderInfo.special_type;
    const isExomeTest = isExome(specialType);
    const isScreeningType = specialType === SCREENING;
    const confirmationQuestionsArr = sortQuestions(confirmationQuestions || {});

    return (
      <div>
        {orderInfo && (
          <AuthorizedProviderOrderInfo
            label="Ordering health care professional"
            name={orderInfo.user_name}
            hospitalName={orderInfo.user_hospital}
            isAuthorizedProvider={orderInfo.user_is_authorized_provider}
            authorizationLastApproved={orderInfo.user_authorization_last_approved}
          />
        )}

        {orderInfo && orderInfo.product.code === 'EA0601' && (
          <AkouosInfo
            orderInfo={orderInfo}
          />
        )}

        {orderInfo && orderInfo.ordered_by_name && (
          <AuthorizedProviderOrderInfo
            label="Ordered by"
            name={orderInfo.ordered_by_name}
            hospitalName={orderInfo.alias_user_hospital}
            isAuthorizedProvider={orderInfo.alias_user_is_authorized_provider}
            authorizationLastApproved={orderInfo.alias_user_authorization_last_approved}
          />
        )}

        <GeneralSection
          orderId={orderInfo.order_id}
          specialType={orderInfo.special_type}
          reflexOrderId={orderInfo.reflex_order_id}
          orderInfo={orderInfo}
        />

        {orderInfo.is_customized && (
          <PanelCustomizationSection
            originalPanelGenes={orderInfo.original_panel_genes}
            maskedGenes={orderInfo.masked_genes}
            addedGenes={orderInfo.added_genes}
            customClinicalInfo={orderInfo.custom_clinical_info}
          />
        )}

        {(orderInfo.has_family_service && !orderInfo.is_us_account
        ) && (
        <div className="form-check checkbox modal_checkbox">
          <input
            type="checkbox"
            className="form-check-input"
            checked={orderInfo.has_family_service}
            disabled
          />
          <label className="view_order_modal_label form-check-label">
            Family Extension Service
          </label>
        </div>
        )}

        {orderInfo.express_service_available && (
          <div className="form-check checkbox modal_checkbox">
            <input
              type="checkbox"
              className="form-check-input"
              checked={orderInfo.is_express_service}
              disabled
            />
            <label className="view_order_modal_label form-check-label">Express Service</label>
          </div>
        )}

        {isVST(specialType) && (
          <VstSection
            indexOrderId={orderInfo.index_order_id}
            indexOrderCompletionDate={orderInfo.index_order_completion_date}
            variants={viewVariantInfo && viewVariantInfo.variants}
          />
        )}

        {!isScreeningType && (
          <>
            <MailSubscriptionSection
              isMailSubscriptionSelected={viewOrderDeliveryResultInfo.mail_subscription}
              mailList={viewOrderDeliveryResultInfo.mail_list}
            />

            <FaxSubscriptionSection
              isFaxSubscriptionSelected={viewOrderDeliveryResultInfo.fax_subscription}
              faxList={viewOrderDeliveryResultInfo.fax_list}
            />

            <div className="modal_row">
              <label className="view_order_modal_label">
                Phone number for possible case discussion
              </label>
              <span>{orderInfo.case_phone ? orderInfo.case_phone : 'N/A'}</span>
            </div>
          </>
        )}

        {isInternalRole(userRole) && (
          <>
            <AttachmentList
              orderId={orderId}
              type={REQUISITION_ATTACHMENT_TYPE}
              label="Attached requisition form files"
            />
            <AttachmentList
              orderId={orderId}
              type={OTHER_ATTACHMENT_TYPE}
              label="Other attachments"
            />
          </>
        )}

        <OrderKitSection orderKit={orderInfo.order_kit} />

        {isExomeTest && (
          <div className="small">
            The results of our Whole Exome tests are provided in a detailed
            clinical statement. In addition, upon separate request, filtered
            variant result files and raw data files can be provided. Please
            contact our Support for more information.
          </div>
        )}

        {(specialType === 'msd') && (
          <div className="small">
            The results of our Development Disorder Panels are provided in a
            detailed clinical statement. In addition, upon separate request,filtered
            variant result files and raw data files can be provided. Please contact
            our Support for more information.
          </div>
        )}

        <ConfirmationQuestions
          questions={confirmationQuestionsArr}
          values={getKeyValuePairsForQuestions(confirmationQuestionsArr)}
          enableHTMLQuestion={orderInfo.product.is_novodetect_product ?? false}
        />

      </div>
    );
  };

  const loading = (
    !orderInfo || !viewOrderDeliveryResultInfo || !confirmationQuestions
  );

  return (
    <ViewOrderStep loading={loading}>
      {!loading && renderBody()}
    </ViewOrderStep>
  );
};

export default ViewOrderInfo;
