import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';
import styles from '../ChangeOrderStatusForm.scss';

const NumOfSamplesInput = ({
  handleInputValue, onChangeValue, resultsReported, value,
}) => (
  <div className={styles.enforce_number_arrows}>
    <LabelInput
      inputSize="6"
      label="Number of samples"
      labelSize="6"
      name="number_of_samples"
      onChange={(event) => onChangeValue('number_of_samples', handleInputValue(event))}
      readOnly={resultsReported}
      type="number"
      value={value}
    />
  </div>
);

NumOfSamplesInput.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  resultsReported: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default NumOfSamplesInput;
