export const TEST_TYPE = {
  PANEL: 'panel',
  SINGLE_GENE_TEST: 'single gene tests',
  VARIANT_SPECIFIC_TEST: 'variant specific testing',
  WES: 'exome',
  SCREENING_PRODUCT: 'screening',
};

export const TEST_TYPE_FILTERING_OPTIONS = [
  {
    value: '',
    label: '- All -',
  },
  {
    value: TEST_TYPE.PANEL,
    label: 'Panels',
  },
  {
    value: TEST_TYPE.SINGLE_GENE_TEST,
    label: 'Single gene tests',
  },
  {
    value: TEST_TYPE.VARIANT_SPECIFIC_TEST,
    label: 'Variant specific tests',
  },
  {
    value: TEST_TYPE.WES,
    label: 'WES + DDP',
  },
  {
    value: TEST_TYPE.SCREENING_PRODUCT,
    label: 'Screening products',
  },
];

export { TEST_TYPE as default };
