import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportVariantsExome extends Component {
  static propTypes = {
    orderReport: PropTypes.object
  }

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  }

  render() {
    const {orderReport} = this.props;
    const styles = require('./_OrderReportVariantsExome.scss');
    let sortedVariantsArr;
    if (orderReport.variants_exome) {
      const variantsArr = Object.keys(orderReport.variants_exome).map(key => { return orderReport.variants_exome[key]; });
      sortedVariantsArr = variantsArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }

    return (
      <div>
        <h3 className="h4">{orderReport.variants_exome_title ? orderReport.variants_exome_title : 'Variant table: Genetic alterations'}</h3>
         {orderReport.version === 4 &&
           <table className="table">
             <thead>
                 <tr className={styles.report_heading_row}>
                   <th>Gene</th>
                   <th>Pos</th>
                   <th>ID</th>
                   <th>Codon</th>
                   <th>Consequence</th>
                   <th>Transcript</th>
                   <th>DNA</th>
                   <th>Protein</th>
                   <th>Genotype</th>
                   <th>EXAC AF</th>
                   <th>1000g</th>
                   <th>Classification</th>
                 </tr>
             </thead>
             <tbody>
               {orderReport.variants_exome && Object.keys(orderReport.variants_exome).map(key =>
                   <tr key={key}>
                     <td><a className="link-pink" target="_blank" href={orderReport.variants_exome[key].gene_url}>{orderReport.variants_exome[key].gene}</a></td>
                     <td>{orderReport.variants_exome[key].pos}</td>
                     <td><a className="link-pink" target="_blank" href={orderReport.variants_exome[key].dbsnp_id_url}>{orderReport.variants_exome[key].dbsnp_id}</a></td>
                     <td>{orderReport.variants_exome[key].codon}</td>
                     <td>{orderReport.variants_exome[key].consequence}</td>
                     <td>{orderReport.variants_exome[key].transcript}</td>
                     <td>{orderReport.variants_exome[key].dna}</td>
                     <td>{orderReport.variants_exome[key].protein}</td>
                     <td>{orderReport.variants_exome[key].genotype}</td>
                     <td>{orderReport.variants_exome[key].exac_af}</td>
                     <td>{orderReport.variants_exome[key]._1000g}</td>
                     <td>{orderReport.variants_exome[key].classification}</td>
                   </tr>
               )}
             </tbody>
           </table>
         }
         {orderReport.version === 5 &&
           <table className="table">
             <thead>
                 <tr className={styles.report_heading_row}>
                   <th>Gene</th>
                   <th>Pos</th>
                   <th>ID</th>
                   <th>Inheritance</th>
                   <th>Consequence</th>
                   <th>Transcript</th>
                   <th>DNA</th>
                   <th>Protein</th>
                   <th>Genotype</th>
                   <th>EXAC AF</th>
                   <th>EXAC AC/AN</th>
                   <th>Classification</th>
                 </tr>
             </thead>
             <tbody>
               {orderReport.variants_exome && Object.keys(orderReport.variants_exome).map(key =>
                   <tr key={key}>
                     <td><a className="link-pink" target="_blank" href={orderReport.variants_exome[key].gene_url}>{orderReport.variants_exome[key].gene}</a></td>
                     <td>{orderReport.variants_exome[key].pos}</td>
                     <td><a className="link-pink" target="_blank" href={orderReport.variants_exome[key].dbsnp_id_url}>{orderReport.variants_exome[key].dbsnp_id}</a></td>
                     <td>{orderReport.variants_exome[key].inheritance}</td>
                     <td>{orderReport.variants_exome[key].consequence}</td>
                     <td>{orderReport.variants_exome[key].transcript}</td>
                     <td>{orderReport.variants_exome[key].dna}</td>
                     <td>{orderReport.variants_exome[key].protein}</td>
                     <td>{orderReport.variants_exome[key].genotype}</td>
                     <td>{orderReport.variants_exome[key].exac_af}</td>
                     <td>{orderReport.variants_exome[key].exac}</td>
                     <td>{orderReport.variants_exome[key].classification}</td>
                   </tr>
               )}
             </tbody>
           </table>
         }
        {orderReport.version >= 6 &&
          <table className="table v6-variants-table">
            {sortedVariantsArr && sortedVariantsArr.map((variant, index) =>
              <tbody key={index}>
                <tr>
                  <td>
                    <span className="report-td-heading">Gene</span><br/>
                     <a className="link-gray" href={variant.gene_url} target="_blank"><span>{variant.gene}</span></a>
                  </td>
                  <td>
                    <span className="report-td-heading">Pos</span><br/>
                     <span>{variant.pos}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Transcript</span><br/>
                     <span>{variant.transcript}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Nomenclature</span><br/>
                     <span>{variant.nomenclature}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Consequence</span><br/>
                     <span>{variant.consequence}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Genotype</span><br/>
                     <span>{variant.genotype}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Classification</span><br/>
                    <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span className="report-td-heading">ID</span><br/>
                    {variant.dbsnp_id_url &&
                      <a className="link-gray" href={variant.dbsnp_id_url} target="_blank"><span>{variant.dbsnp_id}</span></a>
                    }
                    {!variant.dbsnp_id_url &&
                      <span>{variant.dbsnp_id}</span>
                    }
                  </td>
                  {variant.gnomad &&
                    <td>
                      <span className="report-td-heading">gnomAD</span><br/>
                       <span>{variant.gnomad}</span>
                    </td>
                  }
                  {variant.exac &&
                    <td>
                      <span className="report-td-heading">EXAC AC/AN</span><br/>
                       <span>{variant.exac}</span>
                    </td>
                  }
                  <td>
                    <span className="report-td-heading">Polyphen</span><br/>
                     <span className={this.textColorClass(variant.polyphen.class)}>{variant.polyphen.value}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Sift</span><br/>
                     <span className={this.textColorClass(variant.sift.class)}>{variant.sift.value}</span>
                  </td>
                  <td colSpan="2">
                      <span className="report-td-heading">Muttaster</span><br/>
                      <span className={this.textColorClass(variant.muttaster.class)}>{variant.muttaster.value}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a className="link-gray" href={variant.omim_url} target="_blank"><span>{variant.omim}</span></a>
                  </td>
                  <td colSpan="2">
                    <span className="report-td-heading">Phenotype</span><br/>
                     <span>{variant.phenotype}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Inheritance</span><br/>
                     <span>{variant.inheritance}</span>
                  </td>
                  <td colSpan="2">
                    <span className="report-td-heading">Comment</span><br/>
                     <span>{variant.comment}</span>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        }
      </div>
    );
  }
}
