import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as orderActions from 'redux/modules/orders';
import { userInfoSelector, aliasUserIdSelector } from 'redux/modules/auth';

import ViewOrderStep from 'components/_ViewOrderProcess/ViewOrderStep';
import { LabelAttachments } from 'components';

import { renderQuestion } from './PatientsHistoryInfoHelpers';

const PatientsHistoryInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState({});
  const [skipFields, setSkipFields] = useState({});

  const userInfo = useSelector(userInfoSelector);
  const orderInfo = useSelector(orderActions.orderInfoSelector);
  const aliasUserId = useSelector(aliasUserIdSelector);

  const { order_id: orderId, skip_misc_fields: skipMiscFields } = orderInfo;

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const panelQuestions = await dispatch(orderActions.getPanelQuestions(orderId));
        setQuestions(panelQuestions);
        const miscFields = skipMiscFields || [];
        setSkipFields(miscFields.reduce((acc, field) => Object.assign(acc, {
          [field]: true,
        }), {}));
        setIsLoading(false);
      } catch (e) {
        setError('Failed to load patient history');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, orderId, skipMiscFields]);

  let panelQuestionArr = [];
  if (questions) {
    const updatedPanelQuestionsArr = Object.values(questions);
    panelQuestionArr = updatedPanelQuestionsArr.sort((a, b) => a.order_no - b.order_no);
  }

  return (
    <ViewOrderStep loading={isLoading} error={error}>
      {!isLoading && !skipFields.patient_history_attachments && (
        <>
          <LabelAttachments
            orderId={orderId}
            userInfo={userInfo}
            aliasUserId={aliasUserId}
            type="order_attachment"
            label="Attached files"
            hint="Attach additional patient documentation, such as a copy of patient’s insurance card, family tree, ECG or other clinical documentation."
          />
          <p className="small">Allowed file types are TXT, PDF, JPG, PNG, ZIP, Word and Excel.</p>
        </>
      )}

      {panelQuestionArr.map((question) => renderQuestion(question))}
    </ViewOrderStep>
  );
};

export default PatientsHistoryInfo;
