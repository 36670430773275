import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import ErrorChangeStatus from './ErrorChangeStatus';

const ModalHeader = ({ error }) => (
  <Modal.Header closeButton>
    <div>
      <h2>Change order status</h2>
      {
        error && (
          <ErrorChangeStatus error={error} />
        )
      }
    </div>
  </Modal.Header>
);

ModalHeader.propTypes = {
  error: PropTypes.string,
};

ModalHeader.defaultProps = {
  error: null,
};

export default ModalHeader;
