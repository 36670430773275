import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { doneCreateUserSelector } from 'redux/modules/support';
import usePrevious from 'hooks/usePrevious';

const useOnCreateUser = (onCreateUser) => {
  const doneCreateUser = useSelector(doneCreateUserSelector);

  const previousDoneCreateUser = usePrevious(doneCreateUser);

  useEffect(() => {
    if (previousDoneCreateUser === false && doneCreateUser === true) {
      onCreateUser();
    }
  }, [onCreateUser, previousDoneCreateUser, doneCreateUser]);
};

export default useOnCreateUser;
