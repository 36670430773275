import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as orderActions from 'redux/modules/orders';
import Modal from 'react-bootstrap/Modal';
import { getPanelTierNumber } from 'utils/panelTiers';
import { getPanelTierForOrder } from 'utils/orderInfo';
import ViewOrderStep, { getSteps } from 'components/_ViewOrderProcess/ViewOrderStep';
import { isInternalUserSelector, isSupportUserSelector } from 'redux/modules/auth';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import envUtils from 'utils/envUtils';
import styles from './OrderDetailModal.scss';
import salesforceLogo from '../OrderList/salesforce_logo.svg';

const mapStateToProps = (state) => ({
  currentStep: state.orders.currentStep,
  orderInfo: state.orders.orderInfo,
  panelTiers: state.orders.panelTiers,
  isInternalUser: isInternalUserSelector(state),
  isSupportUser: isSupportUserSelector(state),
  panelVersionInformation: state.orders.panelVersionInformation,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getPanelTiers: orderActions.getPanelTiers,
  getPanelVersionInformation: orderActions.getPanelVersionInformation,
  resetPanelVersionInformation: orderActions.resetPanelVersionInformation,
}, dispatch);

class OrderDetailModal extends Component {
  static propTypes = {
    hide: PropTypes.func.isRequired,
    error: PropTypes.string,

    // redux props
    currentStep: PropTypes.number.isRequired,
    isInternalUser: PropTypes.bool.isRequired,
    isSupportUser: PropTypes.bool.isRequired,
    orderInfo: PropTypes.shape({
      ext_panel_id: PropTypes.string,
      order_id: PropTypes.number.isRequired,
      salesforce_order_id: PropTypes.number,
      patient_consent: PropTypes.bool,
      patient_dob: PropTypes.string,
      patient_last_name: PropTypes.string,
      patient_first_name: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string,
        is_new: PropTypes.bool,
        is_updated: PropTypes.bool,
        panel_tier_id: PropTypes.number,
        version: PropTypes.number.isRequired,
        last_updated: PropTypes.string.isRequired,
        prince_id: PropTypes.number,
      }).isRequired,
      panel_genes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
      })),
    }).isRequired,
    getPanelTiers: PropTypes.func.isRequired,
    getPanelVersionInformation: PropTypes.func.isRequired,
    resetPanelVersionInformation: PropTypes.func.isRequired,
    panelTiers: PropTypes.arrayOf(PropTypes.shape({
      panel_tier_id: PropTypes.number,
      size: PropTypes.string,
      minimum: PropTypes.number,
      maximum: PropTypes.number,
      isActive: PropTypes.bool,
    })),
    panelVersionInformation: PropTypes.shape({
      version: PropTypes.number,
      last_updated: PropTypes.string,
    }),
    phiFreeSummaryTextAreaVisible: PropTypes.bool,
  };

  static defaultProps = {
    error: null,
    panelTiers: null,
    phiFreeSummaryTextAreaVisible: false,
    panelVersionInformation: null,
  };

  componentDidMount() {
    const { getPanelTiers, getPanelVersionInformation, orderInfo } = this.props;

    // Load panel version information
    if ((orderInfo.product && orderInfo.product.prince_id) || orderInfo.ext_panel_id) {
      // If order's product is a flex product, use that custom panel instead of original panel
      getPanelVersionInformation(orderInfo.ext_panel_id || orderInfo.product.prince_id);
    }

    getPanelTiers();
  }

  componentWillUnmount() {
    this.props.resetPanelVersionInformation();
  }

  hideModal = () => {
    this.props.hide();
  };

  getPanelNameHeader = () => {
    const { orderInfo } = this.props;

    if (!orderInfo.product.code) {
      return orderInfo.product.name;
    }

    return `${orderInfo.product.name}, ${orderInfo.product.code}`;
  };

  getPanelVersionInfo = () => {
    const { panelVersionInformation } = this.props;
    if (!panelVersionInformation) { return ''; }

    return `Version: ${panelVersionInformation.version}, ${panelVersionInformation.last_updated}`;
  };

  renderHeader() {
    const {
      orderInfo, panelTiers, isInternalUser, isSupportUser,
    } = this.props;
    const informedConsentInfoDetails = orderInfo.patient_consent;

    let panelTierText = null;
    const panelTier = getPanelTierForOrder(orderInfo, panelTiers);
    panelTierText = panelTier && `Panel tier: ${getPanelTierNumber(panelTier.size)}`;

    const geneCount = orderInfo.panel_genes ? orderInfo.panel_genes.length : null;
    const dob = orderInfo.patient_dob ? orderInfo.patient_dob : null;
    const patientLastName = orderInfo.patient_last_name ? orderInfo.patient_last_name : null;
    const patientFirstName = orderInfo.patient_first_name ? orderInfo.patient_first_name : null;
    const patientInfo = dob || patientFirstName || patientLastName;

    return (
      <Modal.Header closeButton>
        <div className={classNames('w-100', styles.header)}>
          <div className={styles.panelHeaderRow}>
            <h2>
              {`Order ID ${orderInfo.order_id} Summary`}
              {isSupportUser && orderInfo.salesforce_order_id && (
                <Link
                  to={{ pathname: envUtils.getSalesforceOrderLink(orderInfo) }}
                  target="_blank"
                  id="salesforce-order-link"
                >
                  <Isvg height={25} width={40} src={salesforceLogo}>
                    Can not handle svg!
                  </Isvg>
                </Link>
              )}
            </h2>
            {patientInfo && <h5>{`Patient: ${patientLastName}, ${patientFirstName}, ${dob}`}</h5>}
          </div>
          <div className={styles.panelHeaderRow}>
            <h4>{this.getPanelNameHeader()}</h4>
            {panelTierText && (
              <a
                className={styles.panelTierHeading}
                href="https://blueprintgenetics.com/pricing/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {panelTierText}
              </a>
            )}
          </div>
          <div className={styles.panelHeaderRow}>
            <div className={styles.panelHeaderColumn}>
              {isInternalUser && <h5>{this.getPanelVersionInfo()}</h5>}
            </div>
            <div className={styles.panelHeaderColumn}>
              {geneCount && <h5 className="h5">{`Gene count: ${geneCount}`}</h5>}
            </div>
          </div>
          {!informedConsentInfoDetails && (
            <p className="small">
              * Patient did not sign the consent form yet.
            </p>
          )}
        </div>
      </Modal.Header>
    );
  }

  renderBody() {
    const { currentStep, orderInfo } = this.props;

    const Step = getSteps(orderInfo).find(
      (_, index) => index + 1 === currentStep,
    );

    return (
      <Modal.Body>
        {Step && (
          // eslint-disable-next-line react/jsx-pascal-case
          <Step.component
            hideModal={this.hideModal}
            Step={ViewOrderStep}
          />
        )}
      </Modal.Body>
    );
  }

  render() {
    const { error } = this.props;

    return (
      <Modal
        className={classNames(
          styles.phiFreeSummaryModal, {
            [styles.visible]: this.props.phiFreeSummaryTextAreaVisible,
          },
        )}
        show
        onHide={this.hideModal}
        size="xl"
        aria-labelledby="contained-modal-title-sm"
        enforceFocus={false}
      >
        {this.renderHeader()}
        {error && (<p className="text-danger">{error}</p>)}
        {this.renderBody()}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailModal);
