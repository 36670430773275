import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CountrySelect } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  ErrorMessage, Formik, Field, Form,
} from 'formik';
import Checkbox from 'components/Formik/Checkbox';
import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import { Link, useHistory } from 'react-router-dom';
import { signup } from 'redux/modules/auth';
import { countriesSelector, loadCountries } from 'redux/modules/countries';
import { loadProvinces, provincesSelector } from 'redux/modules/provinces';
import { getNucleusLoginLink, isUsRegion } from 'utils/envUtils';
import {
  isCountryUS,
  isUsTerritoryCountryCode,
  isLatamCountryCode,
  getProvinceOptions,
  validateWithContext,
} from 'utils/forms';
import useCountryArray from 'hooks/useCountryArray';
import styles from './SignupForm.scss';
import AuthorizedProviderCheckbox from './AuthorizedProviderCheckbox';

const signupValidationSchema = Yup.object().shape({
  full_name: Yup.string().required('Name is required'),
  email: Yup.string().required('Email is required'),
  emailAgain: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Email is required'),
  title: Yup.string().required('Title is required'),
  hospital_name: Yup.string().required('Hospital name is required'),
  department: Yup.string().required('Department is required'),
  country_id: Yup.string().required('Country is required'),
  is_healthcare_professional: Yup.bool()
    .required('This needs to be checked to proceed')
    .oneOf([true], 'This needs to be checked to proceed'),
  is_authorized_provider: Yup.bool(),
  npi: Yup.string().when('is_authorized_provider', {
    is: true,
    then: Yup.string().required('NPI is required for authorized providers'),
  }),
  province_id: Yup.string().nullable().when(['country_id', '$countriesById'], {
    is: (countryId, countriesById) => isCountryUS(countryId, countriesById),
    then: Yup.string().required('State is required for US hospitals'),
  }),
});

const validateForm = (countriesById) => validateWithContext(
  signupValidationSchema,
  { countriesById },
);

const SignupForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);

  const usingUsRegion = isUsRegion();
  const countries = useSelector(countriesSelector);
  const provinces = useSelector(provincesSelector);

  const countriesArray = useCountryArray(countries);

  const provinceOptionsArray = getProvinceOptions(provinces);

  useEffect(() => {
    dispatch(loadCountries());
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCountry || !selectedCountry.id) return;
    dispatch(loadProvinces(selectedCountry.id));
  }, [dispatch, usingUsRegion, selectedCountry]);

  // Filter list of countries based on app region
  const countryOptions = useMemo(() => countriesArray.filter((country) => {
    if (usingUsRegion) {
      return isUsTerritoryCountryCode(country.code) || isLatamCountryCode(country.code);
    }
    return !isLatamCountryCode(country.code);
  }), [countriesArray, usingUsRegion]);

  const provinceOptions = useMemo(() => provinceOptionsArray.filter((province) => {
    if (selectedCountry && selectedCountry.code === 'US') {
      return usingUsRegion ? province.value !== 'New York' : province.value === 'New York';
    }
    return true;
  }), [provinceOptionsArray, usingUsRegion, selectedCountry]);

  const handleCountryChange = useCallback((form) => ({ target: { value } }) => {
    form.setFieldValue('country_id', value);
    if (!provinceOptions || provinceOptions.length === 0) {
      // Clear province ID if country does not have provinces
      form.setFieldValue('province_id', null);
      setSelectedProvince(null);
    }
    const country = countryOptions.find((c) => c.id === Number(value));
    setSelectedCountry(country);
  }, [provinceOptions, countryOptions]);

  const handleProvinceChange = useCallback(({ target: { value } }) => {
    const province = provinceOptions.find((p) => p.key === Number(value));
    setSelectedProvince(province);
  }, [provinceOptions]);

  const showAuthorizedProvider = useMemo(() => {
    if (!selectedCountry) {
      return false;
    }
    if (usingUsRegion) {
      return isUsTerritoryCountryCode(selectedCountry.code);
    }
    return selectedCountry.code === 'US' && selectedProvince?.value === 'New York';
  }, [usingUsRegion, selectedCountry, selectedProvince]);

  return (
    <div>
      <h1 className="no-margin-top">Nucleus</h1>
      <h2>Request an account</h2>

      <Formik
        initialValues={{
          full_name: '',
          email: '',
          emailAgain: '',
          title: '',
          hospital_name: '',
          department: '',
          country_id: '',
          is_healthcare_professional: false,
          is_authorized_provider: false,
          npi: '',
          province_id: null,
        }}
        validate={validateForm(countries)}
        onSubmit={(values, actions) => {
          dispatch(signup(values))
            .then((res) => {
              history.push({
                pathname: '/login',
                state: { message: res },
              });
            })
            .catch((e) => {
              actions.setFieldError('general', e.message);
            });
        }}
      >
        {(formProps) => (
          <Form>
            <Field
              placeholder="Name of the healthcare professional *"
              name="full_name"
              component={LabelInput}
              required
            />

            <Field
              placeholder="Title * (e.g. cardiologist, genetic nurse etc.)"
              name="title"
              component={LabelInput}
              required
            />

            <Field
              placeholder="Hospital *"
              name="hospital_name"
              component={LabelInput}
              required
            />

            <Field
              placeholder="Department *"
              name="department"
              component={LabelInput}
              required
            />

            {countryOptions.length > 0 && (
              <Field name="country_id">
                {({ form, field }) => (
                  <CountrySelect
                    name="country"
                    id="country"
                    value={field.value || ''}
                    options={countryOptions}
                    placeholder="Select country"
                    onChange={handleCountryChange(form)}
                  />
                )}
              </Field>
            )}
            <ErrorMessage
              name="country_id"
              component="p"
              className="text-danger"
            />

            {provinceOptions.length > 0 && (
            <Field
              name="province_id"
              component={SelectInput}
              options={provinceOptions}
              onChange={handleProvinceChange}
              placeholder="Select state:"
              inputSize="fit"
              required
            />
            )}

            {countryOptions.length > 0 && (
              <p className={styles.locationHint}>
                <span>
                  If you cannot find your location, click
                </span>
                <a href={getNucleusLoginLink(process.env.REACT_APP_ENV)}>
                  here
                </a>
              </p>
            )}

            {showAuthorizedProvider && (
              <AuthorizedProviderCheckbox />
            )}

            {formProps.values.is_authorized_provider && (
              <Field
                placeholder="NPI *"
                name="npi"
                component={LabelInput}
                required
              />
            )}

            <Field
              placeholder="E-mail *"
              name="email"
              type="email"
              component={LabelInput}
              required
            />

            <Field
              placeholder="E-mail again *"
              name="emailAgain"
              type="email"
              component={LabelInput}
              required
            />

            <p className="small">
              Please use your hospital / institution email address.
            </p>

            <Field
              name="is_healthcare_professional"
              className={`form-group ${styles.healthcareProfessional}`}
              labelClassName="" // Added this so that this checkbox does not apply the default class of 'col-form-label'
              component={Checkbox}
              label="I am a qualified healthcare professional *"
            />

            {formProps.errors.general && (
              <p className="text-danger">{formProps.errors.general}</p>
            )}

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <button
                  type="submit"
                  className={`btn btn-primary btn-block ${styles['bottom-buffer']}`}
                >
                  Send
                </button>
              </div>
            </div>

            <Link to="/login" className="float-right">
              ← Back to login
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignupForm;
