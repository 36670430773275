const mapUser = (user) => ({
  userEmail: user.email,
  userHospitalName: user.hospital_name,
  userRole: user.role,
  userFullName: user.full_name,
  userOrderCount: user.order_count,
  userLastLogin: user.last_login,
  userId: user.user_id,
  userInactive: user.is_new,
  userBlank: user.is_blank_user,
  id: user.user_id,
});

export default mapUser;
