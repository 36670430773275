import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductListItem, { ProductListItemEditVisibility } from './ProductListItem';

import styles from './SingleGeneList.scss';

const defaultLetter = 'a';

export default class SingleGeneList extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
    })).isRequired,
    onSelect: PropTypes.func.isRequired,
    searchString: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['editVisibility', 'ordering']),
  };

  static defaultProps = {
    mode: 'ordering',
  };

  state = {
    activeLetter: defaultLetter,
    letters: [],
    activeProducts: [],
  }

  componentDidMount() {
    const { searchString } = this.props;

    this.computeActiveProducts(searchString ? searchString[0].toLowerCase() : defaultLetter);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchString !== this.props.searchString) {
      // Recalculate when search updated
      if (this.props.searchString) {
        this.computeActiveProducts(this.props.searchString[0].toLowerCase());
      } else {
        this.computeActiveProducts(defaultLetter);
      }
    }
  }

  toggleLetter = (letter) => {
    this.computeActiveProducts(letter);
  }

  computeActiveProducts(activeLetter) {
    const {
      products,
    } = this.props;

    // Group by letter
    const grouped = products.reduce((acc, product) => {
      const letter = product.name[0].toLowerCase();
      if (!Object.prototype.hasOwnProperty.call(acc, letter)) {
        acc[letter] = [];
      }

      acc[letter].push(product);
      return acc;
    }, {});

    const letters = Object.entries(grouped)
      .filter(([, productsInLetter]) => productsInLetter.length) // Keep only letter with products
      .map(([letter]) => letter)
      .sort((a, b) => a.localeCompare(b)); // Sort

    const activeProducts = products
      .filter(({ name }) => name.toLowerCase().startsWith(activeLetter))
      .map((product) => ({
        ...product, // Use a different name when displaying single gene tests
        display_name: product.name.replace(' single gene test', ''),
      }));

    // If there are no active products then try to compute with next letter
    if (!activeProducts.length && letters.length) {
      this.computeActiveProducts(letters[0]);
      return;
    }

    this.setState({
      activeLetter,
      letters,
      activeProducts,
    });
  }

  render() {
    const {
      onSelect,
      mode,
    } = this.props;

    const {
      activeLetter,
      letters,
      activeProducts,
    } = this.state;

    return (
      <section className={styles.single_gene_list_wrapper}>
        <LetterNavigation
          onLetterClick={this.toggleLetter}
          letters={letters}
          activeLetter={activeLetter}
        />
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {mode === 'editVisibility' && (
              <ul className={`${styles.panel_list} list-unstyled`}>
                {activeProducts.map((product) => (
                  <ProductListItemEditVisibility
                    key={product.panel_id}
                    product={product}
                  />
                ))}
              </ul>
            )}
            {mode === 'ordering' && (
              <ul className={`${styles.panel_list} list-unstyled`} id="single-gene-product-list">
                {activeProducts.map((product) => (
                  <ProductListItem
                    key={product.panel_id}
                    product={product}
                    onSelect={() => onSelect(product)}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const LetterNavigation = ({
  onLetterClick,
  letters,
  activeLetter,
}) => {
  const toggleLetter = (letter) => {
    onLetterClick(letter);
  };
  return (
    <nav>
      <ul className={`${styles.gene_letters_list} list-unstyled list-inline`}>
        {letters.map((letter) => (
          /*
            eslint-disable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */
          <li
            key={letter}
            className={classNames('list-inline-item', {
              [styles.active]: letter === activeLetter, 'single-gene-active-letter': letter === activeLetter,
            })}
            onClick={() => toggleLetter(letter)}
          >
            {letter.toUpperCase()}
          </li>
          /*
            eslint-enable
            jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-noninteractive-element-interactions
          */
        ))}
      </ul>
    </nav>
  );
};

LetterNavigation.propTypes = {
  onLetterClick: PropTypes.func.isRequired,
  letters: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeLetter: PropTypes.string.isRequired,
};
