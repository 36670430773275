import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import styles from './ProfileDropdown.scss';

export default function ProfileDropdown(props) {
  const { userInfo, handleLogout } = props;

  return (
    <Dropdown id="profile-dropdown">
      <Dropdown.Toggle className={styles.profile_toggle_btn} variant="link">
        <span>{userInfo && userInfo.user_name}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="super-colors">
        <Dropdown.Item as={NavLink} to="/account-settings">
          Profile settings
        </Dropdown.Item>
        <Dropdown.Divider className="d-none d-sm-block" />
        <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ProfileDropdown.propTypes = {
  userInfo: PropTypes.shape({
    user_name: PropTypes.string,
  }),
  handleLogout: PropTypes.func.isRequired,
};

ProfileDropdown.defaultProps = {
  userInfo: null,
};
