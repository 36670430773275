import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import OrderTestFormSendSampleInfo from 'components/_CreateOrderProcess/_OrderTestFormSendSampleInfo/_OrderTestFormSendSampleInfo';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';

const OrderTestModalBody = ({
  error, currentStep, close, steps,
}) => {
  const stepCount = steps.length + 1;
  const Step = steps.find(
    (_, index) => index + 1 === currentStep,
  );
  return (
    <Modal.Body>
      {error && (
        <div>
          <p className="text-danger">{error}</p>
        </div>
      )}
      {currentStep >= 1 && currentStep < stepCount && (
        // eslint-disable-next-line react/jsx-pascal-case
        <Step.component
          hideModal={close}
          Step={EditOrderStep}
        />
      )}
      {currentStep === stepCount && (
        <OrderTestFormSendSampleInfo hideModal={close} />
      )}
    </Modal.Body>
  );
};

OrderTestModalBody.propTypes = {
  error: PropTypes.string,
  currentStep: PropTypes.number,
  close: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

OrderTestModalBody.defaultProps = {
  currentStep: null,
  error: null,
};

export default OrderTestModalBody;
