import React from 'react';
import PropTypes from 'prop-types';
import PatientHistoryQuestion from 'components/PatientHistoryQuestion';
import { loopQuestions } from 'utils/patientQuestions';

const PatientHistoryQuestions = ({
  questions,
  namePrefix,
  oneColumn,
  isSupportUser,
  withICDAndHospitalStatus,
  isLatestResonatePanel,
  isInformedDna,
  enableHTMLHint,
}) => (
  <div>
    {loopQuestions(questions, ({ isQuestionVisible }) => (
      questions.map((rawQuestion, index) => {
        let question = rawQuestion;

        if (withICDAndHospitalStatus
          && (question.name !== 'icd10cod_1'
            && question.name !== 'hospitals_1')) {
          return null;
        }
        // if latest resonate panel and dna, show patient addresses questions
        if (isLatestResonatePanel && ['patient_address_1',
          'patient_city_1',
          'patient_state_1'].includes(question?.name)) {
          question = {
            ...question,
            is_hidden_by_default: !isInformedDna,
            is_compulsory: isInformedDna,
          };
        }

        return (
          // We cannot use .filter() as it will change `index`
          // We need correct index to access the right Formik value
          isQuestionVisible(question)
            ? (
              <PatientHistoryQuestion
                enableHTMLHint={enableHTMLHint}
                key={question.question_id}
                question={question}
                oneColumn={oneColumn}
                namePrefix={`${namePrefix}questions[${index}]`}
                isSupportUser={isSupportUser}
              />
            )
            : null
        );
      })
    ))}
  </div>
);
PatientHistoryQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question_id: PropTypes.number.isRequired,
    is_hidden_by_default: PropTypes.bool,
    selected_option_id: PropTypes.string,
    question_options: PropTypes.arrayOf(PropTypes.shape({
      question_option_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      conditional_question_id: PropTypes.number,
    })),
  })).isRequired,
  namePrefix: PropTypes.string,
  // Defines how question label and input should be displayed
  // Default: two column layout
  oneColumn: PropTypes.bool,
  isSupportUser: PropTypes.bool,
  withICDAndHospitalStatus: PropTypes.bool,
  isLatestResonatePanel: PropTypes.bool,
  isInformedDna: PropTypes.bool,
  enableHTMLHint: PropTypes.bool,
};

PatientHistoryQuestions.defaultProps = {
  namePrefix: '',
  oneColumn: false,
  isSupportUser: false,
  withICDAndHospitalStatus: false,
  isLatestResonatePanel: false,
  isInformedDna: false,
  enableHTMLHint: false,
};

export default PatientHistoryQuestions;
