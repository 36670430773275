import React from 'react';
import PropTypes from 'prop-types';

import { LabelInput } from 'components';

const AdditionalLimsInfoInput = ({
  handleInputValue,
  onChangeValue,
  resultsReported,
  value,
}) => (
  <LabelInput
    inputSize="6"
    label="Additional LIMS info"
    labelSize="6"
    name="additional_lims_info"
    onChange={(event) => onChangeValue('additional_lims_info', handleInputValue(event))}
    readOnly={resultsReported}
    type="text"
    value={value}
  />
);

AdditionalLimsInfoInput.propTypes = {
  value: PropTypes.string,
  resultsReported: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  handleInputValue: PropTypes.func.isRequired,
};

AdditionalLimsInfoInput.defaultProps = {
  value: null,
};

export default AdditionalLimsInfoInput;
