import React from 'react';
import PropTypes from 'prop-types';

const RePrintLabId = ({ labId, printerName, reprintLabId }) => (
  <div>
    <button
      type="button"
      className="btn btn-primary"
      onClick={() => reprintLabId(labId, printerName)}
    >
      Reprint Lab ID
    </button>
  </div>
);

RePrintLabId.propTypes = {
  labId: PropTypes.string,
  printerName: PropTypes.string.isRequired,
  reprintLabId: PropTypes.func.isRequired,
};

RePrintLabId.defaultProps = {
  labId: null,
};

export default RePrintLabId;
