import React from 'react';
import PropTypes from 'prop-types';

const AkouosInfo = ({ orderInfo }) => (
  <>
    {typeof orderInfo.akouos_2 === 'boolean' && (
      <div className="form-check modal_row ml-3">
        <input type="checkbox" className="form-check-input" checked={orderInfo.akouos_2} disabled />
        <label className="form-check-label">
          I have reviewed the content of the Resonate Program:
          Sponsored Genetic Testing Informed Consent with the patient or
          their authorized representative, including the notification that
          Blueprint Genetics may provide de-identified information to the
          sponsor of this testing program.
          <br />
          I confirm that any additional informed consent has been obtained
          as required by applicable state law and/or regulations.
        </label>
      </div>
    )}

    {typeof orderInfo.akouos_3 === 'boolean' && (
      <div className="form-check modal_row ml-3">
        <input type="checkbox" className="form-check-input" checked={orderInfo.akouos_3} disabled />
        <label className="form-check-label">
          <span>
            I confirm that I understand that my
            patient&apos;s sample analysis will only begin when:
          </span>
          <ol>
            <li>
              informed consent has been obtained
              in accordance with applicable state,
              federal and/or country laws and regulations; and
            </li>
            <li>
              I have submitted this requisition successfully.
            </li>
          </ol>
        </label>
      </div>
    )}

    {typeof orderInfo.akouos_4 === 'boolean' && (
      <div className="form-check modal_row ml-3">
        <input type="checkbox" className="form-check-input" checked={orderInfo.akouos_4} disabled />
        <label className="form-check-label">
          I request genetic counseling for this patient through
          InformedDNA (an independent, phone-based,
          Genetic Counseling service, contracted for this study).
        </label>
      </div>
    )}

    {typeof orderInfo.akouos_5 === 'boolean' && (
      <div className="form-check modal_row ml-3">
        <input type="checkbox" className="form-check-input" checked={orderInfo.akouos_5} disabled />
        <label className="form-check-label">
          I will provide genetic counseling to the patient or
          refer the patient to a local genetic counselor.
          With this option I understand the cost of
          genetic counseling is not covered by the Resonate Program.
          I affirm my patient understands the requirement for counseling,
          understands the options available,
          and understands and accepts the additional costs
          that may be incurred in selecting this option.
        </label>
      </div>
    )}

    {typeof orderInfo.akouos_6 === 'boolean' && (
      <div className="modal_row">
        <label className="view_order_modal_label">
          OAE or CM present with absent or abnormal ABR:
        </label>
        <span>{orderInfo.akouos_6 ? 'Yes' : 'No'}</span>
      </div>
    )}

    {typeof orderInfo.akouos_7 === 'boolean' && (
      <div className="modal_row">
        <label className="view_order_modal_label">
          OAE or CM previously present with absent or abnormal ABR:
        </label>
        <span>{orderInfo.akouos_7 ? 'Yes' : 'No'}</span>
      </div>
    )}

    {typeof orderInfo.akouos_8 === 'boolean' && (
      <div className="modal_row">
        <label className="view_order_modal_label">
          Patient does not have evidence of syndromic medical history:
        </label>
        <span>{orderInfo.akouos_8 ? 'Yes' : 'No'}</span>
      </div>
    )}
  </>
);

AkouosInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orderInfo: PropTypes.object.isRequired,
};

export default AkouosInfo;
