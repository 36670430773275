import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './SampleTypeQuestion.scss';

const SAMPLE_TYPE_QUESTION_NAME = 'sampletyp_1';
const SAMPLE_TYPE_INDIVIDUAL_2_QUESTION_NAME = 'sampletyp_2';
const SAMPLE_ALREADY_AT_BPG = 'Use sample already at Blueprint Genetics';
const DNA_OPTION = 'DNA';

// eslint-disable-next-line
const convertSampleTypeQuestionsToOptions = (questionOptions) => questionOptions.map((opt) => {
  return { value: opt.question_option, key: opt.question_option_id };
});

const HINT = {
  text: 'Please note that this information affects interpretation for mitochondrial DNA testing. More information about sample requirements',
  linkHref: 'https://blueprintgenetics.com/sample-requirements/',
};

const getSampleOptionId = (values, sampleType) => {
  const sampleTypeQn = values.sample_type_questions.find(
    (q) => q.name === SAMPLE_TYPE_QUESTION_NAME,
  );

  let sampleAtBpgOption = {};
  if (sampleTypeQn && sampleTypeQn.question_options) {
    sampleAtBpgOption = sampleTypeQn.question_options.find(
      (q) => q.question_option === sampleType,
    );
  }

  return sampleAtBpgOption && sampleAtBpgOption.question_option_id;
};

const getSampleOptionIdIndividual2 = (values, sampleType) => {
  const sampleTypeQn = values.sample_type_questions.find(
    (q) => q.name === SAMPLE_TYPE_INDIVIDUAL_2_QUESTION_NAME,
  );

  let sampleAtBpgOption = {};
  if (sampleTypeQn && sampleTypeQn.question_options) {
    sampleAtBpgOption = sampleTypeQn.question_options.find(
      (q) => q.question_option === sampleType,
    );
  }

  return sampleAtBpgOption && sampleAtBpgOption.question_option_id;
};

const SampleTypeQuestion = (props) => {
  const { values, setFieldValue } = useFormikContext();

  if (!props.sampleTypePanelQuestions) {
    return null;
  }

  const sampleTypeLabel = props.screeningLabel ? `Sample Type ${props.screeningLabel}` : 'Sample Type';

  const renderFreeTextOptionField = (questionNumber) => {
    if (!values.sample_type_questions[questionNumber] || !values.sample_type_questions[questionNumber].selected_option_id) {
      return null;
    }

    const sampleAtBpgOptionId = getSampleOptionId(values, SAMPLE_ALREADY_AT_BPG);
    const dnaSampleOptionId = getSampleOptionId(values, DNA_OPTION);

    const sampleAtBpgOptionIdIndividual2 = getSampleOptionIdIndividual2(values, SAMPLE_ALREADY_AT_BPG);
    const dnaSampleOptionIdIndividual2 = getSampleOptionIdIndividual2(values, DNA_OPTION);

    if (props.isScreeningDuo) {
      switch (values.sample_type_questions[questionNumber].selected_option_id) {
        case sampleAtBpgOptionIdIndividual2:
          return (
            <div>
              <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`}>
                {({
                  field,
                }) => (
                  <LabelInput
                    field={{
                      ...field,
                      onChange: (e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(\s*|\d+)$/;
                        if (regex.test(value.toString())) {
                          field.onChange(e);
                        }
                      },
                    }}
                    name={`sample_type_questions[${questionNumber}].free_text_option_answer`}
                    label="Please include previous Order ID"
                    placeholder="######"
                    inputSize={12}
                    labelSize={12}
                  />
                )}
              </Field>
              <div className={styles.orderIdHint}>
                <small>
                  We will contact you if a new sample or additional paperwork is
                  required.
                </small>
              </div>
            </div>
          );
        case dnaSampleOptionIdIndividual2:
          return <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`} type="text" placeholder="Peripheral blood, EDTA blood, skin biopsy, etc." component={LabelInput} label="Indicate the source" inputSize={12} labelSize={12} />;
        default:
          if (!props.isScreeningProduct) {
            return <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`} type="text" placeholder="Specify" component={LabelInput} label="Additional information" inputSize={12} labelSize={12} />;
          } return <></>;
      }
    } else {
      switch (values.sample_type_questions[questionNumber].selected_option_id) {
        case sampleAtBpgOptionId:
          return (
            <div>
              <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`}>
                {({
                  field,
                }) => (
                  <LabelInput
                    field={{
                      ...field,
                      onChange: (e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex = /^(\s*|\d+)$/;
                        if (regex.test(value.toString())) {
                          field.onChange(e);
                        }
                      },
                    }}
                    name={`sample_type_questions[${questionNumber}].free_text_option_answer`}
                    label="Please include previous Order ID"
                    placeholder="######"
                    inputSize={12}
                    labelSize={12}
                  />
                )}
              </Field>
              <div className={styles.orderIdHint}>
                <small>
                  We will contact you if a new sample or additional paperwork is
                  required.
                </small>
              </div>
            </div>
          );
        case dnaSampleOptionId:
          return <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`} type="text" placeholder="Peripheral blood, EDTA blood, skin biopsy, etc." component={LabelInput} label="Indicate the source" inputSize={12} labelSize={12} />;
        default:
          if (!props.isScreeningProduct) {
            return <Field name={`sample_type_questions[${questionNumber}].free_text_option_answer`} type="text" placeholder="Specify" component={LabelInput} label="Additional information" inputSize={12} labelSize={12} />;
          } return <></>;
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <label className="control-label">{sampleTypeLabel}</label>
        <br />
        <small>
          {HINT.text}
          &nbsp;
          <a className={styles.link} href={HINT.linkHref} target="__blank">here.</a>
        </small>
      </div>
      <div className={styles.rightColumn}>
        {props.isScreeningDuo
          ? (
            <Field
              component={SelectInput}
              name="sample_type_questions[1].selected_option_id"
              placeholder="Choose sample type or request to use previous sample"
              inputSize="fit"
              options={convertSampleTypeQuestionsToOptions(
                props.sampleTypePanelQuestions[1].question_options,
              )}
              onChange={() => setFieldValue('sample_type_questions[1].free_text_option_answer', '')}
            />
          )
          : (
            <Field
              component={SelectInput}
              name="sample_type_questions[0].selected_option_id"
              placeholder="Choose sample type or request to use previous sample"
              inputSize="fit"
              options={convertSampleTypeQuestionsToOptions(
                props.sampleTypePanelQuestions[0].question_options,
              )}
              onChange={() => setFieldValue('sample_type_questions[0].free_text_option_answer', '')}
            />
          )}
        {props.isScreeningDuo
          ? (
            <div className={styles.additionalInput}>
              {renderFreeTextOptionField(1)}
            </div>
          )
          : (
            <div className={styles.additionalInput}>
              {renderFreeTextOptionField(0)}
            </div>
          )}
      </div>
    </div>
  );
};

SampleTypeQuestion.propTypes = {
  sampleTypePanelQuestions: PropTypes.arrayOf(PropTypes.shape({
    question_options: PropTypes.arrayOf(PropTypes.shape({
      question_option: PropTypes.string,
      question_option_id: PropTypes.string,
    })),
  })),
  isScreeningProduct: PropTypes.bool,
  isScreeningDuo: PropTypes.bool,
  screeningLabel: PropTypes.string,
};

SampleTypeQuestion.defaultProps = {
  sampleTypePanelQuestions: undefined,
  isScreeningProduct: false,
  isScreeningDuo: false,
  screeningLabel: '',
};

export default SampleTypeQuestion;
