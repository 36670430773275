import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';

const SearchUserNotifications = (props) => {
  const { onSearch } = props;

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values, actions) => {
        onSearch(values).finally(() => {
          actions.setSubmitting(false);
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-md-9">
              <Field
                type="email"
                name="email"
                placeholder="User email"
                className="form-control"
              />
              <ErrorMessage name="email" component="div" />
            </div>
            <div className="col-md-2 offset-md-1">
              <button
                type="submit"
                className="btn btn-default"
                disabled={isSubmitting}
              >
                Search
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SearchUserNotifications.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchUserNotifications;
