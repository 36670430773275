import React from 'react';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';
import PrintConsent from '../_OrderTestFormInformedConsent/PrintConsent';

const label = 'My patient (or their authorized representative) '
  + 'has agreed to and not revoked the '
  + 'Sponsored Genetic Testing Informed Consent '
  + 'and has also given any additional informed consent as '
  + 'required by applicable state law and/or regulations.';

const ConsentAkouosForm = () => (
  <div className="col-md-8 offset-md-2">
    <PrintConsent />
    <Field
      name="consentSigned"
      id="consentSigned"
      component={Checkbox}
      label={label}
      labelClassName="consentSigned"
      className="text-left"
    />
  </div>
);

export default ConsentAkouosForm;
