import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'components';
import * as support from 'redux/modules/support';

import UserForm from './UserForm';
import styles from './UserModal.scss';
import { validateUserForm } from './userFormValidation';
import SaveUserButton from './SaveUserButton';
import useUserFormOptions from './useUserFormOptions';
import useOnUpdateUser from './useOnUpdateUser';

const initialData = (user) => ({
  email: user.email,
  hospital_id: user.hospital_id,
  department: user.department,
  role: user.role,
  title: user.title,
  full_name: user.full_name,
  npi: user.npi,
  allow_order_sharing: user.allow_order_sharing,
  has_eea_rights: user.has_eea_rights,
  has_full_us_access: user.has_full_us_access,
  is_super_user: user.is_super_user,
  allowed_panel_ids: user.allowed_panel_ids,
  is_authorized_provider: typeof user.is_authorized_provider === 'boolean'
    ? user.is_authorized_provider
    : false,
});

const shouldShowButton = (buttonType, user, userInfo = null) => {
  const {
    is_disabled: isUserDisabled,
    is_new: isNewUser,
    is_blank_user: isBlankUser,
  } = user;

  switch (buttonType) {
    case 'reset2FA':
      return userInfo && userInfo.internal_role_rights && !isBlankUser;

    case 'activateAndSendPassword':
      return isUserDisabled && !isBlankUser;

    case 'activateUser':
      return isNewUser;

    case 'sendNewPassword':
    case 'deactivateUser':
      return !isUserDisabled && !isNewUser && !isBlankUser;

    default:
      return false;
  }
};

const UpdateUserModal = ({
  user,
  isOpen,
  onClose,
  userInfo,
}) => {
  const dispatch = useDispatch();

  const error = useSelector(support.supportErrorSelector);
  const isPasswordSent = useSelector(support.isPasswordSentSelector);
  const doneActivateUser = useSelector(support.doneActivateUserSelector);
  const doneDeactivateUser = useSelector(support.doneDeactivateUserSelector);
  const waitingUpdateUser = useSelector(support.waitingUpdateUserSelector);

  const doneReset2FA = useSelector(support.doneReset2FAUserSelector);

  const [initialValues, setInitialValues] = useState(initialData(user));

  const { restrictedPanelOptions, hospitalOptions, usHospitalIds } = useUserFormOptions();

  useEffect(() => {
    setInitialValues(initialData(user));
  }, [user]);

  useOnUpdateUser(() => {
    if (isOpen) {
      onClose();
    }
  });

  const handleCreate = (newValues) => {
    const values = {
      ...newValues,
      id: user.user_id,
      is_disabled: user.is_disabled,
      is_new: user.is_new,
    };
    // Send this field to backend only when it has been changed by the user
    if (initialValues.has_eea_rights === values.has_eea_rights) {
      delete values.has_eea_rights;
    }
    dispatch(support.updateUser(values));
  };

  const sendPassword = () => dispatch(support.sendPassword({ id: user.user_id }));

  const activateUser = () => dispatch(support.activateUser({ id: user.user_id }));

  const reset2FA = () => dispatch(support.reset2FA({ id: user.user_id }));

  const activateUserAndSendPassword = () => {
    activateUser();
    // In a situation where Nucleus backend does not lock tables
    // it is important that both functions are not processed by
    // backend at the same time because they are both trying to
    // create the password_last_changed user setting
    setTimeout(sendPassword, 300);
  };

  const deactivateUser = () => dispatch(support.deactivateUser({ id: user.user_id }));

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="xl"
      aria-labelledby="contained-modal-title-sm"
    >
      <Modal.Header closeButton>
        <h2 className={styles.header}>
          Modify User
        </h2>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validate={validateUserForm(usHospitalIds)}
        enableReinitialize
      >
        <>
          <Modal.Body>
            <UserForm
              hospitalOptions={hospitalOptions}
              restrictedPanelOptions={restrictedPanelOptions}
              isAuthorizedProvider={user.is_authorized_provider}
              authorizedProviderLastApproved={user.authorized_provider_last_approved}
              isBlankUser={user.is_blank_user}
              userRole={user.role}
            />

            {isPasswordSent && <div><p className="text-success">Password send scheduled.</p></div>}
            {doneActivateUser && <div><p className="text-success">User activated.</p></div>}
            {doneDeactivateUser && <div><p className="text-success">User deactivated.</p></div>}
            {doneReset2FA && <div><p className="text-success">2FA token has been removed.</p></div>}
          </Modal.Body>
          <Modal.Footer>
            {error && <p className={`${styles.error} clearfix`}>{error}</p>}

            {shouldShowButton('reset2FA', user, userInfo) && (
              <button
                type="button"
                className="btn btn-default btn-second"
                disabled={doneReset2FA}
                onClick={reset2FA}
              >
                Reset 2FA
              </button>
            )}

            {shouldShowButton('activateUser', user) && (
              <button
                type="button"
                className="btn btn-default btn-second"
                disabled={doneActivateUser}
                onClick={activateUser}
              >
                Activate user
              </button>
            )}

            {shouldShowButton('activateAndSendPassword', user) && (
              <button
                type="button"
                className="btn btn-default"
                disabled={doneActivateUser}
                onClick={activateUserAndSendPassword}
              >
                Activate user and send new password
              </button>
            )}

            {shouldShowButton('sendNewPassword', user) && (
              <button
                type="button"
                className="btn btn-default"
                disabled={isPasswordSent || doneDeactivateUser}
                onClick={sendPassword}
              >
                Send new password
              </button>
            )}

            {shouldShowButton('deactivateUser', user) && (
              <button
                type="button"
                className="btn btn-default"
                disabled={doneDeactivateUser}
                onClick={deactivateUser}
              >
                Deactivate user
              </button>
            )}

            <button
              type="button"
              className="btn btn-default"
              onClick={onClose}
            >
              Cancel
            </button>
            <SaveUserButton
              usHospitalIds={usHospitalIds}
              onClick={handleCreate}
            />
          </Modal.Footer>
        </>
      </Formik>

      {waitingUpdateUser && (
        <div className={styles.spinner_block}>
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

UpdateUserModal.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string,
    is_new: PropTypes.bool,
    is_disabled: PropTypes.bool,
    is_authorized_provider: PropTypes.bool,
    authorized_provider_last_approved: PropTypes.string,
    is_blank_user: PropTypes.bool,
    role: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    internal_role_rights: PropTypes.bool,
  }).isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.auth.userInfo,
  }),
)(UpdateUserModal);
