import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'react-bootstrap';
import styles from './CheckLoginModal.scss';

const CheckLoginModal = ({ handleLogout }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.overlay}>
      <div className={styles.login_modal}>
        <div className="mb-1">
          {!loading
            ? 'Your login session is no longer valid. Please login again.'
            : 'Redirecting to login portal. Please wait...'}
        </div>

        <Button
          variant="primary"
          disabled={loading}
          onClick={(event) => {
            setLoading(true);
            handleLogout(event);
          }}
        >
          {!loading ? (
            'Log In'
          ) : (
            <Spinner
              as="div"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>
    </div>
  );
};

CheckLoginModal.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default CheckLoginModal;
