import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import LabelInput from 'components/Formik/LabelInput';

import styles from './DeliveryEntry.scss';

const DeliveryAddress = ({
  prefix,
  onRemove,
  isSupport,
}) => (
  <div className={styles.subscription_item}>
    <Field
      name={`${prefix}first_name`}
      component={LabelInput}
      label="First name"
      required={!isSupport}
    />

    <Field
      name={`${prefix}last_name`}
      component={LabelInput}
      label="Last name"
      required={!isSupport}
    />

    <Field
      name={`${prefix}organization`}
      component={LabelInput}
      label="Institution"
      required={!isSupport}
    />

    <Field
      name={`${prefix}address_line_1`}
      component={LabelInput}
      label="Address 1st line"
      required={!isSupport}
    />

    <Field
      name={`${prefix}address_line_2`}
      component={LabelInput}
      label="Address 2nd line"
    />

    <Field
      name={`${prefix}zip`}
      component={LabelInput}
      label="Zip"
      required={!isSupport}
    />

    <Field
      name={`${prefix}city`}
      component={LabelInput}
      label="City"
      required={!isSupport}
    />

    <Field
      name={`${prefix}country`}
      component={LabelInput}
      label="Country"
      required={!isSupport}
    />

    <div>
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={onRemove}
      >
        Remove item
      </button>
    </div>
  </div>
);

DeliveryAddress.propTypes = {
  prefix: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  isSupport: PropTypes.bool.isRequired,
};

DeliveryAddress.defaultProps = {
  prefix: '',
};

export default DeliveryAddress;
