import PropTypes from 'prop-types';

export const ScreeningDuoPatientPropTypes = {
  orderStatus: PropTypes.shape({}).isRequired,
  sampleTypeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInputValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  sampleDisposalCategoryOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  reprintLabId: PropTypes.func.isRequired,

};

export const ScreeningDuoPatientDefaultProps = {};
