import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrder,
  getResultsDeliveryInfo,
  getPanelQuestions,
} from 'redux/modules/orders';
import { userInfoSelector } from 'redux/modules/auth';

const useOrderInfoCommonData = ({ orderId, getCustomPanelInfo = false }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector);

  const [orderInfoDataLoading, setOrderInfoDataLoading] = useState(true);
  const [orderInfoDataError, setOrderInfoDataError] = useState(null);
  const [orderInfo, setOrderInfo] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState(null);
  const [sampleTypePanelQuestions, setSampleTypePanelQuestions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setOrderInfoDataLoading(true);
      setOrderInfoDataError(null);

      try {
        const [
          newOrderInfo,
          newDeliveryInfo,
          newSampleTypePanelQuestions,
        ] = await Promise.all([
          dispatch(getOrder(orderId, {
            get_order_kit_info: true,
            get_custom_panel_info: getCustomPanelInfo,
          })),
          dispatch(getResultsDeliveryInfo(orderId)),
          dispatch(getPanelQuestions(orderId, 3)),
        ]);

        setOrderInfoDataLoading(false);
        setOrderInfo(newOrderInfo);
        setDeliveryInfo(newDeliveryInfo);
        setSampleTypePanelQuestions(newSampleTypePanelQuestions);
      } catch (e) {
        setOrderInfoDataError(e.message);
        setOrderInfoDataLoading(false);
      }
    };

    fetchData();
  }, [dispatch, orderId, getCustomPanelInfo, userInfo]);

  return {
    orderInfoDataLoading,
    orderInfoDataError,
    orderInfo,
    deliveryInfo,
    sampleTypePanelQuestions,
  };
};

export default useOrderInfoCommonData;
