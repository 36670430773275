import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Page, { initPageState, handlePagePromise } from 'components/Page';
import EditHospitalForm from 'admin/components/EditHospitalForm';
import * as supportActions from 'redux/modules/support';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getHospital: supportActions.getHospital,
  saveHospital: supportActions.saveHospital,
  deleteHospital: supportActions.deleteHospital,
}, dispatch);

class EditHospitalPage extends Component {
  static propTypes = {
    // Fields
    match: PropTypes.shape({
      params: PropTypes.shape({
        hospitalId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    // Methods
    getHospital: PropTypes.func.isRequired,
    saveHospital: PropTypes.func.isRequired,
    deleteHospital: PropTypes.func.isRequired,
  };

  state = initPageState();

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  async fetchData() {
    const hospital = await this.fetchHospital();
    return { hospital };
  }

  fetchHospital() {
    const {
      getHospital,
      match,
    } = this.props;

    const {
      hospitalId,
    } = match.params;

    switch (hospitalId) {
      case 'new':
        // Init hospital
        return Promise.resolve({
          id: 0,
          country_id: 0,
        });

      default: {
        // Try to fetch hospital
        const numericHospitalId = parseInt(hospitalId, 10);
        if (numericHospitalId > 0) {
          return getHospital(numericHospitalId);
        }

        return Promise.reject(new Error('Incorrect hospital ID'));
      }
    }
  }

  renderForm = () => {
    const {
      saveHospital,
      deleteHospital,
      history,
    } = this.props;

    const {
      data: {
        hospital,
      },
    } = this.state;

    return (
      <EditHospitalForm
        isModify={hospital.id > 0}
        hospital={hospital}
        onSave={saveHospital}
        onDelete={() => deleteHospital(hospital.id)}
        onClose={() => {
          history.push('/hospitals');
        }}
      />
    );
  }

  render() {
    const {
      page,
    } = this.state;

    return (
      <Page
        cols={8}
        offsetCols={2}
        previousPageLink="/hospitals"
        previousPageLabel="hospitals"
        title="Edit hospital"
        initialData={this.initialData}
        render={this.renderForm}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EditHospitalPage);
