import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as orderActions from 'redux/modules/orders';

import styles from './ProductListItem.scss';

const GetPdfInfo = ({ getPdfForProduct, product }) => (
  <button
    type="button"
    className={styles.icon_button}
    onClick={() => getPdfForProduct(product.panel_id)}
  >
    <span className={styles.panel_read_more}>
      <i className="glyphicon glyphicon-lg glyphicon-info-sign" aria-hidden="true" />
    </span>
  </button>
);

GetPdfInfo.propTypes = {
  getPdfForProduct: PropTypes.func.isRequired,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    panel_id: PropTypes.number,
  }).isRequired,
};

export default connect(
  () => ({}),
  {
    getPdfForProduct: orderActions.getPdfForProduct,
  },
)(GetPdfInfo);
