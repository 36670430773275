import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { LabelSelect, LabelInput } from 'components';

const SampleDisposalCategorySelect = ({
  handleInputValue,
  isSampleInAnalysisStatus,
  onChangeValue,
  isImmediateDisposalProvince,
  isSparkSpecialType,
  sampleDisposalCategory,
  sampleDisposalCategoryOptions,
  isScreeningSpecialType,
  label,
}) => {
  const handleDisposalChange = useCallback((event) => {
    onChangeValue('sample_disposal_category', handleInputValue(event));
  }, [onChangeValue, handleInputValue]);

  const immediateDisposalEvent = {
    target: {
      value: 'immediate_disposal',
    },
  };

  useEffect(() => {
    /*
      * We set the default `sample disposal category` value to `immediate disposal` on the ff.
      * There is no `sample disposal category` value, (this will prevent replacing old orders)
      * The order belongs to an immediate disposal USA State/Province
      * The order is a spark special type (mrt)
    */
    if (!sampleDisposalCategory
      && isImmediateDisposalProvince
      && !isSparkSpecialType
    ) {
      handleDisposalChange(immediateDisposalEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isScreeningSpecialType
      ? (
        <LabelInput
          inputSize="6"
          label={label}
          labelSize="6"
          name="sample_disposal_category"
          readOnly
          value="No consent - 1-year storage"
        />
      )
      : (
        <LabelSelect
          inputSize="6"
          label={label}
          labelSize="6"
          name="sample_disposal_category"
          onChange={(event) => handleDisposalChange(event)}
          options={sampleDisposalCategoryOptions}
          placeholder="Select sample category..."
          required={isSampleInAnalysisStatus}
          value={sampleDisposalCategory}
        />
      )
  );
};

SampleDisposalCategorySelect.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  isSampleInAnalysisStatus: PropTypes.bool.isRequired,
  isImmediateDisposalProvince: PropTypes.bool.isRequired,
  isSparkSpecialType: PropTypes.bool.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  sampleDisposalCategory: PropTypes.string,
  sampleDisposalCategoryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  isScreeningSpecialType: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

SampleDisposalCategorySelect.defaultProps = {
  sampleDisposalCategory: null,
};

export default SampleDisposalCategorySelect;
