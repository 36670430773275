/**
 * Utility functions for patient history questions
 */

import { PATIENT_ADDRESSES_QUESTIONS, PATIENT_MINOR_QUESTIONS } from 'helpers/constants/patientInformationFieldConstants';

/**
 * Convert question options to array and set selected option id
 */
export function prepareQuestionsForForm(questions) {
  function selectedOptionId(options) {
    const selected = options.find((o) => o.selected);
    if (selected) {
      return selected.question_option_id;
    }

    return '';
  }

  return questions.map((question) => {
    switch (question.type) {
      case 'radio':
      case 'select': {
        const options = (question.question_options || [])
          .map((option) => ({
            ...option, // Input components accept only string values
            question_option_id: `${option.question_option_id}`,
          }));

        return {
          ...question,
          question_options: options,
          // Set selected option id by finding it in the options
          selected_option_id: selectedOptionId(options),
        };
      }

      case 'checkbox': {
        const options = (question.question_options || [])
          .map((option) => Object.assign(option, {
            // Input components accept only string values
            question_option_id: `${option.question_option_id}`,
          }));

        return {
          ...question,
          question_options: options,
          selected_option_ids: options.filter((o) => o.selected).map((o) => o.question_option_id),
        };
      }

      default:
    }

    return question;
  });
}

/**
 * Creates a map with conditional questions that should be shown based on the selected options.
 */
export function getSelectedConditionalQuestions(questions) {
  const selectedQuestions = {};

  questions?.forEach((question) => {
    const selected = question.selected_option_id;
    if (selected && question.question_options) {
      question.question_options.forEach((option) => {
        if (option.conditional_question_id && option.question_option_id === selected) {
          selectedQuestions[option.conditional_question_id] = true;
        }
      });
    }
  });

  return selectedQuestions;
}

export function questionAnswerField(question) {
  switch (question.type) {
    case 'radio':
    case 'select':
      return 'selected_option_id';

    case 'checkbox':
      return 'selected_option_ids';

    default:
      return 'answer';
  }
}

export function loopQuestions(questions, cb) {
  const selectedQuestions = getSelectedConditionalQuestions(questions);
  const isQuestionVisible = (question) => (
    !question.is_hidden_by_default
    || selectedQuestions[question.question_id]
  );

  return cb({ isQuestionVisible });
}

/**
 * Find the question that defines if the patient is a minor.
 * @use If the patient is a minor, show the parent/guardian questions.
 * @param {Array} questions
 */

export const isThePatientMinorQuestion = (questions) => questions.find((question) => question.name === 'is_patient_minor');

/**
 * Convert questions coming from form to the payload to be send to backend.
 */
export function constructQuestionAnswers(questions, hideAddressesQuestions = false) {
  let updatedQuestions = questions;
  const patientMinor = isThePatientMinorQuestion(questions);
  const hidePatientMinorQuestions = patientMinor?.question_options[0]?.question_option_id
    !== patientMinor?.selected_option_id;

  // If the patient is not a minor, hide the parent/guardian questions and empty the answers
  updatedQuestions = questions?.map((question) => {
    if ((hidePatientMinorQuestions && PATIENT_MINOR_QUESTIONS.includes(question.name))
      || (hideAddressesQuestions
        && PATIENT_ADDRESSES_QUESTIONS.includes(question.name)
      )
    ) {
      return {
        ...question,
        is_hidden_by_default: true,
      };
    }
    return question;
  });

  return loopQuestions(updatedQuestions, ({ isQuestionVisible }) => (
    updatedQuestions.reduce((acc, question) => {
      let answer = question[questionAnswerField(question)];

      if (question.free_text_option_question) {
        answer = {
          answer,
          // Backend doesn't support null free text answer. Fallback to empty string
          free_text_option_answer: question.free_text_option_answer || '',
        };
      }

      if (!isQuestionVisible(question)) {
        // Reset hidden questions
        answer = '';
      }

      return Object.assign(acc, {
        [question.question_id]: answer,
      });
    }, {})
  ));
}

/**
 * Group questions with names starting with `${prefix}_`.
 * Suffix reference is used to find a reference question in the same group.
 * Reference question defines the visibility of the group. If it has been
 * answered then the group should be shown.
 */
export function groupQuestions(questions, prefix, suffixReference) {
  const groups = [];
  const filtered = questions.filter((question) => question.name.startsWith(`${prefix}_`));

  filtered.forEach((question) => {
    const number = parseInt(question.name.split('_')[1], 10);
    if (groups.length < number) {
      groups.push({
        id: number,
        questions: [],
      });
    }

    // Questions start with 1. Arrays start with 0.
    groups[number - 1].questions.push(question);
  });

  const findQuestion = (name, number) => {
    // Replace number in question name with desired one
    const searchName = name.replace(/_[0-9]+_/, `_${number}_`);
    return questions.find((question) => question.name === searchName) || {};
  };

  groups.forEach((group, index) => {
    if (index > 0) {
      group.questions.forEach((question) => {
        /* eslint-disable no-param-reassign */
        // Hide question if it is not in the first group
        // and reference question hasn't been answered in the same group.
        const questionReference = findQuestion(`${prefix}_1_${suffixReference}`, group.id);
        const answerReference = questionReference[questionAnswerField(questionReference)];
        question.is_hidden_by_default = !answerReference;

        // Copy compulsory behaviour from first group question.
        question.is_compulsory = !!findQuestion(question.name, 1).is_compulsory;
        /* eslint-enable no-param-reassign */
      });
    }
  });

  return groups;
}

/**
 * Convert grouped questions coming from form to the payload to be send to backend.
 */
export function constructGroupedQuestionAnswers(groups) {
  return groups.reduce((acc, group) => Object.assign(
    acc,
    constructQuestionAnswers(group.questions),
  ), {});
}

export function constructPatientQuestionsInBillingInfo(questions, isPatientBilling) {
  return questions?.map((question) => {
    if ((question.name === 'icd10cod_1')
      || (question.name === 'hospitals_1' && isPatientBilling)) {
      return { ...question, is_compulsory: true };
    }
    if (question.name === 'hospitals_1' && !isPatientBilling) {
      return { ...question, is_compulsory: false };
    }
    return { ...question, is_hidden_by_default: true };
  });
}

/**
 * Filter questions based on the patient minor status.
 * @param {Array} questions
 * @param {Boolean} isPatientMinorQuestions
 * @returns {Array}
 * @use If the patient is a minor, show the parent/guardian questions.
 * We had to divide the questions as these new questions are placed at the bottom
 */
export const mrtMinorPatientQuestions = (questions, isPatientMinorQuestions = false) => {
  const patientMinorQuestionsArr = ['is_patient_minor',
    'parent_guardian_first_name',
    'parent_guardian_last_name',
    'parent_guardian_email_address'];

  return questions.filter((question) => (isPatientMinorQuestions
    ? patientMinorQuestionsArr.includes(question.name)
    : !patientMinorQuestionsArr.includes(question.name)));
};
