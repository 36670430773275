import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  ErrorMessage,
} from 'formik';

export const defaultOnChange = (form, field) => form.setFieldValue(field.name, !field.value);

const tooltip = (
  <Tooltip bsPrefix="tooltip" id="tooltip">Select this, if the user needs access to order data in US Nucleus</Tooltip>
);

const Checkbox = ({
  // Field
  field,
  form,

  // Appearance
  label,
  labelClassName,
  className,
  hint,
  onChange,
  disabled,
  tooltipIcon,
}) => (
  <div>
    <div className={`form-check checkbox ${className}`}>
      <input
        type="checkbox"
        id={field.name}
        className="form-check-input"
        checked={field.value || false}
        disabled={disabled}
        {...field}
        onChange={(e) => onChange(form, field, e)}
      />

      <label htmlFor={field.name} className={`form-check-label ${labelClassName}`}>
        {label}
        { tooltipIcon && (
          <OverlayTrigger
            placement="right"
            overlay={tooltip}
          >
            <span className="info-icon">
              <i className="glyphicon glyphicon-lg glyphicon-exclamation-sign" aria-hidden="true" />
            </span>
          </OverlayTrigger>
        )}
      </label>

      {field.name && (
        <ErrorMessage
          name={field.name}
          component="p"
          className="text-danger"
        />
      )}
    </div>
    {hint && (<p className="small">{hint}</p>)}
  </div>
);

Checkbox.propTypes = {
  // Field
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,

  // Appearance
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  tooltipIcon: PropTypes.bool,
};

Checkbox.defaultProps = {
  labelClassName: 'col-form-label',
  className: 'form-group',
  hint: null,
  onChange: defaultOnChange,
  disabled: false,
  tooltipIcon: false,
};

export default Checkbox;
