import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ErrorMessage from './ErrorMessage';

const LabelInput = ({
  // Field
  field,

  // Appearance
  type,
  label,
  labelSize,
  inputSize,
  placeholder,
  hint,
  suffix,
  short,
  required,
  disabled,
  autocomplete,
  readOnly,
  onBlur,
  onChange,
  autoFocus,
}) => (
  <div className={classNames('form-group', {
    row: label,
  })}
  >
    {label && (
      <label htmlFor={field.name} className={`col-md-${labelSize} col-form-label`}>
        {required ? `${label} *` : label}
        {hint && (<p className="small">{hint}</p>)}
      </label>
    )}
    <div className={classNames({
      [`col-md-${inputSize}`]: label,
      'no-padding-left': label && labelSize !== 12,
    })}
    >
      <input
        type={type}
        id={field.name}
        data-testid={label && label === 'ICD-10 codes' ? 'icd_codes' : null}
        className={classNames('form-control', {
          short,
        })}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        autoComplete={autocomplete}
        readOnly={readOnly}
        autoFocus={autoFocus}
        {...field}
        value={field.value === undefined || field.value == null ? '' : field.value /* for some fields value could be null */}
        onBlur={(e) => {
          if (field.onBlur) {
            field.onBlur(e);
          }

          if (onBlur) {
            onBlur(e);
          }
        }}
        onChange={(e) => {
          if (field.onChange) {
            field.onChange(e);
          }

          if (onChange) {
            onChange(e);
          }
        }}
      />

      {suffix && (<span className="small">{suffix}</span>)}

      {field.name && (<ErrorMessage name={field.name} />)}
    </div>
  </div>
);

LabelInput.propTypes = {
  // Field
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,

  // Appearance
  type: PropTypes.string,
  label: PropTypes.string,
  labelSize: PropTypes.number,
  inputSize: PropTypes.number,
  placeholder: PropTypes.string,
  hint: PropTypes.node,
  suffix: PropTypes.string,
  short: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};

LabelInput.defaultProps = {
  type: 'text',
  label: null,
  placeholder: '',
  labelSize: 6,
  inputSize: 6,
  hint: null,
  suffix: null,
  short: false,
  required: false,
  disabled: false,
  autocomplete: 'on',
  readOnly: false,
  onBlur: null,
  onChange: null,
  autoFocus: false,
};

export default LabelInput;
