import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InsuranceProgressBar.scss';

export default class InsuranceProgressBar extends Component {
  static propTypes = {
    status: PropTypes.number,
    finalStatus: PropTypes.number,
    currentStep: PropTypes.number,
    changeStep: PropTypes.func.isRequired,
    changeFinalStatus: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    status: null,
    finalStatus: null,
    currentStep: null,
    steps: [],
  };

  setActiveClass = (key) => {
    this.props.changeStep(key);
  }

  render() {
    const {
      steps, status, finalStatus, currentStep,
    } = this.props;

    return (
      <div className={styles.insurance_progress_bar_container}>
        <ul className={`${styles.insurance_progress_bar} list-unstyled`}>
          {steps.filter((step) => !step.hideLine).map((step) => (
            /*
              eslint-disable
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions
            */
            <li
              key={step.key}
              className={classNames('text-center', {
                [styles.active]: status === step.key,
                [styles.done]: status <= 5 && status >= step.key,
                [styles.visible]: currentStep === step.key,
                [styles.hide_line]: step.hideLine,
                [styles.disabled]: step.disabled,
              })}
              onClick={() => this.setActiveClass(step.key)}
            >
              {step.description}
            </li>
            /*
              eslint-enable
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions
            */
          ))}
        </ul>
        <ul className={`${styles.insurance_progress_bar} ${styles.vertical_progress_bar} list-unstyled`}>
          {steps.filter((step) => step.hideLine).map((step) => (
            /*
              eslint-disable
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions
            */
            <li
              key={step.key}
              className={classNames({
                [styles.active]: finalStatus === step.key,
                [styles.disabledFinalStep]: step.disabled,
              })}
              onClick={() => !step.disabled && this.props.changeFinalStatus(step.status)}
            >
              {step.description}
            </li>
            /*
              eslint-enable
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-noninteractive-element-interactions
            */
          ))}
        </ul>
      </div>
    );
  }
}
