import React from 'react';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';

const ConsentScreeningForm = () => (
  <div className="col-md-8 offset-md-2">
    <div className="form-group">
      <p>
        For healthcare providers&apos; reference, Blueprint Genetics&apos;
        template of informed consent is available on our website:&nbsp;
        <a
          href="https://blueprintgenetics.com/screening/consent"
          className="link-pink"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://blueprintgenetics.com/screening/consent
        </a>
      </p>
      <p>
        Blueprint Genetics does not need an informed consent template to perform
        the ordered test and the consent template does not need to be sent with
        the sample.
      </p>
    </div>
    <hr />
    <Field
      name="consentSigned"
      id="consentSigned"
      component={Checkbox}
      label="I acknowledge that as a healthcare provider and the data controller, I am responsible for obtaining any required informed consent from the patient (including consent for merged partner reports if applicable) and collecting and storing required consent according to the laws of the patient's country/state and/or federal laws."
      labelClassName="consent_signed"
      className="text-left"
    />
  </div>
);

export default ConsentScreeningForm;
