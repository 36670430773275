import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import {
  LabelInput, Spinner, TertiaryButton,
} from 'components';
import {
  waitingShareOrderAdminSelector,
  doneShareOrderAdminSelector,
  supportErrorSelector,
} from 'redux/modules/support';
import usePrevious from 'hooks/usePrevious';
import { LabelCheckbox } from 'admin/components';
import styles from './NewOrderShareModal.scss';

const initialValues = {
  orderId: '',
  emailSource: '',
  emailTargetList: '',
  isShareOrders: false,
  isShareOrdersSuperuser: false,
};

const NewOrderShareModal = ({
  show, onClose, showShareAllOrders, labelText, handleCreate,
}) => {
  const [data, setData] = useState(initialValues);

  const error = useSelector(supportErrorSelector);
  const waitingShareOrderAdmin = useSelector(waitingShareOrderAdminSelector);
  const doneShareOrderAdmin = useSelector(doneShareOrderAdminSelector);

  const prevShow = usePrevious(show);
  const prevError = usePrevious(error);
  const prevDoneShareOrderAdmin = usePrevious(doneShareOrderAdmin);

  useEffect(() => {
    if (show && !prevShow) {
      setData(initialValues);
    }
    if (prevError !== error) {
      setData({ ...data, error });
    }
  }, [show, prevShow, data, prevError, error]);

  useEffect(() => {
    if (!prevDoneShareOrderAdmin && doneShareOrderAdmin) {
      onClose();
    }
  }, [prevDoneShareOrderAdmin, doneShareOrderAdmin, onClose]);

  const handleChange = (event) => {
    const input = event.target;

    setData({
      ...data,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  const {
    orderId, emailSource, emailTargetList, isShareOrders, isShareOrdersSuperuser,
  } = data;

  const onCreate = () => {
    const trimmedEmailTargetList = data.emailTargetList.replace(/\n/g, '');
    handleCreate({ ...data, emailTargetList: trimmedEmailTargetList });
  };

  const checkValidity = () => {
    const numberOfOrderId = Number(orderId);
    return showShareAllOrders
      ? (emailSource && (isShareOrders || isShareOrdersSuperuser))
      : (Number.isInteger(numberOfOrderId) && orderId > 0);
  };

  return (
    <Modal show={show} onHide={onClose} size="xl" aria-labelledby="contained-modal-title-sm">
      <Modal.Header closeButton>
        <h2 style={{ marginTop: 30 }}>{labelText}</h2>
      </Modal.Header>
      <Modal.Body>
        <form>
          {showShareAllOrders ? (
            <LabelInput
              label="Share from email"
              name="emailSource"
              required
              labelSize="6"
              inputSize="6"
              value={emailSource}
              onChange={handleChange}
            />
          ) : (
            <LabelInput
              label="Order ID"
              name="orderId"
              required
              labelSize="6"
              inputSize="6"
              value={orderId}
              onChange={handleChange}
            />
          )}

          <div className="form-group row">
            <label htmlFor="emailTargetList" className="col-md-6 col-form-label">
              Share with emails *
              <p className="small">If multiple emails, use &apos;,&apos; as divider</p>
            </label>
            <textarea
              name="emailTargetList"
              className={`form-control ${styles.textarea}`}
              required
              value={emailTargetList}
              onChange={handleChange}
            />
          </div>

          {showShareAllOrders && (
            <div>
              <LabelCheckbox
                id="isShareOrders"
                name="isShareOrders"
                label="Share orders"
                onChange={(event) => handleChange(event)}
                value={isShareOrders}
                checked={isShareOrders}
              />
              <LabelCheckbox
                id="isShareOrdersSuperuser"
                name="isShareOrdersSuperuser"
                label="Share superuser orders"
                onChange={(e) => handleChange(e)}
                value={isShareOrdersSuperuser}
                checked={isShareOrdersSuperuser}
              />
            </div>
          )}
        </form>
      </Modal.Body>

      <Modal.Footer>
        <span className={styles.error}>{error}</span>
        <TertiaryButton
          labelText={labelText}
          onClick={onCreate}
          disabled={!checkValidity()}
        />
      </Modal.Footer>
      {waitingShareOrderAdmin && (
        <div className={styles.spinner_block}>
          <Spinner />
        </div>
      )}
    </Modal>

  );
};

NewOrderShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showShareAllOrders: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
};

export default NewOrderShareModal;
