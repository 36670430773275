import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable, { Column } from 'react-base-table';
import debounce from 'lodash/debounce';
import Helmet from 'react-helmet';

import {
  getUsers,
  getUser,
  resetCreateUser,
  selectedUserSelector,
} from 'redux/modules/support';
import { AdminHeading, NewUserModal, UpdateUserModal } from 'admin/components';
import mapUser from 'utils/users';
import Spinner from 'components/Spinner/Spinner';
import useModal from 'hooks/useModal';
import useData from 'hooks/useData';
import CommonCell from './components/CommonCell';
import UserEmailCell from './components/UserEmailCell';
import EditCell from './components/EditCell';

import styles from './Users.scss';

const Users = () => {
  const selectedUser = useSelector(selectedUserSelector);
  const [showDeactivatedUsers, setShowDeactivatedUsers] = useState(false);
  const [searchString, setSearchString] = useState('');

  const changeSearchString = debounce((string) => {
    setSearchString(string.toLowerCase());
  }, 200);

  const { toggle: toggleNewUserModal, show: showNewUserModal } = useModal();
  const { toggle: toggleUpdateUserModal, show: showUpdateUserModal } = useModal();
  const {
    data,
    isLoading,
    error,
    setShouldFetch,
  } = useData(() => getUsers(showDeactivatedUsers));

  const title = 'Users';

  const getFilteredUsers = () => {
    if (data) {
      const users = Object.values(data).map((user) => mapUser(user));
      const toSearch = searchString.toLowerCase();

      if (!users || !toSearch) {
        return users;
      }

      const fieldContains = (user, fieldName) => {
        if (user[fieldName]) {
          return user[fieldName].toLowerCase().includes(toSearch);
        }
        return false;
      };

      return users.filter((user) => (
        fieldContains(user, 'userEmail')
            || fieldContains(user, 'userFullName')
            || fieldContains(user, 'userHospitalName')
            || fieldContains(user, 'userRole')
      ));
    }
    return [];
  };

  const dispatch = useDispatch();

  const handleOpenUpdateUserModal = (event, userId) => {
    dispatch(getUser(userId));
    toggleUpdateUserModal();
  };

  const handleNewUserModalClose = () => {
    toggleNewUserModal();
    setShouldFetch(true);
  };

  const handleUpdateUserModalClose = () => {
    toggleUpdateUserModal();

    // Wait for modal close animation to play
    setTimeout(() => {
      dispatch(resetCreateUser());
      setShouldFetch(true);
    }, 300);
  };

  const toggleUsers = () => {
    setShowDeactivatedUsers(!showDeactivatedUsers);
    setShouldFetch(true);
  };

  const renderBody = () => {
    const userData = getFilteredUsers();

    if (userData.length === 0) {
      return (
        <div className={styles.search_error}>
          <p>No users matching the search criteria were found.</p>
        </div>
      );
    }

    if (typeof error === 'string') {
      return (
        <div className={styles.search_error}>
          <p>No users were found.</p>
          <p>{error}</p>
        </div>
      );
    }

    return (
      <BaseTable
        data={userData}
        width={1200}
        height={window.innerHeight - 100}
        className={styles.table}
        rowHeight={60}
      >
        <Column
          cellRenderer={UserEmailCell}
          title="Email"
          key="userEmail"
          dataKey="userEmail"
          width={220}
          flexGrow={2}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Hospital"
          key="userHospitalName"
          dataKey="userHospitalName"
          width={200}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Role"
          key="userRole"
          dataKey="userRole"
          width={100}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Full name"
          key="userFullName"
          dataKey="userFullName"
          width={150}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Orders"
          key="userOrderCount"
          dataKey="userOrderCount"
          width={55}
          flexGrow={1}
          align="center"
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={CommonCell}
          title="Last login"
          key="userLastLogin"
          dataKey="userLastLogin"
          width={80}
          flexGrow={1}
          className={styles.table_column}
          headerClassName={styles.table_header}
        />
        <Column
          cellRenderer={EditCell}
          key="userId"
          dataKey="userId"
          width={55}
          flexGrow={1}
          align="right"
          onOpenDialog={handleOpenUpdateUserModal}
        />
      </BaseTable>
    );
  };

  const props = {
    labelText: '+\xa0 Add User',
    title: 'Users',
    buttonFunc: toggleNewUserModal,
    searchFunc: (event) => changeSearchString(event.target.value),
    placeholder: 'Find a user',
    id: 'show_deactivated_users_checkbox',
    htmlFor: 'show_deactivated_users_checkbox',
    checkboxText: 'View deactivated users',
    checked: showDeactivatedUsers,
    onChange: toggleUsers,
  };

  return (
    <div className={`${styles.users_container} container`}>
      <Helmet title={title} />
      <div className={styles.heading}>
        <AdminHeading {...props} />
      </div>
      <div className="col-lg-12">
        {isLoading ? (
          <div className="spinner-wrapper">
            <Spinner />
          </div>
        ) : (
          <div>{renderBody()}</div>
        )}
      </div>
      <NewUserModal
        isOpen={showNewUserModal}
        onClose={handleNewUserModalClose}
      />
      {selectedUser && (
        <UpdateUserModal
          user={selectedUser}
          onClose={handleUpdateUserModalClose}
          isOpen={showUpdateUserModal}
        />
      )}
    </div>
  );
};

export default Users;
