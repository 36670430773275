import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import Modal from 'react-bootstrap/Modal';
import { Spinner } from 'components';
import * as support from 'redux/modules/support';

import UserForm from './UserForm';
import styles from './UserModal.scss';
import { validateUserForm } from './userFormValidation';
import SaveUserButton from './SaveUserButton';
import useUserFormOptions from './useUserFormOptions';
import useOnCreateUser from './useOnCreateUser';

const getInitialValues = () => ({
  email: '',
  hospital_id: '',
  department: '',
  role: 'physician',
  title: '',
  full_name: '',
  npi: '',
  allow_order_sharing: false,
  has_eea_rights: false,
  is_super_user: false,
  allowed_panel_ids: [],
  is_authorized_provider: false,
  has_full_us_access: false,
});

const NewUserModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  const error = useSelector(support.supportErrorSelector);
  const waitingCreateUser = useSelector(support.waitingCreateUserSelector);

  const { restrictedPanelOptions, hospitalOptions, usHospitalIds } = useUserFormOptions();

  useOnCreateUser(() => {
    if (isOpen) {
      onClose();
    }
  });

  const initialValues = getInitialValues();

  const handleCreate = (newValues) => {
    const values = {
      ...newValues,
      id: 0,
    };

    // Send this field to backend only when it has been changed by the user
    if (initialValues.has_eea_rights === values.has_eea_rights) {
      delete values.has_eea_rights;
    }
    dispatch(support.createUser(values));
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="xl"
      aria-labelledby="contained-modal-title-sm"
    >
      <Modal.Header closeButton>
        <h2 className={styles.header}>
          Create User
        </h2>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validate={validateUserForm(usHospitalIds)}
      >
        <>
          <Modal.Body>
            <UserForm
              hospitalOptions={hospitalOptions}
              restrictedPanelOptions={restrictedPanelOptions}
              isBlankUser={null}
            />
          </Modal.Body>
          <Modal.Footer>
            {error && <p className={`${styles.error} clearfix`}>{error}</p>}

            <button
              type="button"
              className="btn btn-default"
              onClick={onClose}
            >
              Cancel
            </button>
            <SaveUserButton
              onClick={handleCreate}
              usHospitalIds={usHospitalIds}
            />
          </Modal.Footer>
        </>
      </Formik>

      {waitingCreateUser && (
        <div className={styles.spinner_block}>
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

NewUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewUserModal;
