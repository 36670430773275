import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Redirect, Switch } from 'react-router';

import App from 'containers/App/App';
import OrdersList from 'containers/OrdersList/OrdersList';
import NewOrder from 'containers/NewOrder/NewOrder';
import NotFound from 'containers/NotFound/NotFound';
import AccountSettingsView from 'containers/AccountSettingsView/AccountSettingsView';
import AccountSettingsEdit from 'containers/AccountSettingsEdit/AccountSettingsEdit';
import AccountSettingsChangePw from 'containers/AccountSettingsChangePw/AccountSettingsChangePw';
import AccountSettingsEnable2fa from 'containers/AccountSettingsEnable2fa/AccountSettingsEnable2fa';
import AccountSettingsDisable2fa from 'containers/AccountSettingsDisable2fa/AccountSettingsDisable2fa';
import OrderReport from 'containers/OrderReport/OrderReport';
import AboutNucleus from 'containers/AboutNucleus/AboutNucleus';
import AccountSettingsEditOptouts from 'containers/AccountSettingsEditOptouts/AccountSettingsEditOptouts';
import Users from 'admin/containers/Users/Users';
import EditHospitalProductVisibilityPage from 'admin/EditHospitalVisibility/EditHospitalProductVisibilityPage';
import EditHospitalPage from 'admin/containers/EditHospitalPage';
import Hospitals from 'admin/containers/Hospitals/Hospitals';
import Groups from 'admin/containers/Groups/Groups';
import OrderShares from 'admin/containers/OrderShares/OrderShares';
import OrderTransfers from 'admin/containers/OrderTransfers/OrderTransfers';
import OtherAccountsSettings from 'admin/containers/OtherAccountsSettings/OtherAccountsSettings';
import BatchOrder from 'admin/containers/BatchOrder/BatchOrderPage';
import NewBlankOrderPage from 'admin/containers/NewBlankOrderPage';
import EditOrderPage from 'admin/containers/EditOrderPage';
import ViewOrderPage from 'admin/containers/ViewOrderPage';
import ConvertBlankOrderPage from 'admin/containers/ConvertBlankOrderPage';
import Questions from 'admin/containers/Questions/Questions';
import AddAuthorizedProvider from 'containers/AddAuthorizedProvider/AddAuthorizedProvider';
import EditQuestionPage from 'admin/components/EditQuestionPage/EditQuestionPage';
import { isUsRegion } from 'utils/envUtils';
import {
  LoginForm,
  RequestPasswordForm,
  SignupForm,
  ForgotPasswordForm,
} from 'components';
import LoginLayout from 'layout/LoginLayout';
import PrivateRoute from 'router/PrivateRoute';
import NotLoggedRoute from 'router/NotLoggedRoute';
import { shouldUseAlbAuth } from 'redux/modules/auth';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

function authenticationRoutes() {
  const paths = ['/login', '/request_password', '/forgot_password', '/signup'];

  if (shouldUseAlbAuth()) {
    return (
      <PrivateRoute path={paths} component={() => <Redirect to="/orders" />} />
    );
  }

  return (
    <NotLoggedRoute
      path={paths}
      render={({ match }) => (
        <LoginLayout>
          {(() => {
            switch (match.url) {
              case '/request_password':
                return <RequestPasswordForm />;
              case '/forgot_password':
                return <ForgotPasswordForm />;
              case '/signup':
                return <SignupForm />;
              default:
                return <LoginForm />;
            }
          })()}
        </LoginLayout>
      )}
    />
  );
}

const AppRouter = () => (
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
  >
    <App>
      <Switch>
        {/* Home (main) route */}
        <PrivateRoute
          exact
          path="/"
          component={() => <Redirect to="/orders" />}
        />

        {authenticationRoutes()}

        {/* Routes requiring login */}
        <PrivateRoute
          path="/orders/new/blank"
          SupportRole
          component={NewBlankOrderPage}
        />
        <PrivateRoute path="/orders/new/:productCode" component={NewOrder} />
        <PrivateRoute
          path="/orders/new"
          component={(props) => <NewOrder {...props} title="New Test" />}
        />
        {!isUsRegion() && (
        <PrivateRoute
          path="/diagnostic_tests"
          component={(props) => <NewOrder {...props} title="Tests" />}
        />
        )}
        <PrivateRoute
          exact
          path="/orders/report/:orderId"
          component={OrderReport}
        />
        <PrivateRoute
          exact
          path="/orders/:orderId/edit"
          component={EditOrderPage}
        />
        <PrivateRoute
          exact
          path="/orders/:orderId/convert"
          component={ConvertBlankOrderPage}
        />
        <PrivateRoute
          exact
          path="/orders/:orderId/view"
          component={ViewOrderPage}
        />
        <PrivateRoute path="/orders" component={OrdersList} />

        <PrivateRoute
          path="/account-settings/edit-profile"
          component={AccountSettingsEdit}
        />
        <PrivateRoute
          path="/account-settings/change-password"
          component={AccountSettingsChangePw}
        />
        <PrivateRoute
          path="/account-settings/enable-2fa"
          component={AccountSettingsEnable2fa}
        />
        <PrivateRoute
          path="/account-settings/disable-2fa"
          component={AccountSettingsDisable2fa}
        />
        <PrivateRoute
          path="/account-settings/email-notification-settings"
          component={AccountSettingsEditOptouts}
        />
        <PrivateRoute path="/account-settings" component={AccountSettingsView} />

        <PrivateRoute path="/confirm-2fa" component={AccountSettingsEnable2fa} />
        <PrivateRoute path="/disable-2fa" component={AccountSettingsDisable2fa} />

        {/* NOTE (Wed May 19 14:12:16 EEST 2021, Trung): route never reached */}
        <PrivateRoute path="/welcome-to-nucleus" component={AboutNucleus} />

        <PrivateRoute
          path="/add-authorized-provider"
          component={AddAuthorizedProvider}
        />

        <PrivateRoute SupportRole path="/users" component={Users} />
        <PrivateRoute
          SupportRole
          exact
          path="/hospitals/:hospitalId"
          component={EditHospitalPage}
        />
        <PrivateRoute
          SupportRole
          path="/hospitals/:hospitalId/edit_visibility"
          component={EditHospitalProductVisibilityPage}
        />
        <PrivateRoute SupportRole path="/hospitals" component={Hospitals} />
        <PrivateRoute SupportRole path="/groups" component={Groups} />
        <PrivateRoute
          SupportRole
          path="/order_transfers"
          component={OrderTransfers}
        />
        <PrivateRoute SupportRole path="/order_sharing" component={OrderShares} />
        <PrivateRoute SupportRole path="/batch_order" component={BatchOrder} />
        <PrivateRoute
          SupportRole
          path="/other_accounts_settings"
          component={OtherAccountsSettings}
        />
        <PrivateRoute
          SupportRole
          exact
          path="/questions/:questionId"
          component={EditQuestionPage}
        />
        <PrivateRoute SupportRole path="/questions" component={Questions} />

        {/* Catch all route */}
        <Route component={NotFound} />
      </Switch>
    </App>
  </ErrorBoundary>

);

export default AppRouter;
