import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { requestPasswordChange } from 'redux/modules/auth';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Formik, Field, Form,
} from 'formik';
import LabelInput from 'components/Formik/LabelInput';

import styles from './RequestPasswordForm.scss';

const requestPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  requestPasswordChange,
}, dispatch);

/* eslint-disable react/prefer-stateless-function */
class RequestPasswordForm extends Component {
  static propTypes = {
    requestPasswordChange: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <div>
        <h1 className="no-margin-top">Nucleus</h1>
        <h2>Recover password</h2>
        <p>Get new password to this email:</p>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={requestPasswordValidationSchema}
          onSubmit={(values, actions) => {
            this.props
              .requestPasswordChange(values)
              .then(() => {
                // eslint-disable-next-line no-alert, no-restricted-globals
                alert(
                  'Password change request has been sent to your email if the email exists in the system',
                );
                this.props.history.push({
                  pathname: '/login',
                });
              })
              .catch((e) => {
                actions.setFieldError('general', e.message);
              });
          }}
        >
          {(formProps) => (
            <Form className="form-group">
              <Field
                type="email"
                placeholder="Your e-mail address"
                name="email"
                component={LabelInput}
              />

              <button
                type="submit"
                className={`btn btn-primary btn-block ${styles['bottom-buffer']}`}
              >
                Request new password
              </button>

              {formProps.errors.general && (
                <p className="text-danger">{formProps.errors.general}</p>
              )}
            </Form>
          )}
        </Formik>
        <Link to="/login">← Back to login</Link>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RequestPasswordForm);
