import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProductListItem.scss';

export default function PanelTierInfo({ product, pricingModel }) {
  const isGeneCountPricing = pricingModel === 'gene_count';
  const isPhenotypePricing = pricingModel === 'phenotype';

  return (
    <>
      {product.panel_tier && isGeneCountPricing && (
        <a href="https://blueprintgenetics.com/pricing/" target="_blank" rel="noopener noreferrer">
          <span className={styles.panel_tier}>{product.panel_tier.size}</span>
        </a>
      )}
      {product.phenotype_tier && isPhenotypePricing && (
        <span className={styles.phenotype_tier}>
          Panel tier:
          {' '}
          {product.phenotype_tier}
        </span>
      )}
    </>
  );
}

PanelTierInfo.propTypes = {
  product: PropTypes.shape({
    panel_tier: PropTypes.shape({
      size: PropTypes.string.isRequired,
    }),
    phenotype_tier: PropTypes.string,
  }).isRequired,
  pricingModel: PropTypes.oneOf(['gene_count', 'phenotype', 'fixed']).isRequired,
};
