import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportTestResults extends Component {
  static propTypes = {
    testResults: PropTypes.string,
  }


  render() {
    const {testResults} = this.props;
    const styles = require('./_OrderReportTestResults.scss');

    return (
      <div>
        <h3 className="h4">Test results</h3>
        <div className={styles.report_text} dangerouslySetInnerHTML={{__html: testResults}}></div>
      </div>
    );
  }
}
