import React from 'react';
import PropTypes from 'prop-types';

const AddressTypes = {
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
};

const AddressCell = ({ cellData }) => {
  const { line1, line2, city } = cellData;
  return (
    <div>
      <div>{line1}</div>
      <div>{line2}</div>
      <div>{city}</div>
    </div>
  );
};

AddressCell.propTypes = {
  cellData: PropTypes.shape(AddressTypes).isRequired,
};

export default AddressCell;
