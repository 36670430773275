import ENVIRONMENTS from 'helpers/constants/environmentConstants';
import {
  APP_REGIONS,
  DEFAULT_APP_REGION,
  RELEASE_DATE,
  RELEASE_DATE_PROD,
} from 'helpers/constants/regionConstants';

// Note that this checks for the built NODE_ENV rather than REACT_APP_ENV.
// Should not be used to check which environment the app is running in.
const isProdEnvironment = () => process.env.NODE_ENV === 'production';

export const isDevEnvironment = () => process.env.REACT_APP_ENV === ENVIRONMENTS.DEVELOPMENT;

export const matchesRegion = (region) => {
  // eslint-disable-next-line no-undef
  const envRegion = String(process.env.REACT_APP_REGION || DEFAULT_APP_REGION)
    .toUpperCase();
  return envRegion === String(region).toUpperCase();
};

export const isUsRegion = () => matchesRegion(APP_REGIONS.US);
export const isEuRegion = () => matchesRegion(APP_REGIONS.EU);

export const getEnvironmentName = (env) => {
  switch (env) {
    case ENVIRONMENTS.DEVELOPMENT:
    case 'development':
      return 'Development';
    case ENVIRONMENTS.STAGING:
      return 'Staging';
    case ENVIRONMENTS.UAT:
      return 'UAT';
    case ENVIRONMENTS.PRODUCTION:
    case 'production':
      return 'Production';
    default:
      return env;
  }
};

export const getNucleusLoginLink = (env) => {
  switch (env) {
    case ENVIRONMENTS.PRODUCTION:
    case 'production':
      return 'https://blueprintgenetics.com/nucleus-login';
    default:
      return 'https://staging-blueprintgenetics.kinsta.cloud/nucleus-login/';
  }
};

export const getSalesforceOrderLink = (order) => {
  const salesforceOrderUrl = String(process.env.REACT_SALESFORCE_ORDER_URL);
  return salesforceOrderUrl.replace('{{salesforce_order_id}}', order.salesforce_order_id);
};

export const getReleaseDate = (env) => {
  // enabling or disabling certain feature based on the date
  switch (env) {
    case ENVIRONMENTS.DEVELOPMENT:
    case ENVIRONMENTS.UAT:
    case ENVIRONMENTS.STAGING:
      return RELEASE_DATE;
    case ENVIRONMENTS.PRODUCTION:
      return RELEASE_DATE_PROD;
    default:
      return RELEASE_DATE;
  }
};

export default {
  isProdEnvironment,
  matchesRegion,
  isUsRegion,
  isEuRegion,
  getEnvironmentName,
  getNucleusLoginLink,
  getSalesforceOrderLink,
  isDevEnvironment,
};
