import React from 'react';
import { Field } from 'formik';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import Checkbox from 'components/Formik/Checkbox';
import { authorizedProviderDescription } from 'utils/forms';

import styles from './SignupForm.scss';

const AuthorizedProviderCheckbox = () => {
  const popover = (
    <Popover id="authorized-provider-checkbox-tooltip" className={styles.popover}>
      {authorizedProviderDescription}
    </Popover>
  );

  const label = (
    <>
      I am an authorized provider
      {' '}
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={popover}
      >
        <i className="glyphicon glyphicon-question-sign" aria-hidden="true" />
      </OverlayTrigger>
    </>
  );

  return (
    <Field
      name="is_authorized_provider"
      className={`form-group ${styles.healthcareProfessional}`}
      labelClassName=""
      component={Checkbox}
      label={label}
    />
  );
};

export default AuthorizedProviderCheckbox;
