import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { GenePropTypes } from 'types/OrderInfoPropTypes';
import ButtonContainer from 'components/StyleComponents/Button/ButtonContainer';

import styles from './ViewOrderInfo.scss';
import GeneList from './GeneList';
import GeneListHeading from './GeneListHeading';

const PanelCustomizationSection = ({
  originalPanelGenes,
  maskedGenes,
  addedGenes,
  customClinicalInfo,
}) => {
  const [originalGenesShown, setOriginalGenesShown] = useState(false);

  const toggleOriginalGenes = () => setOriginalGenesShown(!originalGenesShown);

  return (
    <section>
      <div className="form-check checkbox modal_checkbox">
        <input
          type="checkbox"
          className="form-check-input"
          checked
          disabled
        />
        <label className="view_order_modal_label form-check-label">
          The panel is customized
        </label>
      </div>
      <div>
        <h4 className={styles.extra_info_heading}>Gene information</h4>
        <ButtonContainer onClick={toggleOriginalGenes}>
          <GeneListHeading>
            <span>Panel original genes</span>
            {originalGenesShown ? (
              <i
                className="glyphicon glyphicon-sm glyphicon-menu-up"
                aria-hidden="true"
              />
            ) : (
              <i
                className="glyphicon glyphicon-sm glyphicon-menu-down"
                aria-hidden="true"
              />
            )}
          </GeneListHeading>
        </ButtonContainer>

        {originalGenesShown && originalPanelGenes && (
          <GeneList genes={originalPanelGenes} />
        )}

        <GeneListHeading>
          Removed genes
        </GeneListHeading>
        {maskedGenes && <GeneList genes={maskedGenes} />}
        <GeneListHeading>
          Added genes
        </GeneListHeading>
        {addedGenes && <GeneList genes={addedGenes} />}
      </div>
      <label className="view_order_modal_label">
        Clinical reason for the custom panel
      </label>
      <span>
        {customClinicalInfo || 'N/A'}
      </span>
    </section>
  );
};

PanelCustomizationSection.propTypes = {
  originalPanelGenes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  maskedGenes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  addedGenes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)),
  customClinicalInfo: PropTypes.string,
};

PanelCustomizationSection.defaultProps = {
  originalPanelGenes: null,
  maskedGenes: null,
  addedGenes: null,
  customClinicalInfo: null,
};

export default PanelCustomizationSection;
