import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import Textarea from 'components/Formik/Textarea';
import SimpleDatePicker from 'components/Formik/SimpleDatePicker';
import { LabelAttachments } from 'components';

const insurancePatientRelationOptions = [{
  key: 'self',
  value: 'Self',
}, {
  key: 'spouse',
  value: 'Spouse',
}, {
  key: 'child',
  value: 'Child',
}, {
  key: 'other',
  value: 'Other',
}];

const PatientInsuranceBillingInfo = ({
  orderInfo,
  userInfo,
  aliasUserId,
  values,
  onCreateInsuranceCase,
  insuranceCaseStatus,
  isLegacy,
  isAfterHospitalStatusReleaseAndUsOrder,
}) => (
  <div>
    <LabelAttachments
      orderId={orderInfo.order_id}
      userInfo={userInfo}
      aliasUserId={aliasUserId}
      type="billing_attachment"
      label="Attached files"
      hint="Include copy of both sides of the insurance card. Patient insurance provider may request a letter of medical necessity after submission of the claim."
    />

    <Field
      name="insurance_company"
      component={LabelInput}
      label="Insurance company"
      required
    />

    <Field
      name="insurance_id_no"
      component={LabelInput}
      label="Insurance ID"
    />

    <Field
      name="group_no"
      component={LabelInput}
      label="Group"
    />

    <Field
      name="patient_relation"
      component={SelectInput}
      label="Patient relation to policy holder"
      options={insurancePatientRelationOptions}
      placeholder="Select relation"
    />

    {values.patient_relation === 'other' && (
      <Field
        name="other_patient_relation"
        component={Textarea}
        textareaClassName="additional-textarea"
        placeholder="Specify"
      />
    )}

    {
      isLegacy ? (
        <Field
          name="policy_holder_name"
          component={LabelInput}
          label="Policy holder name"
        />
      ) : (
        <>
          <Field
            name="policy_holder_first_name"
            component={LabelInput}
            label="Policy holder first name"
          />

          <Field
            name="policy_holder_last_name"
            component={LabelInput}
            label="Policy holder last name"
          />
        </>
      )
    }

    <Field
      name="policy_holder_dob"
      component={SimpleDatePicker}
      label="Policy holder Date of Birth"
    />

    {
      isLegacy ? (
        <Field
          name="patient_address"
          component={Textarea}
          label="Patient address"
        />
      ) : (
        <>
          <Field
            name="patient_address_line_1"
            component={LabelInput}
            label="Patient street address line 1"
            required={isAfterHospitalStatusReleaseAndUsOrder}
          />
          <Field
            name="patient_address_line_2"
            component={LabelInput}
            label="Patient street address line 2"
          />
          <Field
            name="patient_city"
            component={LabelInput}
            label="Patient address city"
            required={isAfterHospitalStatusReleaseAndUsOrder}
          />
          <Field
            name="patient_state"
            component={LabelInput}
            label="Patient address state"
            required={isAfterHospitalStatusReleaseAndUsOrder}
          />
          <Field
            name="patient_zip"
            component={LabelInput}
            label="Patient address zip code"
            required={isAfterHospitalStatusReleaseAndUsOrder}
          />
        </>

      )
    }

    <Field
      name="patient_phone"
      component={LabelInput}
      label="Patient phone"
      required={isAfterHospitalStatusReleaseAndUsOrder}
    />

    <Field
      name="patient_email"
      component={LabelInput}
      label="Patient email"
    />

    {onCreateInsuranceCase && (
      <div className="form-group row">
        <label className="col-md-6 col-form-label">
          Insurance case
        </label>

        {!insuranceCaseStatus.created && (
          <button
            className="btn btn-primary"
            type="button"
            disabled={insuranceCaseStatus.creating}
            onClick={onCreateInsuranceCase}
          >
            Create
          </button>
        )}

        {insuranceCaseStatus.created && (<span>Created</span>)}

        {insuranceCaseStatus.error && (
          <p className="text-danger">{insuranceCaseStatus.error}</p>
        )}
      </div>
    )}
  </div>
);

PatientInsuranceBillingInfo.propTypes = {
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number,
  }).isRequired,
  userInfo: PropTypes.shape({}).isRequired,
  aliasUserId: PropTypes.string,
  values: PropTypes.shape({
    patient_relation: PropTypes.string,
  }).isRequired,
  onCreateInsuranceCase: PropTypes.func,
  insuranceCaseStatus: PropTypes.shape({
    created: PropTypes.bool,
    creating: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  isLegacy: PropTypes.bool,
  isAfterHospitalStatusReleaseAndUsOrder: PropTypes.bool,
  formProps: PropTypes.shape({
    values: {
      questions: PropTypes.shape({}),
    },
  }).isRequired,
};

PatientInsuranceBillingInfo.defaultProps = {
  aliasUserId: null,
  onCreateInsuranceCase: null,
  isLegacy: false,
  isAfterHospitalStatusReleaseAndUsOrder: false,
};

export default PatientInsuranceBillingInfo;
