export const INITIAL_STATES = {
  billingInfo: {
    error: null,
    loading: false,
    data: {},
  },
  billingMethods: {
    error: null,
    loading: false,
    data: [],
  },
  insuranceCaseStatus: {
    created: false,
    creating: false,
    error: null,
  },
  isAfterHospitalStatusReleaseAndUsOrder: false,
  patientHistoryQuestions: {
    loading: false,
    error: null,
    questions: [],
  },
};

export const INITIAL_FORM_VALUES = {
  billing_method: '',
  insurance_company: '',
  credit_card_holder_email: '',
  credit_card_holder_name: '',
  credit_card_holder_first_name: '',
  credit_card_holder_last_name: '',
  patient_address_line_1: '',
  patient_city: '',
  patient_state: '',
  patient_zip: '',
  patient_phone: '',
};
