import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useField, useFormikContext } from 'formik';
import { SecondaryButton } from 'components';
import * as orders from 'redux/modules/orders';
import LabelInput from 'components/Formik/LabelInput';
import Checkbox, { defaultOnChange as defaultCheckboxOnChange } from 'components/Formik/Checkbox';
import Textarea from 'components/Formik/Textarea';
import CustomGenesPanel from 'components/CustomGenesPanel/CustomGenesPanel';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import { calculatePanelTier } from 'utils/panelTiers';
import MessageModal from 'components/MessageModal/MessageModal';
import { userInfoSelector } from '../../../redux/modules/auth';

import styles from './_OrderTestFormOrderInfo.scss';
import { getPricingModelForOrder } from '../../../utils/orderInfo';

const getGeneCount = (panelGenes, addedGenes, maskedGenes) => {
  if (!panelGenes || !maskedGenes || !addedGenes) {
    throw new Error('Unexpected parameters mean we are unable to calculate gene count');
  }

  return panelGenes.length - maskedGenes.length + addedGenes.length;
};

const FAILED_TO_LOAD_REQUIRED_DETAILS = 'Failed to load required information to proceed with the order.';

const FlexSection = ({ orderInfo }) => {
  const dispatch = useDispatch();

  const { values, setFieldValue } = useFormikContext();
  // eslint-disable-next-line
  const [_, geneRequestMeta, geneRequestHelpers] = useField('gene_request');
  const [showMessageModal, setShowMessageModal] = useState(false);

  const failedToLoadPanelGeneLists = useSelector(orders.failedToLoadPanelGeneListsSelector);
  const havePanelGeneLists = useSelector(orders.havePanelGeneListsSelector);
  const panelGeneLists = useSelector(orders.panelGeneListsSelector);
  const panelTiers = useSelector(orders.panelTiersSelector);
  const userInfo = useSelector(userInfoSelector);

  const orderPricingModel = getPricingModelForOrder(orderInfo, userInfo);

  /* eslint-disable camelcase */
  const { masked_genes, added_genes, is_customized } = values;
  const { original_panel_genes, panel_genes } = orderInfo;
  const hasOriginalPanelGenes = original_panel_genes || panel_genes;

  const genes = panelGeneLists?.panel?.genes;

  const customizedGeneCount = panelGeneLists && getGeneCount(genes, added_genes, masked_genes);
  const baseGeneCount = (
    hasOriginalPanelGenes
    && (original_panel_genes ? original_panel_genes.length : panel_genes.length)
  );
  const handleRetryLoadDetailsButtonClick = () => dispatch(
    orders.getPanelGeneLists(orderInfo.product.id),
  );

  const loadPanelGenes = () => {
    if (!havePanelGeneLists) {
      // If has not loaded genes
      dispatch(orders.getPanelGeneLists(orderInfo.product.id));
    }
  };

  // The gene count is calculated using:
  // a) the form state (total number of masked or added genes)
  // b) the panels genes (comes in via redux)
  // Therefore external state has an impact on the gene count that is displayed
  // in this component. It's important that when the gene count that comes in
  // via redux might change, that the gene count in the component is reflected.
  // This is achieved with this effect hook.
  useEffect(() => {
    if (!orderInfo) {
      return;
    }

    if (is_customized && genes) {
      dispatch(orders.setCurrentGeneCount(customizedGeneCount));

      const panelTier = calculatePanelTier(customizedGeneCount, panelTiers);
      if (panelTier) {
        dispatch(orders.setCurrentPanelTierId(panelTier.panel_tier_id));
      }
    } else if (hasOriginalPanelGenes) {
      dispatch(orders.setCurrentGeneCount(baseGeneCount));

      const panelTier = calculatePanelTier(baseGeneCount, panelTiers);
      if (panelTier) {
        dispatch(orders.setCurrentPanelTierId(panelTier.panel_tier_id));
      }
    }
  }, [
    dispatch,
    genes,
    hasOriginalPanelGenes,
    orderInfo,
    customizedGeneCount,
    baseGeneCount,
    panelTiers,
    is_customized,
  ]);

  const showPanelTierAlert = (prevGeneCount, newGeneCount) => {
    const previousPanelTier = calculatePanelTier(prevGeneCount, panelTiers);
    const newPanelTier = calculatePanelTier(newGeneCount, panelTiers);

    // only used for the customers with pricing model 'gene_count'
    if (orderInfo.product.panel_tier_id && previousPanelTier.size !== newPanelTier.size && orderPricingModel === 'gene_count') {
      alert(`Please note that the price of the panel has changed from tier ${previousPanelTier.size} to tier ${newPanelTier.size}`);
      setFieldValue('panel_tier_id', newPanelTier.panel_tier_id);
      dispatch(orders.setCurrentPanelTierId(newPanelTier.panel_tier_id));
    }
  };

  const checkPanelTierChange = (newAddedGenes, newMaskedGenes) => {
    const newGeneCount = getGeneCount(genes, newAddedGenes, newMaskedGenes);

    showPanelTierAlert(customizedGeneCount, newGeneCount);
  };

  const sendGeneRequest = async (requestedGenes) => {
    try {
      await dispatch(orders.sendGeneRequest(requestedGenes));
      setShowMessageModal(true);
      geneRequestHelpers.setValue('');
    } catch (err) {
      // NOP
    }
  };

  return (
    <>
      {failedToLoadPanelGeneLists && (
        <div>
          <p className={`text-danger ${styles.top_error}`}>
            {FAILED_TO_LOAD_REQUIRED_DETAILS}
          </p>
          <button
            type="button"
            className="btn btn-sm"
            onClick={handleRetryLoadDetailsButtonClick}
          >
            Retry
          </button>
        </div>
      )}

      {orderInfo.product.has_flex && (
        <Field
          name="is_customized"
          component={Checkbox}
          label="Customize the panel"
          labelClassName="view_order_modal_label"
          className="modal_checkbox"
          onChange={(form, field, e) => {
            defaultCheckboxOnChange(form, field);

            if (e.target.checked) {
              showPanelTierAlert(baseGeneCount, customizedGeneCount || baseGeneCount);
              loadPanelGenes();
            } else {
              showPanelTierAlert(customizedGeneCount, baseGeneCount);
            }
          }}
        />
      )}

      {values.is_customized && panelGeneLists && (
        <div className={styles.selected_genes_list}>
          <CustomGenesPanel
            orderInfo={orderInfo}
            originalGenes={panelGeneLists.panel.genes}
            addedGenes={values.added_genes}
            maskedGenes={values.masked_genes}
            updateAddedGenes={(newAddedGenes) => {
              setFieldValue('added_genes', newAddedGenes);
              checkPanelTierChange(newAddedGenes, values.masked_genes);
            }}
            updateMaskedGenes={(newMaskedGenes) => {
              setFieldValue('masked_genes', newMaskedGenes);
              checkPanelTierChange(values.added_genes, newMaskedGenes);
            }}
          />

          <Field
            name="custom_clinical_info"
            component={Textarea}
            label={(
              <span>
                Clinical reason for the custom panel *
                <p className="small">Please provide the relevant clinical information that lead to the request for customization of the panel. Please note this information is required.</p>
              </span>
            )}
          />

          <Field
            name="gene_request"
            component={Textarea}
            value={geneRequestMeta.value}
            onChange={geneRequestHelpers.setValue}
            label={(
              <span>
                Gene request
                <p className="small">If you could not find the genes you need in the list, kindly let us know so we can improve accordingly in the future.</p>
              </span>
            )}
          />
          <div className={styles.send_gene_request_button_container}>
            <SecondaryButton
              onClick={() => sendGeneRequest(geneRequestMeta.value)}
              disabled={!geneRequestMeta.value}
              labelText="Send gene request"
            />
          </div>

          <div className={styles.emphasis_save_selection}>
            <Field
              name="gene_collection_name"
              component={LabelInput}
              label="Save selection as"
              hint="Save customized panel for later reuse. Leave empty if you don't want to save."
            />
          </div>
          <MessageModal show={showMessageModal} message="Gene request sent!" hide={() => setShowMessageModal(false)} />
        </div>
      )}
    </>
  );
};

FlexSection.propTypes = {
  orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
};

export default FlexSection;
