import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { constructPatientQuestionsInBillingInfo } from 'utils/patientQuestions';
import PatientHistoryQuestions from 'components/PatientHistoryQuestions';

const ClinicalHistoryFields = ({ orderId, formProps, isPatientBilling }) => {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    try {
      setQuestions(constructPatientQuestionsInBillingInfo(
        formProps?.values?.questions,
        isPatientBilling,
      ));
    } catch (err) {
      setQuestions([]);
    }
  }, [formProps?.values?.questions, isPatientBilling, orderId]);

  return (
    <PatientHistoryQuestions
      questions={questions}
      withICDAndHospitalStatus
    />
  );
};

ClinicalHistoryFields.propTypes = {
  orderId: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    values: {
      questions: PropTypes.shape({}),
    },
  }).isRequired,
  isPatientBilling: PropTypes.bool.isRequired,
};

export default ClinicalHistoryFields;
