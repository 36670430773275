import React from 'react';
import PropTypes from 'prop-types';
import styles from '../Groups.scss';

const AdminPropTypes = {
  groupAdminName: PropTypes.string.isRequired,
  groupAdminEmail: PropTypes.string.isRequired,
};

const AdminCell = ({ cellData }) => (
  <div className={styles.admin_cell}>
    <div>{cellData.groupAdminName}</div>
    <div>{cellData.groupAdminEmail}</div>
  </div>
);

AdminCell.propTypes = {
  cellData: PropTypes.shape(AdminPropTypes).isRequired,
};

export default AdminCell;
