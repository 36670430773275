import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProgressBarComponent } from 'components';
import {
  isExome, isFVT, isMSD, isScreening, isSpark,
} from 'utils/forms';
import { reprintLabId } from 'redux/modules/support';

import { isUsRegion } from 'utils/envUtils';

import {
  AdditionalLimsInfoInput,
  DateInput,
  DefaultAssayTypeInput,
  ExomeSpecialTypeSelect,
  NumOfSamplesInput,
  OnHoldReasonsSelect,
  PrinterSelect,
  ProductionLocationInput,
  RePrintLabId,
  SampleDisposalCategorySelect,
  SampleDisposalDateInput,
  SampleIdInput,
  SampleTypeRadioButton,
  UsProdLocationSelect,
  WorkFlowsSelect,
} from './FormInputs';

import {
  changeOrderStatusFormPropTypes,
  onHoldReasonOptions,
  sampleDisposalCategoryOptions,
  sampleSteps,
  wesSecondaryFindingsConsentOptions,
} from './ChangeOrderStatusConstants';
import styles from './ChangeOrderStatusForm.scss';
import LabelCheckbox from '../LabelCheckbox/LabelCheckbox';
import ExomeFamilyPatients from '../ExomeFamilyPatients/ExomeFamilyPatients';
import PatientInfo from '../PatientInfo/PatientInfo';
import ScreeningDuoPatient from '../ScreeningDuoPatient/ScreeningDuoPatient';

export const shouldDisplayLimsWorkflow = (orderStatus, status) => (
  orderStatus.lims_workflow_enabled && (status === 'sample_in_analysis' || orderStatus.internal_status === 'results_reported')
);

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  reprintLabId,
}, dispatch);

class ChangeOrderStatusForm extends Component {
  static propTypes = changeOrderStatusFormPropTypes;

  state = {
    showOnHoldReasons: this.props.currentStatus === 'sample_on_hold',
    showWorkflows: shouldDisplayLimsWorkflow(this.props.orderStatus, this.props.currentStatus),
  };

  setShowOnHoldReasonsState = (value) => {
    this.setState({
      showOnHoldReasons: value,
    });
  }

  setShowWorkflowsState = (value) => {
    this.setState({
      showWorkflows: value,
    });
  }

  getSampleSteps = () => {
    const {
      orderStatus,
    } = this.props;

    return sampleSteps.filter(({ value }) => orderStatus.allowed_statuses.includes(value));
  }

  changeStep = (status) => {
    const newStatus = this.getSampleSteps().filter((step) => step.value === status)[0];
    this.props.onChangeValue('status', newStatus.value);
    if (newStatus.value === 'sample_on_hold') {
      this.setShowOnHoldReasonsState(true);
    } else if (this.state.showOnHoldReasons) {
      this.setShowOnHoldReasonsState(false);
    }

    if (newStatus.value === 'sample_received') {
      this.props.onChangeValue('print_nucleus_id_label', true);
    } else {
      this.props.onChangeValue('print_nucleus_id_label', false);
    }

    if (shouldDisplayLimsWorkflow(this.props.orderStatus, newStatus.value)) {
      this.setShowWorkflowsState(true);
    } else if (this.state.showWorkflows) {
      this.setShowWorkflowsState(false);
    }
  };

  reprintLabId = async (labId, printerName) => {
    if (!labId) {
      // eslint-disable-next-line no-alert
      alert('No lab id found for the sample. Lab ID sticker not going to be reprinted');
      return;
    }

    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to reprint the lab ID for this order?')) {
      try {
        await this.props.reprintLabId(this.props.orderStatus.order_id, labId, printerName);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(e);
      }
    }
  }

  handleInputValue = (event) => event && event.target && event.target.value;

  handleCheckboxValue = (event) => event && event.target && event.target.checked;

  render() {
    const {
      currentStatus,
      onChangeValue,
      orderStatus,
      printerTypeOptions,
      sampleTypeOptions,
      testTypeOptions,
    } = this.props;

    const { showOnHoldReasons, showWorkflows } = this.state;
    // dob to check existence family member
    const isFamily = (orderStatus && orderStatus.patient_dob_fm1) || false;
    const isExomeSpecialType = isExome(orderStatus.special_type);
    const isFVTSpecialType = isFVT(orderStatus.special_type);
    const isMSDSpecialType = isMSD(orderStatus.special_type);
    const isSparkSpecialType = isSpark(orderStatus.special_type);
    const isScreeningSpecialType = isScreening(orderStatus.special_type);
    const isUSProductionLocation = orderStatus.production_location === 'United States';
    const isSampleInAnalysisStatus = orderStatus.status === 'sample_in_analysis';
    const isLIMSSyncDisabled = isUsRegion() && orderStatus.sample_analysis_region && orderStatus.sample_analysis_region !== 'US';
    const resultsReported = orderStatus && orderStatus.internal_status === 'results_reported';
    const isScreeningDuo = !!orderStatus?.name_individual_2;

    if (isScreeningDuo) {
      orderStatus.primary_sample_type_individual_2 = orderStatus.primary_sample_type_individual_2 || 'dna';
    }
    const sampleTypeLabel = isScreeningDuo ? 'Primary sample type individual 1' : 'Primary sample type';
    const sampleCollectionDateLabel = isScreeningDuo ? 'Sample collection date individual 1' : 'Sample collection date';
    const sampleIdLabel = isScreeningDuo ? 'Customer sample ID individual 1' : 'Customer sample ID';
    const sampleDisposalCategoryLabel = isScreeningDuo ? 'Sample disposal category individual 1' : 'Sample disposal category';

    return (
      <div>
        <ProgressBarComponent
          name="status"
          changeStep={this.changeStep}
          currentStatus={currentStatus}
          disabled={resultsReported}
          steps={this.getSampleSteps()}
        />
        {showOnHoldReasons && (
          <OnHoldReasonsSelect
            handleInputValue={this.handleInputValue}
            onChangeValue={onChangeValue}
            onHoldReasonOptions={onHoldReasonOptions}
            reason={this.props.orderStatus.reason || ''}
            resultsReported={resultsReported}
          />
        )}
        <PatientInfo
          name={(orderStatus && orderStatus.patient_name) || ''}
          ssn={(orderStatus && orderStatus.patient_ssn) || ''}
          dob={(orderStatus && orderStatus.patient_dob) || ''}
          order_id={(orderStatus && orderStatus.order_id) || ''}
          lab_id={(orderStatus && orderStatus.lab_id) || ''}
        />
        <SampleTypeRadioButton
          handleInputValue={this.handleInputValue}
          onChangeValue={onChangeValue}
          resultsReported={resultsReported}
          sampleTypeOptions={sampleTypeOptions}
          value={orderStatus.primary_sample_type}
          label={sampleTypeLabel}
        />
        <DateInput
          onChangeValue={onChangeValue}
          sampleCollectionDate={orderStatus.sample_collection_date}
          label={sampleCollectionDateLabel}
        />
        <SampleIdInput
          customerSampleId={orderStatus.customer_sample_id}
          handleInputValue={this.handleInputValue}
          onChangeValue={onChangeValue}
          label={sampleIdLabel}
        />
        <SampleDisposalCategorySelect
          handleInputValue={this.handleInputValue}
          isSampleInAnalysisStatus={isSampleInAnalysisStatus}
          onChangeValue={onChangeValue}
          isImmediateDisposalProvince={orderStatus.is_immediate_disposal_province}
          isSparkSpecialType={isSparkSpecialType}
          sampleDisposalCategory={orderStatus.sample_disposal_category}
          sampleDisposalCategoryOptions={sampleDisposalCategoryOptions}
          isScreeningSpecialType={isScreeningSpecialType}
          label={sampleDisposalCategoryLabel}
        />
        {isExomeSpecialType && (
          <ExomeSpecialTypeSelect
            handleInputValue={this.handleInputValue}
            isRequired={isSampleInAnalysisStatus && (isMSDSpecialType || isExomeSpecialType)}
            onChangeValue={onChangeValue}
            value={orderStatus.wes_secondary_findings_consent}
            wesSecondaryFindingsConsentOptions={wesSecondaryFindingsConsentOptions}
          />
        )}
        <SampleDisposalDateInput value={orderStatus.sample_disposal_date || 'not yet determined'} />
        {showWorkflows && (
          <WorkFlowsSelect
            handleInputValue={this.handleInputValue}
            onChangeValue={onChangeValue}
            options={orderStatus.lims_workflows_available}
            resultsReported={resultsReported}
            value={orderStatus.lims_workflow}
            individualLabel={isScreeningDuo ? 1 : null}
          />
        )}
        <RePrintLabId
          labId={orderStatus.lab_id}
          printerName={orderStatus.printer_name}
          reprintLabId={this.reprintLabId}
        />
        {isFamily && (
          <ExomeFamilyPatients
            disabled={resultsReported}
            handleInputValue={this.handleInputValue}
            onChange={onChangeValue}
            onClick={this.reprintLabId}
            orderStatus={orderStatus}
            isImmediateDisposalProvince={orderStatus.is_immediate_disposal_province}
            isSparkSpecialType={isSparkSpecialType}
            sampleDisposalCategoryOptions={sampleDisposalCategoryOptions}
            sampleTypeOptions={sampleTypeOptions}
            showWorkflows={showWorkflows}
            wesSecondaryFindingsConsentOptions={wesSecondaryFindingsConsentOptions}
            workflows={orderStatus.lims_workflows_available}
          />
        )}
        {isScreeningDuo && (
          <ScreeningDuoPatient
            disabled={resultsReported}
            handleInputValue={this.handleInputValue}
            onChange={onChangeValue}
            orderStatus={orderStatus}
            reprintLabId={this.reprintLabId}
            resultsReported={resultsReported}
            sampleDisposalCategoryOptions={sampleDisposalCategoryOptions}
            sampleTypeOptions={sampleTypeOptions}
            showWorkflows={showWorkflows}
          />
        )}
        <DefaultAssayTypeInput value={orderStatus.assay_type} />
        <ProductionLocationInput value={orderStatus.production_location} />
        {isUSProductionLocation && (
          <UsProdLocationSelect
            testTypeOptions={testTypeOptions}
            isRequired={isSampleInAnalysisStatus && (
              isExomeSpecialType
              || isFVTSpecialType
              || isMSDSpecialType
            )}
            resultsReported={resultsReported}
            value={orderStatus.test_type}
            onChangeValue={onChangeValue}
            handleInputValue={this.handleInputValue}
          />
        )}
        <AdditionalLimsInfoInput
          handleInputValue={this.handleInputValue}
          onChangeValue={onChangeValue}
          resultsReported={resultsReported}
          value={orderStatus.additional_lims_info}
        />
        <PrinterSelect
          handleInputValue={this.handleInputValue}
          onChangeValue={onChangeValue}
          printerTypeOptions={printerTypeOptions}
          resultsReported={resultsReported}
          value={orderStatus.printer_name}
        />
        <NumOfSamplesInput
          handleInputValue={this.handleInputValue}
          onChangeValue={onChangeValue}
          resultsReported={resultsReported}
          value={orderStatus.number_of_samples}
        />
        <LabelCheckbox
          disabled={resultsReported}
          id="print_nucleus_id_label"
          label="Print NucleusID label"
          onChange={(event) => onChangeValue('print_nucleus_id_label', this.handleCheckboxValue(event))}
          value={orderStatus.print_nucleus_id_label}
          checked={orderStatus.print_nucleus_id_label}
        />
        {
          /* eslint-disable max-len */
        }
        {isLIMSSyncDisabled && (
          <p className={styles.warning}>
            <strong>WARNING: </strong>
            <span>The LIMS sample information for this order is stored in EU LIMS. Syncing to EU LIMS will be disabled for this order. If you change the sample disposal category, please do the same change directly in EU LIMS as well.</span>
          </p>
        )}
        {
          /* eslint-enable max-len */
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOrderStatusForm);
