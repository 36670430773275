import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import * as orders from 'redux/modules/orders';
import { Yup } from 'components/Formik/validation';
import { keepOnlyFields } from 'utils/forms';
import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import OrderUserInfo from 'components/_CreateOrderProcess/OrderUserInfo';
import { userInfoSelector } from 'redux/modules/auth';
import { prepareQuestionsForForm, constructQuestionAnswers } from 'utils/patientQuestions';

import CompletedOrderCustomerInformation from './CompletedOrderCustomerInformation';
import AddVariants, { variantsSchema } from './AddVariants';
import { resetOtherKits } from './OrderKit';
import useOrderInfoWithTargetVariantData from './useOrderInfoWithTargetVariantData';
import CommonSection, { commonSectionValidationSchema, getCommonSectionDefaultValues, getCommonSectionInitialValues } from './CommonSection';

const validationSchema = Yup.object().shape({
  ...commonSectionValidationSchema,
  variants: variantsSchema,
});

const getInitialValues = ({
  userInfo,
  orderInfo,
  deliveryInfo,
  variants,
  sampleTypePanelQuestions,
}) => ({
  ...getCommonSectionInitialValues({ userInfo, orderInfo, deliveryInfo }),
  variants,
  sample_type_questions: prepareQuestionsForForm(
    Object.values(sampleTypePanelQuestions).sort((a, b) => a.order_no - b.order_no),
  ),
});

const TVTOrderTestFormOrderInfo = ({ hideModal }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector(userInfoSelector);
  const orderId = useSelector(orders.orderIdSelector);

  const [initialValues, setInitialValues] = useState({
    ...getCommonSectionDefaultValues(),
    variants: [],
    sample_type_questions: [],
  });
  const [showAddVariantTable, setShowAddVariantTable] = useState(true);

  const {
    loading,
    error,
    orderInfo,
    deliveryInfo,
    variants,
    sampleTypePanelQuestions,
  } = useOrderInfoWithTargetVariantData(orderId);

  useEffect(() => {
    if (!orderInfo || !deliveryInfo) {
      return;
    }

    setInitialValues(getInitialValues({
      userInfo,
      orderInfo,
      deliveryInfo,
      variants,
      sampleTypePanelQuestions,
    }));
  }, [deliveryInfo, orderInfo, userInfo, variants, sampleTypePanelQuestions]);

  const handleSubmit = async (values, actions) => {
    actions.setFieldError('general', null);

    try {
      const payload = keepOnlyFields(['case_phone', 'order_kit'], values);
      payload.order_kit = resetOtherKits(payload.order_kit);

      if (values.sample_type_questions.length) {
        const answers = constructQuestionAnswers(values.sample_type_questions);
        await dispatch(orders.savePanelAnswers(orderInfo.order_id, answers, 3));
      }
      await dispatch(orders.saveOrder(orderInfo.order_id, payload));
      await dispatch(orders.saveTargetedVariants(orderInfo.order_id, values.variants));
      await dispatch(orders.saveResultsDeliveryInfo(orderInfo.order_id, values.delivery_info));
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  };

  const isSupport = userInfo.user_role === 'support';

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {(formProps) => (
        <Form autoComplete="off">
          <PromptIfDirty {...formProps} />

          <EditOrderStep
            {...formProps}
            hideModal={hideModal}
            isFirstStep
            error={error || formProps.errors.general}
            loading={loading}
            submit={() => formProps.submitForm()}
          >
            {orderInfo && (
              <div>
                <OrderUserInfo orderInfo={orderInfo} />

                {isSupport && orderInfo.is_order_completed && (
                  <CompletedOrderCustomerInformation orderInfo={orderInfo} />
                )}

                <AddVariants
                  orderInfo={orderInfo}
                  variants={formProps.values.variants}
                  isSubmitting={formProps.isSubmitting}
                  showAddVariantTable={showAddVariantTable}
                  setShowAddVariantTable={setShowAddVariantTable}
                />

                <CommonSection orderInfo={orderInfo} />
              </div>
            )}
          </EditOrderStep>
        </Form>
      )}
    </Formik>
  );
};

TVTOrderTestFormOrderInfo.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default TVTOrderTestFormOrderInfo;
