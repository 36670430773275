import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './Checkbox';

/**
 * Allows to reuse Checkbox component without Formik context.
 */
const CheckboxControlled = ({
  name,
  label,
  value,
  labelClassName,
  className,
  hint,
  readOnly,
}) => (
  <Checkbox
    field={{
      name,
      value,
    }}
    form={{
      setFieldValue: () => {},
    }}
    onChange={() => {}}
    label={label}
    labelClassName={labelClassName}
    className={className}
    hint={hint}
    disabled={readOnly}
  />
);

CheckboxControlled.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  hint: PropTypes.string,
  readOnly: PropTypes.bool,
};

CheckboxControlled.defaultProps = {
  name: null,
  labelClassName: null,
  className: null,
  hint: null,
  readOnly: false,
};

export default CheckboxControlled;
