import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class LabelRadioDisplay extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    options: PropTypes.array,
    hint: PropTypes.string,
    freeTextAnswer: PropTypes.string,
  };
  render() {
    const {disabled, freeTextAnswer, label, hint, labelSize, inputSize, options} = this.props;

    return (
      <div className="form-group row">
        <label className={'col-md-' + labelSize + ' col-form-label'}>
          {label}
          <p className="small">{hint}</p>
        </label>
        <div className={'col-md-' + inputSize + ' no-padding-left'}>
          {options && options.map(option =>
            <div className="form-check radio" key={option.question_option_id}>
              <input disabled={disabled} id={option.question_option_id} className="form-check-input" type="radio" checked={option.selected}/>
              <label htmlFor={option.question_option_id} className="form-check-label">
                {option.question_option}
              </label>
            </div>
          )}
          {freeTextAnswer &&
            <textarea className="form-control additional-textarea" value={freeTextAnswer} disabled></textarea>
          }
        </div>
      </div>
    );
  }
}
