import React from 'react';
import PropTypes from 'prop-types';

import styles from './OrderStatusModal.scss';

const ErrorChangeStatus = ({ error }) => (
  <div className={styles.modal_error_message}>
    <i
      className="glyphicon glyphicon-exclamation-sign"
      aria-hidden="true"
    />
    <span>{error}</span>
  </div>
);

ErrorChangeStatus.propTypes = {
  error: PropTypes.string,
};

ErrorChangeStatus.defaultProps = {
  error: null,
};

export default ErrorChangeStatus;
