import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import WARNING_CONDITIONS from 'helpers/constants/progressBarConstants';

import styles from './ProgressBarComponent.scss';

const showWarning = ({ currentStatus, nextStep }) => {
  if (WARNING_CONDITIONS.some(
    ({ fromStep, toStep }) => fromStep === currentStatus
      && toStep === nextStep,
  )) {
    return true;
  }
  return false;
};

const ProgressBarComponent = (props) => {
  const {
    changeStep,
    currentStatus,
    disabled,
    steps,
  } = props;

  const [active, setActive] = useState(currentStatus);
  const setStep = (value) => {
    if (value !== active && !disabled) {
      const toShowWarning = showWarning({
        currentStatus, nextStep: value,
      });
      const confirmOrderNotReceived = toShowWarning
        ? window.confirm('This order has not been received - are you sure you want to proceed?')
        : true;
      if (!confirmOrderNotReceived) {
        return;
      }

      if (currentStatus === 'sample_in_analysis' && value === 'sample_received') {
        window.alert('Status change from "sample in analysis" to "sample received" is not allowed');
        return;
      }

      setActive(value);
      changeStep(value);
    }
  };
  const width = 100.0 / steps.length;

  return (
    <ul className={`${styles.view_test_progress_bar} list-unstyled`}>
      {steps.map(({ description, info, value }) => (
        /*
          eslint-disable
          jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-noninteractive-element-interactions
        */
        <li
          key={value}
          className={classNames('text-center', {
            [styles.active]: active === value,
            'progress-bar-active-step': active === value,
          })}
          onClick={() => setStep(value)}
          style={{
            width: `${width}%`,
          }}
        >
          <div>{description}</div>
          {info && <div className={styles.info_text}>{info}</div>}
        </li>
      ))}
    </ul>
  );
};

ProgressBarComponent.propTypes = {
  changeStep: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    info: PropTypes.string,
  })).isRequired,

};

ProgressBarComponent.defaultProps = {
  disabled: false,
};

export default ProgressBarComponent;
