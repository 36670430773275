import React from 'react';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';
import PrintConsent from './PrintConsent';

const ConsentSparkFormV2 = () => (
  <div className="col-md-8 offset-md-2">
    <PrintConsent />
    <Field
      name="consentSigned"
      id="consentSigned"
      component={Checkbox}
      label="The patient has signed the Sponsored Genetic Testing Program Informed
        Consent form and it will be sent to Blueprint Genetics"
      labelClassName="consentSigned"
      className="text-left"
    />
  </div>
);

export default ConsentSparkFormV2;
