import React from 'react';
import PropTypes from 'prop-types';

import { GenePropTypes } from 'types/OrderInfoPropTypes';

import styles from './ViewOrderInfo.scss';

const GeneList = ({ genes }) => (
  <ul
    className={`list-unstyled list-inline ${styles.included_gene}`}
  >
    {genes && genes.map((gene) => (
      <li key={gene.id} className={`list-inline-item ${styles.gene_pill_item}`}>
        {gene.hgnc_symbol}
        {gene.overlaps_segmental_duplication && (
          <span className="text-pink">*</span>
        )}
        {gene.has_suboptimal_coverage && (
          <span className="text-pink">#</span>
        )}
      </li>
    ))}
  </ul>
);

GeneList.propTypes = {
  genes: PropTypes.arrayOf(PropTypes.shape(GenePropTypes)).isRequired,
};

export default GeneList;
