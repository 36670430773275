import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Spinner } from 'components';
import moment from 'moment';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  isVST,
  isFVT,
  isScreening,
  isScreeningDuo,
} from 'utils/forms';
import { getFreeFVTStatus } from 'utils/fvtOrder';
import isVusClarificationServiceOrder from 'utils/vusClarificationService';
import envUtils, { isUsRegion } from 'utils/envUtils';
import { VUS_CLARIFICATION_SERVICE_PRODUCT_NAME } from 'helpers/constants/testTypeConstants';
import Isvg from 'react-inlinesvg';

import styles from './OrderList.scss';
import salesforceLogo from './salesforce_logo.svg';

class OrderList extends Component {
  /* eslint-disable react/require-default-props, react/forbid-prop-types */
  static propTypes = {
    order: PropTypes.object,
    visibleColumns: PropTypes.object,
    openInsuranceCaseDetailModal: PropTypes.func.isRequired,
    openInsuranceCaseEditModal: PropTypes.func.isRequired,
    openOrderStatusModal: PropTypes.func.isRequired,
    getReportFile: PropTypes.func,
    downloadingReportId: PropTypes.string,
    gettingReportFile: PropTypes.bool,
    setDownloadingReportId: PropTypes.func,
    userInfo: PropTypes.object,
    archiveOrder: PropTypes.func,
    archiving: PropTypes.bool,
    archivingOrderId: PropTypes.string,
    setArchivingOrderId: PropTypes.func,
    isSupportView: PropTypes.bool,
    openReflexModal: PropTypes.func.isRequired,
    onOrderFVTLinkClick: PropTypes.func.isRequired,
    showFVTLink: PropTypes.bool.isRequired,
  };
  /* eslint-enable react/require-default-props, react/forbid-prop-types */

  state = {
    fvtError: null,
  };

  // print the report
  getReportFile = (event, orderId, reportLabel) => {
    event.preventDefault();
    this.props.getReportFile(orderId, reportLabel);
    this.props.setDownloadingReportId(orderId);
  };

  // archive the order
  archiveOrder = (event, orderId) => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure to archive the order?')) {
      event.preventDefault();
      this.props.archiveOrder({
        order_id: orderId,
      });
      this.props.setArchivingOrderId(orderId);
    }
  };

  unarchiveOrder = (event, orderId) => {
    event.preventDefault();
    this.props.archiveOrder({
      order_id: orderId,
      is_archived: false,
    });
  };

  openInsuranceModal = (order, showDraftModal) => {
    if (showDraftModal) {
      this.props.openInsuranceCaseEditModal(order);
    } else {
      this.props.openInsuranceCaseDetailModal(order);
    }
  };

  openOrderStatusModal = (order) => {
    this.props.openOrderStatusModal(order);
  };

  // TODO: Consider moving this to backend. The JSON key
  // order_status_on_hold_reason could already return the expected
  // value to be rendered (endpoints get_orders and get_admin_orders)
  parseOnHoldReason = (value) => {
    if (value === 'insurance') {
      return 'Pending Insurance';
    }
    if (value === 'payment_approval') {
      return 'Pending Payment Approval (no email)';
    }
    if (value === 'missing_requisition_info') {
      return 'Unclear Requisition Information';
    }
    if (value === 'missing_sample_id_info') {
      return 'Unclear Sample ID Information';
    }
    if (value === 'other') {
      return this.props.isSupportView ? 'Other' : '';
    }
    if (value === 'pending_patient_payment') {
      return 'Pending Patient Payment';
    }
    if (value === 'blank') {
      return this.props.isSupportView ? 'Internal (no email)' : '';
    }
    if (value === 'waiting_for_samples') {
      return 'Waiting for sample(s)';
    }

    return value;
  };

  openReflexModal = (event) => {
    event.preventDefault();
    this.props.openReflexModal(this.props.order.order_id);
  };

  // eslint-disable-next-line no-prototype-builtins
  isColumnVisible = (column) => this.props.visibleColumns.hasOwnProperty(column);

  isReportVisible = (reportType) => this.props.order.available_reports.indexOf(reportType) >= 0;

  getProductCode = (order) => `, ${order.product_code}`;

  renderTestNameAndProductCode = (order) => {
    let testName = order.panel_name;
    let productCode = order.product_code ? this.getProductCode(order) : null;
    if (isVusClarificationServiceOrder(order)) {
      testName = VUS_CLARIFICATION_SERVICE_PRODUCT_NAME;
      productCode = null;
    }

    return (
      <>
        <span className="name">{testName}</span>
        {productCode && <span className="code">{productCode}</span>}
      </>
    );
  }

  handleOrderFVTLinkClick = async (event) => {
    const { order, onOrderFVTLinkClick } = this.props;

    event.preventDefault();

    try {
      await onOrderFVTLinkClick(order);
    } catch (e) {
      this.setState({ fvtError: e.message });
    }
  };

  renderFreeFVTOrderStatus = (order) => {
    const freeFVTStatus = getFreeFVTStatus(order);

    return (
      <div id="free-fvt-status" className={styles.free_fvt_status}>
        <span className="success">{freeFVTStatus}</span>
      </div>
    );
  };

  getReportLinkText = (order) => (isVusClarificationServiceOrder(order) ? 'Summary' : 'Report');

  hasPHIFreeSummary = (order) => {
    let text = '-';

    if (order.has_phi_free_summary) {
      text = 'Yes';
    } else if (order.needs_phi_free_summary) {
      text = 'No';
    }

    return text;
  }

  render() {
    const {
      order,
      gettingReportFile,
      downloadingReportId,
      userInfo,
      archiving,
      archivingOrderId,
      isSupportView,
      showFVTLink,
    } = this.props; // eslint-disable-line no-shadow

    const reportLabel = isScreeningDuo(order.product_code) ? 'individual_1' : 'index_patient';
    const newUserOrder = isSupportView && order.order_user_is_new;
    const hideEditLinkForUSUser = !isSupportView && isUsRegion();

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <tr
        id={`orderRow${order.order_id}`}
        className={classNames({
          [styles.newUserOrder]: newUserOrder,
          'new-user-order': newUserOrder,
          [styles.highlighted]: order.is_highlighted,
          highlighted: order.is_highlighted,
        })}
      >
        {/* Date */}
        <td className={styles.date}>
          {order.date_str}
          <span className={`${styles.secondary} small`}>
            {order.day_diff_str}
          </span>
        </td>

        {/* Order ID */}
        <td className={styles.orderId}>
          <Link
            to={{
              pathname: `/orders/${order.order_id}/view`,
              state: {
                // Allow going back in history from the next page
                canGoBack: true,
              },
            }}
            id="view"
          >
            {order.order_id}
          </Link>
          {order.salesforce_order_id && (
            <Link
              to={{ pathname: envUtils.getSalesforceOrderLink(order) }}
              target="_blank"
              id="salesforce-order-link"
            >
              <Isvg src={salesforceLogo}>
                Can not handle svg!
              </Isvg>
            </Link>
          )}
        </td>

        {/* Lab ID */}
        {this.isColumnVisible('lab-id') && <td>{order.lab_id}</td>}

        {/* Order user */}
        {this.isColumnVisible('order-user') && (
          <td>
            <span>{order.order_user_name}</span>
            <br />
            <span className="small">{order.order_user_email}</span>
            <br />
            <span className="small">
              {order.hospital_name || userInfo.hospital_name}
            </span>
            <br />
            {order.ordered_by_user_email && (
              <span className="small text-light-gray">{`Ordered by ${order.ordered_by_user_email}`}</span>
            )}
          </td>
        )}

        {/* Analysis started */}
        {this.isColumnVisible('analysis-started') && (
          <td>{order.analysis_started}</td>
        )}

        {/* Patient name */}
        {this.isColumnVisible('patient') && (
          <td className={styles.patientName}>
            {order.patient_name}
            <br />
            {(order.fm_1_name || order.fm_2_name || order.individual_2_name) && (
              <div>
                <span className="small">{order.fm_1_name ?? order.individual_2_name}</span>
                <br />
                <span className="small">{order.fm_2_name}</span>
              </div>
            )}
          </td>
        )}

        {/* Patient dob */}
        {this.isColumnVisible('date-of-birth') && (
          <td className={styles.patientDob}>
            {order.patient_dob}
            <br />
            {(order.fm_1_dob || order.fm_2_dob || order.individual_2_dob) && (
              <div>
                <span className="small">{order.fm_1_dob ?? order.individual_2_dob}</span>
                <br />
                <span className="small">{order.fm_2_dob}</span>
              </div>
            )}
          </td>
        )}

        {/* Insurance company */}
        {this.isColumnVisible('insurance-company') && (
          <td>{order.insurance_company || 'N/A'}</td>
        )}

        {/* Test */}
        <td className={styles.panelName} id="panel">
          {this.renderTestNameAndProductCode(order)}
          {isSupportView
            && isFVT(order.special_type)
            && !isEmpty(getFreeFVTStatus(order))
            && this.renderFreeFVTOrderStatus(order)}
          {!isVST(order.special_type) && order.analysis_type && (
            <span className={styles.analysis_type}>{order.analysis_type}</span>
          )}
        </td>

        {/* Insurance status */}
        {this.isColumnVisible('insurance-status') && (
          <td className="text-center">
            <span className={styles[order.status]}>
              {order.insurance_status_str}
            </span>
          </td>
        )}

        {/* Last updated */}
        {this.isColumnVisible('last-updated') && (
          <td>{order.last_updated_str}</td>
        )}

        {/* Owner */}
        {this.isColumnVisible('owner') && <td>{order.owner_str}</td>}

        {/* Order status */}
        {this.isColumnVisible('status') && (
          <td className={`${styles.statusStr} text-center`} id="status">
            <span className={styles[order.status]}>{order.status_str}</span>
            {(() => {
              switch (order.status) {
                case 'draft':
                case 'waiting_for_sample':
                case 'sample_received':
                case 'sample_in_analysis':
                  return (
                    <div className={`${styles.progressBar} progress`}>
                      <div
                        className={`${styles[`progressBar_${order.status}`]
                        } progress-bar`}
                      />
                    </div>
                  );

                case 'completed_successfully':
                  return (
                    <div className={styles.completed_icon}>
                      <i
                        className="glyphicon glyphicon-lg glyphicon-check"
                        aria-hidden="true"
                      />
                    </div>
                  );

                case 'sample_on_hold':
                  return (
                    <div className={styles.on_hold_icon}>
                      <i
                        className="glyphicon glyphicon-exclamation-sign"
                        aria-hidden="true"
                      />
                      {order.order_status_on_hold_reason && (
                        <span>
                          {` ${this.parseOnHoldReason(
                            order.order_status_on_hold_reason,
                          )}`}
                        </span>
                      )}
                    </div>
                  );

                default:
                  return null;
              }
            })()}
          </td>
        )}

        {/* Fax status */}
        {this.isColumnVisible('fax-status') && (
          <td>
            <span>{order.fax_status}</span>
            <br />
            <span className="small">
              {order.fax_status !== 'N/A'
                ? moment
                  .unix(order.fax_status_updated)
                  .format('YYYY-MM-DD HH:mm:ss')
                : ''}
            </span>
          </td>
        )}

        {/* Checked */}
        {this.isColumnVisible('is-checked') && (
          <td
            className={classNames({
              [styles.orderNotchecked]:
                order.is_checked === null || !order.is_checked,
            })}
          >
            {order.is_checked !== null && (order.is_checked ? 'Yes' : 'No')}
          </td>
        )}

        {/* has-phi-free-summary */}
        {this.isColumnVisible('has-phi-free-summary') && (
          <td
            className={classNames({
              [styles.orderNotchecked]:
                order.has_phi_free_summary === null
                || (!order.has_phi_free_summary && order.needs_phi_free_summary),
            })}
          >
            {order.has_phi_free_summary !== null && this.hasPHIFreeSummary(order)}
          </td>
        )}

        {/* Options */}
        <td>
          {order.is_reflex_possible && (
            <a
              className={`${styles.optionButton} btn btn-link`}
              href="#"
              onClick={this.openReflexModal}
            >
              Reflex
            </a>
          )}

          {order.show_view_link && (
            <Link
              className={`${styles.optionButton} btn btn-link`}
              to={{
                pathname: `/orders/${order.order_id}/view`,
                state: {
                  // Allow going back in history from the next page
                  canGoBack: true,
                },
              }}
            >
              View
            </Link>
          )}

          {(order.show_edit_link && !hideEditLinkForUSUser) && (
            <Link
              className={`${styles.optionButton} btn btn-link`}
              id="edit"
              to={{
                pathname: `/orders/${order.order_id}/edit`,
                state: {
                  // Allow going back in history from the next page
                  canGoBack: true,
                },
              }}
            >
              Edit
            </Link>
          )}

          {order.show_view_insurance_link && (
            <a
              className={`${styles.optionButton} btn btn-link`}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                this.openInsuranceModal(order, false);
              }}
            >
              View
            </a>
          )}

          {order.show_edit_insurance_link && (
            <a
              className={`${styles.optionButton} btn btn-link`}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                this.openInsuranceModal(order, true);
              }}
            >
              Edit
            </a>
          )}

          {order.show_change_status_link && (
            <a
              className={`${styles.optionButton} btn btn-link text-nowrap`}
              href="#"
              onClick={(event) => {
                event.preventDefault();
                this.openOrderStatusModal(order);
              }}
            >
              Change status
            </a>
          )}

          {this.isReportVisible(reportLabel) && (

            <div className={styles.inline_btn_wrapper}>
              {!isScreening(order.special_type) && (
                <Link
                  className={`${styles.optionButton} btn btn-link`}
                  to={`/orders/report/${order.order_id}`}
                >
                  {this.getReportLinkText(order)}
                </Link>
              )}

              {!isVusClarificationServiceOrder(this.props.order) && (
                <a
                  href="#"
                  className={`${styles.optionButton} btn btn-link`}
                  onClick={(event) => {
                    event.preventDefault();
                    this.getReportFile(event, order.order_id, reportLabel);
                  }}
                >
                  Print
                </a>
              )}
              {gettingReportFile && downloadingReportId === order.order_id && (
                <div className={styles.spinner_inline}>
                  <Spinner />
                </div>
              )}
            </div>
          )}

          {showFVTLink && (
            <a
              className={`${styles.optionButton} btn btn-link`}
              href="#"
              data-id="fvt-link"
              onClick={this.handleOrderFVTLinkClick}
            >
              FVT
              {/* Show error at proper place */}
              {this.state.fvtError && (
                <div>
                  <span className="text-danger">
                    Error in creating FVT order
                  </span>
                </div>
              )}
            </a>
          )}

          {order.show_archive_link && (
            <div className={styles.inline_btn_wrapper}>
              <a
                href="#"
                className={`${styles.optionButton} btn btn-link`}
                onClick={(event) => {
                  if (order.is_archived) {
                    this.unarchiveOrder(event, order.order_id);
                  } else {
                    this.archiveOrder(event, order.order_id);
                  }
                }}
              >
                {order.is_archived ? 'Unarchive' : 'Archive'}
              </a>
              {archiving && archivingOrderId === order.order_id && (
                <div className={styles.spinner_inline}>
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </td>
      </tr>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

export default withRouter(OrderList);
