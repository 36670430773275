import React from 'react';
import PropTypes from 'prop-types';

const GenesList = ({
  label,
  genes,
}) => (
  <p>
    <b>
      {`${label}: `}
    </b>

    {genes.map((gene, index) => (
      <span key={gene.id}>
        <span>
          {gene.hgnc_symbol}

          {gene.overlaps_segmental_duplication && (
            <span className="text-pink">*</span>
          )}

          {gene.has_suboptimal_coverage && (
            <span className="text-pink">#</span>
          )}
        </span>

        {index !== genes.length - 1 && (
          <span>, </span>
        )}
      </span>
    ))}
  </p>
);

GenesList.propTypes = {
  label: PropTypes.string.isRequired,
  genes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    hgnc_symbol: PropTypes.string,
    overlaps_segmental_duplication: PropTypes.bool,
    has_suboptimal_coverage: PropTypes.bool,
  })).isRequired,
};

export default GenesList;
