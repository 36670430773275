import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { getProfileInfo } from 'redux/modules/auth';
import { Spinner } from 'components';

import styles from './AccountSettingsView.scss';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  gettingProfileInfo: state.auth.gettingProfileInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
}, dispatch);

class AccountSettingsView extends Component {
  static propTypes = {
    userInfo: PropTypes.shape({
      notification_opt_out_new_order: PropTypes.bool,
      notification_opt_out_test_shared: PropTypes.bool,
      notification_opt_out_order_on_hold: PropTypes.bool,
      notification_opt_out_sample_in_analysis: PropTypes.bool,
      notification_opt_out_results_ready: PropTypes.bool,
      notification_opt_out_follow_up_reports: PropTypes.bool,
      notification_opt_out_sample_received: PropTypes.bool,
      notification_opt_out_all: PropTypes.bool,
      user_email: PropTypes.string,
      country: PropTypes.string,
      hospital_name: PropTypes.string,
      user_name: PropTypes.string,
      title: PropTypes.string,
      department_name: PropTypes.string,
      phone: PropTypes.string,
      two_factor_authentication_activated: PropTypes.bool,
    }),
    gettingProfileInfo: PropTypes.bool,
    getProfileInfo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userInfo: null,
    gettingProfileInfo: null,
  };

  componentDidMount() {
    this.props.getProfileInfo();
  }

  render() {
    const { userInfo, gettingProfileInfo } = this.props;
    let noNotificationEmails = false;
    if (userInfo) {
      noNotificationEmails = (
        (userInfo.notification_opt_out_new_order
          && userInfo.notification_opt_out_test_shared
          && userInfo.notification_opt_out_order_on_hold
          && userInfo.notification_opt_out_sample_in_analysis
          && userInfo.notification_opt_out_results_ready
          && userInfo.notification_opt_out_follow_up_reports
          && userInfo.notification_opt_out_sample_received) || userInfo.notification_opt_out_all
      );
    }

    return (
      <div className={`${styles.accountSettings} container`}>
        <div className="row">
          <div className={`${styles.header} col-md-12`}>
            <Helmet title="Account settings" />
            <h1>Account settings</h1>
          </div>

          <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
            {gettingProfileInfo && (
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            )}

            {!gettingProfileInfo && userInfo && (
              <div>
                <div className={styles.acc_info_wrapper}>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Email</label>
                    <span className={styles.profile_info}>{userInfo.user_email}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Country</label>
                    <span className={styles.profile_info}>{userInfo.country ? userInfo.country : 'N/A'}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Hospital name</label>
                    <span className={styles.profile_info}>{userInfo.hospital_name}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Name</label>
                    <span className={styles.profile_info}>{userInfo.user_name}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Title</label>
                    <span className={styles.profile_info}>{userInfo.title ? userInfo.title : 'N/A'}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Department name</label>
                    <span className={styles.profile_info}>{userInfo.department_name ? userInfo.department_name : 'N/A'}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Phone</label>
                    <span className={styles.profile_info}>{userInfo.phone ? userInfo.phone : 'N/A'}</span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Password</label>
                    <span className={styles.profile_info}>
                      *********
                      <Link className={`${styles.btn_change_pw} btn btn-primary`} to="/account-settings/change-password">Change password</Link>
                    </span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>Two-factor authentication</label>
                    <span className={styles.profile_info}>
                      {userInfo.two_factor_authentication_activated ? 'Enabled' : 'Disabled'}
                      <Link
                        className={`${styles.btn_change_pw} btn btn-primary`}
                        to={userInfo.two_factor_authentication_activated ? '/account-settings/disable-2fa' : '/account-settings/enable-2fa'}
                      >
                        {userInfo.two_factor_authentication_activated ? 'Disable' : 'Enable'}
                      </Link>
                    </span>
                  </div>
                  <div className={styles.profile_row}>
                    <label className={styles.profile_label}>
                      Email notification settings
                    </label>
                    <div className={styles.profile_info}>
                      {noNotificationEmails
                        && <p>All notification emails are turned off.</p>}

                      {!noNotificationEmails
                        && (
                        <div>
                          <p>Notification emails are turned on for:</p>
                          <ul>
                            {!userInfo.notification_opt_out_new_order
                              && <li>New order</li>}
                            {!userInfo.notification_opt_out_test_shared
                              && <li>Test shared with you</li>}
                            {!userInfo.notification_opt_out_order_on_hold
                              && <li>Order on hold</li>}
                            {!userInfo.notification_opt_out_sample_in_analysis
                              && <li>Sample in analysis</li>}
                            {!userInfo.notification_opt_out_results_ready
                                && <li>Results ready</li>}
                            {!userInfo.notification_opt_out_follow_up_reports
                                && <li>Follow up reports</li>}
                            {!userInfo.notification_opt_out_sample_received
                                && <li>Sample received</li>}
                          </ul>
                        </div>
                        )}
                      <Link className={`${styles.btn_change_email_settings} btn btn-primary`} to="/account-settings/email-notification-settings">Change notification settings</Link>
                    </div>
                  </div>
                </div>
                <Link className="btn btn-primary" to="/account-settings/edit-profile">Edit profile</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettingsView);
