const checkRole = (userInfo, type) => {
  if (!userInfo) {
    return false;
  }

  const role = userInfo.user_role;

  switch (type) {
    case 'isSupportUser':
      return role === 'support' || role === 'geneticist';
    case 'isHospitalAdminUser':
      return role === 'hospital_admin' && !userInfo.is_superuser;
    case 'isClinicalConsultant':
      return role === 'clinical_consultant';
    case 'isOrderAgent':
      return role === 'order_agent';
    case 'isGeneticist':
      return role === 'geneticist';
    case 'isSuperUser':
      return userInfo.is_superuser === true;
    case 'isCustomer':
      return (
        role === 'physician'
        || (role === 'hospital_admin' && !userInfo.is_superuser)
        || role === 'order_agent'
      );
    default:
      return false;
  }
};

export const isSupportUser = (userInfo) => checkRole(userInfo, 'isSupportUser');

export const isHospitalAdminUser = (userInfo) => checkRole(userInfo, 'isHospitalAdminUser');

export const isClinicalConsultant = (userInfo) => checkRole(userInfo, 'isClinicalConsultant');

export const isCustomer = (userInfo) => checkRole(userInfo, 'isCustomer');

export const isOrderAgent = (userInfo) => checkRole(userInfo, 'isOrderAgent');

export const isSuperUser = (userInfo) => checkRole(userInfo, 'isSuperUser');

export const isGeneticist = (userInfo) => checkRole(userInfo, 'isGeneticist');

export const canPlaceOrderForOneself = (userInfo) => (
  isOrderAgent(userInfo)
    ? userInfo.is_authorized_provider
    : true
);

export const canPlaceOrderForAliasOrForOneself = (userInfo, aliasUserId) => {
  const isOtherAliasUserSelected = aliasUserId !== '' && aliasUserId !== 'current-user';

  if (isOtherAliasUserSelected) {
    return true;
  }

  return canPlaceOrderForOneself(userInfo);
};

export const shouldShowFVTLink = (productOperations, userInfo, aliasUserId) => {
  if (!productOperations || !userInfo) {
    return false;
  }

  // Currently, geneticists should be treated the same way as support
  const isSupport = isSupportUser(userInfo);
  const canCustomerPlaceForAlias = isCustomer(userInfo)
    && canPlaceOrderForAliasOrForOneself(userInfo, aliasUserId);

  if (canCustomerPlaceForAlias || isSupport || isSuperUser(userInfo)) {
    return productOperations.some((pm) => pm.operation === 'copy_index_order');
  }

  return false;
};

export const isInternalRole = (role) => ['support', 'geneticist', 'clinical_consultant']
  .includes(role);
