import React from 'react';
import { NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

const AdminDropdown = () => (
  <NavDropdown title="Admin" id="admin-dropdown">
    <NavDropdown.Item as={NavLink} to="/order_transfers">Order transfers</NavDropdown.Item>
    <NavDropdown.Item as={NavLink} to="/order_sharing">Order sharing</NavDropdown.Item>
    <NavDropdown.Item as={NavLink} to="/batch_order">Batch order</NavDropdown.Item>
    <NavDropdown.Divider />
    <NavDropdown.Item as={NavLink} to="/other_accounts_settings">Other accounts settings</NavDropdown.Item>
    <NavDropdown.Divider />
    <NavDropdown.Item as={NavLink} to="/users">Users</NavDropdown.Item>
    <NavDropdown.Item as={NavLink} to="/hospitals">Hospitals</NavDropdown.Item>
    <NavDropdown.Item as={NavLink} to="/groups">Groups</NavDropdown.Item>
    <NavDropdown.Divider />
    <NavDropdown.Item as={NavLink} to="/questions">Questions</NavDropdown.Item>
  </NavDropdown>
);

export default AdminDropdown;
