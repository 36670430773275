import React, { useMemo } from 'react';

import PrimaryButton from 'components/StyleComponents/Button/PrimaryButton';
import PropTypes from 'prop-types';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';

import { INPUT_FIELD_LIMIT } from 'helpers/constants/inputLimitConstants';
import styles from './PhiFreeSummaryFloatingTextArea.scss';

const PhiFreeSummaryFloatingTextArea = (props) => {
  const {
    phiFreeSummary, onSavePhiFreeSummary, originalPhiFreeSummary, onChangePhiFreeSummary,
  } = props;

  const isButtonsDisabled = () => {
    if (props.readOnly) {
      return true;
    }
    if (originalPhiFreeSummary === phiFreeSummary) {
      return true;
    }
    if (originalPhiFreeSummary == null && phiFreeSummary === '') {
      return true;
    }
    return false;
  };

  const onDiscardChanges = () => {
    onChangePhiFreeSummary(originalPhiFreeSummary || '');
  };

  const onSummaryChange = (e) => {
    onChangePhiFreeSummary(e.target.value);
  };

  const phiFreeSummaryCounter = useMemo(
    () => (phiFreeSummary ? phiFreeSummary.length : 0), [phiFreeSummary],
  );

  return (
    <div id="phi-free-summary-modal" className={styles.container}>
      <div className={styles.innerContainer}>
        <h5>PHI-free summary</h5>
        <textarea
          readOnly={props.readOnly}
          disabled={props.readOnly}
          name="clinical-summary"
          id="clinical-summary-textarea"
          value={phiFreeSummary}
          onChange={onSummaryChange}
          placeholder="Type here all the PHI-free information utilising the data from open text fields and attachments below"
          rows={5}
          maxLength={INPUT_FIELD_LIMIT.TEXT}
        />
        <div className={styles.summaryInfo}>
          <div className={styles.note}>
            NOTE: Once the PHI-free summary has been written and saved,
            the case is ready for interpretation in Clint.
          </div>
          <div className={styles.summaryCounter}>
            {phiFreeSummaryCounter}
            /
            {INPUT_FIELD_LIMIT.TEXT}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <SecondaryButton type="button" labelText="Discard changes" disabled={isButtonsDisabled()} onClick={onDiscardChanges} id="phi-free-modal-discard-button" />
          <PrimaryButton type="button" labelText="Save summary" disabled={isButtonsDisabled()} onClick={onSavePhiFreeSummary} id="phi-free-modal-save-button" />
        </div>
      </div>
    </div>
  );
};

PhiFreeSummaryFloatingTextArea.propTypes = {
  phiFreeSummary: PropTypes.string,
  originalPhiFreeSummary: PropTypes.string,
  onChangePhiFreeSummary: PropTypes.func,
  onSavePhiFreeSummary: PropTypes.func,
  readOnly: PropTypes.bool,
};

PhiFreeSummaryFloatingTextArea.defaultProps = {
  phiFreeSummary: '',
  originalPhiFreeSummary: '',
  onChangePhiFreeSummary: () => {},
  onSavePhiFreeSummary: () => {},
  readOnly: true,
};

export default PhiFreeSummaryFloatingTextArea;
