import React from 'react';
import PropTypes from 'prop-types';

import LogPropTypes from 'types/LogPropTypes';
import styles from './OrderLogs.scss';

const OrderLogs = ({ logs }) => (
  <table className="table">
    <thead>
      <tr className={styles.report_heading_row}>
        <th>Date</th>
        <th>User</th>
        <th>Type</th>
        <th>Field</th>
        <th>Old content</th>
        <th>New content</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(logs).map(([key, entry]) => (
        <tr key={key}>
          <td>{entry.date_str}</td>
          <td>{entry.email}</td>
          <td>{entry.type_str}</td>
          <td>{entry.field}</td>
          {entry.is_safe_html
          // eslint-disable-next-line react/no-danger
            ? <td dangerouslySetInnerHTML={{ __html: entry.old_content }} />
            : <td>{entry.old_content}</td>}
          {entry.is_safe_html
          // eslint-disable-next-line react/no-danger
            ? <td dangerouslySetInnerHTML={{ __html: entry.new_content }} />
            : <td>{entry.new_content}</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

OrderLogs.propTypes = {
  logs: PropTypes.objectOf(PropTypes.shape(LogPropTypes)).isRequired,
};

export default OrderLogs;
