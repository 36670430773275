import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ children }) => (
  <p className="text-danger">
    {children}
  </p>
);

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorMessage;
