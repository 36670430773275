import React from 'react';
import PropTypes from 'prop-types';

const RadioButtonComponent = ({
  label,
  labelSize,
  inputSize,
  options,
  value,
  type,
  ...props
}) => {
  let optionsArr = null;
  if (options) {
    optionsArr = Object.keys(options).map((key) => options[key]);
  }

  return (
    <div className="form-group row">
      <label className={`col-md-${labelSize} col-form-label`}>
        {label}
      </label>
      <div className={`col-md-${inputSize} no-padding-left`}>
        {optionsArr && optionsArr.map((option) => (
          <div
            className="form-check radio"
            key={`${props.name}_${option.question_option_id}`}
          >
            <input
              onChange={props.onChange}
              id={`${props.name}_${option.question_option_id}`}
              className="form-check-input"
              type={type}
              checked={value === option.question_option_id}
              {...props}
              value={String(option.question_option_id)}
              disabled={props.disabled}
            />

            <label htmlFor={`${props.name}_${option.question_option_id}`} className="form-check-label">
              {option.question_option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

RadioButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  labelSize: PropTypes.string.isRequired,
  inputSize: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

RadioButtonComponent.defaultProps = {
  value: null,
  options: null,
};

export default RadioButtonComponent;
