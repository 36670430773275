import React from 'react';
import PropTypes from 'prop-types';

const id = (field, value) => `${field.name}-${value}`;

export const defaultOnChange = (form, field, e) => { // TODO make e the first param here
  // TODO: values are not reset on the backend side
  // Allow resetting current selection
  if (e.target.value === field.value) {
    // If current radio option is selected then uncheck it
    form.setFieldValue(field.name, '');
    e.target.checked = false;
  } else {
    field.onChange(e);
  }
};

const RadioButton = ({
  // Field
  field,
  form,
  value,
  // Appearance
  label,
  onChange,
}) => (
  <div className="form-check radio">
    <input
      name={field.name}
      id={id(field, value)}
      type="radio"
      className="form-check-input"
      value={value || false}
      checked={value === field.value}
      onBlur={field.onBlur}
      onChange={(e) => onChange(form, field, e)}
    />

    <label htmlFor={id(field, value)} className="form-check-label">{label}</label>
  </div>
);

RadioButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

RadioButton.defaultProps = {
  onChange: defaultOnChange,
};

export default RadioButton;
