import React from 'react';
import styles from 'layout/Login.scss';

const IntroText = () => (
  <div className={styles.intro_text}>
    <p>
      <span className="text-pink">* </span>
      {'By logging in you accept our '}
      <a href="https://blueprintgenetics.com/about-us/general-terms/">
        General Terms
      </a>
      {' and '}
      <a href="https://blueprintgenetics.com/nucleus-terms-of-use/">
        Terms of Use of the Nucleus Online System
      </a>
      .
    </p>
    Nucleus is the online system of Blueprint Genetics,
    for ordering genetic tests. Nucleus offers the possibility to place orders for genetic
    testing, follow progress, and read results. The portal is free for all users and
    having an account does not obligate to anything.
    <br />
    <br />
    {
      'To register for an account, click on the link “Request account” above. If you have any questions about Nucleus or ordering please visit '
    }
    <a href="//blueprintgenetics.com/nucleus">
      blueprintgenetics.com/nucleus
    </a>
    {' or contact our customer support:'}
    <br />
    {'GLOBAL '}
    <a href="mailto:support@blueprintgenetics.com">
      support@blueprintgenetics.com
    </a>
    <br />
    {'US '}
    <a href="mailto:support.us@blueprintgenetics.com">
      support.us@blueprintgenetics.com
    </a>
    <br />
    {'CANADA '}
    <a href="mailto:support.ca@blueprintgenetics.com">
      support.ca@blueprintgenetics.com
    </a>
  </div>
);

export default IntroText;
