import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  getProfileInfo, reset2fa, init2fa, get2fa, confirmEnable2fa, disable2fa,
} from 'redux/modules/auth';
import SpinnerOverlay from 'components/Spinner/SpinnerOverlay';
import QRCode from 'components/QRCode';
import queryString from 'query-string';

import styles from './AccountSettingsEnable2fa.scss';

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  gettingProfileInfo: state.auth.gettingProfileInfo,
  authenticationKey: state.auth.authenticationKey,
  qrCodeData: state.auth.qrCodeData,
  get2faPending: state.auth.get2faPending,
  get2faError: state.auth.get2faError,
  init2faPending: state.auth.init2faPending,
  init2faSuccess: state.auth.init2faSuccess,
  init2faError: state.auth.init2faError,
  init2faEmail: state.auth.init2faEmail,
  confirmEnable2faPending: state.auth.confirmEnable2faPending,
  confirmEnable2faSuccess: state.auth.confirmEnable2faSuccess,
  confirmEnable2faError: state.auth.confirmEnable2faError,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getProfileInfo,
  init2fa,
  get2fa,
  reset2fa,
  confirmEnable2fa,
  disable2fa,
}, dispatch);

class AccountSettingsEnable2fa extends Component {
  static propTypes = {
    userInfo: PropTypes.shape({}),
    gettingProfileInfo: PropTypes.bool,
    authenticationKey: PropTypes.string,
    qrCodeData: PropTypes.string,
    init2faPending: PropTypes.bool,
    init2faSuccess: PropTypes.bool,
    init2faError: PropTypes.string,
    init2faEmail: PropTypes.string,
    get2faPending: PropTypes.bool,
    get2faError: PropTypes.string,
    confirmEnable2faPending: PropTypes.bool,
    confirmEnable2faSuccess: PropTypes.bool,
    confirmEnable2faError: PropTypes.string,
    getProfileInfo: PropTypes.func.isRequired,
    init2fa: PropTypes.func.isRequired,
    get2fa: PropTypes.func.isRequired,
    reset2fa: PropTypes.func.isRequired,
    confirmEnable2fa: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    userInfo: null,
    gettingProfileInfo: null,
    authenticationKey: null,
    qrCodeData: null,
    init2faPending: null,
    init2faSuccess: null,
    init2faError: null,
    init2faEmail: null,
    get2faPending: null,
    get2faError: null,
    confirmEnable2faPending: null,
    confirmEnable2faSuccess: null,
    confirmEnable2faError: null,
    location: {},
  };

  state = {
    code: '',
  };

  componentDidMount() {
    const token = queryString.parse(this.props.location.search).auth;

    this.props.getProfileInfo();
    this.props.reset2fa();
    if (token) {
      this.props.get2fa({
        token,
      });
    } else {
      this.props.init2fa();
    }
  }

  handleCodeChange = (event) => {
    this.setState({
      code: event.target.value,
    });
  }

  handleClickConfirm = () => {
    this.props.confirmEnable2fa({
      token: queryString.parse(this.props.location.search).auth,
      code: this.state.code,
    });
  }

  render() {
    const { userInfo, gettingProfileInfo } = this.props;
    const waiting = gettingProfileInfo || this.props.init2faPending || this.props.get2faPending
          || this.props.confirmEnable2faPending;
    const token = queryString.parse(this.props.location.search).auth;

    return (
      <div className={`${styles.accountSettings} container`}>
        <div className="row">
          <div className={`${styles.header} col-md-12`}>
            <Helmet title="Confirm enable two-factor authentication" />
            <h1>Enable two-factor authentication</h1>
          </div>

          {!gettingProfileInfo && userInfo && (
            <div style={{ marginLeft: 15, marginRight: 15 }}>
              <p className="text-danger">
                {this.props.get2faError}
                {this.props.init2faError}
              </p>

              {token && this.props.authenticationKey && (
                <div>
                  <label>Authentication key</label>
                  <p>{this.props.authenticationKey}</p>
                  <div style={{ marginBottom: 10 }}>
                    {this.props.qrCodeData && <QRCode text={this.props.qrCodeData} />}
                  </div>

                  {!this.props.confirmEnable2faSuccess && (
                    <div>
                      <label className={styles.profile_label}>Authentication code</label>
                      <p>
                        <input className="form-control" style={{ width: 200 }} type="password" value={this.state.code} onChange={this.handleCodeChange} />
                      </p>
                      <p className="text-danger">
                        {this.props.confirmEnable2faError}
                      </p>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={this.props.confirmEnable2faPending}
                        onClick={this.handleClickConfirm}
                      >
                        Enable two-factor authentication
                      </button>
                    </div>
                  )}

                  {this.props.confirmEnable2faSuccess && (
                    <p>
                      <strong>Two factor authentication is now enabled for your account.</strong>
                    </p>
                  )}
                </div>
              )}

              {!token && this.props.init2faSuccess && (
                <div>
                  <p>
                    A confirmation email with further instructions has been sent to you email
                    address. Please follow the link in that email to continue the process.
                  </p>
                  {this.props.init2faEmail && <pre>{this.props.init2faEmail}</pre>}
                </div>
              )}
            </div>
          )}

          {waiting && <SpinnerOverlay />}
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AccountSettingsEnable2fa);
