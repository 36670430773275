import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, useFormikContext } from 'formik';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import Checkbox from 'components/Formik/Checkbox';
import CheckboxGroup from 'components/Formik/CheckboxGroup';
import styles from './UserModal.scss';

const roleOptions = [
  { key: 'physician', value: 'Physician' },
  { key: 'geneticist', value: 'Geneticist' },
  { key: 'support', value: 'Support' },
  { key: 'clinical_consultant', value: 'Clinical consultant' },
  { key: 'hospital_admin', value: 'Hospital admin' },
  { key: 'order_agent', value: 'Allied healthcare provider' },
];

const internalUsers = ['geneticist', 'support', 'clinical_consultant'];

const UserForm = ({
  hospitalOptions,
  restrictedPanelOptions,
  isAuthorizedProvider,
  authorizedProviderLastApproved,
  isBlankUser,
  userRole,
}) => {
  const formikContext = useFormikContext();
  const [selected, setSelectRole] = useState(userRole);

  const handleHospitalChange = ({ target: { value } }) => {
    formikContext.setFieldTouched('npi');
    formikContext.setFieldValue('hospital_id', value);
  };

  const handleIsAuthorizedProviderChange = (form, field, { target: { checked } }) => {
    form.setFieldTouched('npi');
    form.setFieldValue('is_authorized_provider', checked);
  };

  const handleRoleChange = (e) => {
    setSelectRole(e.target.value);
  };

  return (
    <Form>
      {formikContext.errors.npi && (
        <p className="text-danger">{formikContext.errors.npi}</p>
      )}
      <Field
        component={LabelInput}
        label="Email"
        name="email"
        required
      />

      <Field
        label="Hospital"
        name="hospital_id"
        component={SelectInput}
        options={hospitalOptions}
        placeholder="Select hospital:"
        required
        onChange={handleHospitalChange}
      />

      <Field
        label="Department"
        name="department"
        component={LabelInput}
      />

      <Field
        label="Role"
        name="role"
        component={SelectInput}
        options={roleOptions}
        placeholder="Select role:"
        onChange={handleRoleChange}
        required
      />

      <Field
        label="Title"
        name="title"
        component={LabelInput}
      />

      <Field
        label="Full name"
        name="full_name"
        component={LabelInput}
      />

      <Field
        label="NPI"
        name="npi"
        component={LabelInput}
      />

      <Field
        name="is_authorized_provider"
        component={Checkbox}
        label="Is authorized provider"
        className="modal_checkbox"
        labelClassName="view_order_modal_label"
        onChange={handleIsAuthorizedProviderChange}
      />
      {isAuthorizedProvider && authorizedProviderLastApproved && (
        <Col smOffset={6} className={styles.authorization_info}>
          Last approved on:
          {' '}
          {moment(authorizedProviderLastApproved).format('YYYY-MM-DD')}
        </Col>
      )}

      <Field
        name="allow_order_sharing"
        component={Checkbox}
        label="Allow order sharing"
        className="modal_checkbox"
        labelClassName="view_order_modal_label"
      />

      <Field
        name="is_super_user"
        component={Checkbox}
        label="Is superuser"
        className="modal_checkbox"
        labelClassName="view_order_modal_label"
      />

      <Field
        name="has_eea_rights"
        component={Checkbox}
        label="Has eea rights"
        className="modal_checkbox"
        labelClassName="view_order_modal_label"
      />

      { internalUsers.includes(selected) && String(process.env.REACT_APP_REGION) === 'US' && (
        <Field
          name="has_full_us_access"
          component={Checkbox}
          label="Full US access"
          className="modal_checkbox"
          labelClassName="view_order_modal_label"
          tooltipIcon
        />
      )}

      <Field
        name="allowed_panel_ids"
        component={CheckboxGroup}
        options={restrictedPanelOptions}
        label="Restricted panels"
      />

      {isBlankUser !== null && (
        <Row>
          <Col sm={6} className={styles.authorization_info}>
            <label className="col-form-label">Is Blank User:</label>
          </Col>
          <Col sm={6} className={styles.no_left_padding}>
            {isBlankUser ? ' Yes' : ' No'}
          </Col>
        </Row>
      )}

    </Form>
  );
};

UserForm.propTypes = {
  hospitalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  restrictedPanelOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  isAuthorizedProvider: PropTypes.bool,
  authorizedProviderLastApproved: PropTypes.string,
  isBlankUser: PropTypes.bool,
  userRole: PropTypes.string,
};

UserForm.defaultProps = {
  isAuthorizedProvider: false,
  authorizedProviderLastApproved: null,
  isBlankUser: false,
  userRole: null,
};

export default UserForm;
