import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { LabelInput, Spinner } from 'components';
import {
  resetTransferOrder,
  transferOrdersById,
  transferAllOrders,
} from 'redux/modules/support';
import ToggleInput from 'components/StyleComponents/Input/ToggleInput';

import styles from './NewOrderTransferModal.scss';

const mapStateToProps = (state) => ({
  waitingTransferOrder: state.support.waitingTransferOrder,
  doneTransferOrder: state.support.doneTransferOrder,
  error: state.support.error,
  confirmationQuestion: state.support.confirmationQuestion,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetTransferOrder,
  transferOrdersById,
  transferAllOrders,
}, dispatch);

class NewOrderTransferModal extends Component {
  static propTypes = {
    waitingTransferOrder: PropTypes.bool,
    doneTransferOrder: PropTypes.bool,
    error: PropTypes.string,
    confirmationQuestion: PropTypes.string,
    resetTransferOrder: PropTypes.func.isRequired,
    transferOrdersById: PropTypes.func.isRequired,
    transferAllOrders: PropTypes.func.isRequired,

    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    waitingTransferOrder: null,
    doneTransferOrder: null,
    error: null,
    confirmationQuestion: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({
        data: {
          order_ids_string: '',
          email_source: '',
          email_target: '',
          force: false,
          alias_email: '',
        },
        isButtonClickedOnce: false,
        isTransferAll: false,
      });
      this.props.resetTransferOrder();
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    if (prevProps.waitingTransferOrder && !this.props.waitingTransferOrder) {
      if (this.props.doneTransferOrder) {
        if (this.state.isButtonClickedOnce) {
          this.setState({
            isButtonClickedOnce: false,
          });
          this.handleClose();
        } else {
          this.setState({
            isButtonClickedOnce: true,
          });
        }
      } else {
        this.setState({
          isButtonClickedOnce: false,
        });
      }
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  handleClose = () => {
    this.setState({
      data: {},
    });
    this.props.onClose();
  }

  handleChange = (event) => {
    const input = event.target;
    this.props.resetTransferOrder();
    this.setState((state) => ({
      data: {
        ...state.data,
        [input.name]: input.type === 'checkbox' ? input.checked : input.value,
      },
      isButtonClickedOnce: false,
    }));
  }

  handleRadioButton = () => {
    const isTransferAllPrev = this.state.isTransferAll;
    this.props.resetTransferOrder();
    this.setState(() => ({
      isTransferAll: !isTransferAllPrev,
    }));
  }

  handleCreate = () => {
    const isTransferAll = this.state.isTransferAll;
    if (isTransferAll) {
      this.props.transferAllOrders({
        ...this.state.data,
        only_test: !this.state.isButtonClickedOnce,
      });
    } else {
      this.props.transferOrdersById({
        ...this.state.data,
        only_test: !this.state.isButtonClickedOnce,
      });
    }
  }

  checkValidity() {
    const data = this.state.data;
    const orderId = data.order_ids_string ? Number(data.order_ids_string.replaceAll(' ', '').replaceAll(',', '')) : 0;
    const isTransferAll = this.state.isTransferAll;
    return (Number.isInteger(orderId) && orderId > 0 && data.email_target && !isTransferAll)
      || (isTransferAll && data.email_target && data.email_source);
  }

  render() {
    const {
      show,
    } = this.props;
    const data = this.state.data;
    const isTransferAll = this.state.isTransferAll;

    return (
      <Modal show={show} onHide={this.handleClose} size="xl" aria-labelledby="contained-modal-title-sm">
        <Modal.Header closeButton>
          <h2 style={{ marginTop: 30 }}>Order transfers</h2>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ToggleInput
              onChange={this.handleChange}
              name="orderTransferType"
              isChecked={this.state.isTransferAll || false}
              onClick={this.handleRadioButton}
              leftValue="Transfer orders by id"
              rightValue="Transfer all orders"
            />
            { !isTransferAll && (
              <div className="form-group row">
                <label htmlFor="order_ids_string" className="col-md-6 col-form-label">
                  Order IDs
                  <p className="small">If multiple orderIDs, use &apos;,&apos; as divider</p>
                </label>
                <textarea
                  name="order_ids_string"
                  className={`form-control ${styles.textarea}`}
                  required
                  value={data.order_ids_string}
                  onChange={this.handleChange}
                />
              </div>
            )}
            { isTransferAll && (
            <LabelInput
              label="From-user email"
              name="email_source"
              required
              labelSize="6"
              inputSize="6"
              value={data.email_source}
              onChange={this.handleChange}
            />
            ) }
            <LabelInput
              label="To-user email"
              name="email_target"
              required
              labelSize="6"
              inputSize="6"
              value={data.email_target}
              onChange={this.handleChange}
            />
            <div className="form-check checkbox modal_checkbox">
              <input
                type="checkbox"
                id="force"
                className="form-check-input"
                name="force"
                checked={data.force || false}
                onChange={this.handleChange}
              />
              <label htmlFor="force" className="view_order_modal_label form-check-label">
                Force order transfer
              </label>
            </div>
            <LabelInput
              label="Ordered by email"
              name="alias_email"
              labelSize="6"
              inputSize="6"
              value={data.alias_email}
              onChange={this.handleChange}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          {this.props.confirmationQuestion && (
            <span className={styles.confirmation_question}>{this.props.confirmationQuestion}</span>
          )}

          {this.state.error && (
            <span className={styles.error}>{this.state.error}</span>
          )}

          <button
            type="submit"
            className="btn btn-primary"
            disabled={!this.checkValidity()}
            onClick={this.handleCreate}
          >
            Transfer Order(s)
          </button>
        </Modal.Footer>

        {this.props.waitingTransferOrder && (
          <div className={styles.spinner_block}>
            <Spinner />
          </div>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderTransferModal);
