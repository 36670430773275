import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Formik/Checkbox';
import { Field } from 'formik';
import styles from './ProductListItem.scss';
import GetPdfInfo from './GetPdfInfo';
import PanelTierInfo from './PanelTierInfo';

// for editing product visibility on the admin side
export const ProductListItemEditVisibility = ({ product }) => (
  <li className={styles.panel_name}>
    <Field
      name={String(product.panel_id)}
      id={product.panel_id}
      component={Checkbox}
      label={`${product.display_name || product.name}, ${product.product_code}`}
      className="text-left"
    />
  </li>
);

ProductListItemEditVisibility.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    panel_id: PropTypes.number,
    product_code: PropTypes.string,
  }).isRequired,
};

// to order or customize when ordering a product
/* eslint-disable camelcase */
const ProductListItem = ({
  product, onSelect, onCustomize, pricingModel,
}) => (
  <li>
    <span
      className={styles.panel_name}
      role="button"
      tabIndex="-1"
      onClick={() => onSelect({
        ...product,
        panel_tier_id: product.panel_tier?.panel_tier_id,
      })}
      onKeyDown={() => {}}
    >
      {`${product.display_name || product.name}, ${product.product_code}`}

      {product.is_updated && (
        <span className={styles.panel_tag_updated}>Updated</span>
      )}

      {product.is_new && <span className={styles.panel_tag_new}>New!</span>}

      {product.ordering_instructions && (
        <div className="small">{product.ordering_instructions}</div>
      )}
    </span>

    <span className={styles.panel_action_group}>
      {/* For non-panel products, we do not show panel tier information */}
      {pricingModel && (
        <PanelTierInfo product={product} pricingModel={pricingModel} />
      )}

      {!!product.web_url && (
        <a
          href={product.web_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={styles.panel_read_more}>
            <i
              className="glyphicon glyphicon-lg glyphicon-info-sign"
              aria-hidden="true"
            />
          </span>
        </a>
      )}

      {product.special_type === 'msd' && (
        <GetPdfInfo product={product} />
      )}

      <button
        type="button"
        className="btn btn-default btn-sm"
        onClick={() => onSelect({
          ...product,
          panel_tier_id: product.panel_tier?.panel_tier_id,
        })}
      >
        Order
      </button>

      {onCustomize && product.has_flex && (
        <button
          type="button"
          className="btn btn-default btn-sm"
          style={{ marginLeft: '5px' }}
          onClick={() => onCustomize({
            ...product,
            panel_tier_id: product.panel_tier?.panel_tier_id,
          })}
        >
          Customize
        </button>
      )}
    </span>
  </li>
);

ProductListItem.propTypes = {
  product: PropTypes.shape({
    panel_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    product_code: PropTypes.string,
    is_updated: PropTypes.bool,
    is_new: PropTypes.bool,
    ordering_instructions: PropTypes.string,
    special_type: PropTypes.string,
    web_url: PropTypes.string,
    has_flex: PropTypes.bool,
    phenotype_tier: PropTypes.string,
    panel_tier: PropTypes.shape({
      panel_tier_id: PropTypes.number,
      size: PropTypes.string,
      minimum: PropTypes.number,
      maximum: PropTypes.number,
      isActive: PropTypes.bool,
    }),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCustomize: PropTypes.func,
  pricingModel: PropTypes.oneOf(['gene_count', 'phenotype', 'fixed']).isRequired,
};

ProductListItem.defaultProps = {
  onCustomize: null,
};

export default ProductListItem;
