import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  isExome,
  isMSD,
  isFMT
} from 'utils/forms';

export default class OrderReportTargetRegion extends Component {
  static propTypes = {
    title: PropTypes.string,
    targetRegion: PropTypes.string,
    orderInfo: PropTypes.object
  }


  render() {
    const {title, targetRegion, orderInfo} = this.props;
    const isExpectedSpecialTypes = isExome(orderInfo.special_type) || isMSD(orderInfo.special_type) || isFMT(orderInfo.special_type);

    return (
      <div>
        <h3 className="h4">{title}</h3>
          <div>
            {!isExpectedSpecialTypes && targetRegion.split('\n').map((item, index) =>
              <p key={index} dangerouslySetInnerHTML={{__html: item}}>
              </p>
            )}
            {isExpectedSpecialTypes && targetRegion.split('\n').map((item, index) =>
              <p key={index} dangerouslySetInnerHTML={{__html: item}}>
              </p>
            )}
          </div>
      </div>
    );
  }
}
