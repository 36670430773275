import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  disableUserNotifications,
  getUserNotifications,
} from 'redux/modules/auth';
import { withRouter } from 'react-router-dom';
import Page, { handlePagePromise } from 'components/Page';
import UserNotificationsList from 'admin/components/UserNotificationsList';
import { OPT_IN_OPTIONS } from 'utils/forms';
import SearchUserNotifications from './SearchUserNotifications';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  disableUserNotifications,
  getUserNotifications,
}, dispatch);

class OtherAccountsSettings extends Component {
  static propTypes = {
    disableUserNotifications: PropTypes.func.isRequired,
    getUserNotifications: PropTypes.func.isRequired,
  };

  state = {
    page: {
      loading: false,
      error: null,
    },
    data: {},
    success: false,
  };

  getUserNotifications = async (email) => {
    const userNotif = await this.props.getUserNotifications(email);
    const notifications = [];

    Object.entries(userNotif).forEach(([index, value]) => {
      const trimmedIndex = index.replace('notification_opt_out_', '');
      if ((trimmedIndex !== 'all' && !value) || (trimmedIndex === 'all' && value)) {
        notifications.push(trimmedIndex);
      }
    });

    const initialValues = {
      notifications: (
        Object.entries(userNotif).length
          ? notifications
          : OPT_IN_OPTIONS.map((o) => o.value) // Fallback to default notification settings
      ),
    };

    return {
      userEmail: email,
      initialValues,
      userNotif,
    };
  };

  handleSearch = (values) => {
    // reset success if we change two users in a row for example
    this.setState({ success: false });

    return this.getUserNotifications(values.email);
  };

  handleUpdateNotifications = async (checkboxes) => {
    const notifications = {};
    // values is an array that includes or not one optin checkbox
    // then get true or false to sent to the backend
    OPT_IN_OPTIONS.forEach((optin) => {
      // reverse boolean because it is fun ....
      notifications[optin.value] = !checkboxes.notifications.includes(
        optin.value,
      );
    });

    // Handle the "ALL" notifications case
    notifications.all = checkboxes.notifications.includes('all');

    const requestData = {
      notification_opt_outs: notifications,
      user_email: this.state.data.userEmail,
    };

    await this.props.disableUserNotifications(requestData);
    this.setState({ success: true });
    return this.state;
  };

  renderPage = () => {
    const {
      data: { userEmail, userNotif, initialValues },
      success,
    } = this.state;

    return (
      <div className="row no-gutters">
        <div className="col-md-12">
          <h3>Notification settings</h3>
          <SearchUserNotifications
            onSearch={(values, actions) => handlePagePromise(
              this.handleSearch(values, actions),
              this,
            )}
          />

          <div style={{ margin: '30px 0 0 0' }}>
            {userNotif !== undefined && (
              <UserNotificationsList
                initialValues={initialValues}
                options={OPT_IN_OPTIONS}
                onUpdate={(values) => handlePagePromise(
                  this.handleUpdateNotifications(values),
                  this,
                )}
                userEmail={userEmail}
                adminMode
              />
            )}

            {success && (
              <div className="col-md-12">
                <p className="text-success">
                  Notification settings updated successfully.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { page } = this.state;

    return (
      <Page
        title="Other accounts settings"
        render={this.renderPage}
        page={page}
        cols={8}
        offsetCols={2}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OtherAccountsSettings);
