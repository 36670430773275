import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import ErrorChangeStatus from './ErrorChangeStatus';

const ModalFooter = ({
  changeStatus,
  error,
  hasLabId,
  hide,
  isOrderStatus,
  loading,
  removeLabId,
  resultsReported,
  saving,
}) => (
  <Modal.Footer>
    {
      error && (
        <ErrorChangeStatus error={error} />
      )
    }
    {!loading && !saving && isOrderStatus && (
      <div className="w-100">
        {hasLabId === true && (
          <div className="float-left">
            <button
              type="button"
              className="btn btn-primary"
              onClick={removeLabId}
              disabled={resultsReported}
            >
              Remove Lab ID
            </button>
          </div>
        )}
        <div className="float-right">
          <button type="button" className="btn btn-default" onClick={hide}>Cancel</button>
          <button type="submit" className="btn btn-primary" onClick={changeStatus}>Change status</button>
        </div>
      </div>
    )}
  </Modal.Footer>
);

ModalFooter.propTypes = {
  changeStatus: PropTypes.func.isRequired,
  error: PropTypes.string,
  hasLabId: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  isOrderStatus: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  removeLabId: PropTypes.func,
  resultsReported: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
};

ModalFooter.defaultProps = {
  error: null,
  hasLabId: false,
  removeLabId: null,
  resultsReported: false,
};

export default ModalFooter;
