import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import './theme/app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux/create';
import NucleusClient from './helpers/NucleusClient';
import AppRouter from './routes';
import { setUser, shouldUseAlbAuth } from './redux/modules/auth';

const client = new NucleusClient();
const store = configureStore({}, client);
client.setStore(store);

// Read user auth
try {
  const userString = localStorage.getItem('user');
  if (userString) {
    const user = JSON.parse(userString);

    if (user && !shouldUseAlbAuth() && !user.auth_token) {
      // This is someone who has switched back from ALB auth to legacy
      // auth. In this case, the user object doesn't contain the needed
      // legacy auth_token and that would create undefined behaviors. We
      // clear the user object in localstorage and restart the app for a
      // clean slate.

      localStorage.removeItem('user');
      window.location.reload();
    }

    store.dispatch(setUser(user));
  }
} catch (e) {
  localStorage.removeItem('user');
}

ReactDOM.render(
  <Provider store={store} key="provider">
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </Provider>,
  document.getElementById('content'),
);
