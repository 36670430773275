import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';

const Checkbox = (props) => (
  <Field name={props.name}>
    {({ field, form }) => (
      <div className="form-check checkbox">
        <input
          type="checkbox"
          className="form-check-input"
          {...props}
          checked={field.value.includes(props.value)}
          onChange={() => {
            if (field.value.includes(props.value)) {
              const nextValue = field.value.filter(
                (value) => value !== props.value,
              );
              form.setFieldValue(props.name, nextValue);
            } else {
              const nextValue = field.value.concat(props.value);
              form.setFieldValue(props.name, nextValue);
            }
          }}
        />
        <label className="form-check-label" htmlFor={props.value}>{props.label}</label>
      </div>
    )}
  </Field>
);

Checkbox.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const listUserNotification = (option, adminMode) => {
  if (option.value === 'sample_not_arrived') {
    if (adminMode) {
      return (
        <Checkbox
          key={option.value}
          id={option.value}
          name="notifications"
          {...option}
        />
      );
    }
  } else {
    return (
      <Checkbox
        key={option.value}
        id={option.value}
        name="notifications"
        {...option}
      />
    );
  }
  return (<></>);
};

const UserNotificationsList = (props) => {
  const {
    onUpdate,
    userEmail,
    adminMode,
    options,
    initialValues,
  } = props;

  return (
    <Formik
      enableReinitialize // allow inital values to change
      initialValues={initialValues}
      onSubmit={onUpdate}
    >
      {(formProps) => (
        <Form>
          <div className="form-group row">
            <div className="col-md-6 col-form-label">
              Update notification settings

              {userEmail && (
                <p>{userEmail}</p>
              )}

              <p className="small">
                Select to receive automated email for the option.
              </p>
            </div>

            <div className="col-md-6 col-form-label">
              {options
               && options.map((option) => (

                 listUserNotification(option, adminMode)

               ))}
            </div>

            {adminMode && (

              <div>
                <label className="col-md-6 col-form-label">
                  Disable ALL notifications for this account

                  <p className="small">
                    This also means disabling emails such as password reset links.
                  </p>

                </label>

                <label className="col-md-6 col-form-label">
                  <Checkbox
                    key="all"
                    id="all"
                    name="notifications"
                    label="Yes, disable ALL notifications for this account"
                    value="all"
                  />
                </label>
              </div>
            )}

            <div className="float-right button-group">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  formProps.resetForm({ values: { notifications: [] } });
                }}
              >
                Uncheck all
              </button>

              {!userEmail && (
                <Link to="/account-settings" className="btn btn-default">
                  Cancel
                </Link>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

UserNotificationsList.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  adminMode: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  initialValues: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

UserNotificationsList.defaultProps = {
  userEmail: null,
};

export default UserNotificationsList;
