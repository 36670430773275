import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import LabelInput from 'components/Formik/LabelInput';
import {
  isFamilyOrder,
  shouldShowSampleFromOngoingPregnancyQuestion,
} from 'utils/forms';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import SelectInput from 'components/Formik/SelectInput';
import RadioButtonGroup from 'components/Formik/RadioButtonGroup';
import RadioButton from 'components/Formik/RadioButton';
import FetalQuestionDisclaimer from 'components/FetalQuestion/FetalQuestionDisclaimer';

export default class FetalQuestion extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    userInfo: PropTypes.shape().isRequired,
    values: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const isSupportUser = props.userInfo?.user_role === 'support';
    this.state = {
      showGestationalAgeQuestion: props.orderInfo.sample_from_ongoing_pregnancy === 'yes',
      showSampleFromOngoingPregnancyQuestion: shouldShowSampleFromOngoingPregnancyQuestion(
        props.orderInfo.is_this_a_fetal_sample,
      ),
      highlighted: isSupportUser && ![undefined, 'none', 'no'].includes(props.orderInfo.is_this_a_fetal_sample),
    };
  }

  onFetalSampleChange = (event, form, isSupportUser) => {
    const showSampleFromOngoingPregnancyQuestion = shouldShowSampleFromOngoingPregnancyQuestion(
      event.target.value,
    );

    if (!showSampleFromOngoingPregnancyQuestion) {
      form.setFieldValue('gestational_age', null);
      form.setFieldValue('sample_from_ongoing_pregnancy', null);
    }

    this.setState({
      showSampleFromOngoingPregnancyQuestion,
      highlighted: ![undefined, 'none', 'no'].includes(event.target.value) && isSupportUser,
    });
  };

  onSampleFromOngoingPregnancyChange = (form, field, event) => {
    const isSampleFromOnGoingPregnancy = event.target.value === 'yes';

    if (!isSampleFromOnGoingPregnancy) {
      form.setFieldValue('gestational_age', null);
    }

    this.setState({
      showGestationalAgeQuestion: isSampleFromOnGoingPregnancy,
    });
    field.onChange(event);
  };

  renderForm() {
    const {
      showGestationalAgeQuestion,
      showSampleFromOngoingPregnancyQuestion,
      highlighted,
    } = this.state;

    const { orderInfo, values, userInfo } = this.props;

    const isSupportUser = userInfo?.user_role === 'support';

    return (
      <div>
        {isFamilyOrder(orderInfo) && (
          <Field
            isHighlighted={highlighted}
            component={SelectInput}
            label="Is this a fetal sample?"
            name="is_this_a_fetal_sample"
            onChange={(event, form) => this.onFetalSampleChange(event, form, isSupportUser)}
            options={[
              {
                key: 'none',
                value: 'None',
              },
              {
                key: 'index',
                value: 'Yes - Index',
              },
              {
                key: 'family_member_one',
                value: 'Yes - Family member 1',
              },
              {
                key: 'family_member_two',
                value: 'Yes - Family member 2',
              },
            ]}
          />
        )}

        {!isFamilyOrder(orderInfo) && (
          <RadioButtonGroup
            isHighlighted={highlighted}
            name="is_this_a_fetal_sample"
            label="Is this a fetal sample?"
          >
            <Field
              component={RadioButton}
              key="patient_is_this_a_fetal_sample_no"
              label="No"
              name="is_this_a_fetal_sample"
              onChange={(form, field, event) => {
                this.onFetalSampleChange(event, form, isSupportUser);
                return field.onChange(event);
              }}
              value="no"
            />
            <Field
              component={RadioButton}
              key="patient_is_this_a_fetal_sample_yes"
              label="Yes"
              name="is_this_a_fetal_sample"
              onChange={(form, field, event) => {
                this.onFetalSampleChange(event, form, isSupportUser);
                return field.onChange(event);
              }}
              value="yes"
            />
            {isSupportUser && values && values.is_this_a_fetal_sample && (
              <Field
                component={RadioButton}
                key="patient_is_this_a_fetal_sample_none"
                label="Remove selection"
                name="is_this_a_fetal_sample"
                onChange={(form) => {
                  form.setFieldValue('is_this_a_fetal_sample', null);
                  form.setFieldValue('sample_from_ongoing_pregnancy', null);
                  form.setFieldValue('gestational_age', null);
                  this.setState({
                    showSampleFromOngoingPregnancyQuestion: false,
                    showGestationalAgeQuestion: false,
                    highlighted: false,
                  });
                }}
              />
            )}
          </RadioButtonGroup>
        )}

        <FetalQuestionDisclaimer orderInfo={orderInfo} />

        {showSampleFromOngoingPregnancyQuestion && (
          <RadioButtonGroup
            name="sample_from_ongoing_pregnancy"
            label="Is this sample from an ongoing pregnancy?"
          >
            <Field
              component={RadioButton}
              key="sample_from_ongoing_pregnancy_no"
              label="No"
              name="sample_from_ongoing_pregnancy"
              onChange={this.onSampleFromOngoingPregnancyChange}
              value="no"
            />
            <Field
              component={RadioButton}
              key="sample_from_ongoing_pregnancy_yes"
              label="Yes"
              name="sample_from_ongoing_pregnancy"
              onChange={this.onSampleFromOngoingPregnancyChange}
              value="yes"
            />
            {isSupportUser && values && values.sample_from_ongoing_pregnancy && (
              <Field
                component={RadioButton}
                key="sample_from_ongoing_pregnancy_none"
                label="Remove selection"
                name="sample_from_ongoing_pregnancy"
                onChange={(form) => {
                  form.setFieldValue('sample_from_ongoing_pregnancy', null);
                  form.setFieldValue('gestational_age', null);
                  this.setState({
                    showGestationalAgeQuestion: false,
                  });
                }}
              />
            )}
          </RadioButtonGroup>
        )}

        {showGestationalAgeQuestion && showSampleFromOngoingPregnancyQuestion && (
          <Field
            name="gestational_age"
            component={LabelInput}
            label="Gestational age"
          />
        )}
      </div>
    );
  }

  render() {
    const { orderInfo } = this.props;

    return <>{orderInfo && this.renderForm()}</>;
  }
}
