import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import {
  Yup, requiredString, when,
  patientQuestions,
} from 'components/Formik/validation';

import * as orderActions from 'redux/modules/orders';
import * as insuranceActions from 'redux/modules/insurance';

import { BILLING_METHODS } from 'helpers/constants/billingInfoConstants';
import { USER_ROLE } from 'helpers/constants/userRoleConstants';
import {
  INITIAL_STATES,
  INITIAL_FORM_VALUES,
} from 'helpers/constants/orderTestFormBillingInfoConstants';
import { isUsTerritoryCountryCode } from 'utils/forms';
import {
  prepareQuestionsForForm,
  constructQuestionAnswers,
  constructPatientQuestionsInBillingInfo,
} from 'utils/patientQuestions';
import { orderTestFormBillingInfoStaticProps } from 'types/OrderInfoPropTypes';

import EditOrderStep from 'components/_CreateOrderProcess/EditOrderStep';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import LabelInput from 'components/Formik/LabelInput';
import InstitutionalBillingInfo from './InstitutionalBillingInfo';
import PatientInsuranceBillingInfo from './PatientInsuranceBillingInfo';
import PatientPaymentBillingInfo, { PatientPaymentDescription } from './PatientPaymentBillingInfo';
import SponsoredBillingInfo from './SponsoredBillingInfo';
import ClinicalHistoryFields from './ClinicalHistoryFields';
import SelectBillingMethodField from './SelectBillingMethodField';

const isPatientPayment = (billingMethod) => billingMethod === BILLING_METHODS.PATIENT_PAYMENT;
const isPatientInsurance = (billingMethod) => billingMethod === BILLING_METHODS.PATIENT_INSURANCE;
const isInstitutional = (billingMethod) => billingMethod === BILLING_METHODS.INSTITUTIONAL;
const isSponsored = (billingMethod) => billingMethod === BILLING_METHODS.SPONSORED;

const transformBillingMethods = (billingMethods) => Object.keys(billingMethods).map((k) => ({
  key: k,
  value: billingMethods[k],
}));

const noValidationSchema = Yup.object().shape({});

const validationSchema = (
  isLegacy = false,
  isAfterHospitalStatusReleaseAndUsOrder = false,
) => {
  const isBillingPatientInsurance = when('billing_method', 'patient_insurance', requiredString());
  const schema = {
    billing_method: requiredString(),
    insurance_company: isBillingPatientInsurance,
    credit_card_holder_email: when('billing_method', 'patient_payment', requiredString()),
  };
  if (isLegacy) {
    schema.credit_card_holder_name = when('billing_method', 'patient_payment', requiredString());
  }
  if (isAfterHospitalStatusReleaseAndUsOrder) {
    schema.patient_address_line_1 = isBillingPatientInsurance;
    schema.patient_city = isBillingPatientInsurance;
    schema.patient_state = isBillingPatientInsurance;
    schema.patient_zip = isBillingPatientInsurance;
    schema.patient_phone = isBillingPatientInsurance;
  } else {
    schema.credit_card_holder_first_name = when('billing_method', 'patient_payment', requiredString());
    schema.credit_card_holder_last_name = when('billing_method', 'patient_payment', requiredString());
  }
  return Yup.object().shape(schema);
};

const initialState = INITIAL_STATES;
const initialFormValues = INITIAL_FORM_VALUES;

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  orderInfo: state.orders.orderInfo,
  aliasUserId: state.auth.aliasUserId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getBillingInfo: orderActions.getBillingInfo,
  getBillingMethods: orderActions.getBillingMethods,
  saveBillingInfo: orderActions.saveBillingInfo,
  createInsuranceStatus: insuranceActions.createInsuranceStatus,
  savePanelAnswers: orderActions.savePanelAnswers,
  getPanelQuestions: orderActions.getPanelQuestions,
}, dispatch);

class OrderTestFormBillingInfo extends Component {
  static propTypes = orderTestFormBillingInfoStaticProps;

  static defaultProps = {
    aliasUserId: null,
  };

  state = initialState;

  async componentDidMount() {
    const { orderInfo } = this.props;
    await this.fetchBillingInfo();

    // fetching the billing methods is dependent on having information about whether
    // existing billing information exists
    await this.fetchBillingMethods();

    if (orderInfo.is_after_hospital_status_release_us_order) {
      this.setState({
        isAfterHospitalStatusReleaseAndUsOrder: orderInfo.is_after_hospital_status_release_us_order,
      });
      this.fetchPatientHistoryQuestions();
    }
  }

  fetchPatientHistoryQuestions = async () => {
    const { orderInfo } = this.props;

    this.setState({
      patientHistoryQuestions: {
        loading: true,
        error: null,
        questions: [],
      },
    });
    try {
      const questions = await this.props.getPanelQuestions(orderInfo.order_id);
      this.setState({
        patientHistoryQuestions: {
          loading: false,
          error: null,
          questions: prepareQuestionsForForm(
            Object.values(questions)
              .sort((a, b) => a.order_no - b.order_no),
          ),
        },
      });
    } catch (e) {
      this.setState({
        patientHistoryQuestions: {
          loading: false,
          error: 'Failed to load clinical history',
          questions: [],
        },
      });
    }
  }

  fetchBillingMethods = async () => {
    const { orderInfo } = this.props;

    this.setState({
      billingMethods: {
        loading: true,
        error: null,
        data: initialState.billingMethods.data,
      },
    });

    try {
      const billingMethods = await this.props.getBillingMethods(orderInfo.product.id);

      this.setState({
        billingMethods: {
          loading: false,
          error: null,
          data: transformBillingMethods(billingMethods.billing_methods),
        },
      });
    } catch (e) {
      this.setState({
        billingMethods: {
          loading: false,
          error: 'Failed to load billing methods',
          data: initialState.billingMethods.data,
        },
      });
    }
  }

  fetchBillingInfo = async () => {
    const { orderInfo } = this.props;

    this.setState({
      billingInfo: {
        loading: true,
        error: null,
        data: initialState.billingInfo.data,
      },
    });

    try {
      const billingInfo = await this.props.getBillingInfo(orderInfo.order_id);

      this.setState({
        billingInfo: {
          loading: false,
          error: null,
          data: billingInfo,
        },
      });
    } catch (e) {
      this.setState({
        billingInfo: {
          loading: false,
          error: 'Failed to load billing info',
          data: initialState.billingInfo.data,
        },
      });
    }
  }

  validateSponsoredBillingMethod = (values, isExternalRole) => {
    if (isExternalRole && !values.promotion_code) {
      return { promotion_code: '* This field is required' };
    }
    return null;
  }

  validatePatientHistoryQuestions = (
    loading,
    isAfterHospitalStatusReleaseAndUsOrder,
    isExternalRole,
    isHidePromoCode,
  // eslint-disable-next-line max-params
  ) => (values) => {
    if (isSponsored(values.billing_method)) {
      if (isHidePromoCode) {
        return null;
      }
      return this.validateSponsoredBillingMethod(values, isExternalRole);
    }

    if (!isAfterHospitalStatusReleaseAndUsOrder) {
      return null;
    }
    if (loading) {
      return { formIsLoading: true };
    }
    const errors = {};

    const filteredQuestions = constructPatientQuestionsInBillingInfo(
      values?.questions,
      isPatientInsurance(values.billing_method)
      || isPatientPayment(values.billing_method),
    );

    const questionErrors = patientQuestions(filteredQuestions);
    if (questionErrors.length) {
      errors.questions = questionErrors;
    }
    return errors;
  };

  submit = async (values, actions) => {
    const { orderInfo } = this.props;

    actions.setFieldError('general', null);
    try {
      const isPatientPaymentOrInsurance = (
        isPatientPayment(values.billing_method)
        || isPatientInsurance(values.billing_method)
      );

      if (this.state.isAfterHospitalStatusReleaseAndUsOrder) {
        const answers = constructQuestionAnswers(values.questions);
        await this.props.savePanelAnswers(orderInfo.order_id, answers);
      }

      await this.props.saveBillingInfo({
        order_id: orderInfo.order_id,
        billing_method: values.billing_method,
        promotion_code: values.promotion_code,

        // Institutional
        has_family_service: isInstitutional(values.billing_method)
          ? values.has_family_service
          : false,
        add_billing_address: values.add_billing_address,
        contact_person: values.contact_person,
        facility_name: values.facility_name,
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        zip: values.zip,
        city: values.city,
        country: values.country,
        contact_person_email: values.contact_person_email,
        // Patient insurance
        insurance_company: values.insurance_company,
        insurance_id_no: values.insurance_id_no,
        group_no: values.group_no,
        patient_relation: values.patient_relation,
        other_patient_relation: (values.patient_relation === 'other' && values.other_patient_relation) ? values.other_patient_relation : '',
        policy_holder_name: values.policy_holder_name,
        policy_holder_first_name: values.policy_holder_first_name,
        policy_holder_last_name: values.policy_holder_last_name,
        policy_holder_dob: values.policy_holder_dob,
        patient_phone: isPatientInsurance(values.billing_method) ? values.patient_phone : '',
        patient_email: isPatientInsurance(values.billing_method) ? values.patient_email : '',
        // Patient payment
        credit_card_holder_email: values.credit_card_holder_email,
        credit_card_holder_name: values.credit_card_holder_name,
        credit_card_holder_first_name: values.credit_card_holder_first_name,
        credit_card_holder_last_name: values.credit_card_holder_last_name,
        credit_card_holder_phone: values.credit_card_holder_phone,
        // Common to patient insurance and patient payment
        patient_address: isPatientPaymentOrInsurance ? values.patient_address : '',
        patient_address_line_1: isPatientPaymentOrInsurance ? values.patient_address_line_1 : '',
        patient_address_line_2: isPatientPaymentOrInsurance ? values.patient_address_line_2 : '',
        patient_city: isPatientPaymentOrInsurance ? values.patient_city : '',
        patient_state: isPatientPaymentOrInsurance ? values.patient_state : '',
        patient_zip: isPatientPaymentOrInsurance ? values.patient_zip : '',
      });
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  }

  onCreateInsuranceCase = async () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!window.confirm('Are you sure you want to create an insurance case?')) {
      return;
    }

    this.setState({
      insuranceCaseStatus: {
        created: false,
        creating: true,
        error: null,
      },
    });

    const { orderInfo } = this.props;

    try {
      await this.props.createInsuranceStatus(orderInfo.order_id);

      this.setState({
        insuranceCaseStatus: {
          created: true,
          creating: false,
        },
      });
    } catch (e) {
      this.setState({
        insuranceCaseStatus: {
          created: false,
          creating: false,
          error: e.message,
        },
      });
    }
  }

  selectDefaultBillingMethodSponsoredTesting = (formProps) => {
    if (formProps.values.billing_method === '') {
      formProps.setFieldValue('billing_method', 'sponsored');
    }
  }

  renderBody(formProps) {
    const {
      orderInfo,
      userInfo,
      aliasUserId,
    } = this.props;

    const { values } = formProps;
    const { billingInfo, billingMethods, isAfterHospitalStatusReleaseAndUsOrder } = this.state;
    const isExternalRole = [
      USER_ROLE.PHYSICIAN,
      USER_ROLE.HOSPITAL_ADMIN,
      USER_ROLE.ORDER_AGENT,
    ].includes(userInfo.user_role);
    const isSponsoredSelected = isSponsored(values.billing_method);
    const isPromotionCodeRequired = isExternalRole && isSponsoredSelected;

    let filteredBillingMethods = billingMethods.data;
    if (!isUsTerritoryCountryCode(orderInfo?.hospital_country) && billingMethods?.data?.length) {
      filteredBillingMethods = billingMethods.data.filter((billingMethod) => billingMethod.key !== BILLING_METHODS.PATIENT_INSURANCE);
    }

    if (filteredBillingMethods.length === 1 && isSponsored(filteredBillingMethods[0].key)) {
      this.selectDefaultBillingMethodSponsoredTesting(formProps);
    }
    return (
      <div>
        {filteredBillingMethods.length && (
          <SelectBillingMethodField
            options={filteredBillingMethods}
            userInfo={userInfo}
            orderInfo={orderInfo}
            isSponsored={isSponsored(values.billing_method)}
          />
        )}

        <PatientPaymentDescription
          isPatientPayment={isPatientPayment(values.billing_method)}
          isSupportUser={userInfo.user_role === USER_ROLE.SUPPORT}
        />
        {!orderInfo.product.hide_promo_code && (
          <Field
            name="promotion_code"
            component={LabelInput}
            label="Promotion/ Contract code"
            required={isPromotionCodeRequired}
          />

        )}

        {isAfterHospitalStatusReleaseAndUsOrder && !isSponsored(values.billing_method)
          && (
            <ClinicalHistoryFields
              orderId={orderInfo.order_id}
              formProps={formProps}
              isPatientBilling={
                isPatientInsurance(values.billing_method)
                || isPatientPayment(values.billing_method)
              }
            />
          )}

        {isInstitutional(values.billing_method) && (
          <InstitutionalBillingInfo
            orderInfo={orderInfo}
            values={values}
          />
        )}

        {isPatientInsurance(values.billing_method) && (
          <PatientInsuranceBillingInfo
            orderInfo={orderInfo}
            userInfo={userInfo}
            aliasUserId={aliasUserId}
            values={values}
            onCreateInsuranceCase={userInfo.user_role === USER_ROLE.SUPPORT ? this.onCreateInsuranceCase : null}
            insuranceCaseStatus={this.state.insuranceCaseStatus}
            isLegacy={billingInfo.is_legacy}
            isAfterHospitalStatusReleaseAndUsOrder={isAfterHospitalStatusReleaseAndUsOrder}
            formProps={formProps}
          />
        )}

        {isPatientPayment(values.billing_method) && (
          <PatientPaymentBillingInfo
            userInfo={userInfo}
            billingInfo={billingInfo.data}
          />
        )}

        {isSponsored(values.billing_method) && (
          <SponsoredBillingInfo />
        )}

      </div>
    );
  }

  render() {
    const { hideModal, orderInfo, userInfo } = this.props;
    const {
      billingInfo,
      billingMethods,
      isAfterHospitalStatusReleaseAndUsOrder,
      patientHistoryQuestions,
    } = this.state;
    const isExternalRole = [
      USER_ROLE.PHYSICIAN,
      USER_ROLE.HOSPITAL_ADMIN,
      USER_ROLE.ORDER_AGENT,
    ].includes(userInfo.user_role);
    const isHidePromoCode = orderInfo.product.hide_promo_code;
    const {
      questions, error: patientHistoryError, loading: isLoading,
    } = patientHistoryQuestions;
    let error = null;
    if (billingInfo.error) {
      error = billingInfo.error;
    } else if (billingMethods.error) {
      error = billingMethods.error;
    } else if (patientHistoryError) {
      error = patientHistoryError;
    }

    const isEmptyBillingInfo = Object.keys(billingInfo.data).length === 0;
    return (
      <Formik
        validate={
          this.validatePatientHistoryQuestions(
            isLoading,
            isAfterHospitalStatusReleaseAndUsOrder,
            isExternalRole,
            isHidePromoCode,
          )
        }
        validationSchema={(
          orderInfo.skip_billing
            ? noValidationSchema
            : validationSchema(billingInfo.data.is_legacy,
              isAfterHospitalStatusReleaseAndUsOrder)
        )}
        initialValues={isEmptyBillingInfo
          ? { ...initialFormValues, questions }
          : { ...billingInfo.data, questions }}
        enableReinitialize
        onSubmit={(values, actions) => this.submit(values, actions)}
      >
        {(formProps) => (
          <Form autoComplete="off">
            <PromptIfDirty {...formProps} />
            <EditOrderStep
              {...formProps}
              hideModal={hideModal}
              error={error || formProps.errors.general}
              loading={billingInfo.loading || billingMethods.loading}
              submit={() => formProps.submitForm()}
            >
              {this.renderBody(formProps)}
            </EditOrderStep>
          </Form>
        )}
      </Formik>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTestFormBillingInfo);
