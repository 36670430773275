import React from 'react';
import { Field } from 'formik';
import Checkbox from 'components/Formik/Checkbox';

const ConsentSecondaryFindingsForm = () => (
  <div className="col-md-8 offset-md-2">
    <Field
      name="secondaryFindingsConsent"
      id="secondaryFindingsConsent"
      component={Checkbox}
      label="The patient has signed National Eye Institute’s consent form and given
      their permission to report secondary findings in ACMG59 genes."
      labelClassName="secondaryFindingsConsent"
      className="text-left"
    />
  </div>
);

export default ConsentSecondaryFindingsForm;
