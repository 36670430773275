import React from 'react';
import PropTypes from 'prop-types';

import PrintOrderSummaryFile from './PrintOrderSummaryFile';

const ViewOrderNavigation = ({ currentStep, onChangeStep, stepCount }) => (
  <div className="form-group row clearfix">
    <div className="col-md-12">
      {currentStep > 1 && (
        <span className="float-left button-group">
          <button
            className="btn btn-default"
            type="button"
            onClick={() => onChangeStep(currentStep - 1)}
          >
            Previous
          </button>
        </span>
      )}

      <span className="float-right button-group">
        <div className="col-md-12">
          <PrintOrderSummaryFile />

          {currentStep < stepCount && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => onChangeStep(currentStep + 1)}
            >
              Next
            </button>
          )}

          {currentStep === stepCount && (
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => onChangeStep(currentStep + 1)}
            >
              Save & close
            </button>
          )}
        </div>
      </span>
    </div>
  </div>
);

ViewOrderNavigation.propTypes = {
  currentStep: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  stepCount: PropTypes.number.isRequired,
};

export default ViewOrderNavigation;
