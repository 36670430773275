import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PatientHistoryQuestion from 'components/PatientHistoryQuestion';
import { Field } from 'formik';
import { questionAnswerField, isThePatientMinorQuestion } from 'utils/patientQuestions';
import RadioButtonGroup from 'components/Formik/RadioButtonGroup';
import RadioButton from 'components/Formik/RadioButton';

const PatientHistoryQuestions = ({
  questions,
  namePrefix,
  oneColumn,
  isSupportUser,
}) => {
  const isPatientMinorQuestion = isThePatientMinorQuestion(questions);
  const [isMinor, setIsMinor] = useState(false);

  useEffect(() => {
    setIsMinor(isPatientMinorQuestion?.question_options[0]?.selected);
  }, [isPatientMinorQuestion?.question_options]);

  return (
    <div>
      {questions.length > 0 && questions.map((question, index) => {
        if (question.name === 'is_patient_minor') {
          return (
            <RadioButtonGroup
              name={`${namePrefix}questions[${index + 6}].${questionAnswerField(question)}`}
              label={question.question}
              required
            >
              {question.question_options.map((option, optionIndex) => (
                <Field
                  component={RadioButton}
                  key={option.question_option_id}
                  label={option.question_option}
                  name={`${namePrefix}questions[${index + 6}].${questionAnswerField(question)}`}
                  onChange={(_, field, event) => {
                    setIsMinor(optionIndex === 0);
                    return field.onChange(event);
                  }}
                  value={option.question_option_id}
                />
              ))}
            </RadioButtonGroup>
          );
        }
        if (question.name !== 'is_patient_minor' && isMinor) {
          return (
            <PatientHistoryQuestion
              key={question.question_id}
              question={{ ...question, is_compulsory: true }}
              oneColumn={oneColumn}
              namePrefix={`${namePrefix}questions[${index + 6}]`}
              isSupportUser={isSupportUser}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

PatientHistoryQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    question_id: PropTypes.number.isRequired,
    is_hidden_by_default: PropTypes.bool,
    selected_option_id: PropTypes.string,
    question_options: PropTypes.arrayOf(PropTypes.shape({
      question_option_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      conditional_question_id: PropTypes.number,
    })),
  })).isRequired,
  namePrefix: PropTypes.string,
  oneColumn: PropTypes.bool,
  isSupportUser: PropTypes.bool,
};

PatientHistoryQuestions.defaultProps = {
  namePrefix: '',
  oneColumn: false,
  isSupportUser: false,
};

export default PatientHistoryQuestions;
