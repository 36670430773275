import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from 'components/StyleComponents/Button/PrimaryButton';

const MessageModal = ({
  hide,
  show,
  message,
  messageHeader,
  buttonCaption,
  ...props
}) => (
  <Modal {...props} show={show} onHide={hide} size="xl" aria-labelledby="contained-modal-title-sm" keyboard>
    <Modal.Header>
      {messageHeader}
    </Modal.Header>
    <Modal.Body>
      <div>{message}</div>
    </Modal.Body>
    <Modal.Footer>
      <PrimaryButton labelText={buttonCaption} onClick={hide} />
    </Modal.Footer>
  </Modal>
);

MessageModal.propTypes = {
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  messageHeader: PropTypes.string,
  buttonCaption: PropTypes.string,
};

MessageModal.defaultProps = {
  messageHeader: null,
  buttonCaption: 'Continue',
};

export default MessageModal;
