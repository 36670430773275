import React from 'react';
import PropTypes from 'prop-types';
import {
  isExome,
  isFamilyOrder,
  isFVT,
  isMSD,
  isTVT,
} from 'utils/forms';

const FetalQuestionDisclaimer = ({ orderInfo }) => {
  if (orderInfo.product.code === 'MI0101') {
    // MGT
    return (
      <p>
        We do not offer prenatal testing for specific variants identified in the
        mitochondrial genome.
      </p>
    );
  }

  if (isFVT(orderInfo.special_type)) {
    return (
      <div>
        <p>
          Prenatal samples are accepted for Familial Variant Testing only when
          the index patient has been tested at Blueprint Genetics. We do not
          offer prenatal testing for mitochondrial gene variants or copy number
          variation (CNV) variants. Single gene testing may be an alternative
          option.
        </p>

        <p>
          We do not offer a maternal cell contamination (MCC) test at the
          moment. We offer prenatal testing only for cases where the maternal
          cell contamination studies (MCC) are done by another genetic
          laboratory.
        </p>
      </div>
    );
  }

  if (isTVT(orderInfo.special_type)) {
    return (
      <p>
        We do not offer Targeted Variant Testing for prenatal samples. Single
        gene testing may be an alternative option.
      </p>
    );
  }

  if (isExome(orderInfo.special_type)) {
    if (isFamilyOrder(orderInfo)) {
      return (
        <div>
          <p>
            We do not offer prenatal testing for Whole Exome Sequencing when the
            index patient is a fetus and the pregnancy is on-going.
          </p>
          <p>
            We do not offer a maternal cell contamination (MCC) test at the
            moment. We offer prenatal testing only for cases where the maternal
            cell contamination studies (MCC) are done by another genetic
            laboratory.
          </p>
        </div>
      );
    }
    return (
      <div>
        <p>
          We do not offer prenatal testing for Whole Exome Sequencing when the
          patient is a fetus and the pregnancy is on-going.
        </p>
        <p>
          We do not offer a maternal cell contamination (MCC) test at the
          moment. We offer prenatal testing only for cases where the maternal
          cell contamination studies (MCC) are done by another genetic
          laboratory.
        </p>
      </div>
    );
  }

  if (isMSD(orderInfo.special_type)) {
    if (isFamilyOrder(orderInfo)) {
      return (
        <div>
          <p>
            We do not offer prenatal testing for the Development Disorder Panel
            when the index patient is a fetus and the pregnancy is on-going.
          </p>
          <p>
            We do not offer a maternal cell contamination (MCC) test at the
            moment. We offer prenatal testing only for cases where the maternal
            cell contamination studies (MCC) are done by another genetic
            laboratory.
          </p>
        </div>
      );
    }
    return (
      <div>
        <p>
          We do not offer prenatal testing for the Development Disorder Panel
          when the patient is a fetus and the pregnancy is on-going.
        </p>
        <p>
          We do not offer a maternal cell contamination (MCC) test at the
          moment. We offer prenatal testing only for cases where the maternal
          cell contamination studies (MCC) are done by another genetic
          laboratory.
        </p>
      </div>
    );
  }

  return (
    <p>
      We do not offer a maternal cell contamination (MCC) test at the moment. We
      offer prenatal testing only for cases where the maternal cell
      contamination studies (MCC) are done by another genetic laboratory.
    </p>
  );
};

FetalQuestionDisclaimer.propTypes = {
  // Fields
  orderInfo: PropTypes.shape({
    product: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
    special_type: PropTypes.string,
  }).isRequired,
};

export default FetalQuestionDisclaimer;
