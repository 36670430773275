import React, {Component} from 'react';
import PropTypes, { string } from 'prop-types';

import styles from './LabelSelect.scss';

export default class LabelSelect extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    onValueChange: PropTypes.func,
    stackedLabel: PropTypes.bool,
  };

  findLabel(value) {
    const option = this.props.options.find(item => item.value === value);

    return option ? option.label : '-';
  }

  render() {
    const {options, disabled, readOnly, label, labelSize, inputSize, placeholder, required, hint, value, onValueChange, stackedLabel, ...props} = this.props;
    return (
      <div className={`row form-group${stackedLabel ? ' ' + styles.stacked_label_select : ''}`}>
        <label className={'col-md-' + labelSize + ' col-form-label'}>
          {required ? label + ' *' : label}
          {hint && (<p className="small">{hint}</p>)}
        </label>
        <div className={`col-md-${inputSize} ${stackedLabel ? '' : 'no-padding-left'}`}>
          {!readOnly &&
            <div className="select">
              <span className="arrow"></span>
              <select
                className="form-control"
                value={value || ''}
                readOnly={readOnly}
                disabled={disabled}
                onChange={onValueChange ? e => onValueChange(e.target.value) : undefined}
                {...props}
              >
                {placeholder &&
                  <option value="">{placeholder}</option>
                }
                {options.map((option, index) =>
                  <option key={index} value={option.value}>{option.label}</option>
                )}
              </select>
              {props.touched && props.error && <div><p className="text-danger">{props.error}</p></div>}
            </div>
          }
          {readOnly && <span>{this.findLabel(value)}</span>}
        </div>
      </div>
    );
  }
}
