import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import RadioButtonGroup from 'components/Formik/RadioButtonGroup';
import RadioButton from 'components/Formik/RadioButton';

const indexPatientTestedAtBpgOptions = [{
  value: 'yes',
  label: 'Yes',
}, {
  value: 'no',
  label: 'No / Not known',
}];

const IndexOrderOptions = ({ onChange }) => (
  <RadioButtonGroup
    name="index_patient_tested_at_bpg"
    label="Was the index patient tested at Blueprint Genetics?"
    required
  >
    {indexPatientTestedAtBpgOptions.map(({ value, label }) => (
      <Field
        key={value}
        component={RadioButton}
        name="index_patient_tested_at_bpg"
        value={value}
        label={label}
        onChange={onChange}
      />
    ))}
  </RadioButtonGroup>
);

IndexOrderOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default IndexOrderOptions;
