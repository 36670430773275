import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Spinner from 'components/Spinner/Spinner';
import { withRouter } from 'react-router-dom';

/**
 * Determine if we can go back in history. We have to match that we are coming from the same
 * host to prevent user from leaving back to external website using canGoBack flag.
 */
function canGoBack(location) {
  return window.history.length > 1 && !!location.state?.canGoBack;
}

// Navigate to previous page by going back or going there directly
export function navigateBack(location, history, previousPageLink) {
  if (location.state && canGoBack(location)) {
    history.goBack();
  } else {
    history.push(previousPageLink);
  }
}

class Page extends Component {
  static propTypes = {
    // Layout
    cols: PropTypes.number,
    offsetCols: PropTypes.number,
    previousPageLink: PropTypes.string,
    previousPageLabel: PropTypes.string,

    // Data
    title: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    page: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.string,
    }),

    // Router
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        canGoBack: PropTypes.bool,
      }),
    }).isRequired,
  };

  static defaultProps = {
    // Layout
    cols: 6,
    offsetCols: 3,
    previousPageLink: null,
    previousPageLabel: 'previous page',

    // Data
    page: {
      loading: true,
      error: null,
    },
  };

  static renderSpinner() {
    return (
      <div className="spinner-wrapper">
        <Spinner />
      </div>
    );
  }

  render() {
    const {
      cols,
      offsetCols,
      previousPageLink,
      previousPageLabel,
      title,
      render,
      page: {
        error,
        loading,
      },
      history,
      location,
    } = this.props;

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Helmet title={title} />

            {previousPageLink && (
              <a
                href={previousPageLink}
                onClick={(e) => {
                  e.preventDefault();

                  navigateBack(location, history, previousPageLink);
                }}
              >
                {`← Back to ${previousPageLabel}`}
              </a>
            )}

            <h1>{title}</h1>
          </div>

          <div className={`col-lg-${cols} offset-lg-${offsetCols}`}>
            {error && (<p className="text-danger">{error}</p>)}

            {loading
              ? Page.renderSpinner()
              : render()}
          </div>
        </div>
      </div>
    );
  }
}

export function initPageState(custom = { data: {} }) {
  return {
    page: {
      loading: true,
      error: null,
    },
    ...custom,
  };
}

// handlePagePromise manages page state depending on whether
// promise succeeded or failed.
export function handlePagePromise(promise, page, indicatePageLoading = false) {
  if (indicatePageLoading) {
    page.setState({
      page: {
        loading: true,
        error: null,
      },
    });
  }

  return promise.then((data) => {
    page.setState({
      data,
      page: {
        loading: false,
        error: null,
      },
    });
  }).catch((e) => {
    page.setState({
      page: {
        loading: false,
        error: e.message,
      },
    });
  });
}

export default withRouter(Page);
