import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';

import LabelInput from 'components/Formik/LabelInput';
import SelectInput from 'components/Formik/SelectInput';
import {
  countriesSelector,
  loadCountries,
  countriesErrorSelector,
  countriesLoadingSelector,
} from 'redux/modules/countries';
import { loadProvinces, provincesSelector } from 'redux/modules/provinces';
import {
  isUsTerritoryCountryCode,
  sortCountries,
  isCountryUS,
  getProvinceOptions,
  validateWithContext,
} from 'utils/forms';
import {
  getAliasUsers, userHospitalNameSelector, userProvinceSelector, userCountryIdSelector,
} from 'redux/modules/auth';
import {
  createAuthorizedProvider,
  resetAddAuthorizedProvider,
  addAuthorizedProviderSelector,
} from 'redux/modules/addAuthorizedProvider';
import SecondaryButton from 'components/StyleComponents/Button/SecondaryButton';
import PrimaryButton from 'components/StyleComponents/Button/PrimaryButton';
import ErrorMessage from 'components/StyleComponents/ErrorMessage';
import SpinnerOverlay from 'components/Spinner/SpinnerOverlay';
import { US_COUNTRY_CODE } from 'helpers/constants/regionConstants';

const validationSchema = Yup.object().shape({
  full_name: Yup.string().required('Name is required'),
  title: Yup.string().required('Title is required'),
  country_id: Yup.number().required('Country is required'),
  hospital_name: Yup.string().required('Hospital name is required'),
  department: Yup.string(),
  npi: Yup.string().required('NPI is required'),
  email: Yup.string(),
  province_id: Yup.string().nullable().when(['country_id', 'hospital_name', '$currentUserHospitalName', '$countries'], {
    // eslint-disable-next-line max-params
    is: (countryId, hospitalName, currentUserHospitalName, countries) => (
      isCountryUS(countryId, countries)
      && hospitalName !== currentUserHospitalName
    ),
    then: Yup.string().required('State is required for US hospitals'),
  }),
});

const validateForm = (currentUserHospitalName, countries) => validateWithContext(
  validationSchema,
  { currentUserHospitalName, countries },
);

const getInitialValues = (usTerritories, currentUserHospitalName, currentUserProvince) => {
  const us = usTerritories.find(({ code }) => code === US_COUNTRY_CODE);
  const usId = us && us.id;

  if (!usId) { return null; }

  return {
    full_name: '',
    title: '',
    country_id: usId,
    hospital_name: currentUserHospitalName,
    department_name: '',
    npi: '',
    email: '',
    province_id: currentUserProvince,
  };
};

const AddAuthorizedProviderForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const countries = useSelector(countriesSelector);
  const countriesError = useSelector(countriesErrorSelector);
  const countriesLoading = useSelector(countriesLoadingSelector);
  const currentUserHospitalName = useSelector(userHospitalNameSelector);
  const currentUserProvince = useSelector(userProvinceSelector);
  const provinces = useSelector(provincesSelector);
  const { creating, created, creationError } = useSelector(addAuthorizedProviderSelector);
  const currentUserCountryId = useSelector(userCountryIdSelector);
  const [countryOptions, setCountryOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [selectedCountryId, setSelectedCountryId] = useState(null);

  useEffect(() => {
    dispatch(loadCountries());

    return () => {
      dispatch(resetAddAuthorizedProvider());
    };
  }, [dispatch]);

  useEffect(() => {
    const usTerritories = sortCountries(Object.values(countries))
      .filter(({ code }) => isUsTerritoryCountryCode(code));

    const options = usTerritories.map(({ id, name }) => ({ key: id, value: name }));

    setCountryOptions(options);
    setInitialValues(getInitialValues(usTerritories, currentUserHospitalName, currentUserProvince));
  }, [countries, currentUserHospitalName, currentUserProvince]);

  useEffect(() => {
    if (!created) { return; }

    dispatch(getAliasUsers());
    history.push('/');
  }, [dispatch, history, created]);

  useEffect(() => {
    const countryId = selectedCountryId || currentUserCountryId;
    if (isCountryUS(countryId, countries)) {
      dispatch(loadProvinces(countryId));
    }
  }, [dispatch, selectedCountryId, currentUserCountryId, countries]);

  const handleCancelButtonClick = () => history.push('/');

  const handleSubmit = (values) => dispatch(createAuthorizedProvider(values));

  const handleCountryChange = ({ target: { value } }) => {
    setSelectedCountryId(value);
  };

  const provinceOptions = getProvinceOptions(provinces);

  const currentCountryId = selectedCountryId || currentUserCountryId;

  if (countriesError) {
    return (
      <ErrorMessage>
        {countriesError}
      </ErrorMessage>
    );
  }

  if (countriesLoading || !initialValues) { return <SpinnerOverlay />; }

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm(currentUserHospitalName, countries)}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <Form>
          <Field
            component={LabelInput}
            label="Name"
            name="full_name"
            required
          />
          <Field
            component={LabelInput}
            label="Title"
            name="title"
            required
          />
          <Field
            name="country_id"
            component={SelectInput}
            label="Country"
            options={countryOptions}
            placeholder="Select country"
            onChange={handleCountryChange}
            required
          />

          {provinceOptions && isCountryUS(currentCountryId, countries) && (
          <Field
            label="State"
            name="province_id"
            component={SelectInput}
            options={provinceOptions}
            placeholder="Select state:"
            disabled={formProps.values.hospital_name === currentUserHospitalName}
            required
          />
          )}

          <Field
            component={LabelInput}
            label="Hospital"
            name="hospital_name"
            required
          />
          <Field
            component={LabelInput}
            label="Department"
            name="department_name"
          />
          <Field
            component={LabelInput}
            label="NPI"
            name="npi"
            required
          />
          <Field
            component={LabelInput}
            label="Email"
            name="email"
            type="email"
          />

          {creationError && (
          <ErrorMessage>
            {creationError}
          </ErrorMessage>
          )}

          <span className="float-right button-group">
            <SecondaryButton
              onClick={handleCancelButtonClick}
              labelText="Cancel"
            />
            <PrimaryButton
              type="submit"
              labelText="Add"
            />
          </span>

          {creating && <SpinnerOverlay />}
        </Form>
      )}
    </Formik>
  );
};

export default AddAuthorizedProviderForm;
