import { yupToFormErrors } from 'formik';

import FileSaver from 'file-saver';
import {
  FVT,
  TVT,
  EXOME,
  EXOME_PLUS,
  EXOME_FAMILY,
  EXOME_FAMILY_PLUS,
  FMT,
  WGS,
  SINGLE_GENE,
  BIOMARIN,
  NEI,
  SPARK,
  MSD,
  PANEL,
  PANEL_DELDUP,
  PANEL_PLUS,
  SCREENING,
} from 'helpers/constants/specialTypeConstants';
import { LATAM_COUNTRY_CODES, US_COUNTRY_CODE, US_TERRITORY_COUNTRY_CODES } from 'helpers/constants/regionConstants';

export function fmtRenderPositiveControlTextLabel(orderCreatedDate) {
  if (typeof orderCreatedDate === 'string'
    && orderCreatedDate !== ''
    && orderCreatedDate < '2019-02-23 00:00:00'
  ) {
    return 'Provide positive control';
  }

  return 'The ordering entity will provide a positive control sample';
}

// TODO: remove after redux-form is no longer used
export function getInitialValueForQuestion(questionItem) {
  if (questionItem.type === 'radio' || questionItem.type === 'select') {
    const options = questionItem.question_options || [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in options) {
      if (options[key].selected) {
        return String(options[key].question_option_id);
      }
    }
  } else if (questionItem.type === 'checkbox') {
    const options = questionItem.question_options || [];
    const checkboxInitialValues = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in options) {
      if (options[key] && options[key].selected) {
        checkboxInitialValues.push(String(options[key].question_option_id));
      }
    }
    return checkboxInitialValues;
  } else {
    return questionItem.answer;
  }

  return null;
}

// TODO: remove after redux-form is no longer used
export function sortQuestions(questions) {
  if (!questions) {
    return [];
  }
  const questionsArr = Object.keys(questions).map((key) => questions[key]);

  return questionsArr.sort((element1, element2) => (element1.order_no - element2.order_no));
}

// TODO: remove after redux-form is no longer used
export function getKeyValuePairsForQuestions(questionsArray) {
  const pairs = [];

  for (let index = 0; index < questionsArray.length; index += 1) {
    const questionItem = questionsArray[index];
    const fieldStr = String(questionItem.question_id);

    pairs.push({
      key: fieldStr,
      value: getInitialValueForQuestion(questionItem),
    });
  }

  return pairs;
}

export const isVST = (specialType) => specialType === FVT || specialType === TVT;

export const isFVT = (specialType) => specialType === FVT;

export const isTVT = (specialType) => specialType === TVT;

const exomeFamilySpecialTypes = [EXOME_FAMILY, EXOME_FAMILY_PLUS];

const singleExomeSpecialTypes = [EXOME, EXOME_PLUS];

const exomeSpecialTypes = [...singleExomeSpecialTypes, ...exomeFamilySpecialTypes];

const panelSpecialTypes = [PANEL, PANEL_DELDUP, PANEL_PLUS];

const allSupportedSpecialTypes = [
  EXOME,
  EXOME_PLUS,
  EXOME_FAMILY,
  EXOME_FAMILY_PLUS,
  FVT,
  TVT,
  FMT,
  WGS,
  SINGLE_GENE,
  BIOMARIN,
  NEI,
  SPARK,
  MSD,
];

export const isSingleGene = (specialType) => specialType === SINGLE_GENE;

export const isPanel = (specialType) => (
  specialType === null
  || panelSpecialTypes.includes(specialType)
);

export const isExome = (specialType) => exomeSpecialTypes.includes(specialType);

export const isSingleExome = (specialType) => singleExomeSpecialTypes.includes(specialType);

export const isExomeFamily = (specialType) => exomeFamilySpecialTypes.includes(specialType);

export const isFMT = (specialType) => specialType === FMT;

export const isSupportedSpecialType = (specialType) => (
  allSupportedSpecialTypes.includes(specialType)
);

export const isWGS = (specialType) => specialType === WGS;

export const isBiomarin = (specialType) => specialType === BIOMARIN;

export const isSpark = (specialType) => specialType === SPARK; // My Retina Tracker

export const isNEI = (specialType) => specialType === NEI;

export const isMSD = (specialType) => specialType === MSD; // Development Disorder Panel

export const isScreening = (specialType) => specialType === SCREENING;

export const isScreeningDuo = (productCode) => ['CS0004', 'CS0002', 'CS0006'].includes(productCode);

export const isScreeningSolo = (productCode) => ['CS0005', 'CS0007', 'CS0001', 'CS0008', 'CS0003', 'PS0003', 'PS0001', 'PS0005', 'PS0004', 'PS0002', 'PS0006'].includes(productCode);

export const showFetalSampleQuestion = (orderInfo) => orderInfo.show_fetus_question;

export const shouldShowSampleFromOngoingPregnancyQuestion = (value) => ['yes', 'index', 'family_member_one', 'family_member_two'].findIndex(
  (element) => element === value,
) !== -1;

export const isIndexOrderIdValid = (id) => {
  const parsedId = parseInt(id, 10);

  return Number.isInteger(parsedId) && parsedId > 0;
};

export const isFamilyOrder = (orderInfo) => { // TODO add this to products table
  if (!orderInfo) {
    return false;
  }

  if (orderInfo.special_type === 'exome_family_plus') {
    return true;
  }

  if (['MS0103', 'MS0203'].includes(orderInfo?.product.code)) {
    return true;
  }

  return false;
};

export const OPT_IN_OPTIONS = [
  {
    label: 'New order',
    value: 'new_order',
  },
  {
    label: 'Test shared with you',
    value: 'test_shared',
  },
  {
    label: 'Order on hold',
    value: 'order_on_hold',
  },
  {
    label: 'Sample in analysis',
    value: 'sample_in_analysis',
  },
  {
    label: 'Results ready',
    value: 'results_ready',
  },
  {
    label: 'Follow up reports',
    value: 'follow_up_reports',
  },
  {
    label: 'Sample received',
    value: 'sample_received',
  },
  {
    label: 'Sample not arrived',
    value: 'sample_not_arrived',
  },
];

export const isMitoGene = (gene) => gene.startsWith('MT-');

export function getPatientInfoLabels(specialType) {
  if (isVST(specialType)) {
    return {
      firstName: 'First name',
      lastName: 'Last name',
      dob: 'Date of Birth',
      ssn: 'Patient Identifier / Medical Record Number',
    };
  }

  if (isScreening(specialType)) {
    return {
      firstName: 'First name of individual 1',
      lastName: 'Last name of individual 1',
      dob: 'Date of Birth of individual 1',
      ssn: 'Identifier / Medical Record Number of individual 1',
    };
  }

  const defaultLabels = {
    firstName: 'Patient first name',
    lastName: 'Patient last name',
    dob: 'Patient Date of Birth',
    ssn: 'Patient Identifier / Medical Record Number',
    phone: 'Patient Phone Number',
    address: 'Patient Address',
    email: 'Patient Email',
  };

  if (isSpark(specialType)) {
    return {
      ...defaultLabels,
      zipCode: 'Patient Postal Code',
    };
  }

  return defaultLabels;
}

// Keep only fields from object 'obj' specified in the 'fields' array
export function keepOnlyFields(fields, obj) {
  return fields.reduce((acc, field) => Object.assign(acc, {
    [field]: obj[field],
  }), {});
}

// Keep only fields from object 'obj' that are not specified in the 'fields' array
export function excludeFields(fields, obj) {
  const result = { ...obj };

  fields.forEach((field) => {
    delete result[field];
  });

  return result;
}

/* eslint-disable no-underscore-dangle */
export function createInitialValues(fields) {
  const values = {};

  Object.entries(fields).forEach((pairs) => {
    if (pairs[1]._type === 'boolean') {
      values[pairs[0]] = false;
    } else {
      values[pairs[0]] = '';
    }
  });

  return values;
}

// change base64 to blob, download, work with IE 10+
export function base64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let index = 0; index < slice.length; index += 1) {
      byteNumbers[index] = slice.charCodeAt(index);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadFileFunc(file) {
  const blob = base64toBlob(file.data, file.mime_type);
  FileSaver.saveAs(blob, file.file_name);
}

export function fullPanelName(orderInfo) {
  const name = orderInfo.product.name;
  if (orderInfo.is_customized && !name.includes('FLEX')) {
    return `FLEX ${name}`;
  }

  return name;
}

export const formatFileSize = (fileSize) => {
  const megabyte = 1024 * 1024;

  if (fileSize > megabyte) {
    return `${Math.floor(fileSize / megabyte)}MB`;
  }

  return `${Math.floor(fileSize / 1024)}kB`;
};

export const sortCountries = (countries) => {
  const result = [...countries];

  result.sort((a, b) => a.name.localeCompare(b.name));

  return result;
};

export const isLatamCountryCode = (countryCode) => LATAM_COUNTRY_CODES.includes(countryCode);

export const isUsTerritoryCountryCode = (countryCode) => US_TERRITORY_COUNTRY_CODES
  .includes(countryCode);

export const isCountryUSTerritory = (countryId, countries) => {
  if (!countries.length || !countryId) {
    return false;
  }
  const match = countries.find((c) => c.id === parseInt(countryId, 10));
  return match && isUsTerritoryCountryCode(match.code);
};

export const isCountryUS = (countryId, countriesById) => {
  const country = countriesById[countryId];

  if (!country) { return false; }

  return country.code === US_COUNTRY_CODE;
};

export const validateWithContext = (yupSchema, context) => async (values) => {
  try {
    await yupSchema.validate(values, { abortEarly: false, context });
  } catch (validationError) {
    return yupToFormErrors(validationError);
  }

  return {};
};

export const authorizedProviderDescription = 'Specific state laws govern the ordering of genetic testing and determine whether a healthcare provider is authorized to order laboratory tests within that state. Physicians (MDs) are authorized providers in every US state; for allied healthcare providers, such as genetic counselors, nurses, and optometrists, licensure requirements vary by state. If you are unsure about your state’s requirements, please don’t hesitate to contact support.us@blueprintgenetics.com.';

export const getProvinceOptions = (provinces) => provinces && provinces
  .map((state) => ({
    key: state.province_id,
    value: state.name,
  }));

// Address fields for Resonate program EA0601
// For v3 and above, we require street address, city, state, and zip code
// For v2 and below, we only require patient address
export const isResonateProgramPanelV3AndAbove = (productCode, productVersion) => productCode === 'EA0601' && productVersion >= 3;
