import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class LabelCheckboxDisplay extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelSize: PropTypes.string,
    inputSize: PropTypes.string,
    options: PropTypes.array,
    hint: PropTypes.string,
    freeTextAnswer: PropTypes.string,
  };
  render() {
    const {disabled, label, hint, labelSize, inputSize, options, freeTextAnswer} = this.props;

    return (
      <div className="form-group row">
        <label className={'col-md-' + labelSize + ' col-form-label'}>
          {label}
          <p className="small">{hint}</p>
        </label>
        <div className={'col-md-' + inputSize + ' no-padding-left'}>
          {options.map(option =>
            <div key={option.question_option_id} className="form-check checkbox">
              <input
                id={option.question_option_id}
                className="form-check-input"
                type="checkbox"
                checked={option.selected}
                disabled={disabled}
                readOnly
                />
              <label htmlFor={option.question_option_id} className="form-check-label">
                {option.question_option}
              </label>
            </div>
          )}
          {freeTextAnswer &&
            <textarea className="form-control additional-textarea" value={freeTextAnswer} disabled></textarea>
          }
        </div>
      </div>
    );
  }
}
