import React from 'react';
import PropTypes from 'prop-types';

import ViewBillingInfoRow from './ViewBillingInfoRow';

import styles from '../BillingInfo.scss';

const ViewInstitutionalBillingInfo = ({ billingInfoDetails, orderInfo }) => (
  <div>
    {orderInfo.has_family_service && orderInfo.is_us_account
      && (
        <div className="form-check checkbox modal_checkbox">
          <input type="checkbox" className="form-check-input" checked={orderInfo.has_family_service} disabled />
          <label className="view_order_modal_label form-check-label">
            Family Extension Service
          </label>
        </div>
      )}
    {billingInfoDetails.add_billing_address
      && (
        <div>
          <h4 className={styles.billing_address_heading}>Billing address</h4>
          <ViewBillingInfoRow value={billingInfoDetails.contact_person} label="Contact Person" />
          <ViewBillingInfoRow value={billingInfoDetails.facility_name} label="Facility Name" />
          <ViewBillingInfoRow value={billingInfoDetails.address_line_1} label="Address 1st Line" />
          <ViewBillingInfoRow value={billingInfoDetails.address_line_2} label="Address 2nd Line" />
          <ViewBillingInfoRow value={billingInfoDetails.zip} label="Zip" />
          <ViewBillingInfoRow value={billingInfoDetails.city} label="City" />
          <ViewBillingInfoRow value={billingInfoDetails.country} label="Country" />
          <ViewBillingInfoRow value={billingInfoDetails.contact_person_email} label="Email" />
        </div>
      )}
  </div>
);

ViewInstitutionalBillingInfo.propTypes = {
  billingInfoDetails: PropTypes.shape({
    add_billing_address: PropTypes.bool,
    contact_person: PropTypes.string,
    facility_name: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    contact_person_email: PropTypes.string,
  }),
  orderInfo: PropTypes.shape({
    is_us_account: PropTypes.bool,
    has_family_service: PropTypes.bool,
    is_order_completed: PropTypes.bool,
  }).isRequired,
};

ViewInstitutionalBillingInfo.defaultProps = {
  billingInfoDetails: null,
};

export default ViewInstitutionalBillingInfo;
