import React from 'react';
import PropTypes from 'prop-types';
import classNamesBind from 'classnames/bind';
import classNames from 'classnames';
import styles from '../Users.scss';

const UserEmailCell = ({ cellData, rowData }) => {
  const { userInactive, userBlank } = rowData;

  const cx = classNamesBind.bind(styles);

  const classNamesBinded = cx({
    inactive_user: userInactive,
  }, 'common_cell');

  const semanticClassnames = classNames({
    'inactivated-user': userInactive,
    'activated-user': !userInactive,
  });

  return (
    <div className={`${classNamesBinded} ${semanticClassnames}`}>
      {cellData}
      {userBlank && (<div className={styles.blank_user_label}>Blank User</div>)}
    </div>
  );
};

UserEmailCell.propTypes = {
  cellData: PropTypes.string.isRequired,
  rowData: PropTypes.shape({
    userBlank: PropTypes.bool.isRequired,
    userInactive: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UserEmailCell;
