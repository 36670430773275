import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';

const WorkFlowsSelect = ({
  handleInputValue,
  onChangeValue,
  options,
  resultsReported,
  value,
  individualLabel,
}) => {
  let label = 'LIMS Workflow';
  let name = 'lims_workflow';
  if (individualLabel === 1) {
    label = 'LIMS Workflow individual 1';
  } else if (individualLabel === 2) {
    label = 'LIMS Workflow individual 2';
    name = 'lims_workflow_individual_2';
  }
  return (
    <LabelSelect
      inputSize="6"
      label={label}
      labelSize="6"
      name={name}
      onChange={(event) => onChangeValue(name, handleInputValue(event))}
      options={options}
      readOnly={resultsReported}
      value={value}
    />
  );
};

WorkFlowsSelect.propTypes = {
  handleInputValue: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  resultsReported: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  individualLabel: PropTypes.number,
};

WorkFlowsSelect.defaultProps = {
  individualLabel: null,
};

export default WorkFlowsSelect;
