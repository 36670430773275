const FREE_FVT_STATUS = {
  MAY_BE_ELIGIBLE: 'may_be_eligible',
  ELIGIBLE: 'eligible',
  NOT_ELIGIBLE: 'not_eligible',
};

export const getFreeFVTStatus = (order) => {
  switch (order.free_fvt_eligibility_status) {
    case FREE_FVT_STATUS.MAY_BE_ELIGIBLE:
      return 'Eligible Free FVT';
    case FREE_FVT_STATUS.ELIGIBLE:
      return 'Free FVT';
    default:
      return '';
  }
};

export const isIndexOrderEligibleForFreeFVT = (indexOrder) => !!(
  indexOrder && indexOrder.is_eligible_for_free_fvt
);

export const isFVTOrderEligibleForFreeFVT = (orderInfo) => !!(
  orderInfo
  && orderInfo.free_fvt_eligibility_status
  && orderInfo.free_fvt_eligibility_status !== FREE_FVT_STATUS.NOT_ELIGIBLE
);

/* eslint-disable eqeqeq */
export const isVariantMatch = (a, b) => (
  a.gene === b.gene
    && a.transcript == b.transcript
    && a.c_dna_change == b.c_dna_change
    && a.protein_change == b.protein_change
    && a.event == b.event
);
/* eslint-enable eqeqeq */

const containsMatchingVariant = (variants, variantToMatch) => variants
  .some((variant) => isVariantMatch(variantToMatch, variant));

const areVariantsSelected = (formVariants, indexOrderVariants) => {
  if (!indexOrderVariants) {
    return false;
  }

  return formVariants
    .some((formVariant) => containsMatchingVariant(indexOrderVariants, formVariant));
};

export const areOnlyPrimaryFindingsSelected = (formVariants, indexOrder) => {
  const areAdditionalFindingsSelected = areVariantsSelected(
    formVariants, indexOrder.additional_findings,
  );

  const areSecondaryFindingsSelected = areVariantsSelected(
    formVariants, indexOrder.secondary_findings,
  );

  const arePrimaryFindingsSelected = areVariantsSelected(
    formVariants, indexOrder.primary_findings,
  );

  const areCandidateFindingsSelected = areVariantsSelected(
    formVariants, indexOrder.candidate_findings,
  );

  return !areAdditionalFindingsSelected
    && !areSecondaryFindingsSelected
    && !areCandidateFindingsSelected
    && arePrimaryFindingsSelected;
};

export const hasIndexOrderFindings = (indexOrder) => {
  if (!indexOrder) {
    return false;
  }

  return !!(
    indexOrder.primary_findings
    || indexOrder.secondary_findings
    || indexOrder.additional_findings
    || indexOrder.candidate_findings
  );
};
