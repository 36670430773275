import React from 'react';
import PropTypes from 'prop-types';

import { LabelSelect } from 'components';

const ExomeSpecialTypeSelect = ({
  handleInputValue,
  isRequired,
  onChangeValue,
  value,
  wesSecondaryFindingsConsentOptions,
}) => (
  <LabelSelect
    inputSize="6"
    label="WES Secondary findings consent"
    labelSize="6"
    name="wes_secondary_findings_consent"
    onChange={(event) => onChangeValue('wes_secondary_findings_consent', handleInputValue(event))}
    options={wesSecondaryFindingsConsentOptions}
    placeholder="Select secondary findings consent..."
    required={isRequired}
    value={value}
  />
);

ExomeSpecialTypeSelect.propTypes = {
  wesSecondaryFindingsConsentOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  handleInputValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default ExomeSpecialTypeSelect;
