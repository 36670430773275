import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { confirmPasswordChange } from 'redux/modules/auth';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as Yup from 'yup';
import {
  Formik, Field, Form,
} from 'formik';
import LabelInput from 'components/Formik/LabelInput';

const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  confirmPasswordChange,
}, dispatch);

class ForgotPasswordForm extends Component {
  static propTypes = {
    confirmPasswordChange: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const authQuery = queryString.parse(this.props.location.search).auth;
    if (!authQuery) {
      this.props.history.push({
        pathname: '/request_password',
      });
    }
  }

  render() {
    return (
      <div>
        <h2>Forgot password?</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
            password_confirmation: '',
          }}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={(values, actions) => {
            const authQuery = queryString.parse(this.props.location.search).auth;
            const data = {
              email: values.email,
              password: values.password,
              password_repeat: values.password_confirmation,
              auth_token: authQuery,
            };
            this.props
              .confirmPasswordChange(data)
              .then(() => {
                // eslint-disable-next-line no-alert, no-restricted-globals
                alert('Password changed successfully');
                this.props.history.push({
                  pathname: '/login',
                });
              })
              .catch((e) => {
                actions.setFieldError('general', e.message);
              });
          }}
        >
          {(formProps) => (
            <Form>
              <Field
                type="email"
                placeholder="Your e-mail address"
                name="email"
                component={LabelInput}
              />

              <Field
                type="password"
                placeholder="Your password"
                name="password"
                component={LabelInput}
              />

              <Field
                type="password"
                placeholder="Confirm password"
                name="password_confirmation"
                component={LabelInput}
              />

              <div className="form-group text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  Change password
                </button>
              </div>
              {formProps.errors.general && (
                <p className="text-danger">{formProps.errors.general}</p>
              )}
            </Form>
          )}
        </Formik>
        <Link to="/login" className="float-right">
          ← Back to login
        </Link>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ForgotPasswordForm);
