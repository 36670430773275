import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  aliasUserIdSelector,
  aliasUsersSelector,
  getAliasUsers,
  setAliasUserId,
} from 'redux/modules/auth';
import { canPlaceOrderForOneself, isOrderAgent } from 'utils/orderRoles';

import styles from './AliasUserSelect.scss';

const getCurrentUserOptions = (userInfo) => (
  canPlaceOrderForOneself(userInfo)
    ? [{
      alias_user_id: 'current-user',
      name: userInfo.user_name,
    }]
    : []
);

const getLabelText = (userInfo) => (
  isOrderAgent(userInfo)
    ? 'Current Ordering Provider is'
    : 'Current alias is'
);

const AliasUserSelect = ({ userInfo }) => {
  const dispatch = useDispatch();
  const [aliasUserArr, setAliasUserArr] = useState(getCurrentUserOptions(userInfo));
  const aliasUserId = useSelector(aliasUserIdSelector);
  const aliasUsers = useSelector(aliasUsersSelector);

  useEffect(() => {
    dispatch(getAliasUsers());

    if (canPlaceOrderForOneself(userInfo)) {
      dispatch(setAliasUserId('current-user'));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!aliasUsers) { return; }

    setAliasUserArr([
      ...getCurrentUserOptions(userInfo),
      ...Object.values(aliasUsers),
    ]);
  }, [aliasUsers, userInfo]);

  const handleSelectChange = ({ target: { value } }) => dispatch(setAliasUserId(value));

  return (
    <>
      <div className={styles.alias_user_text}>{getLabelText(userInfo)}</div>
      <div className={styles.alias_user_select}>
        <div className="select">
          <span className="arrow" />
          <select
            className="form-control"
            value={aliasUserId}
            onChange={handleSelectChange}
          >
            <option
              value=""
              disabled
            >
              -
            </option>
            {aliasUserArr
              && aliasUserArr.map((option) => (
                <option
                  key={option.alias_user_id}
                  value={option.alias_user_id.toString()}
                >
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  );
};

AliasUserSelect.propTypes = {
  userInfo: PropTypes.shape({
    user_name: PropTypes.string,
    user_role: PropTypes.string,
    is_authorized_provider: PropTypes.bool,
  }).isRequired,
};

export default AliasUserSelect;
