import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class OrderReportCandidateFindings extends Component {
  static propTypes = {
    orderReport: PropTypes.object,
  }

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  }

  render() {
    const {orderReport} = this.props;
    let sortedVariantsArr;
    if (orderReport.candidate_findings) {
      const variantsArr = Object.keys(orderReport.candidate_findings).map(key => { return orderReport.candidate_findings[key]; });
      sortedVariantsArr = variantsArr.sort((element1, element2) => {
        return (element1.order_no - element2.order_no);
      });
    }
    return (
      <div>
        <h3 className="h4">{orderReport.candidate_findings_title ? orderReport.candidate_findings_title : 'Variant table: Genetic alterations'}</h3>
        <table className="table v6-variants-table">
            {sortedVariantsArr && sortedVariantsArr.map((variant, index) =>
              <tbody key={index}>
                <tr>
                  <td>
                    <span className="report-td-heading">Gene</span><br/>
                     <a className="link-gray" href={variant.gene_url} target="_blank"><span>{variant.gene}</span></a>
                  </td>
                  <td>
                    <span className="report-td-heading">Pos</span><br/>
                     <span>{variant.pos}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Transcript</span><br/>
                     <span>{variant.transcript}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Nomenclature</span><br/>
                     <span>{variant.nomenclature}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Consequence</span><br/>
                     <span>{variant.consequence}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Genotype</span><br/>
                     <span>{variant.genotype}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Classification</span><br/>
                    <span className={this.textColorClass(variant.classification.class)}>{variant.classification.value}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span className="report-td-heading">ID</span><br/>
                    {variant.dbsnp_id_url &&
                      <a className="link-gray" href={variant.dbsnp_id_url} target="_blank"><span>{variant.dbsnp_id}</span></a>
                    }
                    {!variant.dbsnp_id_url &&
                      <span>{variant.dbsnp_id}</span>
                    }
                  </td>
                  {variant.gnomad &&
                    <td>
                      <span className="report-td-heading">gnomAD</span><br/>
                       <span>{variant.gnomad}</span>
                    </td>
                  }
                  {variant.exac &&
                    <td>
                      <span className="report-td-heading">EXAC AC/AN</span><br/>
                       <span>{variant.exac}</span>
                    </td>
                  }
                  <td>
                    <span className="report-td-heading">Polyphen</span><br/>
                     <span className={this.textColorClass(variant.polyphen.class)}>{variant.polyphen.value}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Sift</span><br/>
                     <span className={this.textColorClass(variant.sift.class)}>{variant.sift.value}</span>
                  </td>
                  <td colSpan="2">
                      <span className="report-td-heading">Muttaster</span><br/>
                      <span className={this.textColorClass(variant.muttaster.class)}>{variant.muttaster.value}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a className="link-gray" href={variant.omim_url} target="_blank"><span>{variant.omim}</span></a>
                  </td>
                  <td colSpan="2">
                    <span className="report-td-heading">Phenotype</span><br/>
                     <span>{variant.phenotype}</span>
                  </td>
                  <td>
                    <span className="report-td-heading">Inheritance</span><br/>
                     <span>{variant.inheritance}</span>
                  </td>
                  <td colSpan="2">
                    <span className="report-td-heading">Comment</span><br/>
                     <span>{variant.comment}</span>
                  </td>
                </tr>
              </tbody>
            )}
        </table>
      </div>
    );
  }
}
