// App regions that have region specific handling
export const APP_REGIONS = {
  US: 'US',
  EU: 'EU',
};

export const DEFAULT_APP_REGION = APP_REGIONS.EU;

// Country codes for US and territories belonging to US
export const US_COUNTRY_CODE = 'US';

export const US_TERRITORY_COUNTRY_CODES = [
  'AS', // American Samoa
  'GU', // Guam
  'MP', // Northern Mariana Islands
  'PR', // Puerto Rico
  'US', // United States
  'VI', // U.S. Virgin Islands
];

// Latin American country codes
export const LATAM_COUNTRY_CODES = [
  'AS', // American Samoa
  'AG', // Antigua and Barbuda
  'AR', // Argentina
  'AW', // Aruba
  'BS', // Bahamas
  'BB', // Barbados
  'BZ', // Belize
  'BO', // Bolivia
  'BR', // Brazil
  'KY', // Cayman Islands
  'CL', // Chile
  'CO', // Colombia
  'CR', // Costa Rica
  'CU', // Cuba
  'DM', // Dominica
  'DO', // Dominican Republic
  'EC', // Ecuador
  'SV', // El Salvador
  'GF', // French Guiana
  'GP', // Guadeloupe
  'GU', // Guam
  'GD', // Grenada
  'GT', // Guatemala
  'GY', // Guyana
  'HT', // Haiti
  'HN', // Honduras
  'JM', // Jamaica
  'MQ', // Martinique
  'MX', // Mexico
  'NI', // Nicaragua
  'MP', // Northern Mariana Islands
  'PA', // Panama
  'PY', // Paraguay
  'PE', // Peru
  'PR', // Puerto Rico
  'BL', // Saint Barthelemy
  'KN', // Saint Kitts and Nevis
  'LC', // Saint Lucia
  'VC', // Saint Vincent and the Grenadines
  'SR', // Suriname
  'TT', // Trinidad and Tobago
  'TC', // Turks and Caicos Islands
  'UY', // Uruguay
  'VE', // Venezuela
  'VG', // British Virgin Islands
  'VI', // U.S. Virgin Islands
];
// MARL is UTC-4 at the time of release
export const RELEASE_DATE_PROD = '2023-04-01 04:00:00 UTC';
export const RELEASE_DATE = '2023-03-24 03:00:00 UTC';

export default {
  APP_REGIONS,
  DEFAULT_APP_REGION,
  US_COUNTRY_CODE,
  US_TERRITORY_COUNTRY_CODES,
  LATAM_COUNTRY_CODES,
  RELEASE_DATE_PROD,
  RELEASE_DATE,
};
