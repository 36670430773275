import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isVST } from 'utils/forms';

export default class OrderReportPatientInfo extends Component {
  static propTypes = {
    orderInfo: PropTypes.object
  }

  render() {
    const {orderInfo} = this.props;

    let patientWord = 'Patient';
    if(isVST(orderInfo.special_type)) {
      patientWord = 'Individual';
    }

    const styles = require('./_OrderReportPatientInfo.scss');

    return (
      <div>
        <div className="report-section">
          <h2>Referring healthcare professional</h2>
          <table className="table">
            <thead>
              <tr className={styles.report_heading_row}>
                <th>Referring healthcare professional</th>
                <th>Hospital</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{orderInfo.user_name}</td>
                <td>{orderInfo.hospital_name}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="report-section">
          <h2>{patientWord}</h2>
          <table className="table">
            <thead>
              <tr className={styles.report_heading_row}>
                <th>Name</th>
                <th>Date of Birth</th>
                <th>Age</th>
                <th>Sex</th>
                <th>Order ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{orderInfo.patient_last_name}, {orderInfo.patient_first_name}</td>
                <td>{orderInfo.patient_dob}</td>
                <td>{orderInfo.patient_age}</td>
                <td>{orderInfo.patient_gender}</td>
                <td>{orderInfo.order_id}</td>
              </tr>
            </tbody>
          </table>
          <table className="table">
            <thead>
              <tr className={styles.report_heading_row}>
                <th>Primary sample type</th>
                <th>Sample collection date</th>
                <th>Customer sample ID</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{orderInfo.primary_sample_type_str}</td>
                <td>{orderInfo.sample_collection_date ? orderInfo.sample_collection_date : 'N/A'}</td>
                <td>{orderInfo.customer_sample_id ? orderInfo.customer_sample_id : 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
