const GET_NOTIFICATIONS = 'nucleus/GET_NOTIFICATIONS';
const GET_NOTIFICATIONS_SUCCESS = 'nucleus/GET_NOTIFICATIONS_SUCCESS';
const GET_NOTIFICATIONS_FAIL = 'nucleus/GET_NOTIFICATIONS_FAIL';

const initialState = {
  loaded: false,
  loading: false,
};

export default function orders(state = initialState, action = {}) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        notifications: action.result,
        newNotificationsCount: action.result.filter((n) => n.is_new).length,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getNotifications() {
  return {
    types: [GET_NOTIFICATIONS, GET_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL],
    request: (client) => client.get('get_notifications').then((res) => (
      Object.values(res).sort((a, b) => a.order_no - b.order_no)
    )),
  };
}

export function readNotification(notificationId) {
  return {
    request: (client) => client.post('read_notification', {
      notification_id: notificationId,
    }),
  };
}
