import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  Formik, Form,
} from 'formik';
import Page, { initPageState, handlePagePromise } from 'components/Page';
import * as supportActions from 'redux/modules/support';
import ProductSelection from 'components/ProductSelection/ProductSelection';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getHospitalProduct: supportActions.getHospitalProduct,
  setHospitalProductVisibility: supportActions.setHospitalProductVisibility,
}, dispatch);

class EditHospitalProductVisibilityPage extends Component {
  static propTypes = {
    // Fields
    match: PropTypes.shape({
      params: PropTypes.shape({
        hospitalId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    // Methods
    getHospitalProduct: PropTypes.func.isRequired,
    setHospitalProductVisibility: PropTypes.func.isRequired,
  };

  state = initPageState({
    data: {
      products: [],
    },
    initialValues: {},
  });

  componentDidMount() {
    handlePagePromise(this.fetchData(), this);
  }

  async fetchData() {
    const { getHospitalProduct, match: { params: { hospitalId } } } = this.props;
    const products = await getHospitalProduct(hospitalId);

    this.setState({
      initialValues: products.products.reduce((acc, p) => {
        acc[p.panel_id] = p.is_visible_for_hospital;
        return acc;
      }, {}),
    });

    return products;
  }

  renderForm = () => {
    const {
      data: { products },
      initialValues,
    } = this.state;
    const {
      setHospitalProductVisibility,
      match: { params: { hospitalId } },
    } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          actions.setFieldError('general', null);

          const newProducts = Object.entries(values)
            .filter(([productId, visibility]) => (
              // Include only changed products
              initialValues[productId] !== visibility
            ))
            .map(([productId, visibility]) => ({
              product_id: Number(productId),
              is_visible_for_hospital: visibility,
            }));

          try {
            await setHospitalProductVisibility({
              products: newProducts,
              hospitalId,
            });
          } catch (e) {
            actions.setFieldError('general', e.message);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formProps) => (
          <Form>
            {formProps.errors.general && (
              <p className="text-danger">{formProps.errors.general}</p>
            )}

            <ProductSelection
              mode="editVisibility"
              products={products}
              formProps={formProps}
            />
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    const { page } = this.state;
    const { match: { params: { hospitalId } } } = this.props;

    return (
      <Page
        cols={12}
        offsetCols={0}
        previousPageLink="/hospitals"
        previousPageLabel="hospitals"
        title={`Edit Hospital ${hospitalId} Product Visibility`}
        initialData={this.initialData}
        render={this.renderForm}
        page={page}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EditHospitalProductVisibilityPage);
