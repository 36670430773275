import React from 'react';
import PropTypes from 'prop-types';

import ViewBillingInfoRow from './ViewBillingInfoRow';

const ViewPatientAddressBillingInfo = ({ billingInfoDetails }) => {
  const isLegacyBillingInfo = billingInfoDetails.is_legacy;

  return (
    <>
      {isLegacyBillingInfo
        ? <ViewBillingInfoRow value={billingInfoDetails.patient_address} label="Patient address" />
        : (
          <>
            <ViewBillingInfoRow value={billingInfoDetails.patient_address_line_1} label="Patient street address line 1" />
            <ViewBillingInfoRow value={billingInfoDetails.patient_address_line_2} label="Patient street address line 2" />
            <ViewBillingInfoRow value={billingInfoDetails.patient_city} label="Patient address city" />
            <ViewBillingInfoRow value={billingInfoDetails.patient_state} label="Patient address state" />
            <ViewBillingInfoRow value={billingInfoDetails.patient_zip} label="Patient address zip code" />
          </>
        )}
      <ViewBillingInfoRow value={billingInfoDetails.patient_phone} label="Patient phone" />
      <ViewBillingInfoRow value={billingInfoDetails.patient_email} label="Patient email" />
    </>
  );
};

ViewPatientAddressBillingInfo.propTypes = {
  billingInfoDetails: PropTypes.shape({
    is_legacy: PropTypes.bool,
    patient_address: PropTypes.string,
    patient_address_line_1: PropTypes.string,
    patient_address_line_2: PropTypes.string,
    patient_city: PropTypes.string,
    patient_state: PropTypes.string,
    patient_zip: PropTypes.string,
    patient_phone: PropTypes.string,
    patient_email: PropTypes.string,
  }),
};

ViewPatientAddressBillingInfo.defaultProps = {
  billingInfoDetails: null,
};

export default ViewPatientAddressBillingInfo;
