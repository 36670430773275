/* eslint-disable max-lines */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import {
  getOrders,
  viewOrderInfo,
  billingInfo,
  resetOrderInfo,
  resetCurrentStep,
  getOrderFile,
  listBillingInfoOrderFiles,
  listRequisitionOrderFiles,
  changeNotificationOrderId,
  submitReflex,
  performOperation,
} from 'redux/modules/orders';
import {
  aliasUserIdSelector,
  getRegionAccess,
} from 'redux/modules/auth';
import { getNotifications } from 'redux/modules/notifications';
import {
  Spinner,
  OrderStatusModal,
  InsuranceCaseDetailModal,
  InsuranceCaseEditModal,
  ReflexModal,
} from 'components';
import ActionButton from 'components/ActionButton';
import {
  resetInsuranceInfo,
  changeInsuranceStatus,
  changeInsuranceSubStatus,
  toggleOwner,
  addInsuranceItem,
  getInsuranceItems,
  getInsuranceStatus,
} from 'redux/modules/insurance';
import { downloadFileFunc } from 'utils/forms';
import * as orderRole from 'utils/orderRoles';
import { getNucleusLoginLink, isUsRegion } from 'utils/envUtils';
import { getColumnOrder, getOrderBy } from 'utils/ordersList';
import FilterSection from './FilterSection';
import OrdersTable from './OrdersTable';
import PagesSection from './PagesSection';

import styles from './OrdersList.scss';

const getPage = (query) => parseInt(query.page, 10) || 1;

const getPageSize = (role) => {
  if (role === 'geneticist' || role === 'support') {
    return 100;
  }
  return 10;
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  list: state.orders.list,
  orderCount: state.orders.orderCount,
  gettingOrders: state.orders.gettingOrders,
  gettingOrder: state.orders.gettingOrder,
  orderInfo: state.orders.orderInfo,
  billingInfoDetails: state.orders.billingInfoDetails,
  billingInfoOrderFiles: state.orders.billingInfoOrderFiles,
  requisitionOrderFiles: state.orders.requisitionOrderFiles,
  haveOrders: state.orders.haveOrders,
  haveBillingInfo: state.orders.haveBillingInfo,
  gettingBillingInfo: state.orders.gettingBillingInfo,
  error: state.orders.error,
  reportFileObject: state.orders.reportFileObject,
  archived: state.orders.archived,
  insuranceItems: state.insurance.items,
  notificationOrderId: state.orders.notificationOrderId,
  orderOffset: state.orders.orderOffset,
  reflexError: state.orders.reflexError,
  reflexResult: state.orders.reflexResult,
  aliasUserId: aliasUserIdSelector(state),
  hasRegionAccess: state.auth.hasRegionAccess,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getOrders,
  viewOrderInfo,
  billingInfo,
  resetOrderInfo,
  resetCurrentStep,
  resetInsuranceInfo,
  changeInsuranceStatus,
  changeInsuranceSubStatus,
  toggleOwner,
  addInsuranceItem,
  getInsuranceItems,
  getInsuranceStatus,
  getOrderFile,
  listBillingInfoOrderFiles,
  listRequisitionOrderFiles,
  getNotifications,
  changeNotificationOrderId,
  submitReflex,
  performOperation,
  getRegionAccess,
}, dispatch);

class OrdersList extends Component {
  static propTypes = {
    userInfo: PropTypes.shape({
      user_role: PropTypes.string,
      is_superuser: PropTypes.bool,
      hospital_name: PropTypes.string,
    }),
    list: PropTypes.arrayOf(PropTypes.shape({})),
    getOrders: PropTypes.func.isRequired,
    gettingOrders: PropTypes.bool,
    gettingOrder: PropTypes.bool,
    orderInfo: PropTypes.shape({
      order_id: PropTypes.number.isRequired,
    }),
    viewOrderInfo: PropTypes.func.isRequired,
    billingInfo: PropTypes.func.isRequired,
    billingInfoDetails: PropTypes.shape({}),
    billingInfoOrderFiles: PropTypes.shape({}),
    requisitionOrderFiles: PropTypes.shape({}),
    resetOrderInfo: PropTypes.func.isRequired,
    haveOrders: PropTypes.bool,
    haveBillingInfo: PropTypes.bool,
    gettingBillingInfo: PropTypes.bool,
    resetCurrentStep: PropTypes.func.isRequired,
    error: PropTypes.string,
    reportFileObject: PropTypes.shape({}),
    archived: PropTypes.bool,
    resetInsuranceInfo: PropTypes.func.isRequired,
    changeInsuranceStatus: PropTypes.func.isRequired,
    changeInsuranceSubStatus: PropTypes.func.isRequired,
    toggleOwner: PropTypes.func.isRequired,
    addInsuranceItem: PropTypes.func.isRequired,
    getInsuranceItems: PropTypes.func.isRequired,
    getInsuranceStatus: PropTypes.func.isRequired,
    insuranceItems: PropTypes.arrayOf(PropTypes.shape({})),
    getOrderFile: PropTypes.func.isRequired,
    listBillingInfoOrderFiles: PropTypes.func.isRequired,
    listRequisitionOrderFiles: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    changeNotificationOrderId: PropTypes.func.isRequired,
    notificationOrderId: PropTypes.string,
    orderOffset: PropTypes.shape({
      page: PropTypes.number,
    }),
    submitReflex: PropTypes.func.isRequired,
    reflexError: PropTypes.string,
    reflexResult: PropTypes.shape({
      order_id: PropTypes.number,
    }),
    performOperation: PropTypes.func.isRequired,
    orderCount: PropTypes.number,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.shape({}),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    aliasUserId: PropTypes.string.isRequired,
    getRegionAccess: PropTypes.func.isRequired,
    hasRegionAccess: PropTypes.bool,
  };

  static defaultProps = {
    userInfo: null,
    list: [],
    gettingOrders: false,
    gettingOrder: false,
    orderInfo: null,
    billingInfoDetails: null,
    billingInfoOrderFiles: null,
    requisitionOrderFiles: null,
    haveOrders: false,
    haveBillingInfo: false,
    gettingBillingInfo: false,
    error: null,
    reportFileObject: null,
    archived: false,
    insuranceItems: [],
    notificationOrderId: null,
    orderOffset: null,
    reflexError: null,
    reflexResult: null,
    orderCount: 0,
    hasRegionAccess: false,
  };

  state = {
    insuranceCaseDetailModalShown: false,
    insuranceCaseEditModalShown: false,
    orderStatusModalShown: false,
    reflexModalShown: false,
    orderId: this.props.orderInfo ? this.props.orderInfo.order_id : null,
    pageSize: 10,
  };

  componentDidMount() {
    const { userInfo } = this.props;
    const pageSize = getPageSize(userInfo.user_role);

    this.setState({
      pageSize,
    });

    this.handleGetOrders(this.props, { ...this.state, pageSize });
    this.props.getRegionAccess();
    this.props.getNotifications();
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    let willLoadOrders = false;

    try {
      if (this.props.userInfo && !prevProps.userInfo) {
        const pageSize = getPageSize(this.props.userInfo.user_role);

        this.setState({
          pageSize,
        });

        this.handleGetOrders(this.props, { ...this.state, pageSize });
        willLoadOrders = true;
        this.props.getNotifications();
      }

      if (
        (this.props.reportFileObject && !prevProps.reportFileObject)
        || (this.props.reportFileObject !== prevProps.reportFileObject)
      ) {
        downloadFileFunc(this.props.reportFileObject);
      }

      if (this.props.archived && !prevProps.archived) {
        this.handleGetOrders(this.props, this.state);
        willLoadOrders = true;
      }
      if (this.props.orderOffset && !prevProps.orderOffset) {
        const query = queryString.parse(this.props.location.search);
        const numPages = Math.ceil(this.props.orderCount / this.state.pageSize);
        const fixedPage = Math.max(Math.min((this.props.orderOffset.page + 1), numPages), 1);

        window.scrollTo(0, 0);
        query.page = fixedPage;
        this.resetPage(query);

        willLoadOrders = true;
      }

      if (!willLoadOrders && (this.props.location.search !== prevProps.location.search)) {
        // Reload orders if query parameters change
        this.handleGetOrders(this.props, this.state);
      }
    } catch (error) {
      // Catch any errors, otherwise we may end up in an infinite loop
      console.error(error); // eslint-disable-line no-console
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  componentWillUnmount() {
    this.props.changeNotificationOrderId(null);
    this.props.resetOrderInfo();
    this.props.resetCurrentStep();
  }

  prepareRequestData = (props, state) => {
    const query = queryString.parse(props.location.search);
    const data = {};

    if (query.shared) {
      data.type = 'shared';
    }

    if (state.pageSize) {
      data.offset = (getPage(query) - 1) * state.pageSize;
      data.limit = state.pageSize;
    }

    data.search_str = query.search || undefined;

    data.filter = query.filter;
    data.is_archived = query.show === 'archive';
    data.is_unchecked = query.show === 'unchecked';
    data.has_no_phifree_summary = query.has_no_phifree_summary === 'true';
    data.sample_at_bpg = query.sample_at_bpg === 'true';
    data.expand_orders = query.expand_orders === 'true';
    if (query.billingMethod) {
      data.billing_method = query.billingMethod;
    }
    if (query.status) {
      data.status = query.status;
    }
    if (query.testType) {
      data.test_type = query.testType;
    }

    const orderBy = getOrderBy(query);
    data.sort = orderBy;
    data.desc = getColumnOrder(query, orderBy) === 'desc';

    return data;
  }

  handleGetOrders = (props = this.props, state = this.state) => {
    const data = this.prepareRequestData(props, state);
    return this.props.getOrders(data);
  }

  hideInsuranceCaseDetailModal = () => {
    this.setState({
      insuranceCaseDetailModalShown: false,
    });
  }

  hideInsuranceCaseEditModal = () => {
    this.setState({
      insuranceCaseEditModalShown: false,
    });
    this.handleGetOrders();
  }

  hideOrderStatusModal = () => {
    this.setState({
      orderStatusModalShown: false,
    });
    this.handleGetOrders().then(window.location.reload());
  }

  setPage = (page) => {
    const {
      orderCount,
      location,
    } = this.props;

    const {
      pageSize,
    } = this.state;

    const numPages = Math.ceil(orderCount / pageSize);
    const fixedPage = Math.max(Math.min(page, numPages), 1);

    window.scrollTo(0, 0);
    const query = queryString.parse(location.search);
    query.page = fixedPage;
    this.resetPage(query);

    this.setState({
      pageInputValue: fixedPage,
    });
  }

  openInsuranceCaseDetailModal = (order) => {
    if (this.props.gettingOrder || this.props.gettingBillingInfo) {
      return;
    }

    const orderId = order.order_id;
    this.setState({
      insuranceCaseDetailModalShown: true,
      order,
      orderId,
    });
    this.props.resetOrderInfo();

    const data = { order_id: orderId };
    this.props.viewOrderInfo(orderId);
    this.props.billingInfo(data);
    this.props.listRequisitionOrderFiles({ order_id: orderId, type: 'requisition_attachment' });
    this.props.listBillingInfoOrderFiles({ order_id: orderId, type: 'billing_attachment' });
  }

  openInsuranceCaseEditModal = (order) => {
    if (this.props.gettingOrder) {
      return;
    }
    this.setState({
      insuranceCaseEditModalShown: true,
      order,
      orderId: order.order_id,
    });
  }

  openOrderStatusModal = (order) => {
    if (this.props.gettingOrder) {
      return;
    }
    this.setState({
      orderStatusModalShown: true,
      order,
      orderId: order.order_id,
    });
  }

  openReflexModal = (orderId) => {
    this.setState({
      reflexModalShown: true,
      orderId,
    });
    this.props.viewOrderInfo(orderId);
  }

  submitReflex = async (data) => {
    await this.props.submitReflex(data);

    if (this.props.reflexError) {
      // There was an error submitting. Keep showing the modal
      return;
    }

    this.props.history.push(`/orders/${this.props.reflexResult.order_id}/edit`);
  }

  handleOrderFVTLinkClick = async (order) => {
    const { aliasUserId } = this.props;
    const mappings = order.product_operations.find(
      (pm) => pm.operation === 'copy_index_order',
    );
    const details = mappings.details[0];

    const operationResult = await this.props.performOperation(
      order.order_id,
      details.operation_id,
      aliasUserId,
    );
    this.props.history.push(`/orders/${operationResult.order_id}/edit`);
  }

  hideReflexModal = () => {
    this.setState({
      reflexModalShown: false,
    });
  }

  setPage = (page) => {
    const {
      orderCount,
      location,
    } = this.props;

    const {
      pageSize,
    } = this.state;

    const numPages = Math.ceil(orderCount / pageSize);
    const fixedPage = Math.max(Math.min(page, numPages), 1);

    window.scrollTo(0, 0);
    const query = queryString.parse(location.search);
    query.page = fixedPage;
    this.resetPage(query);
  };

  resetPage = (query = {}, state = {}) => {
    this.props.history.push({
      pathname: '/orders',
      search: queryString.stringify(query),
      state,
    });
  }

  errorMessage = (error, searchString) => {
    if (error) {
      if (/You do not have the right to access the data in this instance/.test(error)) {
        return (
          <div className={styles.noRegionAccess}>
            <p>{error}</p>
            <a href={getNucleusLoginLink()} className="btn btn-primary">Login to EU instance</a>
          </div>
        );
      }

      return (
        <p className="text-danger">{error}</p>
      );
    }

    if (searchString) {
      return (
        <>
          <div className={`${styles.orderSearchNotFoundLargeText} order-id-not-found`}>
            We could not find any orders for &apos;
            {searchString}
            &apos;.
          </div>
          <div className={styles.orderSearchNotFoundSmallText}>
            Please check the search term or use a different one.
          </div>
        </>
      );
    }
    return (
      <>
        <div className={`${styles.orderSearchNotFoundLargeText} orders-not-found`}>
          We could not find any orders matching the selected criteria.
        </div>
        <div className={styles.orderSearchNotFoundSmallText}>
          Please remove or modify your filter selection.
        </div>
      </>
    );
  }

  render() {
    const {
      userInfo,
      orderInfo,
      billingInfoDetails,
      billingInfoOrderFiles,
      requisitionOrderFiles,
      haveBillingInfo,
      error,
      gettingOrders,
      reflexError,
      aliasUserId,
      orderCount,
      location,
      hasRegionAccess,
    } = this.props;
    const {
      insuranceCaseDetailModalShown,
      insuranceCaseEditModalShown,
      orderStatusModalShown,
      reflexModalShown,
      pageSize,
    } = this.state;

    const isHospitalAdminUser = orderRole.isHospitalAdminUser(userInfo);
    const isSupportUser = orderRole.isSupportUser(userInfo);

    const isNewTestButtonDisabled = !orderRole.canPlaceOrderForAliasOrForOneself(userInfo, aliasUserId);

    const orders = this.props.list;
    const numPages = Math.ceil(orderCount / pageSize);

    const query = queryString.parse(location.search);
    const showOrdersNotFound = orders?.length === 0;
    const showOrders = orders && userInfo && !showOrdersNotFound;
    const hideForUsNucleus = isUsRegion();

    const orderTableProps = {
      openInsuranceCaseDetailModal: this.openInsuranceCaseDetailModal,
      openInsuranceCaseEditModal: this.openInsuranceCaseEditModal,
      openOrderStatusModal: this.openOrderStatusModal,
      openReflexModal: this.openReflexModal,
      handleOrderFVTLinkClick: this.handleOrderFVTLinkClick,
      resetPage: this.resetPage,
    };

    return (
      <div className={styles.ordersPage}>
        {hasRegionAccess && (
          <div className="container">
            <div className="row">
              <Helmet title="Orders" />

              <div className="col-lg-12">
                <div className="clearfix">
                  {userInfo && (
                    isHospitalAdminUser
                      ? <h1 className="order-table-heading float-left">{`All orders from ${userInfo.hospital_name}`}</h1>
                      : <h1 className="order-table-heading float-left">Orders</h1>
                  )}

                  <ActionButton
                    to="/orders/new"
                    text="New Test"
                    id="new-test"
                    hide={hideForUsNucleus}
                    disabled={isNewTestButtonDisabled}
                  />

                  {isSupportUser && (
                  <ActionButton to="/orders/new/blank" text="New Blank Order" withMargin />
                  )}
                </div>
                <FilterSection resetPage={this.resetPage} />
              </div>
            </div>
          </div>
        )}
        <div className={styles.tableWrapper}>
          {gettingOrders && (
            <div className={styles.spinnerFrame}>
              <div className="spinner-wrapper">
                <Spinner />
              </div>
            </div>
          )}
          <div className="container">
            <div className="row">
              {(error || showOrdersNotFound) && (this.errorMessage(error, query.search))}
              { hasRegionAccess && showOrders
              && (
                <PagesSection setPage={this.setPage} numberOfPages={numPages}>
                  <OrdersTable {...orderTableProps} />
                </PagesSection>
              )}
              {insuranceCaseEditModalShown && (
                <InsuranceCaseEditModal
                  orderInfo={orderInfo}
                  show={this.state.insuranceCaseEditModalShown}
                  hide={this.hideInsuranceCaseEditModal}
                  userInfo={userInfo}
                  resetInsuranceInfo={this.props.resetInsuranceInfo}
                  changeInsuranceStatus={this.props.changeInsuranceStatus}
                  changeInsuranceSubStatus={this.props.changeInsuranceSubStatus}
                  toggleOwner={this.props.toggleOwner}
                  addInsuranceItem={this.props.addInsuranceItem}
                  getInsuranceItems={this.props.getInsuranceItems}
                  getInsuranceStatus={this.props.getInsuranceStatus}
                  order={this.state.order}
                  orderId={this.state.orderId}
                  items={this.props.insuranceItems}
                  getOrderFile={this.props.getOrderFile}
                />
              )}
              {orderInfo && haveBillingInfo && insuranceCaseDetailModalShown && (
                <InsuranceCaseDetailModal
                  orderInfo={orderInfo}
                  show={this.state.insuranceCaseDetailModalShown}
                  hide={this.hideInsuranceCaseDetailModal}
                  resetOrderInfo={this.props.resetOrderInfo}
                  resetInsuranceInfo={this.props.resetInsuranceInfo}
                  changeInsuranceStatus={this.props.changeInsuranceStatus}
                  changeInsuranceSubStatus={this.props.changeInsuranceSubStatus}
                  addInsuranceItem={this.props.addInsuranceItem}
                  getInsuranceItems={this.props.getInsuranceItems}
                  getInsuranceStatus={this.props.getInsuranceStatus}
                  order={this.state.order}
                  orderId={this.state.orderId}
                  billingInfoDetails={billingInfoDetails}
                  billingInfoOrderFiles={billingInfoOrderFiles}
                  requisitionOrderFiles={requisitionOrderFiles}
                  getOrderFile={this.props.getOrderFile}
                  listBillingInfoOrderFiles={this.props.listBillingInfoOrderFiles}
                  listRequisitionOrderFiles={this.props.listRequisitionOrderFiles}
                />
              )}
              {orderStatusModalShown && (
                <OrderStatusModal
                  show={orderStatusModalShown}
                  hide={this.hideOrderStatusModal}
                  orderId={this.state.orderId}
                  resetOrderInfo={this.props.resetOrderInfo}
                />
              )}
              {reflexModalShown && (
                <ReflexModal
                  orderId={this.state.orderId}
                  orderInfo={orderInfo}
                  submitError={reflexError}
                  onSubmitOrder={this.submitReflex}
                  onHide={this.hideReflexModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrdersList);
