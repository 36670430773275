import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setNestedObjectValues } from 'formik';

import * as orders from 'redux/modules/orders';
import Spinner from 'components/Spinner/Spinner';
import EditOrderNavigation from 'components/_CreateOrderProcess/EditOrderNavigation';
import OrderTestFormPatientInfo from 'components/_CreateOrderProcess/_OrderTestFormPatientInfo/_OrderTestFormPatientInfo';
import OrderTestFormBillingInfo from 'components/_CreateOrderProcess/_OrderTestFormBillingInfo/_OrderTestFormBillingInfo';
import OrderTestFormShareResults from 'components/_CreateOrderProcess/_OrderTestFormShareResults/_OrderTestFormShareResults';
import OrderTestFormInformedConsent from 'components/_CreateOrderProcess/_OrderTestFormInformedConsent/_OrderTestFormInformedConsent';
import OrderTestFormRequiredConsent from 'components/_CreateOrderProcess/_OrderTestFormRequiredConsent/_OrderTestFormRequiredConsent';
import OrderTestFormPatientHistory from 'components/_CreateOrderProcess/_OrderTestFormPatientHistory/_OrderTestFormPatientHistory';
import OrderTestFormConfirmOrder from 'components/_CreateOrderProcess/_OrderTestFormConfirmOrder/_OrderTestFormConfirmOrder';
import OrderTestFormOrderInfo from 'components/_CreateOrderProcess/_OrderTestFormOrderInfo/_OrderTestFormOrderInfo';
import FVTOrderTestFormOrderInfo from 'components/_CreateOrderProcess/_OrderTestFormOrderInfo/_FVTOrderTestFormOrderInfo';
import TVTOrderTestFormOrderInfo from 'components/_CreateOrderProcess/_OrderTestFormOrderInfo/_TVTOrderTestFormOrderInfo';
import OrderProgressBar from 'components/OrderProgressBar/OrderProgressBar';
import {
  FVT,
  TVT,
} from 'helpers/constants/specialTypeConstants';
import { isScreening, isVST } from 'utils/forms';
import { userInfoSelector } from 'redux/modules/auth';

function getOrderInfoComponent(orderInfo) {
  switch (orderInfo.special_type) {
    case FVT:
      return FVTOrderTestFormOrderInfo;
    case TVT:
      return TVTOrderTestFormOrderInfo;
    default:
      return OrderTestFormOrderInfo;
  }
}

function isLegacySparkProduct(productCode, panelVersionString) {
  const panelVersion = parseInt(panelVersionString, 10);

  return (productCode === 'OP3701' && panelVersion <= 3)
    || (productCode === 'OP3801' && panelVersion <= 4);
}

function isAkouos(productCode) {
  return productCode === 'EA0601';
}

export function getSteps(orderInfo = {}) {
  const showRequiredConsent = isAkouos(orderInfo.product.code)
    || (
      orderInfo.special_type === 'spark'
      && !isLegacySparkProduct(orderInfo.product.code, orderInfo.product.version)
    );

  const steps = [];
  steps.push({
    description: 'Order info',
    component: getOrderInfoComponent(orderInfo),
  });

  steps.push({
    description: 'Billing info',
    component: OrderTestFormBillingInfo,
  });

  steps.push({
    description: (isVST(orderInfo.special_type) || isScreening(orderInfo.special_type)) ? 'Identifying information' : 'Patient information',
    component: OrderTestFormPatientInfo,
  });

  steps.push({
    description: 'Clinical history',
    component: OrderTestFormPatientHistory,
  });

  steps.push({
    description: 'Informed consent',
    component: showRequiredConsent ? OrderTestFormRequiredConsent : OrderTestFormInformedConsent,
  });

  steps.push({
    description: 'Share results',
    component: OrderTestFormShareResults,
  });

  steps.push({
    description: orderInfo.is_order_completed ? 'Order summary' : 'Confirm order',
    component: OrderTestFormConfirmOrder,
  });

  return steps;
}

const EditOrderStep = ({
  children,
  dirty,
  values,
  resetForm,
  validateForm,
  setTouched,
  setFieldError,
  isSubmitting,
  isValid,
  loading,
  error,
  submit,
  hideModal,
  isFirstStep,
  isLastStep,
  extraButtons,
  isRequiredConsent,
}) => {
  const dispatch = useDispatch();
  const orderInfo = useSelector(orders.orderInfoSelector);
  const currentStep = useSelector(orders.currentStepSelector);
  const reachedStep = useSelector(orders.reachedStepSelector);
  const userInfo = useSelector(userInfoSelector);
  const {
    showPopupModal, modalToHandleStep, dontShowTilNextRefresh,
  } = useSelector(orders.consentPopupModalSelector);

  const setErrorMessage = async () => {
    if ((isAkouos(orderInfo.product.code)) && isFirstStep) {
      setFieldError('general', 'Order is not eligible for the program.');
    } else {
      setFieldError('general', 'Please recheck your information');
    }
  };

  const validate = async () => {
    // Mark all fields as touched so that errors will be shown.
    await setTouched(setNestedObjectValues(values, true), /* validate */ false);
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      setErrorMessage();
      return false;
    }

    return true;
  };

  /*
    * `currentStep === 5` - order is in informed consent section
    * `showPopupModal` - controls when the modal will popup
    * `modalToHandleStep` - consent modal pop up will handle the changing of steps
    * `nextStep` - step in the order creation where user wishes to be navigated next.
    *              will be used by consent modal pop up to handle the changing of steps.
    * `isRequiredConsent` - if the order requires consent (fe: mrt), then the consent modal popup
    *                    will not be loaded at all and changing of steps should be handled here.
    * `dontShowTilNextRefresh` - if true, the modal wont show again until the next refresh.
  */

  const setStep = (step) => {
    if ((!showPopupModal && currentStep !== 5)
      || !modalToHandleStep
      || isRequiredConsent
      || dontShowTilNextRefresh
    ) {
      dispatch(orders.changeStep(step));

      if (step > reachedStep) {
        dispatch(orders.setReachedStep(step));
      }
    }
  };

  const onChangeStep = async (step) => {
    if (currentStep === 5
      && modalToHandleStep
      && !showPopupModal
      && !isRequiredConsent
      && !dontShowTilNextRefresh) {
      dispatch(orders.consentPopupModal({ showPopupModal: true, nextStep: step }));
    }

    // Only validate the form. We need to verify that step is completed.
    const isFormValid = await validate();
    if (!isFormValid) {
      return;
    }

    if (dirty || isLastStep) {
      // Submit form only when it was modified
      await submit('step', currentStep, step);

      if (!isValid) {
        setErrorMessage();
        return;
      }
      setStep(step);
      return;
    }

    setStep(step);
  };

  const onCancel = async () => {
    // We need to reset form before canceling/deleting the order so that
    // user is not prompted with unsaved changes message.
    let message;
    if (!orderInfo.is_order_completed) {
      message = 'Are you sure you want to cancel the order? This deletes the order completely.';
    } else {
      message = 'Are you sure you want to delete the order?';
    }

    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm(message)) {
      resetForm();
      await dispatch(orders.deleteOrder(orderInfo.order_id));
      hideModal(true);
    }
  };

  const onSaveAsDraft = async () => {
    if (!await validate()) {
      return;
    }

    if (!orderInfo.is_order_completed) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (!confirm('Are you sure not to submit the order? This order will be saved as draft.')) {
        return;
      }
    }

    try {
      await submit('save');

      // We need to reset form so that user is not prompted with unsaved changes message
      // when closing the dialog.
      resetForm({ values });
    } finally {
      if (userInfo.user_role !== 'support' || (userInfo.user_role === 'support' && !orderInfo.is_order_completed)) {
        hideModal();
      }
    }
  };

  return (
    <div>
      <OrderProgressBar
        currentStep={currentStep}
        reachedStep={reachedStep}
        onStepChange={onChangeStep}
        waiting={isSubmitting}
        isOrderCompleted={orderInfo.is_order_completed}
        steps={getSteps(orderInfo).map((step, index) => ({
          key: index + 1,
          description: step.description,
        }))}
      />

      {error && (
        <div className="mb-sm">
          <span className="text-danger">{error}</span>
        </div>
      )}

      {loading && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}

      {!loading && children}

      {error && (
        <div className="form-group row">
          <div className="text-right col-md-12">
            <span className="text-danger">{error}</span>
          </div>
        </div>
      )}

      <EditOrderNavigation
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        currentStep={currentStep}
        onChangeStep={onChangeStep}
        onCancel={onCancel}
        cancelLabel={orderInfo.is_order_completed ? 'Delete order' : 'Cancel order'}
        onSaveAsDraft={onSaveAsDraft}
        saveLabel={orderInfo.is_order_completed ? 'Save' : 'Save as draft'}
        isSubmitting={isSubmitting}
        dirty={dirty}
        extraButtons={extraButtons}
      />
    </div>
  );
};

EditOrderStep.propTypes = {
  children: PropTypes.node,
  hideModal: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  dirty: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  submit: PropTypes.func.isRequired,
  isFirstStep: PropTypes.bool,
  isLastStep: PropTypes.bool,
  isRequiredConsent: PropTypes.bool,
  extraButtons: PropTypes.func,
};

EditOrderStep.defaultProps = {
  children: null,
  dirty: false,
  isSubmitting: false,
  loading: false,
  error: null,
  isFirstStep: false,
  isLastStep: false,
  isRequiredConsent: false,
  extraButtons: () => null,
};

export default EditOrderStep;
