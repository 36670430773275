import useOrderInfoCommonData from './useOrderInfoCommonData';
import useTargetVariantData from './useTargetVariantData';

const useOrderInfoWithTargetVariantData = (orderId) => {
  const {
    orderInfoDataLoading,
    orderInfoDataError,
    orderInfo,
    deliveryInfo,
    sampleTypePanelQuestions,
  } = useOrderInfoCommonData({ orderId });

  const {
    variants,
    variantsLoading,
    variantsError,
  } = useTargetVariantData(orderId);

  const loading = orderInfoDataLoading || variantsLoading;
  const error = orderInfoDataError || variantsError;

  return {
    loading,
    error,
    orderInfo,
    deliveryInfo,
    sampleTypePanelQuestions,
    variants,
  };
};

export default useOrderInfoWithTargetVariantData;
