import PropTypes from 'prop-types';

export const sampleSteps = [
  {
    value: 'waiting_for_sample',
    description: 'Waiting for sample',
  },
  {
    value: 'sample_received',
    description: 'Sample received',
  },
  {
    value: 'sample_on_hold',
    description: 'Sample on hold',
  },
  {
    value: 'sample_in_analysis',
    description: 'Sample in analysis',
  },
  {
    value: 'sample_failed',
    description: 'Sample failed',
    info: 'You will be contacted by our support',
  },
  {
    value: 'cancelled',
    description: 'Cancelled',
  },
];

export const onHoldReasonOptions = [
  {
    label: 'Internal (no email)',
    value: 'blank',
  },
  {
    label: 'Unclear Requisition Information',
    value: 'missing_requisition_info',
  },
  {
    label: 'Unclear Sample ID Information',
    value: 'missing_sample_id_info',
  },
  {
    label: 'Pending Payment Approval (no email)',
    value: 'payment_approval',
  },
  {
    label: 'Pending Patient Payment',
    value: 'pending_patient_payment',
  },
  {
    label: 'Pending Insurance',
    value: 'insurance',
  },
  {
    label: 'Waiting for sample(s)',
    value: 'waiting_for_samples',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const sampleDisposalCategoryOptions = [
  {
    label: 'No consent - 1-year storage',
    value: 'no_consent',
  },
  {
    label: '3-year storage',
    value: '3_year_storage',
  },
  {
    label: '5-year storage',
    value: '5_year_storage',
  },
  {
    label: 'Research use and long-term storage',
    value: 'research_use_and_long_term_storage',
  },
  {
    label: 'No physical sample received',
    value: 'no_physical_sample_received',
  },
  {
    label: 'No consent - immediate disposal',
    value: 'immediate_disposal',
  },
];

export const wesSecondaryFindingsConsentOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Unknown',
    value: 'unknown',
  },
];

export const testTypesForExomeFamilyOrders = [
  {
    label: 'Select test type',
    value: 'index_patient',
  },
  {
    label: 'Duo Exome',
    value: 'family_member_test_duo',
  },
  {
    label: 'Trio Exome',
    value: 'family_member_test_trio',
  },
  {
    label: 'Quadro Exome',
    value: 'family_member_test_quadro',
  },
  {
    label: 'Additional family member',
    value: 'additional_family_member',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForSingleExomeOrders = [
  {
    label: 'Select test type',
    value: 'index_patient',
  },
  {
    label: 'Single Exome',
    value: 'single_exome',
  },
  {
    label: 'Additional family member',
    value: 'additional_family_member',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForFVTOrders = [
  {
    label: 'Select test type',
    value: 'index_patient',
  },
  {
    label: 'Familial Variant Testing',
    value: 'fvt_test',
  },
  {
    label: 'VUS Clarification FVT',
    value: 'vus_clarification_fvt_test',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForSingleGeneOrders = [
  {
    label: 'Single gene',
    value: 'single_gene_test',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForPanelOrders = [
  {
    label: 'Panel',
    value: 'panel_test',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForTVTOrders = [
  {
    label: 'TVT',
    value: 'tvt_test',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

export const testTypesForInternalOrders = [
  {
    label: 'Select test type',
    value: 'index_patient',
  },
  {
    label: 'Internal order',
    value: 'internal_order',
  },
];

// TODO: https://blueprintgenetics.atlassian.net/browse/SD-8055
// index_patient is default test_type value when an order is created
// Later should be default to NULL instead.
export const defaultOrderTestType = [
  {
    label: 'Select test type',
    value: 'index_patient',
  },
];

export const formatAssayType = (assayType) => {
  switch (assayType) {
    case 'wes':
      return 'Exome';
    case 'mendelome':
      return 'Mendelome';
    default:
      return assayType;
  }
};

export const changeOrderStatusFormPropTypes = {
  orderStatus: PropTypes.shape({
    internal_status: PropTypes.string,
    reason: PropTypes.string,
    patient_name: PropTypes.string,
    patient_ssn: PropTypes.string,
    order_id: PropTypes.number,
    allowed_statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
    lims_workflow_enabled: PropTypes.bool,
    special_type: PropTypes.string,
    patient_dob_fm1: PropTypes.string,
    patient_dob: PropTypes.string,
    production_location: PropTypes.string,
    lab_id: PropTypes.string,
    primary_sample_type: PropTypes.string,
    sample_collection_date: PropTypes.string,
    customer_sample_id: PropTypes.string,
    sample_disposal_category: PropTypes.string,
    is_immediate_disposal_province: PropTypes.bool,
    wes_secondary_findings_consent: PropTypes.string,
    sample_disposal_date: PropTypes.string,
    lims_workflows_available: PropTypes.arrayOf(PropTypes.shape({})),
    lims_workflow: PropTypes.string,
    assay_type: PropTypes.string,
    additional_lims_info: PropTypes.string,
    printer_name: PropTypes.string,
    number_of_samples: PropTypes.string,
    print_nucleus_id_label: PropTypes.bool,
    test_type: PropTypes.string, // will send extra info for QBS
    status: PropTypes.string,
  }).isRequired,
  currentStatus: PropTypes.string.isRequired,
  sampleTypeOptions: PropTypes.shape({}).isRequired,
  printerTypeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeValue: PropTypes.func.isRequired,
  reprintLabId: PropTypes.func.isRequired,
  testTypeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
