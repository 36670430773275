// Blank order view form
// Details: https://blueprintgenetics.atlassian.net/browse/SD-2652
import React from 'react';
import PropTypes from 'prop-types';

const fields = [{
  label: 'Order ID',
  field: 'order_id',
}, {
  label: 'Customer email',
  field: 'user_email',
}, {
  label: 'Patient first name',
  field: 'patient_first_name',
}, {
  label: 'Patient last name',
  field: 'patient_last_name',
}, {
  label: 'Patient date of birth',
  field: 'patient_dob',
}, {
  label: 'Patient Identifier / Medical Record Number',
  field: 'patient_ssn',
}];

const ViewBlankOrderForm = ({
  order,
}) => (
  <div>
    {fields.map(({ label, field }) => (
      <div key={label} className="modal_row">
        <label className="view_order_modal_label">{label}</label>
        <span>{order[field]}</span>
      </div>
    ))}
  </div>
);

ViewBlankOrderForm.propTypes = {
  // Fields
  order: PropTypes.shape({}).isRequired,
};

export default ViewBlankOrderForm;
