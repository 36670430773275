import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { downloadOrderFile } from 'redux/modules/orders';
import { createInsuranceStatus } from 'redux/modules/insurance';
import { userInfoSelector } from 'redux/modules/auth';

import ViewBillingInfoRow from './ViewBillingInfoRow';
import ViewPatientBillingInfo from './ViewPatientBillingInfo';

import styles from '../BillingInfo.scss';

const ViewPatientInsuranceBillingInfo = ({
  billingInfoOrderFiles,
  billingInfoDetails,
  orderInfo,
}) => {
  const userInfo = useSelector(userInfoSelector);
  const dispatch = useDispatch();

  const [disabledCreateInsuranceCase, disableCreateInsuranceCase] = useState(false);
  const [
    createInsuranceCase,
    setCreateInsuranceCase,
  ] = useState(billingInfoDetails.create_insurance_case || false);

  const hasAttachedFile = Boolean(Object.keys(billingInfoOrderFiles).length);
  const isLegacyBillingInfo = billingInfoDetails.is_legacy;

  const onCreateInsuranceCaseClick = async () => {
    // eslint-disable-next-line no-alert, no-restricted-globals
    if (!window.confirm('Are you sure you want to create an insurance case?')) {
      return;
    }

    disableCreateInsuranceCase(true);

    try {
      const response = await dispatch(createInsuranceStatus(orderInfo.order_id));
      if (response) {
        setCreateInsuranceCase(false);
        disableCreateInsuranceCase(false);
      }
    } catch (error) {
      disableCreateInsuranceCase(false);
      throw error;
    }
  };

  const printFile = (fileId) => {
    dispatch(downloadOrderFile(orderInfo.order_id, fileId));
  };

  const renderOrderFiles = () => {
    const files = Object.entries(billingInfoOrderFiles);
    return files.map((file) => {
      const [fileId, fileDetails] = file;
      return (
        /*
          eslint-disable
          jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-noninteractive-element-interactions
        */
        <li className="attached-file-text" key={fileId} onClick={() => printFile(fileId)}>
          {fileDetails.file_name}
        </li>
      );
    });
  };

  return (
    <div>
      <h4 className={styles.billing_address_heading}>Insurance information</h4>
      {billingInfoOrderFiles
        && (
          <div>
            <label className="view_order_modal_label">Insurance attached file</label>
            <span>
              <ul className="list-unstyled">
                {hasAttachedFile ? renderOrderFiles() : <div>No attached file.</div>}
              </ul>
            </span>
          </div>
        )}
      <ViewBillingInfoRow value={billingInfoDetails.insurance_company} label="Insurance company" />
      <ViewBillingInfoRow value={billingInfoDetails.insurance_id_no} label="Insurance ID" />
      <ViewBillingInfoRow value={billingInfoDetails.group_no} label="Group #" />
      <ViewBillingInfoRow value={billingInfoDetails.patient_relation} label="Patient relation to policy holder" />

      {isLegacyBillingInfo
        ? <ViewBillingInfoRow value={billingInfoDetails.policy_holder_name} label="Policy holder" /> : (
          <>
            <ViewBillingInfoRow value={billingInfoDetails.policy_holder_first_name} label="Policy holder first name" />
            <ViewBillingInfoRow value={billingInfoDetails.policy_holder_last_name} label="Policy holder last name" />
          </>
        )}

      <ViewBillingInfoRow value={billingInfoDetails.policy_holder_dob} label="Policy holder Date of Birth" />
      <ViewPatientBillingInfo billingInfoDetails={billingInfoDetails} />

      {userInfo.user_role === 'support' && (
        <div>
          <label className="view_order_modal_label">
            Insurance case
          </label>
          {!createInsuranceCase && <button className="btn btn-primary" type="button" disabled={disabledCreateInsuranceCase} onClick={onCreateInsuranceCaseClick}>Create</button>}
          {createInsuranceCase && <span>Created</span>}
        </div>
      )}
    </div>
  );
};

ViewPatientInsuranceBillingInfo.propTypes = {
  billingInfoOrderFiles: PropTypes.shape({}),
  billingInfoDetails: PropTypes.shape({
    is_legacy: PropTypes.bool,
    insurance_company: PropTypes.string,
    insurance_id_no: PropTypes.string,
    group_no: PropTypes.string,
    patient_relation: PropTypes.string,
    policy_holder_name: PropTypes.string,
    policy_holder_first_name: PropTypes.string,
    policy_holder_last_name: PropTypes.string,
    policy_holder_dob: PropTypes.string,
    create_insurance_case: PropTypes.bool,
  }),
  orderInfo: PropTypes.shape({
    order_id: PropTypes.number,
  }).isRequired,
};

ViewPatientInsuranceBillingInfo.defaultProps = {
  billingInfoOrderFiles: null,
  billingInfoDetails: null,
};

export default ViewPatientInsuranceBillingInfo;
