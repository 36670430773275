import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import SelectInput from 'components/Formik/SelectInput';
import OrderInfoPropTypes from 'types/OrderInfoPropTypes';
import { USER_ROLE } from 'helpers/constants/userRoleConstants';

function SelectBillingMethodField({
  orderInfo,
  options,
  userInfo,
  isSponsored,
}) {
  const isExternalRole = [
    USER_ROLE.PHYSICIAN,
    USER_ROLE.HOSPITAL_ADMIN,
    USER_ROLE.ORDER_AGENT,
  ].includes(userInfo.user_role);
  const isHidePromeCode = orderInfo.product.hide_promo_code;
  const showExtraInput = isExternalRole && isSponsored && !isHidePromeCode;
  const extraInput = showExtraInput ? (
    <small style={{ marginTop: 16, display: 'inline-block' }}>
      Select this billing method only if you are ordering tests eligible for a
      sponsored testing program currently available in your region. You must
      input the program contract code to continue.
    </small>
  ) : null;

  return (
    <Field
      name="billing_method"
      component={SelectInput}
      label="Billing method"
      options={options}
      placeholder="Select billing method"
      extraInput={extraInput}
      required
    />
  );
}

SelectBillingMethodField.propTypes = {
  orderInfo: OrderInfoPropTypes.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
  }).isRequired,
  isSponsored: PropTypes.bool.isRequired,
};

SelectBillingMethodField.defaultProps = {
  options: [],
};

export default SelectBillingMethodField;
