import React, { useEffect, useCallback } from 'react';

import { LabelInput, LabelDateInput, LabelSelect } from 'components';
import { isExome, isMSD } from 'utils/forms';
import RadioButtonComponent from '../RadioButtonComponent/RadioButtonComponent';
import PatientInfo from '../PatientInfo/PatientInfo';

import {
  ExomeFamilyPatientDefaultProps,
  ExomeFamilyPatientPropTypes,
  ExomeFamilyPatientsDefaultProps,
  ExomeFamilyPatientsPropTypes,
} from './ExomeFamilyPatientsConstants';

const getExomeFamilyPatientProps = (isFamilyMember2, props) => {
  const { orderStatus } = props;
  const isSampleInAnalysisStatus = orderStatus.status === 'sample_in_analysis';
  const commonProps = {
    disabled: props.disabled,
    handleInputValue: props.handleInputValue,
    isImmediateDisposalProvince: props.isImmediateDisposalProvince,
    isSparkSpecialType: props.isSparkSpecialType,
    onChange: props.onChange,
    order_id: orderStatus.order_id,
    orderStatus,
    sampleDisposalCategoryOptions: props.sampleDisposalCategoryOptions,
    sampleDisposalRequired: isSampleInAnalysisStatus,
    sampleTypeOptions: props.sampleTypeOptions,
    showWorkflows: props.showWorkflows,
    special_type: orderStatus.special_type,
    wesSecondaryFindingsConsentOptions: props.wesSecondaryFindingsConsentOptions,
    wesSecondaryFindingsConsentRequired: isSampleInAnalysisStatus && (
      isMSD(orderStatus.special_type)
      || isExome(orderStatus.special_type)
    ),
    workflows: props.workflows,
  };

  if (isFamilyMember2) {
    return {
      ...commonProps,
      dob: orderStatus.patient_dob_fm2,
      lab_id: orderStatus.lab_id_fm2,
      name: orderStatus.patient_name_fm2,
      patientKey: 'fm2',
      ssn: orderStatus.patient_ssn_fm2,
      onClick: () => props.onClick(orderStatus.lab_id_fm2, orderStatus.printer_name),
    };
  }

  return {
    ...commonProps,
    dob: orderStatus.patient_dob_fm1,
    lab_id: orderStatus.lab_id_fm1,
    name: orderStatus.patient_name_fm1,
    patientKey: 'fm1',
    ssn: orderStatus.patient_ssn_fm1,
    onClick: () => props.onClick(orderStatus.lab_id_fm1, orderStatus.printer_name),
  };
};

const ExomeFamilyPatients = (props) => {
  const { orderStatus } = props;
  let fm1Props = {};
  let fm2Props = {};
  if (orderStatus.patient_dob_fm1) {
    orderStatus.primary_sample_type_fm1 = orderStatus.primary_sample_type_fm1 || 'dna';
    fm1Props = getExomeFamilyPatientProps(false, props);
    fm1Props = { ...fm1Props };
  }

  if (orderStatus.patient_dob_fm2) {
    orderStatus.primary_sample_type_fm2 = orderStatus.primary_sample_type_fm2 || 'dna';
    fm2Props = getExomeFamilyPatientProps(true, props);
    fm2Props = { ...fm2Props };
  }
  return (
    <div>
      {orderStatus.patient_dob_fm1 && (
        <ExomeFamilyPatient {...fm1Props} />
      )}
      {orderStatus.patient_dob_fm2 && (
        <ExomeFamilyPatient {...fm2Props} />
      )}
    </div>
  );
};

ExomeFamilyPatients.propTypes = ExomeFamilyPatientsPropTypes;
ExomeFamilyPatients.defaultProps = ExomeFamilyPatientsDefaultProps;

export default ExomeFamilyPatients;

const ExomeFamilyPatient = (props) => {
  const {
    onChange, patientKey, handleInputValue, isImmediateDisposalProvince,
    isSparkSpecialType, orderStatus, name, ssn, dob, sampleTypeOptions, sampleDisposalRequired,
    sampleDisposalCategoryOptions, wesSecondaryFindingsConsentRequired,
    wesSecondaryFindingsConsentOptions, workflows, onClick, disabled,
  } = props;

  const handleDisposalChange = useCallback((event) => {
    onChange(`sample_disposal_category_${patientKey}`, handleInputValue(event));
  }, [onChange, handleInputValue, patientKey]);

  const immediateDisposalEvent = {
    target: {
      value: 'immediate_disposal',
    },
  };

  useEffect(() => {
    if (isImmediateDisposalProvince
      && !isSparkSpecialType
      && !orderStatus[`sample_disposal_category_${patientKey}`]) {
      handleDisposalChange(immediateDisposalEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <PatientInfo
        name={name}
        ssn={ssn}
        dob={dob}
        order_id={props.order_id}
        lab_id={props.lab_id}
      />
      <RadioButtonComponent
        label={`Primary sample type ${patientKey.toUpperCase()}`}
        labelSize="6"
        inputSize="6"
        type="radio"
        options={sampleTypeOptions}
        name={`primary_sample_type_${patientKey}`}
        value={orderStatus[`primary_sample_type_${patientKey}`]}
        onChange={(event) => {
          onChange(`primary_sample_type_${patientKey}`, handleInputValue(event));
        }}
        disabled={disabled}
      />
      <LabelDateInput
        label={`Sample collection date ${patientKey.toUpperCase()}`}
        labelSize="6"
        inputSize="6"
        name={`sample_collection_date_${patientKey}`}
        selected={orderStatus[`sample_collection_date_${patientKey}`]}
        onChange={(date) => onChange(`sample_collection_date_${patientKey}`, date)}
        disabled={disabled}
      />
      <LabelInput
        label={`Customer sample ID ${patientKey.toUpperCase()}`}
        labelSize="6"
        inputSize="6"
        name={`customer_sample_id_${patientKey}`}
        type="text"
        value={orderStatus[`customer_sample_id_${patientKey}`]}
        onChange={(event) => onChange(`customer_sample_id_${patientKey}`, handleInputValue(event))}
        disabled={disabled}
      />
      <LabelSelect
        label={`Sample disposal category ${patientKey.toUpperCase()}`}
        placeholder="Select sample disposal category..."
        labelSize="6"
        inputSize="6"
        name={`sample_disposal_category_${patientKey}`}
        required={sampleDisposalRequired}
        options={sampleDisposalCategoryOptions}
        value={orderStatus[`sample_disposal_category_${patientKey}`]}
        onChange={(event) => handleDisposalChange(event)}
      />
      {props.special_type !== 'msd' && (
        <LabelSelect
          label={`WES Secondary findings consent ${patientKey.toUpperCase()}`}
          placeholder="Select secondary findings consent..."
          labelSize="6"
          inputSize="6"
          name={`wes_secondary_findings_consent_${patientKey}`}
          required={wesSecondaryFindingsConsentRequired}
          options={wesSecondaryFindingsConsentOptions}
          value={orderStatus[`wes_secondary_findings_consent_${patientKey}`]}
          onChange={(event) => onChange(`wes_secondary_findings_consent_${patientKey}`, handleInputValue(event))}
        />
      )}
      {props.showWorkflows
        && (
          <LabelSelect
            label={`LIMS Workflow ${patientKey.toUpperCase()}`}
            labelSize="6"
            inputSize="6"
            name={`lims_workflow_${patientKey}`}
            options={workflows}
            // disabled === resultsReported
            readOnly={disabled}
            value={orderStatus[`lims_workflow_${patientKey}`]}
            onChange={(event) => onChange(`lims_workflow_${patientKey}`, handleInputValue(event))}
          />
        )}
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={onClick}
        >
          {`Reprint Lab ID ${patientKey.toUpperCase()}`}
        </button>
      </div>
    </div>
  );
};

ExomeFamilyPatient.propTypes = ExomeFamilyPatientPropTypes;
ExomeFamilyPatient.defaultProps = ExomeFamilyPatientDefaultProps;
