export const USER_ROLE = {
  PHYSICIAN: 'physician',
  SUPPORT: 'support',
  GENETICIST: 'geneticist',
  HOSPITAL_ADMIN: 'hospital_admin',
  ORDER_AGENT: 'order_agent',
  BILLING_PERSONNEL: 'billing_personnel',
  CLINICAL_CONSULTANT: 'clinical_consultant',
};

export default USER_ROLE;
