import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Helmet from 'react-helmet';
import Isvg from 'react-inlinesvg';

import {
  logout, userInfoSelector, userSelector, isAuthenticated, shouldUseAlbAuth, albLogout,
} from 'redux/modules/auth';
import { getDeploySchedule } from 'redux/modules/orders';
import * as notificationActions from 'redux/modules/notifications';
import { EnvironmentBanner } from '@bpg/environment-banner-react';
import { DEFAULT_APP_REGION } from 'helpers/constants/regionConstants';
import {
  isUsRegion,
  getEnvironmentName,
  isDevEnvironment,
  getReleaseDate,
} from 'utils/envUtils';

import {
  NotificationDropdown,
  AdminDropdown,
  ProfileDropdown,
  AliasUserSelect,
  CheckLoginModal,
  FeatureMessageModal,
  DeployCountdown,
} from 'components';
import * as orderRoles from 'utils/orderRoles';
import usePrevious from 'hooks/usePrevious';

import styles from './App.scss';
import logo from './logo-bpg.svg';

const FETCH_DEPLOY_SCHEDULE_DELAY = 60 * 60 * 1000; // One hour

const App = ({ children }) => {
  const location = useLocation();
  const isOrdersNavActive = location.pathname !== '/diagnostic_tests';
  const [featureMessageModalShown, setFeatureMessageModalShown] = useState(false);
  const [longTermMessageModalShown, setLongTermMessageModalShown] = useState(false);
  const [usAddressChangedMessageShown, setUsAddressChangedMessageShown] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector(userSelector);
  const prevUser = usePrevious(user);
  const userInfo = useSelector(userInfoSelector);
  const newFeatureMessage = useSelector((state) => state.auth.new_feature_message);
  const prevNewFeatureMessage = usePrevious(newFeatureMessage);
  const longTermMessageForUSAndLatam = useSelector((state) => state.auth.long_term_message_for_us_and_latam);
  const prevLongTermMessage = usePrevious(longTermMessageForUSAndLatam);
  const sessionExpired = useSelector((state) => state.auth.sessionExpired);
  const deploySchedule = useSelector((state) => state.orders.deploySchedule);

  useEffect(() => {
    dispatch(getDeploySchedule());
    const timerID = setInterval(() => dispatch(getDeploySchedule()), FETCH_DEPLOY_SCHEDULE_DELAY);

    return () => {
      clearInterval(timerID);
    };
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      // User was logged in before and refreshed the page
      dispatch(notificationActions.getNotifications());
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (!user && prevUser) {
      // logout
      localStorage.removeItem('user');

      if (shouldUseAlbAuth()) {
        albLogout();
      } else {
        window.location.assign('/login');
      }
    }
  }, [user, prevUser]);

  useEffect(() => {
    if (!prevNewFeatureMessage && newFeatureMessage) {
      setFeatureMessageModalShown(true);
    }

    if (!prevLongTermMessage && longTermMessageForUSAndLatam) {
      setLongTermMessageModalShown(true);
    }
  }, [newFeatureMessage, prevNewFeatureMessage, longTermMessageForUSAndLatam, prevLongTermMessage]);

  useEffect(() => {
    const releaseDate = getReleaseDate(process.env.REACT_APP_ENV);
    if (!isDevEnvironment() && isUsRegion() && Date.now() > Date.parse(releaseDate)) {
      //  Our redux state is not persistent. Have to use local storage.
      const userNotified = localStorage.getItem('userNotified');
      if (!userNotified) {
        if (user && Date.parse(`${user.last_login} UTC`) < Date.parse(releaseDate)) {
          setUsAddressChangedMessageShown(true);
          localStorage.setItem('userNotified', 'true');
        }
      }
    }
  }, [user]);

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem('userNotified');
    dispatch(logout());
  };

  // Display countdown until the end of service break
  const shouldShowDeployCountdown = () => {
    if (deploySchedule && deploySchedule.start && deploySchedule.end) {
      const now = new Date();
      if (deploySchedule.showAfter) {
        return now > new Date(deploySchedule.showAfter)
          && now < new Date(deploySchedule.end);
      }
      return now < new Date(deploySchedule.end);
    }

    return false;
  };

  const openBpgFAQPage = () => {
    window.open('https://blueprintgenetics.com/faqs/');
  };
  // This is a temporary modal, it is not re-usable, so defining here
  const UsAddressChangedModal = () => (
    <FeatureMessageModal
      show={usAddressChangedMessageShown}
      message={`
        <h3 class="no-margin-top" style="font-weight:normal;">
          Important update: Our North American office has relocated to Marlborough, MA
        </h3>
        <p>
          Please update the address you have for us on file and send patient samples to our new address.
        </p>
        <p style="margin:0; padding-top:0; font-weight:bold;">
          Blueprint Genetics
        </p>
        <p style="margin:0; padding-top:0; font-weight:bold;">
          200 Forest Street, 2nd Floor
        </p>
        <p style="margin:0; padding-top:0; font-weight:bold;">
          Marlborough, MA 01752
        </p>
        <p style="margin:0; padding-top:0; font-weight:bold;">
          United States
        </p>
        <br />
        <br />
        <p style="float:right;"><b>By clicking &#39;Continue&#39;, I acknowledge the address change.</b></p>
      `}
      hide={() => setUsAddressChangedMessageShown(false)}
    />
  );

  return (
    <div>
      <Helmet titleTemplate="Blueprint Genetics Nucleus: %s" />
      {userInfo && !['physician', 'order_agent', 'hospital_admin'].includes(userInfo.user_role) && (
        <EnvironmentBanner
          // eslint-disable-next-line no-undef
          region={process.env.REACT_APP_REGION || DEFAULT_APP_REGION}
          // eslint-disable-next-line no-undef
          env={getEnvironmentName(process.env.REACT_APP_ENV)}
          product="Nucleus"
        />
      )}
      {shouldShowDeployCountdown() && (
        <DeployCountdown
          deployStart={deploySchedule.start}
          deployEnd={deploySchedule.end}
          isLoginPage={window.location.pathname === '/login'}
        />
      )}
      {isAuthenticated(user)
        && (
          <Navbar fixed="top" expand="xl" bg="white" className="shadow-sm">
            <div className="container">
              <Navbar.Brand className="pl-0 mr-0" as={NavLink} exact to="/">
                <div className={styles.logo}>
                  <Isvg src={logo}>
                    Can not handle svg!
                  </Isvg>
                </div>
              </Navbar.Brand>
              <div className={`${styles.notification_dropdown_xs_wrapper} d-xl-none`}>
                <Navbar.Toggle aria-controls="main-navbar" />
                <NotificationDropdown />
              </div>
              <Navbar.Collapse id="main-navbar">
                <Nav
                  className={classNames('nav', {
                    main_nav: userInfo && userInfo.user_role !== 'support',
                  })}
                >
                  <Nav.Item>
                    <Nav.Link activeClassName={isOrdersNavActive ? 'active' : ''} as={NavLink} to="/">Orders</Nav.Link>
                  </Nav.Item>
                  {!isUsRegion() && (
                  <Nav.Item>
                    <Nav.Link activeClassName={!isOrdersNavActive ? 'active' : ''} as={NavLink} to="/diagnostic_tests">Tests</Nav.Link>
                  </Nav.Item>
                  )}
                  {userInfo && userInfo.user_role === 'support' && <AdminDropdown />}
                  <Nav.Item>
                    <Nav.Link onClick={openBpgFAQPage}>Support</Nav.Link>
                  </Nav.Item>
                </Nav>
                <div className={`${styles.notification_dropdown_lg_wrapper} d-none d-xl-block ${styles.nav_right} ml-auto`}>
                  <NotificationDropdown />
                </div>
                <div className={`${styles.nav_right} float-right`}>
                  <ProfileDropdown
                    userInfo={userInfo}
                    handleLogout={handleLogout}
                  />
                </div>
              </Navbar.Collapse>
            </div>
          </Navbar>
        )}
      <div className={(isAuthenticated(user)) ? styles.appMargin : ''}>
        {(orderRoles.isSuperUser(userInfo) || orderRoles.isOrderAgent(userInfo))
          && (
            <div className="container">
              <div className={styles.alias_user_select_wrapper}>
                {userInfo && <AliasUserSelect userInfo={userInfo} />}
                {orderRoles.isOrderAgent(userInfo) && (
                  <Link
                    to="/add-authorized-provider"
                    className={styles.add_authorized_provider_button}
                  >
                    <button
                      className="btn btn-default"
                      type="button"
                    >
                      + Add Authorized Provider
                    </button>
                  </Link>
                )}
              </div>
            </div>
          )}
        {children}
      </div>

      {sessionExpired
        && <CheckLoginModal handleLogout={handleLogout} />}

      {featureMessageModalShown
        && (
          <FeatureMessageModal
            show={featureMessageModalShown}
            hide={() => setFeatureMessageModalShown(false)}
            message={newFeatureMessage}
          />
        )}
      {longTermMessageModalShown
        && (
          <FeatureMessageModal
            show={longTermMessageModalShown}
            hide={() => setLongTermMessageModalShown(false)}
            message={longTermMessageForUSAndLatam}
          />
        )}
      {
        usAddressChangedMessageShown
        && !featureMessageModalShown
        && <UsAddressChangedModal />
      }
    </div>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
