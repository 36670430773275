import React from 'react';
import PropTypes from 'prop-types';

import VariantProp from 'components/_OrderReport/_OrderReportVariantsMito/_VariantProp';

const textColorClass = (colorClass) => {
  if (colorClass === 'good') {
    return 'text-gray';
  }
  if (colorClass === 'severe') {
    return 'text-pink';
  }
  return '';
};

const OrderReportVariantsMito = ({
  orderReport,
}) => (
  <div>
    <h3 className="h4">{ orderReport.variants_mito_title ? orderReport.variants_mito_title : 'Primary mitochondrial variant table: Sequence alterations' }</h3>
    <table className="table v6-variants-table">
      {Object.keys(orderReport.variants_mito).map((key) => {
        const variant = orderReport.variants_mito[key];
        return (
          <tbody key={key}>
            <tr>
              <VariantProp
                title="Gene"
                value={variant.gene}
                isLink
                url={variant.gene_url}
              />
              <VariantProp
                title="Pos"
                value={variant.pos}
              />
              <VariantProp
                title="AF"
                value={variant.af}
              />
              <VariantProp
                title="Level"
                value={variant.level}
              />
              <VariantProp
                title="GT"
                value={variant.genotype}
              />
              <VariantProp
                title="Transcript"
                value={variant.transcript}
              />
              <VariantProp
                title="Nomenclature"
                value={variant.nomenclature}
              />
              <VariantProp
                title="Classification"
                value={variant.classification.value}
                className={textColorClass(variant.classification.class)}
              />
            </tr>
            <tr>
              <td />
              <VariantProp
                title="Consequence"
                value={variant.consequence}
              />
              <VariantProp
                title="Phenotype"
                value={variant.phenotype}
                colSpan={3}
              />
              <VariantProp
                title="Inheritance"
                value={variant.inheritance}
              />
              <VariantProp
                title="Comment"
                value={variant.comment}
                colSpan={2}
              />
            </tr>
          </tbody>
        );
      })}
    </table>
  </div>
);

OrderReportVariantsMito.propTypes = {
  orderReport: PropTypes.shape({
    variants_mito_title: PropTypes.string,
    variants_mito: PropTypes.objectOf(PropTypes.shape({
      gene: PropTypes.string,
      gene_url: PropTypes.string,
      pos: PropTypes.number,
      af: PropTypes.string,
      level: PropTypes.string,
      genotype: PropTypes.string,
      transcript: PropTypes.string,
      nomenclature: PropTypes.string,
      classification: PropTypes.shape({
        value: PropTypes.string,
        class: PropTypes.string,
      }),
      consequence: PropTypes.string,
      phenotype: PropTypes.string,
      inheritance: PropTypes.string,
      comment: PropTypes.string,
    })),
  }).isRequired,
};

export default OrderReportVariantsMito;
