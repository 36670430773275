import PropTypes from 'prop-types';

const QuestionPropTypes = {
  id: PropTypes.string,
  question_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  question: PropTypes.string,
  type: PropTypes.string,
  unit: PropTypes.string,
  allow_user_defined_unit: PropTypes.bool,
  free_text_option_question: PropTypes.string,
  hint: PropTypes.string,
  is_common_question: PropTypes.bool,
  default_order_no: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  default_is_compulsory: PropTypes.bool,
  is_hidden_by_default: PropTypes.bool,
  selected_option_id: PropTypes.string,
  question_options: PropTypes.arrayOf(
    PropTypes.shape({
      question_option_id: PropTypes.string.isRequired,
      question_option: PropTypes.string.isRequired,
      conditional_question_id: PropTypes.number,
    }),
  ),
};

export default QuestionPropTypes;
