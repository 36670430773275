import React from 'react';
import PropTypes from 'prop-types';

const ViewBillingInfoRow = ({ value, label }) => (
  <>
    <label className="view_order_modal_label">{label}</label>
    <span>{value || 'N/A'}</span>
  </>
);

ViewBillingInfoRow.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

ViewBillingInfoRow.defaultProps = {
  value: null,
  label: null,
};

export default ViewBillingInfoRow;
