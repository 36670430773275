import PropTypes from 'prop-types';

export const OrderStatusModalPropTypes = {
  changeOrderStatus: PropTypes.func.isRequired,
  changingOrderStatus: PropTypes.bool.isRequired,
  error: PropTypes.string,
  getOrderStatus: PropTypes.func.isRequired,
  hasReqAttachment: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  isOrderStatusChanged: PropTypes.bool.isRequired,
  isOrderStatusSet: PropTypes.bool.isRequired,
  listRequisitionOrderFiles: PropTypes.func.isRequired,
  orderId: PropTypes.number,
  orderStatus: PropTypes.shape({
    status: PropTypes.string,
    special_type: PropTypes.string,
  }),
  printerNames: PropTypes.shape({}),
  removeLabId: PropTypes.func,
  resetOrderInfo: PropTypes.func.isRequired,
  sampleTypeOptions: PropTypes.shape({}),
  settingOrderStatus: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  // From redux
  userInfo: PropTypes.shape({
    user_role: PropTypes.string,
  }),
};

export const OrderStatusModalDefaultProps = {
  error: null,
  hasReqAttachment: null,
  orderId: null,
  orderStatus: null,
  printerNames: null,
  removeLabId: null,
  sampleTypeOptions: null,
  userInfo: null,
};
