import React, { Component } from "react";
import PropTypes from "prop-types";

export default class OrderReportPerformanceMetricsExome extends Component {
  static propTypes = {
    title: PropTypes.string,
    performanceMetricsExome: PropTypes.object,
    reportVersion: PropTypes.number,
  };

  getPercent(performanceMetricsExome, isMito) {
    if (isMito) {
      return performanceMetricsExome.percent_1000
        ? performanceMetricsExome.percent_1000
        : performanceMetricsExome.percent_over_cutoff;
    } else {
      return performanceMetricsExome.percent_20
        ? performanceMetricsExome.percent_20
        : performanceMetricsExome.percent_over_cutoff;
    }
  }

  render() {
    const { title, performanceMetricsExome, reportVersion, isMito } =
      this.props;
    const styles = require("./_OrderReportPerformanceMetricsExome.scss");

    return (
      <div>
        <h3 className="h4">{title}</h3>
        {reportVersion <= 6 && (
          <table className="table">
            <thead>
              <tr className={styles.report_heading_row}>
                <th>{"Median coverage"}</th>
                <th>{"Mean coverage"}</th>
                <th>{"Percent >= 10X"}</th>
                <th>{"Percent >= 15X"}</th>
                <th>{"Percent >= 20X"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{performanceMetricsExome.median_coverage}</td>
                <td>{performanceMetricsExome.mean_coverage}</td>
                <td>{performanceMetricsExome.percent_10}</td>
                <td>{performanceMetricsExome.percent_15}</td>
                <td>{performanceMetricsExome.percent_20}</td>
              </tr>
            </tbody>
          </table>
        )}
        {reportVersion > 6 && (
          <table className="table">
            <thead>
              <tr className={styles.report_heading_row}>
                <th>{"Sample"}</th>
                <th>{"Median coverage"}</th>
                <th>{isMito ? "Percent > 1000X" : "Percent >= 20X"}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(performanceMetricsExome).map((key) => (
                <tr key={key}>
                  <td>{performanceMetricsExome[key].sample}</td>
                  <td>{performanceMetricsExome[key].median_coverage}</td>
                  <td>
                    {this.getPercent(performanceMetricsExome[key], isMito)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}
