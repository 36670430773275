export const ORDER_STATUSES = {
  DRAFT: 'draft',
  WAITING_FOR_SAMPLE: 'waiting_for_sample',
  SAMPLE_RECEIVED: 'sample_received',
  SAMPLE_ON_HOLD: 'sample_on_hold',
  SAMPLE_IN_ANALYSIS: 'sample_in_analysis',
  WAITING_FOR_STATEMENT: 'waiting_for_statement',
  ANALYSIS_SUCCESFUL: 'completed_successfully',
  SAMPLE_FAILED: 'sample_failed',
  CANCELLED: 'cancelled',
};

export const ORDER_STATUS_OPTIONS = [
  {
    value: 'All',
    label: '- All - ',
  },
  {
    value: ORDER_STATUSES.DRAFT,
    label: 'Draft',
  },
  {
    value: ORDER_STATUSES.WAITING_FOR_SAMPLE,
    label: 'Waiting for sample',
  },
  {
    value: ORDER_STATUSES.SAMPLE_RECEIVED,
    label: 'Sample received',
  },
  {
    value: ORDER_STATUSES.SAMPLE_ON_HOLD,
    label: 'Sample on hold',
  },
  {
    value: ORDER_STATUSES.SAMPLE_IN_ANALYSIS,
    label: 'Sample in analysis',
  },
  {
    value: ORDER_STATUSES.ANALYSIS_SUCCESFUL,
    label: 'Analysis successful',
  },
  {
    value: ORDER_STATUSES.SAMPLE_FAILED,
    label: 'Sample failed',
  },
  {
    value: ORDER_STATUSES.CANCELLED,
    label: 'Cancelled',
  },
];

export default ORDER_STATUSES;
