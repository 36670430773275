import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import {
  Yup, requiredString, when,
} from 'components/Formik/validation';
import Checkbox, { defaultOnChange as defaultCheckboxOnChange } from 'components/Formik/Checkbox';
import DeliveryAddress from './DeliveryAddress';
import FaxItem from './FaxItem';

const mailEntrySchema = Yup.object().shape({
  first_name: requiredString(),
  last_name: requiredString(),
  organization: requiredString(),
  address_line_1: requiredString(),
  address_line_2: Yup.string().ensure(),
  zip: requiredString(),
  city: requiredString(),
  country: requiredString(),
});

const faxEntrySchema = Yup.object().shape({
  fax_number: requiredString(),
  recipient_name: requiredString(),
});

/**
 * DeliveryInfo validation schema.
 * 'user_role' must be set for this validation to run.
 */
export const deliveryInfoSchema = Yup.object().shape({
  mail_subscription: Yup.boolean(),
  mail_list: when('user_role', (userRole) => userRole !== 'support', Yup.array().of(mailEntrySchema)),
  fax_subscription: Yup.boolean(),
  fax_list: when('user_role', (userRole) => userRole !== 'support', Yup.array().of(faxEntrySchema)),
});

export function defaultMailEntry() {
  return Object.keys(mailEntrySchema.fields).reduce((values, field) => Object.assign(values, {
    [field]: '',
  }), {
    // id is used to locally identify the mail entry until it is saved on the backend
    id: Math.round(Math.random() * 1000000),
  });
}

export function defaultFaxEntry() {
  return Object.keys(faxEntrySchema.fields).reduce((values, field) => Object.assign(values, {
    [field]: '',
  }), {
    // id is used to locally identify the fax entry until it is saved on the backend
    id: Math.round(Math.random() * 1000000),
  });
}

export default class DeliveryInfo extends Component {
  static propTypes = {
    skipFields: PropTypes.shape({
      mail_delivery: PropTypes.bool,
      fax_delivery: PropTypes.bool,
    }).isRequired,
    values: PropTypes.shape({
      mail_list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      mail_subscription: PropTypes.bool.isRequired,
      fax_list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      fax_subscription: PropTypes.bool.isRequired,
      user_role: PropTypes.string,
    }).isRequired,
    formProps: PropTypes.shape({
      setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
  };

  renderMailDelivery() {
    const {
      values,
      formProps,
    } = this.props;
    const mailList = values.mail_list;
    const mailSubscription = values.mail_subscription;
    const isSupport = values.user_role === 'support';

    return (
      <FieldArray
        name="delivery_info.mail_list"
        render={(arrayHelpers) => (
          <div>
            <Field
              name="delivery_info.mail_subscription"
              component={Checkbox}
              disabled={mailList.length > 0}
              label={(
                <span>
                  Delivery of result by regular mail
                  {mailList.length > 0 && (
                  <p className="small">
                    Remove all addresses if you want to uncheck this option.
                  </p>
                  )}
                </span>
              )}
              onChange={(form, field) => {
                defaultCheckboxOnChange(form, field);

                // Add first address when checked
                if (!field.value && !mailList.length) {
                  arrayHelpers.push(defaultMailEntry());
                }
              }}
              labelClassName="view_order_modal_label"
              className="modal_checkbox"
            />

            {mailList.map((item, index) => (
              <DeliveryAddress
                key={item.id}
                prefix={`delivery_info.mail_list[${index}].`}
                isSupport={isSupport}
                onRemove={() => {
                  if (mailList.length === 1) {
                    // If removing last item uncheck checkbox
                    formProps.setFieldValue('delivery_info.mail_subscription', false, /* validate */ false);
                  }

                  arrayHelpers.remove(index);
                }}
              />
            ))}

            {!!mailSubscription && (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => arrayHelpers.push(defaultMailEntry())}
                >
                  Add address
                </button>
              </div>
            )}
          </div>
        )}
      />
    );
  }

  renderFaxDelivery() {
    const {
      values,
      formProps,
    } = this.props;
    const faxList = values.fax_list;
    const faxSubscription = values.fax_subscription;
    const isSupport = values.user_role === 'support';

    return (
      <FieldArray
        name="delivery_info.fax_list"
        render={(arrayHelpers) => (
          <div>
            <Field
              name="delivery_info.fax_subscription"
              component={Checkbox}
              disabled={faxList.length > 0}
              label={(
                <span>
                  Delivery of result by fax
                  {faxList.length > 0 && (
                  <p className="small">
                    Remove all fax numbers if you want to uncheck this option.
                  </p>
                  )}
                </span>
              )}
              onChange={(form, field) => {
                form.setFieldValue(field.name, !field.value);
                // Add first address when checked
                if (!field.value && !faxList.length) {
                  arrayHelpers.push(defaultFaxEntry());
                }
              }}
              labelClassName="view_order_modal_label"
              className="modal_checkbox"
            />

            {faxList.map((item, index) => (
              <FaxItem
                key={item.id}
                prefix={`delivery_info.fax_list[${index}].`}
                isSupport={isSupport}
                onRemove={() => {
                  if (faxList.length === 1) {
                    // If removing last item uncheck checkbox
                    formProps.setFieldValue('delivery_info.fax_subscription', false, /* validate */ false);
                  }

                  arrayHelpers.remove(index);
                }}
              />
            ))}

            {!!faxSubscription && (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => arrayHelpers.push(defaultFaxEntry())}
                >
                  Add fax number
                </button>
              </div>
            )}

          </div>
        )}
      />
    );
  }

  render() {
    const { skipFields } = this.props;

    return (
      <div>
        {!skipFields.mail_delivery && this.renderMailDelivery()}
        {!skipFields.fax_delivery && this.renderFaxDelivery()}
      </div>
    );
  }
}
