import { SESSION_EXPIRED } from 'redux/modules/auth';

function handleError(dispatch, cb) {
  return (error) => {
    // eslint-disable-next-line no-console
    console.error('Nucleus request error:', error);

    let stringError;
    if (error && error.message) {
      stringError = error.message;
    } else {
      stringError = 'There was a problem with the request, please try again later';
    }

    if (
      stringError.includes('Check login')
      || stringError.includes('User session has expired')
    ) {
      // Notify about expired session
      dispatch({
        type: SESSION_EXPIRED,
      });
    }

    cb(stringError);
  };
}

function noDispatchRequest(dispatch, client, request) {
  return request(client, dispatch).catch(
    handleError(dispatch, (error) => {
      throw new Error(error);
    }),
  );
}

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { request, types, ...rest } = action;
    if (!request) {
      return next(action);
    }

    // If there are no types present then do not dispatch to Redux store
    if (!types) {
      return noDispatchRequest(dispatch, client, request);
    }

    // Handle sending HTTP requests
    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    // Send request directly to Nucleus backend
    return request(client)
      .then((result) => next({ ...rest, result, type: SUCCESS }))
      .catch(
        handleError(dispatch, (error) => {
          next({ ...rest, error, type: FAILURE });
        }),
      );
  };
}
