import React from 'react';
import { withRouter } from 'react-router-dom';

const AboutNucleus = () => (
  <div className="container">
    <div className="row">
      <div className="col-md-5">
        <h1 className="no-margin-top">
          Welcome to the new and enhanced Nucleus!
        </h1>
      </div>
      <div className="col-md-7">
        We are happy to launch our new and updated version of Nucleus.
        We have both updated the features of the ordering system. All your previous
        orders and profile information are found here, and the basic ordering function is the same.
        Please find out more about the new features below.
      </div>
    </div>

    <div className="row">
      <div className="col-md-5">
        <h2 className="text-pink">New features</h2>
        <ul>
          <li className="text-pink">
            New look
            {' '}
            <span className="text-gray-dark">– fresh and bright visual appearance, with graphics and icons to guide along the way</span>
          </li>
          <li className="text-pink">
            Archiving function
            {' '}
            <span className="text-gray-dark">– provides possibility to archive previous orders, to make your personal view of ordered tests shorter and more clear</span>
          </li>
          <li className="text-pink">
            Progress bar
            {' '}
            <span className="text-gray-dark">presents the stage of analysis for each order</span>
          </li>
          <li className="text-pink">
            Notification center
            {' '}
            <span className="text-gray-dark">that informs when:</span>
            <ul className="text-gray-dark">
              <li>a report is ready</li>
              <li>there is an updated/follow up report available</li>
              <li>a sample is on hold</li>
            </ul>
          </li>
          <li className="text-pink">
            Support
            {' '}
            <span className="text-gray-dark">- FAQ’s on Nucleus and other ordering-related issues</span>
          </li>
        </ul>
      </div>
    </div>
    <div className="row">
      <div className="col-md-11">
        <div className="h2">We hope you find these new features useful and look forward to any feedback you would like to share with us!</div>
        <div className="welcome-msg-signature h5 text-right">- Blueprint Genetics</div>
      </div>
    </div>
  </div>
);

export default withRouter(AboutNucleus);
