import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'components';

import styles from './QuestionOptions.scss';

const OptionPropTypes = {
  question_option_id: PropTypes.string.isRequired,
  question_option: PropTypes.string.isRequired,
  conditional_question_id: PropTypes.number,
};

const QuestionOption = ({ handleMove, handleEdit, option }) => {
  const moveUp = true;

  return (
    <div className={styles.container}>
      <div data-option-text-id={option.order_no} className={styles.question_option}>
        {option.question_option}
      </div>
      <div>
        <SecondaryButton
          labelText="Move up"
          data-option-id={option.question_option_id}
          onClick={() => handleMove(option.question_option_id, moveUp)}
        />
      </div>
      <div>
        <SecondaryButton
          labelText="Move down"
          data-option-id={option.question_option_id}
          onClick={() => handleMove(option.question_option_id)}
        />
      </div>
      <div>
        <button
          type="button"
          className="btn btn-link link-pink"
          data-option-id={option.question_option_id}
          onClick={() => handleEdit(option)}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

QuestionOption.propTypes = {
  handleMove: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  option: PropTypes.shape(OptionPropTypes).isRequired,
};

export default QuestionOption;
