import React from 'react';
import PropTypes from 'prop-types';

const FileItem = ({
  fileName,
  onClick,
  onConfirmDelete,
  virusScanStatus,
}) => {
  const handleRemoveIconClick = (event) => {
    event.stopPropagation();

    // eslint-disable-next-line no-alert, no-restricted-globals
    if (confirm('Are you sure you want to delete the attachment?')) {
      onConfirmDelete();
    }
  };

  return (
    /*
      eslint-disable
      jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions,
      jsx-a11y/no-noninteractive-element-interactions
    */
    <li
      className="attached-file-text"
      onClick={onClick}
    >
      {fileName}
      {' '}
      Virus scan status:
      {' '}
      {virusScanStatus || 'unknown'}
      <span
        className="remove-icon"
        onClick={handleRemoveIconClick}
      >
        x
      </span>
    </li>
    /*
      eslint-enable
      jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions,
      jsx-a11y/no-noninteractive-element-interactions
    */
  );
};

FileItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  virusScanStatus: PropTypes.string.isRequired,
};

export default FileItem;
