import { combineReducers } from 'redux';

import {
  ORDER_ATTACHMENT_TYPE,
  REQUISITION_ATTACHMENT_TYPE,
  BILLING_ATTACHMENT_TYPE,
  OTHER_ATTACHMENT_TYPE,
} from 'helpers/constants/fileTypeConstants';

const listFilesType = (attachmentType) => `nucleus/${attachmentType}/LIST_FILES`;
const listFilesSuccessType = (attachmentType) => `nucleus/${attachmentType}/LIST_FILES_SUCCESS`;
const listFilesFailType = (attachmentType) => `nucleus/${attachmentType}/LIST_FILES_FAIL`;

const addFileType = (attachmentType) => `nucleus/${attachmentType}/ADD_FILE`;
const addFileSuccessType = (attachmentType) => `nucleus/${attachmentType}/ADD_FILE_SUCCESS`;
const addFileFailType = (attachmentType) => `nucleus/${attachmentType}/ADD_FILE_FAIL`;

const deleteFileType = (attachmentType) => `nucleus/${attachmentType}/DELETE_FILE`;
const deleteFileSuccessType = (attachmentType) => `nucleus/${attachmentType}/DELETE_FILE_SUCCESS`;
const deleteFileFailType = (attachmentType) => `nucleus/${attachmentType}/DELETE_FILE_FAIL`;

const resetFilesType = (attachmentType) => `nucleus/${attachmentType}/RESET_FILES`;

const getInitialState = () => ({
  files: {},
  listingFiles: false,
  listFilesError: null,

  addingFile: false,
  addFileError: null,

  deletingFile: false,
  deleteFileError: null,
});

const attachmentsOfTypeReducer = (attachmentType) => (state = getInitialState(), action) => {
  switch (action.type) {
    case listFilesType(attachmentType): {
      return {
        ...state,
        listingFiles: true,
        listFilesError: null,
      };
    }
    case listFilesSuccessType(attachmentType): {
      return {
        ...state,
        files: action.result,
        listingFiles: false,
      };
    }
    case listFilesFailType(attachmentType): {
      return {
        ...state,
        listingFiles: false,
        listFilesError: action.error,
      };
    }
    case addFileType(attachmentType): {
      return {
        ...state,
        addingFile: true,
        addFileError: null,
      };
    }
    case addFileSuccessType(attachmentType): {
      return {
        ...state,
        addingFile: false,
      };
    }
    case addFileFailType(attachmentType): {
      return {
        ...state,
        addingFile: false,
        addFileError: action.error,
      };
    }
    case deleteFileType(attachmentType): {
      return {
        ...state,
        deletingFile: true,
        deleteFileError: null,
      };
    }
    case deleteFileSuccessType(attachmentType): {
      return {
        ...state,
        deletingFile: false,
      };
    }
    case deleteFileFailType(attachmentType): {
      return {
        ...state,
        deletingFile: false,
        deleteFileError: action.error,
      };
    }
    case resetFilesType(attachmentType): {
      return getInitialState();
    }
    default: { return state; }
  }
};

export default combineReducers({
  [ORDER_ATTACHMENT_TYPE]: attachmentsOfTypeReducer(ORDER_ATTACHMENT_TYPE),
  [BILLING_ATTACHMENT_TYPE]: attachmentsOfTypeReducer(BILLING_ATTACHMENT_TYPE),
  [REQUISITION_ATTACHMENT_TYPE]: attachmentsOfTypeReducer(REQUISITION_ATTACHMENT_TYPE),
  [OTHER_ATTACHMENT_TYPE]: attachmentsOfTypeReducer(OTHER_ATTACHMENT_TYPE),
});

export const listFiles = (orderId, fileType) => {
  const path = ['list_order_files', orderId, fileType].join('/');

  return {
    types: [
      listFilesType(fileType),
      listFilesSuccessType(fileType),
      listFilesFailType(fileType),
    ],
    request: (client) => client.get(path),
  };
};

export const addFile = (file) => ({
  types: [
    addFileType(file.type),
    addFileSuccessType(file.type),
    addFileFailType(file.type),
  ],
  request: (client) => client.post('add_order_file', file),
});

export const deleteFile = (attachmentType, params) => ({
  types: [
    deleteFileType(attachmentType),
    deleteFileSuccessType(attachmentType),
    deleteFileFailType(attachmentType),
  ],
  request: (client) => client.post('delete_order_file', params),
});

export const resetFiles = (attachmentType) => ({ type: resetFilesType(attachmentType) });

const orderAttachmentsSelector = (prop) => (
  (attachmentType) => (state) => state.orderAttachments[attachmentType][prop]
);

export const filesSelector = orderAttachmentsSelector('files');
export const addingFileSelector = orderAttachmentsSelector('addingFile');
export const addFileErrorSelector = orderAttachmentsSelector('addFileError');
export const deletingFileSelector = orderAttachmentsSelector('deletingFile');
export const deleteFileErrorSelector = orderAttachmentsSelector('deleteFileError');
