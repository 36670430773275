import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Field, FieldArray,
} from 'formik';
import {
  Yup, requiredString, requiredPositiveInteger, whenValue,
} from 'components/Formik/validation';
import { getFieldError } from 'components/Formik/ErrorMessage';
import GeneSelection from 'components/GeneSelection/GeneSelection';
import LabelInputWithoutSpace from 'components/Formik/LabelInputWithoutSpace';
import SelectInput from 'components/Formik/SelectInput';
import { isMitoGene } from 'utils/forms';
import { MITO_CHROMOSOME } from 'helpers/constants/specialTypeConstants';

import OrderInfoPropTypes from 'types/OrderInfoPropTypes';

import styles from './AddVariants.scss';

const initialTranscriptOptions = [{ value: 'Not known', key: 'not_known' }];

const variantEntrySchema = Yup.object().shape({
  // TODO: Set to '* This field is required, please select a gene' once redux-form is not used
  //       Old LabelInput component is used in GeneSelection
  gene: requiredString('This field is required, please select a gene'),
  transcript: whenValue('gene', 'MT-', Yup.string(), requiredString()),
  c_dna_change: requiredString(),
  protein_change: Yup.string().ensure(),
  chromosome: Yup.string().ensure(),
  start: whenValue('gene', 'MT-', requiredPositiveInteger('Please enter a variant start position in the form of a number ')),
  end: whenValue('gene', 'MT-', requiredPositiveInteger('Please enter a valid variant end position in the form of a number')),
  alt: whenValue('gene', 'MT-', requiredString('This field is required, please enter a variant alteration')),
  ref: whenValue('gene', 'MT-', requiredString('This field is required, please enter a variant reference')),
});

export const variantsSchema = Yup.array().of(variantEntrySchema);

export function newEmptyVariant() {
  return Object.keys(variantEntrySchema.fields).reduce((values, field) => Object.assign(values, {
    [field]: '',
  }), {
    // id is used to locally identify the variant until it is saved on the backend
    id: Math.round(Math.random() * 1000000),
  });
}

export default class AddVariants extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape(OrderInfoPropTypes).isRequired,
    variants: PropTypes.arrayOf(PropTypes.shape({
      gene: PropTypes.string.isRequired,
    })).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    allowEmpty: PropTypes.bool,
    hint: PropTypes.node,
    setShowAddVariantTable: PropTypes.func.isRequired,
    showAddVariantTable: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    allowEmpty: false,
    hint: null,
  };

  state = {
    // Available transcripts per gene ID
    availableTranscripts: {},
  };

  addVariant = (arrayHelpers) => {
    arrayHelpers.push(newEmptyVariant());
    this.props.setShowAddVariantTable(true);
  };

  removeVariant = (arrayHelpers, index, geneId) => {
    arrayHelpers.remove(index);

    // Clean up available transcripts
    this.updateAvailableTranscripts(geneId, []);
  };

  updateAvailableTranscripts = (geneId, transcripts) => {
    this.setState((state) => ({
      availableTranscripts: {
        ...state.availableTranscripts,
        [geneId]: transcripts,
      },
    }));
  }

  setTranscriptFormValue = (form, index, value) => {
    form.setFieldValue(`variants[${index}].transcript`, value);
  }

  handleClickGene = (form, item, index) => (genes) => {
    const geneId = item.id;

    // Clean up existing transcript value
    this.setTranscriptFormValue(form, index, '');

    if (genes.length) {
      // Select gene
      const gene = genes[0];

      this.updateAvailableTranscripts(geneId, gene.transcripts);
      form.setFieldValue(`variants[${index}].gene`, gene.hgnc_symbol);

      if (gene.transcripts && gene.transcripts.length === 1) {
        this.setTranscriptFormValue(form, index, gene.transcripts[0]);
      }
      if (isMitoGene(gene.hgnc_symbol)) {
        form.setFieldValue(`variants[${index}].chromosome`, MITO_CHROMOSOME);
      }
    } else {
      // Remove gene
      form.setFieldValue(`variants[${index}].gene`, '');

      // Clean up chromosome value
      form.setFieldValue(`variants[${index}].chromosome`, '');

      // Clean up available transcripts
      this.updateAvailableTranscripts(geneId, []);
    }
  };

  render() {
    const {
      orderInfo,
      variants,
      isSubmitting,
      allowEmpty,
      hint,
      showAddVariantTable,
    } = this.props;

    const { availableTranscripts } = this.state;

    const panelId = parseInt(orderInfo.product.id, 10);
    const hideRemove = !allowEmpty && variants.length === 1;
    const hideAdd = variants.length >= 10;

    return (
      <FieldArray
        name="variants"
        render={(arrayHelpers) => (
          <div className={styles.variants}>
            {variants.length > 0 && (variants.find((variant) => variant.gene !== '') || showAddVariantTable) && variants.map((item, index) => (
              <div className={`${styles.variant_item} variant-item-container`} key={item.id}>
                <Field name={`variants[${index}].gene`}>
                  {({ form, field }) => (
                    <GeneSelection
                      initialGenes={
                        item.gene !== ''
                          ? (
                            [{
                              hgnc_symbol: item.gene,
                            }]
                          )
                          : []
                      }
                      error={getFieldError(form, field.name)}
                      limitNbrGenes={1}
                      onClick={this.handleClickGene(form, item, index)}
                      onFetchTranscripts={
                        (transcripts) => this.updateAvailableTranscripts(item.id, transcripts)
                      }
                      hint={hint || 'Start typing to search for genes'}
                      panelId={panelId}
                    />
                  )}
                </Field>

                {!isMitoGene(variants[index].gene) && (
                  <Field
                    name={`variants[${index}].transcript`}
                    component={SelectInput}
                    label="Transcript"
                    options={
                      !availableTranscripts[item.id]
                        ? initialTranscriptOptions
                        : (
                          Object.values(availableTranscripts[item.id])
                            .map((transcript) => ({ key: transcript, value: transcript }))
                            .concat(initialTranscriptOptions)
                        )
                    }
                    placeholder="Select transcript:"
                    hint="e.g. NM_170707.3 If transcript information is not known, please provide supportive material, such as a copy of index patient report or a literature reference. Attaching documents is possible in the clinical history section."
                    required
                  />
                )}

                <Field
                  component={LabelInputWithoutSpace}
                  hint="e.g. c.4375C>T, c.612_615delGAGA or c.4375_4385dup"
                  label="Coding DNA/mtDNA change"
                  name={`variants[${index}].c_dna_change`}
                  required
                />

                <Field
                  component={LabelInputWithoutSpace}
                  hint="e.g. Arg190Gln, Leu14del or Pro54Alafs*57"
                  label="Protein change"
                  name={`variants[${index}].protein_change`}
                />

                {isMitoGene(variants[index].gene) && (
                  <>
                    <Field
                      name={`variants[${index}].start`}
                      component={LabelInputWithoutSpace}
                      label="Position start"
                      required
                    />

                    <Field
                      name={`variants[${index}].end`}
                      component={LabelInputWithoutSpace}
                      label="Position end"
                      required
                    />

                    <Field
                      name={`variants[${index}].alt`}
                      component={LabelInputWithoutSpace}
                      label="Alt"
                      required
                    />

                    <Field
                      name={`variants[${index}].ref`}
                      component={LabelInputWithoutSpace}
                      label="Ref"
                      required
                    />
                  </>
                )}

                {!hideRemove && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      disabled={isSubmitting}
                      onClick={() => this.removeVariant(arrayHelpers, index, item.id.value)}
                    >
                      Remove item
                    </button>
                  </div>
                )}
              </div>
            ))}

            {!hideAdd && (
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-default"
                  disabled={isSubmitting}
                  onClick={() => this.addVariant(arrayHelpers)}
                >
                  Add variant
                </button>
              </div>
            )}
          </div>
        )}
      />
    );
  }
}
