import React from 'react';
import PropTypes from 'prop-types';

import styles from './ViewOrderInfo.scss';

const FaxSubscriptionSection = ({
  isFaxSubscriptionSelected,
  faxList,
}) => (
  <>
    <div className="form-check checkbox modal_checkbox">
      <input
        type="checkbox"
        className="form-check-input"
        checked={isFaxSubscriptionSelected}
        disabled
      />
      <label className="view_order_modal_label form-check-label">
        Delivery of result by fax
      </label>
    </div>

    {isFaxSubscriptionSelected && faxList && faxList.length > 0 && (
      <div>
        <h4 className={styles.extra_info_heading}>Fax info</h4>
        {faxList.map((item) => (
          <div className={styles.subscription_item} key={item.id}>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Fax number
              </label>
              <span>{item.fax_number}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Recipient name
              </label>
              <span>{item.recipient_name}</span>
            </div>
          </div>
        ))}
      </div>
    )}
  </>
);

FaxSubscriptionSection.propTypes = {
  isFaxSubscriptionSelected: PropTypes.bool,
  faxList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    fax_number: PropTypes.string,
    recipient_name: PropTypes.string,
  })),
};

FaxSubscriptionSection.defaultProps = {
  isFaxSubscriptionSelected: null,
  faxList: null,
};

export default FaxSubscriptionSection;
