import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './VariantItem.scss';

const VariantItem = ({
  selected,
  variant,
  onClick,
}) => (
  <tr
    className={classNames({
      [styles.selectedRow]: selected,
      'selected-variant-item': selected,
    })}
    onClick={() => onClick()}
  >
    <td width="18%">{variant.gene}</td>
    <td width="18%">{variant.c_dna_change}</td>
    <td width="18%">{variant.protein_change}</td>
    <td width="18%">{variant.event}</td>
    <td width="18%">{variant.classification.value}</td>
    <td width="10%">
      <div className={classNames({
        [styles.selected]: selected,
        'variant-item-tickbox-selected': selected,
        [styles.unselected]: !selected,
        'variant-item-tickbox-unselected': !selected,
      })}
      />
    </td>
  </tr>
);

VariantItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  variant: PropTypes.shape({
    gene: PropTypes.string.isRequired,
    event: PropTypes.string,
    c_dna_change: PropTypes.string.isRequired,
    protein_change: PropTypes.string.isRequired,
    classification: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default VariantItem;
