import React from 'react';
import PropTypes from 'prop-types';

const RowDataPropTypes = {
  userId: PropTypes.number.isRequired,
};

const ColumnPropTypes = {
  onOpenDialog: PropTypes.func.isRequired,
};

const EditCell = ({ cellData, rowData, column }) => {
  const { onOpenDialog } = column;

  return (
    <div>
      <button
        type="button"
        className="btn btn-link link-pink"
        data-userid={cellData}
        onClick={() => onOpenDialog(null, rowData.userId)}
      >
        Edit
      </button>
    </div>
  );
};

EditCell.propTypes = {
  cellData: PropTypes.number.isRequired,
  column: PropTypes.shape(ColumnPropTypes).isRequired,
  rowData: PropTypes.shape(RowDataPropTypes).isRequired,
};

export default EditCell;
