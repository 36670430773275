import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OrderReportVariantsDeldupHeader from './_OrderReportVariantsDeldupHeader';

export default class OrderReportVariantsDeldup extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
    variantsDeldup: PropTypes.object,
    // eslint-disable-next-line react/require-default-props
    reportVersion: PropTypes.number,
  }

  textColorClass = (colorClass) => {
    if (colorClass === 'good') {
      return 'text-gray';
    }
    if (colorClass === 'severe') {
      return 'text-pink';
    }
    return '';
  }

  renderVariant(variant, index) {
    const rowOne = (
      <tr>
        <td>
          <span className="report-td-heading">Gene</span>
          <br />
          {variant.gene.split(',')
            .map((gene, geneIndex) => (
              <a
                // eslint-disable-next-line react/no-array-index-key
                key={geneIndex}
                className="link-gray clearfix"
                target="_blank"
                rel="noopener noreferrer"
                href={variant.gene_base_url + gene}
              >
                <span>{gene}</span>
              </a>
            ))}
        </td>
        <td>
          <span className="report-td-heading">Event</span>
          <br />
          <span>{variant.event}</span>
        </td>
        <td>
          <span className="report-td-heading">Copy number</span>
          <br />
          <span>{variant.copy_number}</span>
        </td>
        <td style={{ minWidth: '120px', maxWidth: '300px', wordBreak: 'break-word' }}>
          <span className="report-td-heading">Genotype</span>
          <br />
          <span>{variant.genotype}</span>
        </td>
        {variant.gnomad && (
        <td>
          <span className="report-td-heading">gnomAD</span>
          <br />
          <span>{variant.gnomad}</span>
        </td>
        )}
        <td>
          <span className="report-td-heading">Impact</span>
          <br />
          {variant.impact && variant.impact.split(',')
            // eslint-disable-next-line react/no-array-index-key
            .map((impact, impactIndex) => <span key={impactIndex} className="clearfix">{impact}</span>)}
        </td>
        <td>
          <span className="report-td-heading">Links</span>
          <br />
          <a className="link-gray" target="_blank" rel="noopener noreferrer" href={variant.links}><span>{variant.links_txt}</span></a>
        </td>
        <td>
          <span className="report-td-heading">Classification</span>
          <br />
          <span className={this.textColorClass(variant.classification.class)}>
            {variant.classification.value}
          </span>
        </td>
      </tr>
    );

    const rowTwo = (
      <tr>
        <td />
        <td>
          <a className="link-gray" target="_blank" rel="noopener noreferrer" href={variant.omim_url}><span>{variant.omim}</span></a>
        </td>
        <td colSpan="3">
          <span className="report-td-heading">Phenotype</span>
          <br />
          <span>{variant.phenotype}</span>
        </td>
        <td colSpan="2">
          <span className="report-td-heading">Comment</span>
          <br />
          <span>{variant.comment}</span>
        </td>
      </tr>
    );

    return (
      <tbody key={index}>
        {rowOne}
        {rowTwo}
      </tbody>
    );
  }

  render() {
    const { reportVersion, variantsDeldup } = this.props;
    // eslint-disable-next-line global-require
    const styles = require('./_OrderReportVariantsDeldup.scss');
    let sortedVariantsDeldupArr;
    if (variantsDeldup) {
      const variantsDeldupArr = Object.keys(variantsDeldup).map((key) => variantsDeldup[key]);
      sortedVariantsDeldupArr = variantsDeldupArr
        .sort((element1, element2) => (element1.order_no - element2.order_no));
    }

    return (
      <div>
        <OrderReportVariantsDeldupHeader />
        {(reportVersion === 4 || reportVersion === 5)
          && (
            <table className="table">
              <thead>
                <tr className={styles.report_heading_row}>
                  <th>Gene</th>
                  <th>Event</th>
                  <th>Copy number</th>
                  <th>Genotype</th>
                  <th>Impact</th>
                  <th>Links</th>
                  <th>Classification</th>
                </tr>
              </thead>
              <tbody>
                {variantsDeldup && Object.keys(variantsDeldup)
                  .map((key) => (
                    <tr key={key}>
                      <td><a className="link-pink" target="_blank" rel="noopener noreferrer" href={variantsDeldup[key].gene_url}>{variantsDeldup[key].gene}</a></td>
                      <td>{variantsDeldup[key].event}</td>
                      <td>{variantsDeldup[key].copy_number}</td>
                      <td>{variantsDeldup[key].genotype}</td>
                      <td>{variantsDeldup[key].impact}</td>
                      <td><a className="link-pink" target="_blank" rel="noopener noreferrer" href={variantsDeldup[key].links}>{variantsDeldup[key].links_txt}</a></td>
                      <td>{variantsDeldup[key].classification}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        {reportVersion >= 6
          && (
            <div>
              <table className="table v6-variants-table">
                {sortedVariantsDeldupArr && sortedVariantsDeldupArr
                  .map((variant, index) => this.renderVariant(variant, index))}
              </table>
            </div>
          )}
      </div>
    );
  }
}
