import React from 'react';
import PropTypes from 'prop-types';

import styles from './ViewOrderInfo.scss';

const MailSubscriptionSection = ({
  isMailSubscriptionSelected,
  mailList,
}) => (
  <>
    <div className="form-check checkbox modal_checkbox">
      <input
        type="checkbox"
        className="form-check-input"
        checked={isMailSubscriptionSelected}
        disabled
      />
      <label className="view_order_modal_label form-check-label">
        Delivery of result by regular mail
      </label>
    </div>

    {isMailSubscriptionSelected && mailList && mailList.length > 0 && (
      <div>
        <h4 className={styles.extra_info_heading}>
          Address(es) for test result
        </h4>
        {mailList.map((item) => (
          <div className={styles.subscription_item} key={item.id}>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                First name
              </label>
              <span>{item.first_name}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Last name
              </label>
              <span>{item.last_name}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Institution
              </label>
              <span>{item.organization}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Address 1st line
              </label>
              <span>{item.address_line_1}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">
                Address 2nd line
              </label>
              <span>
                {item.address_line_2 || 'N/A'}
              </span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">Zip</label>
              <span>{item.zip}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">City</label>
              <span>{item.city}</span>
            </div>
            <div className={styles.subscription_item_row}>
              <label className="view_order_modal_label">Country</label>
              <span>{item.country}</span>
            </div>
          </div>
        ))}
      </div>
    )}
  </>
);

MailSubscriptionSection.propTypes = {
  isMailSubscriptionSelected: PropTypes.bool,
  mailList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    organization: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  })),
};

MailSubscriptionSection.defaultProps = {
  isMailSubscriptionSelected: null,
  mailList: null,
};

export default MailSubscriptionSection;
