import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useData = (action, defaultShouldFetch = true) => {
  const dispatch = useDispatch();

  const [shouldFetch, setShouldFetch] = useState(defaultShouldFetch);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(defaultShouldFetch);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(action());
        const responseData = response.result || response;
        setData(responseData);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };

    if (shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [dispatch, action, shouldFetch]);

  return {
    data,
    isLoading,
    error,
    setShouldFetch,
  };
};

export default useData;
