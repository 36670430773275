import { useState, useEffect } from 'react';

import useData from 'hooks/useData';
import { getTargetedVariants } from 'redux/modules/orders';

import { newEmptyVariant } from './AddVariants';

const useTargetVariantData = (orderId) => {
  const [variants, setVariants] = useState([]);

  const { data, isLoading, error } = useData(() => getTargetedVariants(orderId));

  useEffect(() => {
    if (!data) {
      return;
    }

    const newVariants = data.variants.length
      ? data.variants
      : [newEmptyVariant()];

    setVariants(newVariants);
  }, [data]);

  return {
    variants,
    variantsLoading: isLoading,
    variantsError: error,
  };
};

export default useTargetVariantData;
