import React from 'react';
import PropTypes from 'prop-types';
import OrderInfoPropTypes, { SpecialTypePropType } from 'types/OrderInfoPropTypes';
import {
  EXOME_PLUS,
  EXOME_FAMILY_PLUS,
  MSD,
} from 'helpers/constants/specialTypeConstants';
import { isMSD } from 'utils/forms';

const GeneralSection = ({
  orderId,
  specialType,
  reflexOrderId,
  orderInfo,
}) => (
  <>
    <div className="modal_row">
      <label className="view_order_modal_label">Order Id</label>
      <span>{orderId}</span>
    </div>

    <div>
      {[EXOME_PLUS, EXOME_FAMILY_PLUS, MSD].includes(specialType) && !(orderInfo.is_after_iteration_17 && orderInfo.is_old_wes) && (
        <div className="form-check checkbox modal_checkbox">
          <input
            type="checkbox"
            className="form-check-input"
            checked={!!reflexOrderId}
            disabled
          />
          <label className="view_order_modal_label form-check-label">
            {isMSD(specialType)
              ? 'Expand to Development Disorder Panel'
              : 'Expand to Exome'}
          </label>
        </div>
      )}

      {reflexOrderId && (
        <div className="modal_row">
          <label className="view_order_modal_label">
            Order ID of previous test
          </label>
          <span>{reflexOrderId}</span>
        </div>
      )}
    </div>
  </>
);

GeneralSection.propTypes = {
  orderId: PropTypes.number.isRequired,
  specialType: SpecialTypePropType,
  reflexOrderId: PropTypes.string,
  orderInfo: OrderInfoPropTypes.isRequired,
};

GeneralSection.defaultProps = {
  specialType: null,
  reflexOrderId: null,
};

export default GeneralSection;
