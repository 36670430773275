import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './InsuranceCaseEditModal.scss';

const NewCommentInput = ({
  label,
  value,
  onChange,
}) => {
  const handleInputChange = ({ target }) => {
    onChange(target.value);
  };

  return (
    <div className={classNames(styles.field, styles.newCommentInput)}>
      <label>{label}</label>
      <textarea
        className="form-control"
        rows="5"
        value={value}
        onChange={handleInputChange}
      />
    </div>
  );
};

NewCommentInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

NewCommentInput.defaultProps = {
  value: '',
};

export default NewCommentInput;
