export const camelToSnake = (str) => str.replace(/([A-Z])/g, '_$1').toLowerCase();

export const snakeToCamel = (str) => str.replace(
  /([-_][a-z])/ig,
  ($1) => $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''),
);

export const convertCamelToSnake = (object) => {
  const newObject = {};

  Object.keys(object).forEach((element) => {
    newObject[camelToSnake(element)] = object[element];
  });
  return newObject;
};

export const convertSnakeToCamel = (object) => {
  const newObject = {};

  Object.keys(object).forEach((element) => {
    newObject[snakeToCamel(element)] = object[element];
  });
  return newObject;
};
