import React from 'react';

const Spinner = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle opacity="0.15" className="static-line" fill="none" stroke="#35596B" strokeWidth="12" strokeLinecap="square" strokeMiterlimit="10" cx="50" cy="50" r="43.2" />
    <g className="loader-fast">
      <circle className="loader-line" fill="none" stroke="#FF8F9F" strokeWidth="12" strokeLinecap="square" strokeMiterlimit="10" cx="50" cy="50" r="43.2" />
    </g>
    <g className="loader-faster">
      <circle className="loader-line" fill="none" stroke="#EF426F" strokeWidth="12" strokeLinecap="square" strokeMiterlimit="10" cx="50" cy="50" r="43.2" />
    </g>
  </svg>
);

export default Spinner;
