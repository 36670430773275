import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import LabelInput from 'components/Formik/LabelInput';
import Checkbox from 'components/Formik/Checkbox';

const FamilyServiceLabel = () => (
  <span>
    Family Extension Service
    <p className="small">
      Available for up to 5 first degree family members whose testing is ordered
      and samples are received within 12 months from the original index patient&apos;s report date.
      {' '}
      <strong>
        For &apos;likely pathogenic&apos; and &apos;pathogenic&apos; variants only.
        Note that this service has a fee.
      </strong>
      {' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://blueprintgenetics.com/family-extension-service/"
      >
        Read more
      </a>
    </p>
  </span>
);

const InstitutionalBillingInfo = ({ orderInfo, values }) => (
  <div>
    {orderInfo.has_family_service && orderInfo.is_us_account && (
      <Field
        name="has_family_service"
        component={Checkbox}
        label={<FamilyServiceLabel />}
        labelClassName="view_order_modal_label"
        className="modal_checkbox"
      />
    )}

    <Field
      name="add_billing_address"
      component={Checkbox}
      hint="Please provide if ordering for the first time."
      label="Add billing address"
      labelClassName="view_order_modal_label"
      className="modal_checkbox"
    />

    {values.add_billing_address && (
      <div>
        <Field
          name="contact_person"
          component={LabelInput}
          label="Contact person"
        />

        <Field
          name="facility_name"
          component={LabelInput}
          label="Facility name"
        />

        <Field
          name="address_line_1"
          component={LabelInput}
          label="Address 1st line"
        />

        <Field
          name="address_line_2"
          component={LabelInput}
          label="Address 2nd line"
        />

        <Field
          name="zip"
          component={LabelInput}
          label="Zip"
        />

        <Field
          name="city"
          component={LabelInput}
          label="City"
        />

        <Field
          name="country"
          component={LabelInput}
          label="Country"
        />

        <Field
          name="contact_person_email"
          component={LabelInput}
          label="Email"
        />
      </div>
    )}
  </div>
);

InstitutionalBillingInfo.propTypes = {
  orderInfo: PropTypes.shape({
    is_us_account: PropTypes.bool,
    has_family_service: PropTypes.bool,
    is_order_completed: PropTypes.bool,
  }).isRequired,
  values: PropTypes.shape({
    add_billing_address: PropTypes.bool,
  }).isRequired,
};

export default InstitutionalBillingInfo;
