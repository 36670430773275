import LabelInput from 'components/Formik/LabelInput';
import React from 'react';
import PropTypes from 'prop-types';

const LabelInputWithoutSpace = ({ field, ...other }) => (
  <LabelInput
    field={field}
    {...other}
    onChange={(e) => {
      if (field.onChange) {
        e.target.value = e.target.value.replace(' ', '');
        field.onChange(e);
      }
    }}
  />
);

LabelInputWithoutSpace.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
};

export default LabelInputWithoutSpace;
