import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import * as orderActions from 'redux/modules/orders';
import * as authActions from 'redux/modules/auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PromptIfDirty from 'components/Formik/PromptIfDirty';
import uniqBy from 'lodash/uniqBy';

import styles from './_OrderTestFormShareResults.scss';
import DeselectUsers from './DeselectUsers';

const validationSchema = Yup.object().shape({
  shareWithUserIds: Yup.array().of(Yup.string()),
});

const mapStateToProps = (state) => ({
  orderInfo: state.orders.orderInfo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  shareOrder: orderActions.shareOrder,
  getOrderCollaborators: orderActions.getOrderCollaborators,
  getHospitalUsers: authActions.getHospitalUsers,
}, dispatch);

class OrderTestFormShareResults extends Component {
  static propTypes = {
    orderInfo: PropTypes.shape({
      order_id: PropTypes.number,
      is_order_completed: PropTypes.bool,
      product: PropTypes.shape({
        id: number,
      }).isRequired,
    }).isRequired,
    shareOrder: PropTypes.func.isRequired,
    getOrderCollaborators: PropTypes.func.isRequired,
    getHospitalUsers: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    Step: PropTypes.elementType.isRequired,
  };

  state = {
    searchStr: '',
    loading: true,
    error: null,
    // PropTypes.arrayOf(PropTypes.shape({
    //   user_id: PropTypes.number,
    //   name: PropTypes.string,
    //   email: PropTypes.string,
    // })).isRequired,
    collaborators: [],
    // PropTypes.arrayOf(PropTypes.shape({
    //   user_id: PropTypes.number.isRequired,
    //   email: PropTypes.string.isRequired,
    //   name: PropTypes.string.isRequired,
    // })).isRequired,
    hospitalUsers: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({
      loading: true,
    });

    const { orderInfo } = this.props;

    try {
      const collaborators = await this.props.getOrderCollaborators(orderInfo.order_id);
      const hospitalUsers = await this.props.getHospitalUsers(orderInfo.product.id);

      this.setState({
        collaborators,
        hospitalUsers,
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: 'Failed to load collaborators',
        loading: false,
      });
    }
  }

  selectPerson = (userId, formProps) => (event) => {
    event.preventDefault();

    const selectedUserIds = formProps.values.shareWithUserIds;

    if (!selectedUserIds.includes(userId)) {
      const updatedIds = selectedUserIds.concat([userId]);
      formProps.setFieldValue('shareWithUserIds', updatedIds);
    }
  }

  deselectPerson = (userId, formProps) => {
    const selectedUserIds = formProps.values.shareWithUserIds;
    const updatedIds = selectedUserIds.filter((id) => id !== userId);
    formProps.setFieldValue('shareWithUserIds', updatedIds);
  }

  changeSearchString = (value) => {
    this.setState({
      searchStr: value,
    });
  }

  submit = async (values, actions) => {
    const { orderInfo } = this.props;
    actions.setFieldError('general', null);

    try {
      await this.props.shareOrder(orderInfo.order_id, values.shareWithUserIds);
    } catch (e) {
      actions.setFieldError('general', e.message);
      throw new Error(e);
    }
  }

  userOptions = (formProps) => {
    const {
      searchStr,
      hospitalUsers,
    } = this.state;
    const selectedUserIds = formProps.values.shareWithUserIds;

    return hospitalUsers
      // Filter out selected users
      .filter((u) => !selectedUserIds.includes(u.user_id))
      // Filter out users by search string
      .filter((u) => u.email.includes(searchStr) || u.name.includes(searchStr));
  }

  selectedUsers = (formProps) => {
    const selectedUserIds = formProps.values.shareWithUserIds;

    const {
      collaborators,
      hospitalUsers,
    } = this.state;

    return uniqBy(
      collaborators
        .concat(hospitalUsers)
        .filter((user) => selectedUserIds.includes(user.user_id)),
      (user) => user.user_id,
    );
  }

  render() {
    const {
      hideModal,
      Step,
      orderInfo,
    } = this.props;
    const {
      collaborators,
      loading,
      error,
    } = this.state;

    return (
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          shareWithUserIds: collaborators.map((collaborator) => collaborator.user_id),
        }}
        enableReinitialize
        onSubmit={(values, actions) => this.submit(values, actions)}
      >
        {(formProps) => (
          <Form>
            <PromptIfDirty {...formProps} />
            <Step
              {...formProps}
              hideModal={hideModal}
              error={error || formProps.errors.general}
              loading={loading}
              submit={() => formProps.submitForm()}
            >
              <div className="row">
                <div className="col-md-6">
                  <h4 className={styles.share_result_heading}>Colleagues:</h4>

                  <div className="form-inline tests-search-form">
                    <div className="input-group search-input-group">
                      <input
                        type="text"
                        name="colleague-filter"
                        className="search form-control"
                        placeholder="Filter..."
                        onChange={(event) => this.changeSearchString(event.target.value)}
                      />

                      <span className="input-group-button">
                        <button type="button">
                          <i className="glyphicon glyphicon-search" aria-hidden="true" />
                        </button>
                      </span>
                    </div>
                  </div>

                  <ul className={`${styles.share_result_list} list-unstyled`}>
                    {this.userOptions(formProps).map((u) => (
                      /*
                        eslint-disable
                        jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-noninteractive-element-interactions
                      */
                      <li
                        key={u.user_id}
                        className={styles.share_result_item}
                        onClick={this.selectPerson(u.user_id, formProps)}
                      >
                        {u.name}
                        <br />
                        <span className="small">{u.email}</span>
                        <span className="float-right glyphicon glyphicon-plus" />
                      </li>
                      /*
                        eslint-enable
                        jsx-a11y/click-events-have-key-events,
                        jsx-a11y/no-noninteractive-element-interactions
                      */
                    ))}
                  </ul>
                </div>

                <DeselectUsers
                  formProps={formProps}
                  isCompleted={orderInfo.is_order_completed}
                  selectedUsers={this.selectedUsers}
                  deselectPerson={this.deselectPerson}
                />

              </div>
            </Step>
          </Form>
        )}
      </Formik>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrderTestFormShareResults);
