const ORDER_FILTERING_OPTIONS = [
  {
    value: 'orders.id,patients.name,patients.dob',
    label: 'Pt name / Pt DOB / Order ID',
  },
  {
    value: '',
    label: 'Search all fields',
  },
  {
    value: 'orders.id',
    label: 'Order ID',
  },
  {
    value: 'orders.index_order_id',
    label: 'Index Order ID',
  },
  {
    value: 'patients.name',
    label: 'Patient name',
  },
  {
    value: 'patients.dob',
    label: 'Patient DOB',
  },
  {
    value: 'patients.ssn',
    label: 'Patient SSN',
  },
  {
    value: 'users.email',
    label: 'User email',
  },
  {
    value: 'users.full_name',
    label: 'User name',
  },
  {
    value: 'orders.customer_sample_id',
    label: 'Customer sample ID',
  },
  {
    value: 'alias_users.email',
    label: 'Superuser / AHP user email',
  },
  {
    value: 'hospitals.name',
    label: 'Hospital name',
  },
];

export { ORDER_FILTERING_OPTIONS as default };
