import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
} from 'react';
import * as orderActions from 'redux/modules/orders';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import SpinnerOverlay from 'components/Spinner/SpinnerOverlay';

const ComponentLayout = ({
  type,
  orderId,
  defaultToRender,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [component, setComponent] = useState(null);
  const [componentProps, setComponentProps] = useState(null);

  useEffect(() => {
    const fetchComponentLayout = async () => {
      setIsLoading(true);

      const componentLayoutData = await dispatch(orderActions.getComponentLayout(orderId, type));

      let componentFolder;
      switch (type) {
        case 'informed_consent':
          componentFolder = '_OrderTestFormInformedConsent';
          break;
        case 'billing_info':
          componentFolder = '_OrderTestFormBillingInfo';
          break;
        case 'confirm_order':
          componentFolder = '_OrderTestFormConfirmOrder';
          break;
        case 'order_info':
          componentFolder = '_OrderTestFormOrderInfo';
          break;
        case 'patient_history':
          componentFolder = '_OrderTestFormPatientHistory';
          break;
        case 'share_results':
          componentFolder = '_OrderTestFormShareResults';
          break;
        default:
          break;
      }

      const componentData = {
        componentName: componentLayoutData?.component_name,
        componentProps: componentLayoutData?.component_props
          ? JSON.parse(componentLayoutData.component_props) : null,
      };

      if (componentData && componentData.componentName) {
        const path = `components/_CreateOrderProcess/${componentFolder}/${componentData.componentName}`;
        // eslint-disable-next-line prefer-template
        const asyncComponent = lazy(() => import('' + path + ''));

        setComponent(asyncComponent);
        setComponentProps(componentData.componentProps);
      }
      setIsLoading(false);
    };

    fetchComponentLayout();

    return () => {
      dispatch(orderActions.resetComponentLayout());
    };
  }, [type, orderId, defaultToRender, dispatch]);

  const LoadingComponent = <div style={{ height: '400px' }}><SpinnerOverlay /></div>;

  if (component && !isLoading) {
    const ComponentToRender = component;
    return (
      <Suspense fallback={LoadingComponent}>
        <ComponentToRender {...rest} {...componentProps} />
      </Suspense>
    );
  }

  if (defaultToRender && !isLoading) {
    const ComponentToRender = defaultToRender;
    return <ComponentToRender {...rest} />;
  }

  if (!defaultToRender) return null;

  return LoadingComponent;
};

ComponentLayout.propTypes = {
  type: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  defaultToRender: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
};

ComponentLayout.defaultProps = {
  defaultToRender: null,
};

export default ComponentLayout;
