const mapGroup = (group) => ({
  groupName: group.group_name,
  groupAdmin: {
    groupId: group.group_id,
    groupName: group.group_name,
    groupAdminName: group.group_admin_name,
    groupAdminEmail: group.group_admin_email,
    groupHospitalName: group.hospital_name,
  },
  groupHospitalName: group.hospital_name,
  groupUserCount: group.user_count,
  groupId: group.group_id,
  id: group.group_id,
});

export default mapGroup;
